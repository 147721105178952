import { Box, Stack, Typography } from '@mui/material';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { Account } from 'generated/graphql';

import { styles } from './AccountListItem.styles';

export interface AccountListItemProps {
  handleClick: () => void;
  accountKey: Account;
  label: string;
  amount: string;
  amountColor?: string;
}

export const AccountListItem: React.FC<AccountListItemProps> = ({
  accountKey,
  amount,
  amountColor,
  handleClick,
  label,
}) => {
  return (
    <Stack justifyContent="space-between" onClick={handleClick} sx={styles.container}>
      <Box mb={1}>
        <AccountCard colorIndex={accountKey.colorIndex} height={12} width={20} />
      </Box>
      <Typography sx={styles.label} variant="bodyM">
        {label}
      </Typography>
      <Typography color={amountColor} variant="bodyStrongL">
        {amount}
      </Typography>
    </Stack>
  );
};
