import { Amplify } from 'aws-amplify';
import { environment } from 'environment';

const {
  cognito: { clientId, domain, poolId, region },
} = environment;

export const GOOGLE_SIGN_OUT_URL = `${window.location.origin}/logout`;

export const configureAmplify = (): void => {
  if (clientId && poolId && region) {
    Amplify.configure({
      // See https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
      Auth: {
        oauth: {
          domain: domain,
          redirectSignIn: `${window.location.origin}/login`,
          redirectSignOut: GOOGLE_SIGN_OUT_URL,
          responseType: 'code',
        },
        region: region,
        userPoolId: poolId,
        userPoolWebClientId: clientId,
      },
    });
  } else {
    console.error(
      new Error(
        `Missing auth config variables ${JSON.stringify({
          ...environment.cognito,
        })}`,
      ),
    );
  }
};
