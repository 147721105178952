import { Stack, Typography } from '@mui/material';
import AccountsManagementImage from 'assets/images/accounts/accounts-management.png';
import { Image } from 'common/components/Image/Image';
import { useTranslation } from 'react-i18next';

export const AccountsManagementInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack alignItems="center" spacing={1}>
        <Typography variant="headlineXL">{t('homepage.accountsProductTour.accountsManagementInfo.title')}</Typography>
        <Typography color="textSecondary" variant="bodyL">
          {t('homepage.accountsProductTour.accountsManagementInfo.subtitle')}
        </Typography>
      </Stack>

      <Image height="auto" maxWidth="100%" src={AccountsManagementImage} />
    </Stack>
  );
};
