import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'listItem' | 'chevron' | 'icon', SxProps<Theme>> = {
  chevron: {
    position: 'absolute',
    right: '16px',
    top: '16px',
  },
  container: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.10))',
    },
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 2,
    position: 'relative',
    transition: 'filter .5s ease-in-out',
    width: '100%',
    willChange: 'filter',
  },
  icon: {
    height: '48px',
    width: '48px',
  },
  listItem: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: (theme) => theme.spacing(2),
  },
};
