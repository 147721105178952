import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useRenameTagMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  name?: string;
}

export interface RenameTagDialogProps extends DialogComponentProps<void> {
  name: string;
  tagId: string;
}

export const RenameTagDialog: React.FC<RenameTagDialogProps> = ({ isOpen, name, onResolve, tagId }) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      name,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [renameTag, { loading }] = useRenameTagMutation();
  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (data.name) {
      await renameTag({
        onCompleted: () => onResolve(),
        onError: () => notify({ message: t('common:somethingWentWrong'), type: 'error' }),
        variables: {
          name: data.name,
          tagId: tagId,
        },
      });
    }
  });

  return (
    <PromptDialogOnSide onClose={() => onResolve()} open={isOpen} title={t('renameTag.title')}>
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoFocus fullWidth name="name" placeholder={t('renameTag.name.placeholder')} type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <LoadingButton color="primary" loading={loading} sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('renameTag.submit')}
              </Typography>
            </LoadingButton>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
