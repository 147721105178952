import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { styles } from './ChangeDialog.styles';

export interface FormValues {
  name?: string;
}

export type ChangeNameDialogProps = DialogComponentProps<void>;

export const ChangeNameDialog: React.FC<ChangeNameDialogProps> = ({ isOpen, onReject, onResolve }) => {
  const { t } = useTranslation();
  const { updateAttributes } = useContext(AuthContext);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator(),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (data.name) {
      setError('');
      setLoading(true);
      const resp = await updateAttributes({
        preferred_username: data.name,
      });

      if (resp) {
        setError(resp.formError?.message ?? t('common:somethingWentWrong'));
      } else {
        onResolve();
      }
      setLoading(false);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <StyledDialog maxWidth="sm" onClose={() => onReject()} open={isOpen}>
        <StyledDialogTitle textAlign="left">{t('settings.changeDialog.name.title')}</StyledDialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormInput
              fullWidth
              label={t('settings.changeDialog.name.label')}
              name="name"
              placeholder={t('settings.changeDialog.placeholder.name')}
              sx={styles.input}
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondaryButton" onClick={() => onReject()}>
              {t('settings.changeDialog.cancel')}
            </Button>
            <LoadingButton loading={loading} type="submit">
              {t('settings.changeDialog.save')}
            </LoadingButton>
          </DialogActions>
          {!!error && !loading && <StyledDialogFormError content={error} title={t('settings.changeDialog.error')} />}
        </Box>
      </StyledDialog>
    </FormProvider>
  );
};
