import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { formatCurrency, formatIban } from 'common/utils/formatUtils';
import { getTitleForTransactionCounterparty } from 'components/transactions/utils';
import { format } from 'date-fns';
import { TransactionGroupPeriodTypeCode, useUpdatePeriodOfTransactionGroupMutation } from 'generated/graphql';
import { i18n } from 'i18n';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QUERIES_TO_REFETCH_AFTER_CHANGE } from '../../const';
import { TransactionDetailBox } from '../../TransactionDetailBox';
import { SetAmountInputDialog, SetAmountInputDialogProps } from '../setDialogs/SetAmountInputDialog';
import { SetDateInputDialogProps, SetReferenceDateInputDialog } from '../setDialogs/SetNextDateInputDialog';
import { Period, SetPeriodInputDialog, SetPeriodInputDialogProps } from '../setDialogs/SetPeriodInputDialog';
import { UpcomingPaymentHeader } from '../UpcomingPaymentHeader';
import { UpcomingPaymentListItem } from '../UpcomingPaymentListItem/UpcomingPaymentListItem';
import { ExtendedTransactionGroup, getPeriodicityLabel } from '../utils';
import { styles } from './UpcomingPaymentCreate.styles';

export interface UpcomingPaymentCreateReturn {
  group: ExtendedTransactionGroup;
}

export interface UpcomingPaymentCreateProps extends DialogComponentProps<UpcomingPaymentCreateReturn> {
  group: ExtendedTransactionGroup;
}
const UpcomingPaymentCreateContent: React.FC<UpcomingPaymentCreateProps> = (props) => {
  const [group, setGroup] = useState<ExtendedTransactionGroup>(props.group);
  const notify = useNotification();
  const { t } = useTranslation();

  const [updatePeriod, { loading }] = useUpdatePeriodOfTransactionGroupMutation({
    onCompleted: async () => {
      props.onResolve({ group: group });
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const handleDone = () => {
    if (group.period) {
      updatePeriod({
        refetchQueries: QUERIES_TO_REFETCH_AFTER_CHANGE,
        variables: {
          groupId: group.id,
          period: {
            expectedAmount: {
              amount: group.period.expectedAmount?.amount ?? 0,
              currency: group.period.expectedAmount?.currency ?? 'EUR',
            },
            periodCount: group.period.periodCount,
            periodTypeCode: group.period.periodTypeCode,
            referenceDate: group.period.referenceDate,
          },
        },
      });
    }
  };

  const convertToNegativeNumber = (amount: number) => {
    return amount > 0 ? amount * -1 : amount;
  };

  const amountDialog = useDialog<SetAmountInputDialogProps, number>(SetAmountInputDialog, {
    instanceId: 'set-amount',
  });

  const handleSetAmount = async () => {
    const editedAmount = await amountDialog.create({
      amount: group.period?.expectedAmount?.amount,
    });
    if (editedAmount) {
      setGroup(_.set(_.cloneDeep(group), 'period.expectedAmount.amount', convertToNegativeNumber(editedAmount)));
    }
  };

  const dateDialog = useDialog<SetDateInputDialogProps, Date>(SetReferenceDateInputDialog, {
    instanceId: 'set-next-date',
  });

  const handleSetDate = async () => {
    const editedDate = await dateDialog.create({
      referenceDate: group.period?.expectedDate || undefined,
    });
    if (editedDate) {
      setGroup(
        _.merge(_.cloneDeep(group), {
          period: {
            expectedDate: editedDate,
            referenceDate: editedDate,
          },
        }),
      );
    }
  };

  const periodDialog = useDialog<SetPeriodInputDialogProps, Period>(SetPeriodInputDialog, {
    instanceId: 'set-period',
  });

  const handleSetPeriod = async () => {
    const editedPeriod = await periodDialog.create({
      period: {
        periodCount: group.period?.periodCount ?? 1,
        periodTypeCode: group.period?.periodTypeCode ?? TransactionGroupPeriodTypeCode.Month,
      },
    });
    if (editedPeriod) {
      const clone = _.cloneDeep(group);
      _.set(clone, 'period.periodCount', editedPeriod.periodCount);
      _.set(clone, 'period.periodTypeCode', editedPeriod.periodTypeCode);
      setGroup(clone);
    }
  };

  const isDisabled = !group.period?.referenceDate || !group.period.periodCount || !group.period.periodTypeCode;
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <Stack spacing={3}>
        <UpcomingPaymentHeader group={group} />

        <TransactionDetailBox
          title={getTitleForTransactionCounterparty(group.counterparty, group.period?.expectedAmount?.amount, t)}
        >
          {group.counterparty?.accountIban && (
            <Typography color="textSecondary" variant="bodyM">
              {formatIban(group.counterparty.accountIban)}
            </Typography>
          )}
        </TransactionDetailBox>

        <Stack sx={styles.data}>
          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.amount')} onClick={handleSetAmount}>
            <Typography color="textSecondary" variant="bodyL">
              {group.period?.expectedAmount
                ? formatCurrency(
                    group.period.expectedAmount.amount,
                    i18n.language,
                    2,
                    group.period.expectedAmount.currency,
                    'never',
                  )
                : t('upcomingPaymentDetail.set')}
            </Typography>
          </UpcomingPaymentListItem>

          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.nextPayment')} onClick={handleSetDate}>
            <Typography color="textSecondary" variant="bodyL">
              {group.period?.expectedDate
                ? format(group.period.expectedDate, 'dd.MM.yyyy')
                : t('upcomingPaymentDetail.set')}
            </Typography>
          </UpcomingPaymentListItem>

          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.period')} onClick={handleSetPeriod}>
            <Typography color="textSecondary" variant="bodyL">
              {group.period?.periodCount && group.period?.periodTypeCode
                ? getPeriodicityLabel(t, group.period?.periodCount, group.period?.periodTypeCode)
                : t('upcomingPaymentDetail.set')}
            </Typography>
          </UpcomingPaymentListItem>
        </Stack>

        <Stack marginTop={8}>
          <LoadingButton
            color="primary"
            disabled={isDisabled}
            loading={loading}
            onClick={handleDone}
            sx={styles.button}
          >
            <Typography color="white" variant="bodyL">
              {t('upcomingPaymentDetail.add')}
            </Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </ResponsiveSideDialog>
  );
};

export const UpcomingPaymentCreate: React.FC<UpcomingPaymentCreateProps> = (props) => {
  return <UpcomingPaymentCreateContent {...props} />;
};
