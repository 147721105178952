import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { getWeek, getYear, startOfWeek, subWeeks } from 'date-fns';
import { sk } from 'date-fns/locale';
import { ProductStatus } from 'generated/graphql';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const WEEKS_COUNT = 30;

interface WeekItem {
  week: string;
  label: string;
  activeProducts: number;
  submittedProducts: number;
  waitingForSubmissionProducts: number;
  collisionInsuranceProducts: number;
  genericProducts: number;
  investmentProducts: number;
  lifeInsuranceProducts: number;
  mortgageProducts: number;
  motorLiabilityProducts: number;
  propertyInsuranceProducts: number;
}

interface Props {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products?: any[];
}

export const ProductsStatusChart: React.FC<Props> = ({ isLoading, products }) => {
  const weeks = [] as WeekItem[];
  for (let i = WEEKS_COUNT; i >= 0; i--) {
    const week = startOfWeek(subWeeks(new Date(), i), { locale: sk });
    weeks.push({
      activeProducts: 0,
      collisionInsuranceProducts: 0,
      genericProducts: 0,
      investmentProducts: 0,
      label: getWeek(week, { locale: sk }).toString() + '/' + getYear(week).toString(),
      lifeInsuranceProducts: 0,
      mortgageProducts: 0,
      motorLiabilityProducts: 0,
      propertyInsuranceProducts: 0,
      submittedProducts: 0,
      waitingForSubmissionProducts: 0,
      week: week.toISOString(),
    });
  }

  products?.forEach((product) => {
    const weekIndex = weeks.findIndex(
      (weekItem) =>
        weekItem.week == startOfWeek(product?.creationTimestamp ?? new Date(), { locale: sk }).toISOString(),
    );
    if (weekIndex > -1) {
      switch (product?.status as ProductStatus) {
        case ProductStatus.WaitingForSubmission:
          weeks[weekIndex].waitingForSubmissionProducts++;
          break;
        case ProductStatus.Submitted:
          weeks[weekIndex].submittedProducts++;
          break;
        case ProductStatus.Active:
          weeks[weekIndex].activeProducts++;
          break;
      }
    }
  });

  return isLoading ? (
    <Skeleton height={404} variant="rectangular" />
  ) : (
    <Card>
      <CardHeader title="Produkty podľa stavu" />
      <CardContent>
        <ResponsiveContainer minHeight={300}>
          <BarChart
            data={weeks}
            margin={{
              bottom: 30,
              left: -25,
              right: 25,
              top: -25,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis angle={45} dataKey="label" textAnchor="begin" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" />
            <Bar dataKey="activeProducts" fill="#2196F3" name="ACTIVE" stackId="a" />
            <Bar dataKey="submittedProducts" fill="#0D991C" name="SUBMITTED" stackId="a" />
            <Bar dataKey="waitingForSubmissionProducts" fill="#ED4435" name="WAITING_FOR_SUBMISSION" stackId="a" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
