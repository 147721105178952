import { useApolloClient } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg';
import { ConfirmDialog, ConfirmDialogProps } from 'common/components/ConfirmDialog/ConfirmDialog';
import { useDialog } from 'common/hooks/useDialog';
import { useLoadingIndicator } from 'common/hooks/useLoadingIndicator';
import { useNotification } from 'common/hooks/useNotification';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../common/providers/AuthContextProvider/AuthContextProvider';
import { useRemoveUserMutation } from '../../generated/graphql';
import { ChangeEmailDialog, ChangeEmailDialogProps } from './dialogs/ChangeEmailDialog';
import { ChangeNameDialog, ChangeNameDialogProps } from './dialogs/ChangeNameDialog';
import { ChangeDialogPasswordProps, ChangePasswordDialog } from './dialogs/ChangePasswordDialog';
import { ChangePhoneDialog, ChangePhoneDialogProps } from './dialogs/ChangePhoneDialog';
import { styles } from './SettingsPageContent.styles';
import { SettingsPageItem } from './SettingsPageItem';

export const SettingsPageContent: React.FC = () => {
  const { t } = useTranslation();

  const { end: endLoading, start: startLoading } = useLoadingIndicator(t('settings.removeUser.loading.message'));
  const notify = useNotification();

  const [removeUserMutation] = useRemoveUserMutation({
    onError: () => notify({ message: t('common:somethingWentWrong'), type: 'error' }),
  });

  const changeNameDialog = useDialog<ChangeNameDialogProps, void>(ChangeNameDialog, {
    instanceId: 'settings-change-name',
  });
  const changePhoneDialog = useDialog<ChangePhoneDialogProps, void>(ChangePhoneDialog, {
    instanceId: 'settings-change-phone',
  });
  const changeEmailDialog = useDialog<ChangeEmailDialogProps, void>(ChangeEmailDialog, {
    instanceId: 'settings-change-email',
  });
  const changePasswordDialog = useDialog<ChangeDialogPasswordProps, void>(ChangePasswordDialog, {
    instanceId: 'settings-change-password',
  });

  const confirmDeleteDialog = useDialog<ConfirmDialogProps, boolean>(ConfirmDialog, {
    instanceId: 'confirm-applicant-delete',
  });

  const client = useApolloClient();

  const { signOut, userData: userAuthData } = useContext(AuthContext);
  const isFederatedLogin = !!userAuthData?.user.attributes.identities;

  const handleChangeEmail = async () => {
    if (!isFederatedLogin) {
      changeEmailDialog.create({});
    }
  };

  const handleChangePassword = async () => {
    changePasswordDialog.create({});
  };

  const handleRemoveAccount = async () => {
    const confirmed = await confirmDeleteDialog.create({
      confirmButtonText: t('settings.removeDialog.confirm'),
      title: t('settings.removeDialog.title'),
    });

    if (confirmed) {
      startLoading();
      const resp = await removeUserMutation();

      if (resp.data?.removeUser) {
        await signOut('/removed-user');
        client.clearStore();
      }
      endLoading();
    }
  };

  const handleChangeName = async () => {
    changeNameDialog.create({});
  };

  const handleChangePhone = async () => {
    changePhoneDialog.create({});
  };

  return (
    <Box>
      <Box sx={styles.headline}>
        <Typography component="h2" sx={styles.headlineText} variant="headlineL">
          {t('settings.title')}
        </Typography>
      </Box>
      <Stack sx={styles.content}>
        <SettingsPageItem
          item={t('settings.name')}
          onClick={handleChangeName}
          value={userAuthData?.user?.attributes.preferred_username ?? ''}
        />

        <SettingsPageItem
          disabled={isFederatedLogin}
          item={t('settings.email')}
          onClick={handleChangeEmail}
          tooltip={isFederatedLogin ? t('settings.changeDialog.email.tooltip') : undefined}
          value={userAuthData?.user?.attributes.email ?? ''}
        />
        <SettingsPageItem
          item={t('settings.phone')}
          onClick={handleChangePhone}
          value={userAuthData?.user?.attributes.phone_number ?? ''}
        />
        {isFederatedLogin || (
          <SettingsPageItem item={t('settings.password')} onClick={handleChangePassword} value="**********" />
        )}
        <SettingsPageItem
          icon={<ChevronRightIcon />}
          item={t('settings.removeAccount')}
          onClick={handleRemoveAccount}
          value=""
        />
      </Stack>
    </Box>
  );
};
