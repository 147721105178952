import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ProductsListItem } from './ProductsListItem';
import { ViewProductsListProps } from './ViewAllProductsList';

export const ViewProductsWaitingForReview: React.FC<ViewProductsListProps> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack mb={2} spacing={0.5}>
        <Typography component="h3" variant="headlineM">
          {t('products:seeProductsWaitingForReview.list.title')}
        </Typography>
        <Typography color="textSecondary" variant="bodyM">
          {t('products:seeProductsWaitingForReview.list.subtitle')}
        </Typography>
      </Stack>
      <Stack>
        {products.map((product) => (
          <ProductsListItem key={product.id} product={product} />
        ))}
      </Stack>
    </Box>
  );
};
