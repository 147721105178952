import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Loading } from '../../components/Loading/Loading';

export interface LoadingIndicatorContext {
  start: (message: string) => void;
  end: (message: string) => void;
}

export const LoadingIndicatorContext = createContext<LoadingIndicatorContext>({
  end: () => {
    return;
  },
  start: () => {
    return;
  },
});

LoadingIndicatorContext.displayName = 'LoadingContext';

interface Props {
  children: React.ReactNode;
  fullScreen?: boolean;
  hideCommonLoadingTitle?: boolean;
}

export const LoadingIndicatorProvider: React.FC<Props> = ({ children, fullScreen, hideCommonLoadingTitle }) => {
  const [loadingMessages, setLoadingMessages] = useState<string[]>([]);
  const { pathname } = useLocation();

  const loadingRef = useRef<HTMLDivElement>(null);

  //Reset loading after switching pages
  useEffect(() => {
    return () => {
      setLoadingMessages([]);
    };
  }, [pathname]);

  const context = useMemo(
    () => ({
      end: (message: string) => setLoadingMessages((state) => state.filter((item) => item !== message)),
      start: (message: string) => {
        loadingRef.current?.scrollIntoView?.({ block: 'center' });
        setLoadingMessages((state) => [...state, message]);
      },
    }),
    [],
  );

  return (
    <LoadingIndicatorContext.Provider value={context}>
      {loadingMessages.length > 0 && (
        <Loading
          fullScreen={fullScreen}
          hideCommonLoadingTitle={hideCommonLoadingTitle}
          messages={loadingMessages}
          ref={loadingRef}
        />
      )}
      {children}
    </LoadingIndicatorContext.Provider>
  );
};
