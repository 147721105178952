import { CalculatorLayout } from 'components/calculators/CalculatorLayout/CalculatorLayout';
import { LifeInsuranceCalculatorContent } from 'components/calculators/lifeInsurance/LifeInsuranceCalculatorContent';

export const LifeInsuranceCalculatorInputPage: React.FC = () => {
  return (
    <CalculatorLayout>
      <LifeInsuranceCalculatorContent />
    </CalculatorLayout>
  );
};
