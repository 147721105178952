import { LifeInsuranceCalculatorDomain } from 'generated/graphql';
import {
  ArrayField,
  BooleanField,
  ChipField,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SingleFieldList,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

export const LifeInsuranceCalculatorCalculationShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Základné info">
        <TextField label="ID" source="id" />
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
        <TextField label="Telefónne číslo" source="client.user.phoneNumber" />
        <DateField label="Vytvorené" locales="sk" source="creationTimestamp" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Vstup">
        <TextField label="Záväzky" source="inquiry.liabilitiesSum" />
        <TextField label="Finančná rezerva" source="inquiry.financialReserveRisk" />
        <BooleanField label="Šport" source="inquiry.sportRisk" />
        <TextField label="Zamestnanie" source="inquiry.employmentRisk" />
        <TextField label="Jazda autom" source="inquiry.drivingMileage" />
        <BooleanField label="Zdravie" source="inquiry.healthRisk" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Výsledok">
        <TextField label="Skóre" source="result.score" />
        <TextField label="Potreba" source="result.necessity" />
        <ArrayField label="Oblasti s najväčším dopadom" source="result.significantDomains">
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: LifeInsuranceCalculatorDomain) => <ChipField record={{ name: record }} source="name" />}
            />
          </SingleFieldList>
        </ArrayField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
