import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'icon', SxProps<Theme>> = {
  container: {
    a: {
      color: (theme) => theme.palette.blue.dark,
      textDecoration: 'none',
    },
  },
  icon: {
    minWidth: 48,
  },
};
