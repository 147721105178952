import { Input } from 'components/flows/common/InputStep/InputFieldBase';
import {
  LifeInsuranceCalculatorDrivingMileage,
  LifeInsuranceCalculatorEmploymentRisk,
  LifeInsuranceCalculatorFinancialReserveRisk,
  LifeInsuranceCalculatorLiabilitiesSum,
} from 'generated/graphql';
import { TFunction } from 'react-i18next';

import { NestedInput } from '../CalculatorSection/CalculatorSection';
import { LifeInsuranceCalculatorFormValues } from './LifeInsuranceCalculator.schema';

type LifeInsuranceCalculatorSection = {
  inputs:
    | Input<LifeInsuranceCalculatorFormValues[Extract<keyof LifeInsuranceCalculatorFormValues, string>]>[]
    | NestedInput<LifeInsuranceCalculatorFormValues[Extract<keyof LifeInsuranceCalculatorFormValues, string>]>[];
  name: Extract<keyof LifeInsuranceCalculatorFormValues, string>;
  title: string;
};

export const getSections = (
  t: TFunction<'translation', undefined>,
  isAuthenticated: boolean,
): LifeInsuranceCalculatorSection[] => {
  const defaultSections = [
    {
      inputs: [
        {
          inputType: 'radioSelect',
          label: t('flows/lifeInsurance/calculator:inputs.liabilities.sum.label'),
          name: 'sum',
          options: [
            {
              label: t('flows/lifeInsurance/calculator:inputs.liabilities.sum.options.noLiabilities'),
              value: LifeInsuranceCalculatorLiabilitiesSum.NoLiabilities,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.liabilities.sum.options.under_50000Eur'),
              value: LifeInsuranceCalculatorLiabilitiesSum.Under_50000Eur,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.liabilities.sum.options.from_50000To_100000Eur'),
              value: LifeInsuranceCalculatorLiabilitiesSum.From_50000To_100000Eur,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.liabilities.sum.options.from_100000To_150000Eur'),
              value: LifeInsuranceCalculatorLiabilitiesSum.From_100000To_150000Eur,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.liabilities.sum.options.over_150000Eur'),
              value: LifeInsuranceCalculatorLiabilitiesSum.Over_150000Eur,
            },
          ],
        },
      ],
      name: 'liabilities',
      title: t('flows/lifeInsurance/calculator:sections.liabilities.title'),
    },
    {
      inputs: [
        {
          inputType: 'radioSelect',
          label: t('flows/lifeInsurance/calculator:inputs.financialReserve.risk.label'),
          name: 'risk',
          options: [
            {
              label: t('flows/lifeInsurance/calculator:inputs.financialReserve.risk.options.low'),
              value: LifeInsuranceCalculatorFinancialReserveRisk.Low,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.financialReserve.risk.options.medium'),
              value: LifeInsuranceCalculatorFinancialReserveRisk.Medium,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.financialReserve.risk.options.high'),
              value: LifeInsuranceCalculatorFinancialReserveRisk.High,
            },
          ],
        },
      ],
      name: 'financialReserve',
      title: t('flows/lifeInsurance/calculator:sections.financialReserve.title'),
    },
    {
      inputs: [
        {
          inputType: 'radioSelect',
          label: t('flows/lifeInsurance/calculator:inputs.sport.risk.label'),
          name: 'risk',
          options: [
            {
              label: t('common:boolean.true'),
              value: true,
            },
            {
              label: t('common:boolean.false'),
              value: false,
            },
          ],
        },
      ],
      name: 'sport',
      title: t('flows/lifeInsurance/calculator:sections.sport.title'),
    },
    {
      inputs: [
        {
          inputType: 'radioSelect',
          label: t('flows/lifeInsurance/calculator:inputs.employment.risk.label'),
          name: 'risk',
          options: [
            {
              label: t('flows/lifeInsurance/calculator:inputs.employment.risk.options.low'),
              value: LifeInsuranceCalculatorEmploymentRisk.Low,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.employment.risk.options.medium'),
              value: LifeInsuranceCalculatorEmploymentRisk.Medium,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.employment.risk.options.high'),
              value: LifeInsuranceCalculatorEmploymentRisk.High,
            },
          ],
        },
      ],
      name: 'employment',
      title: t('flows/lifeInsurance/calculator:sections.employment.title'),
    },
    {
      inputs: [
        {
          inputType: 'radioSelect',
          label: t('flows/lifeInsurance/calculator:inputs.driving.mileage.label'),
          name: 'mileage',
          options: [
            {
              label: t('flows/lifeInsurance/calculator:inputs.driving.mileage.options.under_5000Km'),
              value: LifeInsuranceCalculatorDrivingMileage.Under_5000Km,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.driving.mileage.options.from_5000To_20000Km'),
              value: LifeInsuranceCalculatorDrivingMileage.From_5000To_20000Km,
            },
            {
              label: t('flows/lifeInsurance/calculator:inputs.driving.mileage.options.over_20000Km'),
              value: LifeInsuranceCalculatorDrivingMileage.Over_20000Km,
            },
          ],
        },
      ],
      name: 'driving',
      title: t('flows/lifeInsurance/calculator:sections.driving.title'),
    },
    {
      inputs: [
        {
          inputType: 'radioSelect',
          label: t('flows/lifeInsurance/calculator:inputs.health.risk.label'),
          name: 'risk',
          options: [
            {
              label: t('common:boolean.true'),
              value: true,
            },
            {
              label: t('common:boolean.false'),
              value: false,
            },
          ],
        },
      ],
      name: 'health',
      title: t('flows/lifeInsurance/calculator:sections.health.title'),
    },
  ] as LifeInsuranceCalculatorSection[];

  const contactSection = !isAuthenticated
    ? [
        {
          inputs: [
            {
              inputType: 'text',
              label: t('flows/common:calculator.inputs.contact.email.label'),
              name: 'email',
              placeholder: t('flows/common:calculator.inputs.contact.email.placeholder'),
            },
            {
              inputType: 'text',
              label: t('flows/common:calculator.inputs.contact.phone.label'),
              name: 'phone',
              placeholder: t('flows/common:calculator.inputs.contact.phone.placeholder'),
            },
          ],
          name: 'contact',
          title: t('flows/lifeInsurance/calculator:sections.contact.title'),
        } as LifeInsuranceCalculatorSection,
      ]
    : [];

  return [...defaultSections, ...contactSection];
};
