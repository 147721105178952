import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { sk } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ProductCardIcon, ProductCardIconProps } from '../ProductCard/ProductCardIcon';
import { styles } from './ProcessPreviewDialog.styles';

export interface MoneyhoonProcessPreviewDialogProps {
  title: string;
  navigatePath: string;
  updateDate?: Date | null;
  progress?: number | null;
  accepted: boolean;
  onRemoveClick: () => void;
  loadingRemove?: boolean;
  color: ProductCardIconProps['color'];
}

export const MoneyhoonProcessPreviewContent: React.FC<MoneyhoonProcessPreviewDialogProps> = ({
  accepted,
  color,
  loadingRemove,
  navigatePath,
  onRemoveClick,
  progress,
  title,
  updateDate,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack>
      <Stack alignItems="center" mb={5} spacing={0.5} textAlign="center">
        <Box mb={0.5}>
          <ProductCardIcon color={color} progress={progress} variant="large" />
        </Box>
        <Typography variant="headlineXL">{title}</Typography>
        <Typography color="textSecondary" variant="bodyM">
          {accepted
            ? t('products:processProductsDetail.subtitle.accepted')
            : t('products:processProductsDetail.subtitle.inProgress')}
        </Typography>
        {!!updateDate && (
          <Typography color="textSecondary" variant="bodyM">
            {t('products:processProductsDetail.updateDate', {
              date: format(updateDate, 'dd. MMMM', { locale: sk }),
            })}
          </Typography>
        )}
      </Stack>

      <Stack spacing={3}>
        <Button onClick={() => navigate(navigatePath)} size="large">
          {accepted
            ? t('products:processProductsDetail.navigate.accepted')
            : t('products:processProductsDetail.navigate.inProgress')}
        </Button>

        {!accepted && (
          <Button
            color="warningButton"
            disabled={loadingRemove}
            onClick={onRemoveClick}
            size="large"
            sx={styles.removeButton}
          >
            {t('products:processProductsDetail.removeButton')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
