import { QueryResult } from '@apollo/client';
import {
  AdminBuildingSavingsProductQuery,
  AdminCollisionInsuranceProductQuery,
  AdminCreditCardProductQuery,
  AdminGenericInsuranceProductQuery,
  AdminGenericProductQuery,
  AdminInvestmentProductQuery,
  AdminLifeInsuranceProductQuery,
  AdminLoanProductQuery,
  AdminMortgageProductQuery,
  AdminMotorLiabilityInsuranceProductQuery,
  AdminPropertyInsuranceProductQuery,
  AdminRetirementPensionSavingsProductQuery,
  AdminSavingsProductQuery,
  AdminSupplementaryPensionSavingsProductQuery,
  FinancialProductType,
  useAdminBuildingSavingsProductQuery,
  useAdminCollisionInsuranceProductQuery,
  useAdminCreditCardProductQuery,
  useAdminGenericInsuranceProductQuery,
  useAdminGenericProductQuery,
  useAdminInvestmentProductQuery,
  useAdminLifeInsuranceProductQuery,
  useAdminLoanProductQuery,
  useAdminMortgageProductQuery,
  useAdminMotorLiabilityInsuranceProductQuery,
  useAdminPropertyInsuranceProductQuery,
  useAdminRetirementPensionSavingsProductQuery,
  useAdminSavingsProductQuery,
  useAdminSupplementaryPensionSavingsProductQuery,
} from 'generated/graphql';

interface Variables {
  id: string;
}

export type AdminProductQuery =
  | QueryResult<AdminBuildingSavingsProductQuery, Variables>
  | QueryResult<AdminCollisionInsuranceProductQuery, Variables>
  | QueryResult<AdminCreditCardProductQuery, Variables>
  | QueryResult<AdminGenericInsuranceProductQuery, Variables>
  | QueryResult<AdminGenericProductQuery, Variables>
  | QueryResult<AdminInvestmentProductQuery, Variables>
  | QueryResult<AdminLifeInsuranceProductQuery, Variables>
  | QueryResult<AdminLoanProductQuery, Variables>
  | QueryResult<AdminMortgageProductQuery, Variables>
  | QueryResult<AdminMotorLiabilityInsuranceProductQuery, Variables>
  | QueryResult<AdminPropertyInsuranceProductQuery, Variables>
  | QueryResult<AdminRetirementPensionSavingsProductQuery, Variables>
  | QueryResult<AdminSavingsProductQuery, Variables>
  | QueryResult<AdminSupplementaryPensionSavingsProductQuery, Variables>;

export const useAdminProductQuery = (type: FinancialProductType, variables: Variables): AdminProductQuery => {
  const query = {
    [FinancialProductType.BuildingSavings]: useAdminBuildingSavingsProductQuery({
      skip: type != FinancialProductType.BuildingSavings,
      variables,
    }),
    [FinancialProductType.CollisionInsurance]: useAdminCollisionInsuranceProductQuery({
      skip: type != FinancialProductType.CollisionInsurance,
      variables,
    }),
    [FinancialProductType.CreditCard]: useAdminCreditCardProductQuery({
      skip: type != FinancialProductType.CreditCard,
      variables,
    }),
    [FinancialProductType.Generic]: useAdminGenericProductQuery({
      skip: type != FinancialProductType.Generic,
      variables,
    }),
    [FinancialProductType.GenericInsurance]: useAdminGenericInsuranceProductQuery({
      skip: type != FinancialProductType.GenericInsurance,
      variables,
    }),
    [FinancialProductType.Investment]: useAdminInvestmentProductQuery({
      skip: type != FinancialProductType.Investment,
      variables,
    }),
    [FinancialProductType.LifeInsurance]: useAdminLifeInsuranceProductQuery({
      skip: type != FinancialProductType.LifeInsurance,
      variables,
    }),
    [FinancialProductType.Loan]: useAdminLoanProductQuery({
      skip: type != FinancialProductType.Loan,
      variables,
    }),
    [FinancialProductType.Mortgage]: useAdminMortgageProductQuery({
      skip: type != FinancialProductType.Mortgage,
      variables,
    }),
    [FinancialProductType.MotorLiabilityInsurance]: useAdminMotorLiabilityInsuranceProductQuery({
      skip: type != FinancialProductType.MotorLiabilityInsurance,
      variables,
    }),
    [FinancialProductType.PropertyInsurance]: useAdminPropertyInsuranceProductQuery({
      skip: type != FinancialProductType.PropertyInsurance,
      variables,
    }),
    [FinancialProductType.RetirementPensionSavings]: useAdminRetirementPensionSavingsProductQuery({
      skip: type != FinancialProductType.RetirementPensionSavings,
      variables,
    }),
    [FinancialProductType.Savings]: useAdminSavingsProductQuery({
      skip: type != FinancialProductType.Savings,
      variables,
    }),
    [FinancialProductType.SupplementaryPensionSavings]: useAdminSupplementaryPensionSavingsProductQuery({
      skip: type != FinancialProductType.SupplementaryPensionSavings,
      variables,
    }),
  }[type];

  return query;
};
