import { DATA_PROTECTION_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@brand/const';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { signUp } from 'common/auth/user-auth';
import { Center } from 'common/components/Center/Center';
import { Image } from 'common/components/Image/Image';
import { StyledCheckbox } from 'common/form/components/FormCheckBox.styles';
import { FormInput } from 'common/form/components/FormInput';
import { FormPasswordInput } from 'common/form/components/FormPasswordInput';
import { emailValidator, passwordValidator, phoneValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { normalizePhoneNumber } from 'common/utils/formatUtils';
import { pushGTMEvent } from 'common/utils/GTMeventUtils';
import { AllExpertsAvatars } from 'components/flows/common/AllExpertsAvatars/AllExpertsAvatars';
import { GTMEventValueEnum } from 'const';
import {
  RequestContactContext,
  useRequestContactAuthenticatedMutation,
  useRequestContactMutation,
} from 'generated/graphql';
import { useContext, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { StyledDialog } from '../StyledDialog/StyledDialog';
import { StyledDialogFormError } from '../StyledDialog/StyledDialogFormError';
import { StyledDialogSubtitle } from '../StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from '../StyledDialog/StyledDialogTitle';
import { styles } from './ContactDialog.styles';

interface FormValues {
  note?: string;
  email?: string;
  password?: string;
  phone?: string;
}

export interface ContactDialogOwnProps {
  context?: RequestContactContext;
  name?: string;
  jobTitle?: string;
  avatarSrc?: string;
  userEmail?: string;
  userPhone?: string;
}

export type ContactDialogProps = DialogComponentProps<void> & ContactDialogOwnProps;

export const ContactDialog: React.FC<ContactDialogProps> = ({
  avatarSrc,
  context,
  isOpen,
  jobTitle,
  name,
  onResolve,
  userEmail,
  userPhone,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated, updateAttributes, userData } = useContext(AuthContext);
  const [wantRegistration, setWantRegistration] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const [showError, setShowError] = useState(false);

  const [requestContact, { loading }] = useRequestContactMutation({
    onCompleted: () => {
      setShowError(false);
      onResolve();
    },
    onError: () => setShowError(true),
  });
  const [requestContactAuthenticated, { loading: loadingAuthenticated }] = useRequestContactAuthenticatedMutation({
    onCompleted: () => {
      setShowError(false);
      onResolve();
    },
    onError: () => setShowError(true),
  });

  const schema = yup.object({
    email: !isAuthenticated && !userEmail ? emailValidator() : yup.string().nullable(true),
    note: yup.string().required(t('form.validator.fieldIsRequired')),
    password: wantRegistration ? passwordValidator() : yup.string().nullable(true),
    phone: !userPhone ? phoneValidator().required(t('form.validator.fieldIsRequired')) : yup.string().nullable(true),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: undefined,
      note: undefined,
      password: undefined,
      phone: userData?.user.attributes.phone_number ?? undefined,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { errors: formErrors } = useFormState({ control: formMethods.control });

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    const email = userEmail ?? values.email;

    if (isAuthenticated) {
      if (values.phone) {
        const normalizedPhone = normalizePhoneNumber(values.phone);
        updateAttributes({ phone_number: normalizedPhone });
      }

      await requestContactAuthenticated({
        variables: {
          context: context ?? RequestContactContext.GenericSupport,
          text: values.note ?? '',
        },
      });
      if (context === RequestContactContext.Investment) {
        pushGTMEvent(GTMEventValueEnum.InvestmentInquiryCompleted, userData?.user.attributes.email ?? '');
      }
    } else {
      if (!email) {
        return;
      }

      if (wantRegistration) {
        if (!values.password) {
          return;
        }

        setSignUpLoading(true);
        const signUpResult = await signUp({ email, password: values.password });
        setSignUpLoading(false);

        if (signUpResult?.['fieldError']) {
          Object.entries(signUpResult.fieldError).forEach(([name, error]) =>
            formMethods.setError(name as keyof FormValues, { message: error.message }),
          );
          return;
        }
      }
      await requestContact({
        variables: {
          context: context ?? RequestContactContext.GenericSupport,
          text: values.note ?? '',
          userInfo: {
            email,
            phoneNumber: userPhone ?? values.phone,
          },
        },
      });

      if (context === RequestContactContext.Investment) {
        pushGTMEvent(GTMEventValueEnum.InvestmentInquiryCompleted, email);
      }
    }
    navigate('/confirm-contact-form', { state: { email, wantRegistration: wantRegistration } });
  });

  const isLoading = loading || loadingAuthenticated || signUpLoading;

  return (
    <FormProvider {...formMethods}>
      <StyledDialog PaperProps={{ sx: styles.dialog }} fullWidth onClose={() => onResolve()} open={isOpen}>
        <Box sx={styles.header}>
          {!!name && !!avatarSrc && !!jobTitle ? (
            <Center sx={styles.expert}>
              <Image mb={1} src={avatarSrc} sx={styles.image} />
              <Box>
                <Typography mr={0.5} variant="bodyStrongS">
                  {name}
                </Typography>
                <Typography color="textSecondary" variant="bodyS">
                  {jobTitle}
                </Typography>
              </Box>
            </Center>
          ) : (
            <AllExpertsAvatars size={40} />
          )}
        </Box>
        <Stack flexDirection="column" sx={styles.content}>
          <StyledDialogTitle size="small">{t('contactDialog.title')}</StyledDialogTitle>
          <StyledDialogSubtitle size="small">
            {name ? t('contactDialog.subtitle', { name: name.split(' ')[0] }) : t('contactDialog.genericSubtitle')}
          </StyledDialogSubtitle>
          <DialogContent>
            {!isAuthenticated && !userEmail && (
              <Box mb={3}>
                <FormInput
                  fullWidth
                  label={t('contactDialog.email.label')}
                  name="email"
                  placeholder={t('contactDialog.email.placeholder')}
                  size="medium"
                  type="email"
                />
              </Box>
            )}
            {(isAuthenticated || !userPhone) && (
              <Box mb={3}>
                <FormInput
                  fullWidth
                  label={t('contactDialog.phone.label')}
                  name="phone"
                  placeholder={t('contactDialog.phone.placeholder')}
                  size="medium"
                  type="tel"
                />
              </Box>
            )}
            <FormInput
              InputProps={{ minRows: 2, multiline: true }}
              fullWidth
              label={t('contactDialog.note.label')}
              name="note"
              placeholder={t('contactDialog.note.placeholder')}
              size="medium"
            />
            {!isAuthenticated && (
              <Box mt={2}>
                <Box sx={styles.checkbox}>
                  <StyledCheckbox onChange={() => setWantRegistration(!wantRegistration)} />
                  <Typography variant="bodyS">{t('contactDialog.registration.checkbox.wantRegistration')}</Typography>
                </Box>
                {wantRegistration && (
                  <Box mt={2}>
                    <FormPasswordInput
                      autoComplete="new-password"
                      label={t('contactDialog.registration.password.label')}
                      name="password"
                      placeholder={t('contactDialog.registration.password.placeholder')}
                      size="medium"
                      sx={styles.passwordInput}
                    />
                    {!formErrors['password'] && (
                      <Stack component="ul" mt={2} sx={styles.passwordRequirementsList}>
                        <Typography component="li" variant="bodyS">
                          {t('contactDialog.registration.password.list.item0')}
                        </Typography>
                        <Typography component="li" variant="bodyS">
                          {t('contactDialog.registration.password.list.item1')}
                        </Typography>
                        <Typography component="li" variant="bodyS">
                          {t('contactDialog.registration.password.list.item2')}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <LoadingButton disabled={isLoading} fullWidth loading={isLoading} onClick={handleSubmit} size="medium">
              {t('contactDialog.submit')}
            </LoadingButton>
          </DialogActions>
          {!isAuthenticated && wantRegistration && (
            <Typography component="div" mt={4} variant="bodyS">
              <Trans i18nKey="contactDialog.registration.termsAndConditions">
                <a href={TERMS_AND_CONDITIONS_URL} target="_blank" />
                <a href={DATA_PROTECTION_POLICY_URL} target="_blank" />
              </Trans>
            </Typography>
          )}
          {showError && (
            <StyledDialogFormError content={t('contactDialog.error.content')} title={t('contactDialog.error.title')} />
          )}
        </Stack>
      </StyledDialog>
    </FormProvider>
  );
};
