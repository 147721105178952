import { isValid, parseISO } from 'date-fns';
import _ from 'lodash';

export const parseDatestrings = (input: unknown): unknown => {
  if (input instanceof Date) {
    return input;
  }

  if (_.isArray(input)) {
    return input.map((item) => parseDatestrings(item));
  }

  if (_.isPlainObject(input)) {
    return _.mapValues(input as object, (value) => parseDatestrings(value));
  }

  if (_.isString(input)) {
    const parsedDate = parseISO(input);
    if (isValid(parsedDate) && parsedDate.toISOString() === input) {
      return parsedDate;
    }
    return input;
  }
  return input;
};
