import { Box, SxProps, Theme, Typography } from '@mui/material';
import { EnvironmentEnum } from 'const';
import { environment, isProductionEnvironment } from 'environment';
import React from 'react';

const CURRENT_ENVIRONMENT = environment.currentEnvironment as EnvironmentEnum;

const ENVIRONMENT_COLOR = {
  [EnvironmentEnum.PROD]: undefined,
  [EnvironmentEnum.STAGING]: 'red',
  [EnvironmentEnum.DEV]: 'orange',
  [EnvironmentEnum.LOCAL]: 'green',
};

const BACKGROUND_COLOR = ENVIRONMENT_COLOR[CURRENT_ENVIRONMENT];

const style: SxProps<Theme> = {
  background: `linear-gradient(135deg,${BACKGROUND_COLOR} 3rem,rgb(0 0 0 / 0%) 0)`,
  height: '4.25rem',
  marginBottom: '-4.25rem',
  paddingLeft: '0.2rem',
  pointerEvents: 'none',
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 20,
};

interface Props {
  children: React.ReactNode;
}

export const EnvironmentIndicatorBar: React.FC<Props> = ({ children }) => {
  return (
    <>
      {!isProductionEnvironment && (
        <Box sx={style}>
          <Typography variant="bodyS">{CURRENT_ENVIRONMENT}</Typography>
        </Box>
      )}
      {children}
    </>
  );
};
