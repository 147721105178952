import { SxProps, Theme } from '@mui/material';

export const styles: Record<'card' | 'name', SxProps<Theme>> = {
  card: {
    borderRadius: '10%',
    position: 'relative',
  },
  name: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '100px',
    bottom: '10%',
    height: '10%',
    left: '50%',
    opacity: 0.6,
    position: 'absolute',
    width: '40%',
  },
};
