import { Expenses, Mapper } from 'components/flows/common/lifeInsurance/state/lifeInsuranceTypes';
import { LifeInsuranceApplicantFragment, LifeInsuranceExpensesInput } from 'generated/graphql';

export const mapExpenses: Mapper<Expenses, LifeInsuranceExpensesInput, LifeInsuranceApplicantFragment['expenses']> = {
  parse: () => undefined,
  serialize: (expenses) => {
    if (!expenses?.amount) {
      return undefined;
    }

    return {
      monthlyClothingExpenses: {
        amount: 0,
        currency: 'EUR',
      },
      monthlyFamilyExpenses: {
        amount: expenses.amount ?? 0,
        currency: 'EUR',
      },
      monthlyGroceriesExpenses: {
        amount: 0,
        currency: 'EUR',
      },
      monthlyInsuranceExpenses: {
        amount: 0,
        currency: 'EUR',
      },
      monthlyTransportExpenses: {
        amount: 0,
        currency: 'EUR',
      },
      monthlyUtilitiesExpenses: {
        amount: 0,
        currency: 'EUR',
      },
    };
  },
};
