import { Stack } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { useState } from 'react';
import { Path } from 'react-hook-form';

import { ButtonRow } from '../ButtonRow/ButtonRow';
import { EditListItemDialog, EditListItemProps } from '../EditListItemDialog/EditListItemDialog';
import { DetailRowType, ExpandedListType } from '../types';
import { styles } from './DetailList.styles';
import { DetailListItem } from './DetailListItem';

interface Props<T> {
  data: T[];
  path: string;
  label: string;
  addLabel: string;
  removeLabel: string;
  formatter: (value: T) => string;
  maxLength?: number;
  fields: Array<{ [K in Path<T>]: DetailRowType<T, K> | ExpandedListType<T, K> }[Path<T>]>;
  onUpdate: <K extends Path<T>>(value: PathValue<T, K> | T[], path: string) => Promise<void>;
  updatedAt?: Date;
}

export const DetailList = <T,>({
  addLabel,
  data,
  fields,
  formatter,
  label,
  maxLength,
  onUpdate,
  path,
  removeLabel,
  updatedAt,
}: Props<T>): React.ReactElement => {
  const [loadingAdd, setLoadingAdd] = useState(false);

  const editListItemDialog = useDialog<EditListItemProps<T>, void, void>(EditListItemDialog, {});

  const handleAdd = async () => {
    // add new empty object to list
    setLoadingAdd(true);
    const newData = [...data];
    newData.splice(data.length, 1, {} as T);
    await onUpdate(newData, path);
    setLoadingAdd(false);

    // and update it in standard update dialog
    const index = newData.length - 1;
    await editListItemDialog.create({
      data: newData[index],
      fields,
      instanceId: `edit-list-item-${path}[${index}]`,
      key: updatedAt?.toISOString(),
      onRemove: handleRemove(index),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onUpdate: (value, fieldPath) => onUpdate(value as any, `${path}[${index}]${fieldPath}`),
      removeLabel,
      title: label,
      tmpNavigable: true,
      updatedAt,
    });
  };

  const handleRemove = (index: number) => () => {
    const newData = [...data];
    newData.splice(index, 1);
    onUpdate(newData, path);
  };

  return (
    <Stack sx={styles.container}>
      {data.map((value, index) => (
        <DetailListItem<T>
          key={index}
          data={value}
          fields={fields}
          formatter={formatter}
          label={label}
          onRemove={handleRemove(index)}
          onUpdate={onUpdate}
          path={`${path}[${index}]`}
          removeLabel={removeLabel}
          updatedAt={updatedAt}
        />
      ))}
      {(!maxLength || data.length < maxLength) && (
        <ButtonRow label={addLabel} loading={loadingAdd} onClick={handleAdd} />
      )}
    </Stack>
  );
};
