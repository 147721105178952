import { yupResolver } from '@hookform/resolvers/yup';
import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InputField } from 'components/flows/common/InputStep/InputField';
import { Input } from 'components/flows/common/InputStep/InputFieldBase';
import _ from 'lodash';
import { DefaultValues, FieldValues, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { styles } from './CalculatorSection.styles';
import { NestedFields } from './NestedFields';

export type NestedInput<T> = T extends object
  ? { name: Extract<keyof T, string>; fields: Input<T[Extract<keyof T, string>]>[]; title: string }
  : never;

const isNestedInput = <T,>(input: Input<T> | NestedInput<T>): input is NestedInput<T> => {
  return 'fields' in input;
};
interface Props<T extends FieldValues> {
  onEdit: () => void;
  title: string;
  isExpanded?: boolean;
  inputs: Input<T>[] | NestedInput<T>[];
  sectionName: string;
  nextButtonLabel: string;
  onSubmit: (data: T) => void;
  schema: yup.SchemaOf<T>;
  defaultValues?: T;
  number: number;
  children: React.ReactNode;
}

export const CalculatorSection = <T extends FieldValues>({
  children,
  defaultValues,
  inputs,
  isExpanded,
  nextButtonLabel,
  number,
  onEdit,
  onSubmit,
  schema,
  sectionName,
  title,
}: Props<T>): React.ReactElement => {
  const { t } = useTranslation();

  const formMethods = useForm<T>({
    defaultValues: defaultValues as DefaultValues<T> | undefined,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema) as Resolver<T>,
    shouldUnregister: true,
  });

  const isFilled = !_.isEmpty(_.omitBy(defaultValues, (value) => value == undefined));

  const color = isExpanded ? 'gray.800' : isFilled ? 'green.800' : 'gray.400';

  const handleSubmit: React.FocusEventHandler<HTMLFormElement> = async (event) => {
    formMethods.handleSubmit((data) => {
      onSubmit(data as T);
      formMethods.reset(data);
    })(event);
  };

  const error = formMethods.formState.errors[sectionName];

  return (
    <FormProvider {...formMethods}>
      <Box<'form'> component="form" noValidate onSubmit={handleSubmit}>
        <Stack spacing={6}>
          <Box id={sectionName} sx={styles.sectionHeader} tabIndex={0}>
            <Typography color={color} sx={styles.title} variant="headlineL">
              {isFilled && !isExpanded ? (
                <CheckCircle />
              ) : (
                <Box bgcolor={color} sx={styles.number}>
                  {number}
                </Box>
              )}
              {title}
            </Typography>

            <Box sx={styles.controls}>
              {!isExpanded && isFilled && (
                <Button color="secondaryButton" onClick={onEdit}>
                  {t('flows/common:calculator.section.edit')}
                </Button>
              )}
            </Box>
          </Box>

          {isExpanded &&
            inputs.map((input, inputIndex) => {
              if (isNestedInput<T>(input)) {
                return (
                  <NestedFields
                    key={input.name}
                    basePath={input.name}
                    defaultExpanded={!!defaultValues?.[input.name]}
                    fields={input.fields}
                    title={input.title}
                  />
                );
              }
              return <InputField key={input.name} autoFocus={inputIndex === 0} field={input} sx={styles.input} />;
            })}

          {isExpanded && (
            <Stack>
              <Button type="submit">{nextButtonLabel}</Button>
              {!!error && (
                <FormHelperText error size="large">
                  {error.message as string}
                </FormHelperText>
              )}
              {children}
            </Stack>
          )}
        </Stack>
      </Box>
    </FormProvider>
  );
};
