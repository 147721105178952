import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'label', SxProps<Theme>> = {
  container: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    background: (theme) => theme.palette.gray[50],
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
  label: {
    color: (theme) => theme.palette.gray[600],
  },
};
