import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { getWeek, getYear, startOfWeek, subWeeks } from 'date-fns';
import { sk } from 'date-fns/locale';
import { Client } from 'generated/graphql';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const WEEKS_COUNT = 30;

interface WeekItem {
  week: string;
  label: string;
  clients: number;
  deletedClients: number;
  contacts: number;
}

interface Props {
  isLoading: boolean;
  clients?: Client[];
}

export const ClientsContactsChart: React.FC<Props> = ({ clients, isLoading }) => {
  const weeks = [] as WeekItem[];
  for (let i = WEEKS_COUNT; i >= 0; i--) {
    const week = startOfWeek(subWeeks(new Date(), i), { locale: sk });
    weeks.push({
      clients: 0,
      contacts: 0,
      deletedClients: 0,
      label: getWeek(week, { locale: sk }).toString() + '/' + getYear(week).toString(),
      week: week.toISOString(),
    });
  }

  clients?.forEach((client) => {
    // client or contact created
    const weekIndexCreated = weeks.findIndex(
      (weekItem) => weekItem.week == startOfWeek(client.creationTimestamp, { locale: sk }).toISOString(),
    );
    if (weekIndexCreated > -1 && client.user) {
      client.user.subject ? weeks[weekIndexCreated].clients++ : weeks[weekIndexCreated].contacts++;
    }
    // client deleted
    const weekIndexDeleted = weeks.findIndex(
      (weekItem) => weekItem.week == startOfWeek(client.updateTimestamp, { locale: sk }).toISOString(),
    );
    if (weekIndexDeleted > -1 && !client.user) {
      weeks[weekIndexDeleted].deletedClients--;
    }
  });

  return isLoading ? (
    <Skeleton height={404} variant="rectangular" />
  ) : (
    <Card>
      <CardHeader title="Klienti a kontakty" />
      <CardContent>
        <ResponsiveContainer minHeight={300}>
          <BarChart
            data={weeks}
            margin={{
              bottom: 30,
              left: -25,
              right: 25,
              top: -25,
            }}
            stackOffset="sign"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis angle={45} dataKey="label" textAnchor="begin" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" />
            <Bar dataKey="clients" fill="#2196F3" name="Klienti" stackId="a" />
            <Bar dataKey="deletedClients" fill="#ED4435" name="Vymazaní klienti" stackId="a" />
            <Bar dataKey="contacts" fill="#0D991C" name="Kontakty" stackId="a" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
