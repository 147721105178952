import { SxProps, Theme } from '@mui/material';

import { RESPONSIVE_SIDE_DIALOG_WIDTH } from '../ResponsiveSideDialog/ResponsiveSideDialog.styles';

const WIDTH = 336;

export const styles: Record<'content' | 'dialog' | 'button', SxProps<Theme>> = {
  button: {
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
  content: {
    padding: (theme) => theme.spacing(4, 3, 3, 3),
  },
  dialog: {
    [`@media screen and (min-width: ${RESPONSIVE_SIDE_DIALOG_WIDTH}px)`]: {
      right: (RESPONSIVE_SIDE_DIALOG_WIDTH - WIDTH) / 2,
    },
    backgroundColor: (theme) => theme.palette.gray[100],
    border: (theme) => `1px solid ${theme.palette.gray[400]}`,
    borderRadius: '12px',
    margin: 0,
    maxWidth: '100%',
    position: 'fixed',
    width: WIDTH,
  },
};
