import { Box } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { isSameMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { palette } from 'themes/theme';

import { CashflowGuardianAggregateListItem } from '../CashflowGuardianAggregateListItem/CashflowGuardianAggregateListItem';
import { RealizedExpensesDialog, RealizedExpensesDialogProps } from '../RealizedExpensesDialog/RealizedExpensesDialog';
import { UpcomingExpensesDialog, UpcomingExpensesDialogProps } from '../UpcomingExpensesDialog/UpcomingExpensesDialog';
import { CashflowState } from '../useCashflowState';
import { getColorForLeft2Spend } from '../utils';
import { styles } from './CashflowGuardianAggregateList.styles';

export interface CashflowGuardianAggregateListProps {
  cashflowState: CashflowState;
  asOfDate: Date;
}

export const CashflowGuardianAggregateList: React.FC<CashflowGuardianAggregateListProps> = ({
  asOfDate,
  cashflowState,
}) => {
  const { t } = useTranslation();
  const color = getColorForLeft2Spend(cashflowState?.leftToSpend.amount, cashflowState?.budget?.amount);

  const realizedExpensesDialog = useDialog<RealizedExpensesDialogProps, void>(RealizedExpensesDialog, {
    asOfDate,
    instanceId: 'realized-expenses',
    navigable: true,
  });
  const handleRealizedExpenses = () => {
    realizedExpensesDialog.create({ asOfDate });
  };

  const upcomingExpensesDialog = useDialog<UpcomingExpensesDialogProps, void>(UpcomingExpensesDialog, {
    instanceId: 'upcoming-expenses',
    navigable: true,
  });
  const handleUpcomingExpenses = () => {
    upcomingExpensesDialog.create({});
  };

  const isCurrentMonth = isSameMonth(asOfDate, new Date());

  const aggregates = [
    {
      color: color[800],
      label: t('cashflowGuardian.aggregates.realizedExpenses'),
      onClick: handleRealizedExpenses,
      sum: cashflowState.sumOfRealizedExpenses,
    },
    isCurrentMonth && {
      color: palette.gray[200],
      label: t('cashflowGuardian.aggregates.upcomingPayments'),
      onClick: handleUpcomingExpenses,
      sum: cashflowState.sumOfUpcomingExpenses,
    },
  ];

  return (
    <Box sx={styles.container}>
      {aggregates.map(
        (aggregate) =>
          aggregate && (
            <Box key={aggregate.label} sx={styles.row}>
              <CashflowGuardianAggregateListItem aggregate={aggregate} onClick={aggregate.onClick} />
            </Box>
          ),
      )}
    </Box>
  );
};
