import { parseDatestrings } from 'common/utils/parseDatestrings';
import {
  useCreateMortgagePurchaseProcessMutation,
  useSendMortgagePurchaseNotificationToSpecialistMutation,
  useUpdateMortgagePurchaseProcessMutation,
} from 'generated/graphql';
import _ from 'lodash';

import { mapPurchaseStateToInput } from './purchaseState';
import { MortgagePurchaseState } from './purchaseTypes';
import { PURCHASE_STATE_LOCAL_STORAGE_KEY, useMortgagePurchaseState } from './useMortgagePurchaseState';

export const useSaveLocalPurchaseState = (): (() => Promise<string | undefined>) => {
  const [startProcess] = useCreateMortgagePurchaseProcessMutation({
    refetchQueries: ['AllProcesses'],
  });
  const [updateProcess] = useUpdateMortgagePurchaseProcessMutation({
    refetchQueries: ['AllMortgagePurchaseProcesses'],
  });
  const [sendNotification] = useSendMortgagePurchaseNotificationToSpecialistMutation();
  const { clear } = useMortgagePurchaseState();

  const save = async () => {
    const rawState = localStorage.getItem(PURCHASE_STATE_LOCAL_STORAGE_KEY);
    const parsedState = rawState ? JSON.parse(rawState) : undefined;

    if (!_.isEmpty(parsedState)) {
      const stateWithDates = parseDatestrings(parsedState) as MortgagePurchaseState;
      try {
        const processResp = await startProcess();
        const id = processResp.data?.createMortgagePurchaseProcess?.id;
        if (id) {
          await updateProcess({
            variables: {
              id,
              inquiry: mapPurchaseStateToInput(stateWithDates),
            },
          }).then(() => {
            sendNotification({ variables: { processId: id } });
            clear();
          });
        }
        return id;
      } catch (e) {
        //pass
      }
    }
  };

  return save;
};
