import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useDeleteCalendarEventMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export interface DeleteEventDialogProps extends DialogComponentProps<boolean> {
  id: string;
}

export const DeleteEventDialog: React.FC<DeleteEventDialogProps> = ({ id, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [deleteEvent, { loading }] = useDeleteCalendarEventMutation({
    onCompleted: (resp) => {
      if (resp.deleteEvent) {
        onResolve(true);
      }
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
      onResolve(false);
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const handleDelete = async () => {
    if (!loading) {
      await deleteEvent({ variables: { id } });
    }
  };

  return (
    <PromptDialogOnSide
      onClose={() => onResolve(false)}
      open={isOpen}
      subtitle={t('products:financialCalendar.deleteEvent.dialog.subtitle')}
      title={t('products:financialCalendar.deleteEvent.dialog.title')}
    >
      <Stack spacing={1}>
        <LoadingButton color="tertiaryButton" loading={loading} onClick={handleDelete} sx={styles.button}>
          <Typography color="red.dark" variant="bodyL">
            {t('products:financialCalendar.deleteEvent.dialog.button')}
          </Typography>
        </LoadingButton>
        <Button color="tertiaryButton" onClick={() => onResolve(false)} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {t('common:cancel')}
          </Typography>
        </Button>
      </Stack>
    </PromptDialogOnSide>
  );
};
