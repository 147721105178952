import { Box, Button, Dialog, Typography } from '@mui/material';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { styles } from './CookiesConsentDialog.styles';
import { SET_COOKIE_OPTIONS } from './CookiesDialog.const';
import { CookiesSettingsDialog, CookiesSettingsDialogProps } from './CookiesSettingsDialog';

export type CookiesConsentDialogProps = DialogComponentProps<void>;

export const CookiesConsentDialog: React.FC<CookiesConsentDialogProps> = ({ isOpen, onResolve }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [, setCookie] = useCookies(['cookielawinfo-checkbox-necessary', 'cookielawinfo-checkbox-others']);

  const settingsDialog = useDialog<CookiesSettingsDialogProps, void>(CookiesSettingsDialog, {
    instanceId: 'cookies-settings',
  });

  const handleSettingsClick = () => {
    settingsDialog.create({});
    onResolve();
  };

  const handleAgreeClick = () => {
    setCookie('cookielawinfo-checkbox-necessary', true, SET_COOKIE_OPTIONS);
    setCookie('cookielawinfo-checkbox-others', true, SET_COOKIE_OPTIONS);
    onResolve();
  };

  const blockContent = pathname !== '/';

  return (
    <Dialog
      PaperProps={{ sx: styles.dialog }}
      disableEnforceFocus={!blockContent}
      disableScrollLock={!blockContent}
      hideBackdrop={!blockContent}
      open={isOpen}
      sx={styles.root}
    >
      <Typography variant="body2">{t('cookiesConsentDialog.text')}</Typography>
      <Box display="flex" gap={2} mt={3}>
        <Button color="secondaryButton" onClick={handleSettingsClick} size="large">
          {t('cookiesConsentDialog.settings')}
        </Button>
        <Button onClick={handleAgreeClick} size="large">
          {t('cookiesConsentDialog.agree')}
        </Button>
      </Box>
    </Dialog>
  );
};
