import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { usePeriodicityOptions } from 'common/hooks/usePeriodicityOptions';
import { formatIban, formatTransactionAmount, normalizeIban } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import {
  FinancialProductType,
  GenericProductFragment,
  useGenericProductQuery,
  useUpdateGenericProductMutation,
} from 'generated/graphql';
import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductDetail } from '../ProductDetail/ProductDetail';
import { QRCodeDialog, QRCodeDialogProps } from '../QRCodeDialog/QRCodeDialog';
import { QRCodeMissingDataDialog, QRCodeMissingDataDialogProps } from '../QRCodeDialog/QRCodeMissingDataDialog';
import { ButtonRowType, DetailRowType, Section } from '../types';
import { ProductDetailDialogProps } from '../useProductDetail';
import { mapGenericProductToInput } from './utils';

export const GenericProductDetail: React.FC<ProductDetailDialogProps> = ({ id, ...props }) => {
  const { i18n, t } = useTranslation();
  const notify = useNotification();

  const { data } = useGenericProductQuery({
    variables: {
      id,
    },
  });

  const qrCodeDialog = useDialog<QRCodeDialogProps, void>(QRCodeDialog, {
    instanceId: 'qr-code-dialog',
    tmpNavigable: true,
  });

  const qrCodeMissingDataDialog = useDialog<QRCodeMissingDataDialogProps, void>(QRCodeMissingDataDialog, {
    instanceId: 'qr-code-missing-data-dialog',
  });

  const handleQRPayment = () => {
    const iban = data?.detail.paymentInfo?.ibanForPayment;
    const amount = data?.detail.paymentPeriod?.expectedAmount?.amount;
    if (iban && amount) {
      qrCodeDialog.create({
        amount,
        constantSymbol: data?.detail.paymentInfo?.constantSymbol ?? undefined,
        currency: data?.detail.paymentPeriod?.expectedAmount?.currency ?? 'EUR',
        iban,
        specificSymbol: data?.detail.paymentInfo?.specificSymbol ?? undefined,
        variableSymbol: data?.detail.paymentInfo?.variableSymbol ?? undefined,
      });
    } else {
      qrCodeMissingDataDialog.create({});
    }
  };

  const periodicityOptions = usePeriodicityOptions();

  const content: Section<GenericProductFragment, Path<GenericProductFragment>>[] = [
    {
      rows: [
        {
          inputType: 'text',
          label: t('products:generic.detail.institutionName'),
          path: 'institutionName',
          skip: !!data?.detail.institution?.id,
        } as DetailRowType<GenericProductFragment, 'institutionName'>,
        {
          inputType: 'text',
          label: t('products:generic.detail.name'),
          path: 'name',
        } as DetailRowType<GenericProductFragment, 'name'>,
        {
          inputType: 'text',
          label: t('products:generic.detail.contractNumber'),
          path: 'contractNumber',
        } as DetailRowType<GenericProductFragment, 'contractNumber'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:generic.detail.contractSignDate'),
          path: 'contractSignDate',
        } as DetailRowType<GenericProductFragment, 'contractSignDate'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:generic.detail.contractEndDate'),
          path: 'contractEndDate',
        } as DetailRowType<GenericProductFragment, 'contractEndDate'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          formatter: (value: number) =>
            formatTransactionAmount(value, i18n.language, 2, data?.detail.paymentPeriod?.expectedAmount?.currency),
          inputEndAdornment: data?.detail.paymentPeriod?.expectedAmount?.currency,
          inputType: 'amount',
          label: t('products:generic.detail.instalment'),
          // we are storing paymentPeriod.expectedAmount.amount as negative number
          // but we want user to type a positive equivalent
          parse: (value) => -value,
          path: 'paymentPeriod.expectedAmount.amount',
          transform: (value) => -value,
        } as DetailRowType<GenericProductFragment, 'paymentPeriod.expectedAmount.amount'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:generic.detail.nextPayment'),
          path: 'paymentPeriod.expectedDate',
        } as DetailRowType<GenericProductFragment, 'paymentPeriod.expectedDate'>,
        {
          formatter: (value) =>
            periodicityOptions.find(
              (option) =>
                option.value.periodCount === value.periodCount && option.value.periodTypeCode === value.periodTypeCode,
            )?.label ?? '',
          inputType: 'radioSelect',
          label: t('products:generic.detail.periodType'),
          options: periodicityOptions,
          parse: (value) => ({
            expectedAmount: data?.detail.paymentPeriod?.expectedAmount,
            expectedDate: data?.detail.paymentPeriod?.expectedDate,
            periodCount: value.periodCount,
            periodTypeCode: value.periodTypeCode,
          }),
          path: 'paymentPeriod',
          transform: (value) => ({
            periodCount: value.periodCount,
            periodTypeCode: value.periodTypeCode,
          }),
        } as DetailRowType<GenericProductFragment, 'paymentPeriod'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          inputType: 'variableSymbol',
          label: t('products:generic.detail.variableSymbol'),
          path: 'paymentInfo.variableSymbol',
        } as DetailRowType<GenericProductFragment, 'paymentInfo.variableSymbol'>,
        {
          inputType: 'specificSymbol',
          label: t('products:generic.detail.specificSymbol'),
          path: 'paymentInfo.specificSymbol',
        } as DetailRowType<GenericProductFragment, 'paymentInfo.specificSymbol'>,
        {
          inputType: 'constantSymbol',
          label: t('products:generic.detail.constantSymbol'),
          path: 'paymentInfo.constantSymbol',
        } as DetailRowType<GenericProductFragment, 'paymentInfo.constantSymbol'>,
        {
          formatter: formatIban,
          inputType: 'iban',
          label: t('products:generic.detail.ibanForPayment'),
          parse: normalizeIban,
          path: 'paymentInfo.ibanForPayment',
        } as DetailRowType<GenericProductFragment, 'paymentInfo.ibanForPayment'>,
        {
          color: 'blue.dark',
          label: t('products:generic.detail.qrCode'),
          onClick: handleQRPayment,
        } as ButtonRowType,
      ],
      title: t('products:generic.detail.paymentInfo.title'),
    },
  ];

  const [updateProduct] = useUpdateGenericProductMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const handleUpdate = async (value: GenericProductFragment) => {
    await updateProduct({ variables: { id, updatedProduct: mapGenericProductToInput(value) } });
  };

  return (
    <ProductDetail<GenericProductFragment>
      {...props}
      content={content}
      data={data?.detail}
      institution={data?.detail.institution ?? undefined}
      onUpdate={handleUpdate}
      title={data?.detail.name ?? t('products:generic.detail.title')}
      type={FinancialProductType.Generic}
    />
  );
};
