import { Route } from 'react-router-dom';
import { SentryRoutes } from 'sentry';

import { MortgageCalculatorInputPage } from './MortgageCalculatorInputPage';
import { MortgageCalculatorResultPage } from './MortgageCalculatorResultPage';

type Props = Record<string, never>;

export const MortgageCalculatorRouter: React.FC<Props> = () => {
  return (
    <SentryRoutes>
      <Route key="landing" element={<MortgageCalculatorInputPage />} path="/" />
      <Route element={<MortgageCalculatorResultPage />} path="/result" />
    </SentryRoutes>
  );
};

// eslint-disable-next-line import/no-default-export
export default MortgageCalculatorRouter;
