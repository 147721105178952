import { FilterOperator } from 'generated/graphql';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

interface Props {
  source: string;
  disabled?: boolean;
}

const filterToQuery = (searchText: string) => ({
  filterCondition: {
    fieldPath: 'name',
    operator: FilterOperator.Ilike,
    values: [`%${searchText}%`],
  },
});

export const InstitutionInput: React.FC<Props> = ({ disabled, source }) => {
  return (
    <ReferenceInput reference="Institution" source={source}>
      <AutocompleteInput
        disabled={disabled}
        filterToQuery={filterToQuery}
        label="Inštitúcia"
        optionText="name"
        sx={{ width: '40em' }}
      />
    </ReferenceInput>
  );
};
