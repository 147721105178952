import { useAdminProductQuery } from 'admin/products/useAdminProductQuery';
import { ContentDispositionType, useFiles } from 'common/hooks/useFiles';
import { FileStatusEnum, UploadedFile } from 'common/hooks/useFilesUpload';
import { ChangeEvent, MouseEventHandler, useEffect } from 'react';

import { FinancialProductType } from '../../generated/graphql';

export const useAdminProductFiles = (
  documentId: string,
  productId: string,
  type: FinancialProductType,
): {
  files: UploadedFile[];
  handleDeleteClick: (fileId: string, status: FileStatusEnum) => Promise<void>;
  handleDownloadClick: (
    file: UploadedFile,
    contentDisposition: ContentDispositionType,
    windowReference: Window | null,
  ) => Promise<void>;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleRetryClick: (fileId: string) => Promise<void>;
  handleInputClick: MouseEventHandler<HTMLInputElement>;
} => {
  const useFilesHook = useFiles(documentId);

  const { data, loading, refetch } = useAdminProductQuery(type, {
    id: productId,
  });

  useEffect(() => {
    const files =
      data?.detail.documents
        ?.find((d) => d.id == documentId)
        ?.files?.map((file) => ({
          id: file.id,
          name: file.name ?? '',
          status: FileStatusEnum.success,
        })) ?? [];
    useFilesHook.setFiles(files);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [documentId, loading]);

  const fileIds = useFilesHook.files.map((f) => f.id).join(',');

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fileIds]);

  return useFilesHook;
};
