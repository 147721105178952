import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { FinancialProductType } from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useDeleteProduct } from '../useDeleteProduct';

export interface DeleteProductWaitingForReviewDialogProps extends DialogComponentProps<boolean> {
  id: string;
  type: FinancialProductType;
}

export const DeleteProductWaitingForReviewDialog: React.FC<DeleteProductWaitingForReviewDialogProps> = ({
  id,
  isOpen,
  onResolve,
  type,
}) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [deleteProduct, { loading }] = useDeleteProduct(type);

  const handleDelete = async () => {
    if (!loading) {
      await deleteProduct({
        onCompleted: (resp) => {
          if (resp?.deleteProduct) {
            onResolve(true);
          }
        },
        onError: () => {
          notify({ message: t('common:somethingWentWrong'), type: 'error' });
          onResolve(false);
        },
        update: (cache, resp) => {
          if (resp.data?.deleteProduct) {
            cache.evict({
              id: cache.identify({ __typename: `${_.capitalize(type)}Product`, id }),
            });
          }
        },
        variables: {
          id,
        },
      });
    }
  };

  return (
    <PromptDialogOnSide
      onClose={() => onResolve(false)}
      open={isOpen}
      title={t('products:deleteProductWaitingForReview.title')}
    >
      <Stack spacing={1}>
        <LoadingButton color="tertiaryButton" loading={loading} onClick={handleDelete} sx={styles.button}>
          <Typography color="red.dark" variant="bodyL">
            {t('products:deleteProductWaitingForReview.button')}
          </Typography>
        </LoadingButton>
        <Button color="tertiaryButton" onClick={() => onResolve(false)} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {t('common:cancel')}
          </Typography>
        </Button>
      </Stack>
    </PromptDialogOnSide>
  );
};
