import { homeTheme } from '@brand/themes/homeTheme';
import { Box, Dialog, DialogProps, Stack, Theme, ThemeProvider, Typography } from '@mui/material';
import _ from 'lodash';
import { ReactNode, useCallback } from 'react';

import { styles } from './PromptDialogOnSide.styles';

interface PromptDialogOnSideProps extends DialogProps {
  children?: ReactNode;
  title: string;
  subtitle?: string;
}

export const PromptDialogOnSide: React.FC<PromptDialogOnSideProps> = ({ children, onClose, open, subtitle, title }) => {
  const newTheme = useCallback((theme: Theme) => {
    return _.merge(_.cloneDeep(theme), homeTheme);
  }, []);

  return (
    <ThemeProvider theme={newTheme}>
      <Dialog PaperProps={{ sx: styles.dialog }} disableEnforceFocus={true} maxWidth="xs" onClose={onClose} open={open}>
        <Box sx={styles.content}>
          <Stack alignItems="center" mb={3} spacing={1}>
            <Typography textAlign="center" variant="headlineS">
              {title}
            </Typography>
            {subtitle && (
              <Typography textAlign="center" variant="bodyM">
                {subtitle}
              </Typography>
            )}
          </Stack>

          {children}
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
