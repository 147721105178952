import { Loading } from 'common/components/Loading/Loading';

import { BasicLayout } from '../common/layout/onboarding/BasicLayout';

export const LogoutPage: React.FC = () => {
  return (
    <BasicLayout contentCenter>
      <Loading messages={[]} />
    </BasicLayout>
  );
};
