import { useApolloClient } from '@apollo/client';
import { WEB_URL } from '@brand/const';
import { Box, ClickAwayListener, Divider, Popper, PopperProps, Stack, Typography } from '@mui/material';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isProductionLikeEnvironment } from '../../../environment';
import { styles } from './UserHeaderMenuPopper.styles';

interface UserHeaderMenuPopper {
  open: boolean;
  onClose: () => void;
  anchorElement: PopperProps['anchorEl'];
}

export const UserHeaderMenuPopper: React.FC<UserHeaderMenuPopper> = ({ anchorElement, onClose, open }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const { signOut, userData } = useContext(AuthContext);

  const handleLogout = async () => {
    if (isProductionLikeEnvironment) {
      await signOut(WEB_URL);
    } else {
      client.resetStore();
      signOut('/');
    }
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  return (
    <Popper anchorEl={anchorElement} disablePortal open={open} placement="bottom-end">
      <ClickAwayListener onClickAway={onClose}>
        <Box sx={styles.menuWrapper}>
          <Typography variant="bodyStrongS">{userData?.user.attributes.email ?? '-'}</Typography>
          <Divider sx={styles.divider} />
          <Stack>
            <Box onClick={handleSettings} sx={styles.button}>
              <Typography variant="bodyS">{t('userHeaderMenu.settingsButton')}</Typography>
            </Box>
            <Box onClick={handleLogout} sx={styles.button}>
              <Typography variant="bodyS">{t('userHeaderMenu.logoutButton')}</Typography>
            </Box>
          </Stack>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};
