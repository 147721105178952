import { homeTheme } from '@brand/themes/homeTheme';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Theme, ThemeProvider, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg';
import _ from 'lodash';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { styles } from './ResponsiveSideDialog.styles';

export interface ResponsiveSideDialogProps {
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  dialogId: string;
  header?: ReactNode;
}

export const ResponsiveSideDialog: React.FC<ResponsiveSideDialogProps> = ({
  children,
  dialogId,
  header,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const dialogsInUrl = Array.from(searchParams.entries()).filter(
    (entry) => entry[1] === 'open' || entry[1] === 'tmp-open',
  );
  const index = dialogsInUrl.findIndex((entry) => entry[0] === dialogId);

  const hasPrevious = index && index > 0 ? dialogsInUrl[index - 1][0] : false;

  const newTheme = useCallback((theme: Theme) => {
    return _.merge(_.cloneDeep(theme), homeTheme);
  }, []);

  return (
    <ThemeProvider theme={newTheme}>
      <Dialog PaperProps={{ sx: styles.dialog }} disableEnforceFocus={true} onClose={() => onClose()} open={isOpen}>
        <Box sx={styles.header}>
          {hasPrevious ? (
            <Button
              aria-label={t('common:back')}
              color="tertiaryButton"
              onClick={() => onClose()}
              startIcon={<ChevronLeft />}
              sx={styles.backButton}
            >
              <Typography color="blue.dark" variant="bodyL">
                {t('common:back')}
              </Typography>
            </Button>
          ) : (
            <IconButton
              aria-label={t('common:close')}
              color="secondaryButton"
              onClick={() => onClose()}
              size="medium"
              sx={styles.closeButton}
            >
              <CloseIcon />
            </IconButton>
          )}
          {header}
        </Box>
        <Box sx={styles.content}>{children}</Box>
      </Dialog>
    </ThemeProvider>
  );
};
