import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'loader', SxProps<Theme>> = {
  container: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    padding: (theme) => theme.spacing(3, 4),
    width: '100%',
  },
  loader: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: (theme) => theme.palette.blue.dark,
    },
  },
};
