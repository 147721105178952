import { Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactComponent as BuildingSavingsIcon } from 'assets/icons/flow-switcher/BuildingSavings.svg';
import { ReactComponent as CollisionInsuranceIcon } from 'assets/icons/flow-switcher/CollisionInsurance.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/flow-switcher/CreditCard.svg';
import { ReactComponent as GenericIcon } from 'assets/icons/flow-switcher/Generic.svg';
import { ReactComponent as GenericInsuranceIcon } from 'assets/icons/flow-switcher/GenericInsurance.svg';
import { ReactComponent as InvestmentIcon } from 'assets/icons/flow-switcher/Investment.svg';
import { ReactComponent as LifeInsurancePurchaseIcon } from 'assets/icons/flow-switcher/LifeInsurancePurchase.svg';
import { ReactComponent as LoanIcon } from 'assets/icons/flow-switcher/Loan.svg';
import { ReactComponent as MortgagePurchaseIcon } from 'assets/icons/flow-switcher/MortgagePurchase.svg';
import { ReactComponent as MotorLiabilityInsuranceIcon } from 'assets/icons/flow-switcher/MotorLiabilityInsurance.svg';
import { ReactComponent as PropertyInsuranceIcon } from 'assets/icons/flow-switcher/PropertyInsurance.svg';
import { ReactComponent as RetirementPensionSavingsIcon } from 'assets/icons/flow-switcher/RetirementPensionSavings.svg';
import { ReactComponent as SavingsIcon } from 'assets/icons/flow-switcher/Savings.svg';
import { ReactComponent as SupplementaryPensionSavingsIcon } from 'assets/icons/flow-switcher/SupplementaryPensionSavings.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { isProductionEnvironment } from 'environment';
import { FinancialProductType } from 'generated/graphql';
import { createElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FinancialProductTypeTranslations } from 'translations/enums';

import { SelectProviderDialog, SelectProviderDialogProps } from '../SelectProviderDialog/SelectProviderDialog';
import { ProductBaseExtended } from '../types';
import { styles } from './SelectProductTypeDialog.styles';

const ICONS = {
  [FinancialProductType.BuildingSavings]: BuildingSavingsIcon,
  [FinancialProductType.CollisionInsurance]: CollisionInsuranceIcon,
  [FinancialProductType.CreditCard]: CreditCardIcon,
  [FinancialProductType.Generic]: GenericIcon,
  [FinancialProductType.GenericInsurance]: GenericInsuranceIcon,
  [FinancialProductType.Investment]: InvestmentIcon,
  [FinancialProductType.LifeInsurance]: LifeInsurancePurchaseIcon,
  [FinancialProductType.Loan]: LoanIcon,
  [FinancialProductType.Mortgage]: MortgagePurchaseIcon,
  [FinancialProductType.MotorLiabilityInsurance]: MotorLiabilityInsuranceIcon,
  [FinancialProductType.PropertyInsurance]: PropertyInsuranceIcon,
  [FinancialProductType.RetirementPensionSavings]: RetirementPensionSavingsIcon,
  [FinancialProductType.Savings]: SavingsIcon,
  [FinancialProductType.SupplementaryPensionSavings]: SupplementaryPensionSavingsIcon,
};

const NEW_PRODUCT_TYPE_SEARCH_PARAM = 'newProductType';

export interface SelectProductTypeDialogProps extends DialogComponentProps<void> {
  // we use this dialog either for creating new product (undefined product)
  // or for updating product type of existing product
  product?: ProductBaseExtended;
}

const SelectProductTypeDialogContent: React.FC<SelectProductTypeDialogProps> = ({ product }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectProviderDialog = useDialog<SelectProviderDialogProps, void>(SelectProviderDialog, {
    instanceId: 'select-provider-dialog',
    tmpNavigable: true,
  });

  const newProductType = searchParams.get(NEW_PRODUCT_TYPE_SEARCH_PARAM) as FinancialProductType;
  if (newProductType !== null) {
    searchParams.delete(NEW_PRODUCT_TYPE_SEARCH_PARAM);
    setSearchParams(searchParams);
  }
  const productId = product?.id;

  useEffect(() => {
    if (newProductType !== null && Object.values(FinancialProductType).includes(newProductType)) {
      selectProviderDialog.create({ newProductType, product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProductType, productId]);

  const handleSelect = async (type: FinancialProductType) => {
    await selectProviderDialog.create({ newProductType: type, product });
  };

  // specific order of products
  // some products are not yet used in production env
  const options = (
    isProductionEnvironment
      ? [
          FinancialProductType.Mortgage,
          FinancialProductType.LifeInsurance,
          FinancialProductType.MotorLiabilityInsurance,
          FinancialProductType.CollisionInsurance,
          FinancialProductType.PropertyInsurance,
          FinancialProductType.Investment,
          FinancialProductType.Generic,
        ]
      : [
          FinancialProductType.Mortgage,
          FinancialProductType.Loan,
          FinancialProductType.CreditCard,
          FinancialProductType.LifeInsurance,
          FinancialProductType.MotorLiabilityInsurance,
          FinancialProductType.CollisionInsurance,
          FinancialProductType.PropertyInsurance,
          FinancialProductType.GenericInsurance,
          FinancialProductType.Investment,
          FinancialProductType.RetirementPensionSavings,
          FinancialProductType.SupplementaryPensionSavings,
          FinancialProductType.BuildingSavings,
          FinancialProductType.Savings,
          FinancialProductType.Generic,
        ]
  ).map((type) => ({
    label: FinancialProductTypeTranslations(t)[type],
    value: type,
  }));

  return (
    <Stack>
      {product ? (
        <Stack alignItems="center" spacing={0.5}>
          <Typography textAlign="center" variant="headlineXL">
            {t('products:selectProductType.updateProductType.title')}
          </Typography>
        </Stack>
      ) : (
        <Stack alignItems="center" spacing={0.5}>
          <Typography textAlign="center" variant="headlineXL">
            {t('products:selectProductType.createProduct.title')}
          </Typography>
          <Typography color="textSecondary" textAlign="center" variant="bodyM">
            {t('products:selectProductType.createProduct.subtitle')}
          </Typography>
        </Stack>
      )}

      <Stack sx={styles.container}>
        {options.map((option) => (
          <Stack
            key={option.value}
            direction="row"
            justifyContent="space-between"
            onClick={() => handleSelect(option.value)}
            sx={styles.row}
          >
            <Stack alignItems="center" direction="row" gap={2}>
              {createElement(ICONS[option.value], { height: '24px', width: '24px' })}
              <Typography variant="bodyM">{option.label}</Typography>
            </Stack>
            <ChevronRightIcon />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export const SelectProductTypeDialog: React.FC<SelectProductTypeDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <SelectProductTypeDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
