import { Box, SxProps, Theme } from '@mui/material';
import InsuranceAvatar from 'assets/images/insurance-avatar.png';
import InvestingAvatar from 'assets/images/investing-avatar.png';
import MortgageAvatar from 'assets/images/mortgage-avatar.png';
import { Image } from 'common/components/Image/Image';

import { styles } from './AllExpertsAvatars.styles';

interface Props {
  size: 40 | 60;
}

export const AllExpertsAvatars: React.FC<Props> = ({ size }) => {
  return (
    <Box sx={[styles.root, size === 40 ? styles.small : styles.large] as SxProps<Theme>}>
      <Image src={MortgageAvatar} width={size} />
      <Image src={InsuranceAvatar} width={size} />
      <Image src={InvestingAvatar} width={size} />
    </Box>
  );
};
