import { SxProps, Theme } from '@mui/material';

export const styles: Record<'textContainer', SxProps<Theme>> = {
  textContainer: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    zIndex: 1,
  }),
};
