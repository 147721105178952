import { homeTheme } from '@brand/themes/homeTheme';
import { Box, Button, Theme, ThemeProvider } from '@mui/material';
import { Header } from 'common/components/Header/Header';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { LoginDialog, LoginDialogProps } from 'components/auth/LoginDialog';
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Notifications } from '../Notifications/Notifications';
import { RefreshData } from '../RefreshData/RefreshData';
import { styles } from './HomepageLayout.styles';

interface Props {
  children: React.ReactNode;
  showRefreshAccounts?: boolean;
  showNotifications?: boolean;
  header?: React.ReactNode;
}

export const HomepageLayout: React.FC<Props> = ({ children, header, showNotifications, showRefreshAccounts }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });

  const handleLogin = () => {
    loginDialog.create({});
  };

  const newTheme = useCallback((theme: Theme) => {
    return _.merge(_.cloneDeep(theme), homeTheme);
  }, []);

  return (
    <ThemeProvider theme={newTheme}>
      <Box sx={styles.container}>
        <Header sx={styles.headerContainer}>
          <Box sx={styles.header}>{header}</Box>
          {!!showRefreshAccounts && <RefreshData />}
          {!!showNotifications && <Notifications />}
          {!isAuthenticated && (
            <Button color="secondaryButton" onClick={handleLogin} sx={styles.login}>
              {t('introduction.login')}
            </Button>
          )}
        </Header>
        <Box sx={styles.content}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
};
