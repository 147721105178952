import { Box, IconButton } from '@mui/material';
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { FinancialCalendarDialog } from 'components/products/FinancialCalendar/FinancialCalendarDialog';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './FinancialCalendar.styles';

export const FinancialCalendar: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  const financialCalendarDialog = useDialog<DialogComponentProps<void>, void>(FinancialCalendarDialog, {
    instanceId: 'financial-calendar',
    navigable: true,
  });

  const handleFinancialCalendarClick = async () => {
    await financialCalendarDialog.create({});
  };

  return (
    <Box>
      {isAuthenticated && (
        <IconButton
          aria-label={t('homepage.financialCalendar.button')}
          color="tertiaryButton"
          onClick={handleFinancialCalendarClick}
          sx={styles.button}
        >
          <CalendarIcon />
        </IconButton>
      )}
    </Box>
  );
};
