import { MortgageLiabilityType } from 'const';
import {
  EntrepreneurshipExpenseType,
  FamilyStatus,
  IntInterval,
  MortgageCollateralType,
  MortgageIncomeType,
  MortgagePropertyType,
  MortgagePurposeType,
} from 'generated/graphql';

export const MORTGAGE_COLLATERAL_TYPE_SAME = 'SAME';

export interface Mapper<State, Input, Output> {
  parse: (value?: Output) => State | undefined;
  serialize: (value?: State) => Input | undefined;
}

export enum MortgageOtherIncomeSubtype {
  TravelCompensation = 'TRAVEL_COMPENSATION',
  RealEstateRental = 'REAL_ESTATE_RENTAL',
  MaternityLeave = 'MATERNITY_LEAVE',
  ParentalAllowance = 'PARENTAL_ALLOWANCE',
  Alimony = 'ALIMONY',
  WorkAgreement = 'WORK_AGREEMENT',
  DoctoralScholarship = 'DOCTORAL_SCHOLARSHIP',
  RetirementPension = 'RETIREMENT_PENSION',
  DisabilityPension = 'DISABILITY_PENSION',
  OrphanPension = 'ORPHAN_PENSION',
  WidowPension = 'WIDOW_PENSION',
  OldAgePension = 'OLD_AGE_PENSION',
}

export interface Income {
  type?: MortgageIncomeType;
  otherIncomeSubtype?: { id: MortgageOtherIncomeSubtype } | null;
  monthlyIncome?: number | null;
  expenseType?: EntrepreneurshipExpenseType | null;
  revenue?: number | null;
  taxBase?: number | null;
  taxPayed?: number | null;
  equityShares?: number | null;
  ceoCompensation?: number | null;
  yearlyProfit?: number | null;
  yearlyRevenues?: number | null;
  incomeCommencementDate?: Date | null;
}

export type Liability = {
  type?: MortgageLiabilityType | 'none';
  monthlyInstallment?: number | null;
  limit?: number | null;
  balance?: number | null;
};

export interface Applicant {
  data?: {
    dateOfBirth?: Date | null;
    name?: string;
    familyStatus?: FamilyStatus;
    children?: number | null;
    citizenship?: boolean;
  };
  incomes?: Income[];
  liabilities?: Liability[];
}

export type MortgageCollateralWithSameType = MortgageCollateralType | typeof MORTGAGE_COLLATERAL_TYPE_SAME;

export interface CollateralType {
  type?: MortgageCollateralWithSameType | null;
  value?: number | null;
  location?: string | null;
  isInConstruction?: boolean | null;
}

export interface MortgagePurchaseState {
  meta?: {
    progress?: number;
    currentStep?: string;
    updateTimestamp?: Date | null;
  };
  info?: {
    purpose?: {
      type?: MortgagePurposeType;
      subtype?: { id: MortgagePurposeType };
      description?: string;
    };
    amount?: number | null;
  };
  applicants?: {
    data?: (Applicant | null)[];
  };
  collaterals?: {
    data?: (CollateralType | null)[];
  };
  property?: {
    isChosen?: boolean | null;
    type?: MortgagePropertyType | null;
    sizeInterval?: IntInterval | null;
    isDevelopment?: boolean | null;
    energyCertificateClassA?: boolean | null;
    price?: number | null;
  };
  contact?: {
    email?: string | null;
    phone?: string | null;
    consent?: boolean | null;
  };
}
