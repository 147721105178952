import { MortgagePurchaseApplicantFragment, MortgagePurchaseApplicantInput } from 'generated/graphql';

import { Mapper } from './purchaseTypes';

export const mapCitizenship: Mapper<
  boolean,
  MortgagePurchaseApplicantInput['citizenship'],
  MortgagePurchaseApplicantFragment['citizenship']
> = {
  parse: (value) => {
    if (!value) {
      return undefined;
    }

    return value === 'SK';
  },

  serialize: (value) => {
    if (value === undefined) {
      return undefined;
    }
    return value ? 'SK' : '-';
  },
};
