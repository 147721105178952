import { routes as brandRoutes } from '@brand/common/routes/routes';
import { wrapUseRoutes } from '@sentry/react';
import { getUnregisteredUserId } from 'common/auth/unregistered-user';
import { CookiesConsentDialog, CookiesConsentDialogProps } from 'common/components/CookiesDialogs/CookiesConsentDialog';
import { Loading } from 'common/components/Loading/Loading';
import { useDialog } from 'common/hooks/useDialog';
import { useSaveUtmParameters } from 'common/hooks/useSaveUtmParameters';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { DialogContext } from 'common/providers/DialogProvider/DialogContext';
import { routes as commonRoutes } from 'common/routes/routes';
import { useSaveLocalFlowStates } from 'components/flows/useSaveLocalFlowStates';
import { Crisp } from 'crisp-sdk-web';
import { environment } from 'environment';
import React, { Suspense, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export const App: React.FC = () => {
  const cookiesDialog = useDialog<CookiesConsentDialogProps, void>(CookiesConsentDialog, {
    instanceId: 'cookies-consent',
  });
  const [cookies] = useCookies(['cookielawinfo-checkbox-necessary', 'cookielawinfo-checkbox-others']);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasInstance } = useContext(DialogContext);
  const { extractAndStoreUtmParameters, saveStoredUtmParameters } = useSaveUtmParameters();

  useEffect(() => {
    if (!cookies['cookielawinfo-checkbox-necessary']) {
      cookiesDialog.create({
        instanceId: `cookies-consent-${pathname}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies['cookielawinfo-checkbox-necessary'], pathname]);

  // remove all non-openable dialogs from URL search params
  useEffect(() => {
    Array.from(searchParams.entries()).map(([param, value]) => {
      if (value === 'tmp-open' && !hasInstance(param)) {
        searchParams.delete(param);
        return [];
      }
      return param;
    });
    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isAuthenticated, userData } = useContext(AuthContext);
  const unregisteredUserId = getUnregisteredUserId();

  // handle UTM parameters
  useEffect(() => extractAndStoreUtmParameters(searchParams), [extractAndStoreUtmParameters, searchParams]);
  useEffect(() => {
    if (isAuthenticated || unregisteredUserId !== null) saveStoredUtmParameters();
  }, [isAuthenticated, saveStoredUtmParameters, unregisteredUserId]);

  useEffect(() => {
    Crisp.configure(environment.crisp.websiteId);
  }, []);

  useEffect(() => {
    if (!userData) return;
    Crisp.user.setEmail(userData?.user.attributes.email);

    return () => Crisp.session.reset();
  }, [isAuthenticated, userData, userData?.user.attributes.email]);

  useEffect(() => {
    window.gtag('consent', 'update', {
      ad_storage: cookies['cookielawinfo-checkbox-others'] ? 'granted' : 'denied',
      analytics_storage: cookies['cookielawinfo-checkbox-others'] ? 'granted' : 'denied',
      functionality_storage: cookies['cookielawinfo-checkbox-necessary'] ? 'granted' : 'denied',
      personalization_storage: cookies['cookielawinfo-checkbox-others'] ? 'granted' : 'denied',
      security_storage: cookies['cookielawinfo-checkbox-necessary'] ? 'granted' : 'denied',
    });
  }, [cookies]);

  const appendSearchParams = (path: string) => {
    return {
      pathname: path,
      search: searchParams.toString(),
    };
  };

  useSaveLocalFlowStates();

  const routes = [...commonRoutes({ appendSearchParams }), ...brandRoutes({ appendSearchParams })];

  const routesElement = useSentryRoutes(routes);

  return routesElement && <Suspense fallback={<Loading messages={[]} />}>{routesElement}</Suspense>;
};
