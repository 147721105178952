import { Autocomplete, Box, Checkbox, TextField, Typography } from '@mui/material';
import { getNameForTransactionCounterparty } from 'components/transactions/utils';
import { FilterOperator, TransactionCounterpartyFragment, useTransactionCounterpartiesQuery } from 'generated/graphql';
import _ from 'lodash';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Filter, FilterOption } from './Filter';

export const useCounterpartyOptions = (): FilterOption[] => {
  const { t } = useTranslation();

  const label = t('filterTransactions.filter.counterparty.withoutCounterparty');

  return [
    {
      key: label,
      label: label,
      value: {
        arguments: [
          {
            filterCondition: {
              fieldPath: 'counterparty',
              operator: FilterOperator.IsNull,
            },
          },
        ],
      },
    },
  ];
};

const NAME = 'counterparty';

export const CounterpartyFilter: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useTransactionCounterpartiesQuery({
    variables: {
      pageInput: {
        pageIndex: 0,
        pageSize: 10000, //get all counterparties that exist
      },
    },
  });

  const counterpartyMap = _.keyBy(data?.page?.items, 'id');

  const { field: quickfilterField } = useController({ name: `${NAME}.quickfilter` });
  const { field: manualField } = useController({ name: `${NAME}.manual` });

  const getCounterpartyLabel = (value: TransactionCounterpartyFragment | string) => {
    const counterparty = typeof value === 'string' ? counterpartyMap[value] : value;
    return getNameForTransactionCounterparty(counterparty, undefined, t);
  };

  const counterpartyOptions = useCounterpartyOptions();

  const isManual = manualField.value != null;

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: (TransactionCounterpartyFragment | string)[],
  ) => {
    manualField.onChange(value.map((item) => (typeof item === 'string' ? item : item.id)));
  };

  const handleManualClick = () => {
    if (!isManual) {
      manualField.onChange([]);
    }
    quickfilterField.onChange(null);
  };

  return (
    <Filter
      name={NAME}
      onManualClick={handleManualClick}
      options={counterpartyOptions}
      title={t('filterTransactions.filter.counterparty.title')}
    >
      {isManual && !loading && (
        <Box pr={1}>
          <Autocomplete<TransactionCounterpartyFragment | string, true, true, false>
            closeText={t('common:close')}
            defaultValue={manualField.value}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option) => getCounterpartyLabel(option)}
            isOptionEqualToValue={(option, value) => getCounterpartyLabel(option) === getCounterpartyLabel(value)}
            loading={loading}
            loadingText={t('filterTransactions.filter.counterparty.autocomplete.loading')}
            multiple
            noOptionsText={t('filterTransactions.filter.counterparty.autocomplete.noOption')}
            onChange={handleChange}
            openText={t('common:open')}
            options={data?.page?.items ?? []}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option, { selected }) => (
              <Box component={'li'} {...props} key={typeof option === 'string' ? option : option.id}>
                <Checkbox checked={selected} />
                <Typography>{getCounterpartyLabel(option)}</Typography>
              </Box>
            )}
            renderTags={(value) => {
              return value.map((item) => getCounterpartyLabel(item)).join(', ');
            }}
            value={manualField.value}
          />
        </Box>
      )}
    </Filter>
  );
};
