import { SxProps, Theme } from '@mui/material';

const SIZE_ICON = '36px';

export const styles: Record<
  'headline' | 'content' | 'item' | 'icon' | 'value' | 'headlineText' | 'label',
  SxProps<Theme>
> = {
  content: (theme) => ({
    borderTop: `1px solid ${theme.palette.gray['200']}`,
    margin: `${theme.spacing(8)} auto 0`,
    maxWidth: '842px',
  }),
  headline: (theme) => ({
    borderBottom: `1px solid ${theme.palette.gray['300']}`,
    padding: theme.spacing(6, 4, 3),
  }),
  headlineText: { margin: '0 auto', maxWidth: '1360px' },
  icon: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    height: SIZE_ICON,
    justifyContent: 'center',
    width: SIZE_ICON,
  },
  item: (theme) => ({
    '& svg': {
      color: { sm: theme.palette.gray['50'], xss: theme.palette.gray['900'] },
    },
    '&:hover': {
      background: theme.palette.gray['100'],
      cursor: 'pointer',
    },
    '&:hover svg': {
      color: (theme) => theme.palette.gray['900'],
    },
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.gray['200']}`,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'space-between',
    padding: 3,
    width: '100%',
  }),
  label: { flexDirection: { sm: 'row', xxs: 'column' } },
  value: { alignItems: 'center', display: 'flex', gap: 1, wordBreak: 'break-word' },
};
