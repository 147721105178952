import { Button, FormControlLabel, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { FormCheckBox } from 'common/form/components/FormCheckBox';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { CategoryIcon } from 'components/transactions/CategoryIcon/CategoryIcon';
import { FilterConditionInput, FilterOperator, useTransactionCategoriesQuery } from 'generated/graphql';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

interface FormValues {
  [key: string]: boolean;
}

export interface CategorySelectDialogProps extends DialogComponentProps<FilterConditionInput> {
  filter?: FilterConditionInput;
}

export const CategorySelectDialog: React.FC<CategorySelectDialogProps> = (props) => {
  const { i18n, t } = useTranslation();
  const { data } = useTransactionCategoriesQuery({
    variables: {
      filterInput: {
        arguments: [{ filterCondition: { fieldPath: 'isActive', operator: FilterOperator.Equal, values: ['true'] } }],
      },
    },
  });

  const defaultValues = _.fromPairs(props.filter?.values?.map((value) => [value, true]));

  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  const handleSubmit = formMethods.handleSubmit(async (formData) => {
    const values = _.entries(formData)
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    const newFilter: FilterConditionInput = {
      fieldPath: 'category.categoryGroup.name',
      operator: FilterOperator.In,
      values,
    };
    props.onResolve(newFilter);
  });

  return (
    <FormProvider {...formMethods}>
      <ResponsiveSideDialog
        dialogId={props.instanceId}
        header={
          <>
            <Typography sx={styles.title} variant="bodyStrongL">
              {t('filterTransactions.categories.title')}
            </Typography>
            <Button color="tertiaryButton" onClick={handleSubmit} sx={styles.submit}>
              <Typography color="blue.dark" variant="bodyStrongL">
                {t('filterTransactions.categories.header.submit')}
              </Typography>
            </Button>
          </>
        }
        isOpen={props.isOpen}
        onClose={props.onReject}
      >
        <Stack sx={styles.content}>
          {data?.page.items
            .slice()
            .sort((a, b) => a.categoryGroup.name.localeCompare(b.categoryGroup.name ?? '', i18n.language))
            .map((category) => (
              <FormControlLabel
                key={category.id}
                control={<FormCheckBox name={category.categoryGroup.name} />}
                label={
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <CategoryIcon category={category} width={32} />
                    <Typography>{category.categoryGroup.name}</Typography>
                  </Stack>
                }
                sx={styles.checkbox}
                tabIndex={0}
              />
            ))}
        </Stack>
      </ResponsiveSideDialog>
    </FormProvider>
  );
};
