import { usePeriodicityOptions } from 'common/hooks/usePeriodicityOptions';
import { FilterOperator, LogicalOperator } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { Filter, FilterOption } from './Filter';

export const usePeriodicityFilters = (): FilterOption[] => {
  const periodicityOptions = usePeriodicityOptions();
  return periodicityOptions.map((option) => ({
    key: option.label,
    label: option.label,
    value: {
      arguments: [
        {
          filterCondition: {
            fieldPath: 'streamGroup.period.periodCount',
            operator: FilterOperator.Equal,
            values: [`${option.value.periodCount}`],
          },
        },
        {
          filterCondition: {
            fieldPath: 'streamGroup.period.periodTypeCode',
            operator: FilterOperator.Equal,
            values: [option.value.periodTypeCode],
          },
        },
      ],
      operator: LogicalOperator.And,
    },
  }));
};

const NAME = 'periodicity';

export const PeriodicityFilter: React.FC = () => {
  const { t } = useTranslation();

  const periodicityOptions = usePeriodicityFilters();

  return (
    <Filter
      name={NAME}
      noManual
      options={periodicityOptions}
      title={t('filterTransactions.filter.periodicity.title')}
    />
  );
};
