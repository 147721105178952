import { Box, Stack, Typography } from '@mui/material';
import { formatAsIntlNumber } from 'common/utils/formatUtils';
import { useTranslation } from 'react-i18next';

import { styles } from './LifeInsuranceScoreIcon.style';

interface LifeInsuranceScoreIconScaleProps {
  score: number;
  maximalScore: number;
  width: number;
  height: number;
}

const LifeInsuranceScoreIconScale: React.FC<LifeInsuranceScoreIconScaleProps> = ({
  height,
  maximalScore,
  score,
  width,
}) => {
  const viewBox = `0 0 212 138`;

  const r = 98.25;
  const angle = (0.93 + (1.14 * score) / maximalScore) * Math.PI;
  const cx = 106 + r * Math.cos(angle);
  const cy = 106 + r * Math.sin(angle);

  return (
    <Box height={height} width={width}>
      <svg viewBox={viewBox}>
        <path
          clipRule="evenodd"
          d="M109 0.0424579C107.778 0.00786603 106.555 -0.00562219 105.33 0.00211689C88.5972 0.10786 72.1271 4.17355 57.2671 11.8666C42.4071 19.5596 29.5796 30.6613 19.8338 44.2636C10.088 57.8658 3.70102 73.5821 1.19525 90.1267C-0.921947 104.106 -0.21747 118.34 3.23729 131.997C4.28963 136.156 8.71757 138.354 12.7891 137C16.8606 135.646 19.0286 131.253 18.0288 127.08C15.3172 115.764 14.8087 104.007 16.5584 92.4535C18.6969 78.3342 24.1476 64.9218 32.4648 53.3134C40.7819 41.7051 51.7291 32.2307 64.4108 25.6654C77.0925 19.1001 91.1483 15.6304 105.428 15.5402C106.62 15.5327 107.811 15.5487 109 15.5881V0.0424579Z"
          fill="url(#paint0_linear_8327_21882)"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M104 15.5605C104.476 15.55 104.952 15.5432 105.428 15.5402C119.708 15.4499 133.807 18.7417 146.571 25.1462C159.334 31.5507 170.4 40.886 178.863 52.3883C187.327 63.8905 192.947 77.2331 195.263 91.3242C197.159 102.854 196.799 114.617 194.231 125.966C193.284 130.151 195.507 134.517 199.595 135.82C203.684 137.122 208.083 134.869 209.083 130.696C212.365 116.997 212.889 102.754 210.596 88.8034C207.881 72.2918 201.296 56.6575 191.379 43.1795C181.462 29.7015 168.495 18.7628 153.539 11.2582C138.583 3.75358 122.063 -0.103626 105.33 0.00211689C104.886 0.00491977 104.443 0.0105049 104 0.018866V15.5605Z"
          fill="url(#paint1_linear_8327_21882)"
          fillRule="evenodd"
        />
        <circle cx={cx} cy={cy} fill="transparent" r="10.95425" stroke="white" strokeWidth="6.3615" />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_8327_21882"
            x1="10.5"
            x2="105.279"
            y1="137"
            y2="-0.496933"
          >
            <stop stopColor="#019456" />
            <stop offset="0.780137" stopColor="#E3931C" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_8327_21882"
            x1="202"
            x2="106"
            y1="136"
            y2="1.44621e-06"
          >
            <stop stopColor="#B1221F" />
            <stop offset="0.737609" stopColor="#E3931D" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

interface LifeInsuranceScoreIconProps {
  score: number;
  maximalScore: number;
  size?: 'small' | 'large';
}

export const LifeInsuranceScoreIcon: React.FC<LifeInsuranceScoreIconProps> = ({ maximalScore, score, size }) => {
  const { i18n, t } = useTranslation();
  const width = size == 'small' ? 108 : 212;
  const height = size == 'small' ? 70 : 138;

  return (
    <Stack height={height} sx={styles.container} width={width}>
      <LifeInsuranceScoreIconScale height={height} maximalScore={maximalScore} score={score} width={width} />

      {size == 'small' ? (
        <Box sx={styles.label} top={28}>
          <Typography color="textSecondary" sx={styles.labelTextSmall}>
            {t('flows/lifeInsurance/calculator:result.score')}
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.label} top={55}>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/lifeInsurance/calculator:result.score')}
          </Typography>
        </Box>
      )}

      <Box sx={styles.score} top={size == 'small' ? 40 : 75}>
        <Typography variant={size == 'small' ? 'headlineM' : 'headlineXL'}>
          {formatAsIntlNumber(score, i18n.language, {
            maximumFractionDigits: 1,
            notation: 'standard',
            signDisplay: 'never',
          })}
          /
          {formatAsIntlNumber(maximalScore, i18n.language, {
            maximumFractionDigits: 0,
            notation: 'standard',
            signDisplay: 'never',
          })}
        </Typography>
      </Box>
    </Stack>
  );
};
