import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'container' | 'text' | 'imageContainer' | 'link' | 'headline' | 'benefits',
  SxProps<Theme>
> = {
  benefits: {
    flexDirection: { sm: 'row', xxs: 'column' },
    gap: 2,
  },
  container: (theme) => ({
    backgroundColor: theme.palette.gray[50],
    borderRadius: '10px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
  headline: {
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    background: '-webkit-linear-gradient(45deg, #96CEFD, #0040DD)',
    backgroundClip: 'text',
    fontSize: '64px',
    fontWeight: 600,
    lineHeight: '100%',
    paddingBottom: (theme) => theme.spacing(2),
  },
  imageContainer: (theme) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  }),
  link: {
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: 'currentColor',
    },
    cursor: 'pointer',
  },
  text: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 0, 6, 4),
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      padding: theme.spacing(6, 4, 0, 4),
      textAlign: 'center',
    },
  }),
};
