import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'headerWrapper' | 'page' | 'main' | 'progressBarMobile' | 'progressBarWideScreen',
  SxProps<Theme>
> = {
  headerWrapper: {
    backgroundColor: (theme) => theme.palette.gray[50],
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: { sm: 'space-between', xxs: 'start' },
    margin: (theme) => `${theme.spacing(6)} auto`,
    maxWidth: '624px',
    padding: (theme) => theme.spacing(0, 2),
    width: '100%',
  },
  page: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    minWidth: '100%',
    position: 'relative',
    scrollbarWidth: 'thin',
  },
  progressBarMobile: {
    display: { sm: 'none', xxs: 'block' },
    margin: '0 auto',
    maxWidth: '624px',
    padding: (theme) => theme.spacing(0, 2),
  },
  progressBarWideScreen: {
    display: { sm: 'block', xxs: 'none' },
    padding: (theme) => theme.spacing(4, 0),
  },
};

export const fullWidthStyles: Record<'main', SxProps<Theme>> = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: (theme) => `${theme.spacing(6)} auto`,
    padding: (theme) => theme.spacing(0, 2),
    width: '100%',
  },
};
