import ProfileImage from '@brand/assets/images/profile.svg';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { Image } from 'common/components/Image/Image';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { AccountListItem } from 'components/accounts/AccountListItem/AccountListItem';
import { useConnectedAccountsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddAccount } from '../AddAccountDialog/useAddAccount';
import { styles } from './ConnectedAccountsDialog.styles';

const ConnectedAccountsDialogContent: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { data, loading } = useConnectedAccountsQuery({
    skip: !isAuthenticated,
  });

  const addAccount = useAddAccount();

  return (
    <Box>
      <Stack alignItems="center" spacing={0.5}>
        <Image src={ProfileImage} sx={styles.profileImage} />
        <Typography variant="headlineXL">{t('homepage.accounts.yourAccounts')}</Typography>

        {loading ? (
          <Skeleton height={20} variant="rectangular" width="100%" />
        ) : (
          <Typography variant="bodyM">
            {t('homepage.dashboard.profile.connectedAccounts', { count: data?.accounts?.items.length })}
          </Typography>
        )}
      </Stack>

      <Stack mt={3} spacing={3}>
        <Typography variant="headlineM">{t('homepage.accounts.connectedAccounts')}</Typography>

        <Box sx={styles.container}>
          {loading ? (
            <Skeleton height={75} variant="rectangular" />
          ) : (
            data?.accounts?.items.map((account) => <AccountListItem key={account.id} {...account} />)
          )}
        </Box>

        <Button color="tertiaryButton" onClick={addAccount} sx={styles.buttonRow}>
          <Typography color="blue.dark">{t('homepage.accounts.addAccount.title')}</Typography>
        </Button>
      </Stack>
    </Box>
  );
};

export const ConnectedAccountsDialog: React.FC<DialogComponentProps<void>> = ({ instanceId, isOpen, onResolve }) => {
  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={onResolve}>
      <ConnectedAccountsDialogContent />
    </ResponsiveSideDialog>
  );
};
