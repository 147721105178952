import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, Skeleton, Stack, Typography } from '@mui/material';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatIban } from 'common/utils/formatUtils';
import { useAccountDetailQuery, useRenameAccountMutation } from 'generated/graphql';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { styles } from './RenameAccountItem.styles';

interface RenameAccountItemProps {
  id: string;
}

interface FormValues {
  name: string;
}

export const RenameAccountItem: React.FC<RenameAccountItemProps> = ({ id }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { isAuthenticated } = useContext(AuthContext);
  const { data, loading } = useAccountDetailQuery({
    skip: !isAuthenticated,
    variables: {
      id,
    },
  });

  const [renameAccount] = useRenameAccountMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator(),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data?.detail?.name && !formMethods.formState.isDirty) {
      formMethods.reset({ name: data?.detail?.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSave = async (data: FormValues) => {
    await renameAccount({ variables: { accountId: id, name: data.name } });
  };

  return (
    <Box>
      {loading ? (
        <Skeleton height={105} variant="rectangular" />
      ) : (
        <Stack spacing={1} sx={styles.row}>
          <FormProvider {...formMethods}>
            <FormInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCard colorIndex={data?.detail?.colorIndex} height={12} width={20} />
                  </InputAdornment>
                ),
              }}
              autoComplete="name"
              name="name"
              onBlur={formMethods.handleSubmit(handleSave)}
              type="text"
            />
          </FormProvider>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyS">{data?.detail?.iban ? formatIban(data?.detail?.iban) : ''}</Typography>
            <Typography variant="bodyS">{data?.detail?.currency ?? ''}</Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
