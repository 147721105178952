import { Box, Stack, Typography } from '@mui/material';
import { LightTooltip } from 'common/components/LightTooltip/LightTooltip';
import { TransactionTagFragment } from 'generated/graphql';
import React, { useEffect, useRef, useState } from 'react';

import { styles } from './TagList.styles';

interface Props {
  tags: TransactionTagFragment[];
}

export const TagList: React.FC<Props> = ({ tags }) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const tagsRef = useRef<HTMLDivElement>(null);
  const [numberOfVisibleTags, setNumberOfVisibleTags] = useState(tags.length);
  const [recalculate, setRecalculate] = useState(false);

  useEffect(() => {
    setNumberOfVisibleTags(tags.length);
    setRecalculate(true);
  }, [tags]);

  useEffect(() => {
    if (!recalculate || !rowRef.current || !tagsRef.current) {
      return;
    }

    const wrapperRight = rowRef.current.getBoundingClientRect().right;
    const wrapperChildren = Array.from(tagsRef.current.children);
    const visibleTags = wrapperChildren.filter((child) => child.getBoundingClientRect().right < wrapperRight);
    if (
      visibleTags.length > 0 &&
      visibleTags.length < tags.length &&
      visibleTags[visibleTags.length - 1].getBoundingClientRect().right > wrapperRight - 32
    ) {
      // to avoid `+n` being placed out of parent
      setNumberOfVisibleTags(visibleTags.length - 1);
    } else {
      setNumberOfVisibleTags(visibleTags.length);
    }
    setRecalculate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculate]);

  return (
    <Stack direction="row" ref={rowRef} spacing={1} sx={styles.tagList}>
      <Stack direction="row" ref={tagsRef} spacing={1}>
        {tags
          .slice()
          .sort((aTag, bTag) => aTag.name?.localeCompare(bTag.name ?? '') ?? -1)
          .slice(0, numberOfVisibleTags)
          .map((tag) => (
            <Box key={tag.id} sx={styles.tag}>
              <Typography color="textSecondary">{tag.name}</Typography>
            </Box>
          ))}
      </Stack>
      {tags.length - numberOfVisibleTags > 0 && (
        <LightTooltip
          arrow
          children={<Box sx={styles.tag}>+{tags.length - numberOfVisibleTags}</Box>}
          placement="top"
          title={
            <Box>
              {tags
                .slice()
                .sort((aTag, bTag) => aTag.name?.localeCompare(bTag.name ?? '') ?? -1)
                .slice(numberOfVisibleTags, tags.length)
                .map((tag) => (
                  <Box key={tag.id}>{tag.name}</Box>
                ))}
            </Box>
          }
        />
      )}
    </Stack>
  );
};
