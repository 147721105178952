import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/Upload.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { FinancialProductType, ProductStatus } from 'generated/graphql';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DataProtectionGuarantees } from '../DataProtectionGuarantees/DataProtectionGuarantees';
import { ProductCard } from '../ProductCard/ProductCard';
import { UploadDocumentDialog, UploadDocumentDialogProps } from '../UploadDocumentDialog/UploadDocumentDialog';
import { DocumentTypeByProductType, useCreateProductDocument } from '../UploadDocumentDialog/useCreateProductDocument';
import { useCreateProduct } from '../useCreateProduct';
import { useProductDetail } from '../useProductDetail';
import { useUpdateProduct } from '../useUpdateProduct';
import { styles } from './AddCustomProductDialog.styles';

interface AddCustomProductDialogContentProps {
  productType: FinancialProductType;
  institutionId?: string;
  institutionName?: string;
}

export interface AddCustomProductDialogProps extends DialogComponentProps<void>, AddCustomProductDialogContentProps {}

const AddCustomProductDialogContent: React.FC<AddCustomProductDialogProps> = ({
  institutionId,
  institutionName,
  productType,
}) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [createProduct] = useCreateProduct(productType);
  const [updateProduct] = useUpdateProduct(productType);

  const handleCreateProduct = async (status: ProductStatus) => {
    const resp = await createProduct();
    const productId = resp.data?.createProduct?.id;
    if (productId) {
      //close all previous dialogs
      setSearchParams('', { replace: true });
      await updateProduct({
        variables: {
          id: productId,
          updatedProduct: {
            enableFinancialAdvisorCare: true,
            enableNotifications: true,
            institutionId: institutionId,
            institutionName,
            status,
            statusChangeDate: new Date(),
          },
        },
      });
    }
    return productId;
  };

  const mutationTuple = useCreateProductDocument(productType);

  const uploadDocumentDialog = useDialog<UploadDocumentDialogProps, void>(UploadDocumentDialog, {
    instanceId: 'upload-document-dialog',
    tmpNavigable: true,
  });

  const handleUploadClick = async () => {
    setLoading(true);
    const productId = await handleCreateProduct(ProductStatus.WaitingForSubmission);
    if (productId) {
      const createDocumentMutation = mutationTuple[0];
      const resp = await createDocumentMutation({
        variables: {
          document: {
            type: DocumentTypeByProductType[productType],
          },
          productId: productId,
        },
      });
      const documentId = resp.data?.createProductDocument?.id;
      setLoading(false);
      if (documentId) {
        await uploadDocumentDialog.create({
          documentId,
          productId,
          type: productType,
        });
      }
    }
  };

  const productDetail = useProductDetail(productType, {
    tmpNavigable: true,
  });

  const handleFillClick = async () => {
    setLoading(true);
    const productId = await handleCreateProduct(ProductStatus.Active);
    setLoading(false);
    if (productId) {
      productDetail.create({
        id: productId,
      });
    }
  };

  return (
    <Stack>
      <Stack gap={3}>
        <Typography variant="headlineXL">{t('products:addProduct.custom.title')}</Typography>
        <ProductCard
          icon={
            <Box sx={styles.blueIcon}>
              <UploadIcon />
            </Box>
          }
          loading={loading}
          onClick={handleUploadClick}
          subtitle={<Trans i18nKey="products:addProduct.custom.upload.subtitle" />}
          title={t('products:addProduct.custom.upload.title')}
        />
        <ProductCard
          icon={
            <Box sx={styles.blueIcon}>
              <PencilIcon />
            </Box>
          }
          loading={loading}
          onClick={handleFillClick}
          subtitle={<Trans i18nKey="products:addProduct.custom.fill.subtitle" />}
          title={t('products:addProduct.custom.fill.title')}
        />
      </Stack>
      <Box mt={4}>
        <DataProtectionGuarantees />
      </Box>
    </Stack>
  );
};

export const AddCustomProductDialog: React.FC<AddCustomProductDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <AddCustomProductDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
