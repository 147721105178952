import { Loading } from 'common/components/Loading/Loading';
import { useNavigateAfterLogin } from 'common/hooks/useNavigateAfterLogin';
import { BasicLayout } from 'common/layout/onboarding/BasicLayout';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { RegisterDialog } from 'components/auth/RegisterDialog';
import { REGISTER_URL } from 'const';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const RegistrationPage: React.FC = () => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();
  const { saveNavigateAfterLoginUrl } = useNavigateAfterLogin();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) setShowDialog(true);
  }, [isAuthenticated, isLoading]);

  const redirectPathName = location.pathname.replace(new RegExp(`^${_.escapeRegExp(REGISTER_URL)}`), '');
  if (!!redirectPathName) {
    saveNavigateAfterLoginUrl(redirectPathName + location.search + location.hash);
  }

  return (
    <BasicLayout showLogin={false}>
      {!showDialog && isLoading && <Loading hideCommonLoadingTitle={true} messages={[]} />}
      {showDialog && (
        <RegisterDialog
          displayAsPage
          instanceId="registration-page"
          isOpen={true}
          onReject={_.noop}
          onResolve={_.noop}
        />
      )}
    </BasicLayout>
  );
};
