import { Checkbox, styled } from '@mui/material';

interface StyledCheckboxProps {
  error?: boolean;
}

export const StyledCheckbox = styled(Checkbox, { shouldForwardProp: (prop) => prop !== 'error' })<StyledCheckboxProps>(
  ({ error, theme }) => ({
    color: error ? theme.palette.error.main : undefined,
    marginRight: theme.spacing(1),
    padding: 0,
    svg: {
      height: 21.34, //To get the icon to 16x16 size
      width: 21.34,
    },
  }),
);
