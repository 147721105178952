import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { ReactComponent as Visibility } from 'assets/icons/Eye.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/EyeCrossed.svg';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormElementProps } from './FormElement';
import { FormInput } from './FormInput';
import { styles } from './FormPasswordInput.styles';

interface FormPasswordInputProps extends FormElementProps {
  placeholder?: string;
  variant?: TextFieldProps['variant'];
  fullWidth?: boolean;
  size?: 'medium' | 'large';
}

export const FormPasswordInput: React.FC<FormPasswordInputProps> = ({
  autoComplete,
  fullWidth,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  size,
  sx,
}) => {
  const { t } = useTranslation();

  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const {
    field: { value },
  } = useController({ name });

  useEffect(() => {
    if (!value) {
      setIsPasswordShow(false);
    }
  }, [isPasswordShow, value]);

  return (
    <FormInput
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={styles.visibilityIcon}>
            <IconButton
              aria-label={t('form.aria.togglePasswordVisibility')}
              color="greyButton"
              edge="end"
              onClick={() => setIsPasswordShow(!isPasswordShow)}
              onMouseDown={(event) => event.preventDefault()}
              onMouseUp={(event) => event.preventDefault()}
              tabIndex={-1}
            >
              {value && (isPasswordShow ? <VisibilityOff /> : <Visibility />)}
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoComplete={autoComplete}
      fullWidth={fullWidth}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      sx={sx}
      type={isPasswordShow ? 'text' : 'password'}
    />
  );
};
