import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'dialog' | 'content' | 'header' | 'image' | 'expert' | 'checkbox' | 'passwordRequirementsList' | 'passwordInput',
  SxProps<Theme>
> = {
  checkbox: {
    alignItems: 'center',
    display: 'flex',
  },
  content: {
    padding: (theme) => theme.spacing(4.5, 4),
  },
  dialog: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    textAlign: 'center',
    width: '364px',
  },
  expert: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    background: (theme) => theme.palette.gray[100],
    borderBottom: (theme) => `1px solid ${theme.palette.gray[400]}`,
    padding: (theme) => theme.spacing(2, 2),
  },
  image: {
    width: '40px',
  },
  passwordInput: {
    width: '100%',
  },
  passwordRequirementsList: {
    '& li:before': {
      content: '"•"',
      marginRight: (theme) => theme.spacing(0.5),
    },
    color: (theme) => theme.palette.gray['500'],
    listStyleType: 'none',
    padding: 0,
    textAlign: 'left',
  },
};
