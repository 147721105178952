import { SuccessDialog, SuccessDialogProps } from 'common/components/SuccessDialog/SuccessDialog';

import { useDialog } from './useDialog';

interface Args {
  message: string;
  title?: string;
}

export const useSuccessDialog = (): ((props: Args) => Promise<void>) => {
  const successDialog = useDialog<SuccessDialogProps, void>(SuccessDialog, { instanceId: 'success-dialog' });

  return ({ message, title }: Args) => successDialog.create({ message, title });
};
