import { SxProps, Theme } from '@mui/material';

export const styles: Record<'textBox', SxProps<Theme>> = {
  textBox: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    marginTop: (theme) => theme.spacing(3),
    padding: (theme) => ({
      sm: theme.spacing(6),
      xxs: theme.spacing(2),
    }),
  },
};
