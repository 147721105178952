import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { Trans } from 'react-i18next';

export const GoogleLoginInfoDialog: React.FC<DialogComponentProps<void>> = ({ isOpen, onResolve }) => {
  return (
    <StyledDialog fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogSubtitle>
        <Trans i18nKey="googleLogin.info" />
      </StyledDialogSubtitle>
    </StyledDialog>
  );
};
