import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormInput } from 'common/form/components/FormInput';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useUpdateUserMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { StyledDialogFormError } from '../../../common/components/StyledDialog/StyledDialogFormError';
import { emailValidator } from '../../../common/form/validators';
import { styles } from './ChangeDialog.styles';
import { VerifyEmailChangeDialog, VerifyEmailChangeDialogProps } from './VerifyEmailChangeDialog';

export interface FormValues {
  email?: string;
}

export type ChangeEmailDialogProps = DialogComponentProps<void>;

export const ChangeEmailDialog: React.FC<ChangeEmailDialogProps> = ({ isOpen, onReject, onResolve }) => {
  const { t } = useTranslation();
  const [updateUserEmail, { error, loading }] = useUpdateUserMutation({});

  const schema: yup.SchemaOf<FormValues> = yup.object({
    email: emailValidator(),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const verifyEmailChangeDialog = useDialog<VerifyEmailChangeDialogProps, void>(VerifyEmailChangeDialog, {
    instanceId: 'verify-email-change',
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (data.email) {
      const resp = await updateUserEmail({
        variables: {
          input: {
            email: data.email,
          },
        },
      });

      if (resp) {
        verifyEmailChangeDialog.create({ email: data.email });
        onResolve();
      }
    }
  });

  const displayedError = error?.message.includes('EmailAlreadyUsedException')
    ? t('form.validator.aliasAlreadyExists')
    : t('common:somethingWentWrong');

  return (
    <FormProvider {...formMethods}>
      <StyledDialog maxWidth="sm" onClose={() => onReject()} open={isOpen}>
        <StyledDialogTitle textAlign="left">{t('settings.changeDialog.email.title')}</StyledDialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormInput
              fullWidth
              label={t('settings.changeDialog.email.label')}
              name="email"
              placeholder={t('settings.changeDialog.placeholder.email')}
              sx={styles.input}
              type="email"
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondaryButton" onClick={() => onReject()}>
              {t('settings.changeDialog.cancel')}
            </Button>
            <LoadingButton loading={loading} type="submit">
              {t('settings.changeDialog.save')}
            </LoadingButton>
          </DialogActions>
          {!!error && !loading && (
            <StyledDialogFormError content={displayedError} title={t('settings.changeDialog.error')} />
          )}
        </Box>
      </StyledDialog>
    </FormProvider>
  );
};
