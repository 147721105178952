import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  format,
  formatISO,
  isSameMonth,
  isSameYear,
  max,
  min,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { sk } from 'date-fns/locale';
import {
  DateGranularity,
  DateKey,
  FilterConditionInput,
  FilterOperator,
  SortDirection,
  SortExpressionInput,
  TransactionAggregationValueFragment,
} from 'generated/graphql';
import { i18n } from 'i18n';
import _ from 'lodash';

import { Period, PeriodTypeEnum } from './state/spendingReportTypes';

const getLocale = (): Locale => {
  switch (i18n.language) {
    case 'sk':
      return sk;
    default:
      return sk;
  }
};

export const getPeriod = (periodType: PeriodTypeEnum, referenceDate: Date = new Date()): Period => {
  return {
    [PeriodTypeEnum.Day]: {
      endDate: endOfDay(referenceDate),
      startDate: startOfDay(referenceDate),
      unit: DateGranularity.Day,
    },
    [PeriodTypeEnum.Week]: {
      endDate: endOfISOWeek(referenceDate),
      startDate: startOfISOWeek(referenceDate),
      unit: DateGranularity.Day,
    },
    [PeriodTypeEnum.Month]: {
      endDate: endOfMonth(referenceDate),
      startDate: startOfMonth(referenceDate),
      unit: DateGranularity.Week,
    },
    [PeriodTypeEnum.Year]: {
      endDate: endOfYear(referenceDate),
      startDate: startOfYear(referenceDate),
      unit: DateGranularity.Month,
    },
  }[periodType];
};

export const getPeriodSteps = (period: Period): DateKey[] => {
  return {
    [DateGranularity.Day]: Array.from(
      { length: differenceInDays(period.endDate, period.startDate) + 1 },
      (_, index) => index,
    ).map((item, index) => ({
      granularity: period.unit,
      startDate: addDays(period.startDate, index),
    })),
    [DateGranularity.Week]: Array.from(
      { length: differenceInWeeks(endOfISOWeek(period.endDate), startOfISOWeek(period.startDate)) + 1 },
      (_, index) => index,
    ).map((item, index) => ({
      granularity: period.unit,
      startDate: startOfISOWeek(addWeeks(period.startDate, index)),
    })),
    [DateGranularity.Month]: Array.from(
      { length: differenceInMonths(period.endDate, period.startDate) + 1 },
      (_, index) => index,
    ).map((item, index) => ({
      granularity: period.unit,
      startDate: startOfMonth(addMonths(period.startDate, index)),
    })),
    [DateGranularity.Year]: Array.from(
      { length: differenceInYears(period.endDate, period.startDate) + 1 },
      (_, index) => index,
    ).map((item, index) => ({
      granularity: period.unit,
      startDate: startOfYear(addYears(period.startDate, index)),
    })),
  }[period.unit];
};

export const formatPeriodTitle = (period: Period, endDate: Date): string => {
  const locale = i18n.language ?? '-';
  const currentDate = new Date();

  switch (period.unit) {
    case DateGranularity.Day: {
      const start = [
        !isSameYear(currentDate, period.startDate) && period.startDate.getFullYear(),
        _.capitalize(period.startDate.toLocaleDateString(locale, { month: 'long' })),
        period.startDate.getDate(),
      ]
        .filter((value) => !!value)
        .join(' ');

      const end = [
        !isSameYear(period.startDate, endDate) && endDate.getFullYear(),
        !isSameMonth(period.startDate, endDate) && _.capitalize(endDate.toLocaleDateString(locale, { month: 'long' })),
        endDate.getDate(),
      ]
        .filter((value) => !!value)
        .join(' ');

      return `${start} - ${end}`;
    }
    case DateGranularity.Week: {
      const start = [
        !isSameYear(currentDate, period.startDate) && period.startDate.getFullYear(),
        _.capitalize(period.startDate.toLocaleDateString(locale, { month: 'long' })),
        period.startDate.getDate(),
      ]
        .filter((value) => !!value)
        .join(' ');

      const end = [
        !isSameYear(period.startDate, endDate) && endDate.getFullYear(),
        !isSameMonth(period.startDate, endDate) && _.capitalize(endDate.toLocaleDateString(locale, { month: 'long' })),
        endDate.getDate(),
      ]
        .filter((value) => !!value)
        .join(' ');

      return `${start} - ${end}`;
    }
    case DateGranularity.Month: {
      const start = [
        !isSameYear(currentDate, period.startDate) && period.startDate.getFullYear(),
        _.capitalize(period.startDate.toLocaleDateString(locale, { month: 'long' })),
      ]
        .filter((value) => !!value)
        .join(' ');

      const end = [
        !isSameYear(period.startDate, endDate) && endDate.getFullYear(),
        !isSameMonth(period.startDate, endDate) && _.capitalize(endDate.toLocaleDateString(locale, { month: 'long' })),
      ]
        .filter((value) => !!value)
        .join(' ');

      return `${start} - ${end}`;
    }
    case DateGranularity.Year: {
      const start = [period.startDate.getFullYear()].filter((value) => !!value).join(' ');

      const end = [endDate.getFullYear()].filter((value) => !!value).join(' ');

      return `${start} - ${end}`;
    }
  }
};

export const getStepName = (step: DateKey, period: Period): string => {
  const locale = getLocale();
  let name = '-';
  switch (step.granularity) {
    case DateGranularity.Day: {
      name = format(max([step.startDate, period.startDate]), 'EE', { locale });
      break;
    }
    case DateGranularity.Week: {
      name = format(max([step.startDate, period.startDate]), 'd', { locale });
      name += '-';
      name += format(min([endOfISOWeek(step.startDate), period.endDate]), 'd', { locale });
      break;
    }
    case DateGranularity.Month: {
      name = format(max([step.startDate, period.startDate]), 'MMM', { locale });
      break;
    }
    case DateGranularity.Year: {
      name = format(max([step.startDate, period.startDate]), 'YYYY', { locale });
      break;
    }
  }
  return name.charAt(0).toLocaleUpperCase() + name.slice(1);
};

export const addPeriods = (date: Date, periodType: PeriodTypeEnum, amount: number): Date => {
  switch (periodType) {
    case PeriodTypeEnum.Day:
      return addDays(date, amount);
    case PeriodTypeEnum.Week:
      return addWeeks(date, amount);
    case PeriodTypeEnum.Month:
      return addMonths(date, amount);
    case PeriodTypeEnum.Year:
      return addYears(date, amount);
  }
};

export const getFilter = (
  referenceDate: Date,
  periodType: PeriodTypeEnum,
  categoryId?: string,
  tagId?: string,
  accountId?: string,
): FilterConditionInput[] => {
  const period = getPeriod(periodType, referenceDate);
  const filterConditions = [
    {
      fieldPath: 'baseAmount.amount',
      operator: FilterOperator.LessThan,
      values: ['0'],
    },
    {
      fieldPath: 'bookingDate',
      operator: FilterOperator.GreaterThanOrEqual,
      values: [formatISO(period.startDate, { representation: 'date' })],
    },
    {
      fieldPath: 'bookingDate',
      operator: FilterOperator.LessThanOrEqual,
      values: [formatISO(period.endDate, { representation: 'date' })],
    },
    {
      fieldPath: 'excludeFromAnalytics',
      operator: FilterOperator.Equal,
      values: ['false'],
    },
    categoryId && {
      fieldPath: 'category.id',
      operator: FilterOperator.Equal,
      values: [categoryId],
    },
    tagId && {
      fieldPath: 'tags.id',
      operator: FilterOperator.In,
      values: [tagId],
    },
    accountId && {
      fieldPath: 'account.id',
      operator: FilterOperator.Equal,
      values: [accountId],
    },
  ];

  return filterConditions.filter((condition) => !!condition) as FilterConditionInput[];
};

export const getDefaultSortExpressions = (): SortExpressionInput[] => {
  return [
    {
      direction: SortDirection.Asc,
      fieldPath: 'amount.amount',
    },
  ];
};

export const PLACEHOLDER_CATEGORY_AGGREGATE: TransactionAggregationValueFragment = {
  count: 0,
  key: [
    { __typename: 'DateKey', granularity: DateGranularity.Day, startDate: new Date() },
    {
      __typename: 'TransactionCategory',
      categoryGroup: {
        code: 'PLACEHOLDER',
        id: 'PLACEHOLDER',
        isActive: true,
        name: 'PLACEHOLDER',
      },
      categoryName: 'PLACEHOLDER',
      code: 'PLACEHOLDER',
      id: 'PLACEHOLDER',
      isActive: true,
    },
  ],
  sum: {
    amount: 0,
    currency: '',
  },
};
