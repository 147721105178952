import { ReactComponent as LogoIcon } from '@brand/assets/images/logo.svg';
import { LOGO_SIZE } from '@brand/const';
import { styled } from '@mui/material';

export const Logo = styled(LogoIcon, { shouldForwardProp: (prop) => prop !== 'size' })(() => {
  return {
    display: 'block',
    height: LOGO_SIZE,
    width: 'auto',
  };
});
