import { SxProps, Theme } from '@mui/material';

export const styles: Record<'card' | 'listContent', SxProps<Theme>> = {
  card: {
    '&:first-of-type': {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    '&:not(:last-of-type) > div': {
      borderBottom: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    backgroundColor: (theme) => theme.palette.background.paper,
    width: '100%',
  },
  listContent: {
    margin: (theme) => theme.spacing(0, 2),
    padding: (theme) => theme.spacing(2, 0),
    position: 'relative',
  },
};
