import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, DialogActions } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormInput } from 'common/form/components/FormInput';
import { loginEmailValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  email: string;
}
export interface ResendEmailDialogProps extends DialogComponentProps<void> {
  email: string;
}

export const ResendEmailDialog: React.FC<ResendEmailDialogProps> = ({ email, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const [resendError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { resendSignUp } = useContext(AuthContext);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    email: loginEmailValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: email,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleEmailResend = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    setFormError('');

    const result = await resendSignUp(data.email);
    setIsLoading(false);

    if (result?.formError) {
      setFormError(result.formError.message);
    } else {
      onResolve();
    }
  });

  return (
    <StyledDialog fullWidth onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle> {t('resendEmailDialog.title')} </StyledDialogTitle>
      <StyledDialogSubtitle> {t('resendEmailDialog.subtitle')} </StyledDialogSubtitle>
      <FormProvider {...formMethods}>
        <Box component="form" marginTop={3} noValidate onSubmit={handleEmailResend}>
          <FormInput
            autoComplete="email"
            autoFocus
            fullWidth
            label={t('resendEmailDialog.emailLabel')}
            name="email"
            type="email"
          />
          <DialogActions>
            <LoadingButton fullWidth loading={isLoading} size="large" type="submit">
              {t('resendEmailDialog.button')}
            </LoadingButton>
          </DialogActions>
          {resendError && (
            <StyledDialogFormError content={resendError} title={t('registrationVerification.emailNotSentError')} />
          )}
        </Box>
      </FormProvider>
    </StyledDialog>
  );
};
