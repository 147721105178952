import { SxProps, Theme } from '@mui/material';

export const styles: Record<'manageButton' | 'box', SxProps<Theme>> = {
  box: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    flexGrow: 1,
    padding: (theme) => theme.spacing(3, 4),
    width: '100%',
  },
  manageButton: {
    position: { sm: 'absolute' },
    right: (theme) => ({
      sm: theme.spacing(1),
      xxs: theme.spacing(2),
    }),
  },
};
