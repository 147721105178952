import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { transformNaNtoNull } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { startOfMonth } from 'date-fns';
import { useSetBudgetMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  budget: number;
}

export interface UpdateBudgetDialogProps extends DialogComponentProps<void> {
  asOfDate: Date;
  budget?: number;
}

export const UpdateBudgetDialog: React.FC<UpdateBudgetDialogProps> = ({ asOfDate, budget, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const [setBudget, { loading }] = useSetBudgetMutation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    budget: yup
      .number()
      .transform(transformNaNtoNull)
      .nullable(true)
      .integer(t('form.validator.integer'))
      .moreThan(0, t('form.validator.moreThan', { value: 0 }))
      .required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      budget: budget,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    const sinceDate = startOfMonth(asOfDate);
    await setBudget({
      refetchQueries: ['Budget'],
      variables: { amountInBaseCurrency: data.budget, sinceDate: sinceDate },
    });
    onResolve();
    return;
  });

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={t('cashflowGuardian.updateBudget.subtitle')}
      title={t('cashflowGuardian.updateBudget.title')}
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoComplete="budget" autoFocus fullWidth name="budget" type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <LoadingButton color="primary" loading={loading} sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('cashflowGuardian.updateBudget.save')}
              </Typography>
            </LoadingButton>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
