import { RadioGroup, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { CategoryIcon } from 'components/transactions/CategoryIcon/CategoryIcon';
import { FilterOperator, TransactionCategoryFragment, useTransactionCategoriesQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { ChangeGroupCategoryDialog, ChangeGroupCategoryDialogProps } from './ChangeGroupCategoryDialog';
import { styles } from './PickCategoryDialog.styles';

export type PickCategoryDialogOutput = {
  category: TransactionCategoryFragment;
  changeGroup: boolean;
};

export interface PickCategoryDialogProps extends DialogComponentProps<PickCategoryDialogOutput> {
  category?: TransactionCategoryFragment;
}

export const PickCategoryDialog: React.FC<PickCategoryDialogProps> = (props) => {
  const { i18n, t } = useTranslation();
  const { data } = useTransactionCategoriesQuery({
    variables: {
      filterInput: {
        arguments: [{ filterCondition: { fieldPath: 'isActive', operator: FilterOperator.Equal, values: ['true'] } }],
      },
    },
  });

  const changeGroupCategoryDialog = useDialog<ChangeGroupCategoryDialogProps, boolean>(ChangeGroupCategoryDialog, {
    instanceId: `change-group-category`,
  });

  const handleChange = async (value: TransactionCategoryFragment) => {
    if (value) {
      const changeGroup = await changeGroupCategoryDialog.create({});
      if (changeGroup !== undefined) {
        props.onResolve({ category: value, changeGroup });
      }
    }
  };

  return (
    <ResponsiveSideDialog
      dialogId={props.instanceId}
      header={
        <Typography sx={styles.title} variant="bodyStrongL">
          {t('categoryRadioDialog.header.title')}
        </Typography>
      }
      isOpen={props.isOpen}
      onClose={props.onReject}
    >
      <RadioGroup>
        <Stack sx={styles.content}>
          {data?.page.items
            .slice()
            .sort((a, b) => a.categoryGroup.name.localeCompare(b.categoryGroup.name ?? '', i18n.language))
            .map((option) => (
              <Stack
                key={option.id}
                alignItems="center"
                bgcolor={props.category?.id === option.id ? 'gray.100' : undefined}
                direction="row"
                onClick={() => handleChange(option)}
                spacing={2}
                sx={styles.option}
              >
                <CategoryIcon category={option} width={48} />
                <Typography variant={props.category?.id === option.id ? 'bodyStrongL' : 'bodyL'}>
                  {option.categoryGroup.name}
                </Typography>
              </Stack>
            ))}
        </Stack>
      </RadioGroup>
    </ResponsiveSideDialog>
  );
};
