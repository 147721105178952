import { getUnregisteredUserId } from 'common/auth/unregistered-user';
import { getCognitoAccessToken } from 'common/auth/user-auth';

export interface FileResponse {
  fileId: string;
}

// returns either FileResponse or string that contains error message when need to process
export const uploadFileToDocument = async (file: File, documentId: string): Promise<FileResponse | string> => {
  const token = await getCognitoAccessToken();
  const unregisteredUserId = getUnregisteredUserId();
  const form = new FormData();
  form.append('file', file);
  form.append('documentId', documentId);

  const resp = await fetch('/rest/file/upload', {
    body: form,
    headers: token
      ? { authorization: `Bearer ${token}` }
      : {
          'X-UNREGISTERED-USER-ID': unregisteredUserId ?? '',
        },
    method: 'POST',
  });

  if (resp.status === 200) {
    return resp.json();
  }
  if (resp.status === 400) {
    return resp.text();
  }

  return '';
};

export const deleteFileById = async (fileId: string): Promise<FileResponse | void> => {
  const token = await getCognitoAccessToken();
  const unregisteredUserId = getUnregisteredUserId();

  const resp = await fetch(`/rest/file/delete/${fileId}`, {
    headers: token
      ? { authorization: `Bearer ${token}` }
      : {
          'X-UNREGISTERED-USER-ID': unregisteredUserId ?? '',
        },
  });

  if (resp.status === 200) {
    return resp.json();
  }

  return;
};
