import MenuIcon from '@mui/icons-material/Menu';
import TaskIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import { ReactComponent as BuildingSavingsIcon } from 'assets/icons/flow-switcher/BuildingSavings.svg';
import { ReactComponent as CollisionInsuranceIcon } from 'assets/icons/flow-switcher/CollisionInsurance.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/flow-switcher/CreditCard.svg';
import { ReactComponent as GenericIcon } from 'assets/icons/flow-switcher/Generic.svg';
import { ReactComponent as GenericInsuranceIcon } from 'assets/icons/flow-switcher/GenericInsurance.svg';
import { ReactComponent as InvestmentIcon } from 'assets/icons/flow-switcher/Investment.svg';
import { ReactComponent as LifeInsuranceAuditIcon } from 'assets/icons/flow-switcher/LifeInsuranceAudit.svg';
import { ReactComponent as LifeInsurancePurchaseIcon } from 'assets/icons/flow-switcher/LifeInsurancePurchase.svg';
import { ReactComponent as LoanIcon } from 'assets/icons/flow-switcher/Loan.svg';
import { ReactComponent as MortgagePurchaseIcon } from 'assets/icons/flow-switcher/MortgagePurchase.svg';
import { ReactComponent as MortgageRefinanceIcon } from 'assets/icons/flow-switcher/MortgageRefinance.svg';
import { ReactComponent as MotorLiabilityInsuranceIcon } from 'assets/icons/flow-switcher/MotorLiabilityInsurance.svg';
import { ReactComponent as PropertyInsuranceIcon } from 'assets/icons/flow-switcher/PropertyInsurance.svg';
import { ReactComponent as RetirementPensionSavingsIcon } from 'assets/icons/flow-switcher/RetirementPensionSavings.svg';
import { ReactComponent as SavingsIcon } from 'assets/icons/flow-switcher/Savings.svg';
import { ReactComponent as SupplementaryPensionSavingsIcon } from 'assets/icons/flow-switcher/SupplementaryPensionSavings.svg';
import { useState } from 'react';
import { DashboardMenuItem, MenuItemLink, useSidebarState } from 'react-admin';

import { SubMenu } from './SubMenu';

type MenuName = 'menuProcesses' | 'menuProducts' | 'menuCalculators';

export const CustomMenu: React.FC = () => {
  const [state, setState] = useState({
    menuCalculators: true,
    menuProcesses: true,
    menuProducts: true,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        marginBottom: 1,
        marginTop: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          }),
        width: open ? 240 : 50,
      }}
    >
      <DashboardMenuItem />
      <MenuItemLink leftIcon={<PersonIcon />} primaryText="Klienti" state={{ _scrollToTop: true }} to="/admin/Client" />
      <MenuItemLink
        leftIcon={<TaskIcon />}
        primaryText="Úlohy"
        state={{ _scrollToTop: true }}
        to="/admin/FinancialAdvisorTask"
      />
      <SubMenu
        dense={false}
        handleToggle={() => handleToggle('menuProcesses')}
        icon={<MenuIcon />}
        isOpen={state.menuProcesses}
        name="Procesy"
      >
        <MenuItemLink
          leftIcon={<MortgagePurchaseIcon height={24} width={24} />}
          primaryText="Nová hypotéka"
          state={{ _scrollToTop: true }}
          to="/admin/MortgagePurchaseProcess"
        />
        <MenuItemLink
          leftIcon={<MortgageRefinanceIcon height={24} width={24} />}
          primaryText="Refinancovanie hypotéky"
          state={{ _scrollToTop: true }}
          to="/admin/MortgageRefinanceProcess"
        />
        <MenuItemLink
          leftIcon={<LifeInsurancePurchaseIcon height={24} width={24} />}
          primaryText="Nové životné poistenie"
          state={{ _scrollToTop: true }}
          to="/admin/LifeInsurancePurchaseProcess"
        />
        <MenuItemLink
          leftIcon={<LifeInsuranceAuditIcon height={24} width={24} />}
          primaryText="Audit životného poistenia"
          state={{ _scrollToTop: true }}
          to="/admin/LifeInsuranceAuditProcess"
        />
      </SubMenu>
      <SubMenu
        dense={false}
        handleToggle={() => handleToggle('menuProducts')}
        icon={<MenuIcon />}
        isOpen={state.menuProducts}
        name="Produkty"
      >
        <MenuItemLink
          leftIcon={<MortgagePurchaseIcon height={24} width={24} />}
          primaryText="Hypotéka"
          state={{ _scrollToTop: true }}
          to="/admin/MortgageProduct"
        />
        <MenuItemLink
          leftIcon={<LoanIcon height={24} width={24} />}
          primaryText="Úver"
          state={{ _scrollToTop: true }}
          to="/admin/LoanProduct"
        />
        <MenuItemLink
          leftIcon={<CreditCardIcon height={24} width={24} />}
          primaryText="Kreditná karta"
          state={{ _scrollToTop: true }}
          to="/admin/CreditCardProduct"
        />
        <MenuItemLink
          leftIcon={<LifeInsurancePurchaseIcon height={24} width={24} />}
          primaryText="Životné poistenie"
          state={{ _scrollToTop: true }}
          to="/admin/LifeInsuranceProduct"
        />
        <MenuItemLink
          leftIcon={<MotorLiabilityInsuranceIcon height={24} width={24} />}
          primaryText="PZP"
          state={{ _scrollToTop: true }}
          to="/admin/MotorLiabilityInsuranceProduct"
        />
        <MenuItemLink
          leftIcon={<CollisionInsuranceIcon height={24} width={24} />}
          primaryText="Havarijné poistenie"
          state={{ _scrollToTop: true }}
          to="/admin/CollisionInsuranceProduct"
        />
        <MenuItemLink
          leftIcon={<PropertyInsuranceIcon height={24} width={24} />}
          primaryText="Poistenie majetku"
          state={{ _scrollToTop: true }}
          to="/admin/PropertyInsuranceProduct"
        />
        <MenuItemLink
          leftIcon={<GenericInsuranceIcon height={24} width={24} />}
          primaryText="Poistenie"
          state={{ _scrollToTop: true }}
          to="/admin/GenericInsuranceProduct"
        />
        <MenuItemLink
          leftIcon={<InvestmentIcon height={24} width={24} />}
          primaryText="Investičný produkt"
          state={{ _scrollToTop: true }}
          to="/admin/InvestmentProduct"
        />
        <MenuItemLink
          leftIcon={<RetirementPensionSavingsIcon height={24} width={24} />}
          primaryText="Starobné dôchodkové sporenie"
          state={{ _scrollToTop: true }}
          to="/admin/RetirementPensionSavingsProduct"
        />
        <MenuItemLink
          leftIcon={<SupplementaryPensionSavingsIcon height={24} width={24} />}
          primaryText="Doplnkové dôchodkové sporenie"
          state={{ _scrollToTop: true }}
          to="/admin/SupplementaryPensionSavingsProduct"
        />
        <MenuItemLink
          leftIcon={<SavingsIcon height={24} width={24} />}
          primaryText="Sporenie"
          state={{ _scrollToTop: true }}
          to="/admin/SavingsProduct"
        />
        <MenuItemLink
          leftIcon={<BuildingSavingsIcon height={24} width={24} />}
          primaryText="Stavebné sporenie"
          state={{ _scrollToTop: true }}
          to="/admin/BuildingSavingsProduct"
        />
        <MenuItemLink
          leftIcon={<GenericIcon height={24} width={24} />}
          primaryText="Iný finančný produkt"
          state={{ _scrollToTop: true }}
          to="/admin/GenericProduct"
        />
      </SubMenu>
      <SubMenu
        dense={false}
        handleToggle={() => handleToggle('menuCalculators')}
        icon={<MenuIcon />}
        isOpen={state.menuCalculators}
        name="Kalkulačky"
      >
        <MenuItemLink
          leftIcon={<MortgagePurchaseIcon height={24} width={24} />}
          primaryText="Maximálna hypotéka"
          state={{ _scrollToTop: true }}
          to="/admin/MortgageCalculatorCalculation"
        />
        <MenuItemLink
          leftIcon={<LifeInsurancePurchaseIcon height={24} width={24} />}
          primaryText="Potreba životného poistenia"
          state={{ _scrollToTop: true }}
          to="/admin/LifeInsuranceCalculatorCalculation"
        />
      </SubMenu>
    </Box>
  );
};
