import { Box, Button, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import {
  AccountConnectionSuccessDialog,
  AccountConnectionSuccessDialogProps,
} from '../AccountConnectionSuccessDialog/AccountConnectionSuccessDialog';
import { RenameAccountItem } from './RenameAccountItem';

interface BulkRenameAccountsProps {
  ids: string[];
}

const BulkRenameAccountsDialogContent: React.FC<BulkRenameAccountsDialogProps> = ({ ids, onResolve }) => {
  const { t } = useTranslation();

  const accountConnectionSuccessDialog = useDialog<AccountConnectionSuccessDialogProps, void>(
    AccountConnectionSuccessDialog,
    {
      ids: ids,
      instanceId: `account-connection-success-${ids.join('-')}`,
      navigable: true,
    },
  );

  const handleContinueClick = async () => {
    await onResolve();
    await accountConnectionSuccessDialog.create({ ids });
    return;
  };

  return (
    <Box>
      <Stack alignItems="center" spacing={1}>
        <Typography color="textSecondary" variant="bodyM">
          2/3
        </Typography>
        <Typography variant="headlineXL">
          {t('homepage.accounts.bulkRenameAccountsDialog.title', { count: ids.length })}
        </Typography>
        <Typography textAlign="center" variant="bodyL">
          {t('homepage.accounts.bulkRenameAccountsDialog.subtitle')}
        </Typography>
      </Stack>

      <Stack mt={3} spacing={3}>
        {ids.map((id) => (
          <RenameAccountItem key={id} id={id} />
        ))}
      </Stack>

      <Box mt={8}>
        <Button color="primary" fullWidth onClick={handleContinueClick} size="large">
          <Typography color="white" variant="bodyL">
            {t('homepage.accounts.bulkRenameAccountsDialog.continue')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export interface BulkRenameAccountsDialogProps extends BulkRenameAccountsProps, DialogComponentProps<void> {}

export const BulkRenameAccountsDialog: React.FC<BulkRenameAccountsDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <BulkRenameAccountsDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
