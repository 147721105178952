import { Box } from '@mui/material';
import { ErrorFormProps, FormError } from 'common/components/FormError/FormError';

export const StyledDialogFormError: React.FC<ErrorFormProps> = (props) => {
  return (
    <Box mt={4}>
      <FormError {...props} />
    </Box>
  );
};
