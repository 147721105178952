import { SxProps, Theme } from '@mui/material';

const ICON_SIZE = '16px';

const iconStyles: SxProps<Theme> = {
  borderRadius: '50%',
  height: ICON_SIZE,
  width: ICON_SIZE,
};

export const styles: Record<'radioBox' | 'deselectedIcon' | 'selectedIcon' | 'radioBoxSelected', SxProps<Theme>> = {
  deselectedIcon: {
    ...iconStyles,
    border: (theme) => `2px solid ${theme.palette.gray[600]}`,
  },
  radioBox: {
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
      border: (theme) => `1px solid ${theme.palette.gray['500']}`,
    },
    '.MuiFormControlLabel-label': {
      flexGrow: 1,
    },
    border: (theme) => `1px solid ${theme.palette.gray['400']}`,
    borderRadius: '4px',
    margin: 0,
    minWidth: { sm: '296px', xxs: 'none' },
    padding: 2,
    width: '100%',
  },
  radioBoxSelected: { borderColor: (theme) => theme.palette.gray[900] },
  selectedIcon: {
    ...iconStyles,
    border: (theme) => `6px solid ${theme.palette.gray[800]}`,
  },
};
