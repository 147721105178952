import { SxProps, Theme } from '@mui/material';

export const styles: Record<'border' | 'consent' | 'container' | 'header' | 'sections', SxProps<Theme>> = {
  border: {
    borderBottom: (theme) => `1px solid ${theme.palette.gray?.[300]}`,
    margin: (theme) => theme.spacing(3, 0),
  },
  consent: {
    marginTop: (theme) => theme.spacing(2),
  },
  container: {
    alignItems: 'center',
    padding: (theme) => theme.spacing(6, 0, 8),
  },
  header: {
    textAlign: 'center',
  },
  sections: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    padding: (theme) => theme.spacing(8),
    width: '100%',
  },
};
