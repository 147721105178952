import { format } from 'date-fns';
import { TFunction } from 'react-i18next';
import { Frequency, RRule } from 'rrule';

export const formatFrequency = (
  freq: Frequency,
  interval: number,
  t: TFunction<'translation', undefined>,
): string | undefined => {
  switch (freq) {
    case Frequency.DAILY:
      return t('products:financialCalendar.eventDetail.frequency.DAILY.interval', { count: interval });
    case Frequency.WEEKLY:
      return t('products:financialCalendar.eventDetail.frequency.WEEKLY.interval', { count: interval });
    case Frequency.MONTHLY:
      return t('products:financialCalendar.eventDetail.frequency.MONTHLY.interval', { count: interval });
    case Frequency.YEARLY:
      return t('products:financialCalendar.eventDetail.frequency.YEARLY.interval', { count: interval });
    default:
      return;
  }
};

// workarounds for not having compatible iCalendar recurrence libraries on BE & FE

export const createRecurrenceRule = (
  freq?: Frequency | null,
  interval?: number | null,
  until?: Date | null,
): string => {
  return `${freq != null ? `FREQ=${RRule.FREQUENCIES[freq]};` : ''}${interval != null ? `INTERVAL=${interval};` : ''}${
    until != null ? `UNTIL=${format(until, 'yyyyMMdd')};` : ''
  }`.slice(0, -1);
};

export const parseRecurrenceRule = (
  recurrenceRule?: string | null,
): { freq?: Frequency; interval?: number; until?: Date | null } => {
  const parsedRule = !!recurrenceRule ? RRule.parseString(`RRULE:${recurrenceRule}`) : undefined;
  return {
    freq: parsedRule ? parsedRule.freq : undefined,
    interval: parsedRule ? parsedRule.interval : undefined,
    until: parsedRule ? parsedRule.until : undefined,
  };
};
