import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';
import React, { createContext, useMemo, useState } from 'react';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface NotificationPayload extends SnackbarProps {
  message: string;
  type: AlertProps['severity'];
}

export interface NotificationsContext {
  notifications: NotificationPayload[];
  addNotification: (notification: NotificationPayload) => void;
  resetNotifications: () => void;
}

export const NotificationsContext = createContext<NotificationsContext>({
  addNotification: () => {
    return;
  },
  notifications: [],
  resetNotifications: () => {
    return;
  },
});

NotificationsContext.displayName = 'NotificationsContext';

interface Props {
  children: React.ReactNode;
}

export const NotificationsProvider: React.FC<Props> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationPayload[]>([]);

  const addNotification = (payload: NotificationPayload) => {
    setNotifications((state) => [...state, payload]);
  };

  const resetNotifications = () => {
    setNotifications([]);
  };

  const context = useMemo(
    () => ({
      addNotification,
      notifications,
      resetNotifications,
    }),
    [notifications],
  );

  const handleClose = (indexToClose: number) => () => {
    const newState = [...notifications];
    newState.splice(indexToClose, 1);
    setNotifications(newState);
  };

  return (
    <NotificationsContext.Provider value={context}>
      {children}
      {notifications.map(({ message, type, ...snackbarProps }, index) => (
        <Snackbar
          key={message}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          autoHideDuration={type == 'error' ? null : 6000}
          onClose={handleClose(index)}
          open={true}
          {...snackbarProps}
        >
          <Alert severity={type}>{message}</Alert>
        </Snackbar>
      ))}
    </NotificationsContext.Provider>
  );
};
