import { LoadingButton } from '@mui/lab';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

export interface VerifyEmailDialogProps extends DialogComponentProps<void> {
  email: string;
  formErrorContent?: string;
  title: ReactNode;
}

export const VerifyEmailDialog: React.FC<VerifyEmailDialogProps> = ({
  email,
  formErrorContent = '',
  isOpen,
  onResolve,
  title,
}) => {
  const { t } = useTranslation();
  const { resendSignUp } = useContext(AuthContext);
  const [formError, setFormError] = useState(formErrorContent);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEmailResend = async () => {
    setIsLoading(true);
    setFormError('');

    const result = await resendSignUp(email);

    setIsLoading(false);
    if (result?.formError) {
      setFormError(result.formError.message);
    }
  };

  return (
    <StyledDialog fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogSubtitle>{t('registrationVerification.subtitle', { email })}</StyledDialogSubtitle>

      <LoadingButton
        color="tertiaryButton"
        loading={isLoading}
        onClick={handleEmailResend}
        size="small"
        sx={styles.buttonLink}
      >
        {t('registrationVerification.sendAgainButton')}
      </LoadingButton>

      {formError && (
        <StyledDialogFormError content={formError} title={t('registrationVerification.emailNotSentError')} />
      )}
    </StyledDialog>
  );
};
