// eslint-disable-next-line no-restricted-imports
import { Box, InputLabel as MuiInputLabel } from '@mui/material';
import { ReactComponent as QuestionIcon } from 'assets/icons/QuestionBig.svg';

import { LightTooltip } from '../LightTooltip/LightTooltip';
import { styles } from './InputLabel.styles';

interface Props {
  label: string | React.ReactNode;
  helperText?: string;
  size?: 'large' | 'medium';
}

export const InputLabel: React.FC<Props> = ({ helperText, label, size }) => (
  <Box sx={styles.label}>
    <MuiInputLabel
      htmlFor="component-outlined"
      sx={{ ...styles.muiLabel, ...(size !== 'medium' ? { fontSize: '16px' } : { fontSize: '13px' }) }}
    >
      {label}
    </MuiInputLabel>
    {!!helperText && (
      <LightTooltip arrow placement="top" title={helperText}>
        <Box sx={styles.info}>
          <QuestionIcon height={16} width={16} />
        </Box>
      </LightTooltip>
    )}
  </Box>
);
