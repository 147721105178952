import { LifeInsuranceState } from 'components/flows/common/lifeInsurance/state/lifeInsuranceTypes';

import { UploadedFile } from '../../../../common/hooks/useFilesUpload';

export enum FileStatusEnum {
  loading = 'loading',
  success = 'success',
  error = ' error',
}

export interface LifeInsuranceAuditState extends LifeInsuranceState {
  files?: UploadedFile[];
  documentId?: string;
}
