import { Box } from '@mui/material';
import { Header } from 'common/components/Header/Header';
import { useScrollToTop } from 'common/hooks/useScrollToTop';
import { LoadingIndicatorProvider } from 'common/providers/LoadingIndicatorProvider/LoadingIndicatorProvider';

import { styles } from './CalculatorLayout.styles';

interface Props {
  children: React.ReactNode;
  fullWidth?: boolean;
}

export const CalculatorLayout: React.FC<Props> = ({ children, fullWidth }) => {
  useScrollToTop();

  return (
    <Box sx={styles.page}>
      <Header />

      <LoadingIndicatorProvider hideCommonLoadingTitle>
        <Box component="main" maxWidth={fullWidth ? 842 : 752} sx={styles.main}>
          {children}
        </Box>
      </LoadingIndicatorProvider>
    </Box>
  );
};
