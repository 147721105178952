import { Mapper, SportCheckboxType, Sports } from 'components/flows/common/lifeInsurance/state/lifeInsuranceTypes';
import { LifeInsuranceSports } from 'const';
import {
  LifeInsuranceApplicantFragment,
  LifeInsuranceSportInput,
  LifeInsuranceWayOfPracticingSportType,
} from 'generated/graphql';
import _ from 'lodash';

export const mapSports: Mapper<Sports, LifeInsuranceSportInput[], LifeInsuranceApplicantFragment['practicedSports']> = {
  parse: (practicedSports) => parseDefaultValues(practicedSports),
  serialize: (practicedSports) => serializeValues(practicedSports),
};

const getSportsNames = (
  defaultValues: LifeInsuranceSportInput[],
  type: LifeInsuranceWayOfPracticingSportType,
): string[] => defaultValues.filter((sport) => sport.wayOfPracticing === type).map((sport) => sport.name);

const parseSports = (names: LifeInsuranceSports[]): SportCheckboxType =>
  names.reduce((obj, key) => {
    obj[key] = true;
    return obj;
  }, {} as { [key in LifeInsuranceSports]: true });

const parseDefaultValues = (defaultValues?: LifeInsuranceApplicantFragment['practicedSports'] | null): Sports => {
  if (!defaultValues) return {} as Sports;

  const sportsArray = Object.values(LifeInsuranceSports);

  const organized = getSportsNames(defaultValues, LifeInsuranceWayOfPracticingSportType.Organized);
  const professional = getSportsNames(defaultValues, LifeInsuranceWayOfPracticingSportType.Professional);
  const recreational = getSportsNames(defaultValues, LifeInsuranceWayOfPracticingSportType.Recreational);

  const organizedMore = _.difference(organized, sportsArray);
  const professionalMore = _.difference(professional, sportsArray);
  const recreationalMore = _.difference(recreational, sportsArray);

  const wayOfPracticing = {
    [LifeInsuranceWayOfPracticingSportType.Organized]: !_.isEmpty(organized),
    [LifeInsuranceWayOfPracticingSportType.Professional]: !_.isEmpty(professional),
    [LifeInsuranceWayOfPracticingSportType.Recreational]: !_.isEmpty(recreational),
  };

  return {
    sportsOrganized: parseSports(_.difference(organized, organizedMore) as LifeInsuranceSports[]),
    sportsOrganizedMore: organizedMore[0],
    sportsProfessional: parseSports(_.difference(professional, professionalMore) as LifeInsuranceSports[]),
    sportsProfessionalMore: professionalMore[0],
    sportsRecreational: parseSports(_.difference(recreational, recreationalMore) as LifeInsuranceSports[]),
    sportsRecreationalMore: recreationalMore[0],
    wayOfPracticing: wayOfPracticing,
  };
};

const serializeSports = (
  sports: SportCheckboxType | undefined,
  type: LifeInsuranceWayOfPracticingSportType,
): LifeInsuranceSportInput[] => {
  if (!sports) {
    return [];
  }

  return Object.keys(_.fromPairs(Object.entries(sports).filter((item) => item.includes(true)))).map((sport) => ({
    name: sport,
    wayOfPracticing: type,
  }));
};

const serializeValues = (values?: Sports | null): LifeInsuranceSportInput[] => {
  if (!values) {
    return [];
  }

  const organized = values.wayOfPracticing?.ORGANIZED
    ? serializeSports(values.sportsOrganized, LifeInsuranceWayOfPracticingSportType.Organized)
    : [];
  const professional = values.wayOfPracticing?.PROFESSIONAL
    ? serializeSports(values.sportsProfessional, LifeInsuranceWayOfPracticingSportType.Professional)
    : [];
  const recreational = values.wayOfPracticing?.RECREATIONAL
    ? serializeSports(values.sportsRecreational, LifeInsuranceWayOfPracticingSportType.Recreational)
    : [];

  values.sportsOrganized?.MORE &&
    values.sportsOrganizedMore &&
    organized.push({
      name: values.sportsOrganizedMore,
      wayOfPracticing: LifeInsuranceWayOfPracticingSportType.Organized,
    });

  values.sportsProfessional?.MORE &&
    values.sportsProfessionalMore &&
    professional.push({
      name: values.sportsProfessionalMore,
      wayOfPracticing: LifeInsuranceWayOfPracticingSportType.Professional,
    });

  values.sportsRecreational?.MORE &&
    values.sportsRecreationalMore &&
    recreational.push({
      name: values.sportsRecreationalMore,
      wayOfPracticing: LifeInsuranceWayOfPracticingSportType.Recreational,
    });

  return _.flatten([organized, professional, recreational]);
};
