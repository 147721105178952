import { withDefaultCurrency } from 'common/utils/formatUtils';
import { CollisionInsuranceProductFragment, CollisionInsuranceProductInput, ProductStatus } from 'generated/graphql';

export type MapCollisionInsuranceProductToInput = (
  product?: CollisionInsuranceProductFragment,
) => CollisionInsuranceProductInput;

export const mapCollisionInsuranceProductToInput: MapCollisionInsuranceProductToInput = (
  product = {
    creationTimestamp: new Date(),
    documents: [],
    enableFinancialAdvisorCare: true,
    enableNotifications: true,
    id: '',
    status: ProductStatus.Active,
    statusChangeDate: new Date(),
  },
) => {
  return {
    contractEndDate: product.contractEndDate,
    contractNumber: product.contractNumber,
    enableFinancialAdvisorCare: product.enableFinancialAdvisorCare,
    enableNotifications: product.enableNotifications,
    institutionId: product.institution?.id,
    institutionName: product.institutionName,
    insuringPerson: {
      name: product.insuringPerson?.name,
      type: product.insuringPerson?.type,
    },
    name: product.name,
    paymentInfo: {
      constantSymbol: product.paymentInfo?.constantSymbol,
      ibanForPayment: product.paymentInfo?.ibanForPayment,
      specificSymbol: product.paymentInfo?.specificSymbol,
      variableSymbol: product.paymentInfo?.variableSymbol,
    },
    paymentPeriod: {
      expectedAmount: withDefaultCurrency(product.paymentPeriod?.expectedAmount),
      periodCount: product.paymentPeriod?.periodCount,
      periodTypeCode: product.paymentPeriod?.periodTypeCode,
      referenceDate: product.paymentPeriod?.expectedDate,
    },
    status: product.status,
    statusChangeDate: product.statusChangeDate,
    vehicleInfo: {
      licenseNumber: product.vehicleInfo?.licenseNumber,
      owner: {
        dateOfBirth: product.vehicleInfo?.owner?.dateOfBirth,
        numberOfRecentCollisions: product.vehicleInfo?.owner?.numberOfRecentCollisions,
        postalCode: product.vehicleInfo?.owner?.postalCode,
        yearOfLastCollision: product.vehicleInfo?.owner?.yearOfLastCollision,
      },
      type: product.vehicleInfo?.type,
    },
  };
};
