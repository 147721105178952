import { Box } from '@mui/material';
import { Breadcrumb } from 'common/components/Breadcrumb/Breadcrumb';
import { useScrollToTop } from 'common/hooks/useScrollToTop';
import { LoadingIndicatorProvider } from 'common/providers/LoadingIndicatorProvider/LoadingIndicatorProvider';

import { FlowsHeader } from '../FlowsHeader/FlowsHeader';
import { fullWidthStyles, styles } from './FlowsLayout.styles';

interface Props {
  children: React.ReactNode;
  progressBar?: React.ReactNode;
  navigationText: string;
  hideCommonLoadingTitle?: boolean;
  onSummaryClick?: () => void;
  onSaveClick?: () => void;
  fullWidth?: boolean;
}

export const FlowsLayout: React.FC<Props> = ({
  children,
  fullWidth,
  hideCommonLoadingTitle,
  navigationText,
  onSaveClick,
  onSummaryClick,
  progressBar,
}) => {
  useScrollToTop();

  const curStyles = { ...styles, ...(fullWidth ? fullWidthStyles : {}) };

  return (
    <Box sx={curStyles.page}>
      <Box sx={curStyles.headerWrapper}>
        <FlowsHeader onSaveClick={onSaveClick} onSummaryClick={onSummaryClick}>
          <Breadcrumb text={navigationText} to={'#'} />
        </FlowsHeader>
        {!!progressBar && <Box sx={curStyles.progressBarMobile}>{progressBar}</Box>}
      </Box>
      <LoadingIndicatorProvider hideCommonLoadingTitle={hideCommonLoadingTitle}>
        <Box component="main" sx={curStyles.main}>
          {children}
          {!!progressBar && <Box sx={curStyles.progressBarWideScreen}>{progressBar}</Box>}
        </Box>
      </LoadingIndicatorProvider>
    </Box>
  );
};
