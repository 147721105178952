import { Box, Button, Stack, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import {
  CashflowGuardianProductTourDialog,
  CashflowGuardianProductTourDialogProps,
} from '../CashflowGuardianProductTourDialog/CashflowGuardianProductTourDialog';

export const SetBudgetPromptContent: React.FC = () => {
  const { t } = useTranslation();

  const setBudgetIntroDialog = useDialog<CashflowGuardianProductTourDialogProps, void>(
    CashflowGuardianProductTourDialog,
    {
      instanceId: 'cashflow-guardian-product-tour',
      navigable: true,
    },
  );

  const handleStartSetBudgetFlow = () => {
    setBudgetIntroDialog.create({});
  };

  return (
    <Stack alignItems="flex-start" height="100%" justifyContent="space-between">
      <Typography variant="bodyM">{t('cashflowGuardian.widget.budgetPrompt.description')}</Typography>
      <Box ml={-1.5}>
        <Button color="tertiaryButton" onClick={handleStartSetBudgetFlow}>
          <Typography color="blue.dark">{t('cashflowGuardian.widget.budgetPrompt.linkLabel')}</Typography>
        </Button>
      </Box>
    </Stack>
  );
};
