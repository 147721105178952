import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { addYears, format, isToday, isTomorrow } from 'date-fns';
import { CalendarEventInstanceFragment, useCalendarEventInstancesQuery } from 'generated/graphql';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddEventDialog } from './AddEventDialog/AddEventDialog';
import { EventListItem } from './EventListItem';
import { styles } from './FinancialCalendarDialog.styles';

interface FinancialCalendarDialogContentProps {
  loading: boolean;
  events?: CalendarEventInstanceFragment[] | null;
}

const FinancialCalendarDialogContent: React.FC<FinancialCalendarDialogContentProps> = ({ events, loading }) => {
  const { t } = useTranslation();

  const eventsByDate = _(events)
    .groupBy('date')
    .toPairs()
    .sortBy((item) => new Date(item[0]))
    .value();

  return (
    <Stack spacing={6}>
      <Stack spacing={1}>
        <Typography variant="headlineXL">{t('products:financialCalendar.title')}</Typography>
        <Typography variant="bodyM">{t('products:financialCalendar.subtitle')}</Typography>
      </Stack>

      {loading ? (
        <Skeleton height={400} variant="rectangular" />
      ) : (
        <Stack spacing={6}>
          {eventsByDate.map((item) => (
            <Stack key={new Date(item[0]).toISOString()} spacing={1.5}>
              <Typography variant="headlineXS">
                {isToday(new Date(item[0])) && t('products:financialCalendar.dates.today')}
                {isTomorrow(new Date(item[0])) && t('products:financialCalendar.dates.tomorrow')}
                {!isToday(new Date(item[0])) &&
                  !isTomorrow(new Date(item[0])) &&
                  format(new Date(item[0]), 'd. M. yyyy')}
              </Typography>
              {item[1].map((event) => (
                <EventListItem key={event.id} event={event} />
              ))}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export const FinancialCalendarDialog: React.FC<DialogComponentProps<void>> = ({ instanceId, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const [startDate] = useState(new Date());

  const { data, loading } = useCalendarEventInstancesQuery({
    fetchPolicy: 'no-cache',
    skip: !isAuthenticated,
    variables: {
      endDate: addYears(startDate, 10),
      startDate: startDate,
    },
  });

  const addEventDialog = useDialog<DialogComponentProps<void>, void>(AddEventDialog, {
    instanceId: 'add-event',
    navigable: true,
  });

  const handleAddEvent = async () => {
    await addEventDialog.create({});
  };

  return (
    <ResponsiveSideDialog
      dialogId={instanceId}
      header={
        <Button color="tertiaryButton" onClick={handleAddEvent} sx={styles.addButton}>
          <Typography color="blue.dark">{t('products:financialCalendar.addEvent.button')}</Typography>
        </Button>
      }
      isOpen={isOpen}
      onClose={onResolve}
    >
      <FinancialCalendarDialogContent events={data?.eventInstances} loading={loading} />
    </ResponsiveSideDialog>
  );
};
