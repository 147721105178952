import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/Bin.svg';
import { ReactComponent as AddIcon } from 'assets/icons/Plus.svg';
import { ButtonWithIcon } from 'common/components/Buttons/ButtonWithIcon';
import { InputField } from 'components/flows/common/InputStep/InputField';
import { Input } from 'components/flows/common/InputStep/InputFieldBase';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styles } from './NestedFields.styles';

interface Props<T extends FieldValues> {
  fields: Input<T>[];
  basePath: string;
  title: string;
  defaultExpanded?: boolean;
}

export const NestedFields = <T extends FieldValues>({
  basePath,
  defaultExpanded = false,
  fields,
  title,
}: Props<T>): React.ReactElement => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleAdd = () => setExpanded(true);
  const handleDelete = () => setExpanded(false);
  return (
    <Stack spacing={4} sx={styles.container}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="bodyStrongXXL">{title}</Typography>
        {expanded ? (
          <IconButton color="tertiaryButton" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <ButtonWithIcon color="tertiaryButton" icon={<AddIcon width={16} />} onClick={handleAdd}>
            {t('flows/common:calculator.inputs.add')}
          </ButtonWithIcon>
        )}
      </Stack>
      {expanded &&
        fields.map((field, fieldIndex) => (
          <InputField
            key={`${basePath}.${field.name}`}
            autoFocus={fieldIndex === 0}
            basePath={basePath}
            field={field}
            sx={styles.input}
          />
        ))}
    </Stack>
  );
};
