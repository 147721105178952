import { Button, FormControlLabel, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { FormCheckBox } from 'common/form/components/FormCheckBox';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FilterConditionInput, FilterOperator, TransactionTypeCode } from 'generated/graphql';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TransactionTypeTranslations } from 'translations/enums';

import { styles } from './styles';

interface FormValues {
  [key: string]: boolean;
}

export interface TypeSelectDialogProps extends DialogComponentProps<FilterConditionInput> {
  filter?: FilterConditionInput;
}

export const TypeSelectDialog: React.FC<TypeSelectDialogProps> = (props) => {
  const { t } = useTranslation();

  const defaultValues = _.fromPairs(props.filter?.values?.map((value) => [value, true]));

  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  const handleSubmit = formMethods.handleSubmit(async (formData) => {
    const values = _.entries(formData)
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    const newFilter: FilterConditionInput = {
      fieldPath: 'transactionTypeCode',
      operator: FilterOperator.In,
      values,
    };
    props.onResolve(newFilter);
  });

  return (
    <FormProvider {...formMethods}>
      <ResponsiveSideDialog
        dialogId={props.instanceId}
        header={
          <>
            <Typography sx={styles.title} variant="bodyStrongL">
              {t('filterTransactions.type.title')}
            </Typography>
            <Button color="tertiaryButton" onClick={handleSubmit} sx={styles.submit}>
              <Typography color="blue.dark" variant="bodyStrongL">
                {t('filterTransactions.type.header.submit')}
              </Typography>
            </Button>
          </>
        }
        isOpen={props.isOpen}
        onClose={props.onReject}
      >
        <Stack sx={styles.content}>
          {[
            TransactionTypeCode.Transfer,
            TransactionTypeCode.Charge,
            TransactionTypeCode.Cash,
            TransactionTypeCode.Fee,
            TransactionTypeCode.Bonus,
            TransactionTypeCode.Other,
          ].map((transactionTypeCode) => (
            <FormControlLabel
              key={transactionTypeCode}
              control={<FormCheckBox name={transactionTypeCode} />}
              label={TransactionTypeTranslations(t)[transactionTypeCode]}
              sx={styles.checkbox}
              tabIndex={0}
            />
          ))}
        </Stack>
      </ResponsiveSideDialog>
    </FormProvider>
  );
};
