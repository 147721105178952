import { gql } from '@apollo/client';
import { apolloClient } from 'apolloClient/apolloClient';
import { withDefaultCurrency } from 'common/utils/formatUtils';
import {
  FilterOperator,
  LifeInsuranceApplicant,
  MortgageCollateral,
  MortgageParticipant,
  ProductStatus,
  PropertyInsuranceInsurance,
  PropertyInsuranceInsuranceType,
  TransactionGroupPeriodTypeCode,
} from 'generated/graphql';
import _ from 'lodash';
import { DataProvider } from 'react-admin';

const adminCalculatorResources = ['MortgageCalculatorCalculation', 'LifeInsuranceCalculatorCalculation'];

const adminProductResources = [
  'BuildingSavingsProduct',
  'CollisionInsuranceProduct',
  'CreditCardProduct',
  'GenericProduct',
  'GenericInsuranceProduct',
  'InvestmentProduct',
  'LifeInsuranceProduct',
  'LoanProduct',
  'MortgageProduct',
  'MotorLiabilityInsuranceProduct',
  'PropertyInsuranceProduct',
  'RetirementPensionSavingsProduct',
  'SavingsProduct',
  'SupplementaryPensionSavingsProduct',
];

const adminProcessResources = [
  'LifeInsurancePurchaseProcess',
  'LifeInsuranceAuditProcess',
  'MortgagePurchaseProcess',
  'MortgageRefinanceProcess',
];

const adminResources = [
  'Account',
  'Client',
  'FinancialAdvisorTask',
  ...adminCalculatorResources,
  ...adminProductResources,
  ...adminProcessResources,
];

const fields: Record<string, string> = {
  Account: 'id creationTimestamp client {id user { id email subject phoneNumber }} consentExpired accessUntil',
  BuildingSavingsProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  Client:
    'id creationTimestamp updateTimestamp user { id email subject phoneNumber displayName creationTimestamp updateTimestamp }',
  CollisionInsuranceProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}  vehicleInfo { type licenseNumber owner { dateOfBirth postalCode yearOfLastCollision numberOfRecentCollisions } } insuringPerson { type name }',
  CreditCardProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  FinancialAdvisorTask:
    'id client {id user { id email subject phoneNumber }} creationTimestamp title subtitle text dueDate doneDate isActive stage details {... on ProductFinancialAdvisorTaskDetails { productId productType } }',
  GenericInsuranceProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  GenericProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  Institution: 'id name',
  InvestmentProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  LifeInsuranceAuditProcess:
    'id client {id user { id email subject phoneNumber }} creationTimestamp updateTimestamp inquiry { inquiryCurrentStepUrl inquiryProgress } locked',
  LifeInsuranceCalculatorCalculation:
    'id creationTimestamp client {id user { id email subject phoneNumber }} inquiry { liabilitiesSum financialReserveRisk sportRisk employmentRisk drivingMileage healthRisk } result { score maximalScore necessity significantDomains similarUserRatio }',
  LifeInsuranceProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }} applicants { name surname isInsured isInsuring insurance { supplementalInsurances { name annualCost { amount } insuredSum { amount } } } }',
  LifeInsurancePurchaseProcess:
    'id client {id user { id email subject phoneNumber }} creationTimestamp updateTimestamp inquiry { inquiryCurrentStepUrl inquiryProgress } locked',
  LoanProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  MortgageCalculatorCalculation:
    'id creationTimestamp client {id user { id email subject phoneNumber }} inquiry { numberOfApplicants ageOldestApplicant numberOfDependents salaryIncome { monthlyIncome { amount currency } } entrepreneurshipIncome { yearlyRevenues { amount currency } taxBase { amount currency } taxPayed { amount currency } } ltdIncome { yearlyRevenues { amount currency } yearlyProfit { amount currency } equityShares ceoCompensation { amount currency } } liabilities { balance { amount currency } monthlyInstallment { amount currency } }} result { maxMortgageAmount { amount currency } }',
  MortgageProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }} amount { amount currency } interestRate fixationEndDate fixationLength financialDetailsUpdatedAt collaterals { address } participants { name }',
  MortgagePurchaseProcess:
    'id client {id user { id email subject phoneNumber }} creationTimestamp updateTimestamp inquiry { inquiryCurrentStepUrl inquiryProgress } acceptedTimestamp acceptedProposal { amount { amount } institutionAbbreviation }',
  MortgageRefinanceProcess:
    'id client {id user { id email subject phoneNumber }} creationTimestamp updateTimestamp inquiry { inquiryCurrentStepUrl inquiryProgress } acceptedTimestamp acceptedProposal { amount { amount } institutionAbbreviation }',
  MotorLiabilityInsuranceProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }} vehicleInfo { type licenseNumber owner { dateOfBirth postalCode yearOfLastCollision numberOfRecentCollisions } } insuringPerson { type name }',
  PropertyInsuranceProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }} insuredProperty { type address } insuringPerson { name surname } basicInsurances { type insuredSum { amount currency } annualCost { amount currency } } otherInsurances { name insuredSum { amount currency } annualCost { amount currency } }',
  RetirementPensionSavingsProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  SavingsProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
  SupplementaryPensionSavingsProduct:
    'id creationTimestamp client {id user { id email subject phoneNumber }} institution { id name } institutionName name enableNotifications enableFinancialAdvisorCare contractNumber contractSignDate contractEndDate paymentInfo { ibanForPayment, variableSymbol, constantSymbol, specificSymbol } paymentPeriod { periodTypeCode periodCount referenceDate expectedDate expectedAmount { amount currency } } status statusChangeDate documents { id files { id }}',
};

function extractFieldPath(field: string): string {
  const fieldPattern = /^\[(.*?)\]$/;
  return fieldPattern.test(field) ? field.replace(fieldPattern, '$1') : field;
}

export const dataProvider: DataProvider = {
  create: async (resource, params) => {
    return adminProductResources.includes(resource)
      ? apolloClient
          .mutate({
            mutation: gql`
          mutation ($clientId: ID!, $product: ${resource}Input!) {
            adminCreate${resource}(clientId: $clientId, product: $product) {
              ${fields[resource]}
            }
          }`,
            variables: {
              clientId: params.data.clientId,
              product: _.merge(
                _.omit(params.data, [
                  '__typename',
                  'clientId',
                  'creationTimestamp',
                  'institution',
                  'paymentInfo.__typename',
                  'paymentPeriod.__typename',
                  'paymentPeriod.expectedAmount',
                  'paymentPeriod.expectedDate',
                  'vehicleInfo.__typename',
                  'vehicleInfo.owner.__typename',
                  'insuringPerson.__typename',
                  'insuredProperty.__typename',
                  'amount',
                  'collaterals',
                  'participants',
                  'basicInsurances',
                  'otherInsurances',
                  'applicants',
                ]),
                {
                  amount: resource == 'MortgageProduct' ? withDefaultCurrency(params.data.amount) : undefined,
                  applicants:
                    resource == 'LifeInsuranceProduct'
                      ? params.data.applicants?.map((applicant: LifeInsuranceApplicant) => ({
                          insurance: {
                            deathInsuranceAnnualCost: {
                              amount: 0,
                              currency: 'EUR',
                            },
                            deathInsuranceInsuredSum: {
                              amount: 0,
                              currency: 'EUR',
                            },
                            supplementalInsurances:
                              applicant.insurance?.supplementalInsurances?.map((supplementalInsurance) => ({
                                annualCost: {
                                  amount: supplementalInsurance.annualCost?.amount ?? 0,
                                  currency: supplementalInsurance.annualCost?.currency ?? 'EUR',
                                },
                                insuredSum: {
                                  amount: supplementalInsurance.insuredSum?.amount ?? 0,
                                  currency: supplementalInsurance.insuredSum?.currency ?? 'EUR',
                                },
                                name: supplementalInsurance.name ?? '',
                              })) ?? [],
                          },
                          isInsured: applicant.isInsured,
                          isInsuring: applicant.isInsuring,
                          name: applicant.name,
                          surname: applicant.surname,
                        }))
                      : undefined,
                  basicInsurances:
                    resource == 'PropertyInsuranceProduct'
                      ? params.data.basicInsurances?.map((insurance: PropertyInsuranceInsurance) => ({
                          annualCost: withDefaultCurrency(insurance.annualCost),
                          insuranceRisks: insurance.insuranceRisks ?? [],
                          insuredSum: withDefaultCurrency(insurance.insuredSum),
                          type: insurance.type,
                        }))
                      : undefined,
                  collaterals:
                    resource == 'MortgageProduct'
                      ? params.data.collaterals?.map((collateral: MortgageCollateral) => ({
                          address: collateral.address,
                        }))
                      : undefined,
                  enableFinancialAdvisorCare: true,
                  enableNotifications: true,
                  otherInsurances:
                    resource == 'PropertyInsuranceProduct'
                      ? params.data.otherInsurances?.map((insurance: PropertyInsuranceInsurance) => ({
                          annualCost: withDefaultCurrency(insurance.annualCost),
                          insuranceRisks: [],
                          insuredSum: withDefaultCurrency(insurance.insuredSum),
                          name: insurance.name,
                          type: PropertyInsuranceInsuranceType.Other,
                        }))
                      : undefined,
                  participants:
                    resource == 'MortgageProduct'
                      ? params.data.participants?.map((participant: MortgageParticipant) => ({
                          name: participant.name,
                        }))
                      : undefined,
                  paymentPeriod: {
                    expectedAmount: withDefaultCurrency(params.data.paymentPeriod?.expectedAmount),
                    // default month period for Mortgage
                    periodCount:
                      resource == 'MortgageProduct' && params.data.paymentPeriod?.expectedDate
                        ? 1
                        : params.data.paymentPeriod?.periodCount,
                    periodTypeCode:
                      resource == 'MortgageProduct' && params.data.paymentPeriod?.expectedDate
                        ? TransactionGroupPeriodTypeCode.Month
                        : params.data.paymentPeriod?.periodTypeCode,
                    // set referenceDate as expectedDate
                    referenceDate: params.data.paymentPeriod.expectedDate,
                  },
                  statusChangeDate: new Date(),
                },
              ),
            },
          })
          .then((result) => ({
            data: result.data[`adminCreate${resource}`],
          }))
      : apolloClient
          .mutate({
            mutation: gql`
          mutation ($clientId: ID!, $task: ${resource}Input!) {
            adminCreate${resource}(clientId: $clientId, task: $task) {
              ${fields[resource]}
            }
          }`,
            variables: {
              clientId: params.data.clientId,
              task: _.omit(params.data, ['__typename', 'clientId']),
            },
          })
          .then((result) => ({
            data: result.data[`adminCreate${resource}`],
          }));
  },
  delete: async (resource, params) => {
    return apolloClient
      .mutate({
        mutation:
          resource == 'FinancialAdvisorTask'
            ? gql`
                mutation ($id: ID!) {
                  adminDeactivateFinancialAdvisorTask(taskId: $id)
                }
              `
            : gql`
                mutation ($id: ID!) {
                  adminDelete${resource}(id: $id)
                }
              `,
        variables: {
          id: params.id,
        },
      })
      .then((result) => ({
        data: result.data[`adminDelete${resource}`],
      }));
  },
  deleteMany: async (resource, params) => {
    const mutationName = `adminDelete${adminProcessResources.includes(resource) ? `${resource}es` : `${resource}s`}`;
    return apolloClient
      .mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            ${mutationName}${resource}s(ids: $ids)
          }`,
        variables: {
          ids: params.ids,
        },
      })
      .then((result) => ({
        data: result.data[mutationName],
      }));
  },
  getList: async (resource, { filter, pagination, sort }) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const queryName = `${adminResources.includes(resource) ? 'adminGet' : 'get'}${
      adminProcessResources.includes(resource) ? `${resource}esPage` : `${resource}sPage`
    }`;

    return apolloClient
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ($filterInput: FilterInput, $sortInput: SortInput, $pageInput: PageInput) {
            ${queryName}(filterInput: $filterInput, sortInput: $sortInput, pageInput: $pageInput) {
              items {
                ${fields[resource]}
              }
              totalItems
            }
          }`,
        variables: {
          filterInput: {
            arguments: [
              ...Object.keys(filter).map((key) => ({
                filterCondition: key.startsWith('filterCondition')
                  ? // own filter (e.g. for autocomplete fields)
                    filter[key]
                  : // default filtering by value
                  // TODO: proper handling of nested objects, provisionally we took first element
                  typeof filter[key] == 'object'
                  ? {
                      fieldPath: `${key}.${Object.keys(filter[key])[0]}`,
                      operator: FilterOperator.Equal,
                      values: [filter[key][Object.keys(filter[key])[0]]],
                    }
                  : {
                      fieldPath: key,
                      operator: FilterOperator.Equal,
                      values: [filter[key]],
                    },
              })),
              // in case of product resource filter products with status InPreparation
              adminProductResources.includes(resource)
                ? {
                    filterCondition: {
                      fieldPath: 'status',
                      operator: FilterOperator.NotEqual,
                      values: [ProductStatus.InPreparation],
                    },
                  }
                : undefined,
              // in case of process resource exclude processes of removed clients = with client.user.id
              adminProcessResources.includes(resource)
                ? {
                    filterCondition: {
                      fieldPath: 'client.user.id',
                      operator: FilterOperator.IsNotNull,
                      values: [],
                    },
                  }
                : undefined,
              // in case of FinancialAdvisorTask resource get only active tasks
              resource == 'FinancialAdvisorTask'
                ? {
                    filterCondition: {
                      fieldPath: 'isActive',
                      operator: FilterOperator.Equal,
                      values: [true],
                    },
                  }
                : undefined,
            ].filter((item) => item !== undefined),
          },
          pageInput: { pageIndex: page - 1, pageSize: perPage },
          sortInput: { by: [{ direction: order, fieldPath: extractFieldPath(field) }] },
        },
      })
      .then((result) => ({
        data: result.data[queryName].items,
        total: result.data[queryName].totalItems,
      }));
  },
  getMany: async (resource, params) => {
    const queryName = `${adminResources.includes(resource) ? 'adminGet' : 'get'}${
      adminProcessResources.includes(resource) ? `${resource}esPage` : `${resource}sPage`
    }`;

    return apolloClient
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ($filterInput: FilterInput) {
            ${queryName}(filterInput: $filterInput) {
              items {
                ${fields[resource]}
              }
            }
          }`,
        variables: {
          filterInput: {
            arguments: [
              {
                filterCondition: {
                  fieldPath: 'id',
                  operator: FilterOperator.In,
                  values: params.ids,
                },
              },
            ],
          },
        },
      })
      .then((result) => ({ data: result.data[queryName].items }));
  },
  getManyReference: async (resource, { filter, id, pagination, sort, target }) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const queryName = `${adminResources.includes(resource) ? 'adminGet' : 'get'}${
      adminProcessResources.includes(resource) ? `${resource}esPage` : `${resource}sPage`
    }`;

    return apolloClient
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ($filterInput: FilterInput, $sortInput: SortInput, $pageInput: PageInput) {
            ${queryName}(filterInput: $filterInput, sortInput: $sortInput, pageInput: $pageInput) {
              items {
                ${fields[resource]}
              }
              totalItems
            }
          }`,
        variables: {
          filterInput: {
            arguments: [
              // filter for target with given id
              {
                filterCondition: {
                  fieldPath: target,
                  operator: FilterOperator.Equal,
                  values: [id],
                },
              },
              // other filters (same as for getList)
              ...Object.keys(filter).map((key) => ({
                filterCondition: key.startsWith('filterCondition')
                  ? // own filter (e.g. for autocomplete fields)
                    filter[key]
                  : // default filtering by value
                  // TODO: proper handling of nested objects, provisionally we took first element
                  typeof filter[key] == 'object'
                  ? {
                      fieldPath: `${key}.${Object.keys(filter[key])[0]}`,
                      operator: FilterOperator.Equal,
                      values: [filter[key][Object.keys(filter[key])[0]]],
                    }
                  : {
                      fieldPath: key,
                      operator: FilterOperator.Equal,
                      values: [filter[key]],
                    },
              })),
              // in case of product resource filter products with status InPreparation
              adminProductResources.includes(resource)
                ? {
                    filterCondition: {
                      fieldPath: 'status',
                      operator: FilterOperator.NotEqual,
                      values: [ProductStatus.InPreparation],
                    },
                  }
                : undefined,
              // in case of process resource exclude processes of removed clients = with client.user.id
              adminProcessResources.includes(resource)
                ? {
                    filterCondition: {
                      fieldPath: 'client.user.id',
                      operator: FilterOperator.IsNotNull,
                      values: [],
                    },
                  }
                : undefined,
              // in case of FinancialAdvisorTask resource get only active tasks
              resource == 'FinancialAdvisorTask'
                ? {
                    filterCondition: {
                      fieldPath: 'isActive',
                      operator: FilterOperator.Equal,
                      values: [true],
                    },
                  }
                : undefined,
            ].filter((item) => item !== undefined),
          },
          pageInput: { pageIndex: page - 1, pageSize: perPage },
          sortInput: { by: [{ direction: order, fieldPath: extractFieldPath(field) }] },
        },
      })
      .then((result) => ({
        data: result.data[queryName].items,
        total: result.data[queryName].totalItems,
      }));
  },
  getOne: async (resource, params) => {
    return apolloClient
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ($id: ID!) {
            adminGet${resource}(id: $id) {
              ${fields[resource]}
            }
          }`,
        variables: {
          id: params.id,
        },
      })
      .then((result) => ({ data: result.data[`adminGet${resource}`] }));
  },
  update: async (resource, params) => {
    return apolloClient
      .mutate({
        mutation: gql`
          mutation ($id: ID!, $updatedProduct: ${resource}Input!) {
            adminUpdate${resource}(id: $id, updatedProduct: $updatedProduct) {
              ${fields[resource]}
            }
          }`,
        variables: {
          id: params.id,
          updatedProduct: _.merge(
            _.omit(params.data, [
              '__typename',
              'client',
              'id',
              'creationTimestamp',
              'institution',
              'documents',
              'paymentInfo.__typename',
              'paymentPeriod.__typename',
              'paymentPeriod.expectedAmount',
              'paymentPeriod.expectedDate',
              'vehicleInfo.__typename',
              'vehicleInfo.owner.__typename',
              'insuringPerson.__typename',
              'insuredProperty.__typename',
              'amount',
              'collaterals',
              'participants',
              'basicInsurances',
              'otherInsurances',
              'applicants',
            ]),
            {
              amount: resource == 'MortgageProduct' ? withDefaultCurrency(params.data.amount) : undefined,
              applicants:
                resource == 'LifeInsuranceProduct'
                  ? params.data.applicants?.map((applicant: LifeInsuranceApplicant) => ({
                      insurance: {
                        deathInsuranceAnnualCost: {
                          amount: 0,
                          currency: 'EUR',
                        },
                        deathInsuranceInsuredSum: {
                          amount: 0,
                          currency: 'EUR',
                        },
                        supplementalInsurances:
                          applicant.insurance?.supplementalInsurances?.map((supplementalInsurance) => ({
                            annualCost: {
                              amount: supplementalInsurance.annualCost?.amount ?? 0,
                              currency: supplementalInsurance.annualCost?.currency ?? 'EUR',
                            },
                            insuredSum: {
                              amount: supplementalInsurance.insuredSum?.amount ?? 0,
                              currency: supplementalInsurance.insuredSum?.currency ?? 'EUR',
                            },
                            name: supplementalInsurance.name ?? '',
                          })) ?? [],
                      },
                      isInsured: applicant.isInsured,
                      isInsuring: applicant.isInsuring,
                      name: applicant.name,
                      surname: applicant.surname,
                    }))
                  : undefined,
              basicInsurances:
                resource == 'PropertyInsuranceProduct'
                  ? params.data.basicInsurances?.map((insurance: PropertyInsuranceInsurance) => ({
                      annualCost: withDefaultCurrency(insurance.annualCost),
                      insuranceRisks: insurance.insuranceRisks ?? [],
                      insuredSum: withDefaultCurrency(insurance.insuredSum),
                      type: insurance.type,
                    }))
                  : undefined,
              collaterals:
                resource == 'MortgageProduct'
                  ? params.data.collaterals?.map((collateral: MortgageCollateral) => ({ address: collateral.address }))
                  : undefined,
              institutionId: params.data.institution.id,
              otherInsurances:
                resource == 'PropertyInsuranceProduct'
                  ? params.data.otherInsurances?.map((insurance: PropertyInsuranceInsurance) => ({
                      annualCost: withDefaultCurrency(insurance.annualCost),
                      insuranceRisks: [],
                      insuredSum: withDefaultCurrency(insurance.insuredSum),
                      name: insurance.name,
                      type: PropertyInsuranceInsuranceType.Other,
                    }))
                  : undefined,
              participants:
                resource == 'MortgageProduct'
                  ? params.data.participants?.map((participant: MortgageParticipant) => ({ name: participant.name }))
                  : undefined,
              paymentPeriod: {
                expectedAmount: withDefaultCurrency(params.data.paymentPeriod?.expectedAmount),
                // default month period for Mortgage
                periodCount:
                  resource == 'MortgageProduct' && params.data.paymentPeriod?.expectedDate
                    ? 1
                    : params.data.paymentPeriod?.periodCount,
                periodTypeCode:
                  resource == 'MortgageProduct' && params.data.paymentPeriod?.expectedDate
                    ? TransactionGroupPeriodTypeCode.Month
                    : params.data.paymentPeriod?.periodTypeCode,
                // set referenceDate as expectedDate
                referenceDate: params.data.paymentPeriod.expectedDate,
              },
              statusChangeDate: new Date(),
            },
          ),
        },
      })
      .then((result) => ({
        data: result.data[`adminUpdate${resource}`],
      }));
  },
  updateMany: async () => {
    // placeholder, currently not used
    return apolloClient
      .mutate({
        mutation: gql``,
      })
      .then((result) => ({
        data: result.data,
      }));
  },
};
