import { Input } from 'components/flows/common/InputStep/InputFieldBase';
import { TFunction } from 'react-i18next';

import { NestedInput } from '../CalculatorSection/CalculatorSection';
import { MortgageCalculatorFormValues } from './MortgageCalculator.schema';

type MortgageCalculatorSection = {
  continueButtonLabel?: string;
  inputs:
    | Input<MortgageCalculatorFormValues[Extract<keyof MortgageCalculatorFormValues, string>]>[]
    | NestedInput<MortgageCalculatorFormValues[Extract<keyof MortgageCalculatorFormValues, string>]>[];
  name: Extract<keyof MortgageCalculatorFormValues, string>;
  title: string;
};

export const getSections = (
  t: TFunction<'translation', undefined>,
  isAuthenticated: boolean,
): MortgageCalculatorSection[] => {
  const defaultSections = [
    {
      continueButtonLabel: t('flows/mortgage/calculator:sections.info.continue'),
      inputs: [
        {
          inputType: 'integer',
          label: t('flows/mortgage/calculator:inputs.info.count.label'),
          name: 'count',
          placeholder: t('flows/mortgage/calculator:inputs.info.count.placeholder'),
        },
        {
          endAdornmentText: t('common:inputEndAdornment.years'),
          inputType: 'integer',
          label: t('flows/mortgage/calculator:inputs.info.age.label'),
          name: 'age',
          placeholder: t('flows/mortgage/calculator:inputs.info.age.placeholder'),
        },
        {
          inputType: 'integer',
          label: t('flows/mortgage/calculator:inputs.info.children.label'),
          name: 'children',
          placeholder: t('flows/mortgage/calculator:inputs.info.children.placeholder'),
        },
      ],
      name: 'info',
      title: t('flows/mortgage/calculator:sections.info.title'),
    },
    {
      continueButtonLabel: t('flows/mortgage/calculator:sections.incomes.continue'),
      inputs: [
        {
          fields: [
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.employment.label'),
              name: 'monthlyIncome',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.employment.placeholder'),
            },
          ],
          name: 'employment',
          title: t('flows/mortgage/calculator:inputs.incomes.employment.title'),
        },
        {
          fields: [
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.taxBase.label'),
              name: 'taxBase',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.taxBase.placeholder'),
            },
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.taxPayed.label'),
              name: 'taxPayed',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.taxPayed.placeholder'),
            },
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.revenue.label'),
              name: 'revenue',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.revenue.placeholder'),
            },
          ],
          name: 'entrepreneurship',
          title: t('flows/mortgage/calculator:inputs.incomes.entrepreneurship.title'),
        },
        {
          fields: [
            {
              endAdornmentText: t('common:inputEndAdornment.percent'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.ltd.equityShares.label'),
              name: 'equityShares',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.ltd.equityShares.placeholder'),
            },
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.ltd.yearlyRevenues.label'),
              name: 'yearlyRevenues',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.ltd.yearlyRevenues.placeholder'),
            },
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.ltd.yearlyProfit.label'),
              name: 'yearlyProfit',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.ltd.yearlyProfit.placeholder'),
            },
            {
              endAdornmentText: t('common:inputEndAdornment.eur'),
              inputType: 'number',
              label: t('flows/mortgage/calculator:inputs.incomes.ltd.ceoCompensation.label'),
              name: 'ceoCompensation',
              placeholder: t('flows/mortgage/calculator:inputs.incomes.ltd.ceoCompensation.placeholder'),
            },
          ],
          name: 'ltd',
          title: t('flows/mortgage/calculator:inputs.incomes.ltd.title'),
        },
      ],
      name: 'incomes',
      title: t('flows/mortgage/calculator:sections.incomes.title'),
    },
    {
      continueButtonLabel: t('flows/mortgage/calculator:sections.liabilities.continue'),
      inputs: [
        {
          endAdornmentText: t('common:inputEndAdornment.eur'),
          inputType: 'number',
          label: t('flows/mortgage/calculator:inputs.liabilities.installment.label'),
          name: 'installment',
          placeholder: t('flows/mortgage/calculator:inputs.liabilities.installment.placeholder'),
        },
        {
          endAdornmentText: t('common:inputEndAdornment.eur'),
          inputType: 'number',
          label: t('flows/mortgage/calculator:inputs.liabilities.balance.label'),
          name: 'balance',
          placeholder: t('flows/mortgage/calculator:inputs.liabilities.balance.placeholder'),
        },
      ],
      name: 'liabilities',
      title: t('flows/mortgage/calculator:sections.liabilities.title'),
    },
  ] as MortgageCalculatorSection[];

  const contactSection = !isAuthenticated
    ? [
        {
          inputs: [
            {
              inputType: 'text',
              label: t('flows/common:calculator.inputs.contact.email.label'),
              name: 'email',
              placeholder: t('flows/common:calculator.inputs.contact.email.placeholder'),
            },
            {
              inputType: 'text',
              label: t('flows/common:calculator.inputs.contact.phone.label'),
              name: 'phone',
              placeholder: t('flows/common:calculator.inputs.contact.phone.placeholder'),
            },
          ],
          name: 'contact',
          title: t('flows/mortgage/calculator:sections.contact.title'),
        } as MortgageCalculatorSection,
      ]
    : [];

  return [...defaultSections, ...contactSection];
};
