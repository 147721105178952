import { ContainerRef, Hex, Scope, ScopeModel } from './types';

export const DEFAULT_SCOPE = 'stack';
export const SCOPE_KEY = 'factoryStack';

declare global {
  interface Window {
    [SCOPE_KEY]: ScopeModel;
  }
}

if (typeof window !== 'undefined') {
  if (!window[SCOPE_KEY]) {
    window[SCOPE_KEY] = {};
  }
}

export const registerContainer = (scope: Scope, ref: ContainerRef): ContainerRef => {
  window[SCOPE_KEY][scope] = ref;

  return ref;
};

export const unregisterContainer = (scope: Scope): void => {
  delete window[SCOPE_KEY][scope];
};

export const getContainer = (scope?: Scope): ContainerRef | undefined => window[SCOPE_KEY][scope || DEFAULT_SCOPE];

export const hexGen = (len: number = 12): Hex => {
  const maxlen = 8;
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < len) {
    r = r + hexGen(len - maxlen);
  }
  return r;
};
