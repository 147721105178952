import { Box, SxProps, Theme } from '@mui/material';
import { ReactComponent as MoneyhoonAsteriskEmpty } from 'assets/icons/MoneyhoonAsteriskEmpty.svg';
import { ReactComponent as MoneyhoonAsteriskFull } from 'assets/icons/MoneyhoonAsteriskFull.svg';
import { Breadcrumb } from 'common/components/Breadcrumb/Breadcrumb';
import { useScrollToTop } from 'common/hooks/useScrollToTop';

import { FlowsHeader } from '../FlowsHeader/FlowsHeader';
import { styles } from './FlowsIntroLayout.styles';

interface Props {
  children: React.ReactNode;
  navigationText: string;
  introImage: string;
  isMoneyhoon?: boolean;
  asterisk?: boolean;
  background?: 'dark' | 'light';
}

export const FlowsIntroLayout: React.FC<Props> = ({
  asterisk,
  background,
  children,
  introImage,
  isMoneyhoon,
  navigationText,
}) => {
  useScrollToTop();

  return (
    <Box sx={styles().page}>
      <FlowsHeader>
        <Breadcrumb text={navigationText} to={'#'} />
      </FlowsHeader>
      <Box sx={styles().content}>
        <Box component="main" sx={styles().main}>
          {children}
        </Box>
        {isMoneyhoon ? (
          <Box
            sx={
              [
                styles(introImage).imageMoneyhoon,
                background === 'dark' ? styles().darkBackground : styles().lightBackground,
              ] as SxProps<Theme>
            }
          >
            {asterisk && (
              <Box sx={styles().floatingAsteriskEmpty}>
                <MoneyhoonAsteriskEmpty height={128} width={128} />
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={styles(introImage).image}>
            <Box sx={styles().floatingAsteriskEmpty}>
              <MoneyhoonAsteriskEmpty height={128} width={128} />
            </Box>
            <Box sx={styles().floatingAsterisk}>
              <MoneyhoonAsteriskFull height={128} width={128} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
