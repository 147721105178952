import { FlowsLayout } from 'components/flows/common/FlowsLayout/FlowsLayout';
import { Content } from 'components/flows/motorLiabilityInsurance/PurchasePage/Content';
import { useTranslation } from 'react-i18next';

export const MotorLiabilityInsurancePurchasePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FlowsLayout fullWidth={true} navigationText={t('flows.header.navigation.motorLiabilityInsurance')}>
      <Content />
    </FlowsLayout>
  );
};
