import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'header', SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: (theme) => ({
      sm: theme.spacing(3, 4, 2, 4),
      xs: theme.spacing(3, 2, 2, 2),
    }),
    width: '100%',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: (theme) => theme.spacing(5),
    marginLeft: (theme) => theme.spacing(2),
  },
};
