import { SxProps, Theme } from '@mui/material';

export const styles: Record<'row' | 'container' | 'input' | 'button' | 'noResults', SxProps<Theme>> = {
  button: (theme) => ({
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 2),
  }),
  container: (theme) => ({
    backgroundColor: theme.palette.gray[50],
    borderRadius: '10px',
    marginTop: (theme) => theme.spacing(8),
    overflow: 'hidden',
  }),
  input: (theme) => ({
    '& .MuiInputBase-root': {
      '& > *': {
        '&::placeholder': { fontStyle: 'normal' },
        padding: 0,
      },
      '&:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.gray[300],
      },
      backgroundColor: theme.palette.gray[200],
      borderRadius: '6px',
      input: {
        fontSize: 17,
        height: 36,
      },
      padding: theme.spacing(0, 1),
    },
    '& .MuiTextField-root': {
      flexGrow: 1,
    },
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
  }),
  noResults: {
    alignItems: 'center',
    gap: '4',
    marginTop: (theme) => theme.spacing(8),
  },
  row: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    '&:hover': (theme) => ({
      backgroundColor: theme.palette.gray[100],
      cursor: 'pointer',
    }),
    padding: (theme) => theme.spacing(2),
  },
};
