import { SxProps, Theme } from '@mui/material';

export const styles: Record<'card' | 'icon' | 'productsDetected', SxProps<Theme>> = {
  card: {
    '&:hover': (theme) => ({
      backgroundColor: theme.palette.gray[100],
      cursor: 'pointer',
    }),
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2),
  },
  icon: {
    backgroundColor: '#0054B6',
    borderRadius: '8px',
    height: '48px',
    minWidth: '48px',
    width: '48px',
  },
  productsDetected: {
    alignItems: 'center',
    backgroundColor: '#EA3829',
    borderRadius: '50%',
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    width: '20px',
  },
};
