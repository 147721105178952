import { styled } from '@mui/material';
import { ReactComponent as LoaderIcon } from 'assets/icons/Loader.svg';

export const LoadingSpinner = styled(LoaderIcon)(() => ({
  '@keyframes loader': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(90deg)',
    },
  },
  animation: 'loader infinite 1000ms linear',
  height: '16px',
  width: '16px',
}));
