import { Box, Button, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { FileStatusEnum } from 'common/hooks/useFilesUpload';
import { FinancialProductType, ProductBase, ProductStatus } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { ContentDispositionType, useProductFiles } from '../../../common/hooks/useProductFiles';
import { ButtonRow } from '../ButtonRow/ButtonRow';
import { DataProtectionGuarantees } from '../DataProtectionGuarantees/DataProtectionGuarantees';
import { DeleteProductDialog, DeleteProductDialogProps } from '../DeleteProductDialog/DeleteProductDialog';
import { DeleteSuccessDialog, DeleteSuccessDialogProps } from '../DeleteSuccessDialog/DeleteSuccessDialog';
import {
  DocumentUploadSuccessDialog,
  DocumentUploadSuccessDialogProps,
} from '../DocumentUploadSuccessDialog/DocumentUploadSuccessDialog';
import { useProductQuery } from '../useProductQuery';
import { getMapProductToInput, useUpdateProduct } from '../useUpdateProduct';
import { DocumentRow } from './DocumentRow';
import { styles } from './styles';

interface UploadDocumentDialogContentProps {
  documentId: string;
  productId: string;
  type: FinancialProductType;
}

export interface UploadDocumentDialogProps extends DialogComponentProps<void>, UploadDocumentDialogContentProps {}

const UploadDocumentDialogContent: React.FC<UploadDocumentDialogProps> = ({
  documentId,
  onResolve,
  productId,
  type,
}) => {
  const { t } = useTranslation();

  const productQuery = useProductQuery(type, {
    id: productId,
  });

  const [updateProduct] = useUpdateProduct(type);

  const {
    files,
    handleDeleteClick,
    handleDownloadClick,
    handleFileChange,
    handleInputClick,
    handleRetryClick,
    uploadError,
  } = useProductFiles(documentId, productId, type);

  const documentUploadSuccess = useDialog<DocumentUploadSuccessDialogProps, void>(DocumentUploadSuccessDialog, {
    instanceId: 'document-upload-success',
    navigable: true,
  });

  const mapProductToInput = getMapProductToInput(type);
  const handleDone = async () => {
    if (files.filter((file) => file.status == FileStatusEnum.success).length) {
      await updateProduct({
        update: (cache) => {
          cache.modify({
            fields: {
              product: (existing, { readField }) => {
                const newList = {
                  ...existing,
                  items: existing.items.map((item: ProductBase) =>
                    readField('id', item) === productId ? { ...item, status: ProductStatus.Submitted } : item,
                  ),
                };
                return newList;
              },
            },
          });
        },
        variables: {
          id: productId,
          updatedProduct: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(mapProductToInput?.(productQuery.data?.detail as any) ?? {}),
            status: ProductStatus.Submitted,
            statusChangeDate: new Date(),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
        },
      });
      await onResolve();
      documentUploadSuccess.create({
        submitted: true,
      });
    }
  };

  const deleteProductDialog = useDialog<DeleteProductDialogProps, boolean>(DeleteProductDialog, {
    instanceId: 'delete-product',
  });

  const deleteSuccessDialog = useDialog<DeleteSuccessDialogProps, void>(DeleteSuccessDialog, {
    instanceId: 'delete-product-success-dialog',
  });

  const handleDelete = async () => {
    if (productId) {
      const deleted = await deleteProductDialog.create({ id: productId, type });
      if (deleted) {
        await onResolve();
        deleteSuccessDialog.create({});
      }
    }
  };

  return (
    <Stack>
      <Stack gap={3}>
        <Typography textAlign="center" variant="headlineXL">
          {t('products:uploadDocument.title')}
        </Typography>

        <DataProtectionGuarantees />
      </Stack>

      {!!files.length && (
        <Stack gap={3} mt={8}>
          <Typography variant="headlineM">{t('products:uploadDocument.uploaded.title')}</Typography>
          <Stack sx={styles.files}>
            {files.map((file) => (
              <Box key={file.id}>
                <DocumentRow
                  errorMessage={file.error}
                  fileName={file.name}
                  onDelete={() => handleDeleteClick(file.id, file.status)}
                  onDownload={() => handleDownloadClick(file, ContentDispositionType.ATTACHMENT, window.open())}
                  onOpen={() => handleDownloadClick(file, ContentDispositionType.INLINE, window.open())}
                  onRetry={() => handleRetryClick(file.id)}
                  status={file.status}
                />
              </Box>
            ))}
          </Stack>
          <Stack>
            {!!uploadError && (
              <Typography color="error" mb={3} textAlign="center">
                {uploadError.message}
              </Typography>
            )}
            <Button component="label" sx={styles.buttonRow}>
              {t('products:uploadDocument.uploaded.uploadMore')}
              <input hidden onChange={handleFileChange} onClick={handleInputClick} type="file" />
            </Button>
            <Typography color="text.secondary" mt={3} textAlign="center" variant="bodyS">
              {t('products:uploadDocument.note')}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack mt={8}>
        {!files.length && !!uploadError && (
          <Typography color="error" mb={3} textAlign="center">
            {uploadError.message}
          </Typography>
        )}
        {!files.length ? (
          <>
            <Button component="label">
              {t('products:uploadDocument.upload')}
              <input hidden onChange={handleFileChange} type="file" />
            </Button>
            <Typography color="text.secondary" mt={3} textAlign="center" variant="bodyS">
              {t('products:uploadDocument.note')}
            </Typography>
          </>
        ) : (
          <Button disabled={files.some((file) => file.status != FileStatusEnum.success)} onClick={handleDone}>
            {t('products:uploadDocument.done')}
          </Button>
        )}
        <Box sx={styles.deleteButtonRow}>
          <ButtonRow color="red.dark" label={t('products:detail.delete')} onClick={handleDelete} />
        </Box>
      </Stack>
    </Stack>
  );
};

export const UploadDocumentDialog: React.FC<UploadDocumentDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <UploadDocumentDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
