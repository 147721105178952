import { Box, Typography } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';

import { LightTooltip } from '../../common/components/LightTooltip/LightTooltip';
import { styles } from './SettingsPageContent.styles';

interface Props {
  item: string;
  value: string;
  icon?: React.ReactElement;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export const SettingsPageItem: React.FC<Props> = ({ disabled, icon, item, onClick, tooltip, value }) => {
  const editIcon = !disabled ? !!icon ? icon : <EditIcon /> : null;
  const valueBox = (
    <Box sx={styles.value}>
      <Typography component="div" variant="bodyL">
        {value}
      </Typography>
      {editIcon && <Box sx={styles.icon}>{editIcon}</Box>}
    </Box>
  );

  return (
    <Box onClick={onClick} sx={styles.item}>
      <Typography component="div" sx={styles.label} variant="bodyStrongL">
        {item}
      </Typography>
      {tooltip ? (
        <LightTooltip arrow placement="top" title={tooltip}>
          {valueBox}
        </LightTooltip>
      ) : (
        valueBox
      )}
    </Box>
  );
};
