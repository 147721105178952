import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useRenameAccountMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  name: string;
}

export interface RenameAccountDialogProps extends DialogComponentProps<void> {
  id: string;
  name?: string;
}

export const RenameAccountDialog: React.FC<RenameAccountDialogProps> = ({ id, isOpen, name, onResolve }) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [renameAccount, { loading }] = useRenameAccountMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      name: name,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    await renameAccount({ variables: { accountId: id, name: data.name } });
    onResolve();
    return;
  });

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={t('homepage.accounts.accountDetail.renameAccountDialog.text')}
      title={t('homepage.accounts.accountDetail.name')}
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoComplete="name" autoFocus fullWidth name="name" type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <LoadingButton color="primary" loading={loading} sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('homepage.accounts.accountDetail.renameAccountDialog.save')}
              </Typography>
            </LoadingButton>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
