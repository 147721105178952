import { SxProps, Theme } from '@mui/material';

export const styles: Record<'input', SxProps<Theme>> = {
  input: {
    '& + &': {
      marginTop: (theme) => theme.spacing(2),
    },
    width: '100%',
  },
};
