import { normalizeIban } from 'common/utils/formatUtils';
import { isValidIBAN } from 'ibantools';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';

import { i18n } from '../../i18n';

// Inspired by https://github.com/jquense/yup/issues/851#issuecomment-1049705180
// but wth fix for yup.ref
yup.addMethod(yup.string, 'sequence', function (funcList) {
  return this.test(async (value, context) => {
    try {
      for (const func of funcList) {
        await func().validate(value, context);
      }
    } catch ({ message }) {
      return context.createError({ message } as yup.CreateErrorOptions);
    }
    return true;
  });
});

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const termsAndConditionsValidator = () =>
  yup
    .boolean()
    .required(i18n.t('form.validator.termsAndConditionsAreRequired'))
    .oneOf([true], i18n.t('form.validator.termsAndConditionsAreRequired'));

export const personalDataValidator = () =>
  yup
    .boolean()
    .required(i18n.t('form.validator.personalDataAreRequired'))
    .oneOf([true], i18n.t('form.validator.personalDataAreRequired'));

export const emailValidator = () =>
  yup.string().email(i18n.t('form.validator.invalidEmail')).required(i18n.t('form.validator.emailIsRequired')).trim();

export const phoneValidator = () =>
  yup.string().test('phone-number', i18n.t('form.validator.invalidPhone'), (value) => {
    return value ? isValidPhoneNumber(value, 'SK') : true;
  });

export const passwordValidator = () =>
  yup.string().sequence<string>([
    () => yup.string().required(i18n.t('form.validator.passwordIsRequired')),
    () =>
      yup
        .string()
        // Adapted from https://stackoverflow.com/a/55604455, but without symbols or length
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, i18n.t('form.validator.passwordTooWeak')),
    () => yup.string().min(8, i18n.t('form.validator.passwordIsTooShort')),
  ]);

export const loginEmailValidator = () => yup.string().required(i18n.t('form.validator.emailIsRequired')).trim();
export const loginPasswordValidator = () => yup.string().required(i18n.t('form.validator.passwordIsRequired'));

export const fieldIsRequiredValidator = () => yup.string().required(i18n.t('form.validator.fieldIsRequired'));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformNaNtoNull = (value: any) => (isNaN(value) ? null : value);

export const ibanValidator = () =>
  yup.string().test('iban', i18n.t('form.validator.invalidIban'), (value) => {
    return value ? isValidIBAN(normalizeIban(value)) : true;
  });

export const constantSymbolValidator = () =>
  yup.string().matches(/^[0-9]{0,4}$/, i18n.t('form.validator.invalidConstantSymbol'));

export const variableSymbolValidator = () =>
  yup.string().matches(/^[0-9]{0,10}$/, i18n.t('form.validator.invalidVariableSymbol'));

export const specificSymbolValidator = () =>
  yup.string().matches(/^[0-9]{0,10}$/, i18n.t('form.validator.invalidSpecificSymbol'));
