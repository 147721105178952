import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { FilterOperator, SortDirection, useInstitutionsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './SelectBankDialog.styles';
import { SelectBankListItem } from './SelectBankListItem';

const SelectBankDialogContent: React.FC = () => {
  const { t } = useTranslation();

  const { isAuthenticated } = useContext(AuthContext);
  const { data, loading: loadingInstitutions } = useInstitutionsQuery({
    skip: !isAuthenticated,
    variables: {
      filterInput: {
        arguments: [
          {
            filterCondition: {
              fieldPath: 'possibleAispNordigenConnection',
              operator: FilterOperator.Equal,
              values: ['true'],
            },
          },
        ],
      },
      sortInput: {
        by: [{ direction: SortDirection.Asc, fieldPath: 'name' }],
      },
    },
  });

  return (
    <Box>
      <Stack alignItems="center" spacing={1} textAlign="center">
        <Typography variant="headlineXL">{t('homepage.accounts.bankSelector.title')}</Typography>
        <Typography textAlign="center" variant="bodyL">
          {t('homepage.accounts.bankSelector.subtitle', {
            number: data?.getInstitutionsPage.items.length ?? '...',
          })}
        </Typography>
      </Stack>

      <Box mt={3}>
        {loadingInstitutions ? (
          <Stack spacing={1}>
            {[...Array(17)].map((i, x) => (
              <Skeleton key={x} height={48} variant="rectangular" />
            ))}
          </Stack>
        ) : (
          <Stack sx={styles.container}>
            {data?.getInstitutionsPage.items?.map((institution) => (
              <SelectBankListItem key={institution.id} institution={institution} />
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export type SelectBankDialogProps = DialogComponentProps<string, void>;

export const SelectBankDialog: React.FC<SelectBankDialogProps> = ({ instanceId, isOpen, onReject }) => {
  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={onReject}>
      <SelectBankDialogContent />
    </ResponsiveSideDialog>
  );
};
