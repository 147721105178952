import { Box } from '@mui/material';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import { ReactNode } from 'react';

import { styles } from './InputEndAdornment.styles';

interface Props {
  error: boolean;
  children?: ReactNode;
}

export const InputEndAdornment: React.FC<Props> = ({ children, error }) => {
  return (
    <>
      {error ? (
        <Box sx={styles.wrapper}>
          <WarningIcon />
          {children}
        </Box>
      ) : (
        children
      )}
    </>
  );
};
