import { HealthCondition, Mapper } from 'components/flows/common/lifeInsurance/state/lifeInsuranceTypes';
import {
  DisabilityPensionType,
  LifeInsuranceApplicantFragment,
  LifeInsuranceHealthConditionInput,
} from 'generated/graphql';
import _ from 'lodash';

export const mapHealthCondition: Mapper<
  HealthCondition,
  LifeInsuranceHealthConditionInput | null,
  LifeInsuranceApplicantFragment['healthCondition']
> = {
  parse: (healthCondition) => {
    if (!healthCondition) {
      return undefined;
    }
    return {
      disabilityPension: healthCondition.disabilityPension,
      diseasesDescription:
        healthCondition.diseases && healthCondition.diseases[0]?.description
          ? healthCondition.diseases[0].description
          : '',
      hasPermanentInjuryConsequences: healthCondition.hasPermanentInjuryConsequences,
      hasPlannedMedicalProcedure: healthCondition.hasPlannedMedicalProcedure,
      isOnRegularMedication: healthCondition.isOnRegularMedication,
      isOnSickLeave: healthCondition.isOnSickLeave,
      isTreatedForDisease: healthCondition.isTreatedForDisease,
      permanentInjuryConsequencesDescription: healthCondition.permanentInjuryConsequencesDescription,
      plannedMedicalProceduresDescription:
        healthCondition.plannedMedicalProcedures && healthCondition.plannedMedicalProcedures[0]?.description
          ? healthCondition.plannedMedicalProcedures[0].description
          : '',
      plannedMedicalProceduresScheduledFor:
        healthCondition.plannedMedicalProcedures && healthCondition.plannedMedicalProcedures[0]?.scheduledFor
          ? healthCondition.plannedMedicalProcedures[0].scheduledFor
          : '',
      regularMedication: healthCondition.regularMedication,
    };
  },
  serialize: (healthCondition) => {
    if (_.isEmpty(healthCondition)) {
      return null;
    }

    if (!healthCondition) {
      return undefined;
    }

    return {
      disabilityPension: healthCondition.disabilityPension ?? DisabilityPensionType.None,
      diseases: healthCondition.isTreatedForDisease ? [{ description: healthCondition.diseasesDescription ?? '' }] : [],
      hasPermanentInjuryConsequences: healthCondition.hasPermanentInjuryConsequences ?? false,
      hasPlannedMedicalProcedure: healthCondition.hasPlannedMedicalProcedure ?? false,
      isOnRegularMedication: healthCondition.isOnRegularMedication ?? false,
      isOnSickLeave: healthCondition.isOnSickLeave ?? false,
      isTreatedForDisease: healthCondition.isTreatedForDisease ?? false,
      permanentInjuryConsequencesDescription:
        healthCondition.hasPermanentInjuryConsequences && healthCondition.permanentInjuryConsequencesDescription
          ? healthCondition.permanentInjuryConsequencesDescription
          : '',
      plannedMedicalProcedures: healthCondition.hasPlannedMedicalProcedure
        ? [
            {
              description: healthCondition.plannedMedicalProceduresDescription ?? '',
              scheduledFor: healthCondition.plannedMedicalProceduresScheduledFor ?? '',
            },
          ]
        : [],
      regularMedication: healthCondition.regularMedication ?? '',
    };
  },
};
