import { ChevronRight } from '@mui/icons-material';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { HelpTooltip } from 'common/components/HelpTooltip/HelpTooltip';
import { useDialog } from 'common/hooks/useDialog';
import { useState } from 'react';

import { SetValueDialog, SetValueDialogProps } from '../SetValueDialog/SetValueDialog';
import { styles } from './DetailRow.styles';

interface Props<T> {
  label?: string;
  helperText?: string;
  tooltip?: string;
  value?: T;
  transform?: (value: T) => T;
  parse?: (value: T) => T;
  onUpdate: (value: T, path: string) => Promise<void>;
  formatter?: (value: T) => string;
  inputType?: SetValueDialogProps<T>['inputType'];
  inputEndAdornment?: string;
  path: string;
  options?: SetValueDialogProps<T>['options'];
  updatedAt?: Date;
}

export const DetailRow = <T,>({
  formatter,
  helperText,
  inputEndAdornment,
  inputType,
  label,
  onUpdate,
  options,
  parse,
  path,
  tooltip,
  transform,
  value,
}: Props<T>): React.ReactElement => {
  const [loading, setLoading] = useState(false);

  const setValueDialog = useDialog<SetValueDialogProps<T>, T>(SetValueDialog, {
    instanceId: `set-value-${path}`,
  });

  const handleUpdate = async () => {
    try {
      const newValue = await setValueDialog.create({
        defaultValue: value,
        inputEndAdornment,
        inputType,
        options,
        parse,
        subtitle: helperText,
        title: label ?? '',
        transform,
      });
      setLoading(true);
      await onUpdate(newValue, path);
      setLoading(false);
    } catch (e) {
      //pass
    }
  };

  const displayValue = value ? formatter?.(value) ?? `${value}` : '-';

  if (loading) {
    return <Skeleton sx={styles.skeleton} variant="rectangular" />;
  }

  return (
    <Box onClick={handleUpdate} sx={styles.clickableRow}>
      <Stack direction="row" flexGrow={1} justifyContent="space-between">
        <Stack direction="row">
          {!!label && <Typography variant="bodyL">{label}</Typography>}
          {!!tooltip && <HelpTooltip text={tooltip} />}
        </Stack>
        <Typography color={label ? 'textSecondary' : 'textPrimary'} variant="bodyL">
          {displayValue}
        </Typography>
      </Stack>
      <ChevronRight sx={styles.icon} />
    </Box>
  );
};
