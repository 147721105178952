import { InputAdornment } from '@mui/material';
import { dateParser } from 'admin/formatUtils';
import { ClientInput } from 'admin/inputs/ClientInput';
import { ExpectedAmountInput } from 'admin/inputs/ExpectedAmountInput';
import { InstitutionInput } from 'admin/inputs/InstitutionInput';
import { ProductStatus } from 'generated/graphql';
import {
  ArrayInput,
  DateInput,
  Edit,
  FormTab,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

export const MortgageProductEdit: React.FC = () => (
  <Edit mutationMode="pessimistic">
    <TabbedForm>
      <FormTab label="Základné info">
        <ClientInput disabled label="Klient" source="client.id" />
        <InstitutionInput disabled source="institution.id" />
        <TextInput disabled label="Vlastná inštitúcia" source="institutionName" sx={{ width: '40em' }} />
        <TextInput label="Názov produktu" source="name" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Zmluva">
        <TextInput label="Číslo zmluvy" source="contractNumber" sx={{ width: '40em' }} />
        <DateInput label="Dátum podpisu zmluvy" parse={dateParser} source="contractSignDate" />
        <DateInput label="Platnosť zmluvy do" parse={dateParser} source="contractEndDate" />
      </FormTab>

      <FormTab label="Platby">
        <ExpectedAmountInput label="Výška mesačnej splátky" source="paymentPeriod.expectedAmount.amount" />
        <DateInput label="Nasledujúca platba" parse={dateParser} source="paymentPeriod.expectedDate" />
      </FormTab>

      <FormTab label="Informácie o hypotéke">
        <DateInput
          defaultValue={new Date()}
          disabled
          label="platné k"
          parse={dateParser}
          source="financialDetailsUpdatedAt"
        />
        <NumberInput label="Pôvodná výška hypotéky" source="amount.amount" />
        <NumberInput
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          format={(v) => v * 100}
          label="Aktuálna úroková sadzba"
          parse={(v) => parseFloat(v) / 100}
          source="interestRate"
        />
        <NumberInput label="Dĺžka fixácie v rokoch" source="fixationLength" />
        <DateInput label="Výročie fixácie úrokovej sadzby" parse={dateParser} source="fixationEndDate" />
      </FormTab>

      <FormTab label="Založené nehnuteľnosti">
        <ArrayInput label="Založené nehnuteľnosti" source="collaterals">
          <SimpleFormIterator inline>
            <TextInput helperText={false} label="Adresa" source="address" sx={{ width: '40em' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Účastníci úverového vzťahu">
        <ArrayInput label="Účastníci úverového vzťahu" source="participants">
          <SimpleFormIterator inline>
            <TextInput helperText={false} label="Meno" source="name" sx={{ width: '40em' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Status">
        <SelectInput
          choices={[
            { id: ProductStatus.WaitingForSubmission, name: 'WaitingForSubmission' },
            { id: ProductStatus.Processing, name: 'Processing' },
            { id: ProductStatus.Active, name: 'Active' },
          ]}
          label="Status"
          required
          source="status"
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);
