import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as MoneyhoonAsteriskEmpty } from 'assets/icons/MoneyhoonAsteriskEmpty.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { styles } from './NotFoundPageContent.styles';

export const NotFoundPageContent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Box>
      <Box sx={styles.content}>
        <Box sx={styles.asteriskContainer}>
          <Box sx={styles.asteriskImage}>
            <MoneyhoonAsteriskEmpty height={160} width={160}></MoneyhoonAsteriskEmpty>
          </Box>
          <Typography sx={styles.title}>{t('404.title')}</Typography>
        </Box>
        <Typography variant="bodyL">{t('404.subtitle')}</Typography>
        <Button onClick={handleClick} sx={styles.button}>
          {t('404.button')}
        </Button>
      </Box>
    </Box>
  );
};
