import { SxProps, Theme } from '@mui/material';

export const styles: Record<'aggregate' | 'mark' | 'disabled', SxProps<Theme>> = {
  aggregate: {
    '&:hover': (theme) => ({
      backgroundColor: theme.palette.gray[100],
      color: theme.palette.gray[900],
      cursor: 'pointer',
    }),
    padding: (theme) => theme.spacing(2, 2),
  },
  disabled: {
    '&:hover': null,
  },
  mark: {
    borderRadius: '100%',
    height: 10,
    width: 10,
  },
};
