import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'circle', SxProps<Theme>> = {
  circle: {
    alignItems: 'center',
    background: 'linear-gradient(136.41deg, #D5D5D5 0%, #909090 100%)',
    borderRadius: '100%',
    color: (theme) => theme.palette.gray[50],
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    minHeight: 32,
    minWidth: 32,
    width: 32,
  },
  container: {
    backgroundColor: (theme) => theme.palette.gray[100],
    borderRadius: '12px',
    marginTop: (theme) => theme.spacing(4),
    padding: (theme) => theme.spacing(6),
  },
};
