import { LoadingButton } from '@mui/lab';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { format } from 'date-fns';
import { useCreateCalendarEventMutation } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency } from 'rrule';

import { DetailRow } from '../DetailRow/DetailRow';
import { EditEventDateDialog, EditEventDateDialogProps } from '../EditEventDateDialog/EditEventDateDialog';
import {
  EditEventFrequencyDialog,
  EditEventFrequencyDialogProps,
  EventFrequency,
} from '../EditEventFrequencyDialog/EditEventFrequencyDialog';
import { EditEventNameDialog, EditEventNameDialogProps } from '../EditEventNameDialog/EditEventNameDialog';
import { createRecurrenceRule, formatFrequency, parseRecurrenceRule } from '../utils';
import { styles } from './AddEventDialog.styles';

const AddEventDialogContent: React.FC<DialogComponentProps<void>> = ({ onResolve }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [name, setName] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [recurrence, setRecurrence] = useState(false);
  const [recurrenceRule, setRecurrenceRule] = useState<string>();

  const { freq, interval, until } = parseRecurrenceRule(recurrenceRule);

  const eventNameDialog = useDialog<EditEventNameDialogProps, string>(EditEventNameDialog, {
    instanceId: `set-event-name`,
  });

  const handleNameClick = async () => {
    const newName = await eventNameDialog.create({ name });
    setName(newName);
  };

  const eventDateDialog = useDialog<EditEventDateDialogProps, Date>(EditEventDateDialog, {
    instanceId: `set-event-date`,
  });

  const handleDateClick = async () => {
    const newStartDate = await eventDateDialog.create({
      date: startDate,
      subtitle: t('products:financialCalendar.editDateDialog.text'),
      title: t('products:financialCalendar.editDateDialog.title'),
    });
    setStartDate(newStartDate);
  };

  const eventFrequencyDialog = useDialog<EditEventFrequencyDialogProps, EventFrequency>(EditEventFrequencyDialog, {
    instanceId: `set-event-frequency`,
  });

  const handleFrequencyClick = async () => {
    if (recurrence) {
      const newFrequency = await eventFrequencyDialog.create({
        frequency: { freq, interval },
      });
      setRecurrenceRule(createRecurrenceRule(newFrequency.freq, newFrequency.interval, until));
    } else {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    }
  };

  const eventUntilDialog = useDialog<EditEventDateDialogProps, Date>(EditEventDateDialog, {
    instanceId: `set-event-until`,
  });

  const handleUntilClick = async () => {
    if (freq != null && interval != null) {
      const newUntil = await eventUntilDialog.create({
        date: until,
        subtitle: t('products:financialCalendar.editUntilDialog.text'),
        title: t('products:financialCalendar.editUntilDialog.title'),
      });
      setRecurrenceRule(createRecurrenceRule(freq, interval, newUntil));
    } else {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    }
  };

  useEffect(() => {
    setRecurrence(!!recurrenceRule);
  }, [recurrenceRule]);

  const handleRecurrenceChange = async () => {
    const updatedRecurrence = !recurrence;
    setRecurrence(updatedRecurrence);

    if (!updatedRecurrence) {
      // remove existing recurrenceRule from event
      setRecurrenceRule(undefined);
    }
  };

  const [createCalendarEvent, { loading }] = useCreateCalendarEventMutation({
    onCompleted: () => {
      onResolve();
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
      onResolve();
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const handleSubmit = async () => {
    if (!!name && !!startDate && (!recurrence || !!recurrenceRule)) {
      await createCalendarEvent({
        variables: {
          event: {
            name,
            recurrenceRule,
            startDate,
          },
        },
      });
    } else {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
      onResolve();
    }
  };

  return (
    <Stack>
      <Box textAlign="center">
        <Typography variant="headlineXL">{t('products:financialCalendar.addEvent.dialog.title')}</Typography>
      </Box>

      <Stack sx={styles.container}>
        <DetailRow<string>
          label={t('products:financialCalendar.addEvent.dialog.name')}
          onClick={handleNameClick}
          value={name}
        />
        <DetailRow<Date>
          formatter={(value: Date) => format(value, 'd. M. yyyy')}
          label={t('products:financialCalendar.addEvent.dialog.date')}
          onClick={handleDateClick}
          value={startDate}
        />
      </Stack>

      <Stack sx={styles.container}>
        <Box onClick={handleRecurrenceChange} sx={styles.switchRow}>
          <Typography color="textPrimary" variant="bodyL">
            {t('products:financialCalendar.addEvent.dialog.recurrence')}
          </Typography>
          <Switch aria-label={t('products:financialCalendar.addEvent.dialog.recurrence')} checked={recurrence} />
        </Box>

        <DetailRow<[Frequency, number]>
          disabled={!recurrence}
          formatter={(value: [Frequency, number]) => formatFrequency(value[0], value[1], t) ?? ''}
          label={t('products:financialCalendar.addEvent.dialog.frequency')}
          onClick={handleFrequencyClick}
          value={freq != null && interval != null ? [freq, interval] : undefined}
        />

        <DetailRow<Date>
          disabled={!(recurrence && !!freq && !!interval)}
          formatter={(value: Date) => format(value, 'd. M. yyyy')}
          label={t('products:financialCalendar.addEvent.dialog.until')}
          onClick={handleUntilClick}
          value={until}
        />
      </Stack>

      <Box mt={8}>
        <LoadingButton
          color="primary"
          disabled={!name || !startDate || (recurrence && !recurrenceRule)}
          fullWidth
          loading={loading}
          onClick={handleSubmit}
          size="large"
          type="submit"
        >
          <Typography color="white" variant="bodyL">
            {t('products:financialCalendar.addEvent.dialog.button')}
          </Typography>
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export const AddEventDialog: React.FC<DialogComponentProps<void>> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <AddEventDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
