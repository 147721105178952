import { useReactiveVar } from '@apollo/client';
import { useLoadingIndicator } from 'common/hooks/useLoadingIndicator';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { parseDatestrings } from 'common/utils/parseDatestrings';
import { useLifeInsuranceAuditProcessLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  lifeInsuranceAuditState,
  mapLifeInsuranceAuditInquiryToState,
  trimLifeInsuranceAuditState,
} from './lifeInsuranceAuditState';
import { LifeInsuranceAuditState } from './lifeInsuranceAuditTypes';

export const LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY = 'lifeInsuranceAuditState';

interface UseLifeInsuranceAuditStateReturn {
  state: LifeInsuranceAuditState;
  update: (newState: LifeInsuranceAuditState) => void;
  clear: () => void;
}

export const useLifeInsuranceAuditState = (): UseLifeInsuranceAuditStateReturn => {
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);
  const params = useParams();
  const { t } = useTranslation();
  const { end: endLoading, start: startLoading } = useLoadingIndicator(
    t('flows/lifeInsurance/common:saving.loading.message'),
  );

  const state = useReactiveVar(lifeInsuranceAuditState);

  const update = (newState: LifeInsuranceAuditState) => {
    const stateWithTimeStamp = trimLifeInsuranceAuditState(newState);
    _.set(stateWithTimeStamp, 'meta.updateTimestamp', new Date());
    if (!isAuthenticated && !params?.id) {
      localStorage.setItem(LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY, JSON.stringify(stateWithTimeStamp));
    }
    lifeInsuranceAuditState(stateWithTimeStamp);
  };

  const clear = () => {
    localStorage.removeItem(LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY);
    lifeInsuranceAuditState({});
  };

  const [getProcessData] = useLifeInsuranceAuditProcessLazyQuery({
    // this hack fixes document upload that is not done via GraphQL but POST request so we need to avoid
    // reading old data from Apollo cache, should be done probably by invalidating the
    // specific cache field however this is easier
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      update(
        mapLifeInsuranceAuditInquiryToState(
          data.process.documents?.[0],
          data.process.inquiry,
          data.process.updateTimestamp,
        ),
      ),
  });

  useEffect(() => {
    if (_.isEmpty(state) && params.id) {
      startLoading(t('flows/lifeInsurance/common:loading.message'));
      getProcessData({ variables: { id: params.id } }).then(() =>
        endLoading(t('flows/lifeInsurance/common:loading.message')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading && _.isEmpty(state)) {
      const savedData = localStorage.getItem(LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY);
      const parsedData = savedData ? JSON.parse(savedData) : undefined;
      if (!_.isEmpty(parsedData)) {
        const dataWithDates = parseDatestrings(parsedData) as LifeInsuranceAuditState;
        lifeInsuranceAuditState(dataWithDates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthLoading]);

  return { clear, state, update };
};
