import { Color } from '@mui/material';
import { orange } from '@mui/material/colors';
import { differenceInDays, endOfMonth, formatISO, startOfMonth } from 'date-fns';
import {
  FilterConditionInput,
  FilterOperator,
  Money,
  TransactionAggregationValueFragment,
  UpcomingPaymentAggregationValueFragment,
} from 'generated/graphql';
import { palette } from 'themes/theme';

export interface LeftToSpendDetails {
  sumOfRealizedExpensesByDay?: TransactionAggregationValueFragment[];
  sumOfUpcomingExpensesByDay?: UpcomingPaymentAggregationValueFragment[];
}

export const getTransactionsFilterConditions = (asOfDate: Date): FilterConditionInput[] => {
  return [
    {
      fieldPath: 'amount.amount',
      operator: FilterOperator.LessThan,
      values: ['0'],
    },
    {
      fieldPath: 'bookingDate',
      operator: FilterOperator.GreaterThanOrEqual,
      values: [formatISO(startOfMonth(asOfDate), { representation: 'date' })],
    },
    {
      fieldPath: 'bookingDate',
      operator: FilterOperator.LessThanOrEqual,
      values: [formatISO(asOfDate, { representation: 'date' })],
    },
    {
      fieldPath: 'excludeFromAnalytics',
      operator: FilterOperator.Equal,
      values: ['false'],
    },
  ];
};

export const getLevelForLeft2Spend = (leftToSpend: number, budget: number = 0): 'critical' | 'warning' | 'ok' => {
  if (leftToSpend <= 0) return 'critical';
  if (leftToSpend <= 0.2 * budget) return 'warning';
  return 'ok';
};

export const getColorForLeft2Spend = (leftToSpend: number, budget?: number): Partial<Color> => {
  if (budget == undefined) return palette.gray;
  if (leftToSpend <= 0) return palette.red;
  if (leftToSpend <= 0.2 * budget) return orange;
  return palette.green;
};

export const createMoney = (amount?: number | null, currency = 'EUR' as const): Money => ({
  amount: amount ?? 0,
  currency,
});

export const computeDailyAmount = (asOfDate: Date, leftToSpend: Money): Money => {
  const days = differenceInDays(endOfMonth(asOfDate), asOfDate);
  return { amount: leftToSpend.amount / (days + 1), currency: leftToSpend.currency };
};
