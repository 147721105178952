import { GTMEventValueEnum } from 'const';

export const pushGTMEvent = async (event: GTMEventValueEnum, email: string): Promise<void> => {
  window.dataLayer = window.dataLayer || [];
  const emailHashBuffer = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(email));
  const emailHash = Array.from(new Uint8Array(emailHashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  window.dataLayer.push({ email, emailHash, event });
};
