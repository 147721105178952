import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { i18n } from 'i18n';
import { useTranslation } from 'react-i18next';

import { CashflowGuardianWidgetChart } from '../cashflowGuardianWidget/CashflowGuardianWidgetChart';
import { useCashflowState } from '../useCashflowState';
import { computeDailyAmount, getColorForLeft2Spend, getLevelForLeft2Spend } from '../utils';

export interface CashflowGuardianSituationProps {
  asOfDate: Date;
}

export const CashflowGuardianSituation: React.FC<CashflowGuardianSituationProps> = ({ asOfDate }) => {
  const { t } = useTranslation();
  const cashflowState = useCashflowState(asOfDate);

  if (!cashflowState?.budget || cashflowState.loading) {
    return <Skeleton height="100%" variant="rectangular" />;
  }

  const color = getColorForLeft2Spend(cashflowState?.leftToSpend.amount, cashflowState?.budget.amount);
  const level = getLevelForLeft2Spend(cashflowState?.leftToSpend.amount, cashflowState?.budget.amount);
  const dailyAmount = computeDailyAmount(asOfDate, cashflowState.leftToSpend);

  const texts = {
    critical: {
      subtitle: t(`cashflowGuardian.situation.critical.subtitle`, {
        value: formatTransactionAmount(dailyAmount.amount, i18n.language, 2, dailyAmount.currency),
      }),
      title: t(`cashflowGuardian.situation.critical.title`),
    },
    ok: {
      subtitle: t(`cashflowGuardian.situation.ok.subtitle`, {
        value: formatTransactionAmount(dailyAmount.amount, i18n.language, 2, dailyAmount.currency),
      }),
      title: t(`cashflowGuardian.situation.ok.title`),
    },
    warning: {
      subtitle: t(`cashflowGuardian.situation.warning.subtitle`, {
        value: formatTransactionAmount(dailyAmount.amount, i18n.language, 2, dailyAmount.currency),
      }),
      title: t(`cashflowGuardian.situation.warning.title`),
    },
  };

  return (
    <Stack alignItems="center" direction="row" gap={2}>
      <Box width={48}>
        <CashflowGuardianWidgetChart budget={cashflowState.budget} leftToSpend={cashflowState.leftToSpend} />
      </Box>
      <Stack>
        <Typography color={color[800]} variant="headlineXS">
          {texts[level].title}
        </Typography>
        <Typography color="textSecondary" variant="bodyM">
          {texts[level].subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};
