import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as BinIcon } from 'assets/icons/Bin.svg';
import { ReactComponent as LoaderIcon } from 'assets/icons/DocLoader.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/Document.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg';
import { ReactComponent as RetryIcon } from 'assets/icons/RefreshLarge.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/Warning.svg';
import {
  ConfirmDialogOnSide,
  ConfirmDialogOnSideProps,
} from 'common/components/ConfirmDialogOnSide/ConfirmDialogOnSide';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { addCCSpinning } from 'common/components/LoadingSpinner/spinnerUtils';
import { useDialog } from 'common/hooks/useDialog';
import { FileStatusEnum } from 'common/hooks/useFilesUpload';
import { useTranslation } from 'react-i18next';

import { styles } from './DocumentRow.styles';

interface Props {
  fileName: string;
  status: FileStatusEnum;
  onDelete: () => Promise<void>;
  onRetry: () => Promise<void>;
  onDownload?: () => Promise<void>;
  onOpen?: () => Promise<void>;
  errorMessage?: string;
}

const DocLoadingSpinner = addCCSpinning(LoaderIcon);

export const DocumentRow: React.FC<Props> = ({
  errorMessage,
  fileName,
  onDelete,
  onDownload,
  onOpen,
  onRetry,
  status,
}) => {
  const { t } = useTranslation();

  const deleteConfirmationDialog = useDialog<ConfirmDialogOnSideProps, boolean>(ConfirmDialogOnSide, {
    instanceId: 'delete-file',
  });

  const handleDelete = async () => {
    await deleteConfirmationDialog.create({
      confirmButtonText: t('products:uploadDocument.deleteConfirmation.button'),
      content: t('products:uploadDocument.deleteConfirmation.content'),
      onConfirm: async () => {
        await onDelete();
      },
      title: t('products:uploadDocument.deleteConfirmation.title'),
    });
  };

  return (
    <Stack onClick={() => status === FileStatusEnum.success && onOpen && onOpen()}>
      <Stack alignItems="center" direction="row" gap={1} sx={styles.container}>
        <Stack>
          {status === FileStatusEnum.loading && <DocLoadingSpinner />}
          {status === FileStatusEnum.success && <DocumentIcon height="18px" width="18px" />}
          {status === FileStatusEnum.error && <ErrorIcon height="18px" width="18px" />}
        </Stack>
        <Typography sx={styles.filename} variant="bodyS">
          {fileName}
        </Typography>
        {status !== FileStatusEnum.loading && (
          <Stack direction="row" gap={0.5} sx={styles.endIcons}>
            {status === FileStatusEnum.error && (
              <IconButton
                color="tertiaryButton"
                onClick={(e) => {
                  e.stopPropagation();
                  onRetry();
                }}
                size="small"
              >
                <RetryIcon height="18px" width="18px" />
              </IconButton>
            )}
            <IconButton
              color="tertiaryButton"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
              size="small"
            >
              <BinIcon height="18px" width="18px" />
            </IconButton>
            {onDownload && (
              <IconButton
                color="tertiaryButton"
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload();
                }}
                size="small"
              >
                <DownloadIcon height="18px" width="18px" />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>
      {errorMessage && status === FileStatusEnum.error && (
        <FormHelperText error size="medium">
          {errorMessage}
        </FormHelperText>
      )}
    </Stack>
  );
};
