import { ReactNode } from 'react';
import { Datagrid, DateField, DeleteButton, ReferenceField, TextField } from 'react-admin';

interface ProcessDatagridProps {
  acceptedField: ReactNode;
  showClient?: boolean;
}

export const ProcessDatagrid: React.FC<ProcessDatagridProps> = ({ acceptedField, showClient }) => {
  return (
    <Datagrid rowClick={false}>
      <TextField label="ID" source="id" />
      {showClient && (
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
      )}
      <DateField label="Vytvorené" locales="sk" source="creationTimestamp" />
      <DateField label="Zmenené" locales="sk" source="updateTimestamp" />
      <TextField label="Krok" source="inquiry.inquiryCurrentStepUrl" />
      {acceptedField}
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
  );
};
