import { Income, Mapper } from 'components/flows/common/lifeInsurance/state/lifeInsuranceTypes';
import { LifeInsuranceIncomeType } from 'const';
import { LifeInsuranceApplicantFragment, LifeInsuranceIncomeInput } from 'generated/graphql';

export const mapIncomes: Mapper<Income[], LifeInsuranceIncomeInput[], LifeInsuranceApplicantFragment['incomes']> = {
  parse: (incomes) => {
    if (!incomes) {
      return undefined;
    }
    return incomes.map((income) => {
      switch (income.__typename) {
        case 'ApplicantAlimonyIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.Alimony,
          };
        case 'ApplicantEntrepreneurshipIncome':
          return {
            monthlyIncome: income.monthlyIncomeNullable?.amount,
            type: LifeInsuranceIncomeType.Entrepreneurship,
          };
        case 'ApplicantLtdIncome':
          return {
            monthlyIncome: income.monthlyIncomeNullable?.amount,
            type: LifeInsuranceIncomeType.Ltd,
          };
        case 'ApplicantMaternityLeaveIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.MaternityLeave,
          };
        case 'ApplicantOtherIncome':
          return {
            description: income.description,
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.Other,
          };
        case 'ApplicantParentalAllowanceIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.ParentalAllowance,
          };
        case 'ApplicantSalaryIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.Salary,
          };
        case 'ApplicantUnemploymentBenefitIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.UnemploymentBenefit,
          };
        case 'ApplicantWorkAgreementIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: LifeInsuranceIncomeType.WorkAgreement,
          };
        default:
          return {};
      }
    });
  },
  serialize: (incomes) => {
    if (!incomes) {
      return undefined;
    }

    return incomes.map((income) => {
      switch (income.type) {
        case LifeInsuranceIncomeType.Alimony:
          return {
            alimonyIncome: {
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case LifeInsuranceIncomeType.Entrepreneurship:
          return {
            entrepreneurshipIncome: {
              expenseType: null,
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
              taxBase: null,
              taxPayed: null,
              yearlyRevenues: null,
            },
          };
        case LifeInsuranceIncomeType.Ltd:
          return {
            ltdIncome: {
              ceoCompensation: null,
              equityShares: null,
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
              yearlyProfit: null,
              yearlyRevenues: null,
            },
          };
        case LifeInsuranceIncomeType.MaternityLeave:
          return {
            maternityLeaveIncome: {
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case LifeInsuranceIncomeType.Other:
          return {
            otherIncome: {
              description: income.description ?? '',
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case LifeInsuranceIncomeType.ParentalAllowance:
          return {
            parentalAllowanceIncome: {
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case LifeInsuranceIncomeType.Salary:
          return {
            salaryIncome: {
              contractSignatureDate: null,
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case LifeInsuranceIncomeType.UnemploymentBenefit:
          return {
            unemploymentBenefitIncome: {
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case LifeInsuranceIncomeType.WorkAgreement:
          return {
            workAgreementIncome: {
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        default:
          return {};
      }
    });
  },
};
