import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './AccountsDataLoader.styles';

export const AccountsDataLoader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} sx={styles.container}>
      <Typography variant="bodyStrongL">{t('homepage.accounts.dataLoader')}</Typography>
      <Box width="100%">
        <LinearProgress sx={styles.loader} />
      </Box>
    </Stack>
  );
};
