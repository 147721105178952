import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { isSameMonth } from 'date-fns';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { useCashflowState } from '../useCashflowState';
import { getColorForLeft2Spend } from '../utils';
import { useChartOptions } from './CashflowGuardianLineChart.chart';

export interface CashflowGuardianChartProps {
  asOfDate: Date;
}

export const CashflowGuardianLineChart: React.FC<CashflowGuardianChartProps> = ({ asOfDate }) => {
  const { i18n, t } = useTranslation();
  const cashflowState = useCashflowState(asOfDate);

  const color = getColorForLeft2Spend(cashflowState.leftToSpend.amount, cashflowState.budget?.amount);
  const options = useChartOptions(asOfDate);

  if (cashflowState.loading) {
    return <Skeleton height={400} variant="rectangular" />;
  }

  const isPastMonth = !isSameMonth(asOfDate, new Date());

  const pastMonthTitle =
    cashflowState.budget?.amount == undefined
      ? t('cashflowGuardian.common.spent')
      : cashflowState.leftToSpend.amount > 0
      ? t('cashflowGuardian.common.saved')
      : t('cashflowGuardian.common.overdrafted');

  const title =
    cashflowState.leftToSpend.amount > 0
      ? t('cashflowGuardian.common.disposable')
      : t('cashflowGuardian.common.overdraft');

  return (
    <Stack>
      <Typography color="textSecondary" variant="bodyM">
        {isPastMonth ? pastMonthTitle : title}
      </Typography>
      <Typography color={color[800]} variant="headlineXS">
        {formatTransactionAmount(
          cashflowState.leftToSpend.amount ?? 0,
          i18n.language,
          2,
          cashflowState.leftToSpend.currency,
        )}
      </Typography>
      <Box height={300}>
        <ReactEcharts option={options} style={{ height: '100%', width: '100%' }} />
      </Box>
    </Stack>
  );
};
