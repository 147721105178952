import { FilterOperator } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { Filter, FilterOption } from './Filter';

export const useAnalyticsOptions = (): FilterOption[] => {
  const { t } = useTranslation();
  return [
    {
      label: t('filterTransactions.filter.analytics.included.label'),
      value: {
        fieldPath: 'excludeFromAnalytics',
        operator: FilterOperator.Equal,
        values: ['false'],
      },
    },
    {
      label: t('filterTransactions.filter.analytics.excluded.label'),
      value: {
        fieldPath: 'excludeFromAnalytics',
        operator: FilterOperator.Equal,
        values: ['true'],
      },
    },
  ].map((option) => ({
    key: option.label,
    label: option.label,
    value: {
      arguments: [{ filterCondition: option.value }],
    },
  }));
};

const NAME = 'analytics';

export const AnalyticsFilter: React.FC = () => {
  const { t } = useTranslation();

  const analyticsOptions = useAnalyticsOptions();

  return (
    <Filter name={NAME} noManual options={analyticsOptions} title={t('filterTransactions.filter.analytics.title')} />
  );
};
