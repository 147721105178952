import { Done } from '@mui/icons-material';
import { FinancialAdvisorTask } from 'generated/graphql';
import { DateField, FunctionField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { DoneButton } from './DoneButton';
import { ProductReferenceField } from './ProductReferenceField';

export const FinancialAdvisorTaskShow: React.FC = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
        <TextField label="Typ" source="title" />
        <TextField label="Podtyp" source="subtitle" />
        <TextField label="Úloha" source="text" />
        <ProductReferenceField label="Produkt" />
        <DateField label="Dátum udalosti" locales="sk" source="dueDate" />
        <FunctionField
          label="Stav"
          render={(record: FinancialAdvisorTask) => record.doneDate && <Done style={{ fontSize: 20 }} />}
          source="doneDate"
        />
        <DoneButton />
        <DateField label="Dátum vytvorenia" locales="sk" source="creationTimestamp" />
      </SimpleShowLayout>
    </Show>
  );
};
