import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { NoData } from 'common/components/NoData/NoData';
import { PeriodSwitcher } from 'common/components/PeriodSwitcher/PeriodSwitcher';
import { CategoryIcon } from 'components/transactions/CategoryIcon/CategoryIcon';
import { SortOption } from 'components/transactions/SortOption/SortOption';
import { TransactionList } from 'components/transactions/TransactionList/TransactionList';
import { min } from 'date-fns';
import {
  FilterOperator,
  SortDirection,
  SortExpressionInput,
  TransactionAttribute,
  useAggregateTransactionsQuery,
  useConnectedAccountsQuery,
  useTransactionCategoriesQuery,
  useTransactionTagsQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountList } from './AccountList/AccountList';
import { AggregateList } from './AggregateList/AggregateList';
import { SpendingReportChart } from './SpendingReportChart/SpendingReportChart';
import { styles } from './SpendingReportContent.styles';
import { PeriodTypeEnum } from './state/spendingReportTypes';
import { useSpendingReportState } from './state/useSpendingReportState';
import { TagIcon } from './TagIcon/TagIcon';
import { addPeriods, formatPeriodTitle, getFilter, getPeriod } from './utils';

export interface SpendingReportContentProps {
  pointerDate?: Date;
  tagId?: string;
  categoryId?: string;
  accountId?: string;
  lockedPeriodType?: PeriodTypeEnum;
  header?: React.ReactNode;
  chartTitle?: string;
}

export const SpendingReportContent: React.FC<SpendingReportContentProps> = (props) => {
  const { t } = useTranslation();
  const { state } = useSpendingReportState();
  const { accountId, categoryId, chartTitle, header, pointerDate, tagId } = props;
  const periodType = props.lockedPeriodType ?? state.params.periodType;

  const [localPointerDate, setLocalPointerDate] = useState<Date>(pointerDate ?? new Date());

  const isDetail = tagId || categoryId || accountId;

  const { data: accountsData } = useConnectedAccountsQuery();
  const { data: categoriesData } = useTransactionCategoriesQuery({
    variables: {
      filterInput: {
        arguments: [{ filterCondition: { fieldPath: 'isActive', operator: FilterOperator.Equal, values: ['true'] } }],
      },
    },
  });
  const { data: tagsData } = useTransactionTagsQuery();
  const account = accountsData?.accounts?.items.find((account) => account.id === accountId);
  const category = categoriesData?.page.items.find((category) => category.id === categoryId);
  const tag = tagsData?.tags.items.find((tag) => tag.id === tagId);

  const filterConditions = getFilter(localPointerDate, periodType, categoryId, tagId, accountId);

  const filterInput = {
    arguments: filterConditions.map((condition) => ({ filterCondition: condition })),
  };
  const period = getPeriod(periodType, localPointerDate);

  const { data, refetch } = useAggregateTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput,
      groupBy: [TransactionAttribute.Category, period.unit as string as TransactionAttribute],
    },
  });

  const {
    data: totalData,
    loading: totalLoading,
    refetch: refetchTotal,
  } = useAggregateTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput,
    },
  });

  const handleArrowClick = (direction: -1 | 1) => {
    const newDate = addPeriods(localPointerDate, periodType, direction);
    setLocalPointerDate(newDate);

    const newFilterConditions = getFilter(newDate, periodType, categoryId, tagId, accountId);

    const newFilterInput = {
      arguments: newFilterConditions.map((condition) => ({ filterCondition: condition })),
    };

    refetch({ filterInput: newFilterInput });
    refetchTotal({ filterInput: newFilterInput });
  };

  const [sort, setSort] = useState<'date' | 'amount'>('date');
  const sortInput: SortExpressionInput[] =
    sort === 'date'
      ? [{ direction: SortDirection.Desc, fieldPath: 'bookingDate' }]
      : [{ direction: SortDirection.Desc, fieldPath: 'absBaseAmount' }];

  const totalCount = totalData?.aggregateTransactions.resultData[0]?.count ?? 0;

  const periodTitle = formatPeriodTitle(period, min([new Date(), period.endDate]));
  return (
    <Box bgcolor="gray.100" position="relative" pt={isDetail ? 0 : 5}>
      <Stack minHeight="100%">
        <Stack>
          <Stack sx={styles.header}>
            {isDetail && (
              <Stack alignItems="center">
                <Stack direction="row" mb={2} spacing={2}>
                  {account && <AccountCard colorIndex={account.colorIndex} height={58} width={96} />}
                  {category && <CategoryIcon category={category} width={96} />}
                  {tag && <TagIcon height={96} width={96} />}
                </Stack>
                <Typography variant="headlineXL">
                  {[account?.name, category?.categoryGroup.name, tag?.name]
                    .filter((name) => name !== undefined)
                    .join(', ')}
                </Typography>
                <Typography variant="bodyM">
                  {t('spendingReport.common.transactionsCount', {
                    count: totalData?.aggregateTransactions.resultData[0]?.count ?? 0,
                  })}
                </Typography>
              </Stack>
            )}
            {header ?? (
              <PeriodSwitcher
                disableRight={getPeriod(periodType, addPeriods(localPointerDate, periodType, 1)).startDate > new Date()}
                onChange={handleArrowClick}
                title={isDetail ? undefined : periodTitle}
              />
            )}
          </Stack>

          {totalCount === 0 && !totalLoading && (
            <NoData title={t('spendingReport.noTransactions.title', { period: periodTitle })} />
          )}

          {(data && totalData && totalCount > 0 && (
            <SpendingReportChart
              byPeriodAndCategoryAggregates={data.aggregateTransactions.resultData}
              period={period}
              title={chartTitle ?? isDetail ? periodTitle : t('spendingReport.chart.totalExpenses')}
              totalAggregate={totalData.aggregateTransactions}
            />
          )) ?? <Skeleton height={286} variant="rectangular" />}

          {!accountId && totalCount > 0 && (
            <AccountList
              categoryId={categoryId}
              lockedPeriodType={props.lockedPeriodType}
              pointerDate={localPointerDate}
              tagId={tagId}
              type={TransactionAttribute.Account}
            />
          )}
          {!categoryId && totalCount > 0 && (
            <AggregateList
              accountId={accountId}
              lockedPeriodType={props.lockedPeriodType}
              pointerDate={localPointerDate}
              tagId={tagId}
              title={t('spendingReport.content.categories.title')}
              total={totalData?.aggregateTransactions.resultData[0]?.sum.amount}
              type={TransactionAttribute.Category}
            />
          )}
          {!tagId && totalCount > 0 && (
            <AggregateList
              accountId={accountId}
              categoryId={categoryId}
              lockedPeriodType={props.lockedPeriodType}
              pointerDate={localPointerDate}
              title={t('spendingReport.content.tags.title')}
              total={totalData?.aggregateTransactions.resultData[0]?.sum.amount}
              type={TransactionAttribute.Tag}
            />
          )}
        </Stack>
      </Stack>
      {!!totalCount && (
        <Stack height="100vh" mt={8}>
          <Stack>
            <Stack direction="row" justifyContent="space-between" mb={3}>
              <Typography component="h2" variant="headlineM">
                {t('spendingReport.content.transactions.title')}
              </Typography>
              <SortOption onClick={() => setSort(sort === 'date' ? 'amount' : 'date')} sortBy={sort} />
            </Stack>
          </Stack>
          <TransactionList filterInput={[filterInput]} sortInput={sortInput} />
        </Stack>
      )}
    </Box>
  );
};
