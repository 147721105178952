import { dateParser } from 'admin/formatUtils';
import { ClientInput } from 'admin/inputs/ClientInput';
import { ExpectedAmountInput } from 'admin/inputs/ExpectedAmountInput';
import { InstitutionInput } from 'admin/inputs/InstitutionInput';
import { InsuringPersonType, ProductStatus, TransactionGroupPeriodTypeCode, VehicleType } from 'generated/graphql';
import { Create, DateInput, FormTab, NumberInput, SelectInput, TabbedForm, TextInput } from 'react-admin';

// TODO: generalize components

export const MotorLiabilityInsuranceProductCreate: React.FC = () => (
  <Create>
    <TabbedForm>
      <FormTab label="Základné info">
        <ClientInput label="Klient" source="clientId" />
        <InstitutionInput source="institutionId" />
        <TextInput label="Vlastná inštitúcia" source="institutionName" sx={{ width: '40em' }} />
        <TextInput label="Názov produktu" source="name" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Zmluva">
        <TextInput label="Číslo zmluvy" source="contractNumber" sx={{ width: '40em' }} />
        <DateInput label="Začiatok poistenia" parse={dateParser} source="contractSignDate" />
        <DateInput label="Platnosť poistenia do" parse={dateParser} source="contractEndDate" />
      </FormTab>

      <FormTab label="Platby">
        <ExpectedAmountInput label="Suma poistného" source="paymentPeriod.expectedAmount.amount" />
        <TextInput source="paymentPeriod.expectedAmount.currency" style={{ display: 'none' }} value="EUR" />
        <NumberInput label="Opakovať každých" source="paymentPeriod.periodCount" />
        <SelectInput
          choices={[
            { id: TransactionGroupPeriodTypeCode.Week, name: 'týždňov' },
            { id: TransactionGroupPeriodTypeCode.Month, name: 'mesiacov' },
          ]}
          label=""
          source="paymentPeriod.periodTypeCode"
        />
        <DateInput label="Nasledujúca platba" parse={dateParser} source="paymentPeriod.expectedDate" />
      </FormTab>

      <FormTab label="Údaje pre platbu">
        <TextInput label="Variabilný symbol" source="paymentInfo.variableSymbol" sx={{ width: '40em' }} />
        <TextInput label="Špecifický symbol" source="paymentInfo.specificSymbol" sx={{ width: '40em' }} />
        <TextInput label="Konštantný symbol" source="paymentInfo.constantSymbol" sx={{ width: '40em' }} />
        <TextInput label="IBAN" source="paymentInfo.ibanForPayment" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Poistené vozidlo">
        <SelectInput
          choices={[
            { id: VehicleType.Automobile, name: 'Automobil' },
            { id: VehicleType.Motorcycle, name: 'Motocykel' },
            { id: VehicleType.Other, name: 'Iné' },
          ]}
          label="Typ vozidla"
          source="vehicleInfo.type"
        />
        <TextInput label="ŠPZ" source="vehicleInfo.licenseNumber" />
      </FormTab>

      <FormTab label="Poistník">
        <SelectInput
          choices={[
            { id: InsuringPersonType.Natural, name: 'Fyzická osoba' },
            { id: InsuringPersonType.Legal, name: 'Právnická osoba' },
          ]}
          label="Typ poistníka"
          source="insuringPerson.type"
        />
        <TextInput label="Názov spoločnosti / Meno" source="insuringPerson.name" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Údaje pre porovnanie výhodnosti produktu">
        <DateInput label="Dátum narodenia" parse={dateParser} source="vehicleInfo.owner.dateOfBirth" />
        <TextInput label="PSČ" source="vehicleInfo.owner.postalCode" />
        <NumberInput label="Rok poslednej nehody" source="vehicleInfo.owner.yearOfLastCollision" />
        <NumberInput label="Počet nehôd za 3 roky" source="vehicleInfo.owner.numberOfRecentCollisions" />
      </FormTab>

      <FormTab label="Status">
        <SelectInput
          choices={[{ id: ProductStatus.Processing, name: 'Processing' }]}
          label="Status"
          required
          source="status"
        />
      </FormTab>
    </TabbedForm>
  </Create>
);
