import { Box, Typography } from '@mui/material';
import { ReactComponent as SortIcon } from 'assets/icons/Sort.svg';
import { LightTooltip } from 'common/components/LightTooltip/LightTooltip';
import { useTranslation } from 'react-i18next';

import { styles } from './SortOption.styles';

interface Props {
  sortBy: 'date' | 'amount';
  onClick: () => void;
}

export const SortOption: React.FC<Props> = ({ onClick, sortBy }) => {
  const { t } = useTranslation();

  const title = sortBy === 'date' ? t('sortOption.title.date') : t('sortOption.title.amount');
  const tooltip = sortBy === 'date' ? t('sortOption.tooltip.amount') : t('sortOption.tooltip.date');

  return (
    <LightTooltip placement="top" title={tooltip}>
      <Box onClick={onClick} sx={styles.root}>
        <Box sx={styles.icon}>
          <SortIcon />
        </Box>
        <Typography color="blue.dark" variant="bodyL">
          {title}
        </Typography>
      </Box>
    </LightTooltip>
  );
};
