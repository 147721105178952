import { Stack, Typography } from '@mui/material';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY } from 'components/flows/lifeInsuranceAudit/state/useLifeInsuranceAuditState';
import { LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY } from 'components/flows/lifeInsurancePurchase/state/useLifeInsurancePurchaseState';
import { PURCHASE_STATE_LOCAL_STORAGE_KEY } from 'components/flows/mortgagePurchase/state/useMortgagePurchaseState';
import { REFINANCE_STATE_LOCAL_STORAGE_KEY } from 'components/flows/mortgageRefinance/state/useMortgageRefinanceState';
import { FinancialCalendar } from 'components/HomePage/FinancialCalendar/FinancialCalendar';
import { NoProductsTitle } from 'components/HomePage/HomepageProducts/NoProductsTitle';
import { UsersProducts } from 'components/HomePage/UsersProducts/UsersProducts';
import { ViewAllProductsList } from 'components/HomePage/UsersProducts/ViewAllProducts/ViewAllProductsList';
import { ViewProductsWaitingForReview } from 'components/HomePage/UsersProducts/ViewAllProducts/ViewProductsWaitingForReview';
import { isProductionEnvironment } from 'environment';
import {
  FinancialProductType,
  ProductStatus,
  useAllProcessesQuery,
  useBuildingSavingsProductsQuery,
  useCollisionInsuranceProductsQuery,
  useCreditCardProductsQuery,
  useGenericInsuranceProductsQuery,
  useGenericProductsQuery,
  useInvestmentProductsQuery,
  useLifeInsuranceProductsQuery,
  useLoanProductsQuery,
  useMortgageProductsQuery,
  useMotorLiabilityInsuranceProductsQuery,
  usePropertyInsuranceProductsQuery,
  useRetirementPensionSavingsProductsQuery,
  useSavingsProductsQuery,
  useSupplementaryPensionSavingsProductsQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculatorCards } from '../CalculatorCards/CalculatorCards';
import { ProductsSwitcher } from '../ProductSwitcher/ProductsSwitcher';

export const HomepageProducts: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, isLoading: isLoadingAuth } = useContext(AuthContext);
  const { data, loading: loadingProcesses } = useAllProcessesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isAuthenticated,
  });

  const { data: mortgageProductsData, loading: loadingMortgageProducts } = useMortgageProductsQuery({
    skip: !isAuthenticated,
  });
  const { data: loanProductsData, loading: loadingLoanProducts } = useLoanProductsQuery({
    skip: !isAuthenticated,
  });
  const { data: creditCardProductsData, loading: loadingCreditCardProducts } = useCreditCardProductsQuery({
    skip: !isAuthenticated,
  });
  const { data: lifeInsuranceProductsData, loading: loadingLifeInsuranceProducts } = useLifeInsuranceProductsQuery({
    skip: !isAuthenticated,
  });
  const { data: motorLiabilityInsuranceProductsData, loading: loadingMotorLiabilityInsuranceProducts } =
    useMotorLiabilityInsuranceProductsQuery({ skip: !isAuthenticated });
  const { data: collisionInsuranceProductsData, loading: loadingCollisionInsuranceProducts } =
    useCollisionInsuranceProductsQuery({ skip: !isAuthenticated });
  const { data: propertyInsuranceProductsData, loading: loadingPropertyInsuranceProducts } =
    usePropertyInsuranceProductsQuery({ skip: !isAuthenticated });
  const { data: genericInsuranceProductsData, loading: loadingGenericInsuranceProducts } =
    useGenericInsuranceProductsQuery({ skip: !isAuthenticated });
  const { data: investmentProductsData, loading: loadingInvestmentProducts } = useInvestmentProductsQuery({
    skip: !isAuthenticated,
  });
  const { data: retirementPensionSavingsProductsData, loading: loadingRetirementPensionSavingsProducts } =
    useRetirementPensionSavingsProductsQuery({ skip: !isAuthenticated });
  const { data: supplementaryPensionSavingsProductsData, loading: loadingSupplementaryPensionSavingsProducts } =
    useSupplementaryPensionSavingsProductsQuery({ skip: !isAuthenticated });
  const { data: buildingSavingsProductsData, loading: loadingBuildingSavingsProducts } =
    useBuildingSavingsProductsQuery({ skip: !isAuthenticated });
  const { data: savingsProductsData, loading: loadingSavingsProducts } = useSavingsProductsQuery({
    skip: !isAuthenticated,
  });
  const { data: genericProductsData, loading: loadingGenericProducts } = useGenericProductsQuery({
    skip: !isAuthenticated,
  });

  const isLoadingProducts =
    loadingMortgageProducts ||
    loadingLoanProducts ||
    loadingCreditCardProducts ||
    loadingLifeInsuranceProducts ||
    loadingMotorLiabilityInsuranceProducts ||
    loadingCollisionInsuranceProducts ||
    loadingPropertyInsuranceProducts ||
    loadingGenericInsuranceProducts ||
    loadingInvestmentProducts ||
    loadingRetirementPensionSavingsProducts ||
    loadingSupplementaryPensionSavingsProducts ||
    loadingBuildingSavingsProducts ||
    loadingSavingsProducts ||
    loadingGenericProducts;

  const showLoading = isLoadingAuth || loadingProcesses;

  const hasMortgagePurchase =
    (!isAuthenticated && !!localStorage.getItem(PURCHASE_STATE_LOCAL_STORAGE_KEY)) ||
    !!data?.purchaseProcesses?.items[0];
  const hasMortgageRefinance =
    (!isAuthenticated && !!localStorage.getItem(REFINANCE_STATE_LOCAL_STORAGE_KEY)) ||
    !!data?.refinanceProcesses?.items[0];
  const hasLifeInsurancePurchase =
    (!isAuthenticated && !!localStorage.getItem(LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY)) ||
    !!data?.lifeInsuranceProcesses?.items[0];
  const hasLifeInsuranceAudit =
    (!isAuthenticated && !!localStorage.getItem(LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY)) ||
    !!data?.lifeInsuranceAuditProcesses?.items[0];

  const hasProcess = hasMortgagePurchase || hasMortgageRefinance || hasLifeInsurancePurchase || hasLifeInsuranceAudit;

  const getListProducts = (statuses: ProductStatus[]) => {
    const mortgageProducts = _.sortBy(
      mortgageProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.Mortgage })),
      'creationTimestamp',
    ).reverse();

    const loanProducts = _.sortBy(
      loanProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.Loan })),
      'creationTimestamp',
    ).reverse();

    const creditCardProducts = _.sortBy(
      creditCardProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.CreditCard })),
      'creationTimestamp',
    ).reverse();

    const lifeInsuranceProducts = _.sortBy(
      lifeInsuranceProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.LifeInsurance })),
      'creationTimestamp',
    ).reverse();

    const motorLiabilityInsuranceProducts = _.sortBy(
      motorLiabilityInsuranceProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.MotorLiabilityInsurance })),
      'creationTimestamp',
    ).reverse();

    const collisionInsuranceProducts = _.sortBy(
      collisionInsuranceProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.CollisionInsurance })),
      'creationTimestamp',
    ).reverse();

    const propertyInsuranceProducts = _.sortBy(
      propertyInsuranceProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.PropertyInsurance })),
      'creationTimestamp',
    ).reverse();

    const genericInsuranceProducts = _.sortBy(
      genericInsuranceProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.GenericInsurance })),
      'creationTimestamp',
    ).reverse();

    const investmentProducts = _.sortBy(
      investmentProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.Investment })),
      'creationTimestamp',
    ).reverse();

    const retirementPensionSavingsProducts = _.sortBy(
      retirementPensionSavingsProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.RetirementPensionSavings })),
      'creationTimestamp',
    ).reverse();

    const supplementaryPensionSavingsProducts = _.sortBy(
      supplementaryPensionSavingsProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.SupplementaryPensionSavings })),
      'creationTimestamp',
    ).reverse();

    const buildingSavingsProducts = _.sortBy(
      buildingSavingsProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.BuildingSavings })),
      'creationTimestamp',
    ).reverse();

    const savingsProducts = _.sortBy(
      savingsProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.Savings })),
      'creationTimestamp',
    ).reverse();

    const genericProducts = _.sortBy(
      genericProductsData?.products.items
        .filter((item) => statuses.includes(item.status))
        .map((item) => ({ ...item, type: FinancialProductType.Generic })),
      'creationTimestamp',
    ).reverse();

    return [
      ...(mortgageProducts ?? []),
      ...(loanProducts ?? []),
      ...(creditCardProducts ?? []),
      ...(lifeInsuranceProducts ?? []),
      ...(motorLiabilityInsuranceProducts ?? []),
      ...(collisionInsuranceProducts ?? []),
      ...(propertyInsuranceProducts ?? []),
      ...(genericInsuranceProducts ?? []),
      ...(investmentProducts ?? []),
      ...(retirementPensionSavingsProducts ?? []),
      ...(supplementaryPensionSavingsProducts ?? []),
      ...(buildingSavingsProducts ?? []),
      ...(savingsProducts ?? []),
      ...(genericProducts ?? []),
    ];
  };

  const productsWaitingForReview = getListProducts([ProductStatus.WaitingForReview]);
  const productsOther = getListProducts([
    ProductStatus.WaitingForSubmission,
    ProductStatus.Submitted,
    ProductStatus.Processing,
    ProductStatus.Active,
  ]);

  const hasProductWaitingForReview = productsWaitingForReview.length > 0;
  const hasOtherProduct = productsOther.length > 0;
  const hasProduct = isProductionEnvironment ? hasOtherProduct : hasProductWaitingForReview || hasOtherProduct;

  return (
    <Stack spacing={8}>
      {!showLoading &&
        (hasProcess || hasProduct ? (
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography component="h3" variant="headlineXL">
              {t('homepage.products.header')}
            </Typography>
            <FinancialCalendar />
          </Stack>
        ) : (
          <NoProductsTitle />
        ))}

      {!showLoading && hasProcess && <UsersProducts />}

      {!isLoadingProducts && hasOtherProduct && <ViewAllProductsList products={productsOther} />}

      {!isProductionEnvironment && !isLoadingProducts && hasProductWaitingForReview && (
        <ViewProductsWaitingForReview products={productsWaitingForReview} />
      )}

      {(!hasProcess || !hasProduct) && <ProductsSwitcher />}

      {(hasProcess || hasProduct) && <CalculatorCards />}
    </Stack>
  );
};
