import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'row', SxProps<Theme>> = {
  container: (theme) => ({
    backgroundColor: theme.palette.gray[50],
    borderRadius: '10px',
    flexGrow: 1,
    height: 'fit-content',
  }),
  row: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    backgroundColor: (theme) => theme.palette.gray[50],
  },
};
