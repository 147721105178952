import { SxProps, Theme } from '@mui/material';

export const styles: Record<'image' | 'container' | 'icon', SxProps<Theme>> = {
  container: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    margin: (theme) => theme.spacing(3, 0),
    overflow: 'hidden',
    padding: (theme) => theme.spacing(2),
  },
  icon: {
    color: (theme) => theme.palette.gray[500],
  },
  image: {
    borderRadius: '10px',
    height: 48,
    width: 48,
  },
};
