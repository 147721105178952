import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { FilterOperator, useConnectedAccountsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter, FilterOption } from './Filter';

export const useAccountOptions = (): { loading?: boolean; options: FilterOption[] } => {
  const { isAuthenticated } = useContext(AuthContext);
  const { data, loading } = useConnectedAccountsQuery({
    skip: !isAuthenticated,
  });

  return {
    loading,
    options:
      data?.accounts?.items.map((account) => ({
        key: account.id,
        label: account.name,
        value: {
          arguments: [
            {
              filterCondition: {
                fieldPath: 'account.id',
                operator: FilterOperator.Equal,
                values: [account.id],
              },
            },
          ],
        },
      })) ?? [],
  };
};

export const AccountFilter: React.FC = () => {
  const { t } = useTranslation();

  const { loading, options } = useAccountOptions();

  return (
    <Filter
      loading={loading}
      name="account"
      noManual
      options={options}
      title={t('filterTransactions.filter.account.title')}
    />
  );
};
