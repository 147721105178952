import { SxProps, Theme } from '@mui/material';

export const styles: Record<'closeButton' | 'paper', SxProps<Theme>> = {
  closeButton: {
    '&:hover': {
      backgroundColor: (theme) => theme.palette.gray[300],
    },
    backgroundColor: (theme) => theme.palette.gray[200],
    borderRadius: '100%',
    position: 'absolute',
    right: 16,
    top: 16,
  },
  paper: {
    backgroundColor: (theme) => theme.palette.gray[100],
    padding: (theme) => ({
      sm: theme.spacing(6),
      xxs: theme.spacing(2),
    }),
    width: 512,
  },
};
