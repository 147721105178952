import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { NoData } from 'common/components/NoData/NoData';
import { PeriodSwitcher } from 'common/components/PeriodSwitcher/PeriodSwitcher';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatPeriodTitle } from 'components/spendingReport/utils';
import { endOfMonth, isThisMonth, startOfMonth } from 'date-fns';
import {
  AggregateBalanceFilter,
  BalanceAttribute,
  DateGranularity,
  useAggregateBalancesQuery,
  useConnectedAccountsQuery,
  useLastAccountsDataRefreshQuery,
} from 'generated/graphql';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountList } from '../AccountList/AccountList';
import { AvailableFundsLineChart } from '../AvailableFundsLineChart/AvailableFundsLineChart';
import { getAdjacentEndDate, getPeriodFilter } from '../utils';
import { styles } from './AvailableFundsDialog.styles';

interface AvailableFundsDialogContentProps {
  pointerDate?: Date;
  accountId?: string;
}

const AvailableFundsDialogContent: React.FC<AvailableFundsDialogContentProps> = ({ accountId, pointerDate }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { isAuthenticated } = useContext(AuthContext);

  const { data: dataLastRefresh, loading: loadingLastRefresh } = useLastAccountsDataRefreshQuery({
    skip: !isAuthenticated || !!pointerDate,
  });
  const lastRefresh = dataLastRefresh?.lastRefresh;

  const [asOfDate, setAsOfDate] = useState<Date>(pointerDate ?? lastRefresh ?? new Date());

  const [filterInput, setFilterInput] = useState<AggregateBalanceFilter>(getPeriodFilter(asOfDate, accountId));

  const { data, loading, refetch } = useAggregateBalancesQuery({
    fetchPolicy: 'no-cache',
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    skip: !isAuthenticated,
    variables: {
      filterInput,
      groupBy: [BalanceAttribute.Day],
    },
  });
  const areDataAvailable = !!data && data.aggregateBalances.resultData.length > 0;

  const { data: accountsData } = useConnectedAccountsQuery();
  const account = accountsData?.accounts?.items.find((account) => account.id === accountId);

  const handleArrowClick = (direction: -1 | 1) => {
    const newDate = getAdjacentEndDate(asOfDate, direction);
    setAsOfDate(newDate);

    const newPeriodFilterInput = getPeriodFilter(newDate, accountId);

    refetch({ filterInput: newPeriodFilterInput });
    setFilterInput(newPeriodFilterInput);
  };

  const periodTitle = formatPeriodTitle(
    {
      endDate: endOfMonth(asOfDate),
      startDate: startOfMonth(asOfDate),
      unit: DateGranularity.Day,
    },
    asOfDate,
  );

  return (
    <Stack pt={!!account ? 0 : 5} spacing={3}>
      {!!account && (
        <Stack alignItems="center">
          <Stack direction="row" mb={2} spacing={2}>
            <AccountCard colorIndex={account.colorIndex} height={58} width={96} />
          </Stack>
          <Typography variant="headlineL">{account.name}</Typography>
        </Stack>
      )}
      <PeriodSwitcher
        disableRight={isThisMonth(asOfDate)}
        onChange={handleArrowClick}
        title={
          <Typography component="h1" variant={!!account ? 'headlineM' : 'headlineXL'}>
            {periodTitle}
          </Typography>
        }
      />
      {loading || loadingLastRefresh ? (
        <Skeleton height={400} variant="rectangular" />
      ) : areDataAvailable ? (
        <Box sx={styles.box}>
          <AvailableFundsLineChart aggregatedData={data.aggregateBalances.resultData} asOfDate={asOfDate} />
        </Box>
      ) : (
        <NoData title={t('availableFunds.noBalances.title', { period: periodTitle })} />
      )}
      {areDataAvailable && !accountId && (
        <Box>
          <AccountList asOfDate={asOfDate} />
        </Box>
      )}
    </Stack>
  );
};

export interface AvailableFundsDialogProps
  extends DialogComponentProps<void>,
    Partial<AvailableFundsDialogContentProps> {}

export const AvailableFundsDialog: React.FC<AvailableFundsDialogProps> = ({
  instanceId,
  isOpen,
  onResolve,
  ...props
}) => {
  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={onResolve}>
      <AvailableFundsDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
