import { Button, Stack, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { FilterConditionInput } from 'generated/graphql';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CategorySelectDialog, CategorySelectDialogProps } from '../selectDialogs/CategorySelectDialog';
import { Filter } from './Filter';

const NAME = 'category';

export const CategoryFilter: React.FC = () => {
  const { t } = useTranslation();

  const { field: manualField } = useController({ name: `${NAME}.manual` });

  const categoryDialog = useDialog<CategorySelectDialogProps, FilterConditionInput>(CategorySelectDialog, {
    filter: manualField.value,
    instanceId: 'filter-transactions-select-category',
    tmpNavigable: true,
  });

  const handleManualClick = async () => {
    try {
      const filter = await categoryDialog.create({ filter: manualField.value });
      manualField.onChange(filter);
    } catch (e) {
      //pass
    }
  };

  const isManual = !!manualField.value;
  return (
    <Filter
      name={NAME}
      onManualClick={handleManualClick}
      options={[]}
      title={t('filterTransactions.filter.category.title')}
    >
      {isManual && (
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography> {manualField.value.values.join(', ')}</Typography>

          <Button color="tertiaryButton" onClick={handleManualClick}>
            <Typography color="blue.dark">{t('filterTransactions.filter.edit')}</Typography>
          </Button>
        </Stack>
      )}
    </Filter>
  );
};
