import { Box, Typography } from '@mui/material';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { format, isBefore, isToday, isYesterday, startOfYesterday } from 'date-fns';
import { useLastAccountsDataRefreshQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './LastRefreshText.styles';

export const LastRefreshText: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { data: dataLastRefresh } = useLastAccountsDataRefreshQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
  });
  const lastRefresh = dataLastRefresh?.lastRefresh;

  return (
    <Box sx={styles.textContainer}>
      {lastRefresh && (
        <Typography color="gray.400" variant="bodyS">
          {isToday(lastRefresh) &&
            t('homepage.dashboard.refresh.lastRefresh.today', {
              time: format(lastRefresh, 'HH:mm'),
            })}

          {isYesterday(lastRefresh) &&
            t('homepage.dashboard.refresh.lastRefresh.yesterday', {
              time: format(lastRefresh, 'HH:mm'),
            })}

          {isBefore(lastRefresh, startOfYesterday()) &&
            t('homepage.dashboard.refresh.lastRefresh.past', {
              date: lastRefresh.toLocaleDateString(i18n.language),
            })}
        </Typography>
      )}
    </Box>
  );
};
