import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { styles } from './FilterTransactions.styles';
import {
  FilterFormValues,
  FilterState,
  mapFilterFormValuesToState,
  mapFilterStateToFormValues,
} from './FilterTransactions.utils';
import { FilterTransactionsContent } from './FilterTransactionsContent';

export interface FilterTransactionsProps extends DialogComponentProps<FilterState> {
  filterState?: FilterState;
}

export const FilterTransactions: React.FC<FilterTransactionsProps> = (props) => {
  const { t } = useTranslation();

  const defaultValues = mapFilterStateToFormValues(props.filterState);

  const schema: yup.SchemaOf<FilterFormValues> = yup.object({
    account: yup.mixed().nullable(),
    amount: yup.object({
      manual: yup
        .object({
          from: yup.number().nullable(true).typeError(t('form.validator.integer')),
          to: yup.number().nullable(true).typeError(t('form.validator.integer')),
        })
        .nullable(true),
      quickfilter: yup.mixed().nullable(),
    }),
    analytics: yup.mixed().nullable(),
    category: yup.mixed().nullable(),
    counterparty: yup.mixed().nullable(),
    date: yup.object({
      manual: yup
        .object({
          from: yup.date().nullable(true).typeError(t('form.validator.invalidDate')),
          to: yup.date().nullable(true).typeError(t('form.validator.invalidDate')),
        })
        .nullable(true),
      quickfilter: yup.mixed().nullable(),
    }),
    periodicity: yup.mixed().nullable(),
    tags: yup.mixed().nullable(),
    type: yup.mixed().nullable(),
  });

  const formMethods = useForm<FilterFormValues>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (formData) => {
    const data = mapFilterFormValuesToState(formData);
    props.onResolve(data);
  });

  return (
    <FormProvider {...formMethods}>
      <ResponsiveSideDialog
        dialogId={props.instanceId}
        header={
          <>
            <Typography sx={styles.title} variant="bodyStrongL">
              {t('filterTransactions.header.title')}
            </Typography>
            <Button color="tertiaryButton" onClick={handleSubmit} sx={styles.submit}>
              <Typography color="blue.dark" variant="bodyStrongL">
                {t('filterTransactions.header.submit')}
              </Typography>
            </Button>
          </>
        }
        isOpen={props.isOpen}
        onClose={props.onResolve}
      >
        <FilterTransactionsContent />
      </ResponsiveSideDialog>
    </FormProvider>
  );
};
