import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as EUIcon } from 'assets/icons/EU.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/EyeGray.svg';
import { ReactComponent as HandIcon } from 'assets/icons/Hand.svg';
import { ReactComponent as LockedComputerIcon } from 'assets/icons/LockedComputer.svg';
import { ReactComponent as NordigenIcon } from 'assets/icons/Nordigen.svg';
import { PSD2_URL } from 'const';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { styles } from './DataProtectionGuarantees.styles';

export const DataProtectionGuarantees: FC = () => {
  const { t } = useTranslation();

  const content = [
    {
      Icon: EUIcon,
      text: (
        <Trans i18nKey="dataProtectionDialog.content1.text">
          <a href={PSD2_URL} target="_blank" />
        </Trans>
      ),
      title: t('dataProtectionDialog.content1.title'),
    },
    {
      Icon: NordigenIcon,
      text: t('dataProtectionDialog.content2.text'),
      title: t('dataProtectionDialog.content2.title'),
    },
    {
      Icon: EyeIcon,
      text: t('dataProtectionDialog.content3.text'),
      title: t('dataProtectionDialog.content3.title'),
    },
    {
      Icon: LockedComputerIcon,
      text: t('dataProtectionDialog.content4.text'),
      title: t('dataProtectionDialog.content4.title'),
    },
    {
      Icon: HandIcon,
      text: t('dataProtectionDialog.content5.text'),
      title: t('dataProtectionDialog.content5.title'),
    },
  ];

  return (
    <Stack spacing={3} sx={styles.container}>
      {content.map((item) => (
        <Stack key={item.title} direction="row" spacing={3}>
          <Box sx={styles.icon}>
            <item.Icon />
          </Box>
          <Stack>
            <Typography variant="bodyStrongM">{item.title}</Typography>
            <Typography color="textSecondary" variant="bodyM">
              {item.text}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
