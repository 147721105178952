import { Stack, Typography } from '@mui/material';
import { FormNumberInput } from 'common/form/components/FormNumberInput';
import { FilterOperator, LogicalOperator } from 'generated/graphql';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Filter, FilterOption } from './Filter';
import { styles } from './styles';

export const useAmountOptions = (): FilterOption[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('filterTransactions.filter.amount.100.label'),
      values: ['100'],
    },
    {
      label: t('filterTransactions.filter.amount.500.label'),
      values: ['500'],
    },
    {
      label: t('filterTransactions.filter.amount.5000.label'),
      values: ['5000'],
    },
  ].map((option) => ({
    key: option.label,
    label: option.label,
    value: {
      arguments: [
        {
          filterCondition: {
            fieldPath: 'baseAmount.amount',
            operator: FilterOperator.GreaterThanOrEqual,
            values: option.values,
          },
        },
        {
          filterCondition: {
            fieldPath: 'baseAmount.amount',
            operator: FilterOperator.LessThanOrEqual,
            values: option.values.map((value) => `-${value}`),
          },
        },
      ],
      operator: LogicalOperator.Or,
    },
  }));
};

const NAME = 'amount';

export const AmountFilter: React.FC = () => {
  const { t } = useTranslation();
  const { field: manualField } = useController({ name: `${NAME}.manual` });

  const amountOptions = useAmountOptions();

  const isManual = manualField.value != null;

  return (
    <Filter name={NAME} options={amountOptions} title={t('filterTransactions.filter.amount.title')}>
      {isManual && (
        <Stack direction="row" pr={4} spacing={3}>
          <Stack alignItems="start" direction="row" spacing={1}>
            <Typography sx={styles.label} variant="bodyL">
              {t('filterTransactions.filter.date.from.label')}
            </Typography>
            <FormNumberInput name={`${NAME}.manual.from`} />
          </Stack>
          <Stack alignItems="start" direction="row" spacing={1}>
            <Typography sx={styles.label} variant="bodyL">
              {t('filterTransactions.filter.date.to.label')}
            </Typography>
            <FormNumberInput name={`${NAME}.manual.to`} />
          </Stack>
        </Stack>
      )}
    </Filter>
  );
};
