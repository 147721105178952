import { SxProps, Theme } from '@mui/material';

export const RESPONSIVE_DIALOG_HEADER_HEIGHT = 52;
export const RESPONSIVE_SIDE_DIALOG_WIDTH = 640;

export const styles: Record<'closeButton' | 'content' | 'dialog' | 'header' | 'backButton', SxProps<Theme>> = {
  backButton: {
    '&:hover': {
      color: (theme) => theme.palette.blue.dark,
    },
    alignItems: 'center',
    color: (theme) => theme.palette.blue.dark,
    display: 'flex',
    left: 16,
    position: { sm: 'absolute', xxs: 'static' },
  },
  closeButton: {
    '&:hover': {
      background: (theme) => theme.palette.gray[300],
    },
    background: (theme) => theme.palette.gray[200],
    borderRadius: '50%',
    boxShadow: 'none',
    left: 16,
    position: { sm: 'absolute', xxs: 'static' },
  },
  content: {
    flexGrow: 1,
    padding: (theme) => ({
      sm: theme.spacing(4, 4, 12, 4),
      xxs: theme.spacing(2, 2, 12, 2),
    }),
  },
  dialog: {
    background: (theme) => theme.palette.gray[100],
    borderRadius: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    maxHeight: 'unset',
    maxWidth: '100%',
    overflowY: 'scroll',
    position: 'fixed',
    right: 0,
    top: 0,
    width: RESPONSIVE_SIDE_DIALOG_WIDTH,
  },
  header: {
    alignItems: 'center',
    backgroundColor: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    height: RESPONSIVE_DIALOG_HEADER_HEIGHT,
    justifyContent: { sm: 'center', xxs: 'space-between' },
    minHeight: RESPONSIVE_DIALOG_HEADER_HEIGHT,
    padding: (theme) => theme.spacing(1, 2),
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
};
