import { formatCurrency } from 'common/utils/formatUtils';
import { endOfMonth, format } from 'date-fns';
import { EChartsOption, graphic } from 'echarts';
import { BalanceValueFragment, DateKey } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { palette } from 'themes/theme';

import { getAmountLabel } from '../utils';

interface ChartOptionsReturn {
  options: EChartsOption;
  totalBalance: string;
}

export const useChartOptions = (data: BalanceValueFragment[], date: Date): ChartOptionsReturn => {
  const { i18n } = useTranslation();
  const currency = data[0].balance.currency;

  const sortedBalances = data
    .map((aggregate) => {
      const dateKey = aggregate.key.find((key) => key.__typename === 'DateKey') as DateKey | undefined;
      return [format(dateKey?.startDate ?? date, 'd'), aggregate.balance.amount];
    })
    .sort(([dayA], [dayB]) => Number(dayA) - Number(dayB)) as [string, number][];

  const finalBalance = sortedBalances[sortedBalances.length - 1];
  const totalBalance = formatCurrency(finalBalance[1], i18n.language, 2, currency);

  const endDay = format(endOfMonth(date), 'd');

  const upcomingBalances = [finalBalance, [endDay, finalBalance[1]]];

  const maxYAxis = Math.max(...sortedBalances.map((item) => item[1]));
  const minYAxis = Math.min(...sortedBalances.map((item) => item[1]), 0);
  const intervalYAxis = getAmountLabel(maxYAxis - minYAxis);

  const colorPalette = finalBalance[1] < 0 ? palette.red : palette.green;

  const options = {
    grid: {
      bottom: 5,
      containLabel: true,
      left: 5,
      right: 5,
      top: 15,
    },
    series: [
      {
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              color: colorPalette[600] ?? '',
              offset: 0,
            },
            {
              color: palette.gray[50],
              offset: 1,
            },
          ]),
        },
        data: [...sortedBalances],
        lineStyle: {
          color: colorPalette[800],
          width: 2,
        },
        silent: true,
        smooth: false,
        symbol: 'none',
        type: 'line',
      },
      {
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              color: palette.gray[200],
              offset: 0,
            },
            {
              color: palette.gray[50],
              offset: 1,
            },
          ]),
        },
        data: upcomingBalances,
        lineStyle: {
          color: palette.gray[300],
          type: 'dashed',
          width: 2,
        },
        silent: true,
        smooth: false,
        symbol: 'none',
        type: 'line',
      },
    ],
    xAxis: {
      axisLabel: {
        showMinLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisTick: { show: false },
      interval: 5,
      max: endDay,
      min: 1,
      splitLine: {
        show: false,
      },
      type: 'value',
    },
    yAxis: {
      axisLabel: {
        formatter: (value: number) => {
          return formatCurrency(value, i18n.language, 0, currency);
        },
        showMaxLabel: true,
        showMinLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisTick: { show: false },
      boundaryGap: [10, 10],
      interval: intervalYAxis,
      max: maxYAxis,
      min: minYAxis,
      position: 'right',
      splitLine: {
        show: false,
      },
      splitNumber: 1,
      type: 'value',
    },
  } as EChartsOption;

  return { options, totalBalance };
};
