import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { formatCurrency, formatIban } from 'common/utils/formatUtils';
import { CategoryIcon } from 'components/transactions/CategoryIcon/CategoryIcon';
import { getTitleForTransactionCounterparty } from 'components/transactions/utils';
import { format } from 'date-fns';
import {
  TransactionGroupPeriodTypeCode,
  useRemovePeriodFromTransactionGroupMutation,
  useUpdatePeriodOfTransactionGroupMutation,
} from 'generated/graphql';
import { i18n } from 'i18n';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QUERIES_TO_REFETCH_AFTER_CHANGE } from '../const';
import { TransactionDetailBox } from '../TransactionDetailBox';
import { SetAmountInputDialog, SetAmountInputDialogProps } from './setDialogs/SetAmountInputDialog';
import { SetDateInputDialogProps, SetReferenceDateInputDialog } from './setDialogs/SetNextDateInputDialog';
import { Period, SetPeriodInputDialog, SetPeriodInputDialogProps } from './setDialogs/SetPeriodInputDialog';
import { styles } from './UpcomingPaymentDetail.styles';
import { UpcomingPaymentHeader } from './UpcomingPaymentHeader';
import { UpcomingPaymentListItem } from './UpcomingPaymentListItem/UpcomingPaymentListItem';
import { ExtendedTransactionGroup, getPeriodicityLabel } from './utils';

export interface UpcomingPaymentDetailProps extends DialogComponentProps<void> {
  group: ExtendedTransactionGroup;
}

const UpcomingPaymentDetailContent: React.FC<UpcomingPaymentDetailProps> = (props) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState<ExtendedTransactionGroup>(props.group);
  const notify = useNotification();

  const [updatePeriod] = useUpdatePeriodOfTransactionGroupMutation({
    onCompleted: async (data) => {
      setGroup({ ...data.updatePeriodOfTransactionGroup, category: group.category });
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: QUERIES_TO_REFETCH_AFTER_CHANGE,
  });

  const handleGroupUpdate = (newGroup: ExtendedTransactionGroup) => {
    if (newGroup.period) {
      setGroup(newGroup);
      updatePeriod({
        variables: {
          groupId: newGroup.id,
          period: {
            expectedAmount: {
              amount: newGroup.period.expectedAmount?.amount ?? 0,
              currency: newGroup.period.expectedAmount?.currency ?? 'EUR',
            },
            periodCount: newGroup.period.periodCount,
            periodTypeCode: newGroup.period.periodTypeCode,
            referenceDate: newGroup.period.referenceDate,
          },
        },
      });
    }
  };

  const convertToNegativeNumber = (amount: number) => {
    return amount > 0 ? amount * -1 : amount;
  };

  const amountDialog = useDialog<SetAmountInputDialogProps, number>(SetAmountInputDialog, {
    instanceId: 'set-amount',
  });

  const handleSetAmount = async () => {
    const editedAmount = await amountDialog.create({
      amount: group.period?.expectedAmount?.amount,
    });
    if (editedAmount && group.period) {
      const newGroup = _.set(_.cloneDeep(group), 'period.expectedAmount.amount', convertToNegativeNumber(editedAmount));
      handleGroupUpdate(newGroup);
    }
  };

  const dateDialog = useDialog<SetDateInputDialogProps, Date>(SetReferenceDateInputDialog, {
    instanceId: 'set-next-date',
  });

  const handleSetDate = async () => {
    const editedDate = await dateDialog.create({
      referenceDate: group.period?.expectedDate || undefined,
    });
    if (editedDate && group.period) {
      const newGroup = _.merge(_.cloneDeep(group), {
        period: {
          expectedDate: editedDate,
          referenceDate: editedDate,
        },
      });

      handleGroupUpdate(newGroup);
    }
  };

  const periodDialog = useDialog<SetPeriodInputDialogProps, Period>(SetPeriodInputDialog, {
    instanceId: 'set-period',
  });

  const handleSetPeriod = async () => {
    const editedPeriod = await periodDialog.create({
      period: {
        periodCount: group.period?.periodCount ?? 1,
        periodTypeCode: group.period?.periodTypeCode ?? TransactionGroupPeriodTypeCode.Month,
      },
    });

    if (editedPeriod.periodCount && editedPeriod.periodTypeCode && group.period) {
      const newGroup = _.cloneDeep(group);
      _.set(newGroup, 'period.periodCount', editedPeriod.periodCount);
      _.set(newGroup, 'period.periodTypeCode', editedPeriod.periodTypeCode);
      handleGroupUpdate(newGroup);
    }
  };

  const [removePeriod, { loading: loadingRemove }] = useRemovePeriodFromTransactionGroupMutation({
    onCompleted: async () => {
      props.onResolve();
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const handleRemoveFromRecurringPayments = () => {
    removePeriod({
      notifyOnNetworkStatusChange: true,
      refetchQueries: QUERIES_TO_REFETCH_AFTER_CHANGE,
      variables: { groupId: group.id },
    });
  };

  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <Stack spacing={3}>
        <UpcomingPaymentHeader group={group} />

        <TransactionDetailBox
          title={getTitleForTransactionCounterparty(group.counterparty, group.period?.expectedAmount?.amount, t)}
        >
          {group.counterparty?.accountIban && (
            <Typography color="textSecondary" variant="bodyM">
              {formatIban(group.counterparty.accountIban)}
            </Typography>
          )}
        </TransactionDetailBox>

        {group.category && (
          <TransactionDetailBox
            image={<CategoryIcon category={group.category} />}
            title={group.category.categoryGroup.name ?? t('transactionDetail.category.title')}
          />
        )}

        <Stack sx={styles.data}>
          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.account')}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Typography color="textSecondary" variant="bodyL">
                {group.account.name}
              </Typography>
              <AccountCard colorIndex={group.account.colorIndex} height={12} width={20} />
            </Stack>
          </UpcomingPaymentListItem>

          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.amount')} onClick={handleSetAmount}>
            <Typography color="textSecondary" variant="bodyL">
              {group.period?.expectedAmount
                ? formatCurrency(
                    group.period.expectedAmount.amount,
                    i18n.language,
                    2,
                    group.period?.expectedAmount.currency,
                    'never',
                  )
                : '-'}
            </Typography>
          </UpcomingPaymentListItem>

          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.nextPayment')} onClick={handleSetDate}>
            <Typography color="textSecondary" variant="bodyL">
              {group.period?.expectedDate ? format(group.period.expectedDate, 'dd.MM.yyyy') : '-'}
            </Typography>
          </UpcomingPaymentListItem>

          <UpcomingPaymentListItem headline={t('upcomingPaymentDetail.period')} onClick={handleSetPeriod}>
            <Typography color="textSecondary" variant="bodyL">
              {group.period
                ? getPeriodicityLabel(
                    t,
                    group.period?.periodCount ?? 1,
                    group.period?.periodTypeCode ?? TransactionGroupPeriodTypeCode.Month,
                  )
                : '-'}
            </Typography>
          </UpcomingPaymentListItem>
        </Stack>

        <Stack>
          <LoadingButton
            color="tertiaryButton"
            loading={loadingRemove}
            onClick={handleRemoveFromRecurringPayments}
            sx={styles.remove}
          >
            <Typography color="red.dark">{t('upcomingPaymentDetail.deleteButton')}</Typography>
          </LoadingButton>
          <Typography color="textSecondary" sx={styles.removeDescription} textAlign="left" variant="bodyS">
            {t('upcomingPaymentDetail.deleteButtonDescription')}
          </Typography>
        </Stack>
      </Stack>
    </ResponsiveSideDialog>
  );
};

export const UpcomingPaymentDetail: React.FC<UpcomingPaymentDetailProps> = (props) => {
  return <UpcomingPaymentDetailContent {...props} />;
};
