import { Box, Skeleton, Stack, Typography, TypographyProps } from '@mui/material';

import { styles } from './ButtonRow.styles';

interface Props {
  label?: string;
  onClick?: () => void;
  color?: TypographyProps['color'];
  loading?: boolean;
}

export const ButtonRow: React.FC<Props> = ({ color, label, loading, onClick }) => {
  if (loading) {
    return <Skeleton sx={styles.skeleton} variant="rectangular" />;
  }

  return (
    <Box onClick={onClick} sx={styles.container}>
      <Stack direction="row" flexGrow={1} justifyContent="space-between">
        <Typography color={color ?? 'blue.dark'} variant="bodyL">
          {label}
        </Typography>
      </Stack>
    </Box>
  );
};
