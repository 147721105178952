import { SxProps, Theme } from '@mui/material';

const carouselButtonWrapper: SxProps<Theme> = {
  alignItems: 'center',
  button: {
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '100%',
    color: (theme) => theme.palette.gray[50],
  },
  display: 'flex',
  height: '100%',
  position: 'absolute',
  top: 0,
};

export const styles: Record<
  | 'carouselContainer'
  | 'carouselItem'
  | 'carouselViewport'
  | 'nextButtonWrapper'
  | 'carousel'
  | 'prevButtonWrapper'
  | 'buttonIcon'
  | 'card'
  | 'title'
  | 'subtitle'
  | 'image'
  | 'dots'
  | 'dot'
  | 'dotActive',
  SxProps<Theme>
> = {
  buttonIcon: { padding: '6px' },
  card: {
    alignItems: 'flex-start',
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    height: 420,
    maxWidth: 'calc(100vw - 16px)',
    padding: (theme) => theme.spacing(14.5, 4, 6, 6),
    position: 'relative',
    width: 420,
  },
  carousel: {
    position: 'relative',
    width: '100%',
  },
  carouselContainer: {
    display: 'flex',
  },
  carouselItem: {
    borderRadius: '10px',
    flexDirection: {
      sm: 'row',
      xxs: 'column',
    },

    overflow: 'hidden',
    width: 'fit-content',
  },
  carouselViewport: {
    maxWidth: 'calc(100vw - 16px)',
    width: '100%',
  },
  dot: {
    background: (theme) => theme.palette.gray[500],
    borderRadius: '2px',
    cursor: 'pointer',
    height: 4,
    width: 16,
  },
  dotActive: {
    background: (theme) => theme.palette.gray[900],
    borderRadius: '2px',

    height: 4,
    width: 24,
  },
  dots: {
    bottom: -32,
    display: 'flex',
    gap: (theme) => theme.spacing(1),
    justifyContent: 'center',
    padding: (theme) => theme.spacing(2),
    position: 'absolute',
    width: '100%',
  },
  image: {
    alignItems: 'center',
    display: 'flex',
    height: 420,
    justifyContent: 'center',
    maxWidth: 'calc(100vw - 16px)',
    width: 420,
  },
  nextButtonWrapper: {
    ...carouselButtonWrapper,
    right: 'max(calc(50% - 50vw + 16px), -64px)',
  },
  prevButtonWrapper: {
    ...carouselButtonWrapper,
    left: 'max(calc(50% - 50vw + 16px), -64px)',
  },
  subtitle: {
    color: (theme) => theme.palette.gray[500],
    justifyContent: 'left',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
  title: {
    color: (theme) => theme.palette.gray[500],
    justifyContent: 'left',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
};
