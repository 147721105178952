import { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { DialogContext } from './DialogContext';
import { ModalContainer } from './ModalPromiseContainer';
import { ContainerRef, InstanceId, Rejector, Resolver } from './types';

interface Props {
  children: ReactNode;
}

export const DialogProvider: React.FC<Props> = ({ children }) => {
  const ref = useRef<ContainerRef>(null);
  const { pathname } = useLocation();

  const resolveInstance: Resolver = (id) => ref.current?.resolve(id);
  const rejectInstance: Rejector = (id: InstanceId) => ref.current?.reject(id);
  const hasInstance = (id: string) => ref.current?.hasInstance(id) ?? false;
  useEffect(() => {
    ref.current?.rejectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <DialogContext.Provider value={{ hasInstance, rejectInstance, resolveInstance }}>
      <ModalContainer ref={ref} />
      {children}
    </DialogContext.Provider>
  );
};
