import { Button, DialogActions } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { LOGIN_URL, ProductFlows } from 'const';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { styles } from './styles';

export interface VerifyEmailFailDialogProps extends DialogComponentProps<void> {
  displayAsPage?: boolean;
}

export const VerifyEmailFailDialog: React.FC<VerifyEmailFailDialogProps> = ({ displayAsPage, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleLogin = () => {
    searchParams.append('saveLocalState', Object.values(ProductFlows).join(','));
    navigate(
      {
        pathname: LOGIN_URL,
        search: `?${searchParams.toString()}`,
      },
      { replace: true },
    );

    onResolve();
  };

  return (
    <StyledDialog displayAsPage={displayAsPage} fullWidth maxWidth="xs" open={isOpen}>
      <StyledDialogTitle>{t('registrationVerification.verificationFailFlowTitle')}</StyledDialogTitle>
      <StyledDialogSubtitle>{t('registrationVerification.verificationFailSubtitle')}</StyledDialogSubtitle>
      <DialogActions>
        <Button onClick={handleLogin} size="large" sx={styles.button}>
          {t('registrationVerification.loginButton')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
