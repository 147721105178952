import { TabContext, TabList } from '@mui/lab';
import { Tab, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { set } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { SpendingReportContent, SpendingReportContentProps } from './SpendingReportContent';
import { styles } from './SpendingReportDialog.styles';
import { PeriodTypeEnum } from './state/spendingReportTypes';
import { useSpendingReportState } from './state/useSpendingReportState';

export interface SpendingReportDialogProps extends DialogComponentProps<void>, Partial<SpendingReportContentProps> {}

export const SpendingReportDialog: React.FC<SpendingReportDialogProps> = (props) => {
  const { state, update } = useSpendingReportState();
  const { t } = useTranslation();

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newPeriodType: PeriodTypeEnum) => {
    const newState = set('params.periodType', newPeriodType, state);

    update(newState);
  };

  return (
    <TabContext value={state.params.periodType}>
      <ResponsiveSideDialog
        dialogId={props.instanceId}
        header={
          props.lockedPeriodType ? undefined : (
            <TabList onChange={handleTabChange} sx={styles.tabs}>
              <Tab
                label={
                  <Typography color="gray.600" variant="bodyStrongS">
                    {t('spendingReport.tabs.week')}
                  </Typography>
                }
                value={PeriodTypeEnum.Week}
              />
              <Tab
                label={
                  <Typography color="gray.600" variant="bodyStrongS">
                    {t('spendingReport.tabs.month')}
                  </Typography>
                }
                value={PeriodTypeEnum.Month}
              />
              <Tab
                label={
                  <Typography color="gray.600" variant="bodyStrongS">
                    {t('spendingReport.tabs.year')}
                  </Typography>
                }
                value={PeriodTypeEnum.Year}
              />
            </TabList>
          )
        }
        isOpen={props.isOpen}
        onClose={props.onResolve}
      >
        <SpendingReportContent {...props} />
      </ResponsiveSideDialog>
    </TabContext>
  );
};
