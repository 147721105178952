import { Stack, Typography } from '@mui/material';
import CashflowGuardianImage from 'assets/images/accounts/cashflow-guardian.png';
import { Image } from 'common/components/Image/Image';
import { useTranslation } from 'react-i18next';

export const CashflowGuardianInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack alignItems="center" spacing={1}>
        <Typography variant="headlineXL">{t('homepage.accountsProductTour.cashflowGuardianInfo.title')}</Typography>
        <Typography color="textSecondary" variant="bodyL">
          {t('homepage.accountsProductTour.cashflowGuardianInfo.subtitle')}
        </Typography>
      </Stack>

      <Image height="auto" maxWidth="100%" src={CashflowGuardianImage} />
    </Stack>
  );
};
