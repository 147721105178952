import { DateGranularity } from 'generated/graphql';

export enum PeriodTypeEnum {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

export interface Period {
  startDate: Date;
  endDate: Date;
  unit: DateGranularity;
}

export interface SpendingReportState {
  params: {
    periodType: PeriodTypeEnum;
  };
}
