import { CalculatorLayout } from 'components/calculators/CalculatorLayout/CalculatorLayout';
import { MortgageCalculatorContent } from 'components/calculators/mortgage/MortgageCalculatorContent';

export const MortgageCalculatorInputPage: React.FC = () => {
  return (
    <CalculatorLayout>
      <MortgageCalculatorContent />
    </CalculatorLayout>
  );
};
