import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CalendarIcon } from 'assets/icons/CalendarGray.svg';
import { ReactComponent as CheckmarkSuccessIcon } from 'assets/icons/Checkmark.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/EyeGray.svg';
import { ReactComponent as HourglassIcon } from 'assets/icons/Hourglass.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import { styles } from './SaveProductSuccessDialog.styles';

export const SaveProductSuccessDialog: React.FC<DialogComponentProps<void>> = ({ instanceId, isOpen, onResolve }) => {
  const { t } = useTranslation();

  const rewards = [
    {
      Icon: CalendarIcon,
      text: t('products:saveProductSuccess.rewards.content1.text'),
      title: t('products:saveProductSuccess.rewards.content1.title'),
    },
    {
      Icon: EyeIcon,
      text: t('products:saveProductSuccess.rewards.content2.text'),
      title: t('products:saveProductSuccess.rewards.content2.title'),
    },
    {
      Icon: HourglassIcon,
      text: t('products:saveProductSuccess.rewards.content3.text'),
      title: t('products:saveProductSuccess.rewards.content3.title'),
    },
  ];

  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={onResolve}>
      <Stack alignItems="center">
        <Box sx={styles.image}>
          <CheckmarkSuccessIcon height={40} width={40} />
        </Box>
        <Typography textAlign="center" variant="headlineXL">
          {t('products:saveProductSuccess.title')}
        </Typography>
        <Typography mt={2} variant="bodyStrongM">
          {t('products:saveProductSuccess.subtitle')}
        </Typography>
        <Typography color="text.secondary" textAlign="center" variant="bodyM">
          {t('products:saveProductSuccess.text')}
        </Typography>
      </Stack>

      <Stack spacing={3} sx={styles.textBox}>
        {rewards.map((item) => (
          <Stack key={item.title} direction="row" spacing={3}>
            <Box sx={styles.icon}>
              <item.Icon />
            </Box>
            <Stack>
              <Typography variant="bodyStrongM">{item.title}</Typography>
              <Typography color="textSecondary" variant="bodyM">
                {item.text}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </ResponsiveSideDialog>
  );
};
