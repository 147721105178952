import { useDialog } from 'common/hooks/useDialog';

import { SelectBankDialog, SelectBankDialogProps } from './SelectBankDialog/SelectBankDialog';

export const useAddAccount = (): (() => Promise<void>) => {
  const selectBankDialog = useDialog<SelectBankDialogProps, string, void>(SelectBankDialog, {
    instanceId: 'select-bank',
    navigable: true,
  });

  return async () => {
    await selectBankDialog.create({});
  };
};
