import { SxProps, Theme } from '@mui/material';

export const styles: Record<'button' | 'divider' | 'menuWrapper', SxProps<Theme>> = {
  button: { '&:hover': { cursor: 'pointer', textDecoration: 'underline' }, paddingY: 1 },
  divider: {
    margin: (theme) => theme.spacing(1, 0),
  },
  menuWrapper: (theme) => ({
    background: theme.palette.white.main,
    border: `1px solid ${theme.palette.gray['400']}`,
    borderRadius: theme.spacing(1),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
    marginTop: theme.spacing(1),
    minWidth: '180px',
    padding: theme.spacing(2),
  }),
};
