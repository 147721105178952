import { Box, Skeleton, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { mergeRefs } from 'common/utils/mergeRefs';
import {
  FilterInput,
  SortDirection,
  SortExpressionInput,
  useAggregateTransactionsQuery,
  useTransactionsQuery,
} from 'generated/graphql';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { TransactionListItem } from '../TransactionListItem/TransactionListItem';
import { NoTransactions } from './NoTransactions';
import { styles } from './TransactionList.styles';
import { WindowScroller } from './WindowScroller';

const TRANSACTION_PAGE_SIZE = 20;

interface Props {
  sortInput?: SortExpressionInput[];
  filterInput?: FilterInput[];
  count?: number;
}

export const TransactionList: React.FC<Props> = ({ count, filterInput, sortInput }) => {
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const listRef = useRef<VariableSizeList>(null);
  const { i18n, t } = useTranslation();

  const filterArguments = { arguments: (filterInput ?? []).map((condition) => ({ filter: condition })) };

  const { data, fetchMore, loading } = useTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      listRef.current?.resetAfterIndex(data.transactionsList.pageIndex * data.transactionsList.pageSize);
    },
    variables: {
      filterInput: {
        arguments: [
          {
            filter: filterArguments,
          },
        ],
      },
      pageInput: {
        pageIndex: 0,
        pageSize: count ?? TRANSACTION_PAGE_SIZE,
      },
      sortInput: {
        by: [
          ...(sortInput ?? [{ direction: SortDirection.Desc, fieldPath: 'bookingDate' }]),
          // add sorting by transaction ID to all filtering by bookingDate/baseAmount to ensure unambiguity
          ...[{ direction: SortDirection.Desc, fieldPath: 'id' }],
        ],
      },
    },
  });

  const { data: totalData } = useAggregateTransactionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filterInput: {
        arguments: [
          {
            filter: filterArguments,
          },
        ],
      },
    },
  });

  const handleLoadMore = (transactionIndex: number) => {
    if (!loading) {
      fetchMore({
        variables: {
          pageInput: {
            pageIndex: Math.trunc(transactionIndex / TRANSACTION_PAGE_SIZE),
            pageSize: TRANSACTION_PAGE_SIZE,
          },
        },
      });
    }
    return;
  };

  const handleItemResize = (index: number) => {
    listRef.current?.resetAfterIndex(index);
  };

  useEffect(() => {
    handleItemResize(0);
    infiniteLoaderRef.current?.resetloadMoreItemsCache(true);
  }, [data]);

  const transactionList = data?.transactionsList.items ?? [];
  const finalCount = count ?? data?.transactionsList.totalItems ?? 1;
  //item count for virtualized list - add some loading rows to trigger loadMore
  const loadingPlaceholderItemCount = finalCount > transactionList.length ? 3 : 0;
  const listItemCount =
    (!!count ? Math.min(count, transactionList.length) : transactionList.length) + loadingPlaceholderItemCount;

  const calculatedHeight = count
    ? transactionList.slice(0, count).reduce((acc, item) => acc + (!!item?.tags?.length ? 98 : 80), 0) +
      loadingPlaceholderItemCount * 80
    : undefined;

  if (data?.transactionsList.totalItems === 0) {
    return <NoTransactions />;
  }

  const filterApplied = filterInput && filterInput?.length > 1;
  const aggregateTransactions = totalData?.aggregateTransactions.resultData[0];
  const totalTransactions = aggregateTransactions?.count;
  const totalSumTransaction = aggregateTransactions?.sum.amount;

  return (
    <Box sx={styles.container}>
      {filterApplied && totalTransactions && (
        <Box mb={3}>
          <Typography color="textSecondary" variant="bodyXXXL">
            <Typography variant="headlineM">
              {t('spendingReport.common.transactionsCount', {
                count: totalTransactions,
              })}
            </Typography>
            &nbsp;/&nbsp;
            {formatTransactionAmount(totalSumTransaction ?? 0, i18n.language, 2)}
          </Typography>
        </Box>
      )}
      <AutoSizer defaultHeight={calculatedHeight} disableHeight={!!calculatedHeight}>
        {({ height: sizedHeight, width }) => (
          <InfiniteLoader
            isItemLoaded={(index: number) => index < transactionList.length && !!transactionList[index]}
            itemCount={listItemCount}
            loadMoreItems={handleLoadMore}
            minimumBatchSize={3}
            ref={infiniteLoaderRef}
          >
            {({ onItemsRendered, ref }) => (
              <WindowScroller>
                {({ onListRendered, outerRef, ref: scrollerRef }) => (
                  <VariableSizeList
                    estimatedItemSize={80}
                    height={calculatedHeight ?? sizedHeight}
                    itemCount={listItemCount}
                    itemData={data?.transactionsList.items}
                    itemSize={(index: number) => {
                      return !!transactionList[index]?.tags.length ? 98 : 80;
                    }}
                    onItemsRendered={(args) => {
                      onListRendered();
                      onItemsRendered(args);
                    }}
                    outerRef={outerRef}
                    overscanCount={5}
                    ref={mergeRefs([ref, scrollerRef, listRef])}
                    style={{ overflow: 'visible' }}
                    width={width}
                  >
                    {itemRenderer}
                  </VariableSizeList>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Box>
  );
};

const itemRenderer = ({ data, index, style }: ListChildComponentProps) => {
  const transaction = data?.[index];
  return transaction ? (
    <Box style={style} sx={styles.row}>
      <TransactionListItem key={transaction.id} transaction={transaction} />
    </Box>
  ) : (
    <Skeleton key={index} height={80} style={{ ...style, height: '80px' }} variant="rectangular" />
  );
};
