import { parseDatestrings } from 'common/utils/parseDatestrings';
import {
  useCreateMortgageRefinanceProcessMutation,
  useSendMortgageRefinanceNotificationToSpecialistMutation,
  useUpdateMortgageRefinanceProcessMutation,
} from 'generated/graphql';
import _ from 'lodash';

import { mapRefinanceStateToInput } from './refinanceState';
import { MortgageRefinanceState } from './refinanceTypes';
import { REFINANCE_STATE_LOCAL_STORAGE_KEY, useMortgageRefinanceState } from './useMortgageRefinanceState';

export const useSaveLocalRefinanceState = (): (() => Promise<string | undefined>) => {
  const [startProcess] = useCreateMortgageRefinanceProcessMutation({
    refetchQueries: ['AllProcesses'],
  });
  const [updateProcess] = useUpdateMortgageRefinanceProcessMutation({
    refetchQueries: ['AllMortgageRefinanceProcesses'],
  });
  const [sendNotification] = useSendMortgageRefinanceNotificationToSpecialistMutation();
  const { clear } = useMortgageRefinanceState();

  const save = async () => {
    const rawState = localStorage.getItem(REFINANCE_STATE_LOCAL_STORAGE_KEY);
    const parsedState = rawState ? JSON.parse(rawState) : undefined;

    if (!_.isEmpty(parsedState)) {
      const stateWithDates = parseDatestrings(parsedState) as MortgageRefinanceState;
      try {
        const processResp = await startProcess();
        const id = processResp.data?.createMortgageRefinanceProcess?.id;
        if (id) {
          await updateProcess({
            variables: {
              id,
              inquiry: mapRefinanceStateToInput(stateWithDates),
            },
          }).then(() => {
            sendNotification({ variables: { processId: id } });
            clear();
          });
        }
        return id;
      } catch (e) {
        //pass
      }
    }
  };

  return save;
};
