import { ChevronRight } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { styles } from './DetailRow.styles';

interface Props<T> {
  label?: string;
  onClick?: () => void;
  value?: T | null;
  formatter?: (value: T) => string;
  disabled?: boolean;
}

export const DetailRow = <T,>({ disabled, formatter, label, onClick, value }: Props<T>): React.ReactElement => {
  const displayValue = value ? formatter?.(value) ?? `${value}` : '-';

  if (disabled)
    return (
      <Box sx={styles.disabledRow}>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          {!!label && (
            <Typography color="textSecondary" variant="bodyL">
              {label}
            </Typography>
          )}
          <Typography color="textSecondary" variant="bodyL">
            -
          </Typography>
        </Stack>
        <ChevronRight color="secondary" sx={styles.icon} />
      </Box>
    );

  return (
    <Box onClick={onClick} sx={styles.clickableRow}>
      <Stack direction="row" flexGrow={1} justifyContent="space-between">
        {!!label && <Typography variant="bodyL">{label}</Typography>}
        <Typography color={label ? 'textSecondary' : 'textPrimary'} variant="bodyL">
          {displayValue}
        </Typography>
      </Stack>
      <ChevronRight sx={styles.icon} />
    </Box>
  );
};
