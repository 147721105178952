import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Link, Stack, Typography } from '@mui/material';
import { ReactComponent as GoogleLogo } from 'assets/icons/GoogleLogo.svg';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormInput } from 'common/form/components/FormInput';
import { FormPasswordInput } from 'common/form/components/FormPasswordInput';
import { loginEmailValidator, loginPasswordValidator } from 'common/form/validators';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { pushGTMEvent } from 'common/utils/GTMeventUtils';
import { GTMEventValueEnum, ProductFlows } from 'const';
import { useContext, useState } from 'react';
import { MobileView } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ForgotPasswordDialog, ForgotPasswordDialogProps } from './ForgotPasswordDialog';
import { GoogleLoginInfoDialog } from './GoogleLoginInfoDialog';
import { RegisterDialog, RegisterDialogProps } from './RegisterDialog';
import { styles } from './styles';
import { VerifyEmailDialog, VerifyEmailDialogProps } from './VerifyEmailDialog';

interface FormValues {
  email: string;
  password: string;
}

export interface LoginDialogProps extends DialogComponentProps<void> {
  displayAsPage?: boolean;
  title?: string | React.ReactNode;
  saveLocalState?: ProductFlows[];
  showGoogleLoginInfo?: boolean;
  showForgotPassword?: boolean;
  showRegistrationInfo?: boolean;
}

export const LoginDialog: React.FC<LoginDialogProps> = ({
  displayAsPage,
  isOpen,
  onReject,
  onResolve,
  saveLocalState,
  showForgotPassword = true,
  showGoogleLoginInfo = true,
  showRegistrationInfo = true,
  title,
}) => {
  const { t } = useTranslation();

  const { resendSignUp, signInError } = useContext(AuthContext);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { signIn, socialLogin } = useContext(AuthContext);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    email: loginEmailValidator(),
    password: loginPasswordValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const verifyEmailDialog = useDialog<VerifyEmailDialogProps, void>(VerifyEmailDialog, {
    instanceId: 'verify-email-dialog',
  });

  const handleEmailResend = async (email: string) => {
    const result = await resendSignUp(email);

    verifyEmailDialog.create({
      email: email,
      formErrorContent: result?.formError?.message,
      title: <Trans i18nKey={'registrationVerification.loginFlowTitle'} />,
    });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    setFormError('');

    const result = await signIn({ email: data.email, password: data.password });

    if (!result) {
      await pushGTMEvent(GTMEventValueEnum.Login, data.email);
      const url = new URL(window.location.href);
      if (!!saveLocalState) url.searchParams.set('saveLocalState', saveLocalState.join(','));
      onResolve();
      return;
    }
    if (result.fieldError) {
      Object.entries(result.fieldError).forEach(([name, error]) =>
        formMethods.setError(name as keyof FormValues, { message: error.message }),
      );
    }
    if (result.formError?.code === 'UserNotConfirmedException') {
      await handleEmailResend(data.email);
    } else if (result.formError) {
      setFormError(result.formError.message);
    }
    setIsLoading(false);
  });

  const forgotPasswordDialog = useDialog<ForgotPasswordDialogProps, void>(ForgotPasswordDialog, {
    instanceId: 'forgot-password',
  });

  const handleForgotPassword = () => {
    forgotPasswordDialog.create({ email: formMethods.getValues().email, saveLocalState });
  };

  const registerDialog = useDialog<RegisterDialogProps, void>(RegisterDialog, {
    instanceId: 'register-dialog',
  });

  const handleRegister = () => {
    registerDialog.create({});
    onResolve();
  };

  const handleGoogleLogin = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('gtmEvent', 'Login');
    if (!!saveLocalState) url.searchParams.set('saveLocalState', saveLocalState.join(','));
    socialLogin(CognitoHostedUIIdentityProvider.Google, url.pathname + url.search + url.hash);
  };

  const googleLoginInfoDialog = useDialog<DialogComponentProps<void>, void>(GoogleLoginInfoDialog, {
    instanceId: 'google-login-info',
  });

  const handleGoogleLoginInfoClick = () => {
    googleLoginInfoDialog.create({});
  };

  return (
    <StyledDialog displayAsPage={displayAsPage} fullWidth maxWidth="xs" onClose={() => onReject()} open={isOpen}>
      <StyledDialogTitle>{title ?? t('login.title')}</StyledDialogTitle>

      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            {showGoogleLoginInfo && (
              <Stack spacing={1} sx={styles.googleButton}>
                <Button color="secondaryButton" onClick={handleGoogleLogin} startIcon={<GoogleLogo />}>
                  {t('login.googleLogin')}
                </Button>

                <MobileView>
                  <Typography>
                    <Trans i18nKey="login.googleLoginInfo">
                      <Link onClick={handleGoogleLoginInfoClick} variant="bodyS" />
                    </Trans>
                  </Typography>
                </MobileView>
              </Stack>
            )}

            <FormInput
              autoComplete="email"
              autoFocus
              label={t('login.email.label')}
              name="email"
              placeholder={t('login.email.placeholder')}
              sx={styles.input}
              type="email"
            />

            <FormPasswordInput
              autoComplete="password"
              label={t('login.password.label')}
              name="password"
              placeholder={t('login.password.placeholder')}
              sx={styles.input}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} size="large" sx={styles.button} type="submit">
              {t('login.loginButton')}
            </LoadingButton>
          </DialogActions>
          {formError && <StyledDialogFormError content={formError} title={t('login.error.title')} />}
          {signInError.includes('UsernameExistsException') && (
            <StyledDialogFormError content={formError} title={t('googleLogin.errors.UsernameExistsException')} />
          )}
        </Box>
        {showForgotPassword && (
          <Typography color="textSecondary" marginTop={4} textAlign="center" variant="body1">
            <Trans i18nKey="login.forgotPassword">
              <Link onClick={handleForgotPassword} />
            </Trans>
          </Typography>
        )}

        {showRegistrationInfo && (
          <Typography color="textSecondary" marginTop={4} textAlign="center" variant="body1">
            <Trans i18nKey="login.goToRegistration">
              <Link onClick={handleRegister} />
            </Trans>
          </Typography>
        )}
      </FormProvider>
    </StyledDialog>
  );
};
