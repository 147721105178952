import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatCurrency } from 'common/utils/formatUtils';
import { AvailableFundsDialog } from 'components/balances/AvailableFundsDialog/AvailableFundsDialog';
import { useAggregateBalancesQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './Dashboard.styles';

export const AvailableFunds: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);

  const { data, loading } = useAggregateBalancesQuery({
    skip: !isAuthenticated,
  });
  const currentBalance = data?.aggregateBalances.resultData[0]?.balance;

  const connectedAccountsDialog = useDialog<DialogComponentProps<void>, void>(AvailableFundsDialog, {
    instanceId: 'available-funds',
    navigable: true,
  });

  const handleAvailableFundsClick = async () => {
    await connectedAccountsDialog.create({});
  };

  return (
    <Box onClick={handleAvailableFundsClick} sx={styles.box}>
      <Stack>
        <Typography variant="bodyM">{t('homepage.dashboard.availableFunds.title')}</Typography>
        {loading || isAuthLoading ? (
          <Skeleton height={34} variant="rectangular" />
        ) : (
          <Typography variant="headlineL">
            {currentBalance ? formatCurrency(currentBalance.amount, i18n.language, 2, currentBalance.currency) : ''}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
