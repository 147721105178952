import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container', SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    margin: (theme) => theme.spacing(3, 0),
    overflow: 'hidden',
  },
};
