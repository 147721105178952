import { FinancialProductType } from 'generated/graphql';
import { ReferenceField, useRecordContext } from 'react-admin';

interface ProductReferenceFieldProps {
  label?: string;
}

export const ProductReferenceField: React.FC<ProductReferenceFieldProps> = ({ label }) => {
  const record = useRecordContext();

  switch (record.details?.productType as FinancialProductType) {
    case FinancialProductType.CollisionInsurance:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="CollisionInsuranceProduct"
          sortable={false}
          source="details.productId"
        />
      );
    case FinancialProductType.Generic:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="GenericProduct"
          sortable={false}
          source="details.productId"
        />
      );
    case FinancialProductType.Investment:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="InvestmentProduct"
          sortable={false}
          source="details.productId"
        />
      );
    case FinancialProductType.LifeInsurance:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="LifeInsuranceProduct"
          sortable={false}
          source="details.productId"
        />
      );
    case FinancialProductType.Mortgage:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="MortgageProduct"
          sortable={false}
          source="details.productId"
        />
      );
    case FinancialProductType.MotorLiabilityInsurance:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="MotorLiabilityInsuranceProduct"
          sortable={false}
          source="details.productId"
        />
      );
    case FinancialProductType.PropertyInsurance:
      return (
        <ReferenceField
          label={label}
          link="show"
          reference="PropertyInsuranceProduct"
          sortable={false}
          source="details.productId"
        />
      );
  }

  return <></>;
};
