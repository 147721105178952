import { SxProps, Theme } from '@mui/material';

export const styles: Record<'root' | 'large' | 'small', SxProps<Theme>> = {
  large: {
    height: '60px',
  },
  root: {
    '& img:nth-of-type(-n+2)': {
      border: (theme) => `2px solid ${theme.palette.gray['100']}`,
      borderRadius: '50%',
      position: 'relative',
    },
    '& img:nth-of-type(1)': {
      transform: 'translate(14px)',
      zIndex: 3,
    },
    '& img:nth-of-type(2)': {
      transform: 'translate(7px)',
      zIndex: 2,
    },
    transform: 'translate(-14px)',
  },
  small: {
    height: '40px',
  },
};
