import { useReactiveVar } from '@apollo/client';
import { useLoadingIndicator } from 'common/hooks/useLoadingIndicator';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { parseDatestrings } from 'common/utils/parseDatestrings';
import {
  mapPurchaseInquiryToState,
  mortgagePurchaseState,
  trimPurchaseState,
} from 'components/flows/mortgagePurchase/state/purchaseState';
import { MortgagePurchaseState } from 'components/flows/mortgagePurchase/state/purchaseTypes';
import { useMortgagePurchaseProcessLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const PURCHASE_STATE_LOCAL_STORAGE_KEY = 'mortgagePurchaseState';

interface UseMortgagePurchaseStateReturn {
  state: MortgagePurchaseState;
  update: (newState: MortgagePurchaseState) => void;
  clear: () => void;
}

export const useMortgagePurchaseState = (): UseMortgagePurchaseStateReturn => {
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);
  const params = useParams();

  const state = useReactiveVar(mortgagePurchaseState);

  const update = (newState: MortgagePurchaseState) => {
    const stateWithTimestamp = trimPurchaseState(newState);
    _.set(stateWithTimestamp, 'meta.updateTimestamp', new Date());
    if (!isAuthenticated && !params?.id) {
      localStorage.setItem(PURCHASE_STATE_LOCAL_STORAGE_KEY, JSON.stringify(stateWithTimestamp));
    }
    mortgagePurchaseState(stateWithTimestamp);
  };

  const clear = () => {
    localStorage.removeItem(PURCHASE_STATE_LOCAL_STORAGE_KEY);
    mortgagePurchaseState({});
  };

  const { t } = useTranslation();
  const { end: endLoading, start: startLoading } = useLoadingIndicator(
    t('flows/mortgage/purchase:saving.loading.message'),
  );

  const [getProcessData] = useMortgagePurchaseProcessLazyQuery({
    onCompleted: (data) => update(mapPurchaseInquiryToState(data.process?.inquiry, data.process?.updateTimestamp)),
  });

  useEffect(() => {
    if (_.isEmpty(state) && params.id) {
      startLoading(t('flows/mortgage/purchase:loading.loadingMessage'));
      getProcessData({ variables: { id: params.id } }).then(() =>
        endLoading(t('flows/mortgage/purchase:loading.loadingMessage')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading && _.isEmpty(state)) {
      const savedData = localStorage.getItem(PURCHASE_STATE_LOCAL_STORAGE_KEY);
      const parsedData = savedData ? JSON.parse(savedData) : undefined;
      if (!_.isEmpty(parsedData)) {
        const dataWithDates = parseDatestrings(parsedData) as MortgagePurchaseState;
        mortgagePurchaseState(dataWithDates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthLoading]);

  return { clear, state, update };
};
