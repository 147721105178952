import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import { palette } from 'themes/theme';

const homePalette = {
  ...palette,
  primary: {
    contrastText: '#FFFFFF',
    contrastTextDark: '#000000',
    dark: '#0038C2',
    light: '#2094FA',
    main: '#0040DD',
  },
  success: {
    main: '#67C58C',
  },
  text: {
    primary: '#222222',
    secondary: '#6D6D6D',
  },
};

const baseTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1768,
      md: 1280,
      sm: 768,
      xl: 2160,
      xs: 304,
      xxs: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.Mui-disabled': {
            backgroundColor:
              ownerState.color !== 'primary' ? 'transparent' : homePalette[ownerState.color ?? 'primary']?.main,
            color: homePalette[ownerState.color ?? 'primary']?.contrastText,
            ...(ownerState.color === 'secondaryButton' && {
              boxShadow: `inset 0px 0px 0px 2px ${homePalette[ownerState.color].dark}`,
            }),
            opacity: 0.5,
          },
          '&:active': {
            backgroundColor:
              ownerState.variant !== 'contained'
                ? homePalette[ownerState.color ?? 'primary']?.main
                : homePalette[ownerState.color ?? 'primary']?.dark,
            boxShadow: 'none',
            color: homePalette[ownerState.color ?? 'primary']?.contrastText,
          },
          '&:hover, &:focus': {
            backgroundColor: homePalette[ownerState.color ?? 'primary']?.dark,
            boxShadow: 'none',
            color: homePalette[ownerState.color ?? 'primary']?.contrastText,
          },
          ...(ownerState.color !== 'primary' && { background: 'transparent' }),
          ...(ownerState.color === 'secondaryButton' && {
            boxShadow: `inset 0px 0px 0px 2px ${homePalette[ownerState.color].dark}`,
          }),
          borderRadius: 8,
          textTransform: 'none',
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
            marginTop: 0,
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: ({ ownerState }) => ({
          '.MuiCircularProgress-root': {
            svg: {
              height: ownerState.size === 'large' ? 32 : 24,
              width: ownerState.size === 'large' ? 32 : 24,
            },
          },
          color: homePalette[ownerState.color ?? 'primary']?.contrastText,
        }),
        root: ({ ownerState }) => ({
          '&.Mui-disabled': {
            backgroundColor:
              ownerState.color !== 'primary' ? 'transparent' : homePalette[ownerState.color ?? 'primary']?.main,
            color: homePalette[ownerState.color ?? 'primary']?.contrastText,
            ...(ownerState.color === 'secondaryButton' && {
              boxShadow: `inset 0px 0px 0px 2px ${homePalette[ownerState.color].dark}`,
            }),
            opacity: 0.5,
          },
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ':not(.Mui-selected)': {
            border: ownerState.today ? `1px solid ${theme.palette.blue[600]}` : 'none',
          },
          backgroundColor: 'transparent',
          color: theme.palette.gray[900],
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: 16,
          padding: 0,
          width: 32,
        },
        switchBase: ({ theme }) => ({
          '&.Mui-checked': {
            '& + .MuiSwitch-thumb': {
              border: 'none',
              opacity: 1,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.success.main,
              opacity: 1,
            },
            borderColor: theme.palette.success.main,
            color: '#fff',
            transform: 'translateX(16px)',
          },
          border: `2px solid ${theme.palette.gray[600]}`,
          padding: 0,
        }),
        thumb: ({ theme }) => ({
          borderRadius: '100%',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
          height: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
          width: 12,
        }),
        track: ({ theme }) => ({
          backgroundColor: theme.palette.gray[300],
          borderRadius: '40px',
          boxSizing: 'border-box',
          height: 16,
          opacity: 1,
          width: 32,
        }),
      },
    },
  },
  palette: homePalette,
  typography: {
    bodyL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '17px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    bodyM: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    bodyS: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    bodyStrongL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '17px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongM: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongS: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXS: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '11px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXXXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXS: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '13px',
    },
    bodyXXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '25px',
    },
    bodyXXXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    button: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '24px',
    },
    fontFamily: ['Inter', 'sans-serif'].join(','),
    headlineL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 28,
      fontWeight: 600,
      lineHeight: '34px',
    },
    headlineM: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 22,
      fontWeight: 600,
      lineHeight: '28px',
    },
    headlineS: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '25px',
    },
    headlineXL: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 34,
      fontWeight: 600,
      lineHeight: '41px',
    },
    headlineXS: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '22px',
    },
  },
});

export const homeTheme = baseTheme;
