import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FormCheckBoxGroup, FormCheckboxGroupProps } from 'common/form/components/FormCheckboxGroup/FormCheckboxGroup';
import { FormDatePicker } from 'common/form/components/FormDatePicker/FormDatePicker';
import { FormInput } from 'common/form/components/FormInput';
import { FormNumberInput } from 'common/form/components/FormNumberInput';
import { FormRadioSelect, FormRadioSelectProps } from 'common/form/components/FormRadioSelect';
import { FormSelect, FormSelectProps } from 'common/form/components/FormSelect';

import { styles } from './InputField.styles';

interface InputBase<T> {
  text?: string;
  name: RecordPaths<T>;
  condition?: (values: T) => boolean;
  label?: string | React.ReactNode;
  fullWidth?: boolean;
  helperText?: string;
}
interface TextFieldInput<T> extends InputBase<T> {
  endAdornmentText?: string;
  inputType: 'text';
  multiline?: boolean;
  placeholder?: string;
}

interface RadioSelectInput<T> extends InputBase<T> {
  inputType: 'radioSelect';
  options: FormRadioSelectProps<unknown>['options'];
}

interface SelectInput<T> extends InputBase<T> {
  inputType: 'select';
  options: FormSelectProps['options'];
  loading?: boolean;
  placeholder?: string;
}
interface DateInput<T> extends InputBase<T> {
  inputType: 'date';
}

interface IntegerInput<T> extends InputBase<T> {
  endAdornmentText?: string;
  inputType: 'integer';
  placeholder?: string;
}

interface NumberInput<T> extends InputBase<T> {
  endAdornmentText?: string;
  inputType: 'number';
  placeholder?: string;
}

interface CheckboxInput<T> extends InputBase<T> {
  inputType: 'checkbox';
  columns?: FormCheckboxGroupProps['columns'];
  options: FormCheckboxGroupProps['options'];
}

export type Input<T> =
  | TextFieldInput<T>
  | IntegerInput<T>
  | DateInput<T>
  | SelectInput<T>
  | NumberInput<T>
  | RadioSelectInput<T>
  | CheckboxInput<T>;

interface Props<T> {
  field: Input<T>;
  basePath?: string;
  sx?: SxProps<Theme>;
  autoFocus?: boolean;
}

export const InputFieldBase = <T = Record<string, string | number>,>({
  autoFocus,
  basePath,
  field,
  sx,
}: Props<T>): React.ReactElement => {
  const name = (basePath ? `${basePath}.${field.name}` : field.name) as string;

  const gridItemStyle = { ...styles.gridItem, ...sx } as SxProps<Theme>;

  if (field.inputType === 'select') {
    return (
      <Box sx={gridItemStyle}>
        {field.text && (
          <Typography color="textSecondary" marginBottom={4} variant="bodyL">
            {field.text}
          </Typography>
        )}
        <FormSelect
          autoFocus={autoFocus}
          helperText={field.helperText}
          label={field.label}
          loading={field.loading}
          name={name}
          options={field.options}
          placeholder={field.placeholder}
          sx={styles.form}
        />
      </Box>
    );
  }

  if (field.inputType === 'radioSelect') {
    return (
      <Box sx={gridItemStyle}>
        {field.text && (
          <Typography color="textSecondary" mb={4} variant="bodyL">
            {field.text}
          </Typography>
        )}
        <FormRadioSelect
          helperText={field.helperText}
          label={field.label}
          name={name}
          options={field.options}
          sx={styles.form}
        />
      </Box>
    );
  }

  if (field.inputType === 'checkbox') {
    return (
      <Box sx={gridItemStyle}>
        {field.text && (
          <Typography color="textSecondary" mb={4} variant="bodyL">
            {field.text}
          </Typography>
        )}
        <FormCheckBoxGroup columns={field.columns} label={field.label} name={name} options={field.options} />
      </Box>
    );
  }

  if (field.inputType === 'date') {
    return (
      <Box sx={gridItemStyle}>
        <FormDatePicker helperText={field.helperText} label={field.label} name={name} sx={styles.form} />
      </Box>
    );
  }

  if (field.inputType === 'integer') {
    return (
      <Box sx={gridItemStyle}>
        {field.text && (
          <Typography color="textSecondary" marginBottom={4} variant="bodyL">
            {field.text}
          </Typography>
        )}
        <FormNumberInput
          InputProps={{
            autoFocus,
            endAdornment: (
              <Typography color="textSecondary" variant="bodyL">
                {field.endAdornmentText}
              </Typography>
            ),
          }}
          helperText={field.helperText}
          label={field.label}
          name={name}
          placeholder={field.placeholder}
          sx={styles.form}
        />
      </Box>
    );
  }

  return (
    <Box sx={gridItemStyle}>
      {field.text && (
        <Typography color="textSecondary" marginBottom={4} variant="bodyL">
          {field.text}
        </Typography>
      )}
      <FormInput
        InputProps={{
          autoFocus,
          endAdornment: (
            <Typography color="textSecondary" variant="bodyL">
              {field.endAdornmentText}
            </Typography>
          ),
          minRows: field.inputType === 'text' && field.multiline ? 2 : undefined,
          multiline: field.inputType === 'text' ? field.multiline : undefined,
        }}
        helperText={field.helperText}
        label={field.label}
        name={name}
        placeholder={field.placeholder}
        sx={styles.form}
        type={field.inputType as string}
      />
    </Box>
  );
};
