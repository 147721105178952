import { DATA_PROTECTION_POLICY_URL } from '@brand/const';
import { LoadingButton } from '@mui/lab';
import { Box, Link as MuiLink, Stack, Typography } from '@mui/material';
import { InfoBox } from 'common/components/InfoBox/InfoBox';
import { Link } from 'common/components/Link/Link';
import { useInfoDialog } from 'common/hooks/useInfoDialog';
import { PROPERTY_INSURANCE_BASE_URL } from 'const';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IntroStep, Stepper } from '../../common/IntroStepper/Stepper';

export const Content: React.FC = () => {
  const { t } = useTranslation();

  const openInfoDialog = useInfoDialog();

  const navigate = useNavigate();

  const steps: IntroStep[] = [
    { title: t('propertyInsurance.intro.stepper.step0.title') },
    {
      title: t('propertyInsurance.intro.stepper.step1.title'),
    },
    { title: t('propertyInsurance.intro.stepper.step2.title') },
  ];

  const handleContinue = () => {
    navigate(`${PROPERTY_INSURANCE_BASE_URL}/purchase`);
  };

  const handleMoreInfo = () => {
    openInfoDialog({
      message: (
        <Trans i18nKey={`propertyInsurance.intro.infoBox.popup.message`}>
          <MuiLink href={DATA_PROTECTION_POLICY_URL} target="_blank" variant="medium" />
        </Trans>
      ),
    });
  };

  return (
    <Stack>
      <Typography component="h1" mb={4} variant="headlineXL">
        <Trans i18nKey="propertyInsurance.intro.title" />
      </Typography>
      <Stepper mb={2} steps={steps} />
      <InfoBox title={t('infoBox.title')}>
        {t('propertyInsurance.intro.infoBox.description')}{' '}
        <Link onClick={handleMoreInfo} to={''}>
          {t('propertyInsurance.intro.infoBox.linkLabel')}
        </Link>
      </InfoBox>
      <Box>
        <LoadingButton onClick={handleContinue} size="large">
          {t('propertyInsurance.intro.button')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};
