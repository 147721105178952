import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { i18n } from 'i18n';
import { useTranslation } from 'react-i18next';

import { useCashflowState } from '../useCashflowState';
import { getColorForLeft2Spend } from '../utils';
import { CashflowGuardianWidgetChart } from './CashflowGuardianWidgetChart';

export const CashflowGuardianWidgetContent: React.FC = () => {
  const { t } = useTranslation();

  const cashflowState = useCashflowState(new Date());

  if (!cashflowState?.budget || cashflowState.loading) {
    return <Skeleton height="100%" variant="rectangular" />;
  }

  const color = getColorForLeft2Spend(cashflowState.leftToSpend.amount, cashflowState.budget.amount);

  const title =
    cashflowState.leftToSpend.amount >= 0
      ? t('cashflowGuardian.common.disposable')
      : t('cashflowGuardian.common.overdraft');

  return (
    <Stack alignItems="center" direction="row" gap={2}>
      <Box width={48}>
        <CashflowGuardianWidgetChart budget={cashflowState.budget} leftToSpend={cashflowState.leftToSpend} />
      </Box>
      <Stack>
        <Typography variant="bodyM">{title}</Typography>
        <Typography color={color[800]} variant="headlineL">
          {formatTransactionAmount(
            cashflowState?.leftToSpend.amount ?? 0,
            i18n.language,
            2,
            cashflowState?.leftToSpend.currency,
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
