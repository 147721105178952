import { Box, Button, Container, SxProps } from '@mui/material';
import { Header } from 'common/components/Header/Header';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { LoginDialog, LoginDialogProps } from 'components/auth/LoginDialog';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './BasicLayout.styles';

type BasicLayoutProps = {
  children?: React.ReactNode;
  contentCenter?: boolean;
  showLogin?: boolean;
};

export const BasicLayout: React.FC<BasicLayoutProps> = ({ children, contentCenter, showLogin = true }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });

  const handleLogin = () => {
    loginDialog.create({});
  };

  return (
    <Box sx={styles.page}>
      <Box sx={styles.backgroundCircleWrapper}>
        <Box sx={styles.backgroundCircleCenter}>
          <Box sx={styles.circle1} />
          <Box sx={styles.circle2} />
        </Box>
      </Box>
      <Header>
        {showLogin && !isAuthenticated && (
          <Button color="secondaryButton" onClick={handleLogin} size="medium">
            {t('introduction.login')}
          </Button>
        )}
      </Header>
      <Container component="main" sx={[styles.container, contentCenter && styles.containerCenter] as SxProps}>
        {children}
      </Container>
      <Box component="footer" sx={styles.footer} />
    </Box>
  );
};
