import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const StylableTooltip: React.FC<TooltipProps> = ({ className, ...props }) => (
  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} {...props} classes={{ popper: className }} />
);

export const LightTooltip = styled(StylableTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    '& .MuiTooltip-arrow': {
      color: theme.palette.gray[900],
    },
    backgroundColor: theme.palette.gray[900],
    borderRadius: '4px',
    color: theme.palette.gray[50],
    fontSize: '13px',
    padding: '4px 8px',
  },
}));
