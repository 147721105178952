import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/Refresh.svg';
import { ReactComponent as UnreadIcon } from 'assets/icons/Unread.svg';
import InsuranceAvatar from 'assets/images/insurance-avatar.png';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useInitNordigenFlow } from 'components/accounts/AccountConnectUtils/AccountConnectUtils';
import {
  UploadDocumentDialog,
  UploadDocumentDialogProps,
} from 'components/products/UploadDocumentDialog/UploadDocumentDialog';
import { useProductDetail } from 'components/products/useProductDetail';
import { useProductQuery } from 'components/products/useProductQuery';
import { MORTGAGE_REFINANCE_BASE_URL, MOTOR_LIABILITY_INSURANCE_BASE_URL, PROPERTY_INSURANCE_BASE_URL } from 'const';
import { differenceInDays, format, isToday, isYesterday, startOfDay } from 'date-fns';
import {
  CalendarEventNotificationDetails,
  NordigenConsentExpirationNotificationDetails,
  NotificationFragment,
  NotificationType,
  ProductNotificationDetails,
  RequestContactContext,
  useDeactivateNotificationMutation,
  useMarkNotificationAsReadMutation,
} from 'generated/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FinancialProductTypeTranslations, NotificationTypeTranslations } from 'translations/enums';

import { styles } from './NotificationListItem.styles';

export interface EventItemProps {
  notification: NotificationFragment;
}

export const NotificationListItem: React.FC<EventItemProps> = ({ notification }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const navigate = useNavigate();
  const [loadingAction, setLoadingAction] = useState(false);

  const creationDate = new Date(notification.creationTimestamp);
  const dueDate = notification.dueDate;

  const productDetails = notification.details as ProductNotificationDetails;
  const productId = productDetails.productId;
  const productType = productDetails.productType;

  const calendarEventDetails = notification.details as CalendarEventNotificationDetails;
  const calendarEventName = calendarEventDetails.calendarEvent && calendarEventDetails.calendarEvent.name;

  const nordigenConsentExpirationDetails = notification.details as NordigenConsentExpirationNotificationDetails;
  const accountNames =
    nordigenConsentExpirationDetails.nordigenConnection &&
    nordigenConsentExpirationDetails.nordigenConnection.accounts.map((account) => account.name).join(', ');

  const title = {
    [NotificationType.ActiveProductWithoutDocument]: t(
      'homepage.notifications.item.activeProductWithoutDocument.title',
      {
        productType: FinancialProductTypeTranslations(t)[productDetails.productType],
      },
    ),
    [NotificationType.CalendarEvent]:
      dueDate &&
      t('homepage.notifications.item.calendarEvent.title', {
        date: format(dueDate, 'd. M. yyyy'),
        name: calendarEventName,
      }),
    [NotificationType.CollisionInsuranceProductExpiration]:
      dueDate &&
      t('homepage.notifications.item.collisionInsuranceProductExpiration.title', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.CollisionInsuranceProductPayment]: t(
      'homepage.notifications.item.collisionInsuranceProductPayment.title',
    ),
    [NotificationType.GenericProductExpiration]:
      dueDate &&
      t('homepage.notifications.item.genericProductExpiration.title', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.LifeInsuranceAnnualUpdate]: t('homepage.notifications.item.lifeInsuranceAnnualUpdate.title'),
    [NotificationType.MortgageProductFixationEnd]:
      dueDate &&
      t('homepage.notifications.item.mortgageProductFixationEnd.title', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.MotorLiabilityInsuranceProductExpiration]:
      dueDate &&
      t('homepage.notifications.item.motorLiabilityInsuranceProductExpiration.title', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.MotorLiabilityInsuranceProductPayment]: t(
      'homepage.notifications.item.motorLiabilityInsuranceProductPayment.title',
    ),
    [NotificationType.NordigenConsentExpiration]:
      dueDate &&
      t('homepage.notifications.item.nordigenConsentExpiration.title', {
        context: dueDate < new Date() ? 'past' : 'future',
      }),
    [NotificationType.ProductWaitingForSubmission]: t('homepage.notifications.item.productWaitingForSubmission.title', {
      productType: FinancialProductTypeTranslations(t)[productDetails.productType],
    }),
    [NotificationType.PropertyInsuranceProductExpiration]:
      dueDate &&
      t('homepage.notifications.item.propertyInsuranceProductExpiration.title', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.PropertyInsuranceProductPayment]: t(
      'homepage.notifications.item.propertyInsuranceProductPayment.title',
    ),
  }[notification.type];

  const message = {
    [NotificationType.ActiveProductWithoutDocument]: t(
      'homepage.notifications.item.activeProductWithoutDocument.message',
    ),
    [NotificationType.CalendarEvent]: t('homepage.notifications.item.calendarEvent.message'),
    [NotificationType.CollisionInsuranceProductExpiration]: t(
      'homepage.notifications.item.collisionInsuranceProductExpiration.message',
    ),
    [NotificationType.CollisionInsuranceProductPayment]:
      dueDate &&
      t('homepage.notifications.item.collisionInsuranceProductPayment.message', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.GenericProductExpiration]: t('homepage.notifications.item.genericProductExpiration.message'),
    [NotificationType.LifeInsuranceAnnualUpdate]: t('homepage.notifications.item.lifeInsuranceAnnualUpdate.message'),
    [NotificationType.MortgageProductFixationEnd]: t('homepage.notifications.item.mortgageProductFixationEnd.message', {
      context: notification.stage,
    }),
    [NotificationType.MotorLiabilityInsuranceProductExpiration]: t(
      'homepage.notifications.item.motorLiabilityInsuranceProductExpiration.message',
    ),
    [NotificationType.MotorLiabilityInsuranceProductPayment]:
      dueDate &&
      t('homepage.notifications.item.motorLiabilityInsuranceProductPayment.message', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
    [NotificationType.NordigenConsentExpiration]:
      dueDate &&
      t('homepage.notifications.item.nordigenConsentExpiration.message', {
        accountNames,
        context: dueDate < new Date() ? 'past' : 'future',
        date: format(dueDate, 'd. M. yyyy HH:mm'),
      }),
    [NotificationType.ProductWaitingForSubmission]: t(
      'homepage.notifications.item.productWaitingForSubmission.message',
    ),
    [NotificationType.PropertyInsuranceProductExpiration]: t(
      'homepage.notifications.item.propertyInsuranceProductExpiration.message',
    ),
    [NotificationType.PropertyInsuranceProductPayment]:
      dueDate &&
      t('homepage.notifications.item.propertyInsuranceProductPayment.message', {
        date: format(dueDate, 'd. M. yyyy'),
      }),
  }[notification.type];

  const action = {
    [NotificationType.ActiveProductWithoutDocument]: t(
      'homepage.notifications.item.activeProductWithoutDocument.action',
    ),
    [NotificationType.CalendarEvent]: undefined,
    [NotificationType.CollisionInsuranceProductExpiration]: undefined,
    [NotificationType.CollisionInsuranceProductPayment]: undefined,
    [NotificationType.GenericProductExpiration]: t('homepage.notifications.item.genericProductExpiration.action'),
    [NotificationType.LifeInsuranceAnnualUpdate]: t('homepage.notifications.item.lifeInsuranceAnnualUpdate.action'),
    [NotificationType.MortgageProductFixationEnd]: t('homepage.notifications.item.mortgageProductFixationEnd.action'),
    [NotificationType.MotorLiabilityInsuranceProductExpiration]: t(
      'homepage.notifications.item.motorLiabilityInsuranceProductExpiration.action',
    ),
    [NotificationType.MotorLiabilityInsuranceProductPayment]: undefined,
    [NotificationType.NordigenConsentExpiration]: t('homepage.notifications.item.nordigenConsentExpiration.action'),
    [NotificationType.ProductWaitingForSubmission]: t('homepage.notifications.item.productWaitingForSubmission.action'),
    [NotificationType.PropertyInsuranceProductExpiration]: t(
      'homepage.notifications.item.propertyInsuranceProductExpiration.action',
    ),
    [NotificationType.PropertyInsuranceProductPayment]: undefined,
  }[notification.type];

  const [markNotificationAsRead] = useMarkNotificationAsReadMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['NotificationsCount'],
    variables: {
      notificationId: notification.id,
    },
  });

  // various dialogs for all possible actions in handleAction
  const openGenericContactDialog = useContactDialog({});
  const openLifeInsuranceContactDialog = useContactDialog({
    avatarSrc: InsuranceAvatar,
    context: RequestContactContext.LifeInsuranceAudit,
    jobTitle: t('flows/lifeInsurance/common:contact.jobTitle'),
    name: t('flows/lifeInsurance/common:contact.name'),
  });

  const [handleInitNordigenFlow] = useInitNordigenFlow();

  const handleNordigenConsentRenewal = async () => {
    const institutionId = nordigenConsentExpirationDetails.nordigenConnection.institution.id;
    if (institutionId) {
      await handleInitNordigenFlow(institutionId);
    }
  };

  const productQuery = useProductQuery(productType, {
    id: productId,
  });

  const productDetailDialog = useProductDetail(productType, {
    instanceId: `${productType}-detail-${productId}`,
    navigable: true,
  });

  const openProductDetailDialog = async () => {
    await productDetailDialog.create({ id: productId });
  };

  const uploadProductDocumentDialog = useDialog<UploadDocumentDialogProps, void>(UploadDocumentDialog, {
    instanceId: `${productType}-upload-document-dialog-${productId}`,
    navigable: true,
  });

  const openUploadProductDocumentDialog = async () => {
    await uploadProductDocumentDialog.create({
      documentId: productQuery.data?.detail.documents[0].id ?? '',
      productId: productId,
      type: productType,
    });
  };

  const handleAction = async () => {
    setLoadingAction(true);
    await markNotificationAsRead();

    notification.type == NotificationType.ActiveProductWithoutDocument && openProductDetailDialog();
    notification.type == NotificationType.GenericProductExpiration && openGenericContactDialog();
    notification.type == NotificationType.LifeInsuranceAnnualUpdate && openLifeInsuranceContactDialog();
    notification.type == NotificationType.MortgageProductFixationEnd && navigate(MORTGAGE_REFINANCE_BASE_URL);
    notification.type == NotificationType.MotorLiabilityInsuranceProductExpiration &&
      navigate(MOTOR_LIABILITY_INSURANCE_BASE_URL);
    notification.type == NotificationType.NordigenConsentExpiration && handleNordigenConsentRenewal();
    notification.type == NotificationType.ProductWaitingForSubmission && openUploadProductDocumentDialog();
    notification.type == NotificationType.PropertyInsuranceProductExpiration && navigate(PROPERTY_INSURANCE_BASE_URL);

    setLoadingAction(false);
  };

  const [deactivateNotification] = useDeactivateNotificationMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['NotificationsCount'],
    update: (cache, resp) => {
      if (resp.data?.deactivateNotification) {
        cache.evict({
          id: cache.identify({ __typename: 'Notification', id: resp.data.deactivateNotification }),
        });
      }
    },
    variables: {
      notificationId: notification.id,
    },
  });

  const handleDeactivateNotification = async () => {
    await deactivateNotification();
  };

  return (
    <Box sx={styles.container}>
      <IconButton
        aria-label={t('homepage.notifications.deleteButton')}
        color="secondaryButton"
        onClick={handleDeactivateNotification}
        size="small"
        sx={styles.closeButton}
      >
        <CloseIcon />
      </IconButton>

      {!notification.readDate && (
        <Box sx={styles.unreadIcon}>
          <UnreadIcon />
        </Box>
      )}

      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          <Typography color="textSecondary" variant="bodyStrongS">
            {NotificationTypeTranslations(t)[notification.type]}
          </Typography>
        </Stack>
        <Typography variant="bodyStrongXXL">{title}</Typography>
        <Typography color="textSecondary" variant="bodyM">
          {message}
        </Typography>

        <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
          <LoadingButton color="tertiaryButton" loading={loadingAction} onClick={handleAction} sx={styles.actionButton}>
            <Stack alignItems="center" direction="row" spacing={1}>
              {action && <RefreshIcon />}
              {(action || !notification.readDate) && (
                <Typography color="blue.dark">{action ?? t('homepage.notifications.markAsReadButton')}</Typography>
              )}
            </Stack>
          </LoadingButton>

          <Typography color="textSecondary" variant="bodyS">
            {isToday(creationDate) && t('homepage.notifications.dates.today')}
            {isYesterday(creationDate) && t('homepage.notifications.dates.yesterday')}
            {!isToday(creationDate) &&
              !isYesterday(creationDate) &&
              t('homepage.notifications.dates.past', {
                value: differenceInDays(startOfDay(new Date()), startOfDay(creationDate)),
              })}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
