import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { formatIban, formatTransactionAmount, withDefaultCurrency } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import { FinancialProductType, ProductStatus } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { ButtonRow } from '../ButtonRow/ButtonRow';
import {
  DeleteProductWaitingForReviewDialog,
  DeleteProductWaitingForReviewDialogProps,
} from '../DeleteProductWaitingForReviewDialog/DeleteProductWaitingForReviewDialog';
import {
  SelectProductTypeDialog,
  SelectProductTypeDialogProps,
} from '../SelectProductTypeDialog/SelectProductTypeDialog';
import { ProductBaseExtended } from '../types';
import { useProductDetail } from '../useProductDetail';
import { useUpdateProduct } from '../useUpdateProduct';
import { styles } from './ProductWaitingForReviewDialog.styles';

export interface ProductWaitingForReviewDialogProps extends DialogComponentProps<void> {
  product: ProductBaseExtended;
}

export const ProductWaitingForReviewDialog: React.FC<ProductWaitingForReviewDialogProps> = ({
  instanceId,
  isOpen,
  onResolve,
  product,
}) => {
  const { i18n, t } = useTranslation();

  const selectProductType = useDialog<SelectProductTypeDialogProps, void>(SelectProductTypeDialog, {
    instanceId: 'select-product-type-dialog',
    navigable: true,
  });

  const handleUpdate = async () => {
    await selectProductType.create({
      product,
    });
    onResolve();
  };

  const productDetail = useProductDetail(product.type, {
    id: product.id,
    instanceId: `${product.type}-detail-${product.id}`,
    navigable: true,
  });

  const [updateProduct, { loading: loadingUpdateProduct }] = useUpdateProduct(product.type);

  const handleConfirm = async () => {
    await updateProduct({
      variables: {
        id: product.id,
        updatedProduct: {
          enableFinancialAdvisorCare: product.enableFinancialAdvisorCare,
          enableNotifications: product.enableNotifications,
          institutionId: product.institution?.id,
          institutionName: product.institutionName,
          paymentInfo: {
            constantSymbol: product.paymentInfo?.constantSymbol,
            ibanForPayment: product.paymentInfo?.ibanForPayment,
            specificSymbol: product.paymentInfo?.specificSymbol,
            variableSymbol: product.paymentInfo?.variableSymbol,
          },
          paymentPeriod: {
            expectedAmount: withDefaultCurrency(product.paymentPeriod?.expectedAmount),
            periodCount: product.paymentPeriod?.periodCount,
            periodTypeCode: product.paymentPeriod?.periodTypeCode,
            referenceDate: product.paymentPeriod?.expectedDate,
          },
          status: ProductStatus.Active,
          statusChangeDate: new Date(),
        },
      },
    });
    await onResolve();
    await productDetail.create({ id: product.id });
  };

  const deleteProductDialog = useDialog<DeleteProductWaitingForReviewDialogProps, boolean>(
    DeleteProductWaitingForReviewDialog,
    {
      instanceId: 'delete-product-waiting-for-review',
    },
  );

  const handleDelete = async () => {
    const deleted = await deleteProductDialog.create({ id: product.id, type: product.type });
    if (deleted) {
      await onResolve();
    }
  };

  const title = {
    [FinancialProductType.BuildingSavings]: t('products:seeAllProducts.item.product.buildingSavings'),
    [FinancialProductType.CollisionInsurance]: t('products:seeAllProducts.item.product.collisionInsurance'),
    [FinancialProductType.CreditCard]: t('products:seeAllProducts.item.product.creditCard'),
    [FinancialProductType.Generic]: t('products:seeAllProducts.item.product.generic'),
    [FinancialProductType.GenericInsurance]: t('products:seeAllProducts.item.product.genericInsurance'),
    [FinancialProductType.Investment]: t('products:seeAllProducts.item.product.investment'),
    [FinancialProductType.LifeInsurance]: t('products:seeAllProducts.item.product.lifeInsurance'),
    [FinancialProductType.Loan]: t('products:seeAllProducts.item.product.loan'),
    [FinancialProductType.Mortgage]: t('products:seeAllProducts.item.product.mortgage'),
    [FinancialProductType.MotorLiabilityInsurance]: t('products:seeAllProducts.item.product.motorLiabilityInsurance'),
    [FinancialProductType.PropertyInsurance]: t('products:seeAllProducts.item.product.propertyInsurance'),
    [FinancialProductType.RetirementPensionSavings]: t('products:seeAllProducts.item.product.retirementPensionSavings'),
    [FinancialProductType.Savings]: t('products:seeAllProducts.item.product.savings'),
    [FinancialProductType.SupplementaryPensionSavings]: t(
      'products:seeAllProducts.item.product.supplementaryPensionSavings',
    ),
  }[product.type];

  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={onResolve}>
      <Stack alignItems="center">
        <Stack mb={6}>
          <Typography variant="headlineXL">{t('products:productWaitingForReviewDialog.title')}</Typography>
          <Typography variant="bodyM">{t('products:productWaitingForReviewDialog.subtitle')}</Typography>
        </Stack>

        <Stack sx={styles.container}>
          <Stack alignItems="top" direction="row" justifyContent="space-between" sx={styles.detail}>
            <Stack direction="row" spacing={2}>
              <InstitutionIcon
                alt={product.institution?.name ?? product.institutionName ?? '?'}
                src={product.institution?.logoUrl ?? undefined}
                title={product.institution?.name ?? product.institutionName ?? '?'}
                width={48}
              />

              <Stack>
                <Typography variant="bodyStrongL">{title}</Typography>
                <Typography color="textSecondary" variant="bodyM">
                  {product.institution?.name ?? product.institutionName ?? '?'}
                </Typography>
              </Stack>
            </Stack>

            <Stack alignItems="flex-end">
              <Stack alignItems="center" direction="row">
                <Button
                  aria-label={t('products:productWaitingForReviewDialog.updateButton')}
                  color="tertiaryButton"
                  onClick={handleUpdate}
                >
                  <Typography color="blue.dark" sx={{ letterSpacing: '-0.43px' }} variant="bodyL">
                    {t('products:productWaitingForReviewDialog.updateButton')}
                  </Typography>
                </Button>

                <ChevronRightIcon />
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" flexGrow={1} justifyContent="space-between" sx={styles.row}>
            <Typography variant="bodyL">{t('products:productWaitingForReviewDialog.date')}</Typography>
            <Typography color={'textSecondary'} variant="bodyL">
              {format(product.paymentPeriod?.expectedDate ?? new Date(), 'd. M. yyyy')}
            </Typography>
          </Stack>

          <Stack direction="row" flexGrow={1} justifyContent="space-between" sx={styles.row}>
            <Typography variant="bodyL">{t('products:productWaitingForReviewDialog.amount')}</Typography>
            <Typography color={'textSecondary'} variant="bodyL">
              {formatTransactionAmount(
                product.paymentPeriod?.expectedAmount?.amount ?? 0,
                i18n.language,
                2,
                product.paymentPeriod?.expectedAmount?.currency ?? 'EUR',
              )}
            </Typography>
          </Stack>

          <Stack direction="row" flexGrow={1} justifyContent="space-between" sx={styles.row}>
            <Typography variant="bodyL">{t('products:productWaitingForReviewDialog.variableSymbol')}</Typography>
            <Typography color={'textSecondary'} variant="bodyL">
              {product.paymentInfo?.variableSymbol ?? '-'}
            </Typography>
          </Stack>

          <Stack direction="row" flexGrow={1} justifyContent="space-between" sx={styles.row}>
            <Typography variant="bodyL">{t('products:productWaitingForReviewDialog.iban')}</Typography>
            <Typography color={'textSecondary'} variant="bodyL">
              {formatIban(product.paymentInfo?.ibanForPayment ?? '') ?? '-'}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={styles.container}>
          <ButtonRow
            color="red.dark"
            label={t('products:productWaitingForReviewDialog.deleteButton')}
            onClick={handleDelete}
          />
        </Box>

        <Box sx={styles.container}>
          <LoadingButton color="primary" fullWidth loading={loadingUpdateProduct} onClick={handleConfirm} size="large">
            <Typography color="white" variant="bodyL">
              {t('products:productWaitingForReviewDialog.confirmButton')}
            </Typography>
          </LoadingButton>
        </Box>
      </Stack>
    </ResponsiveSideDialog>
  );
};
