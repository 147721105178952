import { Box, Stack, Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { BalanceValueFragment } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { useChartOptions } from './AvailableFundsLineChart.chart';

export interface AvailableFundsLineChartProps {
  asOfDate: Date;
  aggregatedData: BalanceValueFragment[];
}

export const AvailableFundsLineChart: React.FC<AvailableFundsLineChartProps> = ({ aggregatedData, asOfDate }) => {
  const { i18n, t } = useTranslation();

  const { options, totalBalance } = useChartOptions(aggregatedData, asOfDate);

  return (
    <Stack>
      <Typography color="textSecondary" variant="bodyM">
        {t('homepage.dashboard.availableFunds.text', {
          date: asOfDate.toLocaleDateString(i18n.language),
        })}
      </Typography>
      <Typography variant="headlineL">{totalBalance}</Typography>
      <Box height={300}>
        <ReactEcharts option={options} style={{ height: '100%', width: '100%' }} />
      </Box>
    </Stack>
  );
};
