import { SxProps, Theme } from '@mui/material';

export const styles: Record<'title' | 'close' | 'item' | 'content' | 'delete' | 'edit', SxProps<Theme>> = {
  close: {
    position: { sm: 'absolute' },
    right: (theme) => ({
      sm: theme.spacing(1),
      xxs: theme.spacing(2),
    }),
  },
  content: {
    background: (theme) => theme.palette.gray['50'],
    borderRadius: '8px',
    overflow: 'hidden',
  },
  delete: {
    '&:hover': {
      color: (theme) => theme.palette.red.dark,
    },
    color: (theme) => theme.palette.red.dark,
    marginRight: (theme) => theme.spacing(1),
  },
  edit: {
    '&:hover': {
      color: (theme) => theme.palette.blue.dark,
    },
    color: (theme) => theme.palette.blue.dark,
  },
  item: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray['200']}`,
    },
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
    },
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    minWidth: '296px',
    padding: 2,
    width: '100%',
  },
  title: {
    marginX: 'auto',
    padding: (theme) => theme.spacing(1),
  },
};
