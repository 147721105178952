import { Stack, Typography } from '@mui/material';
import SpendingReportImage from 'assets/images/accounts/spending-report.png';
import { Image } from 'common/components/Image/Image';
import { useTranslation } from 'react-i18next';

export const SpendingReportInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack alignItems="center" spacing={1}>
        <Typography variant="headlineXL">{t('homepage.accountsProductTour.spendingReportInfo.title')}</Typography>
        <Typography color="textSecondary" variant="bodyL">
          {t('homepage.accountsProductTour.spendingReportInfo.subtitle')}
        </Typography>
      </Stack>

      <Image height="auto" maxWidth="100%" src={SpendingReportImage} />
    </Stack>
  );
};
