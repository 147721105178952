import InsuranceAvatar from 'assets/images/insurance-avatar.png';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { usePeriodicityOptions } from 'common/hooks/usePeriodicityOptions';
import { formatCurrency, formatIban, formatTransactionAmount, normalizeIban } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import {
  FinancialProductType,
  PropertyInsuranceInsuranceType,
  PropertyInsuranceInsuredPropertyType,
  PropertyInsuranceProductFragment,
  usePropertyInsuranceProductQuery,
  useUpdatePropertyInsuranceProductMutation,
} from 'generated/graphql';
import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductDetail } from '../ProductDetail/ProductDetail';
import { QRCodeDialog, QRCodeDialogProps } from '../QRCodeDialog/QRCodeDialog';
import { QRCodeMissingDataDialog, QRCodeMissingDataDialogProps } from '../QRCodeDialog/QRCodeMissingDataDialog';
import { ButtonRowType, DetailRowType, List, Section } from '../types';
import { ProductDetailDialogProps } from '../useProductDetail';
import { mapPropertyInsuranceProductToInput } from './utils';

export const PropertyInsuranceProductDetail: React.FC<ProductDetailDialogProps> = ({ id, ...props }) => {
  const { i18n, t } = useTranslation();
  const notify = useNotification();

  const { data } = usePropertyInsuranceProductQuery({
    variables: {
      id,
    },
  });

  const qrCodeDialog = useDialog<QRCodeDialogProps, void>(QRCodeDialog, {
    instanceId: 'qr-code-dialog',
    tmpNavigable: true,
  });

  const qrCodeMissingDataDialog = useDialog<QRCodeMissingDataDialogProps, void>(QRCodeMissingDataDialog, {
    instanceId: 'qr-code-missing-data-dialog',
  });

  const handleQRPayment = () => {
    const iban = data?.detail.paymentInfo?.ibanForPayment;
    const amount = data?.detail.paymentPeriod?.expectedAmount?.amount;
    if (iban && amount) {
      qrCodeDialog.create({
        amount,
        constantSymbol: data?.detail.paymentInfo?.constantSymbol ?? undefined,
        currency: data?.detail.paymentPeriod?.expectedAmount?.currency ?? 'EUR',
        iban,
        variableSymbol: data?.detail.paymentInfo?.variableSymbol ?? undefined,
      });
    } else {
      qrCodeMissingDataDialog.create({});
    }
  };

  const periodicityOptions = usePeriodicityOptions();
  const insuredPropertyTypeOptions = [
    {
      label: t('products:propertyInsurance.detail.insuredProperty.type.house.label'),
      value: PropertyInsuranceInsuredPropertyType.House,
    },
    {
      label: t('products:propertyInsurance.detail.insuredProperty.type.apartment.label'),
      value: PropertyInsuranceInsuredPropertyType.Apartment,
    },
    {
      label: t('products:propertyInsurance.detail.insuredProperty.type.recreationalFacility.label'),
      value: PropertyInsuranceInsuredPropertyType.RecreationalFacility,
    },
  ];
  const basicInsurancesTypeOptions = [
    {
      label: t('products:propertyInsurance.detail.basicInsurances.type.property.label'),
      value: PropertyInsuranceInsuranceType.Property,
    },
    {
      label: t('products:propertyInsurance.detail.basicInsurances.type.household.label'),
      value: PropertyInsuranceInsuranceType.Household,
    },
  ];

  const content: (
    | Section<PropertyInsuranceProductFragment, Path<PropertyInsuranceProductFragment>>
    | List<PropertyInsuranceProductFragment, 'basicInsurances'>
    | List<PropertyInsuranceProductFragment, 'otherInsurances'>
  )[] = [
    {
      rows: [
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.institutionName'),
          path: 'institutionName',
          skip: !!data?.detail.institution?.id,
        } as DetailRowType<PropertyInsuranceProductFragment, 'institutionName'>,
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.productName'),
          path: 'name',
        } as DetailRowType<PropertyInsuranceProductFragment, 'name'>,
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.contractNumber'),
          path: 'contractNumber',
        } as DetailRowType<PropertyInsuranceProductFragment, 'contractNumber'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          formatter: (value: number) =>
            formatTransactionAmount(value, i18n.language, 2, data?.detail.paymentPeriod?.expectedAmount?.currency),
          inputEndAdornment: data?.detail.paymentPeriod?.expectedAmount?.currency,
          inputType: 'amount',
          label: t('products:propertyInsurance.detail.instalment'),
          // we are storing paymentPeriod.expectedAmount.amount as negative number
          // but we want user to type a positive equivalent
          parse: (value) => -value,
          path: 'paymentPeriod.expectedAmount.amount',
          transform: (value) => -value,
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentPeriod.expectedAmount.amount'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:propertyInsurance.detail.nextPayment'),
          path: 'paymentPeriod.expectedDate',
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentPeriod.expectedDate'>,
        {
          formatter: (value) =>
            periodicityOptions.find(
              (option) =>
                option.value.periodCount === value.periodCount && option.value.periodTypeCode === value.periodTypeCode,
            )?.label ?? '',
          inputType: 'radioSelect',
          label: t('products:propertyInsurance.detail.periodType'),
          options: periodicityOptions,
          parse: (value) => ({
            expectedAmount: data?.detail.paymentPeriod?.expectedAmount,
            expectedDate: data?.detail.paymentPeriod?.expectedDate,
            periodCount: value.periodCount,
            periodTypeCode: value.periodTypeCode,
          }),
          path: 'paymentPeriod',
          transform: (value) => ({
            periodCount: value.periodCount,
            periodTypeCode: value.periodTypeCode,
          }),
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentPeriod'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:propertyInsurance.detail.contractEndDate'),
          path: 'contractEndDate',
        } as DetailRowType<PropertyInsuranceProductFragment, 'contractEndDate'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          inputType: 'variableSymbol',
          label: t('products:propertyInsurance.detail.variableSymbol'),
          path: 'paymentInfo.variableSymbol',
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentInfo.variableSymbol'>,
        {
          inputType: 'specificSymbol',
          label: t('products:propertyInsurance.detail.specificSymbol'),
          path: 'paymentInfo.specificSymbol',
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentInfo.specificSymbol'>,
        {
          inputType: 'constantSymbol',
          label: t('products:propertyInsurance.detail.constantSymbol'),
          path: 'paymentInfo.constantSymbol',
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentInfo.constantSymbol'>,
        {
          formatter: formatIban,
          inputType: 'iban',
          label: t('products:propertyInsurance.detail.ibanForPayment'),
          parse: normalizeIban,
          path: 'paymentInfo.ibanForPayment',
        } as DetailRowType<PropertyInsuranceProductFragment, 'paymentInfo.ibanForPayment'>,
        {
          color: 'blue.dark',
          label: t('products:propertyInsurance.detail.qrCode'),
          onClick: handleQRPayment,
        } as ButtonRowType,
      ],
      title: t('products:propertyInsurance.detail.paymentInfo.title'),
    },
    {
      rows: [
        {
          formatter: (value) => insuredPropertyTypeOptions.find((option) => option.value === value)?.label ?? '',
          inputType: 'radioSelect',
          label: t('products:propertyInsurance.detail.insuredProperty.type.title'),
          options: insuredPropertyTypeOptions,
          path: 'insuredProperty.type',
        } as DetailRowType<PropertyInsuranceProductFragment, 'insuredProperty.type'>,
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.insuredProperty.address'),
          path: 'insuredProperty.address',
        } as DetailRowType<PropertyInsuranceProductFragment, 'insuredProperty.address'>,
      ],
      title: t('products:propertyInsurance.detail.insuredProperty.title'),
    },
    {
      addLabel: t('products:propertyInsurance.detail.basicInsurances.addLabel'),
      fields: [
        {
          formatter: (value) => basicInsurancesTypeOptions.find((option) => option.value === value)?.label ?? '',
          inputType: 'radioSelect',
          label: t('products:propertyInsurance.detail.basicInsurances.type.label'),
          options: basicInsurancesTypeOptions,
          path: 'type',
        } as DetailRowType<PropertyInsuranceProductFragment['basicInsurances'][0], 'type'>,
        {
          formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
          inputEndAdornment: t('common:inputEndAdornment.eur'),
          inputType: 'amount',
          label: t('products:propertyInsurance.detail.basicInsurances.insuredSum'),
          path: 'insuredSum.amount',
        } as DetailRowType<PropertyInsuranceProductFragment['basicInsurances'][0], 'insuredSum.amount'>,
        {
          formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
          inputEndAdornment: t('common:inputEndAdornment.eur'),
          inputType: 'amount',
          label: t('products:propertyInsurance.detail.basicInsurances.annualCost'),
          path: 'annualCost.amount',
        } as DetailRowType<PropertyInsuranceProductFragment['basicInsurances'][0], 'annualCost.amount'>,
      ],
      formatter: (value) => basicInsurancesTypeOptions.find((option) => option.value === value.type)?.label ?? '-',
      label: t('products:propertyInsurance.detail.basicInsurances.label'),
      path: 'basicInsurances',
      removeLabel: t('products:propertyInsurance.detail.basicInsurances.removeLabel'),
      title: t('products:propertyInsurance.detail.basicInsurances.title'),
    },
    {
      addLabel: t('products:propertyInsurance.detail.otherInsurances.addLabel'),
      fields: [
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.otherInsurances.name'),
          path: 'name',
        } as DetailRowType<PropertyInsuranceProductFragment['otherInsurances'][0], 'name'>,
        {
          formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
          inputEndAdornment: t('common:inputEndAdornment.eur'),
          inputType: 'amount',
          label: t('products:propertyInsurance.detail.otherInsurances.insuredSum'),
          path: 'insuredSum.amount',
        } as DetailRowType<PropertyInsuranceProductFragment['otherInsurances'][0], 'insuredSum.amount'>,
        {
          formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
          inputEndAdornment: t('common:inputEndAdornment.eur'),
          inputType: 'amount',
          label: t('products:propertyInsurance.detail.otherInsurances.annualCost'),
          path: 'annualCost.amount',
        } as DetailRowType<PropertyInsuranceProductFragment['otherInsurances'][0], 'annualCost.amount'>,
      ],
      formatter: (value) => value.name ?? '-',
      label: t('products:propertyInsurance.detail.otherInsurances.label'),
      path: 'otherInsurances',
      removeLabel: t('products:propertyInsurance.detail.otherInsurances.removeLabel'),
      title: t('products:propertyInsurance.detail.otherInsurances.title'),
    },
    {
      rows: [
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.insuringPerson.name'),
          path: 'insuringPerson.name',
        } as DetailRowType<PropertyInsuranceProductFragment, 'insuringPerson.name'>,
        {
          inputType: 'text',
          label: t('products:propertyInsurance.detail.insuringPerson.surname'),
          path: 'insuringPerson.surname',
        } as DetailRowType<PropertyInsuranceProductFragment, 'insuringPerson.surname'>,
      ],
      title: t('products:propertyInsurance.detail.insuringPerson.title'),
    },
  ];

  const [updateProduct] = useUpdatePropertyInsuranceProductMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const handleUpdate = async (value: PropertyInsuranceProductFragment) => {
    await updateProduct({ variables: { id, updatedProduct: mapPropertyInsuranceProductToInput(value) } });
  };

  return (
    <ProductDetail<PropertyInsuranceProductFragment>
      {...props}
      contactExpert={{
        avatarSrc: InsuranceAvatar,
      }}
      content={content}
      data={data?.detail}
      institution={data?.detail.institution ?? undefined}
      onUpdate={handleUpdate}
      title={t('products:propertyInsurance.detail.title')}
      type={FinancialProductType.PropertyInsurance}
    />
  );
};
