import { SxProps, Theme } from '@mui/material';

export const styles: Record<'actionButton' | 'closeButton' | 'container' | 'unreadIcon', SxProps<Theme>> = {
  actionButton: {
    padding: (theme) => theme.spacing(1, 0.25),
  },
  closeButton: {
    '&:hover': {
      background: (theme) => theme.palette.gray[300],
      color: (theme) => theme.palette.gray[600],
    },
    borderRadius: '50%',
    boxShadow: 'none',
    color: (theme) => theme.palette.gray[600],
    height: 26,
    position: 'absolute',
    right: (theme) => theme.spacing(1),
    top: (theme) => theme.spacing(1),
    width: 26,
  },
  container: {
    background: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    padding: (theme) => theme.spacing(2, 3, 2, 4),
    position: 'relative',
    width: '100%',
  },
  unreadIcon: {
    left: (theme) => theme.spacing(1.5),
    position: 'absolute',
    top: (theme) => theme.spacing(5.75),
  },
};
