import { Box, Grid, Stack } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { CashflowGuardianWidget } from 'components/cashflowGuardian/cashflowGuardianWidget/CashflowGuardianWidget';
import { AvailableFunds } from 'components/HomePage/Dashboard/AvailableFunds';
import { Profile } from 'components/HomePage/Dashboard/Profile';
import { SpendingReportDialog, SpendingReportDialogProps } from 'components/spendingReport/SpendingReportDialog';
import { SpendingReportWidget } from 'components/spendingReport/SpendingReportWidget/SpendingReportWidget';

import { styles } from './Dashboard.styles';

export const Dashboard: React.FC = () => {
  const spendingReportDialog = useDialog<SpendingReportDialogProps, void>(SpendingReportDialog, {
    instanceId: 'spending-report',
    navigable: true,
  });

  const handleShowSpendingReportDialog = () => {
    spendingReportDialog.create({});
  };

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item sm={6} xs={12}>
        <Stack height="100%" spacing={4}>
          <Profile />
          <AvailableFunds />
        </Stack>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Stack height="100%" spacing={4}>
          <CashflowGuardianWidget />
          <Box onClick={handleShowSpendingReportDialog} sx={styles.box}>
            <SpendingReportWidget />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
