import { Box, Stack, Typography } from '@mui/material';
import { TransactionAttribute, useAggregateTransactionsQuery } from 'generated/graphql';
import _ from 'lodash';
import { useEffect } from 'react';

import { AggregateListItem } from '../AggregateListItem/AggregateListItem';
import { PeriodTypeEnum } from '../state/spendingReportTypes';
import { useSpendingReportState } from '../state/useSpendingReportState';
import { getFilter } from '../utils';
import { styles } from './AggregateList.styles';

export interface AggregateListProps {
  lockedPeriodType?: PeriodTypeEnum;
  total?: number;
  type: TransactionAttribute;
  accountId?: string;
  categoryId?: string;
  tagId?: string;
  pointerDate: Date;
  title: string;
}

export const AggregateList: React.FC<AggregateListProps> = (props) => {
  const { accountId, categoryId, pointerDate, tagId, title, total, type } = props;

  const { state } = useSpendingReportState();
  const periodType = props.lockedPeriodType ?? state.params.periodType;

  const filterConditions = getFilter(pointerDate, periodType, categoryId, tagId, accountId);

  const filterInput = {
    arguments: filterConditions.map((condition) => ({ filterCondition: condition })),
  };

  const { called, data, refetch } = useAggregateTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput,
      groupBy: [type],
    },
  });

  useEffect(() => {
    if (called) {
      refetch({ filterInput });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, pointerDate, periodType, categoryId, tagId, accountId]);

  const sortedAggregates = _.sortBy(data?.aggregateTransactions.resultData, 'sum.amount');

  return sortedAggregates.length ? (
    <Stack mt={8}>
      <Typography component="h2" mb={3} variant="headlineM">
        {title}
      </Typography>
      <Stack sx={styles.container}>
        {sortedAggregates.map((aggregate) => (
          <Box key={JSON.stringify(aggregate.key[0])} sx={styles.row}>
            <AggregateListItem
              accountId={accountId}
              aggregate={aggregate}
              categoryId={categoryId}
              lockedPeriodType={props.lockedPeriodType}
              pointerDate={pointerDate}
              tagId={tagId}
              total={total}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  ) : null;
};
