import { Button, DialogActions } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmDialogProps extends DialogComponentProps<boolean> {
  title: ReactElement | string;
  content?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  cancelButtonText,
  confirmButtonText,
  content,
  isOpen,
  onResolve,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <StyledDialog maxWidth="xs" open={isOpen}>
      <StyledDialogTitle textAlign="left">{title}</StyledDialogTitle>
      <StyledDialogSubtitle textAlign="left">{content}</StyledDialogSubtitle>
      <DialogActions>
        <Button color="secondaryButton" onClick={() => onResolve(false)}>
          {cancelButtonText ?? t('common:confirmDialog.cancel')}
        </Button>
        <Button onClick={() => onResolve(true)}>{confirmButtonText ?? t('common:confirmDialog.confirm')}</Button>
      </DialogActions>
    </StyledDialog>
  );
};
