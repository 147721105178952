import { Box, Stack } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { useState } from 'react';
import { Path } from 'react-hook-form';

import { ButtonRow } from '../ButtonRow/ButtonRow';
import { SetValueDialog, SetValueDialogProps } from '../SetValueDialog/SetValueDialog';
import { DetailRowType } from '../types';
import { styles } from './ExpandedList.styles';
import { ExpandedListItem } from './ExpandedListItem';

interface Props<T> {
  data: T[];
  path: string;
  labelPath: string;
  addLabel: string;
  removeLabel: string;
  maxLength?: number;
  fields: Array<
    { [K in Path<PathValue<T, `${Path<T>}.0`>>]: DetailRowType<PathValue<T, `${Path<T>}.0`>, K> }[Path<
      PathValue<T, `${Path<T>}.0`>
    >]
  >;
  onUpdate: <K extends Path<T>>(value: PathValue<T, K> | T[], path: string) => Promise<void>;
  updatedAt?: Date;
}

export const ExpandedList = <T,>({
  addLabel,
  data,
  fields,
  labelPath,
  maxLength,
  onUpdate,
  path,
  removeLabel,
  updatedAt,
}: Props<T>): React.ReactElement => {
  const [loadingAdd, setLoadingAdd] = useState(false);

  const setValueDialog = useDialog<
    SetValueDialogProps<{ [K in Path<T>]: PathValue<T, K> }[Path<T>]>,
    { [K in Path<T>]: PathValue<T, K> }[Path<T>]
  >(SetValueDialog, {
    instanceId: `set-value-${path}`,
  });

  const handleAdd = async () => {
    const field = fields[0];
    const value = await setValueDialog.create({
      defaultValue: undefined,
      inputEndAdornment: field.inputEndAdornment,
      inputType: field.inputType,
      options: field.options,
      title: addLabel,
    });
    if (value) {
      setLoadingAdd(true);
      await onUpdate(value, `${path}[${data.length}]${field.path}`);
      setLoadingAdd(false);
    }
  };

  const handleRemove = (index: number) => () => {
    const newData = [...data];
    newData.splice(index, 1);
    onUpdate(newData, path);
  };

  return (
    <Stack>
      {data.map((value, index) => (
        <ExpandedListItem<T>
          key={index}
          data={value}
          fields={fields}
          labelPath={labelPath}
          onRemove={handleRemove(index)}
          onUpdate={onUpdate}
          path={`${path}[${index}]`}
          removeLabel={removeLabel}
          updatedAt={updatedAt}
        />
      ))}

      {(!maxLength || data.length < maxLength) && (
        <Box sx={styles.buttonRow}>
          <ButtonRow label={addLabel} loading={loadingAdd} onClick={handleAdd} />
        </Box>
      )}
    </Stack>
  );
};
