import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { transformNaNtoNull } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { t } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  amount?: number;
}

export interface SetAmountInputDialogProps extends DialogComponentProps<number> {
  amount?: number;
}

export const SetAmountInputDialogContent: React.FC<SetAmountInputDialogProps> = ({ amount, onResolve }) => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    amount: yup.number().transform(transformNaNtoNull).nullable(true).required(t('form.validator.fieldIsRequired')),
  });

  const convertToPositiveNumber = (amount?: number) => {
    return amount ? Math.abs(amount) : undefined;
  };

  const formMethods = useForm<FormValues>({
    defaultValues: {
      amount: convertToPositiveNumber(amount),
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve(data.amount);
  });

  return (
    <Box>
      <FormProvider {...formMethods}>
        <Box component="form" mt={2} noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput fullWidth name="amount" type="number" />
          </Box>
          <Box mt={8} width="100%">
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('upcomingPaymentDetail.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export const SetAmountInputDialog: React.FC<SetAmountInputDialogProps> = (props) => (
  <PromptDialogOnSide
    onClose={() => props.onResolve()}
    open={props.isOpen}
    subtitle={t('upcomingPaymentDetail.amountDescription')}
    title={t('upcomingPaymentDetail.amount')}
  >
    <SetAmountInputDialogContent {...props} />
  </PromptDialogOnSide>
);
