import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CheckmarkSuccessIcon } from 'assets/icons/Checkmark.svg';
import { useTranslation } from 'react-i18next';

import { styles } from './ExpectationsBanner.styles';

interface Props {
  list: {
    title: string;
    subtitle: string;
  }[];
}

export const ExpectationsBanner: React.FC<Props> = ({ list }) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Stack spacing={3}>
        <Typography textAlign="left" variant="headlineL">
          {t('flows/common:expectationsBanner.title')}
        </Typography>
        {list.map((item) => (
          <Stack key={item.title} alignItems="center" direction="row" spacing={4}>
            <Box sx={styles.circle}>
              <CheckmarkSuccessIcon />
            </Box>
            <Stack>
              <Typography variant="bodyStrongM">{item.title}</Typography>
              <Typography color="textSecondary" variant="bodyM">
                {item.subtitle}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
