import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './CalculatorCards.styles';
import { LifeInsuranceCalculatorCard } from './LifeInsuranceCalculatorCard';
import { MortgageCalculatorCard } from './MortgageCalculatorCard';

export const CalculatorCards: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <Typography component="h3" variant="headlineM">
        {t('homepage.products.calculatorCards.title')}
      </Typography>

      <Stack sx={styles.cards}>
        <MortgageCalculatorCard />
        <LifeInsuranceCalculatorCard />
      </Stack>
    </Stack>
  );
};
