import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { FinancialProductType } from 'generated/graphql';
import _ from 'lodash';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

interface Props {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products?: any[];
}

export const ProductsTypeChart: React.FC<Props> = ({ isLoading, products }) => {
  const typeItems = [
    {
      name: 'Hypotéka',
      type: FinancialProductType.Mortgage,
      value: 0,
    },
    {
      name: 'Životné poistenie',
      type: FinancialProductType.LifeInsurance,
      value: 0,
    },
    {
      name: 'PZP',
      type: FinancialProductType.MotorLiabilityInsurance,
      value: 0,
    },
    {
      name: 'Havarijné poistenie',
      type: FinancialProductType.CollisionInsurance,
      value: 0,
    },
    {
      name: 'Poistenie majetku',
      type: FinancialProductType.PropertyInsurance,
      value: 0,
    },
    {
      name: 'Investičný produkt',
      type: FinancialProductType.Investment,
      value: 0,
    },
    {
      name: 'Iný finančný produkt',
      type: FinancialProductType.Generic,
      value: 0,
    },
  ];

  const typeItemsLastWeek = _.cloneDeep(typeItems);
  const lastWeekTimestamp = new Date();
  lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);

  products?.forEach((product) => {
    const typeIndex = typeItems.findIndex((typeItem) => typeItem.type == product.type);
    typeItems[typeIndex].value++;
    if (product.creationTimestamp >= lastWeekTimestamp) {
      typeItemsLastWeek[typeIndex].value++;
    }
  });

  const COLORS = ['#ED4435', '#EC983F', '#E1498A', '#2196F3', '#FFC107', '#0D991C', '#616161'];

  return isLoading ? (
    <Skeleton height={404} variant="rectangular" />
  ) : (
    <Card>
      <CardHeader title="Produkty podľa typu" />
      <CardContent>
        <ResponsiveContainer minHeight={300}>
          <PieChart
            margin={{
              bottom: -25,
              top: -25,
            }}
          >
            <Legend verticalAlign="top" />
            <Pie
              cx="30%"
              cy="50%"
              data={typeItems}
              dataKey="value"
              endAngle={-180}
              fill="#8884d8"
              innerRadius={70}
              label
              outerRadius={100}
              startAngle={180}
            >
              <Label fill="#000000" fontSize="larger" position="center">
                spolu
              </Label>
              {typeItems.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Pie
              cx="70%"
              cy="50%"
              data={typeItemsLastWeek}
              dataKey="value"
              endAngle={-180}
              fill="#8884d8"
              innerRadius={70}
              label
              legendType="none"
              outerRadius={100}
              startAngle={180}
            >
              <Label fill="#000000" position="center">
                posledných 7 dní
              </Label>
              {typeItems.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
