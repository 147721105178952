import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useRemoveAccountMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export interface DeleteAccountDialogProps extends DialogComponentProps<boolean> {
  id: string;
}

export const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = ({ id, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [removeAccount, { loading }] = useRemoveAccountMutation({
    onCompleted: (resp) => {
      if (resp.removeAccount) {
        onResolve(true);
      }
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
      onResolve(false);
    },
    refetchQueries: ['AggregateTransactions', 'AggregateBalances', 'Notifications', 'NotificationsCount'],
    update: (cache, resp) => {
      if (resp.data?.removeAccount) {
        cache.evict({
          id: cache.identify({ __typename: 'Account', id: resp.data.removeAccount }),
        });
      }
    },
  });

  const handleRemoveAccountClick = async () => {
    if (!loading) {
      await removeAccount({ variables: { accountId: id } });
    }
  };

  return (
    <PromptDialogOnSide
      onClose={() => onResolve(false)}
      open={isOpen}
      subtitle={t('homepage.accounts.accountDetail.removeAccountDialog.text')}
      title={t('homepage.accounts.accountDetail.removeAccount')}
    >
      <Stack spacing={1}>
        <LoadingButton color="tertiaryButton" loading={loading} onClick={handleRemoveAccountClick} sx={styles.button}>
          <Typography color="red.dark" variant="bodyL">
            {t('homepage.accounts.accountDetail.removeAccount')}
          </Typography>
        </LoadingButton>
        <Button color="tertiaryButton" onClick={() => onResolve(false)} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {t('common:cancel')}
          </Typography>
        </Button>
      </Stack>
    </PromptDialogOnSide>
  );
};
