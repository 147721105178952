import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { HomepageLayout } from 'components/HomePage/HomepageLayout/HomepageLayout';
import { HomepageProductsCarousel } from 'components/HomePage/HomepageProducts/HomepageProductsCarousel';
import { HOMEPAGE_TABS, HomepageTabs } from 'components/HomePage/HomepageTabs/HomepageTabs';
import { ACCOUNTS_MANAGEMENT_BASE_URL, PRODUCTS_MANAGEMENT_BASE_URL } from 'const';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HomepageAccounts } from '../HomepageAccounts/HomepageAccounts';
import { HomepageAccountsCarousel } from '../HomepageAccounts/HomepageAccountsCarousel';
import { HomepageProducts } from '../HomepageProducts/HomepageProducts';

const HOMEPAGE_TAB_STORAGE_KEY = 'homepageDefaultTab';

interface Props {
  tab?: HOMEPAGE_TABS;
}

const tabToUrlMapping: Record<HOMEPAGE_TABS, string> = {
  [HOMEPAGE_TABS.ACCOUNTS]: ACCOUNTS_MANAGEMENT_BASE_URL,
  [HOMEPAGE_TABS.PRODUCTS]: PRODUCTS_MANAGEMENT_BASE_URL,
};

export const HomepageContent: React.FC<Props> = ({ tab }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<HOMEPAGE_TABS>(
    tab ?? (localStorage.getItem(HOMEPAGE_TAB_STORAGE_KEY) as HOMEPAGE_TABS) ?? HOMEPAGE_TABS.PRODUCTS,
  );

  useEffect(() => {
    if (!!currentTab) {
      localStorage.setItem(HOMEPAGE_TAB_STORAGE_KEY, currentTab);
      if (tab !== currentTab) navigate({ pathname: tabToUrlMapping[currentTab] });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleTabChange = (selectedTab: HOMEPAGE_TABS) => {
    setCurrentTab(selectedTab);
  };

  return (
    <HomepageLayout
      header={<HomepageTabs activeTab={currentTab} setActiveTab={handleTabChange} />}
      showNotifications={isAuthenticated}
      showRefreshAccounts={isAuthenticated && currentTab === HOMEPAGE_TABS.ACCOUNTS}
    >
      <TabContext value={currentTab}>
        <TabPanel value={HOMEPAGE_TABS.PRODUCTS}>
          <HomepageProducts />
          <Box mt={8}>
            <HomepageProductsCarousel />
          </Box>
        </TabPanel>
        <TabPanel value={HOMEPAGE_TABS.ACCOUNTS}>
          <HomepageAccounts />
          <Box mt={8}>
            <HomepageAccountsCarousel />
          </Box>
        </TabPanel>
      </TabContext>
    </HomepageLayout>
  );
};
