import { SxProps, Theme } from '@mui/material';

export const styles: Record<'title' | 'option' | 'content', SxProps<Theme>> = {
  content: {
    background: (theme) => theme.palette.gray['50'],
    borderRadius: '10px',
    overflow: 'hidden',
  },

  option: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray['200']}`,
    },
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
      cursor: 'pointer',
    },
    '.MuiFormControlLabel-label': {
      flexGrow: 1,
    },
    borderRadius: '4px',
    margin: 0,
    minWidth: '296px',
    padding: 2,
    width: '100%',
  },
  title: {
    marginX: 'auto',
    padding: (theme) => theme.spacing(1),
  },
};
