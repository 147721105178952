import { UtmParametersInput } from 'generated/graphql';

const UTM_PARAMETERS_STORAGE_KEY = 'utmParameters';
const UTM_PARAMETERS_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export const saveUtmParameters = (utmParameters: UtmParametersInput): void => {
  localStorage.setItem(UTM_PARAMETERS_STORAGE_KEY, JSON.stringify(utmParameters));
};

export const getUtmParameters = (): UtmParametersInput => {
  return JSON.parse(localStorage.getItem(UTM_PARAMETERS_STORAGE_KEY) ?? '{}');
};

export const clearUtmParameters = (): void => {
  localStorage.removeItem(UTM_PARAMETERS_STORAGE_KEY);
};

export const extractUtmParameters = (searchParams: Iterable<[string, string]>): UtmParametersInput =>
  Object.fromEntries(
    Array.from(searchParams)
      .filter(([key]) => UTM_PARAMETERS_KEYS.includes(key))
      .map(([key, value]) => [key.replace('utm_', ''), value]),
  );
