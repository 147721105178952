import { useApolloClient } from '@apollo/client';
import { useNotification } from 'common/hooks/useNotification';
import {
  TransactionDetailFragment,
  TransactionDetailFragmentDoc,
  TransactionListItemFragment,
  useUpdateTransactionGroupMutation,
  useUpdateTransactionsMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { QUERIES_TO_REFETCH_AFTER_CHANGE } from './const';

type NewTransactionDetailType = Partial<
  Pick<TransactionDetailFragment, 'category' | 'excludeFromAnalytics' | 'note' | 'tags'>
>;

interface UseTransactionDetailChangeReturn {
  update: (newDetail?: NewTransactionDetailType, applyToGroup?: boolean) => void;
}

export const useTransactionDetailChange = (
  transactionDetail?: TransactionDetailFragment | null,
): UseTransactionDetailChangeReturn => {
  const { t } = useTranslation();
  const notify = useNotification();
  const client = useApolloClient();

  const [updateTransactions] = useUpdateTransactionsMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });
  const [updateTransactionGroup] = useUpdateTransactionGroupMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const update = async (newDetail?: NewTransactionDetailType, applyToGroup?: boolean) => {
    if (!!transactionDetail && Object.values(newDetail ?? {}).filter((val) => val !== undefined).length > 0) {
      const variables = {
        categoryId: newDetail?.category?.id,
        excludeFromAnalytics: newDetail?.excludeFromAnalytics,
        note: newDetail?.note,
        tagIds: newDetail?.tags?.map((tag) => tag.id),
      };

      const optimisticTransaction = _.merge(_.cloneDeep(transactionDetail), newDetail);
      if (applyToGroup === true && !!transactionDetail.streamGroup?.id) {
        // update current transaction in cache
        client.writeFragment({
          data: optimisticTransaction,
          fragment: TransactionDetailFragmentDoc,
          fragmentName: 'TransactionDetail',
          id: client.cache.identify(transactionDetail),
        });

        updateTransactionGroup({
          refetchQueries: QUERIES_TO_REFETCH_AFTER_CHANGE,
          update: (cache) => {
            cache.modify({
              fields: {
                getTransactionsPage: (existing, { readField }) => {
                  const newList = {
                    ...existing,
                    items: existing.items.map((item: TransactionListItemFragment) =>
                      readField('id', readField('streamGroup', item)) === transactionDetail.streamGroup?.id
                        ? null
                        : item,
                    ),
                  };
                  return newList;
                },
              },
            });
          },
          variables: {
            ...variables,
            groupId: transactionDetail.streamGroup.id,
          },
        });
      } else {
        updateTransactions({
          optimisticResponse: {
            updateTransactions: [optimisticTransaction],
          },
          refetchQueries: QUERIES_TO_REFETCH_AFTER_CHANGE,
          update: (cache) => {
            cache.modify({
              fields: {
                getTransactionsPage: (existing, { readField }) => {
                  const newList = {
                    ...existing,
                    items: existing.items.map((item: TransactionListItemFragment) =>
                      readField('id', item) === transactionDetail.id ? null : item,
                    ),
                  };
                  return newList;
                },
              },
            });
          },
          variables: {
            ...variables,
            transactionIds: [transactionDetail.id],
          },
        });
      }
    }
  };

  return { update };
};
