import { emailValidator, personalDataValidator, phoneValidator } from 'common/form/validators';
import {
  LifeInsuranceCalculatorDrivingMileage,
  LifeInsuranceCalculatorEmploymentRisk,
  LifeInsuranceCalculatorFinancialReserveRisk,
  LifeInsuranceCalculatorLiabilitiesSum,
} from 'generated/graphql';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export interface LifeInsuranceCalculatorFormValues {
  contact?: {
    email: string;
    phone: string;
    consent: boolean;
  };
  driving?: {
    mileage: LifeInsuranceCalculatorDrivingMileage;
  };
  employment?: {
    risk: LifeInsuranceCalculatorEmploymentRisk;
  };
  financialReserve?: {
    risk: LifeInsuranceCalculatorFinancialReserveRisk;
  };
  health?: {
    risk: boolean;
  };
  liabilities?: {
    sum: LifeInsuranceCalculatorLiabilitiesSum;
  };
  sport?: {
    risk: boolean;
  };
}

export const getSchema = (t: TFunction<'translation', undefined>): yup.SchemaOf<LifeInsuranceCalculatorFormValues> =>
  yup.object({
    contact: yup
      .object({
        consent: personalDataValidator(),
        email: emailValidator(),
        phone: phoneValidator().required(t('form.validator.fieldIsRequired')),
      })
      .default(null)
      .nullable(),
    driving: yup
      .object({
        mileage: yup
          .mixed<LifeInsuranceCalculatorDrivingMileage>()
          .nullable(true)
          .required(t('form.validator.selectionIsRequired')),
      })
      .default(null)
      .nullable(),
    employment: yup
      .object({
        risk: yup
          .mixed<LifeInsuranceCalculatorEmploymentRisk>()
          .nullable(true)
          .required(t('form.validator.selectionIsRequired')),
      })
      .default(null)
      .nullable(),
    financialReserve: yup
      .object({
        risk: yup
          .mixed<LifeInsuranceCalculatorFinancialReserveRisk>()
          .nullable(true)
          .required(t('form.validator.selectionIsRequired')),
      })
      .default(null)
      .nullable(),
    health: yup
      .object({ risk: yup.bool().nullable(true).required(t('form.validator.selectionIsRequired')) })
      .default(null)
      .nullable(),
    liabilities: yup
      .object({
        sum: yup
          .mixed<LifeInsuranceCalculatorLiabilitiesSum>()
          .nullable(true)
          .required(t('form.validator.selectionIsRequired')),
      })
      .default(null)
      .nullable(),
    sport: yup
      .object({ risk: yup.bool().nullable(true).required(t('form.validator.selectionIsRequired')) })
      .default(null)
      .nullable(),
  });
