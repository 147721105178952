import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormNumberInput } from 'common/form/components/FormNumberInput';
import { FormSelect, FormSelectOption } from 'common/form/components/FormSelect';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Frequency } from 'rrule';
import * as yup from 'yup';

const getFrequencies = (t: TFunction<'translation', undefined>): FormSelectOption[] => [
  {
    label: t('products:financialCalendar.editFrequencyDialog.frequency.DAILY'),
    value: {
      id: Frequency.DAILY,
    },
  },
  {
    label: t('products:financialCalendar.editFrequencyDialog.frequency.WEEKLY'),
    value: {
      id: Frequency.WEEKLY,
    },
  },
  {
    label: t('products:financialCalendar.editFrequencyDialog.frequency.MONTHLY'),
    value: {
      id: Frequency.MONTHLY,
    },
  },
  {
    label: t('products:financialCalendar.editFrequencyDialog.frequency.YEARLY'),
    value: {
      id: Frequency.YEARLY,
    },
  },
];

interface FormValues {
  freq?: { id?: Frequency | null };
  interval?: number | null;
}

export interface EventFrequency {
  freq?: Frequency | null;
  interval?: number | null;
}

export interface EditEventFrequencyDialogProps extends DialogComponentProps<EventFrequency> {
  frequency?: EventFrequency | null;
}

export const EditEventFrequencyDialog: React.FC<EditEventFrequencyDialogProps> = ({
  frequency,
  isOpen,
  onReject,
  onResolve,
}) => {
  const { i18n, t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    freq: yup
      .object({
        id: yup.mixed<Frequency>().required(),
      })
      .required(i18n.t('form.validator.fieldIsRequired')),

    interval: yup
      .number()
      .nullable(true)
      .integer(t('form.validator.integer'))
      .min(1, t('form.validator.min', { value: 1 }))
      .required(i18n.t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      freq: { id: frequency?.freq ?? Frequency.MONTHLY },
      interval: frequency?.interval,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve({ freq: data?.freq?.id, interval: data.interval });
  });

  return (
    <PromptDialogOnSide
      onClose={() => onReject()}
      open={isOpen}
      subtitle={t('products:financialCalendar.editFrequencyDialog.text')}
      title={t('products:financialCalendar.editFrequencyDialog.title')}
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <Typography variant="bodyL">
              {t('products:financialCalendar.editFrequencyDialog.interval.label')}
            </Typography>
          </Box>
          <Stack alignItems="center" direction="row" mt={0.5} spacing={1}>
            <FormNumberInput
              autoFocus
              name="interval"
              placeholder={t('products:financialCalendar.editFrequencyDialog.interval.placeholder')}
              size="large"
              type="number"
            />
            <FormSelect name="freq" options={getFrequencies(t)} size="large" />
          </Stack>

          <Stack mt={3} spacing={1}>
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('products:financialCalendar.editFrequencyDialog.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onReject()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
