import { QueryResult } from '@apollo/client';
import {
  BuildingSavingsProductQuery,
  CollisionInsuranceProductQuery,
  CreditCardProductQuery,
  FinancialProductType,
  GenericInsuranceProductQuery,
  GenericProductQuery,
  InvestmentProductQuery,
  LifeInsuranceProductQuery,
  LoanProductQuery,
  MortgageProductQuery,
  MotorLiabilityInsuranceProductQuery,
  PropertyInsuranceProductQuery,
  RetirementPensionSavingsProductQuery,
  SavingsProductQuery,
  SupplementaryPensionSavingsProductQuery,
  useBuildingSavingsProductQuery,
  useCollisionInsuranceProductQuery,
  useCreditCardProductQuery,
  useGenericInsuranceProductQuery,
  useGenericProductQuery,
  useInvestmentProductQuery,
  useLifeInsuranceProductQuery,
  useLoanProductQuery,
  useMortgageProductQuery,
  useMotorLiabilityInsuranceProductQuery,
  usePropertyInsuranceProductQuery,
  useRetirementPensionSavingsProductQuery,
  useSavingsProductQuery,
  useSupplementaryPensionSavingsProductQuery,
} from 'generated/graphql';

interface Variables {
  id: string;
}

export type ProductQuery =
  | QueryResult<BuildingSavingsProductQuery, Variables>
  | QueryResult<CollisionInsuranceProductQuery, Variables>
  | QueryResult<CreditCardProductQuery, Variables>
  | QueryResult<GenericInsuranceProductQuery, Variables>
  | QueryResult<GenericProductQuery, Variables>
  | QueryResult<InvestmentProductQuery, Variables>
  | QueryResult<LifeInsuranceProductQuery, Variables>
  | QueryResult<LoanProductQuery, Variables>
  | QueryResult<MortgageProductQuery, Variables>
  | QueryResult<MotorLiabilityInsuranceProductQuery, Variables>
  | QueryResult<PropertyInsuranceProductQuery, Variables>
  | QueryResult<RetirementPensionSavingsProductQuery, Variables>
  | QueryResult<SavingsProductQuery, Variables>
  | QueryResult<SupplementaryPensionSavingsProductQuery, Variables>;

export const useProductQuery = (type: FinancialProductType, variables: Variables): ProductQuery => {
  const query = {
    [FinancialProductType.BuildingSavings]: useBuildingSavingsProductQuery({
      skip: type != FinancialProductType.BuildingSavings,
      variables,
    }),
    [FinancialProductType.CollisionInsurance]: useCollisionInsuranceProductQuery({
      skip: type != FinancialProductType.CollisionInsurance,
      variables,
    }),
    [FinancialProductType.CreditCard]: useCreditCardProductQuery({
      skip: type != FinancialProductType.CreditCard,
      variables,
    }),
    [FinancialProductType.Generic]: useGenericProductQuery({
      skip: type != FinancialProductType.Generic,
      variables,
    }),
    [FinancialProductType.GenericInsurance]: useGenericInsuranceProductQuery({
      skip: type != FinancialProductType.GenericInsurance,
      variables,
    }),
    [FinancialProductType.Investment]: useInvestmentProductQuery({
      skip: type != FinancialProductType.Investment,
      variables,
    }),
    [FinancialProductType.LifeInsurance]: useLifeInsuranceProductQuery({
      skip: type != FinancialProductType.LifeInsurance,
      variables,
    }),
    [FinancialProductType.Loan]: useLoanProductQuery({
      skip: type != FinancialProductType.Loan,
      variables,
    }),
    [FinancialProductType.Mortgage]: useMortgageProductQuery({
      skip: type != FinancialProductType.Mortgage,
      variables,
    }),
    [FinancialProductType.MotorLiabilityInsurance]: useMotorLiabilityInsuranceProductQuery({
      skip: type != FinancialProductType.MotorLiabilityInsurance,
      variables,
    }),
    [FinancialProductType.PropertyInsurance]: usePropertyInsuranceProductQuery({
      skip: type != FinancialProductType.PropertyInsurance,
      variables,
    }),
    [FinancialProductType.RetirementPensionSavings]: useRetirementPensionSavingsProductQuery({
      skip: type != FinancialProductType.RetirementPensionSavings,
      variables,
    }),
    [FinancialProductType.Savings]: useSavingsProductQuery({
      skip: type != FinancialProductType.Savings,
      variables,
    }),
    [FinancialProductType.SupplementaryPensionSavings]: useSupplementaryPensionSavingsProductQuery({
      skip: type != FinancialProductType.SupplementaryPensionSavings,
      variables,
    }),
  }[type];

  return query;
};
