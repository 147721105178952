import { SxProps, Theme } from '@mui/material';

export const styles: Record<'infoBox', SxProps<Theme>> = {
  infoBox: {
    background: (theme) => theme.palette.blue[100],
    color: (theme) => theme.palette.blue[800],
    display: 'flex',
    gap: 3,
    marginBottom: (theme) => theme.spacing(4),
    padding: (theme) => theme.spacing(2, 3),
  },
};
