import { WEB_URL } from '@brand/const';
import { Box, Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import React, { ReactNode, useContext } from 'react';

import { isProductionLikeEnvironment } from '../../../environment';
import { Link } from '../Link/Link';
import { Logo as LargeLogo } from '../Logo/Logo';
import { SmallLogo } from '../Logo/SmallLogo';
import { UserHeaderMenuAvatar } from '../UserHeaderMenu/UserHeaderMenuAvatar';

const style = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  minHeight: '72px',
  padding: 2,
  paddingX: { sm: 4 },
  placeContent: 'space-between',
  position: 'relative',
  width: '100%',
  zIndex: 10,
};
interface HeaderProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  navigationBar?: ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ children, navigationBar, sx }) => {
  const { isAuthenticated } = useContext(AuthContext);

  const mediaQuery = useMediaQuery;
  const isSmall = mediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const Logo = isSmall ? SmallLogo : LargeLogo;
  return (
    <Box
      component="header"
      sx={
        {
          ...style,
          ...(sx ?? {}),
        } as SxProps<Theme>
      }
    >
      {isAuthenticated && navigationBar ? (
        navigationBar
      ) : (
        <Box zIndex={1}>
          {isAuthenticated || !isProductionLikeEnvironment ? (
            <Link to="/">
              <Logo />
            </Link>
          ) : (
            <a href={WEB_URL}>
              <Logo />
            </a>
          )}
        </Box>
      )}
      <Stack alignItems="center" direction="row" spacing={1}>
        {children}

        {isAuthenticated && (
          <Box zIndex={1}>
            <UserHeaderMenuAvatar />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
