import { TabContext, TabList } from '@mui/lab';
import { Tab, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { FilterConditionInput, FilterOperator } from 'generated/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './AllTransactions.styles';
import { AllTransactionsContent, AllTransactionsContentProps } from './AllTransactionsContent';

enum ALL_TRANSACTIONS_TABS {
  ALL = 'all',
  DEPOSITS = 'deposits',
  EXPENSES = 'expenses',
}

const FILTERS: Record<ALL_TRANSACTIONS_TABS, FilterConditionInput[]> = {
  [ALL_TRANSACTIONS_TABS.ALL]: [],
  [ALL_TRANSACTIONS_TABS.DEPOSITS]: [
    { fieldPath: 'baseAmount.amount', operator: FilterOperator.GreaterThan, values: ['0'] },
  ],
  [ALL_TRANSACTIONS_TABS.EXPENSES]: [
    { fieldPath: 'baseAmount.amount', operator: FilterOperator.LessThanOrEqual, values: ['0'] },
  ],
};

export type AllTransactionsProps = AllTransactionsContentProps;

export const AllTransactions: React.FC<AllTransactionsProps> = (props) => {
  const [currentTab, setCurrentTab] = useState<ALL_TRANSACTIONS_TABS>(ALL_TRANSACTIONS_TABS.ALL);
  const { t } = useTranslation();
  return (
    <TabContext value={currentTab}>
      <ResponsiveSideDialog
        dialogId={props.instanceId}
        header={
          <>
            <TabList onChange={(_, newTab) => setCurrentTab(newTab)} sx={styles.tabs}>
              <Tab
                label={
                  <Typography color="gray.600" variant="bodyStrongS">
                    {t('allTransactions.tabs.all')}
                  </Typography>
                }
                value={ALL_TRANSACTIONS_TABS.ALL}
              />
              <Tab
                label={
                  <Typography color="gray.600" variant="bodyStrongS">
                    {t('allTransactions.tabs.deposits')}
                  </Typography>
                }
                value={ALL_TRANSACTIONS_TABS.DEPOSITS}
              />
              <Tab
                label={
                  <Typography color="gray.600" variant="bodyStrongS">
                    {t('allTransactions.tabs.expenses')}
                  </Typography>
                }
                value={ALL_TRANSACTIONS_TABS.EXPENSES}
              />
            </TabList>
          </>
        }
        isOpen={props.isOpen}
        onClose={props.onResolve}
      >
        <AllTransactionsContent {...props} filter={FILTERS[currentTab]} />
      </ResponsiveSideDialog>
    </TabContext>
  );
};
