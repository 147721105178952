import { withDefaultCurrency } from 'common/utils/formatUtils';
import {
  ProductStatus,
  PropertyInsuranceInsuranceType,
  PropertyInsuranceProductFragment,
  PropertyInsuranceProductInput,
} from 'generated/graphql';

export type MapPropertyInsuranceProductToInput = (
  product?: PropertyInsuranceProductFragment,
) => PropertyInsuranceProductInput;

export const mapPropertyInsuranceProductToInput: MapPropertyInsuranceProductToInput = (
  product = {
    basicInsurances: [],
    creationTimestamp: new Date(),
    documents: [],
    enableFinancialAdvisorCare: true,
    enableNotifications: true,
    id: '',
    otherInsurances: [],
    status: ProductStatus.Active,
    statusChangeDate: new Date(),
  },
) => {
  return {
    basicInsurances: product.basicInsurances?.map((insurance) => ({
      annualCost: withDefaultCurrency(insurance.annualCost),
      insuranceRisks: insurance.insuranceRisks ?? [],
      insuredSum: withDefaultCurrency(insurance.insuredSum),
      type: insurance.type,
    })),
    contractEndDate: product.contractEndDate,
    contractNumber: product.contractNumber,
    enableFinancialAdvisorCare: product.enableFinancialAdvisorCare,
    enableNotifications: product.enableNotifications,
    institutionId: product.institution?.id,
    institutionName: product.institutionName,
    insuredProperty: {
      address: product.insuredProperty?.address,
      type: product.insuredProperty?.type,
    },
    insuringPerson: {
      name: product.insuringPerson?.name,
      surname: product.insuringPerson?.surname,
    },
    name: product.name,
    otherInsurances: product.otherInsurances?.map((insurance) => ({
      annualCost: withDefaultCurrency(insurance.annualCost),
      insuranceRisks: [],
      insuredSum: withDefaultCurrency(insurance.insuredSum),
      name: insurance.name,
      type: PropertyInsuranceInsuranceType.Other,
    })),
    paymentInfo: {
      constantSymbol: product.paymentInfo?.constantSymbol,
      ibanForPayment: product.paymentInfo?.ibanForPayment,
      specificSymbol: product.paymentInfo?.specificSymbol,
      variableSymbol: product.paymentInfo?.variableSymbol,
    },
    paymentPeriod: {
      expectedAmount: withDefaultCurrency(product.paymentPeriod?.expectedAmount),
      periodCount: product.paymentPeriod?.periodCount,
      periodTypeCode: product.paymentPeriod?.periodTypeCode,
      referenceDate: product.paymentPeriod?.expectedDate,
    },
    status: product.status,
    statusChangeDate: product.statusChangeDate,
  };
};
