import { ClientInput } from 'admin/inputs/ClientInput';
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

interface MortgageCalculatorCalculationDatagridProps {
  showClient?: boolean;
}

export const MortgageCalculatorCalculationDatagrid: React.FC<MortgageCalculatorCalculationDatagridProps> = ({
  showClient,
}) => {
  return (
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      {showClient && (
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
      )}
      <TextField label="Telefónne číslo" source="client.user.phoneNumber" />
      <TextField label="Maximálna hypotéka" source="result.maxMortgageAmount.amount" />
      <DateField label="Vytvorené" locales="sk" source="creationTimestamp" />
    </Datagrid>
  );
};

export const MortgageCalculatorCalculationList: React.FC = () => {
  const otherFilters = [<ClientInput includeUnregistered={true} label="Klient" source="client.id" />];

  return (
    <List exporter={false} filters={otherFilters}>
      <MortgageCalculatorCalculationDatagrid showClient={true} />
    </List>
  );
};
