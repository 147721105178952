import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { ReactComponent as GreenCheckmark } from 'assets/icons/GreenCheckmark.svg';
import { ReactComponent as MoneyhoonAsteriskEmpty } from 'assets/icons/MoneyhoonAsteriskEmpty.svg';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { ResendEmailDialog, ResendEmailDialogProps } from 'components/ResendEmailDialog/ResendEmailDialog';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { styles } from './ContactFormConfirmationPageContent.styles';

interface LocationState {
  email?: string;
  wantRegistration?: boolean;
}

export const ContactFormConfirmationPageContent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const state = location.state as LocationState | null;

  const resendEmailDialog = useDialog<ResendEmailDialogProps, void>(ResendEmailDialog, {
    instanceId: 'resend-email-dialog',
  });

  const handleOpenResendDialog = () => {
    resendEmailDialog.create({ email: state?.email ?? '' });
  };

  const handleClickHome = () => {
    navigate('/');
  };

  return (
    <Stack direction="column" sx={styles.content}>
      <Box sx={styles.asteriskContainer}>
        <Box sx={styles.asteriskImage}>
          <MoneyhoonAsteriskEmpty height={77.95} width={77.95}></MoneyhoonAsteriskEmpty>
        </Box>
        <Box sx={styles.greenCheckMark}>
          <GreenCheckmark height={21.19} width={24.76}></GreenCheckmark>
        </Box>
      </Box>
      <Typography variant="headlineS">{t('contactConfirmationPage.title')}</Typography>
      <Typography color="gray.600" marginTop={1} variant="bodyM">
        {t('contactConfirmationPage.subtitle')}
      </Typography>
      {isAuthenticated ? (
        <Button color="secondaryButton" onClick={handleClickHome} size="large" sx={styles.button}>
          {t('contactConfirmationPage.button')}
        </Button>
      ) : (
        state?.wantRegistration && (
          <Box marginTop={3}>
            <Typography color="gray.600" marginTop={1} variant="bodyM">
              {t('contactConfirmationPage.registrationInfo')}
            </Typography>

            <Typography marginTop={1} variant="body1">
              <Trans i18nKey="contactConfirmationPage.resendEmail">
                <Link onClick={handleOpenResendDialog} />
              </Trans>
            </Typography>
          </Box>
        )
      )}
    </Stack>
  );
};
