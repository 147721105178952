import { Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

export interface ExcludeAllFromAnalyticsDialogProps extends DialogComponentProps<boolean | undefined> {
  isExcluded: boolean;
}

export const ExcludeAllFromAnalyticsDialog: React.FC<ExcludeAllFromAnalyticsDialogProps> = ({
  isExcluded,
  isOpen,
  onResolve,
}) => {
  const { t } = useTranslation();

  const translations = isExcluded
    ? {
        saveAll: t('transactionDetail.excludeFromAnalytics.isExcluded.saveAll'),
        saveThis: t('transactionDetail.excludeFromAnalytics.isExcluded.saveThis'),
        text: t('transactionDetail.excludeFromAnalytics.isExcluded.text'),
        title: t('transactionDetail.excludeFromAnalytics.isExcluded.title'),
      }
    : {
        saveAll: t('transactionDetail.excludeFromAnalytics.isNotExcluded.saveAll'),
        saveThis: t('transactionDetail.excludeFromAnalytics.isNotExcluded.saveThis'),
        text: t('transactionDetail.excludeFromAnalytics.isNotExcluded.text'),
        title: t('transactionDetail.excludeFromAnalytics.isNotExcluded.title'),
      };

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={translations.text}
      title={translations.title}
    >
      <Stack mt={3} spacing={1}>
        <Button color="primary" onClick={() => onResolve(true)} sx={styles.button} type="submit">
          <Typography color="white" variant="bodyL">
            {translations.saveAll}
          </Typography>
        </Button>
        <Button color="tertiaryButton" onClick={() => onResolve(false)} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {translations.saveThis}
          </Typography>
        </Button>
        <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyL">
            {t('common:cancel')}
          </Typography>
        </Button>
      </Stack>
    </PromptDialogOnSide>
  );
};
