import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as ExistingProductsIcon } from 'assets/icons/ExistingProducts.svg';
import { ReactComponent as NewProductsIcon } from 'assets/icons/NewProducts.svg';
import { useDialog } from 'common/hooks/useDialog';
import { useNavigateAfterLogin } from 'common/hooks/useNavigateAfterLogin';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import {
  AddMoneyhoonProductDialog,
  AddMoneyhoonProductDialogProps,
} from 'components/HomePage/UsersProducts/AddMoneyhoonProductDialog';
import {
  SelectProductTypeDialog,
  SelectProductTypeDialogProps,
} from 'components/products/SelectProductTypeDialog/SelectProductTypeDialog';
import { PRODUCTS_MANAGEMENT_ADD_PRODUCT_URL } from 'const';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './ProductsSwitcher.styles';

export const ProductsSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  const { navigateAfterLogin } = useNavigateAfterLogin();

  const newProducts = useDialog<AddMoneyhoonProductDialogProps, void>(AddMoneyhoonProductDialog, {
    instanceId: 'add-moneyhoon-product-dialog',
    navigable: true,
  });

  const handleNewProductsClick = () => {
    newProducts.create({});
  };

  const selectProductType = useDialog<SelectProductTypeDialogProps, void>(SelectProductTypeDialog, {
    instanceId: 'select-product-type-dialog',
    navigable: true,
  });

  const handleExistingProductsClick = () => {
    // workaround: as this switcher is also shown to unregistered client, we don't want to open
    // SelectProductTypeDialog directly for them but redirect to login instead
    isAuthenticated ? selectProductType.create({}) : navigateAfterLogin(PRODUCTS_MANAGEMENT_ADD_PRODUCT_URL);
  };

  return (
    <Stack sx={styles.cards}>
      <Stack gap={2} sx={styles.card}>
        <ExistingProductsIcon height={48} width={48} />
        <Stack>
          <Typography variant="bodyStrongL">{t('homepage.products.existing.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('homepage.products.existing.subtitle')}
          </Typography>
        </Stack>
        <Box>
          <Button
            aria-label={t('homepage.products.existing.button')}
            onClick={handleExistingProductsClick}
            size="large"
            sx={styles.button}
          >
            {t('homepage.products.existing.button')}
          </Button>
        </Box>
      </Stack>
      <Stack gap={2} sx={styles.card}>
        <NewProductsIcon height={48} width={48} />
        <Stack>
          <Typography variant="bodyStrongL">{t('homepage.products.new.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('homepage.products.new.subtitle')}
          </Typography>
        </Stack>
        <Box>
          <Button
            aria-label={t('homepage.products.new.button')}
            onClick={handleNewProductsClick}
            size="large"
            sx={styles.button}
          >
            {t('homepage.products.new.button')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
