import { formatIban } from 'common/utils/formatUtils';
import { TransactionCounterpartyFragment } from 'generated/graphql';
import { TFunction } from 'react-i18next';

export const getNameForTransactionCounterparty = (
  counterparty: TransactionCounterpartyFragment | null | undefined,
  transactionAmount: number | null | undefined,
  t: TFunction<'translation', undefined>,
): string => {
  return (
    (counterparty && counterparty.displayName) ??
    (counterparty && counterparty.defaultName) ??
    (counterparty && counterparty.accountIban && formatIban(counterparty.accountIban)) ??
    (transactionAmount && transactionAmount >= 0
      ? t('transactions.listItem.counterparty.unknownSender')
      : t('transactions.listItem.counterparty.unknownRecipient'))
  );
};

export const getTitleForTransactionCounterparty = (
  counterparty: TransactionCounterpartyFragment | null | undefined,
  transactionAmount: number | null | undefined,
  t: TFunction<'translation', undefined>,
): string => {
  return (
    (counterparty && counterparty.displayName) ??
    (counterparty && counterparty.defaultName) ??
    // this method isn't using accountIban as alternative name
    (transactionAmount && transactionAmount >= 0
      ? t('transactions.listItem.counterparty.unknownSender')
      : t('transactions.listItem.counterparty.unknownRecipient'))
  );
};
