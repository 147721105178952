import { Stack, Typography } from '@mui/material';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/AnalyticsExcluded.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactComponent as RecurringIcon } from 'assets/icons/Recurring.svg';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { useDialog } from 'common/hooks/useDialog';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import { TransactionListItemFragment, TransactionTypeCode, useConnectedAccountsQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionTypeTranslations } from 'translations/enums';

import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import { TagList } from '../tags/TagList/TagList';
import { TransactionDetail, TransactionDetailProps } from '../TransactionDetail/TransactionDetail';
import { getNameForTransactionCounterparty } from '../utils';
import { styles } from './TransactionListItem.styles';

interface Props {
  transaction: TransactionListItemFragment;
}

export const TransactionListItem: React.FC<Props> = ({ transaction }) => {
  const { i18n, t } = useTranslation();
  const { data: connectedAccountsData } = useConnectedAccountsQuery();

  const detailDialog = useDialog<TransactionDetailProps, void>(TransactionDetail, {
    id: transaction.id,
    instanceId: `transaction-detail-${transaction.id}`,
    navigable: true,
  });

  const handleTransactionClick = async (id: string) => {
    await detailDialog.create({ id });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      onClick={() => handleTransactionClick(transaction.id)}
      sx={styles.transaction}
    >
      <Stack direction="row" flexGrow={1} spacing={2}>
        <CategoryIcon category={transaction.category} />
        <Stack flexGrow={1} pr={1} width={10 /*let it grow to fill available space */}>
          <Typography sx={styles.counterparty} variant="bodyStrongL">
            {transaction.transactionTypeCode === TransactionTypeCode.Transfer ||
            transaction.transactionTypeCode === TransactionTypeCode.Charge
              ? getNameForTransactionCounterparty(transaction.counterparty, transaction.amount.amount, t)
              : TransactionTypeTranslations(t)[transaction.transactionTypeCode]}
          </Typography>
          <Stack alignItems="center" direction="row" spacing={1} sx={styles.upcomingPaymentItem}>
            <Typography color="textSecondary" variant="bodyM">
              {format(transaction.bookingDate, 'd. M. yyyy')}
            </Typography>
            {!!transaction.excludeFromAnalytics && <AnalyticsIcon height={16} width={16} />}
            {!!transaction?.streamGroup?.period?.expectedDate && <RecurringIcon />}
          </Stack>
          {!!transaction.tags.length && <TagList key={transaction.id} tags={transaction.tags} />}
        </Stack>
      </Stack>

      <Stack alignItems="flex-start" direction="row" spacing={0.5}>
        <Stack alignItems="flex-end" justifyContent="flex-start" spacing={0.5}>
          <Typography color={transaction.amount.amount > 0 ? 'success.main' : undefined} variant="bodyL">
            {formatTransactionAmount(transaction.amount.amount, i18n.language, 2, transaction.amount.currency)}
          </Typography>
          {(connectedAccountsData?.accounts?.items.length ?? 0) > 1 && (
            <AccountCard colorIndex={transaction.account.colorIndex} height={12} width={20} />
          )}
        </Stack>
        <ChevronRightIcon />
      </Stack>
    </Stack>
  );
};
