import { useNotification } from 'common/hooks/useNotification';
import {
  FinancialProductType,
  useDeleteBuildingSavingsProductMutation,
  useDeleteCollisionInsuranceProductMutation,
  useDeleteCreditCardProductMutation,
  useDeleteGenericInsuranceProductMutation,
  useDeleteGenericProductMutation,
  useDeleteInvestmentProductMutation,
  useDeleteLifeInsuranceProductMutation,
  useDeleteLoanProductMutation,
  useDeleteMortgageProductMutation,
  useDeleteMotorLiabilityInsuranceProductMutation,
  useDeletePropertyInsuranceProductMutation,
  useDeleteRetirementPensionSavingsProductMutation,
  useDeleteSavingsProductMutation,
  useDeleteSupplementaryPensionSavingsProductMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

type DeleteProductMutation =
  | ReturnType<typeof useDeleteBuildingSavingsProductMutation>
  | ReturnType<typeof useDeleteCollisionInsuranceProductMutation>
  | ReturnType<typeof useDeleteCreditCardProductMutation>
  | ReturnType<typeof useDeleteGenericProductMutation>
  | ReturnType<typeof useDeleteGenericInsuranceProductMutation>
  | ReturnType<typeof useDeleteInvestmentProductMutation>
  | ReturnType<typeof useDeleteLifeInsuranceProductMutation>
  | ReturnType<typeof useDeleteLoanProductMutation>
  | ReturnType<typeof useDeleteMortgageProductMutation>
  | ReturnType<typeof useDeleteMotorLiabilityInsuranceProductMutation>
  | ReturnType<typeof useDeletePropertyInsuranceProductMutation>
  | ReturnType<typeof useDeleteRetirementPensionSavingsProductMutation>
  | ReturnType<typeof useDeleteSavingsProductMutation>
  | ReturnType<typeof useDeleteSupplementaryPensionSavingsProductMutation>;

export const useDeleteProduct = (type: FinancialProductType): DeleteProductMutation => {
  const notify = useNotification();
  const { t } = useTranslation();

  const mutation = {
    [FinancialProductType.BuildingSavings]: useDeleteBuildingSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['BuildingSavingsProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.CollisionInsurance]: useDeleteCollisionInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CollisionInsuranceProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.CreditCard]: useDeleteCreditCardProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CreditCardProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.Generic]: useDeleteGenericProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.GenericInsurance]: useDeleteGenericInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericInsuranceProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.Investment]: useDeleteInvestmentProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['InvestmentProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.LifeInsurance]: useDeleteLifeInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LifeInsuranceProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.Loan]: useDeleteLoanProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LoanProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.Mortgage]: useDeleteMortgageProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MortgageProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.MotorLiabilityInsurance]: useDeleteMotorLiabilityInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MotorLiabilityInsuranceProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.PropertyInsurance]: useDeletePropertyInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['PropertyInsuranceProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.RetirementPensionSavings]: useDeleteRetirementPensionSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['RetirementPensionSavingsProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.Savings]: useDeleteSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SavingsProducts', 'CalendarEventInstances'],
    }),
    [FinancialProductType.SupplementaryPensionSavings]: useDeleteSupplementaryPensionSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SupplementaryPensionSavingsProducts', 'CalendarEventInstances'],
    }),
  }[type];

  return mutation;
};
