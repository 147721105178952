import { makeVar, ReactiveVar } from '@apollo/client';
import {
  Maybe,
  MortgageCollateralType,
  MortgageIncome,
  MortgageIncomeInput,
  MortgageLiability,
  MortgageLiabilityInput,
  MortgagePropertyType,
  MortgagePurchaseInquiryFragment,
  MortgagePurchaseInquiryInput,
  MortgagePurposeType,
} from 'generated/graphql';
import _ from 'lodash';

import { mapCitizenship } from './mapCitizenship';
import { mapIncomes } from './mapIncomes';
import { mapLiabilities } from './mapLiabilities';
import { Applicant, CollateralType, MORTGAGE_COLLATERAL_TYPE_SAME, MortgagePurchaseState } from './purchaseTypes';

const initialValue: MortgagePurchaseState = {};
export const mortgagePurchaseState: ReactiveVar<MortgagePurchaseState> = makeVar<MortgagePurchaseState>(initialValue);

export const mapPropertyTypeToCollateralType = (property?: MortgagePropertyType | null): MortgageCollateralType => {
  switch (property) {
    case MortgagePropertyType.Flat:
      return MortgageCollateralType.Flat;
    case MortgagePropertyType.House:
      return MortgageCollateralType.House;
    default:
      return MortgageCollateralType.Flat;
  }
};

export const mapPurchaseStateToInput = (state: MortgagePurchaseState): MortgagePurchaseInquiryInput => {
  const collaterals = (state.collaterals?.data?.filter((collateral) => !!collateral) ?? []) as CollateralType[];
  const applicants = (state.applicants?.data?.filter((applicant) => !!applicant) ?? []) as Applicant[];

  return {
    applicants: applicants.map((applicant) => ({
      citizenship: mapCitizenship.serialize(applicant.data?.citizenship),
      dateOfBirth: applicant.data?.dateOfBirth ?? new Date(),
      familyStatus: applicant.data?.familyStatus,
      incomes: mapIncomes.serialize(applicant.incomes),
      liabilities: mapLiabilities.serialize(applicant.liabilities),
      name: applicant.data?.name ?? '',
      numberOfDependents: applicant.data?.children,
    })),
    collaterals: collaterals.map((collateral) =>
      collateral.type === MORTGAGE_COLLATERAL_TYPE_SAME
        ? {
            isInConstruction: state.property?.type === 'HOUSE' ? collateral.isInConstruction : null,
            location: collateral.location,
            sameAsPurchasedProperty: true,
            type: mapPropertyTypeToCollateralType(state.property?.type),
            value: {
              amount: state.property?.price ?? 0,
              currency: 'EUR',
            },
          }
        : {
            isInConstruction: collateral.type === 'HOUSE' ? collateral.isInConstruction : null,
            location: collateral.location,
            sameAsPurchasedProperty: false,
            type: collateral.type ?? MortgageCollateralType.Flat,
            value: {
              amount: collateral.value ?? 0,
              currency: 'EUR',
            },
          },
    ),
    inquiryCurrentStepUrl: state?.meta?.currentStep || '',
    inquiryProgress: state?.meta?.progress || 0,
    purpose: {
      mortgageAmount: state.info?.amount
        ? {
            amount: state.info?.amount,
            currency: 'EUR',
          }
        : undefined,
      property:
        state.info?.purpose?.type === MortgagePurposeType.PurchaseProperty
          ? {
              energyCertificateClassA:
                state.property?.type === 'HOUSE' ? state.property?.energyCertificateClassA : null,
              isChosen: state.property?.isChosen,
              isDeveloperProject: state.property?.type === 'FLAT' ? state.property?.isDevelopment : null,
              price: state.property?.price
                ? {
                    amount: state.property?.price,
                    currency: 'EUR',
                  }
                : undefined,
              sizeInterval: state.property?.sizeInterval,
              type: state.property?.type,
            }
          : null,
      type:
        state.info?.purpose?.type !== undefined
          ? state.info.purpose.type === MortgagePurposeType.PurchaseProperty ||
            state.info.purpose.type === MortgagePurposeType.Other
            ? state.info.purpose.type
            : state.info.purpose.subtype?.id
          : undefined,
      typeOtherDescription:
        state.info?.purpose?.type !== MortgagePurposeType.PurchaseProperty
          ? state.info?.purpose?.description
          : undefined,
    },
  };
};

export const mapPurchaseInquiryToState = (
  inquiry?: Maybe<MortgagePurchaseInquiryFragment>,
  updateTimestamp?: Date | null,
): MortgagePurchaseState => {
  const sizeInterval = _.omit(inquiry?.purpose?.property?.sizeInterval, '__typename');

  return {
    applicants: {
      data: inquiry?.applicants?.map((applicant) => ({
        data: {
          children: applicant?.numberOfDependents ?? undefined,
          citizenship: mapCitizenship.parse(applicant.citizenship),
          dateOfBirth: applicant?.dateOfBirth ?? undefined,
          familyStatus: applicant?.familyStatus ?? undefined,
          name: applicant?.name ?? undefined,
        },
        incomes: mapIncomes.parse(applicant.incomes as MortgageIncome[]) ?? [],
        liabilities: mapLiabilities.parse(applicant?.liabilities),
      })),
    },
    collaterals: {
      data:
        inquiry?.collaterals?.map((collateral) => ({
          isInConstruction:
            (collateral.sameAsPurchasedProperty && inquiry.purpose?.property?.type === MortgagePropertyType.House) ||
            collateral.type === MortgageCollateralType.House
              ? collateral.isInConstruction ?? undefined
              : null,
          location: collateral.location ?? undefined,
          type: collateral.sameAsPurchasedProperty === true ? MORTGAGE_COLLATERAL_TYPE_SAME : collateral.type,
          value: collateral.sameAsPurchasedProperty === true ? null : collateral.value?.amount,
        })) ?? [],
    },
    info: {
      amount: inquiry?.purpose?.mortgageAmount?.amount,
      purpose: {
        description: inquiry?.purpose?.typeOtherDescription ?? undefined,
        subtype:
          inquiry?.purpose?.type != undefined
            ? inquiry?.purpose?.type === MortgagePurposeType.PurchaseProperty ||
              inquiry?.purpose?.type === MortgagePurposeType.Other
              ? undefined
              : { id: inquiry.purpose.type }
            : undefined,
        type:
          inquiry?.purpose?.type != undefined
            ? inquiry?.purpose?.type === MortgagePurposeType.PurchaseProperty ||
              inquiry?.purpose?.type === MortgagePurposeType.Other
              ? inquiry.purpose.type
              : MortgagePurposeType.OtherPropertyRelated
            : undefined,
      },
    },
    meta: {
      currentStep: inquiry?.inquiryCurrentStepUrl || undefined,
      progress: inquiry?.inquiryProgress || 0,
      updateTimestamp,
    },
    property: {
      energyCertificateClassA:
        inquiry?.purpose?.property?.type === 'HOUSE'
          ? inquiry?.purpose?.property?.energyCertificateClassA ?? undefined
          : null,
      isChosen: inquiry?.purpose?.property?.isChosen ?? undefined,
      isDevelopment:
        inquiry?.purpose?.property?.type === 'FLAT'
          ? inquiry?.purpose?.property?.isDeveloperProject ?? undefined
          : null,
      price: inquiry?.purpose?.property?.price?.amount,
      sizeInterval: sizeInterval ?? undefined,
      type: inquiry?.purpose?.property?.type,
    },
  };
};

export const trimPurchaseState = (state: MortgagePurchaseState): MortgagePurchaseState => {
  const inquiryInput = mapPurchaseStateToInput(state);

  const addTypenameToIncomes = (incomes?: MortgageIncomeInput[] | null) => {
    if (!incomes) {
      return undefined;
    }
    return incomes.flatMap((income) => {
      if (!!income.salaryIncome) {
        return { __typename: 'ApplicantSalaryIncome', ...income.salaryIncome };
      }
      if (!!income.entrepreneurshipIncome) {
        return { __typename: 'ApplicantEntrepreneurshipIncome', ...income.entrepreneurshipIncome };
      }
      if (!!income.ltdIncome) {
        return { __typename: 'ApplicantLtdIncome', ...income.ltdIncome };
      }
      if (!!income.travelCompensationIncome) {
        return { __typename: 'ApplicantTravelCompensationIncome', ...income.travelCompensationIncome };
      }
      if (!!income.realEstateRentalIncome) {
        return { __typename: 'ApplicantRealEstateRentalIncome', ...income.realEstateRentalIncome };
      }
      if (!!income.maternityLeaveIncome) {
        return { __typename: 'ApplicantMaternityLeaveIncome', ...income.maternityLeaveIncome };
      }
      if (!!income.parentalAllowanceIncome) {
        return { __typename: 'ApplicantParentalAllowanceIncome', ...income.parentalAllowanceIncome };
      }
      if (!!income.alimonyIncome) {
        return { __typename: 'ApplicantAlimonyIncome', ...income.alimonyIncome };
      }
      if (!!income.workAgreementIncome) {
        return { __typename: 'ApplicantWorkAgreementIncome', ...income.workAgreementIncome };
      }
      if (!!income.doctoralScholarshipIncome) {
        return { __typename: 'ApplicantDoctoralScholarshipIncome', ...income.doctoralScholarshipIncome };
      }
      if (!!income.disabilityPensionIncome) {
        return { __typename: 'ApplicantDisabilityPensionIncome', ...income.disabilityPensionIncome };
      }
      if (!!income.oldAgePensionIncome) {
        return { __typename: 'ApplicantOldAgePensionIncome', ...income.oldAgePensionIncome };
      }
      if (!!income.orphanPensionIncome) {
        return { __typename: 'ApplicantOrphanPensionIncome', ...income.orphanPensionIncome };
      }
      if (!!income.retirementPensionIncome) {
        return { __typename: 'ApplicantRetirementPensionIncome', ...income.retirementPensionIncome };
      }
      if (!!income.widowPensionIncome) {
        return { __typename: 'ApplicantWidowPensionIncome', ...income.widowPensionIncome };
      }
    }) as MortgageIncome[];
  };

  const addTypenameToLiabilities = (liabilities?: MortgageLiabilityInput[] | null) => {
    if (!liabilities) {
      return undefined;
    }
    return liabilities.flatMap((liability) => {
      if (!!liability.alimonyLiability) {
        return { __typename: 'ApplicantAlimonyLiability', ...liability.alimonyLiability };
      }
      if (!!liability.consumerLoanLiability) {
        return { __typename: 'ApplicantConsumerLoanLiability', ...liability.consumerLoanLiability };
      }
      if (!!liability.creditCardLiability) {
        return { __typename: 'ApplicantCreditCardLiability', ...liability.creditCardLiability };
      }
      if (!!liability.leaseLiability) {
        return { __typename: 'ApplicantLeaseLiability', ...liability.leaseLiability };
      }
      if (!!liability.mortgageLiability) {
        return { __typename: 'ApplicantMortgageLiability', ...liability.mortgageLiability };
      }
      if (!!liability.otherLoanLiability) {
        return { __typename: 'ApplicantOtherLoanLiability', ...liability.otherLoanLiability };
      }
      if (!!liability.overdraftLiability) {
        return { __typename: 'ApplicantOtherLoanLiability', ...liability.overdraftLiability };
      }
      if (!!liability.reconstructionLoanLiability) {
        return { __typename: 'ApplicantOtherLoanLiability', ...liability.reconstructionLoanLiability };
      }
    }) as MortgageLiability[];
  };

  const inquiry = {
    id: '',
    ...inquiryInput,
    applicants: inquiryInput.applicants?.map((applicant) => ({
      id: '',
      ...applicant,
      incomes: addTypenameToIncomes(applicant.incomes),
      liabilities: addTypenameToLiabilities(applicant.liabilities),
    })),
  };

  return mapPurchaseInquiryToState(inquiry, state.meta?.updateTimestamp);
};
