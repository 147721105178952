import { Box, Button, FormControlLabel, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { FormCheckBox } from 'common/form/components/FormCheckBox';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { CreateTagDialog, CreateTagDialogProps } from 'components/transactions/tags/CreateTagDialog';
import {
  ManageTagsDialog,
  ManageTagsDialogProps,
} from 'components/transactions/tags/ManageTagsDialog/ManageTagsDialog';
import { TransactionTag, useTransactionTagsQuery } from 'generated/graphql';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styles } from './TagsSelectDialog.styles';

interface FormValues {
  [key: string]: boolean;
}

export interface TagsSelectDialogProps extends DialogComponentProps<TransactionTag[]> {
  tags?: TransactionTag[];
}

export const TagsSelectDialog: React.FC<TagsSelectDialogProps> = (props) => {
  const { t } = useTranslation();
  const { data } = useTransactionTagsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const defaultValues = _.fromPairs(props.tags?.map((tag) => [tag.id, true]));

  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  const handleSubmit = formMethods.handleSubmit(async (formData) => {
    const tagsMap = _.fromPairs(data?.tags.items.map((tag) => [tag.id, tag]));
    const newTags = _.entries(formData)
      .filter(([, value]) => !!value)
      .map(([key]) => tagsMap[key]);

    props.onResolve(newTags);
  });

  const createTagDialog = useDialog<CreateTagDialogProps, void>(CreateTagDialog, {
    instanceId: 'create-tag',
  });

  const handleAddTag = async () => {
    await createTagDialog.create({});
  };

  const manageTagsDialog = useDialog<ManageTagsDialogProps, void>(ManageTagsDialog, {
    instanceId: 'manage-tags',
    navigable: true,
  });

  const handleManageTags = async () => {
    await manageTagsDialog.create({});
  };

  return (
    <FormProvider {...formMethods}>
      <ResponsiveSideDialog
        dialogId={props.instanceId}
        header={
          <>
            <Typography variant="bodyStrongL">{t('tagsSelectDialog.header.title')}</Typography>
            <Stack alignItems="center" direction="row" spacing={1} sx={styles.headerButtons}>
              <Button color="tertiaryButton" onClick={handleSubmit}>
                <Typography color="blue.dark" variant="bodyStrongL">
                  {t('tagsSelectDialog.header.submit')}
                </Typography>
              </Button>
              <Button color="tertiaryButton" onClick={handleManageTags}>
                <Typography color="blue.dark" variant="bodyL">
                  {t('tagsSelectDialog.header.edit')}
                </Typography>
              </Button>
            </Stack>
          </>
        }
        isOpen={props.isOpen}
        onClose={props.onReject}
      >
        <Box onClick={handleAddTag} sx={styles.addTag}>
          <Typography color="blue.dark">{t('tagsSelectDialog.addTag')}</Typography>
        </Box>
        <Stack sx={styles.content}>
          {data?.tags.items
            .map((tag) => (
              <FormControlLabel
                key={tag.id}
                control={<FormCheckBox name={tag.id ?? ''} />}
                label={tag.name}
                sx={styles.checkbox}
                tabIndex={0}
              />
            ))
            .sort((tagA, tagB) => tagA.props.label.localeCompare(tagB.props.label))}
        </Stack>
      </ResponsiveSideDialog>
    </FormProvider>
  );
};
