import { SxProps, Theme } from '@mui/material';

export const styles: Record<'box' | 'container', SxProps<Theme>> = {
  box: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    flexGrow: 1,
    padding: (theme) => theme.spacing(3, 4),
    width: '100%',
  },
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: (theme) => theme.spacing(3, 4),
    width: '100%',
  },
};
