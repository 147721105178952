import { Box, Button, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

export type QRCodeMissingDataDialogProps = DialogComponentProps<void>;

export const QRCodeMissingDataDialog: React.FC<QRCodeMissingDataDialogProps> = ({ isOpen, onResolve }) => {
  const { t } = useTranslation();

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={t('products:qrCodeMissingDataDialog.subtitle')}
      title={t('products:qrCodeMissingDataDialog.title')}
    >
      <Box>
        <Button color="primary" onClick={() => onResolve()} sx={styles.button}>
          <Typography color="white" variant="bodyL">
            {t('common:close')}
          </Typography>
        </Button>
      </Box>
    </PromptDialogOnSide>
  );
};
