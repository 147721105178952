import { makeVar, ReactiveVar } from '@apollo/client';

import { PeriodTypeEnum, SpendingReportState } from './spendingReportTypes';

const initialValue: SpendingReportState = {
  params: {
    periodType: PeriodTypeEnum.Month,
  },
};

export const spendingReportState: ReactiveVar<SpendingReportState> = makeVar<SpendingReportState>(initialValue);
