import { useNavigateAfterLogin } from 'common/hooks/useNavigateAfterLogin';
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthContextProvider/AuthContextProvider';

/**
 * Will redirect to /login, if user is not authorized.
 */
export const AuthorizedOnly: React.FC = () => {
  const location = useLocation();
  const { navigateAfterLogin } = useNavigateAfterLogin();
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      navigateAfterLogin(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  return <Outlet />;
};
