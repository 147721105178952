import { FilterOperator } from 'generated/graphql';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

interface Props {
  source: string;
  disabled?: boolean;
  label?: string;
  includeUnregistered?: boolean;
}

const filterToQuery = (searchText: string) => ({
  filterCondition1: {
    fieldPath: 'user.email',
    operator: FilterOperator.Ilike,
    values: [`%${searchText}%`],
  },
});

export const ClientInput: React.FC<Props> = ({ disabled, includeUnregistered, label, source }) => {
  return (
    <ReferenceInput
      // default filter for all queries
      filter={{
        filterCondition: !!includeUnregistered
          ? // suggest all registered and unregistered clients with user (exclude only deleted ones without user)
            {
              fieldPath: 'user.id',
              operator: FilterOperator.IsNotNull,
              values: [],
            }
          : // suggest only registered clients here = with user.subject
            {
              fieldPath: 'user.subject',
              operator: FilterOperator.IsNotNull,
              values: [],
            },
      }}
      reference="Client"
      required
      source={source}
    >
      <AutocompleteInput
        disabled={disabled}
        filterToQuery={filterToQuery}
        label={label}
        optionText="user.email"
        sx={{ width: '40em' }}
      />
    </ReferenceInput>
  );
};
