import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './NoProductsTitle.styles';

export const NoProductsTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" textAlign="center">
      <Typography component="div" sx={styles.headline}>
        {t('homepage.products.noProducts.title')}
      </Typography>
      <Typography sx={styles.subtitle} textAlign={'center'} variant="bodyStrongL">
        {t('homepage.products.noProducts.subtitle')}
      </Typography>
    </Stack>
  );
};
