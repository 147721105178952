import { makeVar, ReactiveVar } from '@apollo/client';
import { MortgageCalculatorInquiryInput } from 'generated/graphql';

import { MortgageCalculatorFormValues } from './MortgageCalculator.schema';

const initialValue: MortgageCalculatorFormValues = {};
export const mortgageCalculatorState: ReactiveVar<MortgageCalculatorFormValues> =
  makeVar<MortgageCalculatorFormValues>(initialValue);

export const mapValuesToInquiry = (values: MortgageCalculatorFormValues): MortgageCalculatorInquiryInput => {
  return {
    ageOldestApplicant: values.info?.age ?? 0,
    entrepreneurshipIncome: values.incomes?.entrepreneurship
      ? {
          taxBase: {
            amount: values.incomes.entrepreneurship.taxBase,
            currency: 'EUR',
          },
          taxPayed: {
            amount: values.incomes.entrepreneurship.taxPayed,
            currency: 'EUR',
          },
          yearlyRevenues: {
            amount: values.incomes.entrepreneurship.revenue,
            currency: 'EUR',
          },
        }
      : undefined,
    liabilities: {
      balance: {
        amount: values.liabilities?.balance ?? 0,
        currency: 'EUR',
      },
      monthlyInstallment: {
        amount: values.liabilities?.installment ?? 0,
        currency: 'EUR',
      },
    },
    ltdIncome: values.incomes?.ltd
      ? {
          ceoCompensation: {
            amount: values.incomes.ltd.ceoCompensation,
            currency: 'EUR',
          },
          equityShares: values.incomes.ltd.equityShares / 100,
          yearlyProfit: {
            amount: values.incomes.ltd.yearlyProfit,
            currency: 'EUR',
          },
          yearlyRevenues: {
            amount: values.incomes.ltd.yearlyRevenues,
            currency: 'EUR',
          },
        }
      : undefined,
    numberOfApplicants: values.info?.count ?? 0,
    numberOfDependents: values.info?.children ?? 0,
    salaryIncome: values.incomes?.employment
      ? { monthlyIncome: { amount: values.incomes.employment.monthlyIncome, currency: 'EUR' } }
      : undefined,
  };
};
