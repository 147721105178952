import { Box, Typography } from '@mui/material';

import { styles } from './HomepageTabs.styles';

interface Props {
  label: string;
  isActive?: boolean;
  icons: {
    active: React.ReactNode;
    default: React.ReactNode;
  };
  onClick: () => void;
}

export const HomepageTab: React.FC<Props> = ({ icons, isActive, label, onClick }) => {
  return (
    <Box onClick={onClick} sx={isActive ? styles.activeTab : styles.tab}>
      {isActive ? icons.active : icons.default}
      <Typography color={isActive ? 'textPrimary' : 'textSecondary'} sx={styles.tabLabel} variant="bodyStrongS">
        {label}
      </Typography>
    </Box>
  );
};
