import { isUnderage } from 'components/flows/common/lifeInsurance/applicantDialogs/applicantAge.utils';
import {
  LifeInsuranceIncome,
  LifeInsuranceIncomeInput,
  LifeInsuranceInquiryFragment,
  LifeInsuranceInquiryInput,
  LifeInsuranceLiability,
  LifeInsuranceLiabilityInput,
} from 'generated/graphql';

import { Applicant, LifeInsuranceState } from './lifeInsuranceTypes';
import { mapExpenses } from './mapExpenses';
import { mapHealthCondition } from './mapHealthCondition';
import { mapIncomes } from './mapIncomes';
import { mapLiabilities } from './mapLiabilities';
import { mapSports } from './mapSports';

export const mapLifeInsuranceStateToInput = (state: LifeInsuranceState): LifeInsuranceInquiryInput => {
  const applicants = (state.applicants?.filter((applicant) => !!applicant) ?? []) as Applicant[];
  return {
    applicants: applicants
      .filter((applicant) => {
        return applicant.isInsured || applicant.isInsuring;
      })
      .map((applicant) => ({
        dateOfBirth: applicant.personalInfo?.dateOfBirth ?? new Date(),
        expenses:
          isUnderage(applicant.personalInfo?.dateOfBirth) || applicant.expenses?.hasSharedExpenses === true
            ? undefined
            : mapExpenses.serialize(applicant.expenses),
        hasSharedExpenses: isUnderage(applicant.personalInfo?.dateOfBirth)
          ? null
          : applicant.expenses?.hasSharedExpenses,
        hasSharedLiabilities: isUnderage(applicant.personalInfo?.dateOfBirth)
          ? null
          : applicant.liabilities?.hasSharedLiabilities,
        healthCondition: applicant.healthCondition
          ? mapHealthCondition.serialize(applicant.healthCondition)
          : undefined,
        incomes: isUnderage(applicant.personalInfo?.dateOfBirth)
          ? undefined
          : mapIncomes.serialize(applicant.incomes?.data ?? undefined),
        isInsured: applicant.isInsured ?? false,
        isInsuring: applicant.isInsuring ?? false,
        liabilities:
          isUnderage(applicant.personalInfo?.dateOfBirth) || applicant.liabilities?.hasSharedLiabilities === true
            ? undefined
            : mapLiabilities.serialize(applicant.liabilities?.data ?? undefined),
        name: applicant.personalInfo?.name ?? '',
        occupationName: applicant.incomes?.occupationName ?? '',
        practicedSports: mapSports.serialize(applicant.practicedSports),
        surname: applicant.personalInfo?.surname ?? '',
      })),
    inquiryCurrentStepUrl: state?.meta?.currentStep || '',
    inquiryProgress: state?.meta?.progress || 0,
  };
};

export const mapLifeInsuranceInquiryToState = (
  inquiry?: LifeInsuranceInquiryFragment | null,
  updateTimestamp?: Date | null,
): LifeInsuranceState => {
  return {
    applicants: inquiry?.applicants?.map((applicant) => ({
      expenses: {
        amount: applicant.expenses?.monthlyFamilyExpenses.amount,
        hasSharedExpenses: applicant.hasSharedExpenses,
      },
      healthCondition: mapHealthCondition.parse(applicant.healthCondition),
      incomes: {
        data: mapIncomes.parse(applicant.incomes),
        occupationName: applicant.occupationName ?? undefined,
      },
      isInsured: applicant.isInsured ?? undefined,
      isInsuring: applicant.isInsuring ?? undefined,
      liabilities: {
        data: mapLiabilities.parse(applicant?.hasSharedLiabilities === false ? applicant?.liabilities : undefined),
        hasSharedLiabilities: applicant.hasSharedLiabilities,
      },
      personalInfo: {
        dateOfBirth: applicant.dateOfBirth ?? new Date(),
        name: applicant.name,
        surname: applicant.surname,
      },
      practicedSports: mapSports.parse(applicant.practicedSports),
    })),
    meta: {
      currentStep: inquiry?.inquiryCurrentStepUrl || undefined,
      progress: inquiry?.inquiryProgress || 0,
      updateTimestamp,
    },
  };
};

export const trimLifeInsuranceState = (state: LifeInsuranceState): LifeInsuranceState => {
  const inquiryInput = mapLifeInsuranceStateToInput(state);

  const addTypenameToIncomes = (incomes?: LifeInsuranceIncomeInput[] | null) => {
    if (!incomes) {
      return undefined;
    }
    return incomes.flatMap((income) => {
      if (!!income.alimonyIncome) {
        return { __typename: 'ApplicantAlimonyIncome', ...income.alimonyIncome };
      }
      if (!!income.entrepreneurshipIncome) {
        return {
          __typename: 'ApplicantEntrepreneurshipIncome',
          ...income.entrepreneurshipIncome,
          monthlyIncomeNullable: income.entrepreneurshipIncome.monthlyIncome,
        };
      }
      if (!!income.ltdIncome) {
        return {
          __typename: 'ApplicantLtdIncome',
          ...income.ltdIncome,
          monthlyIncomeNullable: income.ltdIncome.monthlyIncome,
        };
      }
      if (!!income.maternityLeaveIncome) {
        return { __typename: 'ApplicantMaternityLeaveIncome', ...income.maternityLeaveIncome };
      }
      if (!!income.otherIncome) {
        return { __typename: 'ApplicantOtherIncome', ...income.otherIncome };
      }
      if (!!income.parentalAllowanceIncome) {
        return { __typename: 'ApplicantParentalAllowanceIncome', ...income.parentalAllowanceIncome };
      }
      if (!!income.salaryIncome) {
        return { __typename: 'ApplicantSalaryIncome', ...income.salaryIncome };
      }
      if (!!income.unemploymentBenefitIncome) {
        return { __typename: 'ApplicantUnemploymentBenefitIncome', ...income.unemploymentBenefitIncome };
      }
      if (!!income.workAgreementIncome) {
        return { __typename: 'ApplicantWorkAgreementIncome', ...income.workAgreementIncome };
      }
    }) as LifeInsuranceIncome[];
  };

  const addTypenameToLiabilities = (liabilities?: LifeInsuranceLiabilityInput[] | null) => {
    if (!liabilities) {
      return undefined;
    }
    return liabilities.flatMap((liability) => {
      if (!!liability.alimonyLiability) {
        return { __typename: 'ApplicantAlimonyLiability', ...liability.alimonyLiability };
      }
      if (!!liability.consumerLoanLiability) {
        return { __typename: 'ApplicantConsumerLoanLiability', ...liability.consumerLoanLiability };
      }
      if (!!liability.leaseLiability) {
        return { __typename: 'ApplicantLeaseLiability', ...liability.leaseLiability };
      }
      if (!!liability.mortgageLiability) {
        return { __typename: 'ApplicantMortgageLiability', ...liability.mortgageLiability };
      }
      if (!!liability.otherLoanLiability) {
        return { __typename: 'ApplicantOtherLoanLiability', ...liability.otherLoanLiability };
      }
      if (!!liability.reconstructionLoanLiability) {
        return { __typename: 'ApplicantOtherLoanLiability', ...liability.reconstructionLoanLiability };
      }
    }) as LifeInsuranceLiability[];
  };

  const inquiry = {
    id: '',
    ...inquiryInput,
    applicants: inquiryInput.applicants?.map((applicant) => ({
      id: '',
      ...applicant,
      incomes: addTypenameToIncomes(applicant.incomes),
      liabilities: addTypenameToLiabilities(applicant.liabilities),
    })),
  } as LifeInsuranceInquiryFragment;

  return mapLifeInsuranceInquiryToState(inquiry, state.meta?.updateTimestamp);
};
