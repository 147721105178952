import { dateParser } from 'admin/formatUtils';
import { ClientInput } from 'admin/inputs/ClientInput';
import { Create, DateInput, SimpleForm, TextInput } from 'react-admin';

export const FinancialAdvisorTaskCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <ClientInput label="Klient" source="clientId" />
      <TextInput label="Typ" required source="title" sx={{ width: '40em' }} />
      <TextInput label="Podtyp" required source="subtitle" sx={{ width: '40em' }} />
      <TextInput label="Úloha" required source="text" sx={{ width: '40em' }} />
      <DateInput label="Dátum úlohy" parse={dateParser} required source="dueDate" />
    </SimpleForm>
  </Create>
);
