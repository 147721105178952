import { SxProps, Theme } from '@mui/material';

const boxStyle: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.gray[50],
  borderRadius: '10px',
  flexGrow: 1,
  padding: (theme) => theme.spacing(3, 4),
  width: '100%',
};

export const styles: Record<'box' | 'clickableBox', SxProps<Theme>> = {
  box: {
    ...boxStyle,
  },
  clickableBox: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.10))',
    },
    ...boxStyle,
  },
};
