import { Done } from '@mui/icons-material';
import { FinancialAdvisorTask } from 'generated/graphql';
import { Datagrid, DateField, DeleteButton, FunctionField, ReferenceField, TextField } from 'react-admin';

import { DoneButton } from './DoneButton';
import { ProductReferenceField } from './ProductReferenceField';

interface FinancialAdvisorTaskDatagridProps {
  showClient?: boolean;
}

export const FinancialAdvisorTaskDatagrid: React.FC<FinancialAdvisorTaskDatagridProps> = ({ showClient }) => {
  return (
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField label="ID" source="id" />
      {showClient && (
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
      )}
      <TextField label="Typ" source="title" />
      <TextField label="Podtyp" source="subtitle" />
      <TextField label="Úloha" source="text" />
      <ProductReferenceField label="Produkt" />
      <DateField label="Dátum udalosti" locales="sk" source="dueDate" />
      <FunctionField
        label="Stav"
        render={(record: FinancialAdvisorTask) => record.doneDate && <Done style={{ fontSize: 20 }} />}
        source="doneDate"
      />
      <DoneButton />
      <DateField label="Dátum vytvorenia" locales="sk" source="creationTimestamp" />
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
  );
};
