import { Skeleton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { sk } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { styles } from './ProductCard.styles';
import { ProductCardIcon, ProductCardIconProps } from './ProductCardIcon';

interface Props {
  title: string;
  onClick: () => void;
  accepted: boolean;
  progress?: number | null;
  updateDate?: Date | null;
  loading?: boolean;
  color?: ProductCardIconProps['color'];
}

export const ProductCard: React.FC<Props> = ({ accepted, color, loading, onClick, progress, title, updateDate }) => {
  const { t } = useTranslation();
  if (loading) {
    return <Skeleton height={116} sx={styles.skeleton} variant="rectangular" />;
  }

  return (
    <Stack onClick={onClick} spacing={1} sx={styles.container}>
      <ProductCardIcon color={color} progress={progress} />
      <Stack direction="column">
        <Typography variant="bodyStrongL">{title}</Typography>
        <Typography color="textSecondary" variant="bodyM">
          {accepted
            ? t('products:processProductsDetail.subtitle.accepted')
            : t('products:processProductsDetail.subtitle.inProgress')}
        </Typography>
        {!!updateDate && (
          <Typography color="textSecondary" variant="bodyM">
            {t('products:processProductsDetail.updateDate', {
              date: format(updateDate, 'dd. MMMM', { locale: sk }),
            })}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
