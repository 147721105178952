import { ClientInput } from 'admin/inputs/ClientInput';
import { FinancialProductType } from 'generated/graphql';
import { List, useStore } from 'react-admin';

import { ProductDatagrid } from '../ProductDatagrid';
import { ProductListAside, productStatusFilters } from '../ProductListAside';

export const MortgageProductList: React.FC = () => {
  const [statusFilter] = useStore('resources.products.list.statusFilter', productStatusFilters.All);
  const otherFilters = [<ClientInput label="Klient" source="client.id" />];

  // TODO: proper translations

  return (
    <List aside={<ProductListAside />} exporter={false} filter={statusFilter} filters={otherFilters}>
      <ProductDatagrid productType={FinancialProductType.Mortgage} showClient={true} />
    </List>
  );
};
