import _ from 'lodash';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ContainerProps,
  ContainerRef,
  Hex,
  Instance,
  InstanceComponent,
  InstanceCreator,
  InstanceId,
  InstanceOptions,
  InstanceProps,
} from './types';
import { DEFAULT_SCOPE, registerContainer, unregisterContainer } from './utils';

const EXIT_TIMEOUT = 400;
const REMOVE_TIMEOUT = 100;
const ENTER_TIMEOUT = 50;

const InstanceContainer: React.ForwardRefRenderFunction<ContainerRef, ContainerProps> = (props, ref) => {
  const navigate = useNavigate();

  const propsRef = useRef(props);

  const [instances, setInstances] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: Instance<any, any, any>;
  }>({});
  const [hashStack, setHashStack] = useState<Hex[]>([]);

  const resolve = useCallback((hash: InstanceId, v: unknown) => instances?.[hash]?.resolve(v), [instances]);
  const resolveAll = useCallback((v: unknown) => Object.values(instances).forEach((i) => i.resolve(v)), [instances]);

  const reject = useCallback((hash: InstanceId, r: unknown) => instances?.[hash]?.reject(r), [instances]);
  const rejectAll = useCallback((r: unknown) => Object.values(instances).forEach((i) => i.reject(r)), [instances]);

  const hasInstance = useCallback(
    (hash: InstanceId) => !!Object.entries(instances).find(([id, instance]) => id === hash && !instance.isClosing),
    [instances],
  );

  const getInstance = useCallback((hash: InstanceId) => instances?.[hash], [instances]);

  const remove = async (hash: InstanceId): Promise<void> => {
    const searchParams = new URL(window.location.href).searchParams;
    if (searchParams.has(hash)) {
      searchParams.delete(hash);
      navigate({ hash: window.location.hash, pathname: window.location.pathname, search: searchParams.toString() });
    }
    setInstances((instances) => {
      const instanceEntry = Object.entries(instances).find(([id]) => id === hash);
      if (instanceEntry) {
        return {
          ...instances,
          [hash]: { ...instanceEntry[1], isClosing: true },
        };
      }
      return instances;
    });
    setHashStack((stack) => stack.filter((id) => id !== hash));
    props.onRemove?.(hash);
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve();
        setTimeout(() => {
          setInstances((instances) => {
            const omitHash = _.omit(instances, hash);
            return omitHash;
          });
        }, EXIT_TIMEOUT);
      }, REMOVE_TIMEOUT),
    );
  };

  const create: InstanceCreator = <T extends InstanceProps<Resolve, Reject>, Resolve, Reject>(
    Component: InstanceComponent<T, Resolve, Reject>,
    options: InstanceOptions,
    instanceProps: Omit<T, keyof InstanceProps<Resolve, Reject>> & Partial<InstanceProps<Resolve, Reject>>,
  ) =>
    new Promise((res, rej) => {
      const hash = instanceProps?.instanceId ?? options?.instanceId;
      const { onReject, onResolve } = propsRef.current;

      const instanceOptions = {
        ...options,
        instanceId: hash,
      };

      const instance: Instance<T, Resolve, Reject> = {
        Component,
        props: { ...options, ...instanceProps },
        reject: async (r) => {
          await removeRef.current(hash);
          rej(r);
          onReject?.(hash, r);
        },
        resolve: async (v) => {
          await removeRef.current(hash);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res(v as any);
          onResolve?.(hash, v);
        },
        ...instanceOptions,
      };

      const searchParams = new URL(window.location.href).searchParams;

      if (instance.props.tmpNavigable && !searchParams.has(hash)) {
        searchParams.append(hash, 'tmp-open');
        navigate({ pathname: window.location.pathname, search: `?${searchParams.toString()}` });
      }
      if (instance.props.navigable && !searchParams.has(hash)) {
        searchParams.append(hash, 'open');
        navigate({ pathname: window.location.pathname, search: `?${searchParams.toString()}` });
      }
      setInstances((instances) => ({
        ...instances,
        [hash]: instance,
      }));

      setTimeout(() => {
        setHashStack((stack) => [...stack.filter((id) => id !== hash), hash]);
        propsRef.current.onOpen?.(hash, instance);
      }, ENTER_TIMEOUT);
    });

  const removeRef = useRef(remove);
  const createRef = useRef(create);

  useEffect(() => {
    propsRef.current = props;
    removeRef.current = remove;
    createRef.current = create;
  });

  useImperativeHandle(ref, () => ({
    create: createRef.current,
    getInstance,
    hasInstance,
    reject,
    rejectAll,
    resolve,
    resolveAll,
  }));

  useEffect(() => {
    registerContainer(DEFAULT_SCOPE, {
      create: createRef.current,
      getInstance,
      hasInstance,
      reject,
      rejectAll,
      resolve,
      resolveAll,
    });

    return () => unregisterContainer(DEFAULT_SCOPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapKeys = useMemo(() => {
    const keys = Object.keys(instances);

    return keys.map((key) => {
      const { Component, props, reject, resolve } = instances[key];

      const isOpen = !!hashStack.find((h) => h === key);

      return <Component {...props} key={key} instanceId={key} isOpen={isOpen} onReject={reject} onResolve={resolve} />;
    });
  }, [instances, hashStack]);

  return <>{mapKeys}</>;
};

export const ModalContainer = forwardRef(InstanceContainer);
