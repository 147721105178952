import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import { i18n } from '../../i18n';
import { FormError } from '../form/FormError.type';

export type SimpleAuthProps = {
  email: string;
  password: string;
};

export type SimpleAuthResponse = FormError<SimpleAuthProps> | undefined;

/**
 * Creates user.
 *
 * Can throw errors from https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors and
 * from the Auth lib
 */
export const signUp = async ({ email, password }: SimpleAuthProps): Promise<SimpleAuthResponse> => {
  try {
    await Auth.signUp({
      attributes: {
        email,
      },
      password,
      username: email,
    });

    return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    switch (error.name) {
      case 'UsernameExistsException':
        return {
          fieldError: {
            email: { code: 'UsernameExistsException', message: i18n.t('form.validator.userAlreadyExists') },
          },
        };
      default:
        console.error(error);
        return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
    }
  }
};

/**
 * Logs in user
 *
 * Can throw errors from https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors and
 * from Auth lib
 */
export const signIn = async ({ email, password }: SimpleAuthProps): Promise<SimpleAuthResponse> => {
  try {
    await Auth.signIn(email, password);
    return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    switch (error.name) {
      case 'UserNotFoundException':
        return { formError: { code: 'UserNotFoundException', message: i18n.t('form.validator.userNotFound') } };
      case 'NotAuthorizedException':
        return {
          formError: { code: 'NotAuthorizedException', message: i18n.t('form.validator.userCredentialsAreIncorrect') },
        };
      case 'UserNotConfirmedException':
        return { formError: { code: 'UserNotConfirmedException', message: i18n.t('form.validator.userNotConfirmed') } };
      default:
        console.error(error);
        return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
    }
  }
};

/**
 * Signs out
 */
export const signOut = async (): Promise<void> => Auth.signOut();

/**
 * Returns boolean indicating if a user is currently logged in
 */
export const getIsUserLoggedIn = async (): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
};

export const getCognitoAccessToken = async (): Promise<unknown> => {
  try {
    // accessToken is valid 1 hour (by default)
    // refreshToken is valid 30 days (by default)
    // if accessToken is expired and refreshToken is valid, this function will refresh accessToken
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    // it will throw, if user is logged out
    return undefined;
  }
};

export const forgotPassword = async (email: string): Promise<SimpleAuthResponse> => {
  try {
    await Auth.forgotPassword(email);
  } catch (error: unknown) {
    console.error(error);
    return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
  }
};

export const forgotPasswordSubmit = async (
  email: string,
  code: string,
  password: string,
): Promise<SimpleAuthResponse> => {
  try {
    await Auth.forgotPasswordSubmit(email, code, password);
  } catch (error: unknown) {
    console.error(error);
    return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
  }
};

export const changePassword = async (currentPassword: string, newPassword: string): Promise<SimpleAuthResponse> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(cognitoUser, currentPassword, newPassword);
    return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    switch (error.name) {
      case 'LimitExceededException':
        return {
          formError: { code: 'LimitExceededException', message: i18n.t('form.validator.changePasswordLimitExceeded') },
        };
      case 'NotAuthorizedException':
        return {
          formError: { code: 'NotAuthorizedException', message: i18n.t('form.validator.userCredentialsAreIncorrect') },
        };
      default:
        return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
    }
  }
};

export const socialLogin = async (provider: CognitoHostedUIIdentityProvider, customState?: string): Promise<unknown> =>
  Auth.federatedSignIn({
    customState,
    provider,
  });

export const resendSignUp = async (email: string): Promise<SimpleAuthResponse> => {
  try {
    await Auth.resendSignUp(email);
  } catch (error: unknown) {
    console.error(error);
    return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
  }
};

export const verifyAttribute = async (attribute: string, code: string): Promise<SimpleAuthResponse> => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    switch (error.name) {
      case 'AliasExistsException':
        return {
          formError: { code: 'AliasExistsException', message: i18n.t('form.validator.aliasAlreadyExists') },
        };
      default:
        return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
    }
  }
};

export const confirmSignUp = async (email: string, code: string): Promise<SimpleAuthResponse> => {
  try {
    await Auth.confirmSignUp(email, code);
  } catch (error: unknown) {
    console.error(error);
    return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
  }
};

export const updateAttributes = async (attributes: Record<string, string>): Promise<SimpleAuthResponse> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.message.includes('UsernameExistsException')) {
      return { formError: { code: '', message: i18n.t('form.validator.aliasAlreadyExists') } };
    }

    return { formError: { code: '', message: i18n.t('common:somethingWentWrong') } };
  }
};
