import { FileLinks } from 'admin/documents/FileLinks';
import { MortgageProduct } from 'generated/graphql';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SingleFieldList,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

export const MortgageProductShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Základné info">
        <TextField label="ID" source="id" />
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
        <TextField label="Inštitúcia" source="institution.name" />
        <TextField label="Vlastná inštitúcia" source="institutionName" />
        <TextField label="Názov produktu" source="name" />
        <BooleanField label="Starostlivosť" source="enableFinancialAdvisorCare" />
        <ArrayField label="Dokumenty" source="documents">
          <SingleFieldList linkType={false}>
            <ArrayField source="files">
              <FileLinks />
            </ArrayField>
          </SingleFieldList>
        </ArrayField>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Zmluva">
        <TextField label="Číslo zmluvy" source="contractNumber" />
        <DateField label="Dátum podpisu zmluvy" locales="sk" source="contractSignDate" />
        <DateField label="Platnosť zmluvy do" locales="sk" source="contractEndDate" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Platby">
        <FunctionField
          label="Výška mesačnej splátky"
          render={(record: MortgageProduct) =>
            record.paymentPeriod?.expectedAmount?.amount ? -record.paymentPeriod?.expectedAmount?.amount : undefined
          }
          source="paymentPeriod.expectedAmount.amount"
        />
        <DateField label="Nasledujúca platba" locales="sk" source="paymentPeriod.expectedDate" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Informácie o hypotéke">
        <DateField label="platné k" locales="sk" source="financialDetailsUpdatedAt" />
        <TextField label="Pôvodná výška hypotéky" source="amount.amount" />
        <NumberField
          label="Aktuálna úroková sadzba"
          options={{
            maximumFractionDigits: 2,
            style: 'percent',
          }}
          source="interestRate"
        />
        <TextField label="Dĺžka fixácie v rokoch" source="fixationLength" />
        <DateField label="Výročie fixácie úrokovej sadzby" locales="sk" source="fixationEndDate" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Založené nehnuteľnosti">
        <ArrayField label={false} source="collaterals">
          <Datagrid bulkActionButtons={false}>
            <TextField label="Adresa" source="address" />
          </Datagrid>
        </ArrayField>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Účastníci úverového vzťahu">
        <ArrayField label={false} source="participants">
          <Datagrid bulkActionButtons={false}>
            <TextField label="Meno" source="name" />
          </Datagrid>
        </ArrayField>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Status">
        <TextField label="Status" source="status" />
        <DateField label="Zmena stavu" locales="sk" source="statusChangeDate" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
