import { Content } from 'components/flows/collisionInsurance/PurchasePage/Content';
import { FlowsLayout } from 'components/flows/common/FlowsLayout/FlowsLayout';
import { useTranslation } from 'react-i18next';

export const CollisionInsurancePurchasePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FlowsLayout fullWidth={true} navigationText={t('flows.header.navigation.collisionInsurance')}>
      <Content />
    </FlowsLayout>
  );
};
