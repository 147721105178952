import { BasicLayout } from '../common/layout/onboarding/BasicLayout';
import { NotFoundPageContent } from '../components/NotFoundPage/NotFoundPageContent';

export const NotFoundPage: React.FC = () => {
  // TODO: refactor to contain at least Layout, header and content
  return (
    <BasicLayout contentCenter>
      <NotFoundPageContent />
    </BasicLayout>
  );
};
