import { Stack, Typography } from '@mui/material';
import { ReactComponent as RecurringIcon } from 'assets/icons/Recurring.svg';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { useDialog } from 'common/hooks/useDialog';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import { UpcomingPaymentListItemFragment, useConnectedAccountsQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import {
  UpcomingPaymentDetail,
  UpcomingPaymentDetailProps,
} from '../TransactionDetail/UpcomingPaymentDetail/UpcomingPaymentDetail';
import { getNameForTransactionCounterparty } from '../utils';
import { styles } from './TransactionListItem.styles';

interface Props {
  payment: UpcomingPaymentListItemFragment;
}

export const UpcomingPaymentListItem: React.FC<Props> = ({ payment }) => {
  const { i18n, t } = useTranslation();
  const { data: connectedAccountsData } = useConnectedAccountsQuery();
  const colorIndex = connectedAccountsData?.accounts?.items.find(
    (account) => account.id === payment.streamGroup.account.id,
  )?.colorIndex;

  const detailDialog = useDialog<UpcomingPaymentDetailProps, void>(UpcomingPaymentDetail, {
    group: payment.streamGroup,
    instanceId: `upcoming-payment-detail-${payment.streamGroup.id + payment.expectedDate.toISOString()}`,
    navigable: true,
  });

  const handleUpcomingPaymentClick = async () => {
    await detailDialog.create({ group: payment.streamGroup });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      onClick={() => handleUpcomingPaymentClick()}
      sx={styles.transaction}
    >
      <Stack direction="row" flexGrow={1} spacing={2}>
        <CategoryIcon category={payment.streamGroup.category} />
        <Stack flexGrow={1} pr={1} width={10 /*let it grow to fill available space */}>
          <Typography sx={styles.counterparty} variant="bodyStrongL">
            {getNameForTransactionCounterparty(
              payment.streamGroup.counterparty,
              payment.streamGroup.period?.expectedAmount?.amount,
              t,
            )}
          </Typography>
          <Stack alignItems="center" direction="row" spacing={1} sx={styles.upcomingPaymentItem}>
            <Typography color="textSecondary" variant="bodyM">
              {t('upcomingPayment.planned', { date: format(payment.expectedDate, 'd. M. yyyy') })}
            </Typography>
            <RecurringIcon />
          </Stack>
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <Stack alignItems="flex-end" justifyContent="flex-start" spacing={0.5}>
          <Typography variant="bodyL">
            {formatTransactionAmount(payment.expectedAmount.amount, i18n.language, 2, payment.expectedAmount.currency)}
          </Typography>
          {(connectedAccountsData?.accounts?.items.length ?? 0) > 1 && (
            <AccountCard colorIndex={colorIndex} height={12} width={20} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
