import { Dialog, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg';
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DataProtectionGuarantees } from './DataProtectionGuarantees';
import { styles } from './DataProtectionGuaranteesDialog.styles';

export type DataProtectionGuaranteesDialogProps = DialogComponentProps<void>;

export const DataProtectionGuaranteesDialog: FC<DataProtectionGuaranteesDialogProps> = ({ isOpen, onResolve }) => {
  const { t } = useTranslation();

  return (
    <Dialog PaperProps={{ sx: styles.paper }} disableEnforceFocus={true} onClose={() => onResolve()} open={isOpen}>
      <IconButton
        aria-label={t('common:close')}
        color="tertiaryButton"
        onClick={() => onResolve()}
        size="medium"
        sx={styles.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <Stack spacing={3}>
        <Stack alignItems="center" spacing={1}>
          <LockIcon />
          <Typography variant="headlineL">{t('dataProtectionDialog.title')}</Typography>
        </Stack>
        <DataProtectionGuarantees />
      </Stack>
    </Dialog>
  );
};
