import { homeTheme } from '@brand/themes/homeTheme';
import { Stack, Theme, ThemeProvider, Typography } from '@mui/material';
import { ReactComponent as MoneyhoonAsteriskEmpty } from 'assets/icons/MoneyhoonAsteriskEmpty.svg';
import { Logo } from 'common/components/Logo/Logo';
import { Crisp } from 'crisp-sdk-web';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export const MaintenancePage: React.FC = () => {
  const { t } = useTranslation();

  Crisp.session.reset();
  const newTheme = (theme: Theme) => _.merge(_.cloneDeep(theme), homeTheme);

  return (
    <ThemeProvider theme={newTheme}>
      <Stack alignItems="center" bgcolor="gray.75" minHeight="100%" minWidth="100%">
        <Stack component="header" direction="row" padding={2} width="100%">
          <Logo />
        </Stack>

        <Stack alignItems="center" component="main" flexGrow={1} maxWidth={620} pt={25} textAlign="center">
          <MoneyhoonAsteriskEmpty height={63} width={63} />
          <Typography mt={3} variant="headlineXL">
            {t('maintenance.title')}
          </Typography>
          <Typography mt={0.5} variant="bodyXL">
            {t('maintenance.text')}
          </Typography>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
