import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';

import { AvailableProducts } from '../AvailableProducts/AvailableProducts';

export type AddMoneyhoonProductDialogProps = DialogComponentProps<void>;

export const AddMoneyhoonProductDialog: React.FC<AddMoneyhoonProductDialogProps> = (props) => (
  <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
    <AvailableProducts />
  </ResponsiveSideDialog>
);
