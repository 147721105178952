import { useReactiveVar } from '@apollo/client';
import { WEB_URL } from '@brand/const';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/Person.svg';
import MortgageAvatar from 'assets/images/mortgage-avatar.png';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { styles } from 'components/calculators/CalculatorResult/CalculatorResult.styles';
import { MORTGAGE_CALCULATOR_BASE_URL, PRODUCTS_MANAGEMENT_BASE_URL } from 'const';
import { RequestContactContext } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { mortgageCalculatorState } from '../MortgageCalculator.utils';

export const MortgageCalculatorNoResult: React.FC = () => {
  const state = useReactiveVar(mortgageCalculatorState);
  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(MORTGAGE_CALCULATOR_BASE_URL, { replace: true });
  };

  const openContactDialog = useContactDialog({
    avatarSrc: MortgageAvatar,
    context: RequestContactContext.NewMortgage,
    jobTitle: t('flows/mortgage/purchase:contact.jobTitle'),
    name: t('flows/mortgage/purchase:contact.name'),
    userEmail: state.contact?.email,
    userPhone: state.contact?.phone,
  });

  const handleBackButton = () => {
    isAuthenticated ? navigate(PRODUCTS_MANAGEMENT_BASE_URL, { replace: true }) : window.location.assign(WEB_URL);
  };

  return (
    <Stack sx={styles.container}>
      <Typography variant="headlineXL">{t('flows/mortgage/calculator:noResult.title')}</Typography>

      <Typography mt={1} variant="bodyL">
        {t('flows/mortgage/calculator:noResult.subtitle')}
      </Typography>

      <Stack sx={styles.boxes}>
        <Stack sx={styles.box}>
          <Box sx={styles.redCircle}>
            <EditIcon />
          </Box>
          <Typography variant="headlineXS">{t('flows/mortgage/calculator:noResult.edit.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/mortgage/calculator:noResult.edit.subtitle')}
          </Typography>
          <Box mt={6}>
            <Button color="tertiaryButton" onClick={handleEdit}>
              {t('flows/mortgage/calculator:noResult.edit.button')}
            </Button>
          </Box>
        </Stack>
        <Stack sx={styles.box}>
          <Box sx={styles.redCircle}>
            <PersonIcon />
          </Box>
          <Typography variant="headlineXS">{t('flows/mortgage/calculator:noResult.contact.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/mortgage/calculator:noResult.contact.subtitle')}
          </Typography>
          <Box mt={6}>
            <Button color="tertiaryButton" onClick={openContactDialog}>
              {t('flows/mortgage/calculator:noResult.contact.button')}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Button color="secondaryButton" onClick={handleBackButton} sx={styles.homepageButton}>
        {t('flows/mortgage/calculator:result.backButton')}
      </Button>
    </Stack>
  );
};
