import { useReactiveVar } from '@apollo/client';
import { useLoadingIndicator } from 'common/hooks/useLoadingIndicator';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { parseDatestrings } from 'common/utils/parseDatestrings';
import { useLifeInsurancePurchaseProcessLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  lifeInsurancePurchaseState,
  mapLifeInsurancePurchaseInquiryToState,
  trimLifeInsurancePurchaseState,
} from './lifeInsurancePurchaseState';
import { LifeInsurancePurchaseState } from './lifeInsurancePurchaseTypes';

export const LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY = 'lifeInsurancePurchaseState';

interface UseLifeInsurancePurchaseStateReturn {
  state: LifeInsurancePurchaseState;
  update: (newState: LifeInsurancePurchaseState) => void;
  clear: () => void;
}

export const useLifeInsurancePurchaseState = (): UseLifeInsurancePurchaseStateReturn => {
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);
  const params = useParams();

  const state = useReactiveVar(lifeInsurancePurchaseState);

  const update = (newState: LifeInsurancePurchaseState) => {
    const stateWithTimestamp = trimLifeInsurancePurchaseState(newState);
    _.set(stateWithTimestamp, 'meta.updateTimestamp', new Date());
    if (!isAuthenticated && !params?.id) {
      localStorage.setItem(LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY, JSON.stringify(stateWithTimestamp));
    }
    lifeInsurancePurchaseState(stateWithTimestamp);
  };

  const clear = () => {
    localStorage.removeItem(LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY);
    lifeInsurancePurchaseState({});
  };

  const { t } = useTranslation();
  const { end: endLoading, start: startLoading } = useLoadingIndicator(
    t('flows/lifeInsurance/common:saving.loading.message'),
  );

  const [getProcessData] = useLifeInsurancePurchaseProcessLazyQuery({
    onCompleted: (data) =>
      update(mapLifeInsurancePurchaseInquiryToState(data.process?.inquiry, data.process?.updateTimestamp)),
  });

  useEffect(() => {
    if (_.isEmpty(state) && params.id) {
      startLoading(t('flows/lifeInsurance/common:loading.message'));
      getProcessData({ variables: { id: params.id } }).then(() =>
        endLoading(t('flows/lifeInsurance/common:loading.message')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading && _.isEmpty(state)) {
      const savedData = localStorage.getItem(LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY);
      const parsedData = savedData ? JSON.parse(savedData) : undefined;
      if (!_.isEmpty(parsedData)) {
        const dataWithDates = parseDatestrings(parsedData) as LifeInsurancePurchaseState;
        lifeInsurancePurchaseState(dataWithDates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthLoading]);

  return { clear, state, update };
};
