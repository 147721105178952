import { Money } from 'generated/graphql';
import parsePhoneNumber from 'libphonenumber-js';
import { TFunction } from 'react-i18next';

const getNumberParts = (locale: string) => {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6789);

  const groupSeparator = parts.find((part) => part.type === 'group')?.value ?? '';
  const decimalSeparator = parts.find((part) => part.type === 'decimal')?.value ?? '';

  const space = ' ';
  const numerals = '0-9';
  const modifiers = '+|-';

  return { decimalSeparator, groupSeparator, modifiers, numerals, space };
};

const nonIntlNumberChars = (locale: string): RegExp => {
  const parts = getNumberParts(locale);

  const intlNumeralChars = Object.values(parts).join('|');

  return new RegExp(`[^${intlNumeralChars}]`, 'g');
};

const stringAsInlFormattedNumber = (value: string, locale: string): string => {
  return value.replaceAll(nonIntlNumberChars(locale), '');
};

export const stringAsIntlFormattedPositiveInteger = (value: string, locale: string): string => {
  const { decimalSeparator, modifiers } = getNumberParts(locale);

  return stringAsInlFormattedNumber(value, locale).replaceAll(
    new RegExp(`[${modifiers}|${decimalSeparator}]`, 'g'),
    '',
  );
};

export const parseIntlNumber = (value: string | number | undefined | null, locale: string): number => {
  const { decimalSeparator, groupSeparator, space } = getNumberParts(locale);

  if (typeof value === 'number') {
    return value;
  }

  const valueWithoutFormatting = value
    ?.trim()
    // SK uses some white space char as groupSeparator, input value uses normal space
    .replaceAll(space, '')
    .replace(new RegExp(groupSeparator, 'g'), '')
    .replace(new RegExp(decimalSeparator), '.');

  return Number.parseFloat(valueWithoutFormatting ?? '');
};

export const formatAsIntlNumber = (
  value: number | string | undefined | null,
  locale: string,
  options?: Intl.NumberFormatOptions,
): string => {
  const formatter = new Intl.NumberFormat(locale, options);

  if (value == undefined || value?.toString() === '' || isNaN(value as number)) {
    return '';
  }

  return formatter.format(value as number);
};

export const formatCurrency = (
  value: number,
  locale: string,
  maximumFractionDigits = 0,
  currency = 'EUR',
  signDisplay?: Intl.NumberFormatOptions['signDisplay'],
): string =>
  formatAsIntlNumber(value, locale, {
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits,
    signDisplay,
    style: 'currency',
  });

export const formatTransactionAmount = (
  value: number,
  locale: string,
  maximumFractionDigits = 0,
  currency = 'EUR',
): string => formatCurrency(value, locale, maximumFractionDigits, currency, value > 0 ? 'always' : 'never');

export const formatPercent = (value: number, locale: string): string =>
  formatAsIntlNumber(value, locale, { maximumFractionDigits: 2, style: 'percent' });

export const formatBoolean = (value: boolean | undefined | null, t: TFunction<'translation', undefined>): string => {
  if (value == null) {
    return '';
  }

  if (value) {
    return t('common:boolean.true');
  }

  return t('common:boolean.false');
};

export const formatIban = (value: string): string => value.match(/.{1,4}/g)?.join(' ') ?? value;

export const normalizeIban = (iban: string): string => iban.replaceAll(/\s/g, '');

export const normalizePhoneNumber = (phone: string): string => {
  const parsed = parsePhoneNumber(phone, 'SK');
  return parsed?.formatInternational()?.replaceAll(' ', '') ?? '';
};

export const withDefaultCurrency = (money: Money | null | undefined): Money | undefined => {
  if (money?.amount != undefined) {
    return { amount: money.amount, currency: money.currency ?? 'EUR' };
  } else return undefined;
};
