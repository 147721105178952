import { Route } from 'react-router-dom';
import { SentryRoutes } from 'sentry';

import { CollisionInsuranceIntroPage } from './CollisionInsuranceIntroPage';
import { CollisionInsurancePurchasePage } from './CollisionInsurancePurchasePage';

type Props = Record<string, never>;

export const CollisionInsuranceRouter: React.FC<Props> = () => (
  <SentryRoutes>
    <Route element={<CollisionInsuranceIntroPage />} path="/" />
    <Route element={<CollisionInsurancePurchasePage />} path="/purchase" />
  </SentryRoutes>
);
