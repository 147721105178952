import { deleteFileById, uploadFileToDocument } from 'common/utils/fileUtils';
import { useGenerateFileDownloadUrlMutation } from 'generated/graphql';
import { ChangeEvent, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileStatusEnum, isUploadError, UploadedFile, UploadError, useFilesUpload } from './useFilesUpload';
import { useNotification } from './useNotification';

export enum ContentDispositionType {
  ATTACHMENT = 'attachment',
  INLINE = 'inline',
}

export const useFiles = (
  documentId: string,
  defaultFiles?: UploadedFile[],
): {
  files: UploadedFile[];
  handleDeleteClick: (fileId: string, status: FileStatusEnum) => Promise<void>;
  handleDownloadClick: (
    file: UploadedFile,
    contentDisposition: ContentDispositionType,
    windowReference: Window | null,
  ) => Promise<void>;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleInputClick: MouseEventHandler<HTMLInputElement>;
  handleRetryClick: (fileId: string) => Promise<void>;
  setFiles: (files: UploadedFile[]) => void;
  uploadError?: UploadError;
} => {
  const [files, setFiles] = useState<UploadedFile[]>(defaultFiles ?? []);
  const [uploadError, setUploadError] = useState<UploadError>();
  const { t } = useTranslation();
  const notify = useNotification();

  const setLoading = (fileId: string) => {
    const newFiles = files?.map((f) =>
      f.id !== fileId ? f : { id: f.id, name: f.name, status: FileStatusEnum.loading },
    );
    setFiles(newFiles);
  };

  const setSuccess = (fileId: string, newId?: string) => {
    const newFiles = files?.map((f) =>
      f.id !== fileId ? f : { id: newId ?? f.id, name: f.name, status: FileStatusEnum.success },
    );
    setFiles(newFiles);
  };

  const onFileChange = useFilesUpload(documentId);

  const handleInputClick: MouseEventHandler<HTMLInputElement> = (e) => {
    const el = e.target as HTMLInputElement;
    el.value = '';
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadError(undefined);
    const file = e.target.files?.[0];
    const fileTmpId = new Date().getTime().toString();
    if (file) {
      setFiles([...files, { id: fileTmpId, name: file.name, status: FileStatusEnum.loading }]);
    }
    const result = await onFileChange(e);

    if (!result) {
      return;
    }
    if (isUploadError(result)) {
      setUploadError(result);
      const newFiles = files?.map((f) =>
        f.id !== fileTmpId ? f : { id: fileTmpId ?? f.id, name: f.name, status: FileStatusEnum.error },
      );
      setFiles(newFiles);
    } else {
      setUploadError(undefined);
      setFiles([...files, result]);
    }
  };

  const handleDeleteClick = async (fileId: string, status: FileStatusEnum) => {
    setUploadError(undefined);
    if (status === FileStatusEnum.error) {
      const newFiles = files?.filter((f) => f.id !== fileId);
      setFiles(newFiles);
      return;
    }

    setLoading(fileId);

    const response = await deleteFileById(fileId);
    if (response) {
      const newFiles = files?.filter((f) => f.id !== fileId);
      setFiles(newFiles);
    } else {
      setSuccess(fileId);
      notify({ message: t('products:uploadDocument.deleteError'), type: 'error' });
    }
  };

  const [getDownloadUrl] = useGenerateFileDownloadUrlMutation();

  const handleDownloadClick = async (
    file: UploadedFile,
    contentDisposition: ContentDispositionType,
    windowReference: Window | null,
  ) => {
    setUploadError(undefined);
    const resp = await getDownloadUrl({
      onError: () => {
        notify({ message: t('products:uploadedDocument.downloadError'), type: 'error' });
      },
      variables: {
        contentDisposition: `${contentDisposition}; filename*=UTF-8''${encodeURIComponent(file.name)}`,
        fileId: file.id,
      },
    });

    if (resp.data?.generateFileDownloadUrl) {
      fetch(resp.data.generateFileDownloadUrl, {
        method: 'GET',
      })
        .then((response: { url: string }) => {
          windowReference?.location.assign(response.url);
        })
        .catch(() => {
          windowReference?.close();
          notify({ message: t('products:uploadedDocument.downloadError'), type: 'error' });
        });
    }
  };

  const handleRetryClick = async (fileId: string) => {
    setUploadError(undefined);
    const fileToRetry = files.find((f) => f.id === fileId);
    setLoading(fileId);
    if (fileToRetry?.file) {
      const response = await uploadFileToDocument(fileToRetry.file, documentId);

      if (typeof response !== 'string') {
        setSuccess(fileId, response.fileId);
        const newFiles = files?.map((f) =>
          f.id !== fileId
            ? f
            : {
                id: f.id,
                name: f.name,
                status: FileStatusEnum.success,
              },
        );
        setFiles(newFiles);
        return;
      }
    }

    const newFiles = files?.map((f) =>
      f.id !== fileId
        ? f
        : {
            error: t('common:documents.uploadError'),
            file: f.file,
            id: f.id,
            name: f.name,
            status: FileStatusEnum.error,
          },
    );
    setFiles(newFiles);
    notify({ message: t('common:documents.uploadError'), type: 'error' });
  };

  return {
    files,
    handleDeleteClick,
    handleDownloadClick,
    handleFileChange,
    handleInputClick,
    handleRetryClick,
    setFiles,
    uploadError,
  };
};
