import { LoadingButton } from '@mui/lab';
import { Skeleton, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import {
  FilterOperator,
  NotificationFragment,
  SortDirection,
  useMarkAllNotificationsAsReadMutation,
  useNotificationsQuery,
} from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationListItem } from './NotificationListItem';
import { styles } from './NotificationsDialog.styles';

interface NotificationsDialogContentProps {
  loading: boolean;
  notifications?: NotificationFragment[] | null;
}

const NotificationsDialogContent: React.FC<NotificationsDialogContentProps> = ({ loading, notifications }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={4}>
      <Typography variant="headlineXL">{t('homepage.notifications.title')}</Typography>

      {loading ? (
        <Skeleton height={400} variant="rectangular" />
      ) : (
        <Stack spacing={3}>
          {notifications?.map((item) => (
            <NotificationListItem key={item.id} notification={item} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export const NotificationsDialog: React.FC<DialogComponentProps<void>> = ({ instanceId, isOpen, onResolve }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();
  const notify = useNotification();

  const { data, loading } = useNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
    variables: {
      filterInput: {
        arguments: [
          {
            filterCondition: { fieldPath: 'isActive', operator: FilterOperator.Equal, values: ['true'] },
          },
        ],
      },
      sortInput: {
        by: [{ direction: SortDirection.Desc, fieldPath: 'creationTimestamp' }],
      },
    },
  });

  const [markAllNotificationsAsRead, { loading: loadingMarkAllNotificationsAsRead }] =
    useMarkAllNotificationsAsReadMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['NotificationsCount'],
    });

  const handleMarkAllNotificationsAsRead = async () => {
    if (!loadingMarkAllNotificationsAsRead) {
      await markAllNotificationsAsRead();
    }
  };

  return (
    <ResponsiveSideDialog
      dialogId={instanceId}
      header={
        <LoadingButton
          color="tertiaryButton"
          loading={loadingMarkAllNotificationsAsRead}
          onClick={handleMarkAllNotificationsAsRead}
          sx={styles.addButton}
        >
          <Typography color="blue.dark">{t('homepage.notifications.markAllAsReadButton')}</Typography>
        </LoadingButton>
      }
      isOpen={isOpen}
      onClose={onResolve}
    >
      <NotificationsDialogContent loading={loading} notifications={data?.notifications?.items} />
    </ResponsiveSideDialog>
  );
};
