import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as CheckmarkSuccessIcon } from 'assets/icons/CheckmarkSuccess.svg';
import { INVESTMENT_BASE_URL, INVESTMENT_BOOK_APPOINTMENT_URL } from 'const';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { styles } from './Content.styles';

export const Content: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenAccount = () => {
    navigate(`${INVESTMENT_BASE_URL}/open-account`);
  };

  const handleBookAppointment = () => {
    window.open(INVESTMENT_BOOK_APPOINTMENT_URL, '_blank');
  };

  const bulletPoints = [
    t('flows/investment:intro.bulletPoints.item1'),
    t('flows/investment:intro.bulletPoints.item2'),
    t('flows/investment:intro.bulletPoints.item3'),
  ];

  return (
    <Stack pb={3}>
      <Box sx={styles.title}>
        <Typography component="h2" mb={6} variant="headlineXL">
          <Trans i18nKey="flows/investment:intro.title" />
        </Typography>
      </Box>
      <Stack gap={2} mb={6}>
        {bulletPoints.map((bulletPoint, index) => (
          <Stack key={index} direction="row" gap={2}>
            <Box pt={0.5}>
              <CheckmarkSuccessIcon height={20} width={20} />
            </Box>
            <Typography color="textSecondary" variant="bodyXXL">
              {bulletPoint}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" gap={1.5}>
        <Button onClick={handleOpenAccount} size="large">
          {t('flows/investment:intro.buttons.openAccount')}
        </Button>

        <Button onClick={handleBookAppointment} size="large">
          {t('flows/investment:intro.buttons.bookAppointment')}
        </Button>
      </Stack>
    </Stack>
  );
};
