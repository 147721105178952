import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'asteriskImage' | 'button' | 'content' | 'asteriskContainer' | 'greenCheckMark',
  SxProps<Theme>
> = {
  asteriskContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(3),
    position: 'relative',
  },
  asteriskImage: {
    color: (theme) => theme.palette.gray[300],
  },
  button: {
    marginTop: (theme) => theme.spacing(2),
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '395px',
    textAlign: 'center',
  },
  greenCheckMark: {
    position: 'absolute',
  },
};
