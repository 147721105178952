import { styled } from '@mui/material';

// adds counter clockwise spinning to component
export const addCCSpinning = (Component: React.FC): React.FC => {
  return styled(Component)(() => ({
    '@keyframes loader': {
      from: {
        transform: 'rotate(360deg)',
      },
      to: {
        transform: 'rotate(0deg)',
      },
    },
    animation: 'loader infinite 1000ms linear',
    height: '16px',
    width: '16px',
  }));
};
