import { Button, DialogActions, FormControl, SxProps, TextField, Theme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalendarIcon } from 'assets/icons/CalendarSmall.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InputLabel } from 'common/components/InputLabel/InputLabel';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styles } from './FormDatePicker.styles';

interface Props {
  label?: string | React.ReactNode;
  name: string;
  onChange?: () => void;
  onBlur?: () => void;
  onAccept?: (value: Date | null) => void;
  helperText?: string;
  sx?: SxProps<Theme>;
}

export const FormDatePicker: React.FC<Props> = ({ helperText, label, name, onAccept, onBlur, onChange, sx }) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({ name });

  const handleChange = (value: Date | null) => {
    field.onChange(value);
    onChange?.();
  };

  const handleBlur = () => {
    field.onBlur();
    onBlur?.();
  };

  const error = fieldState?.error?.message;

  return (
    <DatePicker
      DialogProps={{ PaperProps: { sx: styles.dialog } }}
      OpenPickerButtonProps={{ color: 'tertiaryButton', tabIndex: -1 }}
      components={{
        ActionBar: ({ onAccept, onCancel, ...other }) => (
          <DialogActions {...other} sx={styles.actions}>
            <Button onClick={onAccept} size="large">
              {t('common:confirm')}
            </Button>
            <Button color="tertiaryButton" onClick={onCancel} size="large">
              {t('common:cancel')}
            </Button>
          </DialogActions>
        ),
        LeftArrowIcon: ChevronLeftIcon,
        OpenPickerIcon: CalendarIcon,
        RightArrowIcon: ChevronRightIcon,
        SwitchViewIcon: ChevronDownIcon,
      }}
      componentsProps={{
        leftArrowButton: { color: 'tertiaryButton' },
        rightArrowButton: { color: 'tertiaryButton' },
        switchViewButton: { color: 'tertiaryButton' },
      }}
      disableMaskedInput
      inputFormat={'dd.MM.yyyy'}
      onAccept={onAccept}
      onChange={handleChange}
      renderInput={({ ...params }) => (
        <FormControl error={!!error} sx={sx}>
          {!!label && <InputLabel helperText={helperText} label={label} />}
          <TextField {...params} error={!!error} onBlur={handleBlur} />
          {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
      toolbarTitle={t('datePicker.toolbarTitle')}
      value={field.value ?? ''}
    />
  );
};
