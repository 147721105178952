import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormPasswordInput } from 'common/form/components/FormPasswordInput';
import { emailValidator, passwordValidator } from 'common/form/validators';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { LoginDialog, LoginDialogProps } from './LoginDialog';
import { styles } from './styles';

interface FormValues {
  email: string;
  code: string;
  password: string;
}

export interface ResetPasswordDialogProps extends DialogComponentProps<void> {
  displayAsPage?: boolean;
}

export const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({ displayAsPage, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const { resetPassword } = useContext(AuthContext);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    code: yup.string().nullable(true).required(t('form.validator.fieldIsRequired')),
    email: emailValidator(),
    password: passwordValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      code: params.get('codeParameter'),
      email: params.get('email'),
      password: '',
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });

  const handleSubmit = formMethods.handleSubmit(async (values: FormValues) => {
    setIsLoading(true);
    setFormError('');

    const result = await resetPassword(values.email, values.code, values.password);

    setIsLoading(false);
    if (!result) {
      await loginDialog.create({
        showForgotPassword: false,
        showGoogleLoginInfo: false,
        showRegistrationInfo: false,
        title: t('resetPassword.login.title'),
      });
      onResolve();
      return;
    }
    if (result.formError) {
      setFormError(result.formError.message);
    }
  });

  return (
    <StyledDialog displayAsPage={displayAsPage} fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle>{t('resetPassword.title')}</StyledDialogTitle>

      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormPasswordInput
              autoComplete="new-password"
              label={t('resetPassword.password.label')}
              name="password"
              placeholder={t('resetPassword.password.placeholder')}
              sx={styles.input}
              variant="filled"
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} size="large" sx={styles.button} type="submit">
              {t('resetPassword.submit')}
            </LoadingButton>
          </DialogActions>
          {formError && <StyledDialogFormError content={formError} title={t('resetPassword.error.title')} />}
        </Box>
      </FormProvider>
    </StyledDialog>
  );
};
