import { Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

export type ChangeGroupCategoryDialogProps = DialogComponentProps<boolean | undefined>;

export const ChangeGroupCategoryDialog: React.FC<ChangeGroupCategoryDialogProps> = ({ isOpen, onResolve }) => {
  const { t } = useTranslation();

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={t('categoryRadioDialog.changeGroupCategoryDialog.text')}
      title={t('categoryRadioDialog.changeGroupCategoryDialog.title')}
    >
      <Stack mt={3} spacing={1}>
        <Button color="primary" onClick={() => onResolve(true)} sx={styles.button} type="submit">
          <Typography color="white" variant="bodyL">
            {t('categoryRadioDialog.changeGroupCategoryDialog.saveAll')}
          </Typography>
        </Button>
        <Button color="tertiaryButton" onClick={() => onResolve(false)} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {t('categoryRadioDialog.changeGroupCategoryDialog.saveThis')}
          </Typography>
        </Button>
        <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyL">
            {t('common:cancel')}
          </Typography>
        </Button>
      </Stack>
    </PromptDialogOnSide>
  );
};
