import { Box, Skeleton } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useBudgetQuery } from 'generated/graphql';
import { useContext } from 'react';

import { CashflowGuardianDialog, CashflowGuardianDialogProps } from '../CashflowGuardianDialog/CashflowGuardianDialog';
import { styles } from './CashflowGuardianWidget.styles';
import { CashflowGuardianWidgetContent } from './CashflowGuardianWidgetContent';
import { SetBudgetPromptContent } from './SetBudgetPromptContent';

export const CashflowGuardianWidget: React.FC = () => {
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);
  const { data, loading } = useBudgetQuery({
    skip: !isAuthenticated,
  });

  const cashflowGuardianDialog = useDialog<CashflowGuardianDialogProps, void>(CashflowGuardianDialog, {
    instanceId: 'cashflow-guardian',
    navigable: true,
  });

  const handleShowCashflowGuardianDialog = () => {
    cashflowGuardianDialog.create({});
  };

  if (isAuthLoading || loading) {
    return (
      <Box sx={styles.box}>
        <Skeleton height={75} variant="rectangular" />
      </Box>
    );
  }
  if (!!data?.budget) {
    return (
      <Box onClick={handleShowCashflowGuardianDialog} sx={styles.clickableBox}>
        <CashflowGuardianWidgetContent />
      </Box>
    );
  }

  return (
    <Box sx={styles.box}>
      <SetBudgetPromptContent />
    </Box>
  );
};
