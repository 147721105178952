import { Stack } from '@mui/material';
import { LifeInsuranceCalculatorCalculationDatagrid } from 'admin/calculators/LifeInsuranceCalculatorCalculation/LifeInsuranceCalculatorCalculationList';
import { MortgageCalculatorCalculationDatagrid } from 'admin/calculators/MortgageCalculatorCalculation/MortgageCalculatorCalculationList';
import { FinancialAdvisorTaskDatagrid } from 'admin/financialAdvisorTask/FinancialAdvisorTaskDatagrid';
import { ProcessDatagrid } from 'admin/processes/ProcessDatagrid';
import { ProductDatagrid } from 'admin/products/ProductDatagrid';
import { FinancialProductType } from 'generated/graphql';
import {
  BooleanField,
  CreateButton,
  DateField,
  Labeled,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';

interface ReferenceManyFieldLabelProps {
  label: string;
}

const ReferenceManyFieldLabel: React.FC<ReferenceManyFieldLabelProps> = ({ label }) => (
  <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>{label}</span>
);

interface ReferenceManyFieldButtonLabelProps {
  label: string;
  resource: string;
}

const ReferenceManyFieldButtonLabel: React.FC<ReferenceManyFieldButtonLabelProps> = ({ label, resource }) => {
  const client = useRecordContext();
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <ReferenceManyFieldLabel label={label} />
      <CreateButton resource={resource} state={{ record: { clientId: client.id } }} />
    </Stack>
  );
};

export const ClientShow: React.FC = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="Meno" source="user.displayName" />
        <TextField label="Telefónne číslo" source="user.phoneNumber" />
        <TextField label="E-mail" source="user.email" />
      </SimpleShowLayout>

      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Úlohy">
          <ReferenceManyField
            label={<ReferenceManyFieldButtonLabel label="" resource="FinancialAdvisorTask" />}
            reference="FinancialAdvisorTask"
            target="client.id"
          >
            <FinancialAdvisorTaskDatagrid />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Procesy">
          <Stack spacing={4}>
            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldLabel label="Nová hypotéka" />}
                reference="MortgagePurchaseProcess"
                target="client.id"
              >
                <ProcessDatagrid
                  acceptedField={<DateField label="Akceptované" locales="sk" source="acceptedTimestamp" />}
                />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldLabel label="Refinancovanie hypotéky" />}
                reference="MortgageRefinanceProcess"
                target="client.id"
              >
                <ProcessDatagrid
                  acceptedField={<DateField label="Akceptované" locales="sk" source="acceptedTimestamp" />}
                />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldLabel label="Nové životné poistenie" />}
                reference="LifeInsurancePurchaseProcess"
                target="client.id"
              >
                <ProcessDatagrid acceptedField={<BooleanField label="Akceptované" source="locked" />} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldLabel label="Audit životného poistenia" />}
                reference="LifeInsuranceAuditProcess"
                target="client.id"
              >
                <ProcessDatagrid acceptedField={<BooleanField label="Akceptované" source="locked" />} />
              </ReferenceManyField>
            </Labeled>
          </Stack>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Produkty">
          <Stack spacing={4}>
            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldButtonLabel label="Hypotéka" resource="MortgageProduct" />}
                reference="MortgageProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.Mortgage} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldButtonLabel label="Životné poistenie" resource="LifeInsuranceProduct" />}
                reference="LifeInsuranceProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.LifeInsurance} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldButtonLabel label="PZP" resource="MotorLiabilityInsuranceProduct" />}
                reference="MotorLiabilityInsuranceProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.MotorLiabilityInsurance} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={
                  <ReferenceManyFieldButtonLabel label="Havarijné poistenie" resource="CollisionInsuranceProduct" />
                }
                reference="CollisionInsuranceProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.CollisionInsurance} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldButtonLabel label="Poistenie majetku" resource="PropertyInsuranceProduct" />}
                reference="PropertyInsuranceProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.PropertyInsurance} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldButtonLabel label="Investičný produkt" resource="InvestmentProduct" />}
                reference="InvestmentProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.Investment} />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldButtonLabel label="Iný finančný produkt" resource="GenericProduct" />}
                reference="GenericProduct"
                target="client.id"
              >
                <ProductDatagrid productType={FinancialProductType.Generic} />
              </ReferenceManyField>
            </Labeled>
          </Stack>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Kalkulačky">
          <Stack spacing={4}>
            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldLabel label="Maximálna hypotéka" />}
                reference="MortgageCalculatorCalculation"
                target="client.id"
              >
                <MortgageCalculatorCalculationDatagrid />
              </ReferenceManyField>
            </Labeled>

            <Labeled>
              <ReferenceManyField
                label={<ReferenceManyFieldLabel label="Potreba životného poistenia" />}
                reference="LifeInsuranceCalculatorCalculation"
                target="client.id"
              >
                <LifeInsuranceCalculatorCalculationDatagrid />
              </ReferenceManyField>
            </Labeled>
          </Stack>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
