import { InfoDialog, InfoDialogProps } from 'common/components/InfoDialog/InfoDialog';

import { useDialog } from './useDialog';

interface Args {
  message: React.ReactNode;
  title?: string;
}

export const useInfoDialog = (): ((props: Args) => Promise<void>) => {
  const infoDialog = useDialog<InfoDialogProps, void>(InfoDialog, { instanceId: 'info-dialog' });

  return ({ message, title }: Args) => infoDialog.create({ message, title });
};
