// eslint-disable-next-line no-restricted-imports
import { FormHelperText as MuiFormHelperText } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  error?: boolean;
  size?: 'large' | 'medium';
  children: ReactNode;
}

export const FormHelperText: React.FC<Props> = ({ children, error, size }) => {
  return (
    <MuiFormHelperText error={error} sx={size !== 'medium' ? { fontSize: '16px' } : { fontSize: '13px' }}>
      {children}
    </MuiFormHelperText>
  );
};
