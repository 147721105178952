import { Box } from '@mui/material';
import { ReactComponent as HelpIcon } from 'assets/icons/Help.svg';

import { LightTooltip } from '../LightTooltip/LightTooltip';
import { styles } from './HelpTooltip.styles';

interface Props {
  text: string;
  variant?: 'small' | 'normal';
}

export const HelpTooltip: React.FC<Props> = ({ text, variant = 'normal' }) => {
  const size = variant === 'normal' ? 16 : 12;

  return (
    <LightTooltip arrow placement="top" title={text}>
      <Box component="span" sx={styles.helpTooltip}>
        <HelpIcon height={size} width={size} />
      </Box>
    </LightTooltip>
  );
};
