import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { DATA_PROTECTION_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@brand/const';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, DialogActions, DialogContent, Link as MuiLink, Stack, Typography } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormCheckBox } from 'common/form/components/FormCheckBox';
import { personalDataValidator, termsAndConditionsValidator } from 'common/form/validators';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { ProductFlows } from 'const';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { LoginDialog, LoginDialogProps } from './LoginDialog';
import { styles } from './styles';

interface FormValues {
  agreedToPersonalData: boolean;
  agreedToTermsAndConditions: boolean;
}

export interface GoogleRegisterDialogProps extends DialogComponentProps<void> {
  displayAsPage?: boolean;
  saveLocalState?: ProductFlows[];
}

export const GoogleRegisterDialog: React.FC<GoogleRegisterDialogProps> = ({ isOpen, onResolve, saveLocalState }) => {
  const { t } = useTranslation();
  const { socialSignUp } = useContext(AuthContext);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    agreedToPersonalData: personalDataValidator(),
    agreedToTermsAndConditions: termsAndConditionsValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      agreedToPersonalData: false,
      agreedToTermsAndConditions: false,
      email: '',
      password: '',
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });

  const handleLogin = () => {
    loginDialog.create({ saveLocalState });
    onResolve();
  };

  const handleSubmit = formMethods.handleSubmit(async () => {
    const url = new URL(window.location.href, window.location.origin);
    url.searchParams.set('gtmEvent', 'Registration');
    if (!!saveLocalState) url.searchParams.set('saveLocalState', saveLocalState.join(','));
    socialSignUp(CognitoHostedUIIdentityProvider.Google, url.pathname + url.search + url.hash);
  });

  return (
    <StyledDialog fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle>{t('registration.title')}</StyledDialogTitle>

      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Stack mt={2} spacing={1}>
              <FormCheckBox name="agreedToTermsAndConditions">
                <Typography color="textSecondary" variant="body2">
                  <Trans i18nKey="registration.acceptTermsAndConditions">
                    <a href={TERMS_AND_CONDITIONS_URL} target="_blank" />
                  </Trans>
                </Typography>
              </FormCheckBox>

              <FormCheckBox name="agreedToPersonalData">
                <Typography color="textSecondary" variant="body2">
                  <Trans i18nKey="registration.acceptPersonalData">
                    <a href={DATA_PROTECTION_POLICY_URL} target="_blank" />
                  </Trans>
                </Typography>
              </FormCheckBox>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button size="large" sx={styles.button} type="submit">
              {t('registration.submit')}
            </Button>
          </DialogActions>
        </Box>
        <Typography color="textSecondary" marginTop={4} textAlign="center" variant="body1">
          <Trans i18nKey="registration.alreadyRegistered">
            <MuiLink onClick={handleLogin} />
          </Trans>
        </Typography>
      </FormProvider>
    </StyledDialog>
  );
};
