import { FormControl, OutlinedInput } from '@mui/material';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InputEndAdornment } from 'common/components/InputEndAdornment/InputEndAdornment';
import { InputLabel } from 'common/components/InputLabel/InputLabel';
import { formatAsIntlNumber, parseIntlNumber, stringAsIntlFormattedPositiveInteger } from 'common/utils/formatUtils';
import React, { KeyboardEventHandler, ReactNode, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInputBaseProps } from './FormInputBase';

export interface FormNumberInputProps extends FormInputBaseProps {
  type?: 'number';
  children?: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  InputProps,
  autoComplete,
  children,
  disabled,
  fullWidth,
  helperText,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  size,
  sx,
}) => {
  const { i18n } = useTranslation();
  const { field, fieldState } = useController({ name });

  const stripInvalidChars = (stringValue: string) => {
    return stringAsIntlFormattedPositiveInteger(stringValue, i18n.language);
  };

  const getNumericValue = (stringValue: string) => {
    const parsedValue = parseIntlNumber(stringValue, i18n.language);

    return isNaN(parsedValue) ? null : parsedValue;
  };

  const getFormattedValue = (value: number | null) => {
    if (value == null) {
      return '';
    }

    const formattedValue = formatAsIntlNumber(value, i18n.language, {
      maximumFractionDigits: 0,
      notation: 'standard',
      signDisplay: 'never',
    });

    return formattedValue;
  };

  const [displayValue, setDisplayValue] = useState(getFormattedValue(field.value));

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    const eventValue = event.target.value ?? '';

    if (eventValue === '') {
      setDisplayValue('');
      field.onChange(null);
      onChange?.();
    }

    const stripedValue = stripInvalidChars(eventValue);
    const numericValue = getNumericValue(stripedValue);

    if (stripedValue === '' || numericValue === null) {
      return;
    }

    setDisplayValue(stripedValue);
    field.onChange(numericValue);
    onChange?.();
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    const eventValue = event.key ?? '';
    if (eventValue.length > 1 || event.ctrlKey || event.metaKey) {
      return;
    }

    const stripedValue = stripInvalidChars(eventValue);

    if (stripedValue === '') {
      event.preventDefault();
      return;
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = () => {
    setDisplayValue(getFormattedValue(field.value));
    field.onBlur();
    onBlur?.();
  };

  //Check if the field value has been changed outside of this component
  const numericValue = getNumericValue(displayValue);
  const valueToDisplay = field.value === numericValue ? displayValue : getFormattedValue(field.value);
  const error = fieldState?.error?.message;
  return (
    <FormControl error={!!error} fullWidth={fullWidth} sx={sx}>
      {!!label && <InputLabel helperText={helperText} label={label} size={size} />}
      <OutlinedInput
        autoComplete={autoComplete}
        {...InputProps}
        disabled={disabled}
        endAdornment={<InputEndAdornment error={Boolean(error)}>{InputProps?.endAdornment}</InputEndAdornment>}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        size={size}
        type="text"
        value={valueToDisplay}
      />
      {children}
      {error && <FormHelperText size={size}>{error}</FormHelperText>}
    </FormControl>
  );
};
