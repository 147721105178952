import { Stack, Typography } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  subtitle?: string;
}
export const NoData: React.FC<Props> = ({ subtitle, title }) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" height={196} justifyContent="center" spacing={0.5}>
      <SearchIcon />
      <Typography variant="bodyM">{title}</Typography>
      <Typography color="textSecondary" variant="bodyM">
        {subtitle ?? t('common:noData.subtitle')}
      </Typography>
    </Stack>
  );
};
