import { environment } from 'environment';
import i18n, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const SUPPORTED_LANGUAGES = ['sk'];

export const i18nInstance = use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpBackend)
  .init({
    backend: {
      loadPath: `/brands/${environment.brand}/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage'],
    },
    fallbackLng: 'sk',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: [
      'common',
      'products',
      'translation',
      'flows/common',
      'flows/lifeInsurance/common',
      'flows/lifeInsurance/audit',
      'flows/lifeInsurance/purchase',
      'flows/lifeInsurance/calculator',
      'flows/investment',
      'flows/mortgage/refinance',
      'flows/mortgage/purchase',
      'flows/mortgage/common',
      'flows/mortgage/calculator',
    ],
    supportedLngs: SUPPORTED_LANGUAGES,
  });

export { i18n };
