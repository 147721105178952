export const UNREGISTERED_USER_STORAGE_KEY = 'unregisteredUserId';

export const saveUnregisteredUserId = (id: string): void => {
  localStorage.setItem(UNREGISTERED_USER_STORAGE_KEY, id);
};

export const getUnregisteredUserId = (): string | null => {
  return localStorage.getItem(UNREGISTERED_USER_STORAGE_KEY);
};

export const clearUnregisteredUser = (): void => {
  localStorage.removeItem(UNREGISTERED_USER_STORAGE_KEY);
};
