import { Box, Button, Dialog, Typography } from '@mui/material';
import { Row } from 'common/components/Row/Row';
import { StyledCheckbox } from 'common/form/components/FormCheckBox.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { SET_COOKIE_OPTIONS } from './CookiesDialog.const';
import { styles } from './CookiesSettingsDialog.styles';

export type CookiesSettingsDialogProps = DialogComponentProps<void>;

export const CookiesSettingsDialog: React.FC<CookiesSettingsDialogProps> = ({ isOpen, onResolve }) => {
  const { t } = useTranslation();
  const [, setCookie] = useCookies(['cookielawinfo-checkbox-necessary', 'cookielawinfo-checkbox-others']);

  const [allowCookies, setAllowCookies] = useState(false);

  const handleSubmit = () => {
    if (allowCookies) {
      setCookie('cookielawinfo-checkbox-others', true, SET_COOKIE_OPTIONS);
    }
    setCookie('cookielawinfo-checkbox-necessary', true, SET_COOKIE_OPTIONS);
    onResolve();
  };

  const handleAcceptAll = () => {
    setCookie('cookielawinfo-checkbox-necessary', true, SET_COOKIE_OPTIONS);
    setCookie('cookielawinfo-checkbox-others', true, SET_COOKIE_OPTIONS);
    onResolve();
  };

  return (
    <Dialog PaperProps={{ sx: styles.dialog }} disableEnforceFocus={true} open={isOpen} sx={styles.root}>
      <Typography mb={4} variant="h4">
        {t('cookiesSettingsDialog.title')}
      </Typography>

      <Typography mb={1} variant="h5">
        {t('cookiesSettingsDialog.settings.necessary.title')}
      </Typography>
      <Typography mb={2} variant="body2">
        {t('cookiesSettingsDialog.settings.necessary.text')}
      </Typography>
      <Row>
        <StyledCheckbox checked={true} disabled />
        <Typography color="textSecondary" variant="body1">
          {t('cookiesSettingsDialog.settings.necessary.label')}
        </Typography>
      </Row>

      <Typography mb={1} mt={4} variant="h5">
        {t('cookiesSettingsDialog.settings.rest.title')}
      </Typography>
      <Typography mb={2} variant="body2">
        {t('cookiesSettingsDialog.settings.rest.text')}
      </Typography>
      <Row>
        <StyledCheckbox checked={allowCookies} onChange={() => setAllowCookies(!allowCookies)} />
        <Typography color="textSecondary" variant="body1">
          {t('cookiesSettingsDialog.settings.rest.label')}
        </Typography>
      </Row>

      <Box display="flex" gap={2} mt={4}>
        <Button color="secondaryButton" onClick={handleSubmit} size="large">
          {t('cookiesSettingsDialog.save')}
        </Button>
        <Button onClick={handleAcceptAll} size="large">
          {t('cookiesSettingsDialog.accpetAll')}
        </Button>
      </Box>
    </Dialog>
  );
};
