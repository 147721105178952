import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  | 'row'
  | 'categoryLogo'
  | 'tag'
  | 'data'
  | 'upcomingDetail'
  | 'add'
  | 'addDescription'
  | 'other'
  | 'messageForRecipient',
  SxProps<Theme>
> = {
  add: {
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
    },
    backgroundColor: (theme) => theme.palette.gray['50'],
    borderRadius: '8px',
    cursor: 'pointer',
    padding: (theme) => theme.spacing(2),
  },
  addDescription: {
    marginLeft: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(1),
  },
  categoryLogo: {
    backgroundColor: 'teal',
    borderRadius: '8px',
    height: 48,
    width: 48,
  },
  data: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
  },
  messageForRecipient: {
    borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    padding: (theme) => theme.spacing(2, 2),
  },
  other: {
    marginTop: (theme) => theme.spacing(8),
  },
  row: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2, 2),
  },
  tag: {
    backgroundColor: (theme) => theme.palette.gray[100],
    borderRadius: '2px',
    padding: (theme) => theme.spacing(0, 0.5),
  },
  upcomingDetail: {
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
    },
    backgroundColor: (theme) => theme.palette.gray['50'],
    borderRadius: '8px',
    cursor: 'pointer',
    padding: (theme) => theme.spacing(2),
  },
};
