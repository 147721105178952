import { Stack, Typography } from '@mui/material';
import { CurrencyCode, DataModel, generate, PaymentOptions } from 'bysquare';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { formatIban, formatTransactionAmount } from 'common/utils/formatUtils';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { styles } from './QRCodeDialog.styles';

export interface QRCodeDialogProps extends DialogComponentProps<void> {
  iban: string;
  amount: number;
  currency: string;
  variableSymbol?: string;
  constantSymbol?: string;
  specificSymbol?: string;
}

export const QRCodeDialog: React.FC<QRCodeDialogProps> = ({
  amount,
  constantSymbol,
  currency,
  iban,
  specificSymbol,
  variableSymbol,
  ...props
}) => {
  const { i18n, t } = useTranslation();

  const data = [
    { label: t('products:qrCodeDialog.amount'), value: formatTransactionAmount(amount, i18n.language, 2, currency) },
    variableSymbol && { label: t('products:qrCodeDialog.variableSymbol'), value: variableSymbol },
    constantSymbol && { label: t('products:qrCodeDialog.constantSymbol'), value: constantSymbol },
    specificSymbol && { label: t('products:qrCodeDialog.specificSymbol'), value: specificSymbol },
    { label: t('products:qrCodeDialog.iban'), value: formatIban(iban) },
  ].filter((item) => !!item) as { value: string; label: string }[];

  const qrCodeParams: DataModel = {
    payments: [
      {
        amount: -amount, // we store payment amount as negative number but QR code library needs positive
        bankAccounts: [{ iban }],
        constantSymbol: constantSymbol,
        currencyCode: currency as CurrencyCode,
        specificSymbol: specificSymbol,
        type: PaymentOptions.PaymentOrder,
        variableSymbol: variableSymbol,
      },
    ],
  };

  const qrCodeValue = generate(qrCodeParams, { deburr: true });

  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <Stack alignItems="center">
        <Typography variant="headlineXL">{t('products:qrCodeDialog.title')}</Typography>
        <Typography variant="bodyM">{t('products:qrCodeDialog.subtitle')}</Typography>

        <Stack alignItems="center" p={4} sx={styles.container}>
          <QRCode level="H" size={256} value={qrCodeValue} />
        </Stack>

        <Stack sx={styles.container}>
          {data.map((item) => (
            <Stack key={item.label} direction="row" flexGrow={1} justifyContent="space-between" sx={styles.row}>
              <Typography variant="bodyL">{item.label}</Typography>
              <Typography color={'textSecondary'} variant="bodyL">
                {item.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </ResponsiveSideDialog>
  );
};
