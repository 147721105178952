import { useReactiveVar } from '@apollo/client';
import { WEB_URL } from '@brand/const';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as AlarmIcon } from 'assets/icons/Alarm.svg';
import { ReactComponent as CardTravelIcon } from 'assets/icons/CardTravel.svg';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/Person.svg';
import InsuranceAvatar from 'assets/images/insurance-avatar.png';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatAsIntlNumber } from 'common/utils/formatUtils';
import { styles } from 'components/calculators/CalculatorResult/CalculatorResult.styles';
import { LIFE_INSURANCE_PURCHASE_BASE_URL, PRODUCTS_MANAGEMENT_BASE_URL } from 'const';
import {
  LifeInsuranceCalculatorDomain,
  LifeInsuranceCalculatorNecessity,
  RequestContactContext,
} from 'generated/graphql';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  LifeInsuranceCalculatorDomainTranslations,
  LifeInsuranceCalculatorNecessityTextTranslations,
  LifeInsuranceCalculatorNecessityTitleTranslations,
} from 'translations/enums';

import { lifeInsuranceCalculatorState } from '../LifeInsuranceCalculator.utils';
import { LifeInsuranceScoreIcon } from '../LifeInsuranceScoreIcon/LifeInsuranceScoreIcon';

interface Props {
  score: number;
  maximalScore: number;
  necessity: LifeInsuranceCalculatorNecessity;
  significantDomains: LifeInsuranceCalculatorDomain[];
  similarUserRatio: number;
  averageScore: number;
}

export const LifeInsuranceCalculatorResult: React.FC<Props> = (props) => {
  const state = useReactiveVar(lifeInsuranceCalculatorState);
  const { isAuthenticated } = useContext(AuthContext);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const handleOffers = () => {
    navigate(LIFE_INSURANCE_PURCHASE_BASE_URL, { replace: true });
  };

  const openContactDialog = useContactDialog({
    avatarSrc: InsuranceAvatar,
    context: RequestContactContext.LifeInsurancePurchase,
    jobTitle: t('flows/lifeInsurance/common:contact.jobTitle'),
    name: t('flows/lifeInsurance/common:contact.name'),
    userEmail: state.contact?.email,
    userPhone: state.contact?.phone,
  });

  const handleBackButton = () => {
    isAuthenticated ? navigate(PRODUCTS_MANAGEMENT_BASE_URL, { replace: true }) : window.location.assign(WEB_URL);
  };

  return (
    <Stack sx={styles.container}>
      <Typography variant="headlineXL">{t('flows/lifeInsurance/calculator:result.title')}</Typography>

      <Stack sx={styles.resultContainer}>
        <Stack sx={styles.resultBoxes}>
          <Box>
            <LifeInsuranceScoreIcon maximalScore={props.maximalScore} score={props.score} size="large" />
          </Box>

          <Stack spacing={1}>
            <Typography variant="bodyStrongXXXL">
              {LifeInsuranceCalculatorNecessityTitleTranslations(t)[props.necessity]}
            </Typography>
            <Typography color="textSecondary" variant="bodyM">
              {LifeInsuranceCalculatorNecessityTextTranslations(t)[props.necessity]}
            </Typography>
            <Typography color="textSecondary" variant="bodyS">
              <Trans
                i18nKey="flows/lifeInsurance/calculator:result.averageScore"
                values={{
                  averageScore: formatAsIntlNumber(props.averageScore, i18n.language, {
                    maximumFractionDigits: 1,
                    notation: 'standard',
                    signDisplay: 'never',
                  }),
                  maximalScore: props.maximalScore,
                }}
              />
            </Typography>
          </Stack>
        </Stack>

        <Box sx={styles.resultLine} />

        <Stack sx={styles.resultBoxes}>
          <Stack maxWidth={300} spacing={1}>
            <Typography variant="headlineXL">
              {formatAsIntlNumber(props.similarUserRatio, i18n.language, {
                maximumFractionDigits: 0,
                notation: 'standard',
                signDisplay: 'never',
                style: 'percent',
              })}
            </Typography>
            <Typography color="textSecondary" variant="bodyM">
              {t('flows/lifeInsurance/calculator:result.similarUserRatio')}
            </Typography>
          </Stack>

          <Stack spacing={1.5}>
            <Typography color="textSecondary" variant="bodyM">
              {t('flows/lifeInsurance/calculator:result.significantDomains')}
            </Typography>
            <Stack direction="row" spacing={1.5}>
              {props.significantDomains.map((domain) => (
                <Box key={domain} sx={styles.resultDomain}>
                  <Typography color="gray.700" variant="bodyM">
                    {LifeInsuranceCalculatorDomainTranslations(t)[domain]}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box mt={10}>
        <Typography variant="headlineL">{t('flows/lifeInsurance/calculator:result.subtitle')}</Typography>
      </Box>

      <Stack alignItems="center" justifyContent="center" mt={4} sx={styles.benefits}>
        <Typography color="textSecondary" variant="bodyM">
          {t('flows/lifeInsurance/calculator:result.benefits.1')}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Stack direction="row" spacing={1}>
            <AlarmIcon />
            <Typography color="textSecondary" variant="bodyM">
              {t('flows/lifeInsurance/calculator:result.benefits.2')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <CardTravelIcon />
            <Typography color="textSecondary" variant="bodyM">
              {t('flows/lifeInsurance/calculator:result.benefits.3')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={styles.boxes}>
        <Stack sx={styles.box}>
          <Box sx={styles.greenBox}>
            <Typography color="inherit" variant="bodyM">
              <Trans i18nKey="flows/lifeInsurance/calculator:result.offers.greenBox" />
            </Typography>
          </Box>
          <Box sx={styles.redCircle}>
            <EditIcon />
          </Box>
          <Typography variant="headlineXS">{t('flows/lifeInsurance/calculator:result.offers.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/lifeInsurance/calculator:result.offers.subtitle')}
          </Typography>
          <Box mt={6}>
            <Button color="tertiaryButton" onClick={handleOffers}>
              {t('flows/lifeInsurance/calculator:result.offers.button')}
            </Button>
          </Box>
        </Stack>
        <Stack sx={styles.box}>
          <Box sx={styles.redCircle}>
            <PersonIcon />
          </Box>
          <Typography variant="headlineXS">{t('flows/lifeInsurance/calculator:result.contact.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/lifeInsurance/calculator:result.contact.subtitle')}
          </Typography>
          <Box mt={6}>
            <Button color="tertiaryButton" onClick={openContactDialog}>
              {t('flows/lifeInsurance/calculator:result.contact.button')}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Button color="secondaryButton" onClick={handleBackButton} sx={styles.homepageButton}>
        {t('flows/lifeInsurance/calculator:result.backButton')}
      </Button>
    </Stack>
  );
};
