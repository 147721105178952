import { SxProps, Theme } from '@mui/material';

export const styles: Record<'closeButton' | 'paper', SxProps<Theme>> = {
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  paper: {
    border: { sm: 1, xxs: 0 },
    margin: { sm: 0, xxs: '100px 0px 0px 0px' },
    padding: (theme) => theme.spacing(4.5, 4),
  },
};
