import IntroImage from 'assets/images/intro-pages/investment-open-account.png';
import { FlowsIntroLayout } from 'components/flows/common/FlowsIntroLayout/FlowsIntroLayout';
import { Content } from 'components/flows/investment/InvestmentOpenAccountPage/Content';
import { useTranslation } from 'react-i18next';

export const InvestmentOpenAccountPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FlowsIntroLayout introImage={IntroImage} isMoneyhoon navigationText={t('flows.header.navigation.investment')}>
      <Content />
    </FlowsIntroLayout>
  );
};
