import { ClientInput } from 'admin/inputs/ClientInput';
import { LifeInsuranceCalculatorDomain } from 'generated/graphql';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';

interface LifeInsuranceCalculatorCalculationDatagridProps {
  showClient?: boolean;
}

export const LifeInsuranceCalculatorCalculationDatagrid: React.FC<LifeInsuranceCalculatorCalculationDatagridProps> = ({
  showClient,
}) => {
  return (
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      {showClient && (
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
      )}
      <TextField label="Telefónne číslo" source="client.user.phoneNumber" />
      <TextField label="Skóre" source="result.score" />
      <TextField label="Potreba" source="result.necessity" />
      <ArrayField label="Oblasti s najväčším dopadom" source="result.significantDomains">
        <SingleFieldList linkType={false}>
          <FunctionField
            render={(record: LifeInsuranceCalculatorDomain) => <ChipField record={{ name: record }} source="name" />}
          />
        </SingleFieldList>
      </ArrayField>
      <DateField label="Vytvorené" locales="sk" source="creationTimestamp" />
    </Datagrid>
  );
};

export const LifeInsuranceCalculatorCalculationList: React.FC = () => {
  const otherFilters = [<ClientInput includeUnregistered={true} label="Klient" source="client.id" />];

  return (
    <List exporter={false} filters={otherFilters}>
      <LifeInsuranceCalculatorCalculationDatagrid showClient={true} />
    </List>
  );
};
