import { EnvironmentEnum } from './const';

export interface Environment {
  brand: string;
  cognito: {
    clientId: string;
    poolId: string;
    region: string;
    domain: string;
  };
  backendRouting: {
    gqlEndpoint: string;
  };
  currentEnvironment: string;
  crisp: {
    websiteId: string;
  };
  gtm: {
    auth: string;
    cookiesWin: string;
    id: string;
    preview: string;
  };
  finportal: {
    collisionInsuranceUrl: string;
    motorLiabilityInsuranceUrl: string;
    propertyInsuranceUrl: string;
  };
  sentry: {
    dns: string;
  };
}

const {
  REACT_APP_BRAND,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_REGION,
  REACT_APP_CRISP_WEBSITE_ID,
  REACT_APP_ENVIRONMENT,
  REACT_APP_FINPORTAL_COLLISION_INSURANCE_URL,
  REACT_APP_FINPORTAL_MOTOR_LIABILITY_INSURANCE_URL,
  REACT_APP_FINPORTAL_PROPERTY_INSURANCE_URL,
  REACT_APP_GQL_ENDPOINT_PATH,
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_COOKIES_WIN,
  REACT_APP_GTM_ID,
  REACT_APP_GTM_PREVIEW,
  REACT_APP_SENTRY_DSN,
} = process.env;

/**
 * Contains just runtime variables for Frontend. No proxy server variables.
 *
 * Dev build (CRA) uses env variables. In production build, we want to take them from window
 * object if possible, so we do not have to rebuild image to use new variables
 * See //TODO: ROBO2-68
 */
export const environment: Environment = {
  backendRouting: {
    gqlEndpoint: window?.environment?.backendRouting?.gqlEndpoint ?? REACT_APP_GQL_ENDPOINT_PATH ?? '',
  },
  brand: window?.environment?.brand ?? REACT_APP_BRAND ?? '',
  cognito: {
    clientId: window?.environment?.cognito?.clientId ?? REACT_APP_COGNITO_CLIENT_ID ?? '',
    domain: window?.environment?.cognito?.domain ?? REACT_APP_COGNITO_DOMAIN ?? '',
    poolId: window?.environment?.cognito?.poolId ?? REACT_APP_COGNITO_POOL_ID ?? '',
    region: window?.environment?.cognito?.region ?? REACT_APP_COGNITO_REGION ?? '',
  },
  crisp: {
    websiteId: window.environment?.crisp.websiteId ?? REACT_APP_CRISP_WEBSITE_ID ?? '',
  },
  currentEnvironment: window?.environment?.currentEnvironment ?? REACT_APP_ENVIRONMENT ?? '',
  finportal: {
    collisionInsuranceUrl:
      window.environment?.finportal.collisionInsuranceUrl ?? REACT_APP_FINPORTAL_COLLISION_INSURANCE_URL ?? '',
    motorLiabilityInsuranceUrl:
      window.environment?.finportal.motorLiabilityInsuranceUrl ??
      REACT_APP_FINPORTAL_MOTOR_LIABILITY_INSURANCE_URL ??
      '',
    propertyInsuranceUrl:
      window.environment?.finportal.propertyInsuranceUrl ?? REACT_APP_FINPORTAL_PROPERTY_INSURANCE_URL ?? '',
  },
  gtm: {
    auth: window.environment?.gtm.auth ?? REACT_APP_GTM_AUTH ?? '',
    cookiesWin: window.environment?.gtm.cookiesWin ?? REACT_APP_GTM_COOKIES_WIN ?? '',
    id: window.environment?.gtm.id ?? REACT_APP_GTM_ID ?? '',
    preview: window.environment?.gtm.preview ?? REACT_APP_GTM_PREVIEW ?? '',
  },
  sentry: {
    dns: window.environment?.sentry.dns ?? REACT_APP_SENTRY_DSN ?? '',
  },
};
export const isProductionEnvironment =
  !!environment.currentEnvironment && environment.currentEnvironment == EnvironmentEnum.PROD;
export const isStagingEnvironment =
  !!environment.currentEnvironment && environment.currentEnvironment == EnvironmentEnum.STAGING;
export const isProductionLikeEnvironment = isProductionEnvironment || isStagingEnvironment;
