import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'header' | 'headerContainer' | 'content' | 'login', SxProps<Theme>> = {
  container: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[100],
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: '100%',
    overflowX: 'hidden',
    position: 'relative',
  },
  content: {
    margin: (theme) => ({
      sm: theme.spacing(7.5, 10),
      xs: theme.spacing(4),
    }),
    marginX: 'auto',
    maxWidth: '842px',
    padding: (theme) => ({
      sm: 0,
      xs: theme.spacing(0, 2),
    }),
    width: '100%',
  },
  header: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[100],
    display: 'flex',
    flexDirection: 'row',
    left: 0,
    padding: (theme) => theme.spacing(2),
    placeContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  headerContainer: {
    height: 80,
    position: 'sticky',
    top: 0,
  },
  login: {
    position: 'absolute',
    right: (theme) => theme.spacing(4),
  },
};
