import { ContactFormConfirmationPageContent } from 'components/ContactFormConfirmationPage/ContactFormConfirmationPageContent';

import { BasicLayout } from '../common/layout/onboarding/BasicLayout';

export const ContactFormConfirmationPage: React.FC = () => {
  return (
    <BasicLayout contentCenter>
      <ContactFormConfirmationPageContent />
    </BasicLayout>
  );
};
