import { alpha } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { CATEGORY_COLORS } from 'const';
import { EChartsOption, graphic } from 'echarts';
import { DateKey, TransactionAggregationValueFragment, TransactionCategoryFragment } from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { palette } from 'themes/theme';

import { Period } from '../state/spendingReportTypes';
import { getPeriodSteps, getStepName } from '../utils';

const DEFAULT_CATEGORY_CODE = 'MISCELLANEOUS';

export const useChartOption = (
  aggregates: TransactionAggregationValueFragment[],
  currency: string,
  period: Period,
): EChartsOption => {
  const { i18n } = useTranslation();

  const mappedAggregates = aggregates.map((aggregate) => {
    const categoryKey = aggregate.key.find((key) => key.__typename === 'TransactionCategory') as
      | TransactionCategoryFragment
      | undefined;
    const dateKey = aggregate.key.find((key) => key.__typename === 'DateKey') as DateKey | undefined;

    return {
      ...aggregate,
      category: categoryKey,
      date: dateKey,
    };
  });

  const steps = getPeriodSteps(period);

  const series = _.groupBy(mappedAggregates, (aggregate) => aggregate.category?.categoryGroup.code);
  const seriesWithEmpty = _.mapValues(series, (aggregates) => {
    const dateMap = _.keyBy(aggregates, (aggregate) => aggregate.date?.startDate.toISOString());
    return steps.map((step) => dateMap[step.startDate.toISOString()] ?? { sum: { amount: 0 } });
  }) as typeof series;

  return {
    grid: { bottom: 10, containLabel: true, left: 10, right: 10, top: 10 },
    series: _.toPairs(seriesWithEmpty).map(([categoryCode, categoryAggregates]) => {
      return {
        backgroundStyle: {
          color: palette.gray[100],
        },
        barMaxWidth: 15,
        data: categoryAggregates.map((aggregate) => Math.abs(aggregate.sum.amount)),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              color: CATEGORY_COLORS[categoryCode] ?? CATEGORY_COLORS[DEFAULT_CATEGORY_CODE],
              offset: 0,
            },
            {
              color: alpha(CATEGORY_COLORS[categoryCode] ?? CATEGORY_COLORS[DEFAULT_CATEGORY_CODE], 0.5),
              offset: 1,
            },
          ]),
        },
        name: categoryCode,
        showBackground: true,
        silent: true,
        stack: 'x',
        type: 'bar',
      };
    }),
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: { show: false },
      data: steps.map((step) => {
        return getStepName(step, period);
      }),
      type: 'category',
    },
    yAxis: {
      axisLabel: {
        formatter: (amount: number) => formatTransactionAmount(-amount, i18n.language, 2, currency),
        showMaxLabel: true,
        showMinLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisTick: { show: false },
      position: 'right',
      splitNumber: 1,
      type: 'value',
    },
  };
};
