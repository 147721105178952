import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/Bin.svg';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { TransactionTag, useDeleteTagMutation, useTransactionTagsQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { RenameTagDialog, RenameTagDialogProps } from '../RenameTagDialog';
import { styles } from './ManageTagsDialog.styles';

export type ManageTagsDialogProps = DialogComponentProps<void>;

export const ManageTagsDialog: React.FC<ManageTagsDialogProps> = (props) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const { data } = useTransactionTagsQuery({
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteTag] = useDeleteTagMutation();

  const handleRemoveTag = (tagId: string) => {
    deleteTag({
      onError: () => notify({ message: t('common:somethingWentWrong'), type: 'error' }),
      optimisticResponse: { deleteTransactionTag: tagId },
      update: (cache) => {
        const id = cache.identify({ __typename: 'TransactionTag', id: tagId });
        cache.evict({ id });
        cache.gc();
      },
      updateQueries: {
        TransactionTags: (prev, result) => {
          return {
            tags: {
              ...prev.tags,
              items: prev.tags.items.filter(
                (item: TransactionTag) => item.id !== result.mutationResult.data?.deleteTransactionTag,
              ),
            },
          };
        },
      },
      variables: {
        tagId,
      },
    });
  };

  const renameDialog = useDialog<RenameTagDialogProps, void>(RenameTagDialog, { instanceId: 'rename-tag' });

  const handleRenameTag = (tagId: string) => {
    const name = data?.tags.items.find((tag) => tag.id === tagId)?.name;
    if (name) {
      renameDialog.create({
        name,
        tagId,
      });
    }
  };

  return (
    <ResponsiveSideDialog
      dialogId={props.instanceId}
      header={
        <>
          <Typography sx={styles.title} variant="bodyStrongL">
            {t('manageTagsDialog.header.title')}
          </Typography>
          <Button color="tertiaryButton" onClick={() => props.onResolve()} sx={styles.close}>
            <Typography color="blue.dark" variant="bodyStrongL">
              {t('manageTagsDialog.header.close')}
            </Typography>
          </Button>
        </>
      }
      isOpen={props.isOpen}
      onClose={props.onReject}
    >
      <Stack sx={styles.content}>
        {data?.tags?.items
          .slice()
          .sort((aTag, bTag) => aTag.name.localeCompare(bTag.name) ?? 0)
          .map((tag) => (
            <Box key={tag.id} sx={styles.item}>
              <Stack alignItems="center" direction="row">
                <IconButton
                  color="tertiaryButton"
                  onClick={() => handleRemoveTag(tag.id)}
                  size="medium"
                  sx={styles.delete}
                >
                  <DeleteIcon />
                </IconButton>
                <Typography>{tag.name}</Typography>
              </Stack>
              <IconButton color="tertiaryButton" onClick={() => handleRenameTag(tag.id)} size="medium" sx={styles.edit}>
                <EditIcon />
              </IconButton>
            </Box>
          ))}
      </Stack>
    </ResponsiveSideDialog>
  );
};
