import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'button', SxProps<Theme>> = {
  button: {
    ':hover': {
      cursor: 'pointer',
    },
    alignItems: 'center',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
  },
  container: (theme) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.gray[200],
    borderRadius: '6px',
    display: 'flex',
    flexDirect: 'row',
    gap: theme.spacing(1),
    minHeight: 36,
    padding: theme.spacing(1),
  }),
};
