import InsuranceAvatar from 'assets/images/insurance-avatar.png';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { usePeriodicityOptions } from 'common/hooks/usePeriodicityOptions';
import { formatCurrency, formatIban, formatTransactionAmount, normalizeIban } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import {
  FinancialProductType,
  useLifeInsuranceProductQuery,
  useUpdateLifeInsuranceProductMutation,
} from 'generated/graphql';
import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductDetail } from '../ProductDetail/ProductDetail';
import { QRCodeDialog, QRCodeDialogProps } from '../QRCodeDialog/QRCodeDialog';
import { QRCodeMissingDataDialog, QRCodeMissingDataDialogProps } from '../QRCodeDialog/QRCodeMissingDataDialog';
import { ButtonRowType, DetailRowType, ExpandedListType, List, Section } from '../types';
import { ProductDetailDialogProps } from '../useProductDetail';
import {
  getLifeInsuranceDefaultSupplementalInsurances,
  LifeInsuranceProductDetailType,
  mapLifeInsuranceProductToDetail,
  mapLifeInsuranceProductToInput,
} from './utils';

export const LifeInsuranceProductDetail: React.FC<ProductDetailDialogProps> = ({ id, ...props }) => {
  const { i18n, t } = useTranslation();
  const notify = useNotification();

  const { data } = useLifeInsuranceProductQuery({
    variables: {
      id,
    },
  });

  const qrCodeDialog = useDialog<QRCodeDialogProps, void>(QRCodeDialog, {
    instanceId: 'qr-code-dialog',
    tmpNavigable: true,
  });

  const qrCodeMissingDataDialog = useDialog<QRCodeMissingDataDialogProps, void>(QRCodeMissingDataDialog, {
    instanceId: 'qr-code-missing-data-dialog',
  });

  const handleQRPayment = () => {
    const iban = data?.detail.paymentInfo?.ibanForPayment;
    const amount = data?.detail.paymentPeriod?.expectedAmount?.amount;
    if (iban && amount) {
      qrCodeDialog.create({
        amount,
        constantSymbol: data?.detail.paymentInfo?.constantSymbol ?? undefined,
        currency: data?.detail.paymentPeriod?.expectedAmount?.currency ?? 'EUR',
        iban,
        specificSymbol: data?.detail.paymentInfo?.specificSymbol ?? undefined,
        variableSymbol: data?.detail.paymentInfo?.variableSymbol ?? undefined,
      });
    } else {
      qrCodeMissingDataDialog.create({});
    }
  };

  const periodicityOptions = usePeriodicityOptions();

  const content: (
    | Section<LifeInsuranceProductDetailType, Path<LifeInsuranceProductDetailType>>
    | List<LifeInsuranceProductDetailType, 'applicants'>
    | List<LifeInsuranceProductDetailType, 'insurer'>
  )[] = [
    {
      rows: [
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.institutionName'),
          path: 'institutionName',
          skip: !!data?.detail.institution?.id,
        } as DetailRowType<LifeInsuranceProductDetailType, 'institutionName'>,
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.productName'),
          path: 'name',
        } as DetailRowType<LifeInsuranceProductDetailType, 'name'>,
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.contractNumber'),
          path: 'contractNumber',
        } as DetailRowType<LifeInsuranceProductDetailType, 'contractNumber'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:lifeInsurance.detail.contractSignDate'),
          path: 'contractSignDate',
        } as DetailRowType<LifeInsuranceProductDetailType, 'contractSignDate'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:lifeInsurance.detail.contractEndDate'),
          path: 'contractEndDate',
        } as DetailRowType<LifeInsuranceProductDetailType, 'contractEndDate'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          formatter: (value: number) =>
            formatTransactionAmount(value, i18n.language, 2, data?.detail.paymentPeriod?.expectedAmount?.currency),
          inputEndAdornment: data?.detail.paymentPeriod?.expectedAmount?.currency,
          inputType: 'amount',
          label: t('products:lifeInsurance.detail.instalment'),
          // we are storing paymentPeriod.expectedAmount.amount as negative number
          // but we want user to type a positive equivalent
          parse: (value) => -value,
          path: 'paymentPeriod.expectedAmount.amount',
          transform: (value) => -value,
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentPeriod.expectedAmount.amount'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:lifeInsurance.detail.nextPayment'),
          path: 'paymentPeriod.expectedDate',
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentPeriod.expectedDate'>,
        {
          formatter: (value) =>
            periodicityOptions.find(
              (option) =>
                option.value.periodCount === value.periodCount && option.value.periodTypeCode === value.periodTypeCode,
            )?.label ?? '',
          inputType: 'radioSelect',
          label: t('products:lifeInsurance.detail.periodType'),
          options: periodicityOptions,
          parse: (value) => ({
            expectedAmount: data?.detail.paymentPeriod?.expectedAmount,
            expectedDate: data?.detail.paymentPeriod?.expectedDate,
            periodCount: value.periodCount,
            periodTypeCode: value.periodTypeCode,
          }),
          path: 'paymentPeriod',
          transform: (value) => ({
            periodCount: value.periodCount,
            periodTypeCode: value.periodTypeCode,
          }),
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentPeriod'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          inputType: 'variableSymbol',
          label: t('products:lifeInsurance.detail.variableSymbol'),
          path: 'paymentInfo.variableSymbol',
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentInfo.variableSymbol'>,
        {
          inputType: 'specificSymbol',
          label: t('products:lifeInsurance.detail.specificSymbol'),
          path: 'paymentInfo.specificSymbol',
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentInfo.specificSymbol'>,
        {
          inputType: 'constantSymbol',
          label: t('products:lifeInsurance.detail.constantSymbol'),
          path: 'paymentInfo.constantSymbol',
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentInfo.constantSymbol'>,
        {
          formatter: formatIban,
          inputType: 'iban',
          label: t('products:lifeInsurance.detail.ibanForPayment'),
          parse: normalizeIban,
          path: 'paymentInfo.ibanForPayment',
        } as DetailRowType<LifeInsuranceProductDetailType, 'paymentInfo.ibanForPayment'>,
        {
          color: 'blue.dark',
          label: t('products:lifeInsurance.detail.qrCode'),
          onClick: handleQRPayment,
        } as ButtonRowType,
      ],
      title: t('products:lifeInsurance.detail.paymentInfo.title'),
    },
    {
      addLabel: t('products:lifeInsurance.detail.applicants.addLabel'),
      fields: [
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.applicants.name.label'),
          path: 'name',
        } as DetailRowType<LifeInsuranceProductDetailType['applicants'][0], 'name'>,
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.applicants.surname.label'),
          path: 'surname',
        } as DetailRowType<LifeInsuranceProductDetailType['applicants'][0], 'surname'>,
        {
          addLabel: t('products:lifeInsurance.detail.applicants.insurance.addLabel'),
          fields: [
            {
              inputType: 'text',
              label: t('products:lifeInsurance.detail.applicants.insurance.name.label'),
              path: 'name',
            },
            {
              formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
              inputEndAdornment: t('common:inputEndAdornment.eur'),
              inputType: 'amount',
              label: t('products:lifeInsurance.detail.applicants.insurance.annualCost.label'),
              path: 'annualCost.amount',
            },
            {
              formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
              inputEndAdornment: t('common:inputEndAdornment.eur'),
              inputType: 'amount',
              label: t('products:lifeInsurance.detail.applicants.insurance.insuredSum.label'),
              path: 'insuredSum.amount',
            },
          ],
          labelPath: 'name',
          path: 'insurance.supplementalInsurances',
          removeLabel: t('products:lifeInsurance.detail.applicants.insurance.removeLabel'),
        } as ExpandedListType<LifeInsuranceProductDetailType['applicants'][0], 'insurance.supplementalInsurances'>,
      ],
      formatter: (value) => (value.name || value.surname ? `${value.name} ${value.surname}` : '-'),
      label: t('products:lifeInsurance.detail.applicants.label'),
      path: 'applicants',
      removeLabel: t('products:lifeInsurance.detail.applicants.removeLabel'),
      title: t('products:lifeInsurance.detail.applicants.title'),
    },
    {
      addLabel: t('products:lifeInsurance.detail.insurer.addLabel'),
      fields: [
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.insurer.name.label'),
          path: 'name',
        },
        {
          inputType: 'text',
          label: t('products:lifeInsurance.detail.insurer.surname.label'),
          path: 'surname',
        },
      ],
      formatter: (value) => (value.name || value.surname ? `${value.name} ${value.surname}` : '-'),
      label: t('products:lifeInsurance.detail.insurer.label'),
      maxLength: 1,
      path: 'insurer',
      removeLabel: t('products:lifeInsurance.detail.insurer.removeLabel'),
      title: t('products:lifeInsurance.detail.insurer.title'),
    },
  ];

  const [updateProduct] = useUpdateLifeInsuranceProductMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const handleUpdate = async (value: LifeInsuranceProductDetailType) => {
    // add default supplemental insurances for new applicants
    value.applicants = value.applicants?.map((applicant) => {
      applicant.insurance = applicant.insurance ?? {
        supplementalInsurances: getLifeInsuranceDefaultSupplementalInsurances(t).map((supplementalInsurance) => ({
          annualCost: {
            amount: 0,
            currency: 'EUR',
          },
          insuredSum: {
            amount: 0,
            currency: 'EUR',
          },
          name: supplementalInsurance,
        })),
      };
      return applicant;
    });

    await updateProduct({ variables: { id, updatedProduct: mapLifeInsuranceProductToInput(value) } });
  };

  return (
    <ProductDetail<LifeInsuranceProductDetailType>
      {...props}
      contactExpert={{
        avatarSrc: InsuranceAvatar,
        email: t('products:contacts.lifeInsurance.email'),
        jobTitle: t('products:contacts.lifeInsurance.jobTitle'),
        name: t('products:contacts.lifeInsurance.name'),
        phone: t('products:contacts.lifeInsurance.phone'),
      }}
      content={content}
      data={mapLifeInsuranceProductToDetail(data?.detail)}
      institution={data?.detail.institution ?? undefined}
      onUpdate={handleUpdate}
      title={t('products:lifeInsurance.detail.title')}
      type={FinancialProductType.LifeInsurance}
    />
  );
};
