import { makeVar, ReactiveVar } from '@apollo/client';
import {
  mapLifeInsuranceInquiryToState,
  mapLifeInsuranceStateToInput,
  trimLifeInsuranceState,
} from 'components/flows/common/lifeInsurance/state/lifeInsuranceState';
import { LifeInsuranceInquiryFragment, LifeInsuranceInquiryInput } from 'generated/graphql';

import { LifeInsurancePurchaseState } from './lifeInsurancePurchaseTypes';

const initialValue: LifeInsurancePurchaseState = {};
export const lifeInsurancePurchaseState: ReactiveVar<LifeInsurancePurchaseState> =
  makeVar<LifeInsurancePurchaseState>(initialValue);

export const mapLifeInsurancePurchaseStateToInput = (state: LifeInsurancePurchaseState): LifeInsuranceInquiryInput => {
  return {
    ...mapLifeInsuranceStateToInput({ applicants: state.applicants, meta: state.meta }),
  };
};

export const mapLifeInsurancePurchaseInquiryToState = (
  inquiry?: LifeInsuranceInquiryFragment | null,
  updateTimestamp?: Date | null,
): LifeInsurancePurchaseState => {
  return {
    ...mapLifeInsuranceInquiryToState(inquiry, updateTimestamp),
  };
};

export const trimLifeInsurancePurchaseState = (state: LifeInsurancePurchaseState): LifeInsurancePurchaseState => {
  return {
    ...trimLifeInsuranceState(state),
  };
};
