import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';

import { styles } from './FlowSwitcherCard.styles';

interface Props {
  title: string;
  icon: React.ReactNode;
  description?: string;
  onClick: () => void;
}

export const FlowSwitcherCard: React.FC<Props> = ({ description, icon, onClick, title }) => {
  return (
    <Box onClick={onClick} sx={styles.container}>
      <Box sx={styles.chevron}>
        <ChevronRightIcon />
      </Box>

      <Stack sx={styles.listItem}>
        <Box sx={styles.icon}>{icon}</Box>
        <Stack pr={3}>
          <Typography component="div" variant="bodyStrongL">
            {title}
          </Typography>
          <Typography color="textSecondary" component="div" variant="bodyM">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
