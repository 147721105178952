import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactNode } from 'react';

import { styles } from './TransactionDetailBox.styles';

interface Props {
  title: string;
  children?: ReactNode;
  image?: ReactNode;
  loading?: boolean;
  onClick?: () => void;
}

export const TransactionDetailBox: React.FC<Props> = (props) => {
  if (props.loading) {
    return <Skeleton height={80} variant="rectangular" />;
  }

  const sx = { ...styles.box, ...(props.onClick ? styles.hover : {}) };

  return (
    <Box onClick={props.onClick} sx={sx}>
      <Stack direction="row" spacing={2} sx={styles.fullWidth}>
        {props.image}
        <Stack sx={styles.fullWidth}>
          <Stack direction="row" justifyContent="space-between" sx={styles.fullWidth}>
            <Typography variant="bodyStrongL">{props.title}</Typography>
            {!!props.onClick && <ChevronRightIcon />}
          </Stack>
          {props.children}
        </Stack>
      </Stack>
    </Box>
  );
};
