import { Button, Stack, Typography } from '@mui/material';
import { ReactComponent as NoLifeInsuranceScoreIcon } from 'assets/icons/NoLifeInsuranceScore.svg';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { LifeInsuranceScoreIcon } from 'components/calculators/lifeInsurance/LifeInsuranceScoreIcon/LifeInsuranceScoreIcon';
import { LIFE_INSURANCE_CALCULATOR_BASE_URL } from 'const';
import { SortDirection, useLifeInsuranceCalculatorCalculationsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { styles } from './CalculatorCards.styles';

export const LifeInsuranceCalculatorCard: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data } = useLifeInsuranceCalculatorCalculationsQuery({
    fetchPolicy: 'network-only',
    skip: !isAuthenticated,
    variables: {
      // fetch only latest calculation
      pageInput: {
        pageIndex: 0,
        pageSize: 1,
      },
      sortInput: {
        by: [{ direction: SortDirection.Desc, fieldPath: 'creationTimestamp' }],
      },
    },
  });
  const lifeInsuranceCalculationsCount = data?.calculations.totalItems ?? 0;
  const buttonLabel =
    lifeInsuranceCalculationsCount == 0
      ? t('homepage.products.calculatorCards.button.calculate')
      : t('homepage.products.calculatorCards.button.recalculate');

  const handleLifeInsuranceCalculatorClick = () => {
    navigate(LIFE_INSURANCE_CALCULATOR_BASE_URL);
  };

  return (
    <Stack gap={2} justifyContent="space-between" sx={styles.card}>
      <Stack alignItems="top" direction="row" justifyContent="space-between">
        {lifeInsuranceCalculationsCount == 0 ? (
          <NoLifeInsuranceScoreIcon />
        ) : (
          <LifeInsuranceScoreIcon
            maximalScore={data?.calculations.items[0].result.maximalScore ?? 10}
            score={data?.calculations.items[0].result.score ?? 0}
            size="small"
          />
        )}

        <Button
          aria-label={buttonLabel}
          color="tertiaryButton"
          onClick={handleLifeInsuranceCalculatorClick}
          size="medium"
          sx={styles.button}
        >
          <Typography color="blue.dark" variant="bodyL">
            {buttonLabel}
          </Typography>
        </Button>
      </Stack>

      <Stack>
        <Typography variant="bodyStrongXXL">
          {t('homepage.products.calculatorCards.lifeInsuranceCalculator.title')}
        </Typography>
        <Typography color="textSecondary" variant="bodyM">
          {t('homepage.products.calculatorCards.lifeInsuranceCalculator.subtitle')}
        </Typography>
      </Stack>
    </Stack>
  );
};
