import { Route } from 'react-router-dom';
import { SentryRoutes } from 'sentry';

import { LifeInsuranceCalculatorInputPage } from './LifeInsuranceCalculatorInputPage';
import { LifeInsuranceCalculatorResultPage } from './LifeInsuranceCalculatorResultPage';

type Props = Record<string, never>;

export const LifeInsuranceCalculatorRouter: React.FC<Props> = () => {
  return (
    <SentryRoutes>
      <Route key="landing" element={<LifeInsuranceCalculatorInputPage />} path="/" />
      <Route element={<LifeInsuranceCalculatorResultPage />} path="/result" />
    </SentryRoutes>
  );
};

// eslint-disable-next-line import/no-default-export
export default LifeInsuranceCalculatorRouter;
