import { Box } from '@mui/material';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';

import { Row } from '../../components/Row/Row';
import { StyledCheckbox } from './FormCheckBox.styles';
import { FormElementProps } from './FormElement';

interface FormCheckBoxProps extends FormElementProps {
  children?: ReactNode;
  autoComplete?: never;
  size?: 'large' | 'medium';
}

export const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  autoFocus,
  children,
  name,
  onBlur,
  onChange,
  size = 'medium',
  sx,
}) => {
  const { field, fieldState } = useController({ name });

  return (
    <Box>
      <Row>
        <StyledCheckbox
          autoFocus={autoFocus}
          checked={!!field.value}
          error={!!fieldState?.error?.message}
          onBlur={() => {
            field.onBlur();
            onBlur?.();
          }}
          onChange={(event) => {
            field.onChange(event);
            onChange?.();
          }}
          sx={sx}
        />
        {children}
      </Row>
      {!!fieldState?.error?.message && (
        <FormHelperText error size={size}>
          {fieldState.error.message}
        </FormHelperText>
      )}
    </Box>
  );
};
