import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { FilterConditionInput, FilterOperator, SortDirection, SortExpressionInput } from 'generated/graphql';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPill } from '../FilterTransactions/FilterPill/FilterPill';
import { FilterTransactions, FilterTransactionsProps } from '../FilterTransactions/FilterTransactions';
import { FilterState } from '../FilterTransactions/FilterTransactions.utils';
import { SortOption } from '../SortOption/SortOption';
import { TransactionList } from '../TransactionList/TransactionList';
import { styles } from './AllTransactionsContent.styles';

export interface AllTransactionsContentProps extends DialogComponentProps<void> {
  filter?: FilterConditionInput[];
}

export const AllTransactionsContent: React.FC<AllTransactionsContentProps> = ({ filter }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [filterState, setFilterState] = useState<FilterState>({});
  const [sort, setSort] = useState<'date' | 'amount'>('date');

  const sortInput: SortExpressionInput[] =
    sort === 'date'
      ? [{ direction: SortDirection.Desc, fieldPath: 'bookingDate' }]
      : [{ direction: SortDirection.Desc, fieldPath: 'absBaseAmount' }];

  const allFilters = [
    { arguments: filter?.map((condition) => ({ filterCondition: condition })) },
    ..._.flatten(Object.values(filterState)).filter((value) => !!value),
  ];
  const filterWithSearch = !!search
    ? allFilters.concat({
        arguments: [{ filterCondition: { operator: FilterOperator.FulltextSearch, values: [search] } }],
      })
    : allFilters;

  const handleSearchChange = _.debounce(setSearch, 250);

  const filterDialog = useDialog<FilterTransactionsProps, FilterState>(FilterTransactions, {
    instanceId: 'filter-transactions',
    tmpNavigable: true,
  });

  const handleOpenFilter: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.currentTarget?.blur();
    const newFilter = await filterDialog.create({
      filterState,
    });

    setFilterState(newFilter ?? {});
  };

  const handleRemoveFilter = (key: string) => {
    setFilterState((state) => ({
      ...state,
      [key]: undefined,
    }));
  };

  const hasActiveFilters = _.values(filterState).filter((value) => !_.isEmpty(value)).length !== 0;

  return (
    <Stack height="100%">
      <Stack sx={styles.header}>
        <Stack direction="row" justifyContent="space-between">
          <Typography mb={1} variant="headlineXL">
            {t('allTransactions.content.title')}
          </Typography>
          <SortOption onClick={() => setSort(sort === 'date' ? 'amount' : 'date')} sortBy={sort} />
        </Stack>
        <Box sx={styles.search}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => handleSearchChange(event.target.value)}
            placeholder={t('allTransactions.search.placeholder')}
            variant="filled"
          />
          <IconButton
            color="tertiaryButton"
            onClick={handleOpenFilter}
            sx={hasActiveFilters ? styles.activeFilterButton : styles.filterButton}
          >
            <FilterIcon />
          </IconButton>
        </Box>
        {hasActiveFilters && (
          <Box sx={styles.filters}>
            {_.entries(filterState)
              .filter(([, value]) => !!value)
              .map(
                ([key, value]) =>
                  !_.isEmpty(value.arguments) && (
                    <FilterPill key={key} filter={value} onClick={() => handleRemoveFilter(key)} />
                  ),
              )}
          </Box>
        )}
      </Stack>
      <TransactionList filterInput={filterWithSearch} sortInput={sortInput} />
    </Stack>
  );
};
