import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { useDialog } from 'common/hooks/useDialog';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import {
  DocumentUploadSuccessDialog,
  DocumentUploadSuccessDialogProps,
} from 'components/products/DocumentUploadSuccessDialog/DocumentUploadSuccessDialog';
import {
  ProductWaitingForReviewDialog,
  ProductWaitingForReviewDialogProps,
} from 'components/products/ProductWaitingForReviewDialog/ProductWaitingForReviewDialog';
import { ProductBaseExtended } from 'components/products/types';
import {
  UploadDocumentDialog,
  UploadDocumentDialogProps,
} from 'components/products/UploadDocumentDialog/UploadDocumentDialog';
import { useProductDetail } from 'components/products/useProductDetail';
import { format } from 'date-fns';
import { sk } from 'date-fns/locale';
import { FinancialProductType, ProductStatus } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { styles } from './ViewAllProducts.style';

interface ProductsListItemProps {
  product: ProductBaseExtended;
}

export const ProductsListItem: React.FC<ProductsListItemProps> = ({ product }) => {
  const { i18n, t } = useTranslation();

  const productDetail = useProductDetail(product.type, {
    id: product.id,
    instanceId: `${product.type}-detail-${product.id}`,
    navigable: true,
  });
  const productWaitingForReview = useDialog<ProductWaitingForReviewDialogProps, void>(ProductWaitingForReviewDialog, {
    instanceId: `${product.type}-waiting-for-review-${product.id}`,
    navigable: true,
    product,
  });
  const uploadDocument = useDialog<UploadDocumentDialogProps, void>(UploadDocumentDialog, {
    documentId: product.documents[0]?.id,
    instanceId: `${product.type}-upload-document-dialog-${product.id}`,
    navigable: true,
    productId: product.id,
    type: product.type,
  });
  const documentUploadSuccess = useDialog<DocumentUploadSuccessDialogProps, void>(DocumentUploadSuccessDialog, {
    instanceId: 'document-upload-success',
    navigable: true,
  });

  const handleClick = async () => {
    switch (product.status) {
      case ProductStatus.WaitingForSubmission:
        await uploadDocument.create({
          documentId: product.documents[0].id,
          productId: product.id,
          type: product.type,
        });
        break;

      case ProductStatus.WaitingForReview:
        await productWaitingForReview.create({
          product,
        });
        break;

      case ProductStatus.Submitted:
      case ProductStatus.Processing:
        await documentUploadSuccess.create({});
        break;

      default:
        await productDetail.create({ id: product.id });
        break;
    }
  };

  const formattedDate = format(product.statusChangeDate ?? new Date(), 'd. MMMM', { locale: sk });

  const productStatusString = {
    [ProductStatus.InPreparation]: t('products:seeAllProducts.item.inPreparation.date', {
      date: formattedDate,
    }),
    [ProductStatus.WaitingForSubmission]: t('products:seeAllProducts.item.waitingForSubmission'),
    [ProductStatus.Submitted]: t('products:seeAllProducts.item.submitted'),
    [ProductStatus.Processing]: t('products:seeAllProducts.item.processing'),
    [ProductStatus.WaitingForReview]: t('products:seeAllProducts.item.waitingForReview.date', {
      date: formattedDate,
    }),
    [ProductStatus.Active]: t('products:seeAllProducts.item.active.date', {
      date: formattedDate,
    }),
    [ProductStatus.Archived]: t('products:seeAllProducts.item.archived.date', {
      date: formattedDate,
    }),
    [ProductStatus.Cancelled]: t('products:seeAllProducts.item.cancelled.date', {
      date: formattedDate,
    }),
    [ProductStatus.Deleted]: t('products:seeAllProducts.item.deleted.date', {
      date: formattedDate,
    }),
  }[product.status];

  const expectedPaymentString = product.paymentPeriod?.expectedDate
    ? product.status == ProductStatus.Active
      ? t('products:seeAllProducts.item.activeWithPaymentPeriod.date', {
          date: format(product.paymentPeriod?.expectedDate, 'd. MMMM', { locale: sk }),
        })
      : product.status == ProductStatus.WaitingForReview
      ? t('products:seeAllProducts.item.waitingForReviewWithPaymentPeriod.date', {
          date: format(product.paymentPeriod?.expectedDate, 'd. MMMM yyyy', { locale: sk }),
        })
      : undefined
    : undefined;

  const productTypeTitle = {
    [FinancialProductType.BuildingSavings]: t('products:seeAllProducts.item.product.buildingSavings'),
    [FinancialProductType.CollisionInsurance]: t('products:seeAllProducts.item.product.collisionInsurance'),
    [FinancialProductType.CreditCard]: t('products:seeAllProducts.item.product.creditCard'),
    [FinancialProductType.Generic]: t('products:seeAllProducts.item.product.generic'),
    [FinancialProductType.GenericInsurance]: t('products:seeAllProducts.item.product.genericInsurance'),
    [FinancialProductType.Investment]: t('products:seeAllProducts.item.product.investment'),
    [FinancialProductType.LifeInsurance]: t('products:seeAllProducts.item.product.lifeInsurance'),
    [FinancialProductType.Loan]: t('products:seeAllProducts.item.product.loan'),
    [FinancialProductType.Mortgage]: t('products:seeAllProducts.item.product.mortgage'),
    [FinancialProductType.MotorLiabilityInsurance]: t('products:seeAllProducts.item.product.motorLiabilityInsurance'),
    [FinancialProductType.PropertyInsurance]: t('products:seeAllProducts.item.product.propertyInsurance'),
    [FinancialProductType.RetirementPensionSavings]: t('products:seeAllProducts.item.product.retirementPensionSavings'),
    [FinancialProductType.Savings]: t('products:seeAllProducts.item.product.savings'),
    [FinancialProductType.SupplementaryPensionSavings]: t(
      'products:seeAllProducts.item.product.supplementaryPensionSavings',
    ),
  }[product.type];

  const title = product.name ? `${productTypeTitle} - ${product.name}` : productTypeTitle;

  return (
    <Box onClick={handleClick} sx={styles.card}>
      <Stack alignItems="top" direction="row" justifyContent="space-between" sx={styles.listContent}>
        <Stack direction="row" spacing={2}>
          <InstitutionIcon
            alt={product.institution?.name ?? product.institutionName ?? '?'}
            src={product.institution?.logoUrl ?? undefined}
            title={product.institution?.name ?? product.institutionName ?? '?'}
            width={48}
          />

          <Stack>
            <Typography variant="bodyStrongL">{title}</Typography>
            <Typography color="textSecondary" variant="bodyM">
              {product.institution?.name ?? product.institutionName ?? '?'}
            </Typography>
            <Typography color="textSecondary" variant="bodyM">
              {expectedPaymentString ?? productStatusString}
            </Typography>
          </Stack>
        </Stack>

        <Stack alignItems="flex-end">
          <Stack alignItems="center" direction="row">
            {product.status === ProductStatus.Active && product.paymentPeriod?.expectedAmount && (
              <Typography pr={1} variant="bodyL">
                {formatTransactionAmount(
                  product.paymentPeriod?.expectedAmount?.amount,
                  i18n.language,
                  2,
                  product.paymentPeriod?.expectedAmount?.currency,
                )}
              </Typography>
            )}

            {product.status === ProductStatus.WaitingForReview && (
              <Button
                aria-label={t('products:seeProductsWaitingForReview.item.button')}
                color="tertiaryButton"
                onClick={handleClick}
              >
                <Typography color="blue.dark" sx={{ letterSpacing: '-0.43px' }} variant="bodyL">
                  {t('products:seeProductsWaitingForReview.item.button')}
                </Typography>
              </Button>
            )}

            <ChevronRightIcon />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
