import { SxProps, Theme } from '@mui/material';

export const styles: Record<'buttonLink', SxProps<Theme>> = {
  buttonLink: {
    ':hover': {
      textDecoration: 'underline',
    },
    alignSelf: 'center',
    fontSize: '16px',
    fontWeight: '400',
    marginTop: (theme) => theme.spacing(3),
    textDecoration: 'underline',
    width: 'fit-content',
  },
};
