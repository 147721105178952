import { Grid, SxProps, Theme } from '@mui/material';

import { styles } from './InputField.styles';
import { Input, InputFieldBase } from './InputFieldBase';

interface Props<T> {
  field: Input<T>;
  basePath?: string;
  sx?: SxProps<Theme>;
  autoFocus?: boolean;
  id?: string;
}

export const InputField = <T = Record<string, string | number>,>({
  autoFocus,
  basePath,
  field,
  id,
  sx,
}: Props<T>): React.ReactElement => (
  <Grid id={id} item sx={styles.gridContainer} xs={12}>
    <InputFieldBase autoFocus={autoFocus} basePath={basePath} field={field} sx={sx} />
  </Grid>
);
