import { useReactiveVar } from '@apollo/client';
import { Loading } from 'common/components/Loading/Loading';
import { useNotification } from 'common/hooks/useNotification';
import { CalculatorLayout } from 'components/calculators/CalculatorLayout/CalculatorLayout';
import { mapValuesToInquiry, mortgageCalculatorState } from 'components/calculators/mortgage/MortgageCalculator.utils';
import { MortgageCalculatorNoResult } from 'components/calculators/mortgage/MortgageCalculatorResult/MortgageCalculatorNoResult';
import { MortgageCalculatorResult } from 'components/calculators/mortgage/MortgageCalculatorResult/MortgageCalculatorResult';
import { MORTGAGE_CALCULATOR_BASE_URL } from 'const';
import { MortgageCalculatorResultQuery, useMortgageCalculatorResultQuery } from 'generated/graphql';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const MortgageCalculatorResultPage: React.FC = () => {
  const { t } = useTranslation();
  const state = useReactiveVar(mortgageCalculatorState);
  const notify = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (_.isEmpty(state)) {
      navigate(MORTGAGE_CALCULATOR_BASE_URL, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState<MortgageCalculatorResultQuery>();

  useMortgageCalculatorResultQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (resp) => {
      setData(resp);
    },
    onError: () => {
      navigate(MORTGAGE_CALCULATOR_BASE_URL, { replace: true });
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    skip: !!data,
    variables: {
      inquiry: mapValuesToInquiry(state),
    },
  });

  if (!data) {
    return <Loading messages={[]} />;
  }

  return (
    <CalculatorLayout fullWidth>
      {!!data.result.maxMortgageAmount && data.result.maxMortgageAmount.amount > 0 ? (
        <MortgageCalculatorResult amount={data.result.maxMortgageAmount} />
      ) : (
        <MortgageCalculatorNoResult />
      )}
    </CalculatorLayout>
  );
};
