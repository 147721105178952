import { SxProps, Theme } from '@mui/material';

export const styles: Record<'gridContainer' | 'gridItem' | 'form', SxProps<Theme>> = {
  form: {
    width: '100%',
  },
  gridContainer: {
    columnGap: {
      lg: 5,
      md: 4,
      sm: 3,
      xl: 6,
      xxs: 0,
    },
    display: 'grid',
    gridTemplateColumns: 'repeat(6,1fr)',
    gridTemplateRows: 'auto 1fr',
  },
  gridItem: {
    gridColumn: { sm: '1/5', xxs: '1/7' },
  },
};
