import { AccountListItem } from 'common/components/AccountListItem/AccountListItem';
import { useDialog } from 'common/hooks/useDialog';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { Account, TransactionAggregationValueFragment } from 'generated/graphql';
import { i18n } from 'i18n';

import { SpendingReportDialog, SpendingReportDialogProps } from '../SpendingReportDialog';
import { PeriodTypeEnum } from '../state/spendingReportTypes';
import { useSpendingReportState } from '../state/useSpendingReportState';
import { formatPeriodTitle, getPeriod } from '../utils';

export interface AccountListItemWrapperProps {
  aggregate: TransactionAggregationValueFragment;
  pointerDate: Date;
  categoryId?: string;
  tagId?: string;
  lockedPeriodType?: PeriodTypeEnum;
}

export const AccountListItemWrapper: React.FC<AccountListItemWrapperProps> = (props) => {
  const { aggregate, categoryId, pointerDate, tagId } = props;
  const { state } = useSpendingReportState();
  const periodType = props.lockedPeriodType ?? state.params.periodType;

  const accountKey = aggregate.key.find((key) => key.__typename === 'Account') as Account;

  const period = getPeriod(periodType, pointerDate);

  const detailDialog = useDialog<SpendingReportDialogProps, void>(SpendingReportDialog, {
    accountId: accountKey.id,
    categoryId: categoryId,
    chartTitle: formatPeriodTitle(period, pointerDate),
    instanceId: `spending-report-detail-${accountKey.id}-${categoryId ?? ''}-${tagId ?? ''}`,
    lockedPeriodType: props.lockedPeriodType,
    navigable: true,
    pointerDate,
    tagId: tagId,
  });

  const handleClick = () => {
    detailDialog.create({});
  };

  return (
    <AccountListItem
      accountKey={accountKey}
      amount={formatTransactionAmount(aggregate.sum.amount, i18n.language, 2, aggregate.sum.currency)}
      amountColor={aggregate.sum.amount > 0 ? 'success.main' : undefined}
      handleClick={handleClick}
      label={accountKey.name}
    />
  );
};
