import { SxProps, Theme } from '@mui/material';

export const styles: Record<'root' | 'dialog', SxProps<Theme>> = {
  dialog: {
    backgroundColor: (theme) => theme.palette.primary[200],
    bottom: (theme) => theme.spacing(4),
    left: (theme) => ({
      sm: theme.spacing(4),
      xxs: 0,
    }),
    margin: 0,
    padding: (theme) => theme.spacing(4),
    position: 'fixed',
    width: {
      sm: 452,
      xxs: '100%',
    },
  },
  root: {
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    top: 'unset',
  },
};
