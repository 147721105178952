import { SxProps, Theme } from '@mui/material';

export const styles: Record<'addButton', SxProps<Theme>> = {
  addButton: {
    position: { sm: 'absolute' },
    right: (theme) => ({
      sm: theme.spacing(1),
      xxs: theme.spacing(2),
    }),
  },
};
