import { Dialog, DialogProps, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './StyledDialog.styles';

interface StyledDialogProps extends DialogProps {
  children?: ReactNode;
  displayAsPage?: boolean;
}

export const StyledDialog: React.FC<StyledDialogProps> = ({ children, displayAsPage, onClose, ...dialogProps }) => {
  const { t } = useTranslation();

  const asPageDialogProps = {
    disableEnforceFocus: true,
    hideBackdrop: true,
    sx: {
      zIndex: 0,
    },
  };

  const currentOnClose = displayAsPage ? undefined : onClose;

  return (
    <Dialog
      PaperProps={{ sx: styles.paper }}
      {...dialogProps}
      {...(displayAsPage && asPageDialogProps)}
      onClose={currentOnClose}
    >
      {!!currentOnClose && (
        <IconButton
          aria-label={t('common:close')}
          color="tertiaryButton"
          onClick={(e) => currentOnClose(e, 'backdropClick')}
          size="medium"
          sx={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};
