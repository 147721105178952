import { useLoadingIndicator } from 'common/hooks/useLoadingIndicator';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import {
  LIFE_INSURANCE_AUDIT_BASE_URL,
  LIFE_INSURANCE_PURCHASE_BASE_URL,
  MORTGAGE_PURCHASE_BASE_URL,
  MORTGAGE_REFINANCE_BASE_URL,
  ProductFlows,
} from 'const';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useSaveLocalLifeInsuranceAuditState } from './lifeInsuranceAudit/state/useSaveLocalLifeInsuranceAuditState';
import { useSaveLocalLifeInsurancePurchaseState } from './lifeInsurancePurchase/state/useSaveLocalLifeInsurancePurchaseState';
import { useSaveLocalPurchaseState } from './mortgagePurchase/state/useSaveLocalPurchaseState';
import { useSaveLocalRefinanceState } from './mortgageRefinance/state/useSaveLocalRefinanceState';

export const SAVE_LOCAL_STATE_STORAGE_KEY = 'saveLocalState';

export const useSaveLocalFlowStates = (): void => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  const { end: endLoading, start: startLoading } = useLoadingIndicator(t('localState.save.loading'));

  const navigate = useNavigate();
  const saveLocalPurchaseState = useSaveLocalPurchaseState();
  const saveLocalRefinanceState = useSaveLocalRefinanceState();
  const saveLocalLifeInsuranceState = useSaveLocalLifeInsurancePurchaseState();
  const saveLocalLifeInsuranceAuditState = useSaveLocalLifeInsuranceAuditState();

  const saveMap: Record<ProductFlows, () => Promise<string | undefined>> = {
    [ProductFlows.MortgagePurchase]: saveLocalPurchaseState,
    [ProductFlows.MortgageRefinance]: saveLocalRefinanceState,
    [ProductFlows.LifeInsuranceAudit]: saveLocalLifeInsuranceAuditState,
    [ProductFlows.LifeInsurancePurchase]: saveLocalLifeInsuranceState,
  };

  const navigateMap: Record<ProductFlows, string> = {
    [ProductFlows.MortgagePurchase]: MORTGAGE_PURCHASE_BASE_URL,
    [ProductFlows.MortgageRefinance]: MORTGAGE_REFINANCE_BASE_URL,
    [ProductFlows.LifeInsuranceAudit]: LIFE_INSURANCE_AUDIT_BASE_URL,
    [ProductFlows.LifeInsurancePurchase]: LIFE_INSURANCE_PURCHASE_BASE_URL,
  };

  useEffect(() => {
    if (isAuthenticated) {
      const saveLocalState = (
        searchParams.get('saveLocalState') ?? localStorage.getItem(SAVE_LOCAL_STATE_STORAGE_KEY)
      )?.split(',') as ProductFlows[];

      if (saveLocalState?.length) {
        startLoading();
        Promise.all(saveLocalState.map((process) => saveMap[process]()))
          .then((processIds) => {
            endLoading();
            localStorage.removeItem(SAVE_LOCAL_STATE_STORAGE_KEY);
            if (processIds.filter((id) => !!id).length === 1) {
              const activeProcessIndex = processIds.findIndex((id) => !!id);
              navigate(
                `${navigateMap[saveLocalState[activeProcessIndex]]}/${processIds[activeProcessIndex]}/current-step`,
              );
            } else {
              searchParams.delete('saveLocalState');
              setSearchParams(searchParams, { replace: true });
            }
          })
          .catch(() => {
            localStorage.removeItem(SAVE_LOCAL_STATE_STORAGE_KEY);
            searchParams.delete('saveLocalState');
            setSearchParams(searchParams, { replace: true });
            endLoading();
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isAuthenticated]);
};
