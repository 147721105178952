import { formatCurrency } from 'common/utils/formatUtils';
import { endOfMonth, format } from 'date-fns';
import { EChartsOption, graphic } from 'echarts';
import { DateKey } from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { palette } from 'themes/theme';

import { useCashflowState } from '../useCashflowState';
import { getColorForLeft2Spend } from '../utils';

export const useChartOptions = (date: Date): EChartsOption => {
  const { i18n } = useTranslation();
  const cashflowState = useCashflowState(date);
  const currency = cashflowState.budget?.currency;

  const realizedExpensesSeries = cashflowState.realizedExpensesAggregates
    .map((aggregate) => {
      const dateKey = aggregate.key.find((key) => key.__typename === 'DateKey') as DateKey | undefined;
      return [format(dateKey?.startDate ?? date, 'd'), -aggregate.sum.amount];
    })
    .sort(([dayA], [dayB]) => Number(dayA) - Number(dayB)) as [string, number][];

  const stackedRealizedExpenses = _.reduce(
    realizedExpensesSeries,
    (acc, value) => [...acc, [value[0], value[1] + (_.last(acc)?.[1] ?? 0)] as [string, number]],
    [] as [string, number][],
  );

  const upcomingPaymentsSeries = cashflowState.upcomingPaymentsAggregates
    .map((aggregate) => {
      const dateKey = aggregate.key.find((key) => key.__typename === 'DateKey') as DateKey | undefined;
      return [format(dateKey?.startDate ?? endOfMonth(date), 'd'), -aggregate.sum.amount];
    })
    .sort(([dayA], [dayB]) => Number(dayA) - Number(dayB)) as [string, number][];

  const stackedUpcomingExpenses = _.reduce(
    [_.last(stackedRealizedExpenses) ?? [0, 0]].concat(upcomingPaymentsSeries),
    (acc, value) => [...acc, [value[0], value[1] + (_.last(acc)?.[1] ?? 0)] as [string, number]],
    [] as [string, number][],
  );

  const endDay = format(endOfMonth(date), 'd');

  const totalExpenses = Math.abs(
    cashflowState.sumOfRealizedExpenses.amount + cashflowState.sumOfUpcomingExpenses.amount,
  );

  if (_.last(stackedUpcomingExpenses)?.[0] !== endDay) {
    stackedUpcomingExpenses.push([endDay, totalExpenses]);
  }

  const maxYAxis = Math.max(totalExpenses, cashflowState.budget?.amount ?? 0);

  const color = getColorForLeft2Spend(cashflowState.leftToSpend.amount, cashflowState.budget?.amount);

  return {
    grid: {
      bottom: 5,
      containLabel: true,
      left: 5,
      right: 5,
      top: 15,
    },
    series: [
      {
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              color: color[600] ?? '',
              offset: 0,
            },
            {
              color: palette.gray[50],
              offset: 1,
            },
          ]),
        },
        data: [[0, 0], ...stackedRealizedExpenses],
        lineStyle: {
          color: color[800],
          width: 2,
        },
        markLine: {
          data: [
            [
              { value: cashflowState.budget?.amount, xAxis: 1, yAxis: cashflowState.budget?.amount },
              { xAxis: endDay, yAxis: cashflowState.budget?.amount },
            ],
          ],
          label: {
            color: palette.gray[600],
            distance: 8,
            formatter: (data) => formatCurrency(Number(data.value), i18n.language, 0, currency),
          },
          lineStyle: {
            color: palette.gray[600],
            type: 'dashed',
          },
          silent: true,
          symbol: 'none',
        },
        silent: true,
        smooth: false,
        symbol: 'none',
        type: 'line',
      },
      {
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              color: palette.gray[50],
              offset: 0,
            },
            {
              color: palette.gray[200],
              offset: 1,
            },
          ]),
        },
        data: stackedUpcomingExpenses,
        lineStyle: {
          color: palette.gray[300],
          type: 'dashed',
          width: 2,
        },
        silent: true,
        smooth: false,
        symbol: 'none',
        type: 'line',
      },
    ],
    xAxis: {
      axisLabel: {
        showMinLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisTick: { show: false },
      interval: 5,
      max: endDay,
      min: 1,
      splitLine: {
        show: false,
      },
      type: 'value',
    },
    yAxis: {
      axisLabel: {
        formatter: (value: number) => {
          return formatCurrency(value, i18n.language, 0, currency);
        },
        showMaxLabel: true,
        showMinLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisTick: { show: false },
      boundaryGap: [10, 10],
      interval: 1000,
      max: maxYAxis,
      min: 0,
      position: 'right',
      splitLine: {
        show: false,
      },
      splitNumber: 1,
      type: 'value',
    },
  };
};
