import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as AddAccountIcon } from 'assets/icons/AddAccountIcon.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/Money.svg';
import { ReactComponent as TrendingUpIcon } from 'assets/icons/TrendingUpGray.svg';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import {
  AccountsProductTour,
  AccountsProductTourProps,
} from 'components/accounts/AccountsProductTour/AccountsProductTourDialog';
import { useAddAccount } from 'components/accounts/AddAccountDialog/useAddAccount';
import {
  DataProtectionGuaranteesDialog,
  DataProtectionGuaranteesDialogProps,
} from 'components/accounts/DataProtectionGuarantees/DataProtectionGuaranteesDialog';
import { Banner } from 'components/HomePage/Banner/Banner';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConnectAccountsImageLarge from '../../../assets/images/connect-account-banner.svg';
import { styles } from './AddAccountBanner.styles';

export const AddAccountBanner: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const addAccount = useAddAccount();

  const handleAddAccount = async () => {
    // workaround: as this banner is also shown to unregistered client, we don't want to use
    // addAccount directly for them but redirect to login instead
    isAuthenticated ? addAccount() : navigate('/login?select-bank=open');
  };

  const handleTourFinish = async () => {
    // similar workaround as above
    isAuthenticated ? addAccount() : navigate('/login?select-bank=open');
  };

  const accountsProductTourDialog = useDialog<AccountsProductTourProps, void>(AccountsProductTour, {
    instanceId: 'accounts-product-tour',
    navigable: true,
    onFinish: handleTourFinish,
  });

  const handleTourClick = async () => {
    await accountsProductTourDialog.create({});
  };

  const dataProtectionDialog = useDialog<DataProtectionGuaranteesDialogProps, void>(DataProtectionGuaranteesDialog, {
    instanceId: 'data-protection-dialog',
  });

  const handleDataProtectionClick = () => {
    dataProtectionDialog.create({});
  };

  return (
    <Stack spacing={8}>
      <Stack alignItems="center" textAlign="center">
        <Typography component="div" sx={styles.headline}>
          {t('homepage.accounts.addAccountBanner.header.title')}
        </Typography>
        <Box mb={5}>
          <Typography textAlign="center" variant="bodyStrongL">
            {t('homepage.accounts.addAccountBanner.header.subtitle')}
            <Box onClick={handleDataProtectionClick}>
              <Typography color="blue.dark" sx={styles.link} variant="bodyStrongL">
                {t('homepage.accounts.addAccountBanner.dataProtection')}
              </Typography>
            </Box>
          </Typography>
        </Box>
        <Stack alignItems="center" justifyContent="center" mb={5} sx={styles.benefits}>
          <Typography color="textSecondary" variant="bodyM">
            {t('homepage.accounts.addAccountBanner.benefits.1')}
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Stack direction="row" spacing={1}>
              <TrendingUpIcon />
              <Typography color="textSecondary" variant="bodyM">
                {t('homepage.accounts.addAccountBanner.benefits.2')}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <MoneyIcon />
              <Typography color="textSecondary" variant="bodyM">
                {t('homepage.accounts.addAccountBanner.benefits.3')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button onClick={handleAddAccount} size="large">
          {t('homepage.accounts.addAccountBanner.connectButton')}
        </Button>
      </Stack>
      <Banner
        Icon={<AddAccountIcon />}
        imageSrc={ConnectAccountsImageLarge}
        onTourClick={handleTourClick}
        text={t('homepage.accounts.addAccountBanner.text')}
        title={t('homepage.accounts.addAccountBanner.title')}
        tour={t('homepage.accounts.addAccountBanner.tour')}
      />
    </Stack>
  );
};
