import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { getScrollParent } from 'components/transactions/TransactionList/WindowScroller';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './ProductTourDialog.styles';

export interface ProductTourBaseProps extends DialogComponentProps<void> {
  onFinish?: () => void;
  steps: TourStep[];
  finishButtonText: string;
  finishLoading?: boolean;
  hideFinishButton?: boolean;
}

export interface TourStep {
  content: ReactNode;
  nextButtonText?: string;
  backButtonText?: string;
}

export interface ProductTourContentProps extends ProductTourBaseProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const ProductTourContent: React.FC<ProductTourContentProps> = ({
  activeStep,
  finishButtonText,
  finishLoading,
  hideFinishButton = false,
  onFinish,
  onResolve,
  setActiveStep,
  steps,
}) => {
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    getScrollParent(contentRef.current)?.scrollTo(0, 0);
  }, [activeStep]);

  const handleFinish = async () => {
    await onResolve();
    onFinish?.();
  };

  return (
    <Stack alignItems="center" ref={contentRef} textAlign="center">
      <Stack alignItems="center" mb={1}>
        <Typography color="textSecondary" variant="bodyM">
          {`${activeStep + 1} z ${steps.length}`}
        </Typography>
      </Stack>

      {steps[activeStep].content}

      <Stack mt={hideFinishButton && activeStep === steps.length - 1 ? 1 : 8} spacing={3} width="100%">
        {activeStep === steps.length - 1 && !hideFinishButton && (
          <LoadingButton color="primary" fullWidth loading={finishLoading} onClick={handleFinish} size="large">
            <Typography color="white" variant="bodyL">
              {finishButtonText}
            </Typography>
          </LoadingButton>
        )}
        {activeStep < steps.length - 1 && (
          <Button color="primary" fullWidth onClick={() => setActiveStep(activeStep + 1)} size="large">
            <Typography color="white" variant="bodyL">
              {steps[activeStep].nextButtonText ?? t('homepage.productTour.next')}
            </Typography>
          </Button>
        )}
        {activeStep > 0 && (
          <Button color="tertiaryButton" fullWidth onClick={() => setActiveStep(activeStep - 1)} size="large">
            <Typography color="blue.dark" variant="bodyL">
              {steps[activeStep].backButtonText ?? t('homepage.productTour.back')}
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export type ProductTourDialogProps = ProductTourBaseProps;

export const ProductTourDialog: React.FC<ProductTourDialogProps> = (props) => {
  const [step, setStep] = useState(0);
  const { t } = useTranslation();

  const handleSkip = async () => {
    await props.onResolve();
    props.onFinish?.();
  };

  return (
    <ResponsiveSideDialog
      dialogId={props.instanceId}
      header={
        <Button color="tertiaryButton" onClick={handleSkip} sx={styles.skipButton}>
          <Typography color="blue.dark">{t('homepage.productTour.skip')}</Typography>
        </Button>
      }
      isOpen={props.isOpen}
      onClose={props.onReject}
    >
      <ProductTourContent {...props} activeStep={step} setActiveStep={setStep} />
    </ResponsiveSideDialog>
  );
};
