import { AuthorizedOnly } from 'common/components/AuthorizedOnly/AuthorizedOnly';
import { HOMEPAGE_TABS } from 'components/HomePage/HomepageTabs/HomepageTabs';
import { SettingsPageRemovedUser } from 'components/SettingsPage/SettingsPageRemovedUser';
import {
  ACCOUNTS_MANAGEMENT_BASE_URL,
  ACCOUNTS_MANAGEMENT_URL_ALIASES,
  LOGIN_URL,
  LOGIN_URL_ALIASES,
  PRODUCTS_MANAGEMENT_BASE_URL,
  PRODUCTS_MANAGEMENT_URL_ALIASES,
  REGISTER_URL,
  REGISTER_URL_ALIASES,
} from 'const';
import { AdminPage } from 'pages/AdminPage';
import { ContactFormConfirmationPage } from 'pages/ContactFormConfirmationPage';
import { HomePage } from 'pages/HomePage';
import { LoginPage } from 'pages/LoginPage';
import { LogoutPage } from 'pages/LogoutPage';
import { MaintenancePage } from 'pages/MaintenancePage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { RegistrationPage } from 'pages/RegistrationPage';
import { RenewNordigenConsentPage } from 'pages/RenewNordigenConsentPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { SettingsPage } from 'pages/SettingsPage';
import { VerifyEmailChangePage } from 'pages/VerifyEmailChangePage';
import { VerifyEmailPage } from 'pages/VerifyEmailPage';
import { Navigate, RouteObject, To } from 'react-router-dom';

interface Props {
  appendSearchParams: (path: string) => To;
}

export const routes = ({ appendSearchParams }: Props): RouteObject[] => [
  { element: <HomePage />, index: true },
  { element: <NotFoundPage />, path: '*' },
  { element: <RegistrationPage />, path: `${REGISTER_URL}/*` },
  ...REGISTER_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(REGISTER_URL)} />,
    key: path,
    path: path,
  })),
  { element: <LoginPage />, path: `${LOGIN_URL}/*` },
  ...LOGIN_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(LOGIN_URL)} />,
    key: path,
    path: path,
  })),
  { element: <LogoutPage />, path: '/logout' },
  { element: <ResetPasswordPage />, path: '/reset-password' },
  { element: <VerifyEmailPage />, path: '/verify-email' },
  { element: <VerifyEmailChangePage />, path: '/verify-change-email' },
  { element: <SettingsPageRemovedUser />, path: '/removed-user' },
  { element: <RenewNordigenConsentPage />, path: '/renew-nordigen-consent' },
  { element: <MaintenancePage />, path: '/maintenance' },
  {
    children: [
      { element: <SettingsPage />, index: true },
      { element: <Navigate replace to="/settings" />, path: '*' },
    ],
    element: <AuthorizedOnly />,
    path: '/settings',
  },
  { element: <ContactFormConfirmationPage />, path: '/confirm-contact-form' },
  { element: <AdminPage />, path: '/admin/*' },
  { element: <HomePage tab={HOMEPAGE_TABS.ACCOUNTS} />, path: `${ACCOUNTS_MANAGEMENT_BASE_URL}/*` },
  ...ACCOUNTS_MANAGEMENT_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(ACCOUNTS_MANAGEMENT_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <HomePage tab={HOMEPAGE_TABS.PRODUCTS} />, path: `${PRODUCTS_MANAGEMENT_BASE_URL}/*` },
  ...PRODUCTS_MANAGEMENT_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(PRODUCTS_MANAGEMENT_BASE_URL)} />,
    key: path,
    path: path,
  })),
];
