import { SxProps, Theme } from '@mui/material';

export const styles: Record<'button', SxProps<Theme>> = {
  button: {
    svg: {
      height: 42,
      width: 42,
    },
  },
};
