import { Box, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { FilterInput } from 'generated/graphql';
import _ from 'lodash';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styles } from './Filter.styles';

export interface FilterOption {
  value?: FilterInput;
  label: string;
  key: string;
}

interface Props {
  options: FilterOption[];
  title: string;
  name: string;
  loading?: boolean;
  children?: React.ReactNode;
  noManual?: boolean;
  onManualClick?: () => void;
}

export const Filter: React.FC<Props> = ({ children, loading, name, noManual, onManualClick, options, title }) => {
  const { t } = useTranslation();
  const { field: quickfilterField } = useController({ name: `${name}.quickfilter` });
  const { field: manualField } = useController({ name: `${name}.manual` });

  const getOptionStyle = (optionValue: FilterInput | null = null) => ({
    ...styles.option,
    ...(_.isEqual(optionValue, quickfilterField.value) ? styles.selected : {}),
  });

  const isManual = manualField.value != null;

  const handleQuickfilterChange = (value?: FilterInput) => {
    quickfilterField.onChange(value);
    manualField.onChange(null);
  };

  return (
    <Stack spacing={1}>
      <Typography variant="headlineM">{title}</Typography>
      {loading ? (
        <Skeleton variant="rectangular" />
      ) : (
        <Stack sx={styles.content}>
          <Box
            key="all"
            onClick={function () {
              quickfilterField.onChange(null);
              manualField.onChange(null);
            }}
            sx={
              {
                ...styles.option,
                ...(!isManual && !quickfilterField.value ? styles.selected : {}),
              } as SxProps<Theme>
            }
          >
            <Typography variant="bodyL">{t('filterTransactions.filter.all.label')}</Typography>
          </Box>
          {!noManual && (
            <Box
              key="manual"
              onClick={
                onManualClick ??
                function () {
                  quickfilterField.onChange(null);
                  manualField.onChange({});
                }
              }
              sx={
                {
                  ...styles.option,
                  ...(isManual ? styles.selected : {}),
                } as SxProps<Theme>
              }
            >
              <Typography variant="bodyL">{t('filterTransactions.filter.manual.label')}</Typography>
            </Box>
          )}
          {options.map((option) => (
            <Box
              key={option.key}
              onClick={() => handleQuickfilterChange(option.value)}
              sx={getOptionStyle(option.value)}
            >
              <Typography variant="bodyL">{option.label}</Typography>
            </Box>
          ))}
        </Stack>
      )}
      {children}
    </Stack>
  );
};
