import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'header' | 'row', SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    overflow: 'hidden',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: (theme) => theme.spacing(5),
  },
  row: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    backgroundColor: (theme) => theme.palette.gray[50],
  },
};
