import { SxProps, Theme } from '@mui/material';

export const styles: Record<'error' | 'content' | 'icon', SxProps<Theme>> = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: (theme) => theme.spacing(2, 3),
    textAlign: 'start',
  },
  error: {
    background: (theme) => theme.palette.red[100],
    borderRadius: '4px',
    color: (theme) => theme.palette.red[800],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(2),
  },
};
