import { ClientInput } from 'admin/inputs/ClientInput';
import { BooleanField, List } from 'react-admin';

import { ProcessDatagrid } from '../ProcessDatagrid';

export const LifeInsurancePurchaseProcessList: React.FC = () => {
  const otherFilters = [<ClientInput includeUnregistered={true} label="Klient" source="client.id" />];

  return (
    <List exporter={false} filters={otherFilters}>
      <ProcessDatagrid acceptedField={<BooleanField label="Akceptované" source="locked" />} showClient={true} />
    </List>
  );
};
