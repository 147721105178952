import { FileLinks } from 'admin/documents/FileLinks';
import { GenericProduct } from 'generated/graphql';
import {
  ArrayField,
  BooleanField,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SingleFieldList,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

export const GenericProductShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Základné info">
        <TextField label="ID" source="id" />
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
        <TextField label="Inštitúcia" source="institution.name" />
        <TextField label="Vlastná inštitúcia" source="institutionName" />
        <TextField label="Názov produktu" source="name" />
        <BooleanField label="Starostlivosť" source="enableFinancialAdvisorCare" />
        <ArrayField label="Dokumenty" source="documents">
          <SingleFieldList linkType={false}>
            <ArrayField source="files">
              <FileLinks />
            </ArrayField>
          </SingleFieldList>
        </ArrayField>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Zmluva">
        <TextField label="Číslo zmluvy" source="contractNumber" />
        <DateField label="Dátum podpisu zmluvy" locales="sk" source="contractSignDate" />
        <DateField label="Platnosť zmluvy do" locales="sk" source="contractEndDate" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Platby">
        <FunctionField
          label="Výška splátky"
          render={(record: GenericProduct) =>
            record.paymentPeriod?.expectedAmount?.amount ? -record.paymentPeriod?.expectedAmount?.amount : undefined
          }
          source="paymentPeriod.expectedAmount.amount"
        />
        <TextField label="Opakovať každých" source="paymentPeriod.periodCount" />
        <TextField label="" source="paymentPeriod.periodTypeCode" />
        <DateField label="Nasledujúca platba" locales="sk" source="paymentPeriod.expectedDate" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Údaje pre platbu">
        <TextField label="Variabilný symbol" source="paymentInfo.variableSymbol" />
        <TextField label="Špecifický symbol" source="paymentInfo.specificSymbol" />
        <TextField label="Konštantný symbol" source="paymentInfo.constantSymbol" />
        <TextField label="IBAN" source="paymentInfo.ibanForPayment" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Status">
        <TextField label="Status" source="status" />
        <DateField label="Zmena stavu" locales="sk" source="statusChangeDate" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
