import {
  extractUtmParameters,
  getUtmParameters as getUtmParametersFromLocalStorage,
  saveUtmParameters as saveUtmParametersToLocalStorage,
} from 'common/utils/utmUtils';
import { useSaveUtmParametersMutation } from 'generated/graphql';
import _ from 'lodash';
import { useCallback } from 'react';

export const useSaveUtmParameters = (): {
  extractAndStoreUtmParameters: (searchParams: URLSearchParams) => void;
  saveStoredUtmParameters: () => void;
} => {
  const [saveUtmParametersToBackend] = useSaveUtmParametersMutation();

  const extractAndStoreUtmParameters = useCallback((searchParams: URLSearchParams) => {
    const utmParameters = extractUtmParameters(searchParams);
    if (!_.isEmpty(utmParameters)) {
      saveUtmParametersToLocalStorage(utmParameters);
    }
  }, []);

  const saveStoredUtmParameters = useCallback(() => {
    const utmParameters = getUtmParametersFromLocalStorage();
    if (!_.isEmpty(utmParameters)) {
      saveUtmParametersToBackend({
        variables: {
          utmParameters: utmParameters,
        },
      });
    }
  }, [saveUtmParametersToBackend]);

  return { extractAndStoreUtmParameters, saveStoredUtmParameters };
};
