import { FilterOperator } from 'generated/graphql';
import { Datagrid, DateField, List, TextField } from 'react-admin';

import { ClientInput } from '../inputs/ClientInput';

export const ClientList: React.FC = () => {
  const filters = [<ClientInput label="Klient" source="id" />];

  return (
    <List
      exporter={false}
      // show only registered clients here = with user.subject
      filter={{
        filterCondition: {
          fieldPath: 'user.subject',
          operator: FilterOperator.IsNotNull,
          values: [],
        },
      }}
      filters={filters}
      sort={{ field: 'user.updateTimestamp', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField label="ID" source="id" />
        <TextField label="E-mail" source="user.email" />
        <TextField label="Meno" source="user.displayName" />
        <TextField label="Telefónne číslo" source="user.phoneNumber" />
        <DateField label="Dátum vytvorenia" locales="sk" source="user.creationTimestamp" />
        <DateField label="Dátum zmeny" locales="sk" source="user.updateTimestamp" />
      </Datagrid>
    </List>
  );
};
