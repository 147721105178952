import { SxProps, Theme } from '@mui/material';

export const styles: Record<'button', SxProps<Theme>> = {
  button: {
    padding: '3px 1px 10px 10px',
    svg: {
      height: 23,
      width: 25,
    },
  },
};
