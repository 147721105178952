const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;

export const dateParser: (value: string) => Date | undefined = (value: string) => {
  //value is a string of "YYYY-MM-DD" format
  const match = dateParseRegex.exec(value);
  if (match === null || match.length === 0) return;
  const d = new Date(parseInt(match[1]), parseInt(match[2], 10) - 1, parseInt(match[3]));
  if (isNaN(d.getDate())) return;
  return d;
};
