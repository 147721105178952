import { emailValidator, personalDataValidator, phoneValidator, transformNaNtoNull } from 'common/form/validators';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export interface MortgageCalculatorFormValues {
  info?: {
    count: number;
    age: number;
    children: number;
  };
  contact?: {
    email: string;
    phone: string;
    consent: boolean;
  };
  liabilities?: {
    balance: number;
    installment: number;
  };
  incomes?: {
    employment?: {
      monthlyIncome: number;
    };
    entrepreneurship?: {
      revenue: number;
      taxBase: number;
      taxPayed: number;
    };
    ltd?: {
      yearlyProfit: number;
      yearlyRevenues: number;
      equityShares: number;
      ceoCompensation: number;
    };
  };
}

export const getSchema = (t: TFunction<'translation', undefined>): yup.SchemaOf<MortgageCalculatorFormValues> =>
  yup.object({
    contact: yup
      .object({
        consent: personalDataValidator(),
        email: emailValidator(),
        phone: phoneValidator().required(t('form.validator.fieldIsRequired')),
      })
      .default(null)
      .nullable(),
    incomes: yup
      .object({
        employment: yup
          .object({
            monthlyIncome: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
          })
          .nullable()
          .default(null),
        entrepreneurship: yup
          .object({
            revenue: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
            taxBase: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
            taxPayed: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
          })
          .nullable()
          .default(null),
        ltd: yup
          .object({
            ceoCompensation: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
            equityShares: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .max(100, t('form.validator.max', { value: 100 }))
              .required(t('form.validator.fieldIsRequired')),
            yearlyProfit: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
            yearlyRevenues: yup
              .number()
              .transform(transformNaNtoNull)
              .nullable(true)
              .integer(t('form.validator.integer'))
              .min(0, t('form.validator.min', { value: 0 }))
              .required(t('form.validator.fieldIsRequired')),
          })
          .nullable()
          .default(null),
      })
      .default(null)
      .nullable()
      .test(
        'incomes',
        t('form.validator.fieldIsRequired'),
        (incomes: MortgageCalculatorFormValues['incomes'], context: yup.TestContext<MortgageCalculatorFormValues>) => {
          return !_.isEmpty(_.omitBy(incomes, (income) => !income))
            ? true
            : context.createError({
                message: t('flows/mortgage/calculator:inputs.incomes.error.required'),
                path: 'incomes',
              });
        },
      ),
    info: yup
      .object({
        age: yup
          .number()
          .transform(transformNaNtoNull)
          .nullable(true)
          .integer(t('form.validator.integer'))
          .min(18, t('form.validator.min', { value: 18 }))
          .required(t('form.validator.fieldIsRequired')),
        children: yup
          .number()
          .transform(transformNaNtoNull)
          .nullable(true)
          .integer(t('form.validator.integer'))
          .min(0, t('form.validator.min', { value: 0 }))
          .required(t('form.validator.fieldIsRequired')),
        count: yup
          .number()
          .transform(transformNaNtoNull)
          .nullable(true)
          .integer(t('form.validator.integer'))
          .min(1, t('form.validator.min', { value: 1 }))
          .max(4, t('form.validator.max', { value: 4 }))
          .required(t('form.validator.fieldIsRequired')),
      })
      .default(null)
      .nullable(),
    liabilities: yup
      .object({
        balance: yup
          .number()
          .transform(transformNaNtoNull)
          .nullable(true)
          .min(0, t('form.validator.min', { value: 0 }))
          .required(t('form.validator.fieldIsRequired')),
        installment: yup
          .number()
          .transform(transformNaNtoNull)
          .nullable(true)
          .min(0, t('form.validator.min', { value: 0 }))
          .required(t('form.validator.fieldIsRequired')),
      })
      .default(null)
      .nullable(),
  });
