import { format, parseISO } from 'date-fns';

export const typesMap = {
  Date: {
    parseValue: (raw: unknown): Date => {
      if (typeof raw === 'string') {
        return parseISO(raw);
      }
      throw new Error('invalid value to parse');
    },
    serialize: (parsed: unknown): string | null => {
      if (parsed instanceof Date) {
        return format(parsed, 'yyyy-MM-dd');
      }
      return null;
    },
  },
  DateTime: {
    parseValue: (raw: unknown): Date => {
      if (typeof raw === 'string') {
        return parseISO(raw);
      }
      throw new Error('invalid value to parse');
    },
    serialize: (parsed: unknown): string | null => {
      if (parsed instanceof Date) {
        return parsed.toISOString();
      }
      return null;
    },
  },
};
