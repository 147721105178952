import {
  constantSymbolValidator,
  ibanValidator,
  specificSymbolValidator,
  transformNaNtoNull,
  variableSymbolValidator,
} from 'common/form/validators';
import { Input } from 'components/flows/common/InputStep/InputFieldBase';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export type InputType =
  | Input<unknown>['inputType']
  | 'iban'
  | 'constantSymbol'
  | 'variableSymbol'
  | 'specificSymbol'
  | 'amount';

export const getSchema: (t: TFunction) => Record<InputType, yup.AnyObjectSchema> = (t) => ({
  amount: yup.object({
    value: yup
      .number()
      .transform(transformNaNtoNull)
      .nullable(true)
      .moreThan(0, t('form.validator.moreThan', { value: 0 })),
  }),
  checkbox: yup.object({
    value: yup.boolean().nullable(true),
  }),
  constantSymbol: yup.object({
    value: constantSymbolValidator().nullable(true),
  }),
  date: yup.object({
    value: yup.date().nullable(true).typeError(t('form.validator.invalidDate')),
  }),
  iban: yup.object({
    value: ibanValidator().nullable(true),
  }),
  integer: yup.object({
    value: yup.number().nullable(true).integer(t('form.validator.integer')),
  }),
  number: yup.object({
    value: yup.number().transform(transformNaNtoNull).nullable(true),
  }),
  radioSelect: yup.object({
    value: yup.mixed().nullable(true).required(t('form.validator.selectionIsRequired')),
  }),
  select: yup.object({
    value: yup.string().nullable(true).required(t('form.validator.selectionIsRequired')),
  }),
  specificSymbol: yup.object({
    value: specificSymbolValidator().nullable(true),
  }),
  text: yup.object({
    value: yup.string().nullable(true),
  }),
  variableSymbol: yup.object({
    value: variableSymbolValidator().nullable(true),
  }),
});

export const getFormInputType = (inputType: InputType): Input<unknown>['inputType'] => {
  switch (inputType) {
    case 'iban':
      return 'text';
    case 'constantSymbol':
      return 'text';
    case 'variableSymbol':
      return 'text';
    case 'specificSymbol':
      return 'text';
    case 'amount':
      return 'number';
    default:
      return inputType;
  }
};
