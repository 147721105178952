import { Stack } from '@mui/material';

import { AccountFilter } from './filters/AccountFilter';
import { AmountFilter } from './filters/AmountFilter';
import { AnalyticsFilter } from './filters/AnalyticsFilter';
import { CategoryFilter } from './filters/CategoryFilter';
import { CounterpartyFilter } from './filters/CounterpartyFilter';
import { DateFilter } from './filters/DateFilter';
import { PeriodicityFilter } from './filters/PeriodicityFilter';
import { TagsFilter } from './filters/TagsFilter';
import { TypeFilter } from './filters/TypeFilter';

export const FilterTransactionsContent: React.FC = () => {
  return (
    <Stack marginRight={{ sm: -4, xxs: 0 }} spacing={4}>
      <AccountFilter />
      <DateFilter />
      <AmountFilter />
      <CategoryFilter />
      <TagsFilter />
      <CounterpartyFilter />
      <TypeFilter />
      <PeriodicityFilter />
      <AnalyticsFilter />
    </Stack>
  );
};
