import { MortgageOtherIncomeSubtype } from 'components/flows/mortgagePurchase/state/purchaseTypes';
import { LifeInsuranceIncomeType, LifeInsuranceLiabilityType, LifeInsuranceSports, MortgageLiabilityType } from 'const';
import {
  CalendarEventType,
  DisabilityPensionType,
  EntrepreneurshipExpenseType,
  FamilyStatus,
  FinancialProductType,
  LifeInsuranceCalculatorDomain,
  LifeInsuranceCalculatorNecessity,
  MortgageCollateralType,
  MortgageIncomeType,
  MortgagePropertyType,
  MortgagePurposeType,
  NotificationType,
  TransactionTypeCode,
} from 'generated/graphql';
import { TFunction } from 'react-i18next';

export const MortgagePurposeTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<MortgagePurposeType, string> = (t) => ({
  [MortgagePurposeType.PurchaseProperty]: t('enums.mortgagePurposeType.PURCHASE_PROPERTY'),
  [MortgagePurposeType.OtherPropertyRelated]: t('enums.mortgagePurposeType.OTHER_PROPERTY_RELATED'),
  [MortgagePurposeType.Other]: t('enums.mortgagePurposeType.OTHER'),
  [MortgagePurposeType.PurchasePriceRefund]: t('enums.mortgagePurposeType.PURCHASE_PRICE_REFUND'),
  [MortgagePurposeType.Construction]: t('enums.mortgagePurposeType.CONSTRUCTION'),
  [MortgagePurposeType.Completion]: t('enums.mortgagePurposeType.COMPLETION'),
  [MortgagePurposeType.ReconstructionNotRequiringBuildingPermit]: t(
    'enums.mortgagePurposeType.RECONSTRUCTION_NOT_REQUIRING_BUILDING_PERMIT',
  ),
  [MortgagePurposeType.ReconstructionRequiringBuildingPermit]: t(
    'enums.mortgagePurposeType.RECONSTRUCTION_REQUIRING_BUILDING_PERMIT',
  ),
  [MortgagePurposeType.OwnershipSettlement]: t('enums.mortgagePurposeType.OWNERSHIP_SETTLEMENT'),
  [MortgagePurposeType.InheritanceSettlement]: t('enums.mortgagePurposeType.INHERITANCE_SETTLEMENT'),
  [MortgagePurposeType.Consolidation]: t('enums.mortgagePurposeType.CONSOLIDATION'),
  [MortgagePurposeType.Refinance]: t('enums.mortgagePurposeType.REFINANCE'),
});

export const MortgagePropertyTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<MortgagePropertyType, string> = (t) => ({
  [MortgagePropertyType.Flat]: t('enums.mortgagePropertyType.FLAT'),
  [MortgagePropertyType.House]: t('enums.mortgagePropertyType.HOUSE'),
  [MortgagePropertyType.Land]: t('enums.mortgagePropertyType.LAND'),
  [MortgagePropertyType.Other]: t('enums.mortgagePropertyType.OTHER'),
});

export const MortgageCollateralTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<MortgageCollateralType, string> = (t) => ({
  [MortgageCollateralType.Flat]: t('enums.mortgageCollateralType.FLAT'),
  [MortgageCollateralType.House]: t('enums.mortgageCollateralType.HOUSE'),
});

export const FamilyStatusTranslations: (t: TFunction<'translation', undefined>) => Record<FamilyStatus, string> = (
  t,
) => ({
  [FamilyStatus.CohabitingPartnership]: t('enums.familyStatus.COHABITING_PARTNERSHIP'),
  [FamilyStatus.Divorced]: t('enums.familyStatus.DIVORCED'),
  [FamilyStatus.Married]: t('enums.familyStatus.MARRIED'),
  [FamilyStatus.MarriedSplitOwnership]: t('enums.familyStatus.MARRIED_SPLIT_OWNERSHIP'),
  [FamilyStatus.Single]: t('enums.familyStatus.SINGLE'),
  [FamilyStatus.Widow]: t('enums.familyStatus.WIDOW'),
});

export const MortgageIncomeTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<MortgageIncomeType, string> = (t) => ({
  [MortgageIncomeType.Entrepreneurship]: t('enums.mortgageIncomeType.ENTREPRENEURSHIP'),
  [MortgageIncomeType.Ltd]: t('enums.mortgageIncomeType.LTD'),
  [MortgageIncomeType.Other]: t('enums.mortgageIncomeType.OTHER'),
  [MortgageIncomeType.Salary]: t('enums.mortgageIncomeType.SALARY'),
});

export const MortgageOtherIncomeSubtypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<MortgageOtherIncomeSubtype, string> = (t) => ({
  [MortgageOtherIncomeSubtype.Alimony]: t('enums.mortgageOtherIncomeSubtype.ALIMONY'),
  [MortgageOtherIncomeSubtype.DisabilityPension]: t('enums.mortgageOtherIncomeSubtype.DISABILITY_PENSION'),
  [MortgageOtherIncomeSubtype.DoctoralScholarship]: t('enums.mortgageOtherIncomeSubtype.DOCTORAL_SCHOLARSHIP'),
  [MortgageOtherIncomeSubtype.MaternityLeave]: t('enums.mortgageOtherIncomeSubtype.MATERNITY_LEAVE'),
  [MortgageOtherIncomeSubtype.OldAgePension]: t('enums.mortgageOtherIncomeSubtype.OLD_AGE_PENSION'),
  [MortgageOtherIncomeSubtype.OrphanPension]: t('enums.mortgageOtherIncomeSubtype.ORPHAN_PENSION'),
  [MortgageOtherIncomeSubtype.ParentalAllowance]: t('enums.mortgageOtherIncomeSubtype.PARENTAL_ALLOWANCE'),
  [MortgageOtherIncomeSubtype.TravelCompensation]: t('enums.mortgageOtherIncomeSubtype.TRAVEL_COMPENSATION'),
  [MortgageOtherIncomeSubtype.RealEstateRental]: t('enums.mortgageOtherIncomeSubtype.REAL_ESTATE_RENTAL'),
  [MortgageOtherIncomeSubtype.RetirementPension]: t('enums.mortgageOtherIncomeSubtype.RETIREMENT_PENSION'),
  [MortgageOtherIncomeSubtype.WidowPension]: t('enums.mortgageOtherIncomeSubtype.WIDOW_PENSION'),
  [MortgageOtherIncomeSubtype.WorkAgreement]: t('enums.mortgageOtherIncomeSubtype.WORK_AGREEMENT'),
});

export const MortgageLiabilityTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<MortgageLiabilityType, string> = (t) => ({
  [MortgageLiabilityType.CreditCard]: t('enums.mortgageLiabilityType.CREDIT_CARD'),
  [MortgageLiabilityType.OtherLoan]: t('enums.mortgageLiabilityType.OTHER_LOAN'),
  [MortgageLiabilityType.Overdraft]: t('enums.mortgageLiabilityType.OVERDRAFT'),
  [MortgageLiabilityType.ConsumerLoan]: t('enums.mortgageLiabilityType.CONSUMER_LOAN'),
  [MortgageLiabilityType.Mortgage]: t('enums.mortgageLiabilityType.MORTGAGE'),
  [MortgageLiabilityType.Lease]: t('enums.mortgageLiabilityType.LEASE'),
  [MortgageLiabilityType.ReconstructionLoan]: t('enums.mortgageLiabilityType.RECONSTRUCTION_LOAN'),
  [MortgageLiabilityType.Alimony]: t('enums.mortgageLiabilityType.ALIMONY'),
});

export const EntrepreneurshipExpenseTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<EntrepreneurshipExpenseType, string> = (t) => ({
  [EntrepreneurshipExpenseType.FlatRate]: t('enums.entrepreneurshipExpenseType.FLAT_RATE'),
  [EntrepreneurshipExpenseType.Real]: t('enums.entrepreneurshipExpenseType.REAL'),
});

export const TransactionTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<TransactionTypeCode, string> = (t) => ({
  [TransactionTypeCode.Bonus]: t('enums.transactionTypeCode.BONUS'),
  [TransactionTypeCode.Cash]: t('enums.transactionTypeCode.CASH'),
  [TransactionTypeCode.Charge]: t('enums.transactionTypeCode.CHARGE'),
  [TransactionTypeCode.Fee]: t('enums.transactionTypeCode.FEE'),
  [TransactionTypeCode.Transfer]: t('enums.transactionTypeCode.TRANSFER'),
  [TransactionTypeCode.Other]: t('enums.transactionTypeCode.OTHER'),
});

export const LifeInsuranceIncomeTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<LifeInsuranceIncomeType, string> = (t) => ({
  [LifeInsuranceIncomeType.Alimony]: t('enums.lifeInsuranceIncomeType.ALIMONY'),
  [LifeInsuranceIncomeType.Entrepreneurship]: t('enums.lifeInsuranceIncomeType.ENTREPRENEURSHIP'),
  [LifeInsuranceIncomeType.Ltd]: t('enums.lifeInsuranceIncomeType.LTD'),
  [LifeInsuranceIncomeType.MaternityLeave]: t('enums.lifeInsuranceIncomeType.MATERNITY_LEAVE'),
  [LifeInsuranceIncomeType.Other]: t('enums.lifeInsuranceIncomeType.OTHER'),
  [LifeInsuranceIncomeType.ParentalAllowance]: t('enums.lifeInsuranceIncomeType.PARENTAL_ALLOWANCE'),
  [LifeInsuranceIncomeType.Salary]: t('enums.lifeInsuranceIncomeType.SALARY'),
  [LifeInsuranceIncomeType.UnemploymentBenefit]: t('enums.lifeInsuranceIncomeType.UNEMPLOYMENT_BENEFIT'),
  [LifeInsuranceIncomeType.WorkAgreement]: t('enums.lifeInsuranceIncomeType.WORK_AGREEMENTS'),
});

export const LifeInsuranceLiabilityTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<LifeInsuranceLiabilityType, string> = (t) => ({
  [LifeInsuranceLiabilityType.OtherLoan]: t('enums.lifeInsuranceLiabilityType.OTHER_LOAN'),
  [LifeInsuranceLiabilityType.ConsumerLoan]: t('enums.lifeInsuranceLiabilityType.CONSUMER_LOAN'),
  [LifeInsuranceLiabilityType.Lease]: t('enums.lifeInsuranceLiabilityType.LEASE'),
  [LifeInsuranceLiabilityType.Alimony]: t('enums.lifeInsuranceLiabilityType.ALIMONY'),
  [LifeInsuranceLiabilityType.ReconstructionLoan]: t('enums.lifeInsuranceLiabilityType.RECONSTRUCTION_LOAN'),
  [LifeInsuranceLiabilityType.Mortgage]: t('enums.lifeInsuranceLiabilityType.MORTGAGE'),
});

export const LifeInsuranceSportTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<LifeInsuranceSports, string> = (t) => ({
  [LifeInsuranceSports.cycling]: t('enums.sports.CYCLING'),
  [LifeInsuranceSports.figureSkating]: t('enums.sports.FIGURE_SKATING'),
  [LifeInsuranceSports.fitness]: t('enums.sports.FITNESS'),
  [LifeInsuranceSports.football]: t('enums.sports.FOOTBALL'),
  [LifeInsuranceSports.hockey]: t('enums.sports.HOCKEY'),
  [LifeInsuranceSports.more]: t('enums.sports.MORE'),
  [LifeInsuranceSports.running]: t('enums.sports.RUNNING'),
  [LifeInsuranceSports.skiing]: t('enums.sports.SKIING'),
  [LifeInsuranceSports.swimming]: t('enums.sports.SWIMMING'),
  [LifeInsuranceSports.volleyball]: t('enums.sports.VOLLEYBALL'),
});

export const LifeInsuranceDisabilityPensionTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<DisabilityPensionType, string> = (t) => ({
  [DisabilityPensionType.Full]: t('flows/lifeInsurance/common:health.disabilityPension.full'),
  [DisabilityPensionType.Partial]: t('flows/lifeInsurance/common:health.disabilityPension.partial'),
  [DisabilityPensionType.None]: t('flows/lifeInsurance/common:health.disabilityPension.none'),
});

export const LifeInsuranceCalculatorNecessityTitleTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<LifeInsuranceCalculatorNecessity, string> = (t) => ({
  [LifeInsuranceCalculatorNecessity.Low]: t('flows/lifeInsurance/calculator:result.necessity.low.title'),
  [LifeInsuranceCalculatorNecessity.Medium]: t('flows/lifeInsurance/calculator:result.necessity.medium.title'),
  [LifeInsuranceCalculatorNecessity.High]: t('flows/lifeInsurance/calculator:result.necessity.high.title'),
});

export const LifeInsuranceCalculatorNecessityTextTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<LifeInsuranceCalculatorNecessity, string> = (t) => ({
  [LifeInsuranceCalculatorNecessity.Low]: t('flows/lifeInsurance/calculator:result.necessity.low.text'),
  [LifeInsuranceCalculatorNecessity.Medium]: t('flows/lifeInsurance/calculator:result.necessity.medium.text'),
  [LifeInsuranceCalculatorNecessity.High]: t('flows/lifeInsurance/calculator:result.necessity.high.text'),
});

export const LifeInsuranceCalculatorDomainTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<LifeInsuranceCalculatorDomain, string> = (t) => ({
  [LifeInsuranceCalculatorDomain.Driving]: t('flows/lifeInsurance/calculator:result.domain.driving'),
  [LifeInsuranceCalculatorDomain.Employment]: t('flows/lifeInsurance/calculator:result.domain.employment'),
  [LifeInsuranceCalculatorDomain.FinancialReserve]: t('flows/lifeInsurance/calculator:result.domain.financialReserve'),
  [LifeInsuranceCalculatorDomain.Health]: t('flows/lifeInsurance/calculator:result.domain.health'),
  [LifeInsuranceCalculatorDomain.Liabilities]: t('flows/lifeInsurance/calculator:result.domain.liabilities'),
  [LifeInsuranceCalculatorDomain.Sport]: t('flows/lifeInsurance/calculator:result.domain.sport'),
});

export const FinancialProductTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<FinancialProductType, string> = (t) => ({
  [FinancialProductType.BuildingSavings]: t('enums.financialProductType.BUILDING_SAVINGS'),
  [FinancialProductType.CollisionInsurance]: t('enums.financialProductType.COLLISION_INSURANCE'),
  [FinancialProductType.CreditCard]: t('enums.financialProductType.CREDIT_CARD'),
  [FinancialProductType.Generic]: t('enums.financialProductType.GENERIC'),
  [FinancialProductType.GenericInsurance]: t('enums.financialProductType.GENERIC_INSURANCE'),
  [FinancialProductType.Investment]: t('enums.financialProductType.INVESTMENT'),
  [FinancialProductType.LifeInsurance]: t('enums.financialProductType.LIFE_INSURANCE'),
  [FinancialProductType.Loan]: t('enums.financialProductType.LOAN'),
  [FinancialProductType.Mortgage]: t('enums.financialProductType.MORTGAGE'),
  [FinancialProductType.MotorLiabilityInsurance]: t('enums.financialProductType.MOTOR_LIABILITY_INSURANCE'),
  [FinancialProductType.PropertyInsurance]: t('enums.financialProductType.PROPERTY_INSURANCE'),
  [FinancialProductType.RetirementPensionSavings]: t('enums.financialProductType.RETIREMENT_PENSION_SAVINGS'),
  [FinancialProductType.Savings]: t('enums.financialProductType.SAVINGS'),
  [FinancialProductType.SupplementaryPensionSavings]: t('enums.financialProductType.SUPPLEMENTARY_PENSION_SAVINGS'),
});

export const FinancialCalendarEventTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<FinancialProductType, Record<CalendarEventType, string | undefined>> = (t) => ({
  [FinancialProductType.BuildingSavings]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.BUILDING_SAVINGS_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.BUILDING_SAVINGS_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.CollisionInsurance]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.COLLISION_INSURANCE_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.COLLISION_INSURANCE_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.CreditCard]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.CREDIT_CARD_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t('enums.financialCalendarEventType.CREDIT_CARD_PRODUCT_CONTRACT_END_DATE'),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.Generic]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.GENERIC_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t('enums.financialCalendarEventType.GENERIC_PRODUCT_CONTRACT_END_DATE'),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.GenericInsurance]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.GENERIC_INSURANCE_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.GENERIC_INSURANCE_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.Investment]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.INVESTMENT_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: undefined,
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.LifeInsurance]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.LIFE_INSURANCE_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.LIFE_INSURANCE_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.Loan]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.LOAN_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t('enums.financialCalendarEventType.LOAN_PRODUCT_CONTRACT_END_DATE'),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.Mortgage]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.MORTGAGE_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t('enums.financialCalendarEventType.MORTGAGE_PRODUCT_FIXATION_END_DATE'),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.MotorLiabilityInsurance]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.MOTOR_LIABILITY_INSURANCE_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.MOTOR_LIABILITY_INSURANCE_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.PropertyInsurance]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.PROPERTY_INSURANCE_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.PROPERTY_INSURANCE_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.RetirementPensionSavings]: {
    [CalendarEventType.ProductPayment]: t(
      'enums.financialCalendarEventType.RETIREMENT_PENSION_SAVINGS_PRODUCT_PAYMENT',
    ),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.RETIREMENT_PENSION_SAVINGS_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.Savings]: {
    [CalendarEventType.ProductPayment]: t('enums.financialCalendarEventType.SAVINGS_PRODUCT_PAYMENT'),
    [CalendarEventType.ProductExpiration]: t('enums.financialCalendarEventType.SAVINGS_PRODUCT_CONTRACT_END_DATE'),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
  [FinancialProductType.SupplementaryPensionSavings]: {
    [CalendarEventType.ProductPayment]: t(
      'enums.financialCalendarEventType.SUPPLEMENTARY_PENSION_SAVINGS_PRODUCT_PAYMENT',
    ),
    [CalendarEventType.ProductExpiration]: t(
      'enums.financialCalendarEventType.SUPPLEMENTARY_PENSION_SAVINGS_PRODUCT_CONTRACT_END_DATE',
    ),
    [CalendarEventType.General]: undefined,
    [CalendarEventType.UserDefined]: undefined,
  },
});

export const NotificationTypeTranslations: (
  t: TFunction<'translation', undefined>,
) => Record<NotificationType, string> = (t) => ({
  [NotificationType.ActiveProductWithoutDocument]: t('enums.notificationType.ACTIVE_PRODUCT_WITHOUT_DOCUMENT'),
  [NotificationType.CalendarEvent]: t('enums.notificationType.CALENDAR_EVENT'),
  [NotificationType.CollisionInsuranceProductExpiration]: t(
    'enums.notificationType.COLLISION_INSURANCE_PRODUCT_EXPIRATION',
  ),
  [NotificationType.CollisionInsuranceProductPayment]: t('enums.notificationType.COLLISION_INSURANCE_PRODUCT_PAYMENT'),
  [NotificationType.GenericProductExpiration]: t('enums.notificationType.GENERIC_PRODUCT_EXPIRATION'),
  [NotificationType.LifeInsuranceAnnualUpdate]: t('enums.notificationType.LIFE_INSURANCE_ANNUAL_UPDATE'),
  [NotificationType.MortgageProductFixationEnd]: t('enums.notificationType.MORTGAGE_PRODUCT_FIXATION_END'),
  [NotificationType.MotorLiabilityInsuranceProductExpiration]: t(
    'enums.notificationType.MOTOR_LIABILITY_INSURANCE_PRODUCT_EXPIRATION',
  ),
  [NotificationType.MotorLiabilityInsuranceProductPayment]: t(
    'enums.notificationType.MOTOR_LIABILITY_INSURANCE_PRODUCT_PAYMENT',
  ),
  [NotificationType.NordigenConsentExpiration]: t('enums.notificationType.NORDIGEN_CONSENT_EXPIRATION'),
  [NotificationType.ProductWaitingForSubmission]: t('enums.notificationType.PRODUCT_WAITING_FOR_SUBMISSION'),
  [NotificationType.PropertyInsuranceProductExpiration]: t(
    'enums.notificationType.PROPERTY_INSURANCE_PRODUCT_EXPIRATION',
  ),
  [NotificationType.PropertyInsuranceProductPayment]: t('enums.notificationType.PROPERTY_INSURANCE_PRODUCT_PAYMENT'),
});
