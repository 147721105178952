import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useRenameTransactionCounterpartyMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  name: string;
}

export interface EditCounterpartyDialogProps extends DialogComponentProps<string | undefined | null> {
  id: string;
  name?: string | null | undefined;
  transactionAmount: number;
}

export const EditCounterpartyDialog: React.FC<EditCounterpartyDialogProps> = ({
  id,
  isOpen,
  name,
  onResolve,
  transactionAmount,
}) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [renameTransactionCounterparty, { loading }] = useRenameTransactionCounterpartyMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator().nullable(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      name: name,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    await renameTransactionCounterparty({ variables: { counterpartyId: id, name: data.name } });
    onResolve();
    return;
  });

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      title={
        transactionAmount < 0
          ? t('transactionDetail.editCounterparty.title.recipient')
          : t('transactionDetail.editCounterparty.title.sender')
      }
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoComplete="name" autoFocus fullWidth name="name" type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <LoadingButton color="primary" loading={loading} sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('transactionDetail.editCounterparty.save')}
              </Typography>
            </LoadingButton>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
