import { SxProps, Theme } from '@mui/material';

export const styles: Record<'root' | 'icon', SxProps<Theme>> = {
  icon: {
    height: 20,
    width: 20,
  },
  root: {
    alignItems: 'center',
    color: (theme) => theme.palette.blue.dark,
    cursor: 'pointer',
    display: 'flex',
    gap: 0.5,
    justifyContent: 'space-between',
  },
};
