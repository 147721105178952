import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'deleteButtonRow' | 'icon' | 'textBox' | 'files' | 'buttonRow' | 'header' | 'headerSubmit',
  SxProps<Theme>
> = {
  buttonRow: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      color: (theme) => theme.palette.blue.dark,
      cursor: 'pointer',
    },
    '.MuiBox-root + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],

    color: (theme) => theme.palette.blue.dark,
    display: 'flex',
    flexDirection: 'row',
    padding: (theme) => theme.spacing(2),
    position: 'relative',
  },
  deleteButtonRow: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    marginTop: (theme) => theme.spacing(3),
    overflow: 'hidden',
  },
  files: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    overflow: 'hidden',
  },
  header: {
    marginX: 'auto',
    padding: (theme) => theme.spacing(1),
  },
  headerSubmit: {
    position: { sm: 'absolute' },
    right: (theme) => ({
      sm: theme.spacing(1),
      xxs: theme.spacing(2),
    }),
  },
  icon: {
    minWidth: 48,
  },
  textBox: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    padding: (theme) => ({
      sm: theme.spacing(6),
      xxs: theme.spacing(2),
    }),
  },
};
