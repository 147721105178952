import { parseDatestrings } from 'common/utils/parseDatestrings';
import {
  DocumentType,
  useCreateLifeInsuranceAuditProcessMutation,
  useCreateLifeInsuranceDocumentMutation,
  useSendLifeInsuranceAuditNotificationToSpecialistMutation,
  useUpdateLifeInsuranceAuditProcessMutation,
} from 'generated/graphql';
import _ from 'lodash';

import { mapLifeInsuranceAuditStateToInput } from './lifeInsuranceAuditState';
import { LifeInsuranceAuditState } from './lifeInsuranceAuditTypes';
import { LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY, useLifeInsuranceAuditState } from './useLifeInsuranceAuditState';

export const useSaveLocalLifeInsuranceAuditState = (): (() => Promise<string | undefined>) => {
  const [startProcess] = useCreateLifeInsuranceAuditProcessMutation({
    refetchQueries: ['AllProcesses'],
  });
  const [updateProcess] = useUpdateLifeInsuranceAuditProcessMutation({
    refetchQueries: ['AllLifeInsuranceAuditProcesses'],
  });
  const [sendNotification] = useSendLifeInsuranceAuditNotificationToSpecialistMutation();
  const { clear } = useLifeInsuranceAuditState();

  const [createDocuments] = useCreateLifeInsuranceDocumentMutation({});

  const save = async () => {
    const rawState = localStorage.getItem(LIFE_INSURANCE_AUDIT_STATE_LOCAL_STORAGE_KEY);
    const parsedState = rawState ? JSON.parse(rawState) : undefined;

    if (!_.isEmpty(parsedState)) {
      const stateWithDates = parseDatestrings(parsedState) as LifeInsuranceAuditState;
      try {
        const processResp = await startProcess();
        const id = processResp.data?.createLifeInsuranceAuditProcess?.id;
        if (id) {
          const docResponse = await createDocuments({
            variables: { document: { type: DocumentType.LifeInsuranceContract }, id },
          });

          _.set(stateWithDates, 'documentId', docResponse.data?.createLifeInsuranceDocument.id);

          await updateProcess({
            variables: {
              id,
              inquiry: mapLifeInsuranceAuditStateToInput(stateWithDates),
            },
          }).then(() => {
            sendNotification({ variables: { processId: id } });
            clear();
          });
        }
        return id;
      } catch (e) {
        //pass
      }
    }
  };

  return save;
};
