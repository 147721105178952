import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { useCreateTagMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  name: string;
}

export type CreateTagDialogProps = DialogComponentProps<void>;

export const CreateTagDialog: React.FC<CreateTagDialogProps> = ({ isOpen, onResolve }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [createTag, { loading }] = useCreateTagMutation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator(),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    await createTag({
      onCompleted: () => onResolve(),
      onError: () => notify({ message: t('common:somethingWentWrong'), type: 'error' }),
      refetchQueries: ['TransactionTags'],
      variables: {
        tag: {
          name: data.name,
        },
      },
    });
  });

  return (
    <PromptDialogOnSide onClose={() => onResolve()} open={isOpen} title={t('createTag.title')}>
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoFocus fullWidth name="name" placeholder={t('createTag.name.placeholder')} type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <LoadingButton color="primary" loading={loading} sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('createTag.submit')}
              </Typography>
            </LoadingButton>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
