import { Upload } from '@mui/icons-material';
import { useAdminCreateProductDocument } from 'admin/products/useAdminCreateProductDocument';
import { useDialog } from 'common/hooks/useDialog';
import { DocumentTypeByProductType } from 'components/products/UploadDocumentDialog/useCreateProductDocument';
import { FinancialProductType } from 'generated/graphql';
import { Button, useRecordContext } from 'react-admin';

import { EditDocumentDialog, EditDocumentDialogProps } from './EditDocumentDialog';

interface Props {
  productType: FinancialProductType;
}

export const AddDocumentButton: React.FC<Props> = ({ productType }) => {
  const record = useRecordContext();

  const editDocumentDialog = useDialog<EditDocumentDialogProps, void>(EditDocumentDialog, {
    documentId: record.documents[0]?.id,
    instanceId: 'edit-document-dialog',
    navigable: true,
    productId: record.id.toString(),
    type: productType,
  });

  const createDocumentTuple = useAdminCreateProductDocument(productType);

  const handleButtonClick = async (event: React.MouseEvent) => {
    event.stopPropagation();

    let documentId = record.documents[0]?.id;
    if (!documentId) {
      const resp = await createDocumentTuple[0]({
        variables: {
          document: { type: DocumentTypeByProductType[productType] },
          productId: record.id.toString(),
        },
      });
      documentId = resp?.data?.createProductDocument?.id;
    }

    if (documentId) {
      try {
        await editDocumentDialog.create({
          documentId,
          productId: record.id.toString(),
          type: productType,
        });
      } catch (e) {
        //pass
      }
    }
  };

  return (
    <Button label="Upload" onClick={handleButtonClick}>
      <Upload />
    </Button>
  );
};
