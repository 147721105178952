import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormCheckboxGroupProps } from 'common/form/components/FormCheckboxGroup/FormCheckboxGroup';
import { FormRadioSelectProps } from 'common/form/components/FormRadioSelect';
import { FormSelectProps } from 'common/form/components/FormSelect';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { InputFieldBase } from 'components/flows/common/InputStep/InputFieldBase';
import { ReactElement } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFormInputType, getSchema, InputType } from './SetValueDialog.schema';

export interface SetValueDialogProps<T> extends DialogComponentProps<T> {
  defaultValue?: T;
  transform?: (value: T) => T;
  parse?: (value: T) => T;
  inputType?: InputType;
  inputEndAdornment?: string;
  title: string;
  subtitle?: string;
  options?: FormSelectProps['options'] | FormRadioSelectProps<unknown>['options'] | FormCheckboxGroupProps['options'];
}

const SetValueDialogContent = <T,>({
  defaultValue,
  inputEndAdornment,
  inputType = 'text',
  onReject,
  onResolve,
  options,
  parse,
  transform,
}: SetValueDialogProps<T>): ReactElement => {
  const { t } = useTranslation();

  const schema = getSchema(t)[inputType];

  const formMethods = useForm({
    defaultValues: {
      value: defaultValue ? transform?.(defaultValue) ?? defaultValue : null,
    } as FieldValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async ({ value }) => {
    onResolve(value ? parse?.(value as T) ?? (value as T) : undefined);
  });

  const field = {
    endAdornmentText: inputEndAdornment,
    fullWidth: true,
    inputType: getFormInputType(inputType),
    name: 'value',
    options,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  return (
    <Box>
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <InputFieldBase autoFocus field={field} />
          </Box>

          <Stack mt={3} spacing={1} width="100%">
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('products:setValueDialog.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onReject()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export const SetValueDialog = <T,>(props: SetValueDialogProps<T>): ReactElement => (
  <PromptDialogOnSide
    onClose={() => props.onReject()}
    open={props.isOpen}
    subtitle={props.subtitle}
    title={props.title}
  >
    <SetValueDialogContent<T> {...props} />
  </PromptDialogOnSide>
);
