import { Route } from 'react-router-dom';
import { SentryRoutes } from 'sentry';

import { MotorLiabilityInsuranceIntroPage } from './MotorLiabilityInsuranceIntroPage';
import { MotorLiabilityInsurancePurchasePage } from './MotorLiabilityInsurancePurchasePage';

type Props = Record<string, never>;

export const MotorLiabilityInsuranceRouter: React.FC<Props> = () => (
  <SentryRoutes>
    <Route element={<MotorLiabilityInsuranceIntroPage />} path="/" />
    <Route element={<MotorLiabilityInsurancePurchasePage />} path="/purchase" />
  </SentryRoutes>
);
