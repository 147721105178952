import { List } from 'react-admin';

import { ClientInput } from '../inputs/ClientInput';
import { FinancialAdvisorTaskDatagrid } from './FinancialAdvisorTaskDatagrid';
import {
  FinancialAdvisorTaskSubtitleInput,
  FinancialAdvisorTaskTextInput,
  FinancialAdvisorTaskTitleInput,
} from './FinancialAdvisorTaskInputs';

export const FinancialAdvisorTaskList: React.FC = () => {
  const filters = [
    <ClientInput label="Klient" source="client.id" />,
    <FinancialAdvisorTaskTitleInput label="Typ" source="title" />,
    <FinancialAdvisorTaskSubtitleInput label="Podtyp" source="subtitle" />,
    <FinancialAdvisorTaskTextInput label="Úloha" source="text" />,
  ];

  return (
    <List exporter={false} filters={filters} sort={{ field: 'creationTimestamp', order: 'DESC' }}>
      <FinancialAdvisorTaskDatagrid showClient={true} />
    </List>
  );
};
