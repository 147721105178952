import { MutationTuple } from '@apollo/client';
import {
  AdminCreateBuildingSavingsProductDocumentMutation,
  AdminCreateCollisionInsuranceProductDocumentMutation,
  AdminCreateCreditCardProductDocumentMutation,
  AdminCreateGenericInsuranceProductDocumentMutation,
  AdminCreateGenericProductDocumentMutation,
  AdminCreateInvestmentProductDocumentMutation,
  AdminCreateLifeInsuranceProductDocumentMutation,
  AdminCreateLoanProductDocumentMutation,
  AdminCreateMortgageProductDocumentMutation,
  AdminCreateMotorLiabilityInsuranceProductDocumentMutation,
  AdminCreatePropertyInsuranceProductDocumentMutation,
  AdminCreateRetirementPensionSavingsProductDocumentMutation,
  AdminCreateSavingsProductDocumentMutation,
  AdminCreateSupplementaryPensionSavingsProductDocumentMutation,
  DocumentInput,
  Exact,
  FinancialProductType,
  useAdminCreateBuildingSavingsProductDocumentMutation,
  useAdminCreateCollisionInsuranceProductDocumentMutation,
  useAdminCreateCreditCardProductDocumentMutation,
  useAdminCreateGenericInsuranceProductDocumentMutation,
  useAdminCreateGenericProductDocumentMutation,
  useAdminCreateInvestmentProductDocumentMutation,
  useAdminCreateLifeInsuranceProductDocumentMutation,
  useAdminCreateLoanProductDocumentMutation,
  useAdminCreateMortgageProductDocumentMutation,
  useAdminCreateMotorLiabilityInsuranceProductDocumentMutation,
  useAdminCreatePropertyInsuranceProductDocumentMutation,
  useAdminCreateRetirementPensionSavingsProductDocumentMutation,
  useAdminCreateSavingsProductDocumentMutation,
  useAdminCreateSupplementaryPensionSavingsProductDocumentMutation,
} from 'generated/graphql';

type MutationInput = Exact<{
  document: DocumentInput;
  productId: string;
}>;

type AdminCreateProductDocumentMutation =
  | MutationTuple<AdminCreateBuildingSavingsProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateCollisionInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateCreditCardProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateGenericInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateGenericProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateInvestmentProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateLifeInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateLoanProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateMortgageProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateMotorLiabilityInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreatePropertyInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateRetirementPensionSavingsProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateSavingsProductDocumentMutation, MutationInput>
  | MutationTuple<AdminCreateSupplementaryPensionSavingsProductDocumentMutation, MutationInput>;

export const useAdminCreateProductDocument = (type: FinancialProductType): AdminCreateProductDocumentMutation => {
  const mutation = {
    [FinancialProductType.BuildingSavings]: useAdminCreateBuildingSavingsProductDocumentMutation(),
    [FinancialProductType.CollisionInsurance]: useAdminCreateCollisionInsuranceProductDocumentMutation(),
    [FinancialProductType.CreditCard]: useAdminCreateCreditCardProductDocumentMutation(),
    [FinancialProductType.GenericInsurance]: useAdminCreateGenericInsuranceProductDocumentMutation(),
    [FinancialProductType.Generic]: useAdminCreateGenericProductDocumentMutation(),
    [FinancialProductType.Investment]: useAdminCreateInvestmentProductDocumentMutation(),
    [FinancialProductType.LifeInsurance]: useAdminCreateLifeInsuranceProductDocumentMutation(),
    [FinancialProductType.Loan]: useAdminCreateLoanProductDocumentMutation(),
    [FinancialProductType.Mortgage]: useAdminCreateMortgageProductDocumentMutation(),
    [FinancialProductType.MotorLiabilityInsurance]: useAdminCreateMotorLiabilityInsuranceProductDocumentMutation(),
    [FinancialProductType.PropertyInsurance]: useAdminCreatePropertyInsuranceProductDocumentMutation(),
    [FinancialProductType.RetirementPensionSavings]: useAdminCreateRetirementPensionSavingsProductDocumentMutation(),
    [FinancialProductType.Savings]: useAdminCreateSavingsProductDocumentMutation(),
    [FinancialProductType.SupplementaryPensionSavings]:
      useAdminCreateSupplementaryPensionSavingsProductDocumentMutation(),
  }[type];

  return mutation;
};
