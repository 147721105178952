import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormDatePicker } from 'common/form/components/FormDatePicker/FormDatePicker';
import { transformNaNtoNull } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { t } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  referenceDate?: Date;
}

export interface SetDateInputDialogProps extends DialogComponentProps<Date> {
  referenceDate?: Date;
}

export const SetReferenceDateInputDialogContent: React.FC<SetDateInputDialogProps> = ({ onResolve, referenceDate }) => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    referenceDate: yup
      .date()
      .transform(transformNaNtoNull)
      .nullable(true)
      .required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      referenceDate: referenceDate,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve(data.referenceDate);
  });

  return (
    <Box>
      <FormProvider {...formMethods}>
        <Box component="form" mt={2} noValidate onSubmit={handleSubmit}>
          <Box>
            <FormDatePicker name="referenceDate" />
          </Box>
          <Box mt={8} width="100%">
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('upcomingPaymentDetail.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export const SetReferenceDateInputDialog: React.FC<SetDateInputDialogProps> = (props) => (
  <PromptDialogOnSide
    onClose={() => props.onResolve()}
    open={props.isOpen}
    subtitle={t('upcomingPaymentDetail.nextPaymentDescription')}
    title={t('upcomingPaymentDetail.nextPayment')}
  >
    <SetReferenceDateInputDialogContent {...props} />
  </PromptDialogOnSide>
);
