import { SxProps, Theme } from '@mui/material';

export const styles: Record<'loading' | 'background' | 'content' | 'fullScreen', SxProps<Theme>> = {
  background: {
    backgroundColor: 'inherit',
    borderRadius: 'inherit',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
  },
  fullScreen: {
    backgroundColor: (theme) => theme.palette.gray[50],
    position: 'fixed',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  loading: {
    height: '16px',
    marginBottom: (theme) => theme.spacing(1),
    width: '16px',
  },
};
