import { ReactComponent as CreditCardColorIcon } from '@brand/assets/icons/CreditCardColor.svg';
import { ReactComponent as GridColorIcon } from '@brand/assets/icons/GridColor.svg';
import { Box } from '@mui/material';
import { ReactComponent as CreditCardIcon } from 'assets/icons/CreditCard.svg';
import { ReactComponent as GridIcon } from 'assets/icons/Grid.svg';
import { useTranslation } from 'react-i18next';

import { HomepageTab } from './HomepageTab';
import { styles } from './HomepageTabs.styles';

export enum HOMEPAGE_TABS {
  PRODUCTS = 'products',
  ACCOUNTS = 'accounts',
}

interface Props {
  activeTab: HOMEPAGE_TABS;
  setActiveTab: (tab: HOMEPAGE_TABS) => void;
}

export const HomepageTabs: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  const icons = {
    [HOMEPAGE_TABS.PRODUCTS]: {
      active: <GridColorIcon />,
      default: <GridIcon />,
    },
    [HOMEPAGE_TABS.ACCOUNTS]: {
      active: <CreditCardColorIcon />,
      default: <CreditCardIcon />,
    },
  };

  return (
    <Box sx={styles.container}>
      <HomepageTab
        icons={icons[HOMEPAGE_TABS.PRODUCTS]}
        isActive={activeTab === HOMEPAGE_TABS.PRODUCTS}
        label={t('homepage.tabs.products')}
        onClick={() => setActiveTab(HOMEPAGE_TABS.PRODUCTS)}
      />
      <HomepageTab
        icons={icons[HOMEPAGE_TABS.ACCOUNTS]}
        isActive={activeTab === HOMEPAGE_TABS.ACCOUNTS}
        label={t('homepage.tabs.accounts')}
        onClick={() => setActiveTab(HOMEPAGE_TABS.ACCOUNTS)}
      />
    </Box>
  );
};
