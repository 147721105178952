import { DialogTitle, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

export const styles: Record<'center' | 'left' | 'small' | 'large', SxProps<Theme>> = {
  center: {
    textAlign: 'center', // theme default value
  },
  large: {
    fontSize: 25, // theme default value
  },
  left: {
    textAlign: 'left',
  },
  small: {
    fontSize: 18,
  },
};

interface StyledDialogTitleProps {
  children?: ReactNode;
  size?: 'small' | 'large';
  textAlign?: 'left' | 'center';
}

export const StyledDialogTitle: React.FC<StyledDialogTitleProps> = ({
  children,
  size = 'large',
  textAlign = 'center',
}) => {
  return <DialogTitle sx={{ ...styles[textAlign], ...styles[size] } as SxProps}>{children}</DialogTitle>;
};
