import { Box, Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';

import { styles } from './FormError.style';

export interface ErrorFormProps {
  title: string;
  content: string;
}

export const FormError: React.FC<ErrorFormProps> = ({ content, title }) => {
  return (
    <Box sx={styles.error}>
      <Box sx={styles.content}>
        <Typography mb={0.5} variant="bodyStrongS">
          {title}
        </Typography>
        <Typography variant="bodyS">{content}</Typography>
      </Box>
      <Box sx={styles.icon}>
        <WarningIcon />
      </Box>
    </Box>
  );
};
