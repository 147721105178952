import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'switchRow', SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    margin: (theme) => theme.spacing(3, 0, 0, 0),
    overflow: 'hidden',
  },
  switchRow: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
};
