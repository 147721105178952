import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface StyledDialogSubtitleProps {
  children?: ReactNode;
  size?: 'small' | 'large';
  textAlign?: 'left' | 'center';
}

export const StyledDialogSubtitle: React.FC<StyledDialogSubtitleProps> = ({
  children,
  size = 'large',
  textAlign = 'center',
}) => {
  return (
    <Typography mt={1} textAlign={textAlign} variant={size === 'small' ? 'bodyS' : 'bodyL'}>
      {children}
    </Typography>
  );
};
