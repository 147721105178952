import { AccountListItem } from 'common/components/AccountListItem/AccountListItem';
import { useDialog } from 'common/hooks/useDialog';
import { formatCurrency } from 'common/utils/formatUtils';
import { Account, BalanceValueFragment } from 'generated/graphql';
import { i18n } from 'i18n';

import { AvailableFundsDialog, AvailableFundsDialogProps } from '../AvailableFundsDialog/AvailableFundsDialog';

export interface AccountListItemWrapperProps {
  aggregate: BalanceValueFragment;
  asOfDate: Date;
}

export const AccountListItemWrapper: React.FC<AccountListItemWrapperProps> = ({ aggregate, asOfDate }) => {
  const accountKey = aggregate.key.find((key) => key.__typename === 'Account') as Account;

  const detailDialog = useDialog<AvailableFundsDialogProps, void>(AvailableFundsDialog, {
    accountId: accountKey.id,
    instanceId: `available-funds-detail-${accountKey.id}`,
    navigable: true,
  });

  const handleClick = () => {
    detailDialog.create({ pointerDate: asOfDate });
  };

  return (
    <AccountListItem
      accountKey={accountKey}
      amount={formatCurrency(aggregate.balance.amount, i18n.language, 2, aggregate.balance.currency)}
      handleClick={handleClick}
      label={accountKey.name}
    />
  );
};
