import { Button, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { PeriodTypeEnum } from 'components/spendingReport/state/spendingReportTypes';
import { addPeriods } from 'components/spendingReport/utils';
import { endOfMonth, isSameMonth } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateBudgetDialog, UpdateBudgetDialogProps } from '../UpdateBudgetDialog/UpdateBudgetDialog';
import { useCashflowState } from '../useCashflowState';
import { styles } from './CashflowGuardianDialog.styles';
import { CashflowGuardianDialogContent } from './CashflowGuardianDialogContent';

export type CashflowGuardianDialogProps = DialogComponentProps<void>;

export const CashflowGuardianDialog: React.FC<CashflowGuardianDialogProps> = (props) => {
  const { t } = useTranslation();
  const [pointerDate, setPointerDate] = useState<Date>(new Date());
  const isCurrentMonth = isSameMonth(pointerDate, new Date());
  const asOfDate = isCurrentMonth ? pointerDate : endOfMonth(pointerDate);
  const cashflowState = useCashflowState(asOfDate);

  const updateBudgetDialog = useDialog<UpdateBudgetDialogProps, void>(UpdateBudgetDialog, {
    instanceId: 'update-budget',
  });

  const handleUpdateBudget = async () => {
    await updateBudgetDialog.create({ asOfDate, budget: cashflowState?.budget?.amount });
  };

  const handleArrowClick = (direction: -1 | 1) => {
    setPointerDate(addPeriods(pointerDate, PeriodTypeEnum.Month, direction));
  };

  return (
    <ResponsiveSideDialog
      dialogId={props.instanceId}
      header={
        isCurrentMonth ? (
          <Button color="tertiaryButton" onClick={handleUpdateBudget} sx={styles.manageButton}>
            <Typography color="blue.dark">{t('cashflowGuardian.header.updateBudget')}</Typography>
          </Button>
        ) : undefined
      }
      isOpen={props.isOpen}
      onClose={props.onResolve}
    >
      {cashflowState && (
        <CashflowGuardianDialogContent
          cashflowState={cashflowState}
          onArrowClick={handleArrowClick}
          pointerDate={pointerDate}
        />
      )}
    </ResponsiveSideDialog>
  );
};
