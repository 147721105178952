import { useReactiveVar } from '@apollo/client';
import { parseDatestrings } from 'common/utils/parseDatestrings';
import _ from 'lodash';
import { useEffect } from 'react';

import { spendingReportState } from './spendingReportState';
import { SpendingReportState } from './spendingReportTypes';

export const SPENDING_REPORT_STATE_LOCAL_STORAGE_KEY = 'spendingReportState';

interface UseSpendingReportStateReturn {
  state: SpendingReportState;
  update: (newState: SpendingReportState) => void;
  clear: () => void;
}

export const useSpendingReportState = (): UseSpendingReportStateReturn => {
  const state = useReactiveVar(spendingReportState);

  const update = (newState: SpendingReportState) => {
    const stateWithTimestamp = _.cloneDeep(newState);
    _.set(stateWithTimestamp, 'meta.updateTimestamp', new Date());
    localStorage.setItem(SPENDING_REPORT_STATE_LOCAL_STORAGE_KEY, JSON.stringify(stateWithTimestamp));
    spendingReportState(stateWithTimestamp);
  };

  const clear = () => {
    localStorage.removeItem(SPENDING_REPORT_STATE_LOCAL_STORAGE_KEY);
    spendingReportState();
  };

  useEffect(() => {
    if (!_.isEmpty(state)) {
      const savedData = localStorage.getItem(SPENDING_REPORT_STATE_LOCAL_STORAGE_KEY);
      const parsedData = savedData ? JSON.parse(savedData) : undefined;
      if (!_.isEmpty(parsedData)) {
        const dataWithDates = parseDatestrings(parsedData) as SpendingReportState;
        spendingReportState(dataWithDates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { clear, state, update };
};
