import { Button, Stack, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { FilterConditionInput, FilterOperator, TransactionTypeCode } from 'generated/graphql';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TransactionTypeTranslations } from 'translations/enums';

import { TypeSelectDialog, TypeSelectDialogProps } from '../selectDialogs/TypeSelectDialog';
import { Filter, FilterOption } from './Filter';

export const useTypeOptions = (): FilterOption[] => {
  const { t } = useTranslation();
  return [TransactionTypeCode.Transfer, TransactionTypeCode.Charge, TransactionTypeCode.Cash].map((option) => ({
    key: TransactionTypeTranslations(t)[option],
    label: TransactionTypeTranslations(t)[option],
    value: {
      arguments: [
        {
          filterCondition: {
            fieldPath: 'transactionTypeCode',
            operator: FilterOperator.Equal,
            values: [option],
          },
        },
      ],
    },
  }));
};

const NAME = 'type';

export const TypeFilter: React.FC = () => {
  const { t } = useTranslation();
  const { field: manualField } = useController({ name: `${NAME}.manual` });
  const { field: quickfilterField } = useController({ name: `${NAME}.quickfilter` });

  const typesDialog = useDialog<TypeSelectDialogProps, FilterConditionInput>(TypeSelectDialog, {
    filter: manualField.value,
    instanceId: 'filter-transactions-select-types',
    tmpNavigable: true,
  });

  const handleManualClick = async () => {
    try {
      const filter = await typesDialog.create({ filter: manualField.value });
      manualField.onChange(filter);
      quickfilterField.onChange(null);
    } catch (e) {
      //pass
    }
  };

  const isManual = !!manualField.value;

  const typeOptions = useTypeOptions();

  return (
    <Filter
      name={NAME}
      onManualClick={handleManualClick}
      options={typeOptions}
      title={t('filterTransactions.filter.type.title')}
    >
      {isManual && (
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography>
            {manualField.value.values
              .map((transactionTypeCode: TransactionTypeCode) => TransactionTypeTranslations(t)[transactionTypeCode])
              .join(', ')}
          </Typography>
          <Button color="tertiaryButton" onClick={handleManualClick}>
            <Typography color="blue.dark">{t('filterTransactions.filter.edit')}</Typography>
          </Button>
        </Stack>
      )}
    </Filter>
  );
};
