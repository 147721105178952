import { Box, ListItemText, MenuItem, MenuList } from '@mui/material';
import { ProductStatus } from 'generated/graphql';
import isEqual from 'lodash/isEqual';
import { Count, useStore } from 'react-admin';

export const productStatusFilters: Record<string, Record<string, string>> = {
  All: {},
  Processing: { status: ProductStatus.Processing },
  Submitted: { status: ProductStatus.Submitted },
};

export const ProductListAside: React.FC = () => {
  const [statusFilter, setStatusFilter] = useStore('resources.products.list.statusFilter', productStatusFilters.All);

  return (
    <Box flexShrink={0} mr={1} mt={7} order={-1} width={200}>
      <MenuList>
        {Object.keys(productStatusFilters).map((key) => (
          <MenuItem
            key={key}
            onClick={() => setStatusFilter(productStatusFilters[key])}
            selected={isEqual(statusFilter, productStatusFilters[key])}
          >
            <ListItemText>{key}</ListItemText>
            <Count color="text.disabled" filter={productStatusFilters[key]} />
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};
