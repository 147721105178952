import { Box, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import ReactEcharts from 'echarts-for-react';
import { AggregateTransactionsQuery, TransactionAggregationValueFragment } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { Period } from '../state/spendingReportTypes';
import { useChartOption } from './SpendingReportChart.chart';
import { styles } from './SpendingReportChart.styles';

export interface SpendingReportChartProps {
  byPeriodAndCategoryAggregates: TransactionAggregationValueFragment[];
  totalAggregate: AggregateTransactionsQuery['aggregateTransactions'];
  title: string;
  period: Period;
}

export const SpendingReportChart: React.FC<SpendingReportChartProps> = ({
  byPeriodAndCategoryAggregates,
  period,
  title,
  totalAggregate,
}) => {
  const option = useChartOption(byPeriodAndCategoryAggregates, totalAggregate.baseCurrency, period);
  const { i18n } = useTranslation();

  return (
    <Box mt={3} sx={styles.container}>
      {byPeriodAndCategoryAggregates.length > 0 && (
        <>
          <Typography variant="bodyM">{title}</Typography>
          <Typography variant="headlineL">
            {formatTransactionAmount(
              totalAggregate.resultData[0].sum.amount,
              i18n.language,
              2,
              totalAggregate.baseCurrency,
            )}
          </Typography>
          <Box sx={styles.chart}>
            <ReactEcharts
              notMerge={true}
              option={option}
              style={{ height: '100%', pointerEvents: 'none', width: '100%' }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
