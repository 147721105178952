import { Box, Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Image } from 'common/components/Image/Image';

import { styles } from './Banner.styles';

interface BannerProps {
  title: string;
  text: string;
  tour?: string;
  Icon?: React.ReactNode;
  imageSrc: string;
  alignItems?: React.CSSProperties['alignItems'];
  onTourClick?: () => void;
}

export const Banner: React.FC<BannerProps> = ({
  Icon,
  alignItems = 'top',
  imageSrc,
  onTourClick,
  text,
  title,
  tour,
}) => {
  const mediaQuery = useMediaQuery;
  const isLarge = mediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Stack alignItems={alignItems} direction="row" sx={styles.container}>
      <Stack sx={styles.text}>
        <Stack spacing={1}>
          {Icon && <Box>{Icon}</Box>}
          <Typography variant="headlineL">{title}</Typography>
          <Typography variant="bodyL">{text}</Typography>
        </Stack>
        {tour && onTourClick && (
          <Stack direction="row" ml={-1.5} mt={1} spacing={1}>
            <Button color="tertiaryButton" onClick={onTourClick} size="medium">
              <Typography color="primary" variant="bodyL">
                {tour}
              </Typography>
            </Button>
          </Stack>
        )}
      </Stack>

      <Box sx={styles.imageContainer}>
        {isLarge ? (
          <Image
            src={imageSrc}
            style={{
              borderRadius: '0 10px 10px 0',
              display: 'block',
            }}
          />
        ) : (
          <Image
            src={imageSrc}
            style={{
              borderRadius: '0 0 10px 10px',
              display: 'block',
              height: 'auto',
              maxWidth: '100%',
              width: 'auto',
            }}
          />
        )}
      </Box>
    </Stack>
  );
};
