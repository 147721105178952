import { LoadingButton } from '@mui/lab';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useUpdateUserMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { styles } from './VerifyEmailChangeDialog.styles';

export interface VerifyEmailChangeDialogProps extends DialogComponentProps<void> {
  email: string;
}

export const VerifyEmailChangeDialog: React.FC<VerifyEmailChangeDialogProps> = ({ email, isOpen, onResolve }) => {
  const { t } = useTranslation();

  const [updateUserEmail, { error, loading }] = useUpdateUserMutation({});

  const handleEmailResend = async () => {
    await updateUserEmail({
      variables: {
        input: {
          email,
        },
      },
    });
  };

  return (
    <StyledDialog fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle>{t('verifyEmailChange.title')}</StyledDialogTitle>
      <StyledDialogSubtitle>{t('verifyEmailChange.subtitle', { email })}</StyledDialogSubtitle>
      <LoadingButton
        color="tertiaryButton"
        loading={loading}
        onClick={handleEmailResend}
        size="small"
        sx={styles.buttonLink}
      >
        {t('registrationVerification.sendAgainButton')}
      </LoadingButton>
      {!!error && (
        <StyledDialogFormError content={t('common:somethingWentWrong')} title={t('settings.changeDialog.error')} />
      )}
    </StyledDialog>
  );
};
