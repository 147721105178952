import { FormControl, InputProps, OutlinedInput } from '@mui/material';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InputEndAdornment } from 'common/components/InputEndAdornment/InputEndAdornment';
import { InputLabel } from 'common/components/InputLabel/InputLabel';
import React from 'react';
import { useController } from 'react-hook-form';

import { FormElementProps } from './FormElement';

export interface FormInputBaseProps extends FormElementProps {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  InputProps?: Partial<InputProps>;
  size?: 'large' | 'medium';
  autoFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const FormInputBase: React.FC<FormInputBaseProps> = ({
  InputProps,
  autoComplete,
  autoFocus,
  disabled,
  fullWidth,
  helperText,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  size,
  sx,
  type,
}) => {
  const { field, fieldState } = useController({ name });

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    field.onChange(event);
    onChange?.();
  };

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = () => {
    field.onBlur();
    onBlur?.();
  };

  const error = fieldState?.error?.message;
  return (
    <>
      <FormControl error={!!error} fullWidth={fullWidth} sx={sx}>
        {!!label && <InputLabel helperText={helperText} label={label} size={size} />}
        <OutlinedInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          {...InputProps}
          disabled={disabled}
          endAdornment={<InputEndAdornment error={Boolean(error)}>{InputProps?.endAdornment}</InputEndAdornment>}
          inputProps={{
            onWheelCapture: (e) => {
              e.currentTarget.blur();
            },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          size={size}
          type={type}
          value={field.value ?? ''}
        />

        {error && <FormHelperText size={size}>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};
