import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FormValues {
  note?: string | null;
}

export interface EditNoteDialogProps extends DialogComponentProps<string | undefined | null> {
  note?: string | null;
}

export const EditNoteDialog: React.FC<EditNoteDialogProps> = ({ isOpen, note, onResolve }) => {
  const { t } = useTranslation();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      note,
    } as FormValues,
    mode: 'onSubmit',
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve(data.note);
  });

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={t('transactionDetail.editNote.text')}
      title={t('transactionDetail.editNote.title')}
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput InputProps={{ minRows: 2, multiline: true }} autoFocus fullWidth name="note" />
          </Box>

          <Stack mt={3} spacing={1}>
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('transactionDetail.editNote.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
