import { SxProps, Theme } from '@mui/material';

export const styles: Record<'box' | 'profileImage', SxProps<Theme>> = {
  box: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.10))',
    },
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    flexGrow: 1,
    padding: (theme) => theme.spacing(3, 4),
    width: '100%',
  },
  profileImage: {
    borderRadius: '100%',
    height: 96,
    width: 96,
  },
};
