import { ClientInput } from 'admin/inputs/ClientInput';
import { DateField, List } from 'react-admin';

import { ProcessDatagrid } from '../ProcessDatagrid';

export const MortgagePurchaseProcessList: React.FC = () => {
  const otherFilters = [<ClientInput includeUnregistered={true} label="Klient" source="client.id" />];

  return (
    <List exporter={false} filters={otherFilters}>
      <ProcessDatagrid
        acceptedField={<DateField label="Akceptované" locales="sk" source="acceptedTimestamp" />}
        showClient={true}
      />
    </List>
  );
};
