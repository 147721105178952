import { Box, Button, DialogActions } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import { StyledDialog } from '../StyledDialog/StyledDialog';
import { StyledDialogSubtitle } from '../StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from '../StyledDialog/StyledDialogTitle';
import { styles } from './InfoDialog.styles';

export interface InfoDialogProps extends DialogComponentProps<void> {
  title?: string;
  message: React.ReactNode;
}

export const InfoDialog: React.FC<InfoDialogProps> = ({ isOpen, message, onResolve, title }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <Box sx={styles.icon}>
        <InfoIcon height={16} width={16} />
      </Box>
      {title && <StyledDialogTitle size="small">{title}</StyledDialogTitle>}
      <StyledDialogSubtitle size="small" textAlign="left">
        {message}
      </StyledDialogSubtitle>
      <DialogActions>
        <Button fullWidth onClick={() => onResolve()} size="medium">
          {t('infoDialog.close')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
