import { useReactiveVar } from '@apollo/client';
import { WEB_URL } from '@brand/const';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as AlarmIcon } from 'assets/icons/Alarm.svg';
import { ReactComponent as CardTravelIcon } from 'assets/icons/CardTravel.svg';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/Person.svg';
import MortgageAvatar from 'assets/images/mortgage-avatar.png';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatCurrency } from 'common/utils/formatUtils';
import { styles } from 'components/calculators/CalculatorResult/CalculatorResult.styles';
import { MORTGAGE_PURCHASE_BASE_URL, PRODUCTS_MANAGEMENT_BASE_URL } from 'const';
import { Money, RequestContactContext } from 'generated/graphql';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { mortgageCalculatorState } from '../MortgageCalculator.utils';

interface Props {
  amount: Money;
}

export const MortgageCalculatorResult: React.FC<Props> = ({ amount }) => {
  const state = useReactiveVar(mortgageCalculatorState);
  const { isAuthenticated } = useContext(AuthContext);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const handleOffers = () => {
    navigate(MORTGAGE_PURCHASE_BASE_URL, { replace: true });
  };

  const openContactDialog = useContactDialog({
    avatarSrc: MortgageAvatar,
    context: RequestContactContext.NewMortgage,
    jobTitle: t('flows/mortgage/purchase:contact.jobTitle'),
    name: t('flows/mortgage/purchase:contact.name'),
    userEmail: state.contact?.email,
    userPhone: state.contact?.phone,
  });

  const handleBackButton = () => {
    isAuthenticated ? navigate(PRODUCTS_MANAGEMENT_BASE_URL, { replace: true }) : window.location.assign(WEB_URL);
  };

  return (
    <Stack sx={styles.container}>
      <Typography variant="headlineXL">{t('flows/mortgage/calculator:result.title')}</Typography>
      <Typography sx={styles.amount}>{formatCurrency(amount.amount, i18n.language, 0, amount.currency)}</Typography>
      <Box sx={styles.line} />
      <Typography variant="headlineL">{t('flows/mortgage/calculator:result.subtitle')}</Typography>

      <Stack alignItems="center" justifyContent="center" mt={4} sx={styles.benefits}>
        <Typography color="textSecondary" variant="bodyM">
          {t('flows/mortgage/calculator:result.benefits.1')}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Stack direction="row" spacing={1}>
            <AlarmIcon />
            <Typography color="textSecondary" variant="bodyM">
              {t('flows/mortgage/calculator:result.benefits.2')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <CardTravelIcon />
            <Typography color="textSecondary" variant="bodyM">
              {t('flows/mortgage/calculator:result.benefits.3')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={styles.boxes}>
        <Stack sx={styles.box}>
          <Box sx={styles.greenBox}>
            <Typography color="inherit" variant="bodyM">
              <Trans i18nKey="flows/mortgage/calculator:result.offers.greenBox" />
            </Typography>
          </Box>
          <Box sx={styles.redCircle}>
            <EditIcon />
          </Box>
          <Typography variant="headlineXS">{t('flows/mortgage/calculator:result.offers.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/mortgage/calculator:result.offers.subtitle')}
          </Typography>
          <Box mt={6}>
            <Button color="tertiaryButton" onClick={handleOffers}>
              {t('flows/mortgage/calculator:result.offers.button')}
            </Button>
          </Box>
        </Stack>
        <Stack sx={styles.box}>
          <Box sx={styles.redCircle}>
            <PersonIcon />
          </Box>
          <Typography variant="headlineXS">{t('flows/mortgage/calculator:result.contact.title')}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('flows/mortgage/calculator:result.contact.subtitle')}
          </Typography>
          <Box mt={6}>
            <Button color="tertiaryButton" onClick={openContactDialog}>
              {t('flows/mortgage/calculator:result.contact.button')}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Button color="secondaryButton" onClick={handleBackButton} sx={styles.homepageButton}>
        {t('flows/mortgage/calculator:result.backButton')}
      </Button>
    </Stack>
  );
};
