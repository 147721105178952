import { makeVar, ReactiveVar } from '@apollo/client';
import {
  mapLifeInsuranceInquiryToState,
  mapLifeInsuranceStateToInput,
  trimLifeInsuranceState,
} from 'components/flows/common/lifeInsurance/state/lifeInsuranceState';
import { DocumentFragment, LifeInsuranceInquiryFragment, LifeInsuranceInquiryInput } from 'generated/graphql';

import { FileStatusEnum, LifeInsuranceAuditState } from './lifeInsuranceAuditTypes';

const initialValues: LifeInsuranceAuditState = {};
export const lifeInsuranceAuditState: ReactiveVar<LifeInsuranceAuditState> =
  makeVar<LifeInsuranceAuditState>(initialValues);

export const mapLifeInsuranceAuditStateToInput = (state: LifeInsuranceAuditState): LifeInsuranceInquiryInput => {
  return {
    ...mapLifeInsuranceStateToInput({ applicants: state.applicants, meta: state.meta }),
  };
};

export const mapLifeInsuranceAuditInquiryToState = (
  documents?: DocumentFragment | null,
  inquiry?: LifeInsuranceInquiryFragment | null,
  updateTimestamp?: Date | null,
): LifeInsuranceAuditState => {
  return {
    documentId: documents?.id ?? '',
    files:
      documents?.files.map((file) => ({
        id: file.id,
        name: file.name ?? '',
        status: FileStatusEnum.success,
      })) ?? [],
    ...mapLifeInsuranceInquiryToState(inquiry, updateTimestamp),
  };
};

export const trimLifeInsuranceAuditState = (state: LifeInsuranceAuditState): LifeInsuranceAuditState => {
  return {
    documentId: state.documentId,
    files: state.files,
    ...trimLifeInsuranceState(state),
  };
};
