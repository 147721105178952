import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { getWeek, getYear, startOfWeek, subWeeks } from 'date-fns';
import { sk } from 'date-fns/locale';
import { Account } from 'generated/graphql';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const WEEKS_COUNT = 30;

interface WeekItem {
  week: string;
  label: string;
  accounts: number;
  expiredAccounts: number;
}

interface Props {
  isLoading: boolean;
  accounts?: Account[];
}

export const AccountsChart: React.FC<Props> = ({ accounts, isLoading }) => {
  const weeks = [] as WeekItem[];
  for (let i = WEEKS_COUNT; i >= 0; i--) {
    const week = startOfWeek(subWeeks(new Date(), i), { locale: sk });
    weeks.push({
      accounts: 0,
      expiredAccounts: 0,
      label: getWeek(week, { locale: sk }).toString() + '/' + getYear(week).toString(),
      week: week.toISOString(),
    });
  }

  accounts?.forEach((account) => {
    // account created
    const weekIndexCreated = weeks.findIndex(
      (weekItem) => weekItem.week == startOfWeek(account.creationTimestamp ?? new Date(), { locale: sk }).toISOString(),
    );
    if (weekIndexCreated > -1) {
      weeks[weekIndexCreated].accounts++;
    }
    // account expired
    const weekIndexExpired = weeks.findIndex(
      (weekItem) => weekItem.week == startOfWeek(account.accessUntil ?? new Date(), { locale: sk }).toISOString(),
    );
    if (weekIndexExpired > -1) {
      weeks[weekIndexExpired].expiredAccounts--;
    }
  });

  return isLoading ? (
    <Skeleton height={404} variant="rectangular" />
  ) : (
    <Card>
      <CardHeader title="Účty" />
      <CardContent>
        <ResponsiveContainer minHeight={300}>
          <BarChart
            data={weeks}
            margin={{
              bottom: 30,
              left: -25,
              right: 25,
              top: -25,
            }}
            stackOffset="sign"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis angle={45} dataKey="label" textAnchor="begin" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" />
            <Bar dataKey="accounts" fill="#2196F3" name="Pripojené účty" stackId="a" />
            <Bar dataKey="expiredAccounts" fill="#ED4435" name="Exspirované účty" stackId="a" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
