import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/ArrowRight.svg';

import { styles } from './Stepper.styles';

export interface IntroStep {
  title: string;
  content?: string[];
  message?: React.ReactElement;
}

interface Props {
  steps: IntroStep[];
  mb?: number;
}

export const Stepper: React.FC<Props> = ({ mb, steps }) => {
  return (
    <Stack mb={mb ?? 6} spacing={2}>
      {steps.map((step, index) => (
        <Box key={index} sx={styles.stepper}>
          <Typography component="div" sx={styles.number} variant="headlineXL">
            0{index + 1}
          </Typography>
          <Typography component="h3" variant="bodyXXXL">
            {step.title}
          </Typography>

          {steps.length > index + 1 && (
            <>
              <Box sx={styles.connectorWrapper}>
                <Box sx={styles.connector} />
              </Box>
              {!!step.message && <Box>{step.message}</Box>}
              {!!step.content && (
                <Stack paddingY={1} spacing={1.5}>
                  {step.content?.map((subStep, subStepIndex) => (
                    <Stack key={subStepIndex} alignItems="center" direction="row" spacing={2}>
                      <Box sx={styles.arrow}>
                        <ArrowIcon height={16} width={16} />
                      </Box>
                      <Typography variant="bodyL">{subStep}</Typography>
                    </Stack>
                  ))}
                </Stack>
              )}
            </>
          )}
        </Box>
      ))}
    </Stack>
  );
};
