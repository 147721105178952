import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'chart', SxProps<Theme>> = {
  chart: {
    height: '192px',
  },
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: (theme) => theme.spacing(3, 4, 2, 4),
    width: '100%',
  },
};
