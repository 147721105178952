import { useApolloClient } from '@apollo/client';
import { Skeleton, Stack } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import {
  convertJobStatusErrorMessage,
  NordigenFlowError,
  NordigenFlowStatus,
} from 'components/accounts/AccountConnectUtils/AccountConnectUtils';
import {
  BulkRenameAccountsDialog,
  BulkRenameAccountsDialogProps,
} from 'components/accounts/BulkRenameAccountsDialog/BulkRenameAccountsDialog';
import { AccountsDataLoader } from 'components/HomePage/AccountsDataLoader/AccountsDataLoader';
import { RecentTransactions } from 'components/HomePage/RecentTransactions/RecentTransactions';
import {
  JobStatusType,
  useConnectedAccountsQuery,
  useJobStatusLazyQuery,
  useRefreshAccountsDataMutation,
  useSynchronizeAccountsMutation,
} from 'generated/graphql';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AddAccountBanner } from '../AddAccountBanner/AddAccountBanner';
import { Dashboard } from '../Dashboard/Dashboard';

const SYNCHRONIZE_ACCOUNTS_MAX_RETRY_COUNT = 10;
const SYNCHRONIZE_ACCOUNTS_RETRY_DELAY = 30 * 1000;

export const HomepageAccounts: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated, isLoading: isLoadingAuth } = useContext(AuthContext);

  const { data: dataConnectedAccounts, loading: loadingConnectedAccounts } = useConnectedAccountsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
  });

  const bulkRenameAccountsDialog = useDialog<BulkRenameAccountsDialogProps, void>(BulkRenameAccountsDialog, {
    navigable: true,
  });

  const removeNordigenSearchParams = (): void => {
    searchParams.delete('nordigenFlowStatus');
    searchParams.delete('ref');
    searchParams.delete('error');
    setSearchParams(searchParams, { replace: true });
  };

  /* 
    Following code performs these account synchronization steps:
    1. check if user has returned from Nordigen authorization flow (variable nordigenFlowStatus in query string is "Finished")
    2. check if Nordigen authorization flow was sucessfull and we have requisitionId in query string (variable ref)
    3. run synchronizeAccounts mutation if we have requisitionId, obtain new accounts and display dialog for renaming them
    4. in case of error retry synchronizeAccounts mutation 
       (max SYNCHRONIZE_ACCOUNTS_MAX_RETRY_COUNT times with SYNCHRONIZE_ACCOUNTS_RETRY_DELAY between attempts)
    5. in case of successful obtaining of new accounts run full refresh of accounts data 
       (it's BE async job so we need to poll getJobStatus query until job status is done)
  */

  const client = useApolloClient();
  const [requisitionId, setRequisitionId] = useState<string>();
  const [loadingNewAccountsData, setLoadingNewAccountsData] = useState(false);

  const [synchronizeAccountsRetryCount, setSynchronizeAccountsRetryCount] = useState(0);
  const [synchronizeAccounts] = useSynchronizeAccountsMutation({
    onCompleted: async (data) => {
      // if we have at least one new account, show dialog for renaming accounts (and continue syncing to save time)
      if (data.newAccounts.length > 0) {
        const newAccountsIds = data.newAccounts.map((account) => account.id);
        bulkRenameAccountsDialog.create({
          ids: newAccountsIds,
          instanceId: `bulk-rename-accounts-${newAccountsIds.join('-')}`,
        });
      }
      // perform full refresh of accounts data
      await client.reFetchObservableQueries();
      const { data: dataRefreshAccounts } = await refreshAccountsData();
      const jobId = dataRefreshAccounts?.jobId;
      if (jobId) {
        startPolling(1000);
        getJobStatus({ variables: { jobId } });
      }
    },
    onError: async ({ graphQLErrors }) => {
      // ignore ConcurrentAccountSynchronizationException and do nothing (user is synchronizing on another device / in another window)
      if (graphQLErrors.some((error) => error.message.includes('ConcurrentAccountSynchronizationException'))) {
        // just remove URL params here and stop loader, no error message
        removeNordigenSearchParams();
        setLoadingNewAccountsData(false);
      } else {
        // retry max SYNCHRONIZE_ACCOUNTS_MAX_RETRY_COUNT times (with SYNCHRONIZE_ACCOUNTS_RETRY_DELAY between attempts)
        if (synchronizeAccountsRetryCount < SYNCHRONIZE_ACCOUNTS_MAX_RETRY_COUNT) {
          setTimeout(async () => {
            setSynchronizeAccountsRetryCount((prevCount) => prevCount + 1);
            await synchronizeAccounts();
          }, SYNCHRONIZE_ACCOUNTS_RETRY_DELAY);
        } else {
          // if all attemps were unsuccessful, display proper error message and remove URL params
          if (graphQLErrors) {
            notify({ message: t('homepage.accounts.errors.thirdPartyError'), type: 'error' });
          } else {
            notify({ message: t('common:somethingWentWrong'), type: 'error' });
          }
          removeNordigenSearchParams();
          setLoadingNewAccountsData(false);
        }
      }
    },
    variables: { requisitionId },
  });

  const [refreshAccountsData] = useRefreshAccountsDataMutation();
  const [getJobStatus, { startPolling, stopPolling }] = useJobStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      if (data.getJobStatus.status == JobStatusType.Done || data.getJobStatus.status == JobStatusType.Error) {
        stopPolling();
        if (data.getJobStatus.status == JobStatusType.Done) {
          await client.reFetchObservableQueries();
        } else {
          notify({ message: convertJobStatusErrorMessage(data.getJobStatus.errorMessage, t), type: 'error' });
        }
        // we are calling removeNordigenSearchParams as last step so in case of possible page refresh we still display
        // loader to user and go through the process again
        removeNordigenSearchParams();
        // stop displaying loader after everything is prepared and refreshed
        setLoadingNewAccountsData(false);
      }
    },
  });

  useEffect(() => {
    if (!requisitionId) return;

    const fetchNewAccounts = async () => {
      await synchronizeAccounts();
    };

    fetchNewAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisitionId]);

  useEffect(() => {
    const getRequisitionId = async () => {
      // On successful return from Nordigen authorization flow we have following set in query string:
      // - nordigenFlowStatus is "Finished"
      // - ref is set to valid UUID (requisitionId of Nordigen connection)
      // - error is not set
      // On unsuccessful return from Nordigen authorization flow we have following set in query string:
      // - nordigenFlowStatus is "Finished"
      // - error is set to one of NordigenFlowError
      if (searchParams.get('nordigenFlowStatus') === NordigenFlowStatus.Finished) {
        if (searchParams.get('error')) {
          // we are ignoring UserCancelledSession error because this type of error is returned if user
          // intentionally cancel the account addition flow
          if (searchParams.get('error') != NordigenFlowError.UserCancelledSession) {
            notify({ message: t('homepage.accounts.errors.thirdPartyError'), type: 'error' });
          }
          removeNordigenSearchParams();
        } else {
          const requisitionId = searchParams.get('ref');
          if (requisitionId) {
            setLoadingNewAccountsData(true);
            setRequisitionId(requisitionId);
            // useEffect hook (see above) starts account synchronization in case of not empty requisitionId
          } else {
            notify({ message: t('common:somethingWentWrong'), type: 'error' });
            removeNordigenSearchParams();
          }
        }
      }
    };

    getRequisitionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingNewAccountsData) {
    return <AccountsDataLoader />;
  }

  if (loadingConnectedAccounts || isLoadingAuth) {
    return <Skeleton height={606} variant="rectangular" />;
  }

  if (!!dataConnectedAccounts?.accounts && dataConnectedAccounts?.accounts?.items.length > 0) {
    return (
      <Stack spacing={4}>
        <Dashboard />
        <RecentTransactions />
      </Stack>
    );
  }

  return <AddAccountBanner />;
};
