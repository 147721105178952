import { Stack, Typography } from '@mui/material';
import { formatCurrency } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ExtendedTransactionGroup } from './utils';

interface UpcomingPaymentHeaderProps {
  group: ExtendedTransactionGroup;
}

export const UpcomingPaymentHeader: React.FC<UpcomingPaymentHeaderProps> = ({ group }) => {
  const { i18n, t } = useTranslation();
  return (
    <Stack spacing={3}>
      <Stack alignItems="center" spacing={0.5}>
        <Typography variant="headlineXL">
          {group.period &&
            formatCurrency(
              group.period.expectedAmount?.amount ?? 0,
              i18n.language,
              2,
              group.period.expectedAmount?.currency,
              'never',
            )}
        </Typography>
        {group.period?.expectedDate && (
          <Typography color="textSecondary" variant="bodyM">{`${t('upcomingPaymentDetail.nextPayment')} ${format(
            group.period.expectedDate,
            'dd.MM.yyyy',
          )}`}</Typography>
        )}
      </Stack>
    </Stack>
  );
};
