import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useLifeInsurancePurchaseState } from 'components/flows/lifeInsurancePurchase/state/useLifeInsurancePurchaseState';
import { LIFE_INSURANCE_PURCHASE_BASE_URL } from 'const';
import { useDeleteLifeInsurancePurchaseProcessMutation, useLifeInsurancePurchaseProcessQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyhoonProcessPreviewContent } from './MoneyhoonProcessPreviewContent';

export interface LifeInsurancePurchasePreviewDialogProps extends DialogComponentProps<void> {
  id?: string;
}

export const LifeInsurancePurchaseProductPreviewDialog: React.FC<LifeInsurancePurchasePreviewDialogProps> = (props) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { isAuthenticated } = useContext(AuthContext);
  const { clear, state } = useLifeInsurancePurchaseState();

  const { data } = useLifeInsurancePurchaseProcessQuery({
    skip: !isAuthenticated || !props.id,
    variables: {
      id: props.id ?? '',
    },
  });

  const [deleteProcess, { loading: loadingRemove }] = useDeleteLifeInsurancePurchaseProcessMutation({
    onCompleted: () => {
      props.onResolve();
    },
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['AllProcesses'],
    variables: {
      id: props.id ?? '',
    },
  });

  const handleRemove = async () => {
    if (props.id) {
      await deleteProcess();
    } else {
      clear();
      props.onResolve();
    }
  };

  const navigatePath = props.id
    ? `${LIFE_INSURANCE_PURCHASE_BASE_URL}/${props.id}/current-step`
    : `${LIFE_INSURANCE_PURCHASE_BASE_URL}/current-step`;

  const progress = isAuthenticated ? data?.process.inquiry?.inquiryProgress : state.meta?.progress;

  const updateTimestamp = isAuthenticated ? data?.process.updateTimestamp : state.meta?.updateTimestamp;

  const accepted = !!data?.process.locked;

  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <MoneyhoonProcessPreviewContent
        accepted={accepted}
        color="orange"
        loadingRemove={loadingRemove}
        navigatePath={navigatePath}
        onRemoveClick={handleRemove}
        progress={progress}
        title={t('products:cards.lifeInsurancePurchase.title')}
        updateDate={updateTimestamp}
      />
    </ResponsiveSideDialog>
  );
};
