import { SxProps, Theme } from '@mui/material';
import { theme } from 'themes/theme';

export const styles: Record<'image' | 'button' | 'textBox' | 'icon', SxProps<Theme>> = {
  button: {
    marginTop: (theme) => theme.spacing(8),
  },
  icon: {
    minWidth: 48,
  },
  image: {
    alignItems: 'center',
    background: 'linear-gradient(#01A963, #004428)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    display: 'flex',
    height: 96,
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(1),
    width: 96,
  },
  textBox: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    marginTop: (theme) => theme.spacing(6),
    padding: (theme) => ({
      sm: theme.spacing(6),
      xxs: theme.spacing(2),
    }),
  },
};
