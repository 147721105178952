import { SxProps, Theme } from '@mui/material';

export const styles: Record<'box' | 'hover' | 'fullWidth', SxProps<Theme>> = {
  box: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },

  fullWidth: {
    width: '100%',
  },

  hover: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.10))',
    },
  },
};
