import { Breadcrumbs } from '@mui/material';
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { Breadcrumb } from 'common/components/Breadcrumb/Breadcrumb';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: ReactNode;
}

export const CurrentPageButtons: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs>
      <Breadcrumb startIcon={<HomeIcon />} text={t('flows.header.navigation.home')} to="/" />
      {children}
    </Breadcrumbs>
  );
};
