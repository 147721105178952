import { ChevronRight } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { Image } from 'common/components/Image/Image';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { useTranslation } from 'react-i18next';

import { styles } from './ContactExpert.styles';

export interface ContactExpertProps {
  avatarSrc?: string;
  name?: string;
  jobTitle?: string;
  phone?: string;
  email?: string;
}

export const ContactExpert: React.FC<ContactExpertProps> = ({ avatarSrc, email, jobTitle, name, phone }) => {
  const { t } = useTranslation();

  const openContactDialog = useContactDialog({
    avatarSrc,
    jobTitle,
    name,
  });

  return (
    <>
      {name && (
        <Box mt={5}>
          <Typography variant="headlineM">{t('products:detail.contactExpert.title')}</Typography>
        </Box>
      )}

      <Stack direction="row" gap={2} onClick={openContactDialog} sx={styles.container}>
        <Image mb={1} src={avatarSrc} sx={styles.image} />

        {name ? (
          <Stack flexGrow={1} spacing={0.25}>
            <Typography variant="bodyL">{name}</Typography>
            {phone && (
              <Typography color="textSecondary" variant="bodyM">
                {phone}
              </Typography>
            )}
            {email && (
              <Typography color="textSecondary" variant="bodyM">
                {email}
              </Typography>
            )}
          </Stack>
        ) : (
          <Stack flexGrow={1}>
            <Typography variant="bodyStrongL">{t('products:detail.contactExpert.title')}</Typography>
            <Typography color="textSecondary" variant="bodyM">
              {t('products:detail.contactExpert.subtitle')}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" spacing={0.5}>
          {name && (
            <Typography color="textSecondary" variant="bodyL">
              {t('products:detail.contactExpert.contact')}
            </Typography>
          )}
          <ChevronRight sx={styles.icon} />
        </Stack>
      </Stack>
    </>
  );
};
