import { Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Link } from '../Link/Link';

const style = {
  link: {
    '&:hover': {
      background: (theme: Theme) => theme.palette.gray[200],
    },
    alignItems: 'center',
    borderRadius: (theme: Theme) => theme.spacing(0.5),
    display: 'flex',
    gap: (theme: Theme) => theme.spacing(1),
    padding: (theme: Theme) => theme.spacing(1, 1.5),
  },
  text: {
    color: (theme: Theme) => theme.palette.gray[900],
  },
};

interface LinkProps {
  to: string;
  text: string;
  startIcon?: ReactNode;
}

export const Breadcrumb: React.FC<LinkProps> = ({ startIcon, text, to }) => {
  return (
    <Link sx={style.link} to={to}>
      {startIcon}
      <Typography sx={style.text} variant="bodyStrongS">
        {text}
      </Typography>
    </Link>
  );
};
