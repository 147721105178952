import {
  ProductTourBaseProps,
  ProductTourDialog,
  TourStep,
} from 'common/components/ProductTourDialog/ProductTourDialog';
import { useTranslation } from 'react-i18next';

import { AccountsManagementInfo } from './AccountsManagementInfo';
import { CashflowGuardianInfo } from './CashflowGuardianInfo';
import { SpendingReportInfo } from './SpendingReportInfo';

export type AccountsProductTourProps = Omit<ProductTourBaseProps, 'steps' | 'finishButtonText'>;

export const AccountsProductTour: React.FC<AccountsProductTourProps> = (props) => {
  const { t } = useTranslation();

  const steps: TourStep[] = [
    { content: <CashflowGuardianInfo /> },
    { content: <SpendingReportInfo /> },
    { content: <AccountsManagementInfo /> },
  ];

  return <ProductTourDialog {...props} finishButtonText={t('homepage.accountsProductTour.connect')} steps={steps} />;
};
