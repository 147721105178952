import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'filename' | 'endIcons' | 'deleteIcon', SxProps<Theme>> = {
  container: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    'div + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: (theme) => theme.spacing(2),
        top: 0,
      },
    },
    padding: (theme) => theme.spacing(2),
    position: 'relative',
  },
  deleteIcon: {
    color: (theme) => theme.palette.red.dark,
  },
  endIcons: {
    marginLeft: 'auto',
  },
  filename: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
