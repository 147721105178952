import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useLifeInsuranceAuditState } from 'components/flows/lifeInsuranceAudit/state/useLifeInsuranceAuditState';
import { useLifeInsurancePurchaseState } from 'components/flows/lifeInsurancePurchase/state/useLifeInsurancePurchaseState';
import { useMortgagePurchaseState } from 'components/flows/mortgagePurchase/state/useMortgagePurchaseState';
import { useMortgageRefinanceState } from 'components/flows/mortgageRefinance/state/useMortgageRefinanceState';
import { useAllProcessesQuery } from 'generated/graphql';
import _ from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AddMoneyhoonProductDialog, AddMoneyhoonProductDialogProps } from './AddMoneyhoonProductDialog';
import { LifeInsuranceAuditHomepageCard } from './LifeInsuranceAuditHomepageCard';
import { LifeInsuranceHomepageCard } from './LifeInsuranceHomepageCard';
import { MortgagePurchaseHomepageCard } from './MortgagePurchaseHomepageCard';
import { MortgageRefinanceHomepageCard } from './MortgageRefinanceHomepageCard';

export const styles: Record<'container', SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
};

export const UsersProducts: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  const { state: mortgagePurchaseState } = useMortgagePurchaseState();
  const { state: mortgageRefinanceState } = useMortgageRefinanceState();
  const { state: lifeInsurancePurchaseState } = useLifeInsurancePurchaseState();
  const { state: lifeInsuranceAuditState } = useLifeInsuranceAuditState();

  const { data } = useAllProcessesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isAuthenticated,
  });

  const processes = [
    ...(data?.purchaseProcesses?.items ?? []),
    ...(data?.refinanceProcesses?.items ?? []),
    ...(data?.lifeInsuranceProcesses?.items ?? []),
    ...(data?.lifeInsuranceAuditProcesses?.items ?? []),
  ];

  const sortedProcesses = _.sortBy(processes, (process) => process?.updateTimestamp).reverse();

  const moneyhoonProducts = useDialog<AddMoneyhoonProductDialogProps, void>(AddMoneyhoonProductDialog, {
    instanceId: 'add-moneyhoon-product-dialog',
    navigable: true,
  });

  const handleMoneyhonProductsClick = () => {
    moneyhoonProducts.create({});
  };

  const cards = isAuthenticated
    ? sortedProcesses.map((process) => {
        switch (process?.__typename) {
          case 'MortgagePurchaseProcess':
            return <MortgagePurchaseHomepageCard key={process.id} id={process.id} />;
          case 'MortgageRefinanceProcess':
            return <MortgageRefinanceHomepageCard key={process.id} id={process.id} />;
          case 'LifeInsurancePurchaseProcess':
            return <LifeInsuranceHomepageCard key={process.id} id={process.id} />;
          case 'LifeInsuranceAuditProcess':
            return <LifeInsuranceAuditHomepageCard key={process.id} id={process.id} />;

          default:
            return null;
        }
      })
    : [
        !_.isEmpty(mortgagePurchaseState) && <MortgagePurchaseHomepageCard key="MortgagePurchaseProcess" />,
        !_.isEmpty(mortgageRefinanceState) && <MortgageRefinanceHomepageCard key="MortgageRefinanceProcess" />,
        !_.isEmpty(lifeInsurancePurchaseState) && <LifeInsuranceHomepageCard key="LifeInsurancePurchaseProcess" />,
        !_.isEmpty(lifeInsuranceAuditState) && <LifeInsuranceAuditHomepageCard key="LifeInsuranceAuditProcess" />,
      ];

  return (
    <Box>
      <Stack alignItems="center" direction="row" justifyContent="space-between" mb={3}>
        <Typography component="h3" variant="headlineM">
          {t('homepage.products.userProducts.title')}
        </Typography>
        <Button
          aria-label={t('homepage.products.userProducts.startNewButton')}
          color="tertiaryButton"
          onClick={handleMoneyhonProductsClick}
        >
          <Typography color="blue.dark" sx={{ letterSpacing: '-0.43px' }} variant="bodyL">
            {t('homepage.products.userProducts.startNewButton')}
          </Typography>
        </Button>
      </Stack>
      <Stack sx={styles.container}>{cards}</Stack>
    </Box>
  );
};
