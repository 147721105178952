import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { Auth, Hub } from 'aws-amplify';
import { configureAmplify, GOOGLE_SIGN_OUT_URL } from 'common/auth/aws-amplify-setup';
import { useNavigateAfterLogin } from 'common/hooks/useNavigateAfterLogin';
import { pushGTMEvent } from 'common/utils/GTMeventUtils';
import { GTMEventValueEnum } from 'const';
import { useSynchronizeUserMutation } from 'generated/graphql';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  changePassword,
  confirmSignUp,
  forgotPassword,
  forgotPasswordSubmit,
  getIsUserLoggedIn,
  resendSignUp,
  signIn,
  signOut,
  signUp,
  SimpleAuthProps,
  SimpleAuthResponse,
  socialLogin,
  updateAttributes,
  verifyAttribute,
} from '../../auth/user-auth';

const GOOGLE_SIGN_OUT_REDIRECT_KEY = 'googleSignoutRedirect';

interface UserData {
  session: PromiseAwaitedValueType<ReturnType<typeof Auth['currentSession']>>;
  user: {
    username: string;
    attributes: {
      email: string;
      sub: string;
      email_verified: boolean;
      identities?: string;
      preferred_username?: string;
      given_name?: string;
      family_name?: string;
      phone_number?: string;
    };
  };
}

const INITIAL_AUTH_CONTEXT = {
  changePassword,
  confirmSignUp,
  forgotPassword,
  isAuthenticated: false,
  isLoading: true,
  resendSignUp,
  resetPassword: forgotPasswordSubmit,
  signIn,
  signInError: '',
  signOut: (() => signOut()) as (url?: string) => Promise<void>,
  signUp,
  socialLogin,
  socialSignUp: socialLogin,
  updateAttributes,
  userData: undefined as undefined | UserData,
  verifyAttribute,
};

export const AuthContext = createContext(INITIAL_AUTH_CONTEXT);

AuthContext.displayName = 'AuthContext';

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(INITIAL_AUTH_CONTEXT.isLoading);
  const [isCognitoAuthenticated, setIsCognitoAuthenticated] = useState(INITIAL_AUTH_CONTEXT.isAuthenticated);
  const [isSynchronized, setIsSynchronized] = useState(false);
  const [userData, setUserData] = useState(INITIAL_AUTH_CONTEXT.userData);
  const [signInError, setSignInError] = useState('');
  const { handleNavigateAfterLogin } = useNavigateAfterLogin();

  const [synchronizeUser] = useSynchronizeUserMutation({
    onCompleted: (data) => {
      setIsSynchronized(data?.synchronizeUser ?? false);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
      Auth.signOut();
      if (window.location.pathname != '/') {
        window.location.assign(`${window.location.origin}/login`);
      }
    },
  });

  const handleSignOut = useCallback(
    async (redirectUrl?: string) => {
      if (redirectUrl) {
        if (userData?.user.username.includes('google_')) {
          localStorage.setItem(GOOGLE_SIGN_OUT_REDIRECT_KEY, redirectUrl);
          await signOut();
        } else {
          await signOut();
          if (redirectUrl[0] === '/') {
            navigate(redirectUrl);
          } else {
            window.location.assign(redirectUrl);
          }
        }
      }
      setIsCognitoAuthenticated(false);
      setIsSynchronized(false);
      setIsLoading(false);
      return;
    },
    [navigate, userData?.user.username],
  );
  const getUserInfo = async () => {
    try {
      const user = await Auth.currentUserInfo();
      const session = await Auth.currentSession();
      setUserData({ session, user });
    } catch (error) {
      console.error('Could not get user data', error);
    }
  };

  const handleUpdateUser = useCallback(
    async (attributes: Record<string, string>): Promise<SimpleAuthResponse> => {
      const resp = await updateAttributes(attributes);
      if (resp) {
        return resp;
      } else {
        synchronizeUser();
        await getUserInfo();
      }
    },
    [synchronizeUser],
  );

  const contextContent = useMemo(() => {
    return {
      changePassword,
      confirmSignUp,
      forgotPassword,
      isAuthenticated: isCognitoAuthenticated && isSynchronized,
      isLoading,
      resendSignUp,
      resetPassword: forgotPasswordSubmit,
      signIn: async (data: SimpleAuthProps) => {
        setIsLoading(true);
        setSignInError('');
        const error = await signIn(data);
        if (!error) {
          setIsCognitoAuthenticated(true);
        } else {
          setIsLoading(false);
        }
        return error;
      },
      signInError,
      signOut: handleSignOut,
      signUp: async (data: SimpleAuthProps) => {
        setIsLoading(true);
        setSignInError('');
        const error = await signUp(data);
        if (!error) {
          setIsCognitoAuthenticated(false);
        } else {
          setIsLoading(false);
        }
        return error;
      },
      socialLogin: (provider: CognitoHostedUIIdentityProvider, customState?: string) => {
        setIsLoading(true);
        setSignInError('');
        return socialLogin(provider, customState);
      },
      socialSignUp: (provider: CognitoHostedUIIdentityProvider, customState?: string) => {
        setIsLoading(true);
        setSignInError('');
        return socialLogin(provider, customState);
      },
      updateAttributes: handleUpdateUser,
      userData,
      verifyAttribute: async (attribute: string, code: string): Promise<SimpleAuthResponse> => {
        const resp = await verifyAttribute(attribute, code);
        const user = await Auth.currentUserInfo();
        const session = await Auth.currentSession();
        setUserData({ session, user });
        return resp;
      },
    };
  }, [handleSignOut, handleUpdateUser, isCognitoAuthenticated, isLoading, isSynchronized, signInError, userData]);

  // INITIAL login status only
  useEffect(() => {
    const updateLoginStatus = async () => {
      setIsLoading(true);
      const isLoggedIn = await getIsUserLoggedIn();
      setIsCognitoAuthenticated(isLoggedIn);
      if (isLoggedIn) {
        synchronizeUser();
        const user = await Auth.currentUserInfo();
        // if this will be removed/changed - revive gtm login event in `add GTM event for google auth` useEffect
        pushGTMEvent(GTMEventValueEnum.Login, user?.attributes.email);
      } else {
        setIsLoading(false);
      }
    };

    updateLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isCognitoAuthenticated) {
      setUserData(INITIAL_AUTH_CONTEXT.userData);
      return;
    }

    handleNavigateAfterLogin();
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCognitoAuthenticated, isLoading]);

  // handle events after login/logout/register
  useEffect(() => {
    // add user info to Sentry
    Sentry.setUser(userData?.user.attributes.email ? { email: userData.user.attributes.email } : null);

    if (!userData?.user.attributes.email) return;

    // add GTM event for google auth
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const GTMEventParam = params.get('gtmEvent');

    if (GTMEventParam && GTMEventParam in GTMEventValueEnum) {
      let GTMEventValue: GTMEventValueEnum;
      switch (GTMEventParam) {
        case 'Login':
          // not firing because of the same during useEffect for `INITIAL login status only`
          params.delete('gtmEvent');
          url.search = params.toString();
          window.history.pushState(null, '', url.toString());
          return;
        case 'Registration':
          GTMEventValue = GTMEventValueEnum.Registration;
          break;
        case 'AccountConnectionCompleted':
          GTMEventValue = GTMEventValueEnum.AccountConnectionCompleted;
          break;
        default:
          console.log(`GTM Event not defined: ${GTMEventParam}`);
          return;
      }

      pushGTMEvent(GTMEventValue, userData.user.attributes.email).then(() => {
        params.delete('gtmEvent');
        url.search = params.toString();
        window.history.pushState(null, '', url.toString());
      });
    }
  }, [userData?.user.attributes.email]);

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { data, event } }) => {
      switch (event) {
        case 'signIn':
          synchronizeUser();
          break;
        case 'customOAuthState': {
          if (typeof data != 'string') {
            return;
          }
          // redirect to original page after google sign in
          navigate(data, { replace: true });
          break;
        }
        case 'parsingCallbackUrl': {
          if (data.url === GOOGLE_SIGN_OUT_URL) {
            const redirectUrl = localStorage.getItem(GOOGLE_SIGN_OUT_REDIRECT_KEY);
            if (redirectUrl) {
              localStorage.removeItem(GOOGLE_SIGN_OUT_REDIRECT_KEY);

              window.location.assign(redirectUrl);
            } else {
              navigate('/');
            }
          }
          break;
        }
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setSignInError(data.message);
          break;
      }
    });

    configureAmplify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={contextContent}>{children}</AuthContext.Provider>;
};
