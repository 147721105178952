import { useDialog } from 'common/hooks/useDialog';

import { ContactDialog, ContactDialogOwnProps, ContactDialogProps } from '../components/ContactDialog/ContactDialog';

export const useContactDialog = ({
  avatarSrc,
  context,
  jobTitle,
  name,
  userEmail,
  userPhone,
}: ContactDialogOwnProps): (() => Promise<void>) => {
  const contactDialog = useDialog<ContactDialogProps, void>(ContactDialog, { instanceId: 'contact-dialog' });

  return () => contactDialog.create({ avatarSrc, context, jobTitle, name, userEmail, userPhone });
};
