import { Box, Button, Stack, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import {
  SelectProductTypeDialog,
  SelectProductTypeDialogProps,
} from 'components/products/SelectProductTypeDialog/SelectProductTypeDialog';
import { ProductBaseExtended } from 'components/products/types';
import { useTranslation } from 'react-i18next';

import { ProductsListItem } from './ProductsListItem';

export interface ViewProductsListProps {
  products: ProductBaseExtended[];
}

export const ViewAllProductsList: React.FC<ViewProductsListProps> = ({ products }) => {
  const { t } = useTranslation();

  const selectProductType = useDialog<SelectProductTypeDialogProps, void>(SelectProductTypeDialog, {
    instanceId: 'select-product-type-dialog',
    navigable: true,
  });

  const handleAddProductClick = () => {
    selectProductType.create({});
  };

  return (
    <Box>
      <Stack alignItems="center" direction="row" justifyContent="space-between" mb={3}>
        <Typography component="h3" variant="headlineM">
          {t('products:seeAllProducts.list.title')}
        </Typography>
        <Button
          aria-label={t('products:seeAllProducts.list.addProduct')}
          color="tertiaryButton"
          onClick={handleAddProductClick}
        >
          <Typography color="blue.dark" sx={{ letterSpacing: '-0.43px' }} variant="bodyL">
            {t('products:seeAllProducts.list.addProduct')}
          </Typography>
        </Button>
      </Stack>
      <Stack>
        {products.map((product) => (
          <ProductsListItem key={product.id} product={product} />
        ))}
      </Stack>
    </Box>
  );
};
