import { dateParser } from 'admin/formatUtils';
import { ClientInput } from 'admin/inputs/ClientInput';
import { ExpectedAmountInput } from 'admin/inputs/ExpectedAmountInput';
import { InstitutionInput } from 'admin/inputs/InstitutionInput';
import { ProductStatus, TransactionGroupPeriodTypeCode } from 'generated/graphql';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  FormTab,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

export const LifeInsuranceProductEdit: React.FC = () => (
  <Edit mutationMode="pessimistic">
    <TabbedForm>
      <FormTab label="Základné info">
        <ClientInput disabled label="Klient" source="client.id" />
        <InstitutionInput disabled source="institution.id" />
        <TextInput disabled label="Vlastná inštitúcia" source="institutionName" sx={{ width: '40em' }} />
        <TextInput label="Názov produktu" source="name" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Zmluva">
        <TextInput label="Číslo zmluvy" source="contractNumber" sx={{ width: '40em' }} />
        <DateInput label="Začiatok poistenia" parse={dateParser} source="contractSignDate" />
        <DateInput label="Platnosť poistenia do" parse={dateParser} source="contractEndDate" />
      </FormTab>

      <FormTab label="Platby">
        <ExpectedAmountInput label="Suma poistného" source="paymentPeriod.expectedAmount.amount" />
        <TextInput source="paymentPeriod.expectedAmount.currency" style={{ display: 'none' }} value="EUR" />
        <NumberInput label="Opakovať každých" source="paymentPeriod.periodCount" />
        <SelectInput
          choices={[
            { id: TransactionGroupPeriodTypeCode.Week, name: 'týždňov' },
            { id: TransactionGroupPeriodTypeCode.Month, name: 'mesiacov' },
          ]}
          label=""
          source="paymentPeriod.periodTypeCode"
        />
        <DateInput label="Nasledujúca platba" parse={dateParser} source="paymentPeriod.expectedDate" />
      </FormTab>

      <FormTab label="Údaje pre platbu">
        <TextInput label="Variabilný symbol" source="paymentInfo.variableSymbol" sx={{ width: '40em' }} />
        <TextInput label="Špecifický symbol" source="paymentInfo.specificSymbol" sx={{ width: '40em' }} />
        <TextInput label="Konštantný symbol" source="paymentInfo.constantSymbol" sx={{ width: '40em' }} />
        <TextInput label="IBAN" source="paymentInfo.ibanForPayment" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Poistenci, poistníci a rozsah poistenia">
        <ArrayInput label="Poistenci, poistníci a rozsah poistenia" source="applicants">
          <SimpleFormIterator inline>
            <TextInput helperText={false} label="Meno" source="name" />
            <TextInput helperText={false} label="Priezvisko" source="surname" />
            <BooleanInput helperText={false} label="Poistenec" source="isInsured" />
            <BooleanInput helperText={false} label="Poistník" source="isInsuring" />
            <ArrayInput label="Pripoistenia" source="insurance.supplementalInsurances">
              <SimpleFormIterator inline>
                <TextInput helperText={false} label="Názov pripoistenia" source="name" sx={{ width: '40em' }} />
                <NumberInput helperText={false} label="Poistná suma" source="insuredSum.amount" />
                <NumberInput helperText={false} label="Poistné" source="annualCost.amount" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Status">
        <SelectInput
          choices={[
            { id: ProductStatus.WaitingForSubmission, name: 'WaitingForSubmission' },
            { id: ProductStatus.Processing, name: 'Processing' },
            { id: ProductStatus.Active, name: 'Active' },
          ]}
          label="Status"
          required
          source="status"
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);
