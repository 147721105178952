import _, { DebouncedFunc } from 'lodash';
import { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

export function getScrollParent(node?: Element | null): Element | null {
  if (node == null) {
    return null;
  }

  const isElement = node instanceof HTMLElement;
  const overflowY = isElement ? window.getComputedStyle(node).overflowY : '';
  const isScrollable = !(overflowY.includes('hidden') || overflowY.includes('visible'));
  if (isScrollable && node.clientHeight && node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as Element);
  }
}

interface WindowSrollerReturnArgs {
  ref: React.LegacyRef<VariableSizeList<unknown>>;
  outerRef: React.Ref<HTMLDivElement>;
  onListRendered: () => void;
}

export const WindowScroller = ({
  children,
}: {
  children: (args: WindowSrollerReturnArgs) => React.ReactElement;
}): React.ReactElement => {
  const ref = useRef<VariableSizeList<unknown>>(null);
  const outerRef = useRef<HTMLDivElement>(null);

  const windowScrollListener = useRef<DebouncedFunc<() => void>>();

  const onListRendered = () => {
    const scrollElement = getScrollParent(outerRef.current);
    if (!windowScrollListener.current) {
      if (scrollElement) {
        windowScrollListener.current = _.throttle(() => {
          const offsetTop = outerRef.current?.offsetTop ?? 0;
          const listTop = outerRef.current?.getBoundingClientRect().top ?? 0;
          if (listTop <= offsetTop) {
            ref.current?.scrollTo(offsetTop - listTop);
          }
        }, 10);
        scrollElement.addEventListener('scroll', windowScrollListener.current);
      }
    }
  };

  useEffect(() => {
    const scrollElement = getScrollParent(outerRef.current);
    if (!windowScrollListener.current) {
      if (scrollElement) {
        windowScrollListener.current = _.throttle(() => {
          const offsetTop = outerRef.current?.offsetTop ?? 0;
          const listTop = outerRef.current?.getBoundingClientRect().top ?? 0;
          if (listTop <= offsetTop) {
            ref.current?.scrollTo(offsetTop - listTop);
          }
        }, 10);
        scrollElement.addEventListener('scroll', windowScrollListener.current);
      }
    }

    return () => {
      if (windowScrollListener.current) {
        windowScrollListener.current.cancel();
        scrollElement?.removeEventListener('scroll', windowScrollListener.current);
        windowScrollListener.current = undefined;
      }
    };
  }, []);

  return children({
    onListRendered,
    outerRef,
    ref,
  });
};
