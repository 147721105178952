import { Clear, Done } from '@mui/icons-material';
import {
  useMarkFinancialAdvisorTaskAsDoneMutation,
  useMarkFinancialAdvisorTaskAsUndoneMutation,
} from 'generated/graphql';
import { Button, useNotify, useRecordContext, useRefresh } from 'react-admin';

export const DoneButton: React.FC = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const [markAsDone] = useMarkFinancialAdvisorTaskAsDoneMutation();
  const [markAsUndone] = useMarkFinancialAdvisorTaskAsUndoneMutation();

  const handleDoneButtonClick = async (event: React.MouseEvent) => {
    event.stopPropagation();

    await markAsDone({
      variables: {
        taskId: record.id.toString(),
      },
    });

    refresh();
    notify(`Marked as done`, { type: 'info' });
  };

  const handleUndoneButtonClick = async (event: React.MouseEvent) => {
    event.stopPropagation();

    await markAsUndone({
      variables: {
        taskId: record.id.toString(),
      },
    });

    refresh();
    notify(`Marked as not done`, { type: 'info' });
  };

  return (
    <>
      {record.doneDate ? (
        <Button label="Not done" onClick={handleUndoneButtonClick}>
          <Clear />
        </Button>
      ) : (
        <Button label="Done" onClick={handleDoneButtonClick}>
          <Done />
        </Button>
      )}
    </>
  );
};
