import { useReactiveVar } from '@apollo/client';
import { useLoadingIndicator } from 'common/hooks/useLoadingIndicator';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { parseDatestrings } from 'common/utils/parseDatestrings';
import {
  mapRefinanceInquiryToState,
  mortgageRefinanceState,
  trimRefinanceState,
} from 'components/flows/mortgageRefinance/state/refinanceState';
import { MortgageRefinanceState } from 'components/flows/mortgageRefinance/state/refinanceTypes';
import { useMortgageRefinanceProcessLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const REFINANCE_STATE_LOCAL_STORAGE_KEY = 'mortgageRefinanceState';

interface UseMortgageRefinanceStateReturn {
  state: MortgageRefinanceState;
  update: (newState: MortgageRefinanceState) => void;
  clear: () => void;
}

export const useMortgageRefinanceState = (): UseMortgageRefinanceStateReturn => {
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);
  const params = useParams();

  const state = useReactiveVar(mortgageRefinanceState);

  const update = (newState: MortgageRefinanceState) => {
    const stateWithTimestamp = trimRefinanceState(newState);
    _.set(stateWithTimestamp, 'meta.updateTimestamp', new Date());
    if (!isAuthenticated && !params?.id) {
      localStorage.setItem(REFINANCE_STATE_LOCAL_STORAGE_KEY, JSON.stringify(stateWithTimestamp));
    }
    mortgageRefinanceState(stateWithTimestamp);
  };

  const clear = () => {
    localStorage.removeItem(REFINANCE_STATE_LOCAL_STORAGE_KEY);
    mortgageRefinanceState({});
  };

  const { t } = useTranslation();
  const { end: endLoading, start: startLoading } = useLoadingIndicator(
    t('flows/mortgage/refinance:saving.loading.message'),
  );

  const [getProcessData] = useMortgageRefinanceProcessLazyQuery({
    onCompleted: (data) => update(mapRefinanceInquiryToState(data.process?.inquiry, data.process?.updateTimestamp)),
  });

  useEffect(() => {
    if (_.isEmpty(state) && params.id) {
      startLoading(t('flows/mortgage/refinance:loading.loadingMessage'));
      getProcessData({ variables: { id: params.id } }).then(() =>
        endLoading(t('flows/mortgage/refinance:loading.loadingMessage')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading && _.isEmpty(state)) {
      const savedData = localStorage.getItem(REFINANCE_STATE_LOCAL_STORAGE_KEY);
      const parsedData = savedData ? JSON.parse(savedData) : undefined;
      if (!_.isEmpty(parsedData)) {
        const dataWithDates = parseDatestrings(parsedData) as MortgageRefinanceState;
        mortgageRefinanceState(dataWithDates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthLoading]);

  return { clear, state, update };
};
