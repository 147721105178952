import './i18n';
import './sentry';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { configureAmplify } from 'common/auth/aws-amplify-setup';
import { EnvironmentIndicatorBar } from 'common/components/EnvironmentIndicatorBar/EnvironmentIndicatorBar';
import { AuthContextProvider } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { DatePickerProvider } from 'common/providers/DatePickerProvider/DatePickerProvider';
import { DialogProvider } from 'common/providers/DialogProvider/DialogProvider';
import { LoadingIndicatorProvider } from 'common/providers/LoadingIndicatorProvider/LoadingIndicatorProvider';
import { NotificationsProvider } from 'common/providers/NotificationProvider/NotificationProvider';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { apolloClient } from './apolloClient/apolloClient';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { theme } from './themes/theme';

configureAmplify();

export const Root = (): JSX.Element => (
  <React.StrictMode>
    <CookiesProvider>
      <ApolloProvider client={apolloClient}>
        <Router>
          <AuthContextProvider>
            <DatePickerProvider>
              <ThemeProvider theme={theme}>
                <NotificationsProvider>
                  <CssBaseline />
                  <LoadingIndicatorProvider fullScreen>
                    <DialogProvider>
                      <EnvironmentIndicatorBar>
                        <App />
                      </EnvironmentIndicatorBar>
                    </DialogProvider>
                  </LoadingIndicatorProvider>
                </NotificationsProvider>
              </ThemeProvider>
            </DatePickerProvider>
          </AuthContextProvider>
        </Router>
      </ApolloProvider>
    </CookiesProvider>
  </React.StrictMode>
);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<Root />);
} else {
  throw new Error('Missing root element');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
