import { ChevronRight } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { FinancialProductType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { EditDocumentDialog, EditDocumentDialogProps } from '../UploadDocumentDialog/EditDocumentDialog';
import { DocumentTypeByProductType, useCreateProductDocument } from '../UploadDocumentDialog/useCreateProductDocument';
import { styles } from './DetailRow.styles';

interface Props {
  document?: {
    id: string;
    files: {
      id: string;
      name?: string | null;
    }[];
  };
  productId: string;
  type: FinancialProductType;
}

export const DetailDocumentsRow: React.FC<Props> = ({ document, productId, type }) => {
  const { t } = useTranslation();

  const editDocumentDialog = useDialog<EditDocumentDialogProps, void>(EditDocumentDialog, {
    documentId: document?.id,
    instanceId: 'edit-document-dialog',
    navigable: true,
    productId: productId,
    type: type,
  });

  const createDocumentTuple = useCreateProductDocument(type);

  const handleClick = async () => {
    let documentId = document?.id;
    if (!documentId) {
      const resp = await createDocumentTuple[0]({
        variables: {
          document: { type: DocumentTypeByProductType[type] },
          productId,
        },
      });
      documentId = resp?.data?.createProductDocument?.id;
    }
    if (documentId) {
      try {
        await editDocumentDialog.create({
          documentId,
          productId,
          type,
        });
      } catch (e) {
        //pass
      }
    }
  };

  return (
    <Box onClick={handleClick} sx={styles.clickableRow}>
      {!!document && document?.files?.length > 0 ? (
        <>
          <Stack direction="row" flexGrow={1} justifyContent="space-between">
            <Typography variant="bodyL">{t('products:detail.documents.label')}</Typography>
            <Typography color={'textSecondary'} variant="bodyL">
              {document?.files?.length}
            </Typography>
          </Stack>
          <ChevronRight sx={styles.icon} />
        </>
      ) : (
        <Typography color="primary.main" variant="bodyL">
          {t('products:detail.documents.manage')}
        </Typography>
      )}
    </Box>
  );
};
