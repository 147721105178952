import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'input', SxProps<Theme>> = {
  container: {
    border: (theme) => `1px solid ${theme.palette.gray[300]}`,
    borderRadius: '12px',
    padding: (theme) => theme.spacing(3),
  },
  input: {
    gridColumn: '1/7',
  },
};
