import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useLifeInsurancePurchaseState } from 'components/flows/lifeInsurancePurchase/state/useLifeInsurancePurchaseState';
import { useLifeInsurancePurchaseProcessQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LifeInsurancePurchasePreviewDialogProps,
  LifeInsurancePurchaseProductPreviewDialog,
} from './MoneyhoonProcessPreviewDialog/LifeInsurancePurchasePreviewDialog';
import { ProductCard } from './ProductCard/ProductCard';

interface Props {
  id?: string;
}

export const LifeInsuranceHomepageCard: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { state } = useLifeInsurancePurchaseState();

  const { data, loading } = useLifeInsurancePurchaseProcessQuery({
    skip: !isAuthenticated || !id,
    variables: {
      id: id ?? '',
    },
  });

  const progress = isAuthenticated ? data?.process.inquiry?.inquiryProgress : state.meta?.progress;

  const updateTimestamp = isAuthenticated ? data?.process.updateTimestamp : state.meta?.updateTimestamp;

  const accepted = !!data?.process.locked;

  const handleLifeInsuranceClick = async () => {
    processProductPreviewDialog.create({ id });
  };

  const processProductPreviewDialog = useDialog<LifeInsurancePurchasePreviewDialogProps, void>(
    LifeInsurancePurchaseProductPreviewDialog,
    {
      id,
      instanceId: 'life-insurance-product-preview-dialog',
      navigable: true,
    },
  );

  return (
    <ProductCard
      accepted={accepted}
      color="orange"
      loading={loading}
      onClick={handleLifeInsuranceClick}
      progress={progress}
      title={t('products:cards.lifeInsurancePurchase.title')}
      updateDate={updateTimestamp}
    />
  );
};
