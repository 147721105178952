import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { Image } from 'common/components/Image/Image';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';

import { styles } from './HomepageCarousel.styles';

interface HomepageCarouselProps {
  imageBg?: string;
  list: {
    description?: string;
    image: string | null;
    onClick: () => void;
    title: string;
    name?: string;
    buttonLabel: string;
  }[];
}

export const HomepageCarousel: React.FC<HomepageCarouselProps> = ({ imageBg, list }) => {
  const [carouselRef, carousel] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    loop: true,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollPrev: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.currentTarget?.blur();
      carousel && carousel.scrollPrev();
    },
    [carousel],
  );
  const scrollNext: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.currentTarget?.blur();
      carousel && carousel.scrollNext();
    },
    [carousel],
  );

  const onSelect = useCallback(() => {
    if (!carousel) return;
    setSelectedIndex(carousel.selectedScrollSnap());
    setPrevBtnEnabled(carousel.canScrollPrev());
    setNextBtnEnabled(carousel.canScrollNext());
  }, [carousel]);

  const onInit = useCallback(() => {
    if (!carousel) return;
    setScrollSnaps(carousel.scrollSnapList());
  }, [carousel]);

  useEffect(() => {
    if (!carousel) return;
    onInit();
    carousel.on('reInit', onInit);
    carousel.on('select', onSelect);
    onSelect();
  }, [carousel, onInit, onSelect]);

  return (
    <Box sx={styles.carousel}>
      <Box ref={carouselRef} sx={styles.carouselViewport}>
        <Box sx={styles.carouselContainer}>
          {list.map((item, index) => (
            <Box key={index} px={2}>
              <Stack sx={styles.carouselItem}>
                <Stack sx={styles.card}>
                  <Typography mb={1} textAlign="left" variant="headlineS" width="100%">
                    {item.name}
                  </Typography>
                  <Typography mb={2} variant="headlineL">
                    {item.title}
                  </Typography>

                  <Typography mb={4} variant="bodyM">
                    {item.description}
                  </Typography>
                  <Button color="secondaryButton" onClick={item.onClick}>
                    {item.buttonLabel}
                  </Button>
                </Stack>
                <Box sx={{ ...styles.image, background: imageBg }}>
                  <Image src={item.image ?? ''} />
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={styles.prevButtonWrapper}>
        <IconButton color="tertiaryButton" disabled={!prevBtnEnabled} onClick={scrollPrev} size="small">
          <ArrowBackIosRoundedIcon sx={styles.buttonIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.nextButtonWrapper}>
        <IconButton color="tertiaryButton" disabled={!nextBtnEnabled} onClick={scrollNext} size="small">
          <ArrowForwardIosRounded sx={styles.buttonIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.dots}>
        {scrollSnaps.map((_, index) => (
          <Box
            key={index}
            onClick={() => carousel?.scrollTo(index)}
            sx={index === selectedIndex ? styles.dotActive : styles.dot}
          />
        ))}
      </Box>
    </Box>
  );
};
