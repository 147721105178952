import Contact from 'assets/images/carousel/contact.png';
import Investment from 'assets/images/carousel/products/investment.png';
import LifeInsurance from 'assets/images/carousel/products/life-insurance.png';
import NewMortgage from 'assets/images/carousel/products/new-mortgage.png';
import ProductManagement from 'assets/images/carousel/products/product-management.png';
import RefinanceMortgage from 'assets/images/carousel/products/refinance-mortgage.png';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { useDialog } from 'common/hooks/useDialog';
import { useNavigateAfterLogin } from 'common/hooks/useNavigateAfterLogin';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import {
  SelectProductTypeDialog,
  SelectProductTypeDialogProps,
} from 'components/products/SelectProductTypeDialog/SelectProductTypeDialog';
import {
  INVESTMENT_BASE_URL,
  LIFE_INSURANCE_PURCHASE_BASE_URL,
  MORTGAGE_PURCHASE_BASE_URL,
  MORTGAGE_REFINANCE_BASE_URL,
  PRODUCTS_MANAGEMENT_ADD_PRODUCT_URL,
} from 'const';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HomepageCarousel } from '../HomepageCarousel/HomepageCarousel';

export const HomepageProductsCarousel: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const { navigateAfterLogin } = useNavigateAfterLogin();

  const selectProductType = useDialog<SelectProductTypeDialogProps, void>(SelectProductTypeDialog, {
    instanceId: 'select-product-type-dialog',
    navigable: true,
  });

  const handleNewProduct = () => {
    // workaround: as this carousel is also shown to unregistered client, we don't want to open
    // SelectProductTypeDialog directly for them but redirect to login instead
    isAuthenticated ? selectProductType.create({}) : navigateAfterLogin(PRODUCTS_MANAGEMENT_ADD_PRODUCT_URL);
  };

  const handleNewMortgage = () => {
    navigate(MORTGAGE_PURCHASE_BASE_URL);
  };

  const handleRefinanceMortgage = () => {
    navigate(MORTGAGE_REFINANCE_BASE_URL);
  };

  const handleLifeInsurance = () => {
    navigate(LIFE_INSURANCE_PURCHASE_BASE_URL);
  };

  const handleInvestment = () => {
    navigate(INVESTMENT_BASE_URL);
  };

  const openContactDialog = useContactDialog({});
  const handleContact = () => {
    openContactDialog();
  };

  const views = [
    {
      buttonLabel: t('products:carousel.1.buttonLabel'),
      description: t('products:carousel.1.description'),
      image: ProductManagement,
      onClick: handleNewProduct,
      title: t('products:carousel.1.title'),
    },
    {
      buttonLabel: t('products:carousel.2.buttonLabel'),
      description: t('products:carousel.2.description'),
      image: NewMortgage,
      onClick: handleNewMortgage,
      title: t('products:carousel.2.title'),
    },
    {
      buttonLabel: t('products:carousel.3.buttonLabel'),
      description: t('products:carousel.3.description'),
      image: RefinanceMortgage,
      onClick: handleRefinanceMortgage,
      title: t('products:carousel.3.title'),
    },
    {
      buttonLabel: t('products:carousel.4.buttonLabel'),
      description: t('products:carousel.4.description'),
      image: LifeInsurance,
      onClick: handleLifeInsurance,
      title: t('products:carousel.4.title'),
    },
    {
      buttonLabel: t('products:carousel.5.buttonLabel'),
      description: t('products:carousel.5.description'),
      image: Investment,
      onClick: handleInvestment,
      title: t('products:carousel.5.title'),
    },
    {
      buttonLabel: t('products:carousel.6.buttonLabel'),
      image: Contact,
      name: t('products:carousel.6.name'),
      onClick: handleContact,
      title: t('products:carousel.6.title'),
    },
  ];
  return <HomepageCarousel imageBg="linear-gradient(#FF9B88, #EA3829)" list={views} />;
};
