import { Create, DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { InstanceProps } from 'common/providers/DialogProvider/types';
import { FinancialProductType } from 'generated/graphql';

import { BuildingSavingsProductDetail } from './buildingSavings/BuildingSavingsProductDetail';
import { CollisionInsuranceProductDetail } from './collisionInsurance/CollisionInsuranceProductDetail';
import { CreditCardProductDetail } from './creditCard/CreditCardProductDetail';
import { GenericProductDetail } from './generic/GenericProductDetail';
import { GenericInsuranceProductDetail } from './genericInsurance/GenericInsuranceProductDetail';
import { InvestmentProductDetail } from './investment/InvestmentProductDetail';
import { LifeInsuranceProductDetail } from './lifeInsurance/LifeInsuranceProductDetail';
import { LoanProductDetail } from './loan/LoanProductDetail';
import { MortgageProductDetail } from './mortgage/MortgageProductDetail';
import { MotorLiabilityInsuranceProductDetail } from './motorLiabilityInsurance/MotorLiabilityInsuranceProductDetail';
import { PropertyInsuranceProductDetail } from './propertyInsurance/PropertyInsuranceProductDetail';
import { RetirementPensionSavingsProductDetail } from './retirementPensionSavings/RetirementPensionSavingsProductDetail';
import { SavingsProductDetail } from './savings/SavingsProductDetail';
import { SupplementaryPensionSavingsProductDetail } from './supplementaryPensionSavings/SupplementaryPensionSavingsProductDetail';

export interface ProductDetailDialogProps extends DialogComponentProps<void> {
  id: string;
}

type Params = Partial<Omit<ProductDetailDialogProps, keyof InstanceProps<void, void>>> &
  Partial<InstanceProps<void, void>>;

type ProductDetailType = {
  create: Create<ProductDetailDialogProps, void, void>;
  reject: () => void;
  resolve: () => void;
};

export const useProductDetail = (type: FinancialProductType, params: Params): ProductDetailType => {
  const productDetailDialog = {
    [FinancialProductType.BuildingSavings]: BuildingSavingsProductDetail,
    [FinancialProductType.CollisionInsurance]: CollisionInsuranceProductDetail,
    [FinancialProductType.CreditCard]: CreditCardProductDetail,
    [FinancialProductType.Generic]: GenericProductDetail,
    [FinancialProductType.GenericInsurance]: GenericInsuranceProductDetail,
    [FinancialProductType.Investment]: InvestmentProductDetail,
    [FinancialProductType.LifeInsurance]: LifeInsuranceProductDetail,
    [FinancialProductType.Loan]: LoanProductDetail,
    [FinancialProductType.Mortgage]: MortgageProductDetail,
    [FinancialProductType.MotorLiabilityInsurance]: MotorLiabilityInsuranceProductDetail,
    [FinancialProductType.PropertyInsurance]: PropertyInsuranceProductDetail,
    [FinancialProductType.RetirementPensionSavings]: RetirementPensionSavingsProductDetail,
    [FinancialProductType.Savings]: SavingsProductDetail,
    [FinancialProductType.SupplementaryPensionSavings]: SupplementaryPensionSavingsProductDetail,
  }[type];

  return useDialog<ProductDetailDialogProps, void>(productDetailDialog, {
    ...params,
  });
};
