import { useApolloClient } from '@apollo/client';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { ReactComponent as RefreshIcon } from 'assets/icons/Refresh.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import { LightTooltip } from 'common/components/LightTooltip/LightTooltip';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { convertJobStatusErrorMessage } from 'components/accounts/AccountConnectUtils/AccountConnectUtils';
import { ConnectedAccountsDialog } from 'components/accounts/ConnectedAccountsDialog/ConnectedAccountsDialog';
import {
  JobStatusType,
  useConnectedAccountsQuery,
  useJobStatusLazyQuery,
  useRefreshAccountsDataMutation,
} from 'generated/graphql';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LastRefreshText } from './LastRefreshText';

export const RefreshData: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { isAuthenticated } = useContext(AuthContext);

  const { data: dataConnectedAccounts, refetch } = useConnectedAccountsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
  });
  const hasConnectedAccounts = !!dataConnectedAccounts?.accounts?.items.length;
  const accountsWithConsentExpired = dataConnectedAccounts?.accounts?.items.filter(
    ({ consentExpired }) => consentExpired,
  );
  const hasAccountsWithConsentExpired = accountsWithConsentExpired && accountsWithConsentExpired.length > 0;

  const client = useApolloClient();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshAccountsData, { loading: refreshPreparing }] = useRefreshAccountsDataMutation();
  const [getJobStatus, { startPolling, stopPolling }] = useJobStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      if (data.getJobStatus.status == JobStatusType.Done || data.getJobStatus.status == JobStatusType.Error) {
        stopPolling();

        if (data.getJobStatus.status == JobStatusType.Done) {
          await client.reFetchObservableQueries();
        } else {
          notify({ message: convertJobStatusErrorMessage(data.getJobStatus.errorMessage, t), type: 'error' });
        }

        setIsRefreshing(false);
      }
    },
  });

  const handleRefreshClick = async () => {
    const { data } = await refreshAccountsData();
    setIsRefreshing(true);
    const jobId = data?.jobId;
    if (jobId) {
      startPolling(1000);
      getJobStatus({ variables: { jobId } });
    }
    return;
  };

  const connectedAccountsDialog = useDialog<DialogComponentProps<void>, void>(ConnectedAccountsDialog, {
    instanceId: 'connected-accounts',
    navigable: true,
  });

  const handleConsentExpiredClick = async () => {
    await connectedAccountsDialog.create({});
    await refetch();
  };

  return (
    <Box>
      {hasConnectedAccounts && (
        <Stack alignItems="center" direction="row" spacing={1}>
          <LastRefreshText />

          {(refreshPreparing || isRefreshing) && (
            <Box alignItems="center" display="flex" justifyContent="center" width="36px">
              <CircularProgress color="primary" size="16px" />
            </Box>
          )}

          {!hasAccountsWithConsentExpired && !refreshPreparing && !isRefreshing && (
            <IconButton
              aria-label={t('homepage.dashboard.refresh.button')}
              color="tertiaryButton"
              onClick={handleRefreshClick}
            >
              <RefreshIcon />
            </IconButton>
          )}

          {hasAccountsWithConsentExpired && (
            <LightTooltip
              placement="top"
              title={t('homepage.dashboard.refresh.consentExpired.tooltip', {
                name: accountsWithConsentExpired.map((account) => account.name).join(', '),
              })}
            >
              <IconButton
                aria-label={t('homepage.dashboard.refresh.consentExpired.button')}
                color="tertiaryButton"
                onClick={handleConsentExpiredClick}
              >
                <WarningIcon />
              </IconButton>
            </LightTooltip>
          )}
        </Stack>
      )}
    </Box>
  );
};
