import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import type {} from '@mui/lab/themeAugmentation';
import { CircularProgress } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg';

export const palette = {
  background: {
    default: '#fff',
  },
  blue: {
    100: '#F2EFFF',
    1000: '#100069',
    1100: '#000000',
    200: '#E3DFFF',
    300: '#C3C0FF',
    400: '#A5A1FF',
    50: '#FFFFFF',
    500: '#8781FF',
    600: '#6961FA',
    700: '#5044E0',
    75: '#FFFBFF',
    800: '#3624C8',
    900: '#2000A4',
    dark: '#0040DD',
    light: '#2094FA',
  },
  chipTonal: {
    contrastText: '#222222',
    main: '#FDFDFD',
  },
  gray: {
    100: '#F8F8F8',
    200: '#E6E6E6',
    300: '#D5D5D5',
    400: '#B1B1B1',
    50: '#FFFFFF',
    500: '#909090',
    600: '#6D6D6D',
    700: '#464646',
    75: '#FDFDFD',
    800: '#222222',
    900: '#000000',
  },
  green: {
    100: '#DDF4EA',
    1000: '#002214',
    1100: '#000000',
    200: '#CCEEE0',
    300: '#99DDC1',
    400: '#66CDA2',
    50: '#FFFFFF',
    500: '#33BC83',
    600: '#01A963',
    700: '#008950',
    75: '#EEF9F5',
    800: '#00673C',
    900: '#004428',
  },
  greyButton: {
    contrastText: '#AAB3B4',
    contrastTextDark: '#AAB3B4',
    dark: '#F0F7F8',
    light: '#FFFFFF',
    main: '#FFFFFF',
  },
  info: {
    main: '#188BE2',
  },
  primary: {
    100: '#F7FFC0',
    1000: '#2B3100',
    1100: '#000000',
    200: '#F3FF9B',
    300: '#EBFF68',
    400: '#E5FF35',
    50: '#FFFFFF',
    500: '#D6F500',
    600: '#C1DD00',
    700: '#96AC00',
    75: '#FBFEE6',
    800: '#809300',
    900: '#566200',
    contrastText: '#FFFFFF',
    contrastTextDark: '#000000',
    dark: '#C1DD00',
    light: '#D6F500',
    main: '#000000',
  },
  red: {
    100: '#FFEDEA',
    1000: '#410002',
    1100: '#000000',
    200: '#FFDAD6',
    300: '#FFB4AB',
    400: '#FF897D',
    50: '#FFFFFF',
    500: '#FF5449',
    600: '#DE3730',
    700: '#BA1A1A',
    75: '#FFFBFF',
    800: '#93000A',
    900: '#690005',
    dark: '#D21111',
  },
  secondaryButton: {
    contrastText: '#000000',
    contrastTextDark: '#000000',
    dark: '#D5D5D5',
    light: '#E6E6E6',
    main: '#F0F7F8',
  },
  success: {
    main: '#0EA76A',
  },
  tertiaryButton: {
    contrastText: '#000000',
    contrastTextDark: '#000000',
    dark: '#D5D5D5',
    light: '#E6E6E6',
    main: '#F0F7F8',
  },
  text: {
    primary: '#000000',
    secondary: '#6D6D6D',
  },
  warning: {
    main: '#ED7926',
  },
  warningButton: {
    contrastText: '#D21111',
    contrastTextDark: '#D21111',
    dark: '#D5D5D5',
    light: '#E6E6E6',
    main: '#F0F7F8',
  },
  white: {
    main: '#fff',
  },
};

const baseTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1768,
      md: 1280,
      sm: 768,
      xl: 2160,
      xs: 304,
      xxs: 0,
    },
  },
  components: {
    MuiAlert: {
      defaultProps: {
        iconMapping: { error: <WarningAmberRoundedIcon />, success: <CheckCircleOutlineRoundedIcon /> },
      },
      styleOverrides: {
        root: {
          borderRadius: '16px',
          padding: '10px 24px',
        },
        standardError: ({ theme }) => ({
          '& .MuiAlert-icon': {
            color: theme.palette.white.main,
          },
          backgroundColor: theme.palette.red?.['600'],
          color: theme.palette.white.main,
        }),
        standardSuccess: ({ theme }) => ({
          '& .MuiAlert-icon': {
            color: theme.palette.white.main,
          },
          backgroundColor: theme.palette.green?.['600'],
          color: theme.palette.white.main,
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ChevronDownIcon />,
      },
      styleOverrides: {
        popupIndicator: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.gray[200],
          },
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: ({ theme }) => ({
          color: theme.palette.gray[900],
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 400,
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
        variant: 'contained',
      },
      styleOverrides: {
        endIcon: ({ theme }) => ({
          alignItems: 'center',
          height: theme.spacing(2),
          marginLeft: theme.spacing(1.5),
          marginRight: theme.spacing(0),
          width: theme.spacing(2),
        }),
        root: ({ ownerState }) => ({
          '&.Mui-disabled': {
            backgroundColor:
              ownerState.color !== 'primary' ? 'transparent' : palette[ownerState.color ?? 'primary']?.main,
            color: palette[ownerState.color ?? 'primary']?.contrastText,
            ...(ownerState.color === 'secondaryButton' && {
              boxShadow: `inset 0px 0px 0px 2px ${palette[ownerState.color].dark}`,
            }),
            opacity: 0.5,
          },
          '&:active': {
            backgroundColor:
              ownerState.variant !== 'contained'
                ? palette[ownerState.color ?? 'primary']?.main
                : palette[ownerState.color ?? 'primary']?.dark,
            boxShadow: 'none',
            color:
              ownerState.variant !== 'contained' && ownerState.color !== 'primary'
                ? palette[ownerState.color ?? 'primary']?.contrastText
                : palette[ownerState.color ?? 'primary']?.contrastTextDark,
          },
          '&:hover': {
            backgroundColor: palette[ownerState.color ?? 'primary']?.light,
            boxShadow: 'none',
            color:
              ownerState.variant !== 'contained' && ownerState.color !== 'primary'
                ? palette[ownerState.color ?? 'primary']?.contrastText
                : palette[ownerState.color ?? 'primary']?.contrastTextDark,
          },
          ...(ownerState.color !== 'primary' && { background: 'transparent' }),
          ...(ownerState.color === 'secondaryButton' && {
            boxShadow: `inset 0px 0px 0px 2px ${palette[ownerState.color].dark}`,
          }),
          borderRadius: 4,
          textTransform: 'none',
        }),
        sizeLarge: ({ theme }) => ({
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '22px',
          padding: theme.spacing(1.5, 2.5),
        }),
        sizeMedium: ({ theme }) => ({
          fontSize: '13px',
          fontWeight: 600,
          lineHeight: '18px',
          padding: theme.spacing(1, 1.5),
        }),
        startIcon: ({ theme }) => ({
          alignItems: 'center',
          height: theme.spacing(2),
          marginLeft: theme.spacing(0),
          marginRight: theme.spacing(1.5),
          width: theme.spacing(2),
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'chipTonal',
      },

      styleOverrides: {
        deleteIcon: ({ theme }) => ({
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0),
        }),
        icon: ({ theme }) => ({
          fontSize: 24,
          marginLeft: 0,
          marginRight: theme.spacing(1),
        }),
        label: {
          padding: 0,
        },
        root: ({ theme }) => ({
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary[75],
          },
          ':hover': {
            backgroundColor: theme.palette.primary[75],
          },
          borderRadius: 60,
          fontSize: 14,
          fontWeight: 700,
          height: 'unset',
          lineHeight: '24px',
          textTransform: 'none',
        }),
        sizeLarge: ({ theme }) => ({
          padding: theme.spacing(2, 4),
        }),
        sizeMedium: ({ theme }) => ({
          padding: theme.spacing(1, 2.5),
        }),
        sizeSmall: ({ theme }) => ({
          padding: theme.spacing(0.5, 2.5),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          height: '100%',
          width: '100%',
        },
        a: {
          color: palette.text.primary,
        },
        body: {
          height: '100%',
          width: '100%',
        },
        html: {
          height: '100%',
          width: '100%',
        },
        input: {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.gray[100],
          border: `1px solid ${theme.palette.gray[400]}`,
          borderRadius: '8px',
          boxShadow: 'none',
          width: 404,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          justifyContent: 'flex-end',
          padding: theme.spacing(4, 0, 0, 0),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(4, 0, 0, 0),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontSize: 25,
          fontWeight: 700,
          lineHeight: '130%',
          padding: theme.spacing(0),
          textAlign: 'center',
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.gray[300],
        }),
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 12,
          fontWeight: 500,
          marginLeft: 0,
          marginTop: theme.spacing(0.5),
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.Mui-disabled': {
            backgroundColor:
              ownerState.color !== 'primary' ? 'transparent' : palette[ownerState.color ?? 'primary']?.main,
            color: palette[ownerState.color ?? 'primary']?.contrastText,
            ...(ownerState.color === 'secondaryButton' && {
              boxShadow: `inset 0px 0px 0px 2px ${palette[ownerState.color].dark}`,
            }),
            opacity: 0.5,
          },
          '&:focus': {
            backgroundColor: palette[ownerState.color ?? 'primary']?.dark,
            boxShadow: 'none',
            color: palette[ownerState.color ?? 'primary']?.contrastTextDark,
          },
          '&:hover': {
            backgroundColor: palette[ownerState.color ?? 'primary']?.light,
            boxShadow: 'none',
            color:
              ownerState.variant !== 'contained' && ownerState.color !== 'primary'
                ? palette[ownerState.color ?? 'primary']?.contrastText
                : palette[ownerState.color ?? 'primary']?.contrastTextDark,
          },
          backgroundColor: palette[ownerState.color ?? 'primary']?.main,
          borderRadius: 4,
          color: palette[ownerState.color ?? 'primary']?.contrastText,
          textTransform: 'none',
          ...(ownerState.color !== 'primary' && { background: 'transparent' }),
          ...(ownerState.color === 'secondaryButton' && {
            boxShadow: `inset 0px 0px 0px 2px ${palette[ownerState.color].dark}`,
          }),
        }),
        sizeLarge: ({ theme }) => ({
          padding: theme.spacing(2),
          svg: {
            height: 16,
            width: 16,
          },
        }),
        sizeMedium: ({ theme }) => ({
          padding: theme.spacing(1.25),
          svg: {
            height: 16,
            width: 16,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '& > *': {
            '&::placeholder': { fontStyle: 'italic' },
            padding: 0,
          },
          '&.MuiSelect-select': {
            paddingRight: '8px',
          },
          backgroundColor: theme.palette.gray[50],
          fontSize: ownerState?.size !== 'large' ? '13px' : '16px',
          fontWeight: 400,
          input: {
            padding: 0,
          },
          padding: ownerState.size !== 'large' ? theme.spacing(1, 1.5) : theme.spacing(1.5, 2),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.gray[700],
          marginBottom: theme.spacing(0.5),
          position: 'relative',
          transform: 'none',
          transition: 'none',
          whiteSpace: 'normal',
          zIndex: 0,
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: ({ theme }) => ({
          background: theme.palette.green['800'],
        }),
        root: ({ theme }) => ({
          backgroundColor: theme.palette.gray['300'],
          borderRadius: '8px',
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        variant: 'large',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '&:active': {
            background: theme.palette.primary[600],
          },
          '&:hover, &:focus': {
            background: theme.palette.primary[500],
          },
          color: theme.palette.gray[900],
          cursor: 'pointer',
          padding: theme.spacing(0.5),
          pointerEvents: 'inherit',
          textDecorationColor: theme.palette.gray[900],
          userSelect: 'inherit',
        }),
      },
      variants: [
        {
          props: { variant: 'large' },
          style: {
            fontSize: '16px',
          },
        },
        {
          props: { variant: 'medium' },
          style: {
            fontSize: '13px',
          },
        },
      ],
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginRight: theme.spacing(1),
          minWidth: 'unset',
        }),
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        color: 'primary',
        loadingIndicator: <CircularProgress color="inherit" size="auto" />,
        variant: 'contained',
      },
      styleOverrides: {
        loadingIndicator: ({ ownerState }) => ({
          '.MuiCircularProgress-root': {
            svg: {
              height: ownerState.size === 'large' ? 32 : 24,
              width: ownerState.size === 'large' ? 32 : 24,
            },
          },
          color: palette[ownerState.color ?? 'primary']?.contrastText,
        }),
        root: ({ ownerState }) => ({
          '&.Mui-disabled': {
            backgroundColor:
              ownerState.color !== 'primary' ? 'transparent' : palette[ownerState.color ?? 'primary']?.main,
            color: palette[ownerState.color ?? 'primary']?.contrastText,
            ...(ownerState.color === 'secondaryButton' && {
              boxShadow: `inset 0px 0px 0px 2px ${palette[ownerState.color].dark}`,
            }),
            opacity: 0.5,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: theme.palette.gray[100],
            },
            backgroundColor: theme.palette.gray[50],
            color: theme.palette.gray[700],
          },
          '&:hover': {
            backgroundColor: theme.palette.gray[100],
            color: theme.palette.gray[900],
          },
          color: theme.palette.gray[700],
          padding: theme.spacing(1.25, 2),
        }),
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&.MuiOutlinedInput-root': {
            '&.Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              backgroundColor: theme.palette.gray[200],
            },
            '&.Mui-focused fieldset': {
              borderWidth: 1,
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: ownerState?.size !== 'large' ? theme.spacing(1.5) : theme.spacing(2),
            },
            '&:hover fieldset': {
              borderColor: ownerState?.disabled ? undefined : theme.palette.gray[500],
            },
            fieldset: {
              legend: {
                display: 'none',
              },
              top: 0,
            },
          },
          backgroundColor: ownerState?.error ? theme.palette.red?.[75] : undefined,
          borderColor: ownerState?.error ? theme.palette.red?.[800] : undefined,
          borderRadius: '4px',
          padding: ownerState?.size !== 'large' ? theme.spacing(1, 1.5) : theme.spacing(1.5, 2),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
          },
          '&.MuiPopover-paper': {
            border: `1px solid ${theme.palette.gray[400]}`,
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
            marginTop: theme.spacing(1),
          },
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ':not(.Mui-selected)': {
            border: 'none',
          },
          backgroundColor: ownerState.today ? theme.palette.primary[75] : undefined,
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            background: 'none',
          },
          '&:focus': {
            background: 'none',
          },
          '&:hover': {
            background: 'none',
          },
          marginRight: '8px',
          padding: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          position: 'relative',
          right: 0,
        },
        select: ({ theme }) => ({
          display: 'flex',
          marginRight: theme.spacing(-3),
          padding: 0,
          paddingRight: '8px',
        }),
      },
    },
    MuiStep: {
      styleOverrides: {
        horizontal: {
          padding: '0',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          margin: '0 18px',
        },
        vertical: {
          marginLeft: '12px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-active': {
            borderRadius: '50%',
            boxShadow: `0 0 1px 4px ${theme.palette.gray[300]}`,
            color: theme.palette.text.primary,
          },
          '&.Mui-completed': {
            color: theme.palette.text.primary,
          },
          color: theme.palette.gray[400],
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: ({ theme }) => ({
          color: theme.palette.gray[400],
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '21px',
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: 20,
          padding: 0,
          width: 34,
        },
        switchBase: ({ theme }) => ({
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary[50],
              opacity: 1,
            },
            color: '#fff',
            transform: 'translateX(14px)',
          },
          padding: 3,
        }),
        thumb: ({ theme }) => ({
          borderRadius: '100%',
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          height: 14,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
          width: 14,
        }),
        track: ({ theme }) => ({
          backgroundColor: theme.palette.gray[100],
          borderRadius: '40px',
          boxSizing: 'border-box',
          opacity: 1,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.gray[900],
          fontSize: '13px',
          fontWeight: 400,
          minHeight: 'unset',
          padding: theme.spacing(1, 1.5),
          textTransform: 'none',
        }),
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          height: '100%',
          padding: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.gray?.[300]}`,
          color: theme.palette.text.primary,
          minHeight: 'unset',
          position: 'relative',
        }),
        scrollButtons: ({ theme }) => ({
          '&.Mui-disabled': {
            opacity: 0,
            zIndex: 0,
          },
          backgroundColor: theme.palette.white.main,
          bottom: 0,
          opacity: 1,
          position: 'absolute',
          top: 0,
          zIndex: 10,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiFormHelperText-root': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary',
      },
    },
  },
  palette,
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 500,
    },
  },
  typography: {
    bodyL: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyM: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyS: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyStrongL: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongM: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongS: {
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXL: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXS: {
      fontSize: '11px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXXL: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyStrongXXXL: {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    bodyXL: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXS: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXXL: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXXXL: {
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '24px',
    },
    fontFamily: ['Inter', 'sans-serif'].join(','),
    headlineL: {
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '130%',
    },
    headlineM: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '130%',
    },
    headlineS: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '130%',
    },
    headlineXL: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '130%',
    },
    headlineXS: {
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '22px',
    },
  },
});

export const theme = baseTheme;
