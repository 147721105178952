import { Box, Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as CollisionInsuranceIcon } from 'assets/icons/flow-switcher/CollisionInsurance.svg';
import { ReactComponent as InvestmentIcon } from 'assets/icons/flow-switcher/Investment.svg';
import { ReactComponent as LifeInsuranceAuditIcon } from 'assets/icons/flow-switcher/LifeInsuranceAudit.svg';
import { ReactComponent as LifeInsurancePurchaseIcon } from 'assets/icons/flow-switcher/LifeInsurancePurchase.svg';
import { ReactComponent as MortgagePurchaseIcon } from 'assets/icons/flow-switcher/MortgagePurchase.svg';
import { ReactComponent as MortgageRefinanceIcon } from 'assets/icons/flow-switcher/MortgageRefinance.svg';
import { ReactComponent as MotorLiabilityInsuranceIcon } from 'assets/icons/flow-switcher/MotorLiabilityInsurance.svg';
import { ReactComponent as PropertyInsuranceIcon } from 'assets/icons/flow-switcher/PropertyInsurance.svg';
import {
  COLLISION_INSURANCE_BASE_URL,
  INVESTMENT_BASE_URL,
  LIFE_INSURANCE_AUDIT_BASE_URL,
  LIFE_INSURANCE_PURCHASE_BASE_URL,
  MORTGAGE_PURCHASE_BASE_URL,
  MORTGAGE_REFINANCE_BASE_URL,
  MOTOR_LIABILITY_INSURANCE_BASE_URL,
  PROPERTY_INSURANCE_BASE_URL,
} from 'const';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FlowSwitcherCard } from '../FlowSwitcherCard/FlowSwitcherCard';

export const AvailableProducts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const products = [
    {
      description: t('products:cards.purchaseMortgage.description'),
      icon: <MortgagePurchaseIcon width={48} />,
      title: t('products:cards.purchaseMortgage.title'),
      url: MORTGAGE_PURCHASE_BASE_URL,
    },
    {
      description: t('products:cards.refinanceMortgage.description'),
      icon: <MortgageRefinanceIcon width={48} />,
      title: t('products:cards.refinanceMortgage.title'),
      url: MORTGAGE_REFINANCE_BASE_URL,
    },
    {
      description: t('products:cards.lifeInsurancePurchase.description'),
      icon: <LifeInsurancePurchaseIcon width={48} />,
      title: t('products:cards.lifeInsurancePurchase.title'),
      url: LIFE_INSURANCE_PURCHASE_BASE_URL,
    },
    {
      description: t('products:cards.lifeInsuranceAudit.description'),
      icon: <LifeInsuranceAuditIcon width={48} />,
      title: t('products:cards.lifeInsuranceAudit.title'),
      url: LIFE_INSURANCE_AUDIT_BASE_URL,
    },
    {
      description: t('products:cards.motorLiabilityInsurance.description'),
      icon: <MotorLiabilityInsuranceIcon width={48} />,
      title: t('products:cards.motorLiabilityInsurance.title'),
      url: MOTOR_LIABILITY_INSURANCE_BASE_URL,
    },
    {
      description: t('products:cards.collisionInsurance.description'),
      icon: <CollisionInsuranceIcon width={48} />,
      title: t('products:cards.collisionInsurance.title'),
      url: COLLISION_INSURANCE_BASE_URL,
    },
    {
      description: t('products:cards.propertyInsurance.description'),
      icon: <PropertyInsuranceIcon width={48} />,
      title: t('products:cards.propertyInsurance.title'),
      url: PROPERTY_INSURANCE_BASE_URL,
    },
    {
      description: t('products:cards.investment.description'),
      icon: <InvestmentIcon width={48} />,
      title: t('products:cards.investment.title'),
      url: INVESTMENT_BASE_URL,
    },
  ];

  return (
    <Box>
      <Stack alignItems="center" mb={4} spacing={0.5}>
        <Typography component="h3" variant="headlineXL">
          {t('products:addBrandProduct.title')}
        </Typography>
        <Typography color="textSecondary" component="h3" variant="bodyM">
          {t('products:addBrandProduct.subtitle')}
        </Typography>
      </Stack>

      <Grid columns={6} container spacing={1}>
        {products.map((product) => (
          <Grid key={product.title} item sm={6} xs={12}>
            <FlowSwitcherCard
              description={product.description}
              icon={product.icon}
              onClick={() => navigate(product.url)}
              title={product.title}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
