import { Box, SxProps, Theme } from '@mui/material';

import { styles } from './AccountCard.styles';

const ACCOUNT_COLORS = [
  ['#C93400', '#FFB340'],
  ['#8944AB', '#DA8FFF'],
  ['#D30F45', '#FF6482'],
  ['#B25000', '#FFD426'],
  ['#248A3D', '#30DB5B'],
  ['#0071A4', '#70D7FF'],
  ['#0C817B', '#66D4CF'],
  ['#7F6545', '#B59469'],
];

interface Props {
  width: number;
  height: number;
  colorIndex?: number;
}

export const AccountCard: React.FC<Props> = ({ colorIndex, height, width }) => {
  const safeIndex = (colorIndex ?? 0) % ACCOUNT_COLORS.length;
  const background =
    typeof colorIndex === 'number'
      ? `linear-gradient(291.34deg, ${ACCOUNT_COLORS[safeIndex]?.[0]} 0%, ${ACCOUNT_COLORS[safeIndex]?.[1]} 100%)`
      : undefined;

  const sx = {
    ...styles.card,
    background,
  } as SxProps<Theme>;

  return (
    <Box height={height} sx={sx} width={width}>
      <Box sx={styles.name} />
    </Box>
  );
};
