import { TransactionGroupPeriodTypeCode } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export const usePeriodicityOptions = (): Array<{
  label: string;
  value: {
    periodCount: number;
    periodTypeCode: TransactionGroupPeriodTypeCode;
  };
}> => {
  const { t } = useTranslation();
  return [
    {
      label: t('filterTransactions.filter.periodicity.weekly.label'),
      value: { periodCount: 1, periodTypeCode: TransactionGroupPeriodTypeCode.Week },
    },
    {
      label: t('filterTransactions.filter.periodicity.monthly.label'),
      value: { periodCount: 1, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
    },
    {
      label: t('filterTransactions.filter.periodicity.quarterly.label'),
      value: { periodCount: 3, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
    },
    {
      label: t('filterTransactions.filter.periodicity.semiannually.label'),
      value: { periodCount: 6, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
    },
    {
      label: t('filterTransactions.filter.periodicity.annually.label'),
      value: { periodCount: 12, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
    },
  ];
};
