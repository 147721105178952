import { PRODUCT_START_FLOW_ENABLED } from '@brand/const';
import { Box, Button, Stack, Switch, Typography } from '@mui/material';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import {
  COLLISION_INSURANCE_BASE_URL,
  LIFE_INSURANCE_AUDIT_BASE_URL,
  MORTGAGE_REFINANCE_BASE_URL,
  MOTOR_LIABILITY_INSURANCE_BASE_URL,
  PROPERTY_INSURANCE_BASE_URL,
} from 'const';
import { FinancialProductType, InstitutionFragment } from 'generated/graphql';
import _ from 'lodash';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { Path, PathValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonRow } from '../ButtonRow/ButtonRow';
import { ContactExpert, ContactExpertProps } from '../ContactExpert/ContactExpert';
import { DeleteProductDialog, DeleteProductDialogProps } from '../DeleteProductDialog/DeleteProductDialog';
import { DeleteSuccessDialog, DeleteSuccessDialogProps } from '../DeleteSuccessDialog/DeleteSuccessDialog';
import { DetailList } from '../DetailList/DetailList';
import { DetailDocumentsRow } from '../DetailRow/DetailDocumentsRow';
import { DetailRow } from '../DetailRow/DetailRow';
import { SaveProductSuccessDialog } from '../SaveProductSuccessDialog/SaveProductSuccessDialog';
import { isButtonRow, isSection, List, ProductBase, Section } from '../types';
import { styles } from './ProductDetail.styles';

export interface ProductDetailProps<T extends ProductBase> extends DialogComponentProps<void> {
  content: Array<Section<T, Path<T>> | { [K in ArrayPath<T>]: List<T, K> }[ArrayPath<T>]>;
  institution?: InstitutionFragment;
  title: string;
  onUpdate: (value: T) => Promise<void>;
  data?: T;
  type: FinancialProductType;
  contactExpert?: ContactExpertProps;
}

const ProductDetailContent = <T extends ProductBase>({
  contactExpert,
  content,
  data,
  institution,
  onResolve,
  onUpdate,
  title,
  type,
}: ProductDetailProps<T>): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [updatedAt, setUpdatedAt] = useState(new Date());

  const handleUpdate = async <K extends Path<T>>(value: PathValue<T, K>, path: K) => {
    const updatedProduct = _.cloneDeep(data) as T;
    _.set(updatedProduct ?? {}, path, value);
    await onUpdate(updatedProduct);
    setUpdatedAt(new Date());
  };

  const [enableNotifications, setEnableNotifications] = useState(false);
  const [enableFinancialAdvisorCare, setEnableFinancialAdvisorCare] = useState(false);

  useEffect(() => {
    setEnableNotifications(!!data?.enableNotifications);
    setEnableFinancialAdvisorCare(!!data?.enableFinancialAdvisorCare);
  }, [data]);

  const handleEnableNotificationsChange = async () => {
    const updatedEnableNotifications = !enableNotifications;
    setEnableNotifications(updatedEnableNotifications);
    handleUpdate(updatedEnableNotifications as PathValue<T, Path<T>>, 'enableNotifications' as Path<T>);
  };

  const handleEnableFinancialAdvisorCareChange = async () => {
    const updatedEnableFinancialAdvisorCare = !enableFinancialAdvisorCare;
    setEnableFinancialAdvisorCare(updatedEnableFinancialAdvisorCare);
    handleUpdate(updatedEnableFinancialAdvisorCare as PathValue<T, Path<T>>, 'enableFinancialAdvisorCare' as Path<T>);
  };

  const deleteProductDialog = useDialog<DeleteProductDialogProps, boolean>(DeleteProductDialog, {
    instanceId: 'delete-product',
  });

  const deleteSuccessDialog = useDialog<DeleteSuccessDialogProps, void>(DeleteSuccessDialog, {
    instanceId: 'delete-product-success-dialog',
  });

  const handleDelete = async () => {
    if (data?.id) {
      const deleted = await deleteProductDialog.create({ id: data.id, type });
      if (deleted) {
        await onResolve();
        deleteSuccessDialog.create({});
      }
    }
  };

  const handleStartFlow = () => {
    type == FinancialProductType.CollisionInsurance && navigate(COLLISION_INSURANCE_BASE_URL);
    type == FinancialProductType.LifeInsurance && navigate(LIFE_INSURANCE_AUDIT_BASE_URL);
    type == FinancialProductType.Mortgage && navigate(MORTGAGE_REFINANCE_BASE_URL);
    type == FinancialProductType.MotorLiabilityInsurance && navigate(MOTOR_LIABILITY_INSURANCE_BASE_URL);
    type == FinancialProductType.PropertyInsurance && navigate(PROPERTY_INSURANCE_BASE_URL);
  };

  return (
    <Stack>
      <Stack alignItems="center">
        <InstitutionIcon
          alt={institution?.name ?? data?.institutionName ?? '?'}
          src={institution?.logoUrl ?? undefined}
          title={institution?.name ?? data?.institutionName ?? '?'}
          width={96}
        />
        <Box mt={1}>
          <Typography textAlign="center" variant="headlineXL">
            {title}
          </Typography>
        </Box>
        <Box mt={0.5}>
          <Typography color="textSecondary" variant="bodyM">
            {institution?.name ?? data?.institutionName ?? '?'}
          </Typography>
        </Box>
      </Stack>

      {PRODUCT_START_FLOW_ENABLED &&
        [
          FinancialProductType.CollisionInsurance,
          FinancialProductType.LifeInsurance,
          FinancialProductType.Mortgage,
          FinancialProductType.MotorLiabilityInsurance,
          FinancialProductType.PropertyInsurance,
        ].includes(type) && (
          <Box mb={5} mt={3}>
            <Button color="primary" fullWidth onClick={handleStartFlow} size="large" type="submit">
              <Typography color="white" variant="bodyL">
                {t('products:detail.startFlow')}
              </Typography>
            </Button>
          </Box>
        )}
      {content.map((section, index) => (
        <Fragment key={index}>
          {!!section.title && (
            <Box mt={5}>
              <Typography variant="headlineM">{section.title}</Typography>
            </Box>
          )}
          <Stack sx={styles.container}>
            {isSection(section) ? (
              section.rows
                .filter((row) => !row.skip)
                .map((row) => {
                  return isButtonRow(row) ? (
                    <ButtonRow key={row.label} color={row.color} label={row.label} onClick={row.onClick} />
                  ) : (
                    <DetailRow<typeof row.path>
                      key={row.path}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      formatter={row.formatter as any}
                      helperText={row.helperText}
                      inputEndAdornment={row.inputEndAdornment}
                      inputType={row.inputType}
                      label={row.label}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onUpdate={handleUpdate as any}
                      options={row.options}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      parse={row.parse as any}
                      path={row.path}
                      tooltip={row.tooltip}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      transform={row.transform as any}
                      updatedAt={updatedAt}
                      value={_.get(data, row.path)}
                    />
                  );
                })
            ) : (
              <DetailList
                addLabel={section.addLabel}
                data={_.get(data, section.path) ?? []}
                fields={section.fields}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatter={section.formatter as any}
                label={section.label}
                maxLength={section.maxLength}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onUpdate={handleUpdate as any}
                path={section.path}
                removeLabel={section.removeLabel}
                updatedAt={updatedAt}
              />
            )}
          </Stack>

          {index == 0 && (
            <Box sx={styles.container}>
              {!!data && <DetailDocumentsRow document={data.documents[0]} productId={data.id} type={type} />}
            </Box>
          )}
        </Fragment>
      ))}
      {contactExpert && <ContactExpert {...contactExpert} />}
      <Stack mb={3} mt={3} spacing={3}>
        <Stack spacing={1}>
          <Box onClick={handleEnableNotificationsChange} sx={styles.switchRow}>
            <Typography variant="bodyL">{t('products:detail.notifications.label')}</Typography>
            <Switch aria-label={t('products:detail.notifications.label')} checked={enableNotifications} />
          </Box>
          <Box sx={styles.helperText}>
            <Typography variant="bodyS">{t('products:detail.notifications.helperText')}</Typography>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Box onClick={handleEnableFinancialAdvisorCareChange} sx={styles.switchRow}>
            <Typography variant="bodyL">{t('products:detail.financialAdvisorCare.label')}</Typography>
            <Switch aria-label={t('products:detail.financialAdvisorCare.label')} checked={enableFinancialAdvisorCare} />
          </Box>
          <Box sx={styles.helperText}>
            <Typography variant="bodyS">{t('products:detail.financialAdvisorCare.helperText')}</Typography>
          </Box>
        </Stack>
      </Stack>
      <Box sx={styles.container}>
        <ButtonRow color="red.dark" label={t('products:detail.delete')} onClick={handleDelete} />
      </Box>
    </Stack>
  );
};

export const ProductDetail = <T extends ProductBase>(props: ProductDetailProps<T>): ReactElement => {
  const { t } = useTranslation();

  const saveProductSuccessDialog = useDialog<DeleteSuccessDialogProps, void>(SaveProductSuccessDialog, {
    instanceId: 'save-product-success-dialog',
  });

  const handleSave = async () => {
    await props.onResolve();
    saveProductSuccessDialog.create({});
  };

  return (
    <ResponsiveSideDialog
      dialogId={props.instanceId}
      header={
        <Button color="tertiaryButton" onClick={handleSave} sx={styles.saveButton}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {t('products:detail.save')}
          </Typography>
        </Button>
      }
      isOpen={props.isOpen}
      onClose={props.onResolve}
    >
      <ProductDetailContent {...props} />
    </ResponsiveSideDialog>
  );
};
