import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { Client } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  clients?: Client[];
}

export const ClientStatsCard: React.FC<Props> = ({ clients, isLoading }) => {
  const registeredClients = clients?.filter((client) => client.user?.subject);

  const lastWeekTimestamp = new Date();
  lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);

  const registeredClientsLastWeek = registeredClients?.filter(
    (client) => client.creationTimestamp >= lastWeekTimestamp,
  );
  const deletedClientsLastWeek = clients?.filter(
    (client) => !client.user && client.updateTimestamp >= lastWeekTimestamp,
  );
  const deltaClientsLastWeek = (registeredClientsLastWeek?.length ?? 0) - (deletedClientsLastWeek?.length ?? 0);

  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">
          {`${registeredClients?.length} registrovaných klientov`}
        </Typography>
        <Typography color="text.secondary">{`${
          deltaClientsLastWeek >= 0 ? '+' : ''
        }${deltaClientsLastWeek} za posledných 7 dní`}</Typography>
      </CardContent>
    </Card>
  );
};
