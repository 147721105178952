import { Box } from '@mui/material';

import { styles } from './TagIcon.styles';

interface Props {
  width?: number;
  height?: number;
}

export const TagIcon: React.FC<Props> = (props) => (
  <Box fontSize={(props.height ?? 48) / 3} height={props.height ?? 48} sx={styles.container} width={props.width ?? 48}>
    #
  </Box>
);
