import { Box, Typography } from '@mui/material';
import { partialCircle } from 'common/utils/svgUtils';

import { styles } from './ProductCardIcon.style';

export interface ProductCardIconProps {
  progress?: number | null;
  color?: 'red' | 'orange' | 'pink' | 'green' | 'volt';
  variant?: 'small' | 'large';
}

export const ProductCardIcon: React.FC<ProductCardIconProps> = ({ color, progress, variant = 'small' }) => {
  const iconSize = variant === 'small' ? 64 : 96;
  const circleSize = variant === 'small' ? 20 : 32;
  const viewBox = `0 0 ${iconSize} ${iconSize}`;

  return (
    <Box sx={styles(iconSize, circleSize).progress}>
      <svg viewBox={viewBox}>
        <linearGradient gradientTransform="rotate(45)" id="red">
          <stop offset="0%" stopColor="#FF9B88" />
          <stop offset="100%" stopColor="#EA3829" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="orange"
          x1="1.06167e-07"
          x2="49.2178"
          y1="0.264664"
          y2="46.71"
        >
          <stop stopColor="#FFBB63" />
          <stop offset="1" stopColor="#CB5D00" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="pink" x1="1.06167e-07" x2="49.2178" y1="0.264664" y2="46.71">
          <stop stopColor="#FFB2CE" />
          <stop offset="1" stopColor="#DE3D82" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="green"
          x1="1.06167e-07"
          x2="49.2178"
          y1="0.264664"
          y2="46.71"
        >
          <stop stopColor="#96EE85" />
          <stop offset="1" stopColor="#009112" />
        </linearGradient>
        <linearGradient id="volt">
          <stop stopColor="#D6F500" />
        </linearGradient>
        <rect fill={`url(#${color})`} height={iconSize} rx={8} ry={8} width={iconSize} />

        <path
          className="svg-indicator-track"
          d={partialCircle(
            iconSize / 2,
            iconSize / 2,
            circleSize,
            (3 * Math.PI) / 2,
            (3 / 4 + 1 - 0.001) * Math.PI * 2,
          )}
        />
        <path
          className="svg-indicator-indication"
          d={partialCircle(
            iconSize / 2,
            iconSize / 2,
            circleSize,
            (3 * Math.PI) / 2,
            (3 / 4 + (progress ?? 0) - 0.001) * Math.PI * 2,
          )}
        />
        <circle className="svg-indicator-center" fill={`url(#${color})`} />
      </svg>
      <Typography sx={styles().progressLabel} variant={variant === 'small' ? 'bodyStrongXS' : 'bodyStrongL'}>
        {Math.round(100 * (progress ?? 0)) ?? 0}%
      </Typography>
    </Box>
  );
};
