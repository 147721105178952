import { SxProps, Theme } from '@mui/material';
import CashWithdrawalsIcon from 'assets/icons/transaction-categories/CashWithdrawals.svg';
import EatingOutIcon from 'assets/icons/transaction-categories/EatingOut.svg';
import EntertainmentIcon from 'assets/icons/transaction-categories/Entertainment.svg';
import GroceriesIcon from 'assets/icons/transaction-categories/Groceries.svg';
import HealthIcon from 'assets/icons/transaction-categories/Health.svg';
import HousingIcon from 'assets/icons/transaction-categories/Housing.svg';
import IncomeIcon from 'assets/icons/transaction-categories/Income.svg';
import InsurancesIcon from 'assets/icons/transaction-categories/Insurances.svg';
import LoansIcon from 'assets/icons/transaction-categories/Loans.svg';
import MiscellaneousIcon from 'assets/icons/transaction-categories/Miscellaneous.svg';
import SavingsIcon from 'assets/icons/transaction-categories/Savings.svg';
import ShoppingIcon from 'assets/icons/transaction-categories/Shopping.svg';
import TransfersIcon from 'assets/icons/transaction-categories/Transfers.svg';
import TransportationIcon from 'assets/icons/transaction-categories/Transportation.svg';
import TravelIcon from 'assets/icons/transaction-categories/Travel.svg';
import { Image } from 'common/components/Image/Image';

const styles: Record<'institutionIcon', SxProps<Theme>> = {
  institutionIcon: {
    borderRadius: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const CATEGORY_ICONS: Record<string, string> = {
  AUTO_AND_TRANSPORT: TransportationIcon,
  CASH_WITHDRAWALS: CashWithdrawalsIcon,
  EATING_OUT: EatingOutIcon,
  ENTERTAINMENT: EntertainmentIcon,
  GROCERIES: GroceriesIcon,
  HEALTH_AND_BEAUTY: HealthIcon,
  HOUSING_AND_UTILITIES: HousingIcon,
  INCOME: IncomeIcon,
  INSURANCES: InsurancesIcon,
  LOANS: LoansIcon,
  MISCELLANEOUS: MiscellaneousIcon,
  SAVINGS_AND_INVESTMENTS: SavingsIcon,
  SHOPPING: ShoppingIcon,
  TRANSFERS: TransfersIcon,
  TRAVEL: TravelIcon,
};

interface CategoryIconProps {
  category: {
    id: string;
    categoryGroup: {
      name: string;
      code: string;
    };
  };
  width?: number;
}

export const CategoryIcon: React.FC<CategoryIconProps> = ({ category, width }) => {
  return (
    <Image
      alt={category.categoryGroup.name}
      height={width ?? 48}
      src={CATEGORY_ICONS[category.categoryGroup.code] ?? MiscellaneousIcon}
      sx={styles.institutionIcon}
      title={category.categoryGroup.name}
      width={width ?? 48}
    />
  );
};
