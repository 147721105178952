import { IconButton } from '@mui/material';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './UserHeaderMenuAvatar.styles';
import { UserHeaderMenuPopper } from './UserHeaderMenuPopper';

export const UserHeaderMenuAvatar: React.FC = () => {
  const { t } = useTranslation();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const isOpen = !!anchorElement;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => setAnchorElement(event.currentTarget);

  const handleClose = () => setAnchorElement(null);

  return (
    <>
      <IconButton aria-label={t('userHeaderMenu.avatarButtonAriaLabel')} onClick={handleClick} sx={styles.button}>
        <UserIcon />
      </IconButton>
      <UserHeaderMenuPopper anchorElement={anchorElement} onClose={handleClose} open={isOpen} />
    </>
  );
};
