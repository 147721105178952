import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { Money } from 'generated/graphql';
import { i18n } from 'i18n';

import { styles } from './CashflowGuardianAggregateListItem.styles';

export interface CashflowGuardianAggregateListItemProps {
  aggregate: {
    sum: Money;
    label: string;
    color?: string;
  };
  onClick: () => void;
}

export const CashflowGuardianAggregateListItem: React.FC<CashflowGuardianAggregateListItemProps> = ({
  aggregate,
  onClick,
}) => {
  const disabled = aggregate.sum.amount === 0;

  const sx = { ...styles.aggregate, ...(disabled ? styles.disabled : {}) };
  return (
    <Stack direction="row" justifyContent="space-between" onClick={disabled ? undefined : onClick} sx={sx}>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Box bgcolor={aggregate.color} sx={styles.mark} />
        <Typography color="textSecondary" variant="bodyStrongL">
          {aggregate.label}
        </Typography>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <Stack textAlign="right">
          <Typography color="textSecondary" variant="bodyL">
            {formatTransactionAmount(aggregate.sum.amount, i18n.language, 2, aggregate.sum.currency)}
          </Typography>
        </Stack>
        {!disabled && <ChevronRightIcon />}
      </Stack>
    </Stack>
  );
};
