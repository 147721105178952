import { SxProps, Theme } from '@mui/material';

export const styles: Record<'tabs' | 'manageButton', SxProps<Theme>> = {
  manageButton: {
    position: 'absolute',
    right: (theme) => ({
      sm: theme.spacing(1),
      xxs: theme.spacing(-1),
    }),
  },
  tabs: (theme) => ({
    '& .Mui-selected': {
      '& .MuiTypography-root': {
        color: theme.palette.gray[900],
      },
      backgroundColor: theme.palette.gray[50],
      borderRadius: '4px',
    },
    '& .MuiButtonBase-root-MuiTab-root.Mui-selected': {
      fontWeight: 600,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    backgroundColor: theme.palette.gray[200],
    borderBottom: 'unset',
    borderRadius: '6px',
    color: theme.palette.gray[600],
    minHeight: 'unset',
    padding: '2px',
  }),
};
