import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'container' | 'data' | 'transactionRangeRow' | 'consentRow' | 'buttonRow' | 'clickableRow' | 'icon' | 'label',
  SxProps<Theme>
> = {
  buttonRow: {
    justifyContent: 'left',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
  clickableRow: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      borderRadius: '10px',
      cursor: 'pointer',
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2),
  },
  consentRow: {
    alignItems: 'flex-end',
    borderBottom: (theme) => `1px solid ${theme.palette.gray[200]}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2, 0),
  },
  container: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
  },
  data: {
    padding: (theme) => theme.spacing(0, 2),
  },
  icon: {
    color: (theme) => theme.palette.gray[500],
  },
  label: {
    color: (theme) => theme.palette.gray[600],
  },
  transactionRangeRow: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2, 0),
  },
};
