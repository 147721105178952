import { useNotification } from 'common/hooks/useNotification';
import {
  FinancialProductType,
  useUpdateBuildingSavingsProductMutation,
  useUpdateCollisionInsuranceProductMutation,
  useUpdateCreditCardProductMutation,
  useUpdateGenericInsuranceProductMutation,
  useUpdateGenericProductMutation,
  useUpdateInvestmentProductMutation,
  useUpdateLifeInsuranceProductMutation,
  useUpdateLoanProductMutation,
  useUpdateMortgageProductMutation,
  useUpdateMotorLiabilityInsuranceProductMutation,
  useUpdatePropertyInsuranceProductMutation,
  useUpdateRetirementPensionSavingsProductMutation,
  useUpdateSavingsProductMutation,
  useUpdateSupplementaryPensionSavingsProductMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { mapBuildingSavingsProductToInput } from './buildingSavings/utils';
import { mapCollisionInsuranceProductToInput } from './collisionInsurance/utils';
import { mapCreditCardProductToInput } from './creditCard/utils';
import { mapGenericProductToInput } from './generic/utils';
import { mapGenericInsuranceProductToInput } from './genericInsurance/utils';
import { mapInvestmentProductToInput } from './investment/utils';
import { mapLifeInsuranceProductToInput } from './lifeInsurance/utils';
import { mapLoanProductToInput } from './loan/utils';
import { mapMortgageProductToInput } from './mortgage/utils';
import { mapMotorLiabilityInsuranceProductToInput } from './motorLiabilityInsurance/utils';
import { mapPropertyInsuranceProductToInput } from './propertyInsurance/utils';
import { mapRetirementPensionSavingsProductToInput } from './retirementPensionSavings/utils';
import { mapSavingsProductToInput } from './savings/utils';
import { mapSupplementaryPensionSavingsProductToInput } from './supplementaryPensionSavings/utils';

type UpdateProductMutation =
  | ReturnType<typeof useUpdateBuildingSavingsProductMutation>
  | ReturnType<typeof useUpdateCollisionInsuranceProductMutation>
  | ReturnType<typeof useUpdateCreditCardProductMutation>
  | ReturnType<typeof useUpdateGenericInsuranceProductMutation>
  | ReturnType<typeof useUpdateGenericProductMutation>
  | ReturnType<typeof useUpdateInvestmentProductMutation>
  | ReturnType<typeof useUpdateLifeInsuranceProductMutation>
  | ReturnType<typeof useUpdateLoanProductMutation>
  | ReturnType<typeof useUpdateMortgageProductMutation>
  | ReturnType<typeof useUpdateMotorLiabilityInsuranceProductMutation>
  | ReturnType<typeof useUpdatePropertyInsuranceProductMutation>
  | ReturnType<typeof useUpdateRetirementPensionSavingsProductMutation>
  | ReturnType<typeof useUpdateSavingsProductMutation>
  | ReturnType<typeof useUpdateSupplementaryPensionSavingsProductMutation>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMapProductToInput = (type: FinancialProductType) => {
  switch (type) {
    case FinancialProductType.BuildingSavings:
      return mapBuildingSavingsProductToInput;
    case FinancialProductType.CollisionInsurance:
      return mapCollisionInsuranceProductToInput;
    case FinancialProductType.CreditCard:
      return mapCreditCardProductToInput;
    case FinancialProductType.GenericInsurance:
      return mapGenericInsuranceProductToInput;
    case FinancialProductType.Generic:
      return mapGenericProductToInput;
    case FinancialProductType.Investment:
      return mapInvestmentProductToInput;
    case FinancialProductType.LifeInsurance:
      return mapLifeInsuranceProductToInput;
    case FinancialProductType.Loan:
      return mapLoanProductToInput;
    case FinancialProductType.Mortgage:
      return mapMortgageProductToInput;
    case FinancialProductType.MotorLiabilityInsurance:
      return mapMotorLiabilityInsuranceProductToInput;
    case FinancialProductType.PropertyInsurance:
      return mapPropertyInsuranceProductToInput;
    case FinancialProductType.RetirementPensionSavings:
      return mapRetirementPensionSavingsProductToInput;
    case FinancialProductType.Savings:
      return mapSavingsProductToInput;
    case FinancialProductType.SupplementaryPensionSavings:
      return mapSupplementaryPensionSavingsProductToInput;
  }
};

export const useUpdateProduct = (type: FinancialProductType): UpdateProductMutation => {
  const { t } = useTranslation();
  const notify = useNotification();

  const mutation = {
    [FinancialProductType.BuildingSavings]: useUpdateBuildingSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['BuildingSavingsProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.CollisionInsurance]: useUpdateCollisionInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CollisionInsuranceProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.CreditCard]: useUpdateCreditCardProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CreditCardProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.Generic]: useUpdateGenericProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.GenericInsurance]: useUpdateGenericInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericInsuranceProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.Investment]: useUpdateInvestmentProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['InvestmentProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.LifeInsurance]: useUpdateLifeInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LifeInsuranceProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.Loan]: useUpdateLoanProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LoanProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.Mortgage]: useUpdateMortgageProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MortgageProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.MotorLiabilityInsurance]: useUpdateMotorLiabilityInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MotorLiabilityInsuranceProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.PropertyInsurance]: useUpdatePropertyInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['PropertyInsuranceProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.RetirementPensionSavings]: useUpdateRetirementPensionSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['RetirementPensionSavingsProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.Savings]: useUpdateSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['RetirementPensionSavingsProducts', 'Notifications', 'NotificationsCount'],
    }),
    [FinancialProductType.SupplementaryPensionSavings]: useUpdateSupplementaryPensionSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SupplementaryPensionSavingsProducts', 'Notifications', 'NotificationsCount'],
    }),
  }[type];

  return mutation;
};
