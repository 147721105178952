import { configureAmplify } from 'common/auth/aws-amplify-setup';
import { Loading } from 'common/components/Loading/Loading';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { LoginDialog, LoginDialogProps } from 'components/auth/LoginDialog';
import { useSynchronizeUserMutation } from 'generated/graphql';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BasicLayout } from '../common/layout/onboarding/BasicLayout';

export const VerifyEmailChangePage: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, isLoading: isLoadingAuth, verifyAttribute } = useContext(AuthContext);
  const notify = useNotification();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [synchronizeUser] = useSynchronizeUserMutation();

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-page-dialog',
  });

  const handleVerifyEmailChange = async () => {
    const code = searchParams.get('confirmationCode');

    if (code) {
      setLoading(true);
      configureAmplify();
      const resp = await verifyAttribute('email', code);
      setLoading(false);

      if (!resp) {
        synchronizeUser();
        notify({ message: t('verifyEmailChange.page.success'), type: 'success' });
        navigate('/', { replace: true });
      } else {
        notify({ message: resp.formError?.message ?? t('common:somethingWentWrong'), type: 'error' });
        navigate('/settings', { replace: true });
      }
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (!isLoadingAuth) {
      if (!isAuthenticated) {
        loginDialog.create({
          displayAsPage: true,
          title: t('verifyEmailChange.page.login.title'),
        });
      } else {
        handleVerifyEmailChange();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoadingAuth]);

  return <BasicLayout>{loading && <Loading hideCommonLoadingTitle messages={[]} />}</BasicLayout>;
};
