import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Image } from 'common/components/Image/Image';

const styles: Record<'institutionIcon' | 'letterIcon', SxProps<Theme>> = {
  institutionIcon: {
    borderRadius: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  letterIcon: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[500],
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
};

interface InstitutionIconProps {
  alt?: string;
  src?: string;
  title?: string;
  width?: number;
}

export const InstitutionIcon: React.FC<InstitutionIconProps> = ({ alt, src, title, width }) => {
  if (src) {
    return (
      <Image alt={alt} height={width ?? 48} src={src} sx={styles.institutionIcon} title={title} width={width ?? 48} />
    );
  }
  return (
    <Box height={width ?? 48} sx={styles.letterIcon} width={width ?? 48}>
      <Typography color={'gray.50'} fontSize={(width ?? 48) / 2}>
        {(title || '?')[0].toUpperCase()}
      </Typography>
    </Box>
  );
};
