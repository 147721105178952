import { styled } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface BaseLinkProps extends RouterLinkProps {
  disabled?: boolean;
}

const BaseLink: React.FC<BaseLinkProps> = (props) => {
  return <RouterLink {...props} />;
};

export const Link = styled(BaseLink, {
  shouldForwardProp: (prop) => !['disabled'].includes(String(prop)),
})(({ disabled }) => ({
  cursor: disabled ? 'disabled' : 'pointer',
  pointerEvents: disabled ? 'none' : 'inherit',
  userSelect: disabled ? 'none' : 'inherit',
}));
