import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormPasswordInput } from 'common/form/components/FormPasswordInput';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { StyledDialogFormError } from '../../../common/components/StyledDialog/StyledDialogFormError';
import { loginEmailValidator, passwordValidator } from '../../../common/form/validators';
import { useSuccessDialog } from '../../../common/hooks/useSuccessDialog';
import { AuthContext } from '../../../common/providers/AuthContextProvider/AuthContextProvider';
import { styles } from './ChangeDialog.styles';

interface FormValues {
  currentPassword: string;
  newPassword: string;
}

export type ChangeDialogPasswordProps = DialogComponentProps<void>;

export const ChangePasswordDialog: React.FC<ChangeDialogPasswordProps> = ({ isOpen, onReject, onResolve }) => {
  const { t } = useTranslation();
  const { changePassword } = useContext(AuthContext);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    currentPassword: loginEmailValidator(),
    newPassword: passwordValidator(),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const openSuccessDialog = useSuccessDialog();

  const handleSubmit = formMethods.handleSubmit(async (values: FormValues) => {
    setIsLoading(true);
    setFormError('');

    const error = await changePassword(values.currentPassword, values.newPassword);
    if (!error) {
      openSuccessDialog({
        message: t('settings.changeDialog.success'),
        title: t('settings.changeDialog.password.title'),
      });
      onResolve();
      return;
    }
    if (error.formError) {
      setFormError(error.formError.message);
    }
    setIsLoading(false);
  });

  return (
    <FormProvider {...formMethods}>
      <StyledDialog maxWidth="sm" onClose={() => onReject()} open={isOpen}>
        <StyledDialogTitle textAlign="left">{t('settings.changeDialog.password.title')}</StyledDialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormPasswordInput
              fullWidth
              label={t('settings.changeDialog.password.labelCurrent')}
              name="currentPassword"
              placeholder={t('settings.changeDialog.placeholder.password.current')}
              sx={styles.input}
            />
            <FormPasswordInput
              fullWidth
              label={t('settings.changeDialog.password.labelNew')}
              name="newPassword"
              placeholder={t('settings.changeDialog.placeholder.password.new')}
              sx={styles.input}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondaryButton" onClick={() => onReject()}>
              {t('settings.changeDialog.cancel')}
            </Button>
            <LoadingButton loading={isLoading} type="submit">
              {t('settings.changeDialog.save')}
            </LoadingButton>
          </DialogActions>
          {formError && <StyledDialogFormError content={formError} title={t('settings.changeDialog.error')} />}
        </Box>
      </StyledDialog>
    </FormProvider>
  );
};
