import { Stack, Typography } from '@mui/material';
import {
  ConfirmDialogOnSide,
  ConfirmDialogOnSideProps,
} from 'common/components/ConfirmDialogOnSide/ConfirmDialogOnSide';
import { useDialog } from 'common/hooks/useDialog';
import _ from 'lodash';
import { Path } from 'react-hook-form';

import { ButtonRow } from '../ButtonRow/ButtonRow';
import { DetailRow } from '../DetailRow/DetailRow';
import { DetailRowType } from '../types';
import { styles } from './ExpandedList.styles';

interface Props<T> {
  data: T;
  path: string;
  labelPath: string;
  removeLabel: string;
  fields: Array<
    { [K in Path<PathValue<T, `${Path<T>}.0`>>]: DetailRowType<PathValue<T, `${Path<T>}.0`>, K> }[Path<
      PathValue<T, `${Path<T>}.0`>
    >]
  >;
  onUpdate: <K extends Path<T>>(value: PathValue<T, K>, path: string) => Promise<void>;
  onRemove: () => void;
  updatedAt?: Date;
}

export const ExpandedListItem = <T,>({
  data,
  fields,
  labelPath,
  onRemove,
  onUpdate,
  path,
  removeLabel,
  updatedAt,
}: Props<T>): React.ReactElement => {
  const label = _.get(data, labelPath) ?? '';

  const deleteProductItemDialog = useDialog<ConfirmDialogOnSideProps, boolean>(ConfirmDialogOnSide, {
    instanceId: 'delete-product-item',
  });

  const handleRemove = async () => {
    await deleteProductItemDialog.create({
      confirmButtonText: removeLabel,
      onConfirm: async () => {
        await onRemove();
      },
      title: removeLabel,
    });
  };

  return (
    <Stack mt={5}>
      <Typography variant="headlineM">{label}</Typography>
      <Stack sx={styles.container}>
        {fields
          .filter((field) => field.path != labelPath)
          .map((field) => {
            return (
              <DetailRow<PathValue<T, Path<T>>>
                key={field.path}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatter={field.formatter as any}
                helperText={field.helperText}
                inputEndAdornment={field.inputEndAdornment}
                inputType={field.inputType}
                label={field.label}
                onUpdate={onUpdate}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                parse={field.parse as any}
                path={`${path}.${field.path}`}
                tooltip={field.tooltip}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                transform={field.transform as any}
                updatedAt={updatedAt}
                value={field.path ? _.get(data, field.path) : data}
              />
            );
          })}
        <ButtonRow color="red.dark" label={removeLabel} onClick={handleRemove} />
      </Stack>
    </Stack>
  );
};
