import { useReactiveVar } from '@apollo/client';
import { DATA_PROTECTION_POLICY_URL } from '@brand/const';
import { Box, Stack, Typography } from '@mui/material';
import { saveUnregisteredUserId } from 'common/auth/unregistered-user';
import { FormCheckBox } from 'common/form/components/FormCheckBox';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { normalizePhoneNumber } from 'common/utils/formatUtils';
import { pushGTMEvent } from 'common/utils/GTMeventUtils';
import { LoginDialog, LoginDialogProps } from 'components/auth/LoginDialog';
import { ExpectationsBanner } from 'components/flows/common/ExpectationsBanner/ExpectationsBanner';
import { GTMEventValueEnum, LIFE_INSURANCE_CALCULATOR_BASE_URL } from 'const';
import { LeadContext, useCreateLeadMutation, useCreateUnregisteredUserMutation } from 'generated/graphql';
import _ from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { CalculatorSection } from '../CalculatorSection/CalculatorSection';
import { getSchema, LifeInsuranceCalculatorFormValues } from './LifeInsuranceCalculator.schema';
import { getSections } from './LifeInsuranceCalculator.sections';
import { lifeInsuranceCalculatorState } from './LifeInsuranceCalculator.utils';
import { styles } from './LifeInsuranceCalculatorContent.style';

export const LifeInsuranceCalculatorContent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, userData } = useContext(AuthContext);
  const notify = useNotification();
  const sections = getSections(t, isAuthenticated);
  const state = useReactiveVar(lifeInsuranceCalculatorState);

  const [saveLead] = useCreateLeadMutation({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {}, // lead creation errors should not prevent completion of the flow
  });

  const schema = getSchema(t);

  const [expandedIndex, setExpandedIndex] = useState(0);

  // in case of error on result page we are returned here and we expand last contact step
  useEffect(() => {
    if (state?.contact?.email || state?.contact?.phone) {
      setExpandedIndex(sections.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit =
    (index: number): (() => void) =>
    () => {
      setExpandedIndex(index);
    };

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });

  const [createUnregisteredUser] = useCreateUnregisteredUserMutation({
    onError: (error) => {
      if (error.message.includes('UserAlreadyRegistered')) {
        loginDialog.create({
          title: (
            <Stack spacing={2}>
              <Typography variant="headlineL">{t('flows/lifeInsurance/calculator:login.title')}</Typography>
              <Typography variant="bodyL">{t('flows/lifeInsurance/calculator:login.subtitle')}</Typography>
            </Stack>
          ),
        });
      } else {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      }
    },
  });

  const handleContinue =
    (index: number) =>
    async (data: LifeInsuranceCalculatorFormValues[Extract<keyof LifeInsuranceCalculatorFormValues, string>]) => {
      const newValues = _.merge(state, { [sections[index].name]: data });
      lifeInsuranceCalculatorState(newValues);
      if (index === sections.length - 1) {
        const email = userData?.user.attributes.email ?? newValues.contact?.email ?? '';
        const phoneNumber = userData?.user.attributes.phone_number ?? newValues.contact?.phone ?? '';
        if (!isAuthenticated) {
          const resp = await createUnregisteredUser({
            variables: {
              user: {
                email,
                phoneNumber,
              },
            },
          });
          if (!resp.errors && resp.data?.createUnregisteredUser) {
            saveUnregisteredUserId(resp.data?.createUnregisteredUser);
          }
        }
        pushGTMEvent(GTMEventValueEnum.LifeInsuranceScoreCalculationCompleted, email);
        saveLead({
          variables: {
            context: LeadContext.LiScoreCalc,
            userInfo: {
              email,
              phoneNumber: normalizePhoneNumber(phoneNumber),
            },
          },
        });
        navigate(`${LIFE_INSURANCE_CALCULATOR_BASE_URL}/result`);
      } else {
        setExpandedIndex(index + 1);
      }
    };

  return (
    <Stack spacing={5} sx={styles.container}>
      <Stack spacing={0.5} sx={styles.header}>
        <Typography variant="headlineXL">{t('flows/lifeInsurance/calculator:header.title')}</Typography>
        <Typography variant="bodyL">{t('flows/lifeInsurance/calculator:header.subtitle')}</Typography>
      </Stack>

      <Stack sx={styles.sections}>
        {sections.map((section, index) => (
          <Fragment key={section.name}>
            <CalculatorSection
              defaultValues={state[section.name]}
              inputs={section.inputs}
              isExpanded={expandedIndex === index}
              nextButtonLabel={
                index === sections.length - 1
                  ? isAuthenticated
                    ? t('flows/lifeInsurance/calculator:section.submit.registered')
                    : t('flows/lifeInsurance/calculator:section.submit.unregistered')
                  : t('flows/lifeInsurance/calculator:section.continue')
              }
              number={index + 1}
              onEdit={handleEdit(index)}
              onSubmit={handleContinue(index)}
              schema={yup.reach(schema, section.name)}
              sectionName={section.name}
              title={section.title}
            >
              {index === sections.length - 1 && (
                <>
                  {!isAuthenticated && (
                    <Box sx={styles.consent}>
                      <FormCheckBox name="consent">
                        <Typography color="textSecondary" variant="body2">
                          <Trans i18nKey="flows/common:calculator.consent">
                            <a href={DATA_PROTECTION_POLICY_URL} target="_blank" />
                          </Trans>
                        </Typography>
                      </FormCheckBox>
                    </Box>
                  )}
                  <ExpectationsBanner
                    list={[
                      {
                        subtitle: t('flows/lifeInsurance/calculator:banner.list.0.subtitle'),
                        title: t('flows/lifeInsurance/calculator:banner.list.0.title'),
                      },
                      {
                        subtitle: t('flows/lifeInsurance/calculator:banner.list.1.subtitle'),
                        title: t('flows/lifeInsurance/calculator:banner.list.1.title'),
                      },
                      {
                        subtitle: t('flows/lifeInsurance/calculator:banner.list.2.subtitle'),
                        title: t('flows/lifeInsurance/calculator:banner.list.2.title'),
                      },
                    ]}
                  />
                </>
              )}
            </CalculatorSection>
            {index < sections.length - 1 && <Box sx={styles.border} />}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
