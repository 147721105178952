import { DATA_PROTECTION_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@brand/const';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Link, Link as MuiLink, Stack, Typography } from '@mui/material';
import { ReactComponent as GoogleLogo } from 'assets/icons/GoogleLogo.svg';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormCheckBox } from 'common/form/components/FormCheckBox';
import { FormInput } from 'common/form/components/FormInput';
import { FormPasswordInput } from 'common/form/components/FormPasswordInput';
import {
  emailValidator,
  passwordValidator,
  personalDataValidator,
  termsAndConditionsValidator,
} from 'common/form/validators';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { pushGTMEvent } from 'common/utils/GTMeventUtils';
import { SAVE_LOCAL_STATE_STORAGE_KEY } from 'components/flows/useSaveLocalFlowStates';
import { GTMEventValueEnum, ProductFlows } from 'const';
import { useContext, useState } from 'react';
import { MobileView } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { GoogleLoginInfoDialog } from './GoogleLoginInfoDialog';
import { GoogleRegisterDialog, GoogleRegisterDialogProps } from './GoogleRegisterDialog';
import { LoginDialog, LoginDialogProps } from './LoginDialog';
import { styles } from './styles';
import { VerifyEmailDialog, VerifyEmailDialogProps } from './VerifyEmailDialog';

interface FormValues {
  email: string;
  password: string;
  agreedToPersonalData: boolean;
  agreedToTermsAndConditions: boolean;
}

export interface RegisterDialogProps extends DialogComponentProps<void> {
  displayAsPage?: boolean;
  saveLocalState?: ProductFlows[];
}

export const RegisterDialog: React.FC<RegisterDialogProps> = ({ displayAsPage, isOpen, onResolve, saveLocalState }) => {
  const { t } = useTranslation();
  const { signUp } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    agreedToPersonalData: personalDataValidator(),
    agreedToTermsAndConditions: termsAndConditionsValidator(),
    email: emailValidator(),
    password: passwordValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      agreedToPersonalData: false,
      agreedToTermsAndConditions: false,
      email: '',
      password: '',
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [formError, setFormError] = useState('');

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });

  const handleLogin = () => {
    loginDialog.create({ saveLocalState });
    onResolve();
  };

  const verifyEmailDialog = useDialog<VerifyEmailDialogProps, void>(VerifyEmailDialog, {
    instanceId: 'verify-email-dialog',
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    setIsLoading(true);
    setFormError('');

    const result = await signUp({ email: data.email, password: data.password });
    setIsLoading(false);

    if (!result) {
      // the flow is broken by email verification, so we don't need to save the state
      if (!!saveLocalState) localStorage.setItem(SAVE_LOCAL_STATE_STORAGE_KEY, saveLocalState.join(','));

      verifyEmailDialog.create({
        email: data.email,
        title: <Trans i18nKey={'registrationVerification.registrationFlowTitle'} />,
      });

      // we fire registration event before email verification as we don't know the email on verification page
      // but this means that the registration event will be fired even if the user doesn't verify the email
      await pushGTMEvent(GTMEventValueEnum.Registration, data.email);

      onResolve();
      return;
    }
    if (result.fieldError) {
      Object.entries(result.fieldError).forEach(([name, error]) =>
        formMethods.setError(name as keyof FormValues, { message: error.message }),
      );
    }
    if (result.formError) {
      setFormError(result.formError.message);
    }
  });

  const googleRegisterDialog = useDialog<GoogleRegisterDialogProps, void>(GoogleRegisterDialog, {
    instanceId: 'google-register',
  });

  const handleGoogleRegister = () => {
    googleRegisterDialog.create({
      saveLocalState,
    });
  };

  const googleRegistrationInfoDialog = useDialog<DialogComponentProps<void>, void>(GoogleLoginInfoDialog, {
    instanceId: 'google-registration-info',
  });

  const handleGoogleRegistrationInfoClick = () => {
    googleRegistrationInfoDialog.create({});
  };

  return (
    <StyledDialog displayAsPage={displayAsPage} fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle>{t('registration.title')}</StyledDialogTitle>

      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={1} sx={styles.googleButton}>
              <Button color="secondaryButton" onClick={handleGoogleRegister} startIcon={<GoogleLogo />}>
                {t('registration.googleRegister')}
              </Button>

              <MobileView>
                <Typography>
                  <Trans i18nKey="registration.googleRegistrationInfo">
                    <Link onClick={handleGoogleRegistrationInfoClick} variant="bodyS" />
                  </Trans>
                </Typography>
              </MobileView>
            </Stack>

            <FormInput
              autoComplete="email"
              autoFocus
              label={t('registration.email.label')}
              name="email"
              placeholder={t('registration.email.placeholder')}
              sx={styles.input}
              type="email"
            />

            <FormPasswordInput
              autoComplete="new-password"
              label={t('registration.password.label')}
              name="password"
              placeholder={t('registration.password.placeholder')}
              sx={styles.input}
            />

            <Stack mt={2} spacing={1}>
              <FormCheckBox name="agreedToTermsAndConditions">
                <Typography color="textSecondary" variant="body2">
                  <Trans i18nKey="registration.acceptTermsAndConditions">
                    <a href={TERMS_AND_CONDITIONS_URL} target="_blank" />
                  </Trans>
                </Typography>
              </FormCheckBox>

              <FormCheckBox name="agreedToPersonalData">
                <Typography color="textSecondary" variant="body2">
                  <Trans i18nKey="registration.acceptPersonalData">
                    <a href={DATA_PROTECTION_POLICY_URL} target="_blank" />
                  </Trans>
                </Typography>
              </FormCheckBox>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} size="large" sx={styles.button} type="submit">
              {t('registration.submit')}
            </LoadingButton>
          </DialogActions>
          {formError && <StyledDialogFormError content={formError} title={t('registration.error.title')} />}
        </Box>
        <Typography color="textSecondary" marginTop={4} textAlign="center" variant="body1">
          <Trans i18nKey="registration.alreadyRegistered">
            <MuiLink onClick={handleLogin} />
          </Trans>
        </Typography>
      </FormProvider>
    </StyledDialog>
  );
};
