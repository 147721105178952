import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactNode } from 'react';

import { styles } from './UpcomingPaymentListItem.styles';

interface UpcomingPaymentListItemProps {
  children?: ReactNode;
  headline: string;
  onClick?: () => void;
}

export const UpcomingPaymentListItem: React.FC<UpcomingPaymentListItemProps> = ({ children, headline, onClick }) => {
  return (
    <Box sx={styles.container}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        onClick={onClick}
        sx={onClick ? styles.clickableItem : styles.row}
      >
        <Typography variant="bodyL">{headline}</Typography>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <Stack textAlign="right">{children}</Stack>
          {onClick && <ChevronRightIcon />}
        </Stack>
      </Stack>
    </Box>
  );
};
