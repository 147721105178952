import { Button, Stack, Typography } from '@mui/material';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatCurrency } from 'common/utils/formatUtils';
import { MORTGAGE_CALCULATOR_BASE_URL } from 'const';
import { SortDirection, useMortgageCalculatorCalculationsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { styles } from './CalculatorCards.styles';

export const MortgageCalculatorCard: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data } = useMortgageCalculatorCalculationsQuery({
    fetchPolicy: 'network-only',
    skip: !isAuthenticated,
    variables: {
      // fetch only latest calculation
      pageInput: {
        pageIndex: 0,
        pageSize: 1,
      },
      sortInput: {
        by: [{ direction: SortDirection.Desc, fieldPath: 'creationTimestamp' }],
      },
    },
  });
  const mortgageCalculationsCount = data?.calculations.totalItems ?? 0;
  const buttonLabel =
    mortgageCalculationsCount == 0
      ? t('homepage.products.calculatorCards.button.calculate')
      : t('homepage.products.calculatorCards.button.recalculate');

  const handleMortgageCalculatorClick = () => {
    navigate(MORTGAGE_CALCULATOR_BASE_URL);
  };

  return (
    <Stack gap={2} justifyContent="space-between" sx={styles.card}>
      <Stack alignItems="top" direction="row" justifyContent="space-between">
        {mortgageCalculationsCount == 0 ? (
          <Typography color="gray.500" variant="headlineM">
            ... €
          </Typography>
        ) : (
          <Typography variant="headlineM">
            {formatCurrency(
              data?.calculations.items[0].result.maxMortgageAmount.amount ?? 0,
              i18n.language,
              0,
              data?.calculations.items[0].result.maxMortgageAmount.currency,
            )}
          </Typography>
        )}

        <Button
          aria-label={buttonLabel}
          color="tertiaryButton"
          onClick={handleMortgageCalculatorClick}
          size="medium"
          sx={styles.button}
        >
          <Typography color="blue.dark" variant="bodyL">
            {buttonLabel}
          </Typography>
        </Button>
      </Stack>

      <Stack>
        <Typography variant="bodyStrongXXL">
          {t('homepage.products.calculatorCards.mortgageCalculator.title')}
        </Typography>
        <Typography color="textSecondary" variant="bodyM">
          {t('homepage.products.calculatorCards.mortgageCalculator.subtitle')}
        </Typography>
      </Stack>
    </Stack>
  );
};
