import {
  FinancialProductType,
  InstitutionFragment,
  ProductStatus,
  TransactionGroupPeriodTypeCode,
} from 'generated/graphql';
import { Path } from 'react-hook-form';

import { SetValueDialogProps } from './SetValueDialog/SetValueDialog';

export interface DetailRowType<T, P extends Path<T>> {
  label?: string;
  helperText?: string;
  tooltip?: string;
  path: P;
  transform?: (value: NonNullable<PathValue<T, P>>) => NonNullable<PathValue<T, P>>;
  parse?: (value: NonNullable<PathValue<T, P>>) => NonNullable<PathValue<T, P>>;
  formatter?: (value: NonNullable<PathValue<T, P>>) => string;
  inputType?: SetValueDialogProps<PathValue<T, P>>['inputType'];
  options?: SetValueDialogProps<PathValue<T, P>>['options'];
  inputEndAdornment?: string;
  skip?: boolean;
}

export interface ExpandedListType<T, P extends Path<T>> {
  fields: Array<
    { [K in Path<PathValue<T, `${P}.0`>>]: DetailRowType<PathValue<T, `${P}.0`>, K> }[Path<PathValue<T, `${P}.0`>>]
  >;
  path: P;
  labelPath: Path<PathValue<T, `${P}.0`>>;
  addLabel: string;
  removeLabel: string;
  maxLength?: number;
}

export interface ButtonRowType {
  label?: string;
  onClick: () => void;
  color?: string;
  path: never;
  skip?: boolean;
}

export interface Section<T, P extends Path<T>> {
  title: string;
  rows: Array<{ [K in P]: DetailRowType<T, K> | ButtonRowType }[P]>;
}

export interface List<T, P extends ArrayPath<T>> {
  title: string;
  label: string;
  maxLength?: number;
  path: P;
  addLabel: string;
  removeLabel: string;
  formatter: (value: PathValue<T, P> extends Array<infer K> ? K : never) => string;
  fields: Array<
    {
      [K in Path<PathValue<T, `${P}.0`>>]:
        | DetailRowType<PathValue<T, `${P}.0`>, K>
        | ExpandedListType<PathValue<T, `${P}.0`>, K>;
    }[Path<PathValue<T, `${P}.0`>>]
  >;
}

export const isSection = <T, P extends Path<T>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: Section<T, P> | List<T, any>,
): value is Section<T, P> => {
  return Object.hasOwn(value, 'rows');
};

export const isButtonRow = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: ButtonRowType | DetailRowType<any, any>,
): value is ButtonRowType => {
  return Object.hasOwn(value, 'onClick');
};

export const isExpandedList = <T, P extends Path<T>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: DetailRowType<T, P> | ExpandedListType<T, P>,
): value is ExpandedListType<T, P> => {
  return Object.hasOwn(value, 'fields');
};

export interface ProductBase {
  id: string;
  name?: string | null;
  status: ProductStatus;
  statusChangeDate: Date;
  enableNotifications: boolean;
  enableFinancialAdvisorCare: boolean;
  contractNumber?: string | null;
  paymentPeriod?: {
    expectedAmount?: {
      amount: number;
      currency: string;
    } | null;
    periodCount?: number | null;
    periodTypeCode?: TransactionGroupPeriodTypeCode | null;
    referenceDate?: Date | null;
    expectedDate?: Date | null;
  } | null;
  paymentInfo?: {
    ibanForPayment?: string | null;
    variableSymbol?: string | null;
    constantSymbol?: string | null;
    specificSymbol?: string | null;
  } | null;
  institution?: InstitutionFragment | null;
  institutionName?: string | null;
  documents: {
    id: string;
    files: {
      id: string;
      name?: string | null;
    }[];
  }[];
  __typename?: string;
}

export interface ProductBaseExtended extends ProductBase {
  type: FinancialProductType;
}

export const productTypename = (productType: FinancialProductType): string => {
  return `${(Object.keys(FinancialProductType) as Array<keyof typeof FinancialProductType>).find(
    (key) => FinancialProductType[key] === productType,
  )}Product`;
};
