import { Box, Button, ButtonProps, Stack } from '@mui/material';

interface Props extends ButtonProps {
  children: React.ReactNode;
  icon: React.ReactNode;
}

export const ButtonWithIcon: React.FC<Props> = ({ children, icon, ...props }) => {
  return (
    <Button {...props}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Stack>{icon}</Stack>
        <Box>{children}</Box>
      </Stack>
    </Button>
  );
};
