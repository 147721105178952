import { Box, FormControlLabel, Radio, RadioGroup, Stack, SxProps, Theme } from '@mui/material';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InfoBar } from 'common/components/InfoBar/InfoBar';
import { InputLabel } from 'common/components/InputLabel/InputLabel';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';

import { FormElementProps } from './FormElement';
import { styles } from './FormRadioSelect.styles';

export interface RadioSelect<Value> {
  label: string | React.ReactNode;
  value: Value;
  disabled?: boolean;
  helperText?: string;
}

export interface FormRadioSelectProps<Value> extends FormElementProps {
  options: RadioSelect<Value>[];
  size?: 'large' | 'medium';
  helperText?: string;
}

export const FormRadioSelect = <Value,>({
  autoFocus,
  helperText,
  label,
  name,
  onBlur,
  onChange,
  options,
  size,
  sx,
}: FormRadioSelectProps<Value>): React.ReactElement => {
  const { field, fieldState } = useController({ name });
  const selectedOption = options.find((option) => option.value === field.value);

  const focusedRadioRef = useRef<null | HTMLElement>(null);

  const handleChange = (value: Value) => () => {
    if (value === field.value) {
      //react-hok-form does not support undefined as field value
      field.onChange(null);
      field.onBlur();
      onBlur?.();
      return;
    }
    field.onChange(value);
    onChange?.();
  };

  /** Set first chip, or selected chip as autoFocused */
  const handleAutoFocusedRadioRef = (index: number, option: RadioSelect<Value>) => (element: HTMLDivElement | null) => {
    if (index === 0) {
      focusedRadioRef.current = element;
      return;
    }

    if (field.value === option.value) {
      focusedRadioRef.current = element;
    }
  };

  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    focusedRadioRef?.current?.focus();
  }, [autoFocus]);

  return (
    <>
      {!!label && <InputLabel helperText={helperText} label={label} size={size} />}
      <RadioGroup>
        <Stack spacing={1} sx={sx}>
          {options.map((option, index) => (
            <Box key={JSON.stringify(option.value)}>
              <FormControlLabel
                control={
                  <Radio
                    checked={_.isEqual(field.value, option.value)}
                    checkedIcon={<Box sx={styles.selectedIcon} />}
                    disabled={option.disabled}
                    icon={<Box sx={styles.deselectedIcon} />}
                    onClick={handleChange(option.value)}
                  />
                }
                label={option.label}
                ref={handleAutoFocusedRadioRef(index, option)}
                sx={
                  [styles.radioBox, _.isEqual(field.value, option.value) && styles.radioBoxSelected] as SxProps<Theme>
                }
                tabIndex={0}
              />
            </Box>
          ))}
        </Stack>
      </RadioGroup>
      {!!fieldState?.error?.message && (
        <FormHelperText error size={size}>
          {fieldState.error.message}
        </FormHelperText>
      )}
      {selectedOption?.helperText && <InfoBar> {selectedOption.helperText}</InfoBar>}
    </>
  );
};
