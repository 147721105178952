import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { fieldIsRequiredValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  name?: string | null;
}

export interface EditEventNameDialogProps extends DialogComponentProps<string | undefined | null> {
  name?: string | null;
}

export const EditEventNameDialog: React.FC<EditEventNameDialogProps> = ({ isOpen, name, onReject, onResolve }) => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    name: fieldIsRequiredValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      name,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve(data.name);
  });

  return (
    <PromptDialogOnSide
      onClose={() => onReject()}
      open={isOpen}
      subtitle={t('products:financialCalendar.editNameDialog.text')}
      title={t('products:financialCalendar.editNameDialog.title')}
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoComplete="name" autoFocus fullWidth name="name" type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('products:financialCalendar.editNameDialog.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onReject()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
