import { NumberInput, Validator } from 'react-admin';

interface Props {
  source: string;
  label?: string;
}

const validateExpectedAmount: Validator = (value) => {
  if (typeof value === 'number' && value >= 0) {
    return 'Zadajte sumu ako kladné číslo';
  }
  return undefined;
};

export const ExpectedAmountInput: React.FC<Props> = ({ label, source }) => {
  return (
    <NumberInput
      format={(value) => (typeof value === 'number' ? -value : undefined)}
      label={label}
      parse={(value) => (typeof value === 'number' ? -value : undefined)}
      source={source}
      validate={validateExpectedAmount}
    />
  );
};
