import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as CheckmarkSuccessIcon } from 'assets/icons/Checkmark.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import { styles } from './DeleteSuccessDialog.styles';

export type DeleteSuccessDialogProps = DialogComponentProps<void>;

export const DeleteSuccessDialog: React.FC<DeleteSuccessDialogProps> = ({ instanceId, isOpen, onResolve }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onResolve();
  };
  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={handleClose}>
      <Stack alignItems="center">
        <Box sx={styles.image}>
          <CheckmarkSuccessIcon height={40} width={40} />
        </Box>
        <Typography variant="headlineXL">{t('products:deleteSuccess.title')}</Typography>
        <Typography color="text.secondary" variant="bodyM">
          {t('products:deleteSuccess.subtitle')}
        </Typography>

        <Button fullWidth onClick={handleClose} size="large" sx={styles.button}>
          {t('products:deleteSuccess.button')}
        </Button>
      </Stack>
    </ResponsiveSideDialog>
  );
};
