import { SxProps, Theme } from '@mui/material';

export const styles: Record<'row' | 'container', SxProps<Theme>> = {
  container: (theme) => ({
    backgroundColor: theme.palette.gray[50],
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  row: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    '&:hover': (theme) => ({
      backgroundColor: theme.palette.gray[100],
      cursor: 'pointer',
    }),
    padding: (theme) => theme.spacing(2),
  },
};
