import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'label', SxProps<Theme>> = {
  container: {
    '&:hover': (theme) => ({
      backgroundColor: theme.palette.gray[100],
      color: theme.palette.gray[900],
      cursor: 'pointer',
    }),
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    maxWidth: '280px',
    padding: (theme) => theme.spacing(2),
  },
  label: {
    color: (theme) => theme.palette.gray[600],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
