import {
  TransactionCategoryFragment,
  TransactionGroupFragment,
  TransactionGroupPeriodTypeCode,
} from 'generated/graphql';
import { TFunction } from 'react-i18next';

export const getPeriodicityLabel: (
  t: TFunction<'translation', undefined>,
  periodCount?: number,
  periodTypeCode?: TransactionGroupPeriodTypeCode,
) => string = (t, periodCount?: number, periodTypeCode?: TransactionGroupPeriodTypeCode) => {
  switch (periodTypeCode) {
    case TransactionGroupPeriodTypeCode.Week:
      return t('upcomingPaymentDetail.periodType.weekly');

    case TransactionGroupPeriodTypeCode.Month:
      switch (periodCount) {
        case 1:
          return t('upcomingPaymentDetail.periodType.monthly');
        case 3:
          return t('upcomingPaymentDetail.periodType.quarterly');
        case 6:
          return t('upcomingPaymentDetail.periodType.semiannually');
        case 12:
          return t('upcomingPaymentDetail.periodType.annually');
        default:
          return t('upcomingPaymentDetail.set');
      }
    default:
      return t('upcomingPaymentDetail.set');
  }
};

export interface ExtendedTransactionGroup extends TransactionGroupFragment {
  category: TransactionCategoryFragment;
}
