import { CheckCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { Money } from 'generated/graphql';

import { getColorForLeft2Spend } from '../utils';
import { styles } from './CashflowGuardianWidgetChart.styles';

const createOptions = (width: number, budget: number, leftToSpend: number) => {
  const color = getColorForLeft2Spend(leftToSpend, budget);
  return {
    series: [
      {
        data: [
          {
            itemStyle: { color: color[800] },
            value: budget - leftToSpend,
          },
          {
            itemStyle: { color: color[200] },
            value: leftToSpend,
          },
        ],
        emphasis: { disabled: true },
        label: {
          show: false,
        },
        radius: [width / 2, width / 2 - 8],
        type: 'pie',
      },
    ],
  };
};

export interface CashflowGuardianWidgetChartProps {
  budget: Partial<Money>;
  leftToSpend: Money;
}

export const CashflowGuardianWidgetChart: React.FC<CashflowGuardianWidgetChartProps> = ({ budget, leftToSpend }) => {
  const color = getColorForLeft2Spend(leftToSpend.amount, budget.amount);
  const width = 48;
  const options = createOptions(width, budget.amount ?? 0, leftToSpend.amount);
  return (
    <Box sx={styles.container}>
      <CheckCircle style={{ color: color[800] }} sx={styles.icon} />
      <Box sx={styles.chart}>
        <ReactEcharts option={options} style={{ height: '100%', pointerEvents: 'none', width: '100%' }} />
      </Box>
    </Box>
  );
};
