import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'controls' | 'sectionHeader' | 'title' | 'border' | 'number' | 'continueButton' | 'input',
  SxProps<Theme>
> = {
  border: {
    borderBottom: (theme) => `1px solid ${theme.palette.gray?.[300]}`,
    margin: (theme) => theme.spacing(3, 0),
  },
  continueButton: {
    marginTop: (theme) => theme.spacing(6),
  },
  controls: {
    alignItems: 'center',
    display: 'flex',
  },
  input: {
    gridColumn: '1/7',
  },
  number: {
    alignItems: 'center',
    borderRadius: '100%',
    color: (theme) => theme.palette.gray[50],
    display: 'flex',
    fontSize: '12px',
    fontWeight: 400,
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
  sectionHeader: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    gap: (theme) => theme.spacing(2),
    width: '100%',
  },
};
