import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/InfoCircle.svg';

import { styles } from './InfoBar.styles';

interface Props {
  children: React.ReactNode;
}
export const InfoBar: React.FC<Props> = ({ children }) => (
  <Stack alignItems="center" direction="row" sx={styles.container}>
    <Box sx={styles.icon}>
      <InfoIcon height={24} width={24} />
    </Box>
    <Typography ml={1} sx={styles.text} variant="bodyStrongM">
      {children}
    </Typography>
  </Stack>
);
