import { Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { SpendingReportContent } from 'components/spendingReport/SpendingReportContent';
import { PeriodTypeEnum } from 'components/spendingReport/state/spendingReportTypes';
import { formatPeriodTitle, getPeriod } from 'components/spendingReport/utils';
import { endOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';

export interface RealizedExpensesDialogProps extends DialogComponentProps<void> {
  asOfDate: Date;
}

export const RealizedExpensesDialog: React.FC<RealizedExpensesDialogProps> = (props) => {
  const period = getPeriod(PeriodTypeEnum.Month, props.asOfDate);

  const { t } = useTranslation();
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <SpendingReportContent
        chartTitle={formatPeriodTitle(period, endOfMonth(props.asOfDate))}
        header={
          <Typography component="h1" variant="headlineXL">
            {t('realizedExpenses.header')}
          </Typography>
        }
        lockedPeriodType={PeriodTypeEnum.Month}
        pointerDate={props.asOfDate}
      />
    </ResponsiveSideDialog>
  );
};
