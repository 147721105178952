import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'buttonRow' | 'profileImage', SxProps<Theme>> = {
  buttonRow: {
    backgroundColor: (theme) => theme.palette.gray[50],
    justifyContent: 'left',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  profileImage: {
    borderRadius: '100%',
    height: 96,
    width: 96,
  },
};
