import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useLifeInsuranceAuditState } from 'components/flows/lifeInsuranceAudit/state/useLifeInsuranceAuditState';
import { useLifeInsuranceAuditProcessQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LifeInsuranceAuditPreviewDialogProps,
  LifeInsuranceAuditProductPreviewDialog,
} from './MoneyhoonProcessPreviewDialog/LifeInsuranceAuditPreviewDialog';
import { ProductCard } from './ProductCard/ProductCard';

interface Props {
  id?: string;
}

export const LifeInsuranceAuditHomepageCard: React.FC<Props> = ({ id }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLifeInsuranceAuditState();

  const { data, loading } = useLifeInsuranceAuditProcessQuery({
    skip: !isAuthenticated || !id,
    variables: {
      id: id ?? '',
    },
  });

  const progress = isAuthenticated ? data?.process.inquiry?.inquiryProgress : state.meta?.progress;

  const updateTimestamp = isAuthenticated ? data?.process.updateTimestamp : state.meta?.updateTimestamp;

  const accepted = !!data?.process.locked;

  const handleLifeInsuranceAuditClick = async () => {
    processProductPreviewDialog.create({ id });
  };

  const processProductPreviewDialog = useDialog<LifeInsuranceAuditPreviewDialogProps, void>(
    LifeInsuranceAuditProductPreviewDialog,
    {
      id,
      instanceId: 'life-insurance-audit-product-preview-dialog',
      navigable: true,
    },
  );

  return !!updateTimestamp || loading ? (
    <ProductCard
      accepted={accepted}
      color="orange"
      loading={loading}
      onClick={handleLifeInsuranceAuditClick}
      progress={progress}
      title={t('products:cards.lifeInsuranceAudit.title')}
      updateDate={updateTimestamp}
    />
  ) : null;
};
