import { Route } from 'react-router-dom';
import { SentryRoutes } from 'sentry';

import { InvestmentIntroPage } from './InvestmentIntroPage';
import { InvestmentOpenAccountPage } from './InvestmentOpenAccountPage';

type Props = Record<string, never>;

export const InvestmentRouter: React.FC<Props> = () => {
  return (
    <SentryRoutes>
      <Route key="landing" element={<InvestmentIntroPage />} path="/" />
      <Route element={<InvestmentOpenAccountPage />} path="/open-account" />
    </SentryRoutes>
  );
};
