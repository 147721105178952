import { SxProps, Theme } from '@mui/material';
import { theme } from 'themes/theme';

export const styles: Record<'image' | 'banner', SxProps<Theme>> = {
  banner: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    marginTop: (theme) => theme.spacing(6),
    padding: (theme) => theme.spacing(6),
    width: '100%',
  },
  image: {
    alignItems: 'center',
    background: 'linear-gradient(#01A963, #004428)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    display: 'flex',
    height: 96,
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(1),
    width: 96,
  },
};
