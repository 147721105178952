import { SxProps, Theme } from '@mui/material';

export const styles: Record<'helpTooltip', SxProps<Theme>> = {
  helpTooltip: {
    '& svg': {
      color: (theme) => theme.palette.primary[1100],
    },
    cursor: 'pointer',
    display: 'inline-block',
    marginLeft: 1,
  },
};
