import { SxProps, Theme } from '@mui/material';

export const styles: Record<'header', SxProps<Theme>> = {
  header: {
    backgroundColor: (theme) => theme.palette.gray[50],
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
};
