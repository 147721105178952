import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { Client } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  clients?: Client[];
}

export const ContactStatsCard: React.FC<Props> = ({ clients, isLoading }) => {
  const unregisteredContacts = clients?.filter((client) => client.user && !client.user.subject);
  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">
          {`${unregisteredContacts?.length} neregistrovaných kontaktov`}
        </Typography>
        <Typography color="text.secondary">
          {`+${
            unregisteredContacts?.filter((client) => {
              const lastWeekTimestamp = new Date();
              lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);
              return client.creationTimestamp >= lastWeekTimestamp;
            }).length
          } za posledných 7 dní`}
        </Typography>
      </CardContent>
    </Card>
  );
};
