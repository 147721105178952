import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  | 'container'
  | 'page'
  | 'circle1'
  | 'circle2'
  | 'backgroundCircleWrapper'
  | 'footer'
  | 'backgroundCircleCenter'
  | 'containerCenter',
  SxProps<Theme>
> = {
  backgroundCircleCenter: {
    height: '100%',
    maxHeight: (theme) => theme.breakpoints.values.xl * 0.56,
    maxWidth: (theme) => theme.breakpoints.values.xl,
    position: 'relative',
    width: '100%',
  },
  backgroundCircleWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
  },
  circle1: {
    background: 'rgba(255, 255, 255, 0.7)',
    filter: 'blur(224px)',
    height: 418,
    left: 91,
    position: 'absolute',
    top: -30,
    width: 418,
  },
  circle2: {
    background: 'rgba(0, 138, 153, 0.13)',
    bottom: 129,
    filter: 'blur(224px)',
    height: 220,
    position: 'absolute',
    right: 144,
    width: 220,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    position: 'relative',
  },
  containerCenter: {
    justifyContent: 'center',
  },

  footer: {
    display: 'flex',
    padding: (theme) => ({
      sm: `0 ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
      xs: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    }),
    position: 'relative',
    width: '100%',
  },
  page: {
    backgroundColor: (theme) => theme.palette.gray[75],
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    minWidth: '100%',
  },
};
