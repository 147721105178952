import { SxProps, Theme } from '@mui/material';

export const styles: Record<'button' | 'card' | 'cards', SxProps<Theme>> = {
  button: {
    alignSelf: 'flex-start',
  },
  card: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: '10px',
    flexShrink: 1,
    padding: (theme) => theme.spacing(3),
    textAlign: 'left',
    width: {
      sm: '50%',
      xxs: '100%',
    },
  },
  cards: {
    flexDirection: {
      sm: 'row',
      xxs: 'column',
    },
    gap: 4,
    width: '100%',
  },
};
