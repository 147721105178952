import { InMemoryCache } from '@apollo/client';
import { TransactionsPage } from 'generated/graphql';

export const cache: InMemoryCache = new InMemoryCache({
  possibleTypes: {
    BalanceKey: ['DateKey', 'Account'],
    LifeInsuranceProposal: ['LifeInsuranceAdultProposal', 'LifeInsuranceChildProposal'],
    TransactionAggregateKey: ['DateKey', 'TransactionCategory', 'TransactionTag', 'Account'],
  },
  typePolicies: {
    Query: {
      fields: {
        aggregateTransactions: {
          keyArgs: (args, context): string =>
            JSON.stringify(context?.variables?.filterInput) + ';' + JSON.stringify(context?.variables?.groupBy),
        },
        getTransactionsPage: {
          keyArgs: (args, context): string =>
            JSON.stringify(context?.variables?.filterInput) + ';' + JSON.stringify(context?.variables?.sortInput),
          merge: (existing: TransactionsPage | undefined, incoming: TransactionsPage) => {
            //reset cache after fetching first page
            if (incoming.pageIndex === 0) {
              return incoming;
            }

            return {
              ...incoming,
              items: [...(existing?.items.slice(0, incoming.pageIndex * incoming.pageSize) ?? []), ...incoming.items],
            };
          },
        },
      },
    },
    User: {
      keyFields: ['subject'],
    },
  },
});
