import { makeVar, ReactiveVar } from '@apollo/client';
import {
  LifeInsuranceCalculatorDrivingMileage,
  LifeInsuranceCalculatorEmploymentRisk,
  LifeInsuranceCalculatorFinancialReserveRisk,
  LifeInsuranceCalculatorInquiryInput,
  LifeInsuranceCalculatorLiabilitiesSum,
} from 'generated/graphql';

import { LifeInsuranceCalculatorFormValues } from './LifeInsuranceCalculator.schema';

const initialValue: LifeInsuranceCalculatorFormValues = {};
export const lifeInsuranceCalculatorState: ReactiveVar<LifeInsuranceCalculatorFormValues> =
  makeVar<LifeInsuranceCalculatorFormValues>(initialValue);

export const mapValuesToInquiry = (values: LifeInsuranceCalculatorFormValues): LifeInsuranceCalculatorInquiryInput => {
  return {
    drivingMileage: values.driving?.mileage ?? LifeInsuranceCalculatorDrivingMileage.Over_20000Km,
    employmentRisk: values.employment?.risk ?? LifeInsuranceCalculatorEmploymentRisk.High,
    financialReserveRisk: values.financialReserve?.risk ?? LifeInsuranceCalculatorFinancialReserveRisk.High,
    healthRisk: values.health?.risk ?? true,
    liabilitiesSum: values.liabilities?.sum ?? LifeInsuranceCalculatorLiabilitiesSum.Over_150000Eur,
    sportRisk: values.sport?.risk ?? true,
  };
};
