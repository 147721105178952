import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import skDateLocale from 'date-fns/locale/sk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const dateLocaleMap: Record<string, string | object> = {
  sk: skDateLocale,
};

interface Props {
  children: React.ReactNode;
}

export const DatePickerProvider: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => dateLocaleMap[i18n.language] ?? skDateLocale, [i18n.language]);

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      {props.children}
    </LocalizationProvider>
  );
};
