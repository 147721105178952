import { ChevronRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { LightTooltip } from 'common/components/LightTooltip/LightTooltip';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { formatIban } from 'common/utils/formatUtils';
import { useAccountDetailQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useInitNordigenFlow } from '../AccountConnectUtils/AccountConnectUtils';
import { DeleteAccountDialog, DeleteAccountDialogProps } from '../DeleteAccountDialog/DeleteAccountDialog';
import { RenameAccountDialog, RenameAccountDialogProps } from '../RenameAccountDialog/RenameAccountDialog';
import { styles } from './AccountDetailDialog.styles';

interface AccountDetailProps {
  id: string;
}

const AccountDetailDialogContent: React.FC<AccountDetailDialogProps> = ({ id, onResolve }) => {
  const { i18n, t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { data, loading } = useAccountDetailQuery({
    skip: !isAuthenticated,
    variables: {
      id,
    },
  });

  const renameAccountDialog = useDialog<RenameAccountDialogProps, void>(RenameAccountDialog, {
    instanceId: 'rename-account',
  });

  const handleAccountNameClick = async () => {
    await renameAccountDialog.create({ id, name: data?.detail?.name });
  };

  const [handleInitNordigenFlow, { loading: loadingNordigenFlow }] = useInitNordigenFlow();

  const prolongConsentDisabled = !data?.detail?.institution.possibleAispNordigenConnection;

  const handleProlongConsentClick = async () => {
    const institutionId = data?.detail?.institution.id;
    if (institutionId) {
      await handleInitNordigenFlow(institutionId);
    }
  };

  const deleteAccountDialog = useDialog<DeleteAccountDialogProps, boolean>(DeleteAccountDialog, {
    instanceId: 'delete-account',
  });

  const handleRemoveAccountClick = async () => {
    const deleted = await deleteAccountDialog.create({ id });
    if (deleted) {
      onResolve();
      return;
    }
  };

  return (
    <Box>
      {loading ? (
        <Box>
          <Skeleton height={58} variant="rectangular" />
          <Box mt={3}>
            <Skeleton height={41} variant="rectangular" />
          </Box>
          <Box mt={0.5}>
            <Skeleton height={24} variant="rectangular" />
          </Box>
        </Box>
      ) : (
        <Stack alignItems="center">
          <AccountCard colorIndex={data?.detail?.colorIndex} height={58} width={96} />
          <Box mt={3}>
            <Typography textAlign="center" variant="headlineXL">
              {data?.detail?.name}
            </Typography>
          </Box>
          <Box mt={0.5}>
            <Typography sx={styles.label} variant="bodyM">
              {data?.detail?.iban ? formatIban(data?.detail?.iban) : ''}
            </Typography>
          </Box>
          <Box mt={0.5}>
            <Typography sx={styles.label} variant="bodyM">
              {data?.detail?.currency ?? ''}
            </Typography>
          </Box>
        </Stack>
      )}

      <Stack mt={3} spacing={3}>
        {loading ? (
          <Skeleton height={56} variant="rectangular" />
        ) : (
          <Box onClick={handleAccountNameClick} sx={styles.clickableRow}>
            <Typography variant="bodyL">{t('homepage.accounts.accountDetail.name')}</Typography>
            <Stack alignItems="center" direction="row">
              <Typography color="textSecondary" variant="bodyL">
                {data?.detail?.name}
              </Typography>
              <ChevronRight sx={styles.icon} />
            </Stack>
          </Box>
        )}

        <Stack sx={styles.container}>
          {loading ? (
            <Skeleton height={112} variant="rectangular" />
          ) : (
            <Stack sx={styles.data}>
              {data?.detail?.transactionsRange?.map((transactionsRange) => (
                <Box
                  key={`${transactionsRange.from?.toLocaleDateString(
                    i18n.language,
                  )}_${transactionsRange.to?.toLocaleDateString(i18n.language)}`}
                >
                  <Box sx={styles.transactionRangeRow}>
                    <Typography variant="bodyL">{t('homepage.accounts.accountDetail.transactionsFrom')}</Typography>
                    <Typography color="textSecondary" variant="bodyL">
                      {transactionsRange.from?.toLocaleDateString(i18n.language)}
                    </Typography>
                  </Box>
                  <Box sx={styles.transactionRangeRow}>
                    <Typography variant="bodyL">{t('homepage.accounts.accountDetail.transactionsTo')}</Typography>
                    <Typography color="textSecondary" variant="bodyL">
                      {transactionsRange.to?.toLocaleDateString(i18n.language)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
          )}
        </Stack>

        <Stack sx={styles.container}>
          {loading ? (
            <Skeleton height={56} variant="rectangular" />
          ) : (
            <Stack sx={styles.data}>
              <Box>
                <Box sx={styles.consentRow}>
                  <Typography variant="bodyL">{t('homepage.accounts.accountDetail.consentTo')}</Typography>

                  <Stack alignItems="center" direction="row" spacing={1}>
                    {!!data?.detail?.consentExpired && (
                      <LightTooltip placement="top" title={t('homepage.accounts.consentExpiredTooltip')}>
                        <WarningIcon />
                      </LightTooltip>
                    )}
                    <Typography color="textSecondary" variant="bodyL">
                      {data?.detail?.accessUntil?.toLocaleDateString(i18n.language)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          )}

          <LightTooltip
            placement="top"
            title={prolongConsentDisabled ? t('homepage.accounts.accountDetail.prolongConsent.disabledTooltip') : ''}
          >
            <Box>
              <LoadingButton
                color="tertiaryButton"
                disabled={prolongConsentDisabled}
                loading={loadingNordigenFlow}
                onClick={prolongConsentDisabled ? undefined : handleProlongConsentClick}
                sx={styles.buttonRow}
              >
                <Typography color="blue.dark">{t('homepage.accounts.accountDetail.prolongConsent.text')}</Typography>
              </LoadingButton>
            </Box>
          </LightTooltip>
        </Stack>

        <Stack sx={styles.container}>
          <Button color="tertiaryButton" onClick={handleRemoveAccountClick} sx={styles.buttonRow}>
            <Typography color="red.dark">{t('homepage.accounts.accountDetail.removeAccount')}</Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export interface AccountDetailDialogProps extends AccountDetailProps, DialogComponentProps<void> {}

export const AccountDetailDialog: React.FC<AccountDetailDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <AccountDetailDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
