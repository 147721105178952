import { uploadFileToDocument } from 'common/utils/fileUtils';
import { ALLOWED_FILE_TYPES } from 'const';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export enum InvalidityType {
  SIZE = 'size',
  TYPE = 'type',
}
export enum FileStatusEnum {
  loading = 'loading',
  success = 'success',
  error = ' error',
}

export interface UploadedFile {
  id: string;
  name: string;
  status: FileStatusEnum;
  error?: string;
  file?: File;
}

export interface UploadError {
  type: InvalidityType;
  message: string;
}

export const isUploadError = (error: UploadError | UploadedFile | undefined): error is UploadError => {
  return !!error && 'type' in error && 'message' in error;
};

export const useFilesUpload = (
  documentId?: string,
): ((e: ChangeEvent<HTMLInputElement>) => Promise<UploadedFile | UploadError | undefined>) => {
  const { t } = useTranslation();

  const errorMessage = (error: InvalidityType): string => {
    switch (error) {
      case InvalidityType.SIZE:
        return t('common:documents.invalidSize');
      case InvalidityType.TYPE:
        return t('common:documents.invalidType');
      default:
        return '';
    }
  };

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<UploadedFile | UploadError | undefined> => {
    if (!e.target.files?.length) {
      return;
    }

    const file = e.target.files[0];
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return {
        message: errorMessage(InvalidityType.TYPE),
        type: InvalidityType.TYPE,
      };
    }
    if (file.size > 10 * 1024 * 1024) {
      return {
        message: errorMessage(InvalidityType.SIZE),
        type: InvalidityType.SIZE,
      };
    }

    const fileName = file.name;
    const fileTmpId = new Date().getTime().toString();

    if (documentId) {
      const response = await uploadFileToDocument(file, documentId);
      if (typeof response !== 'string') {
        return { id: response.fileId, name: fileName, status: FileStatusEnum.success };
      } else if (response.includes('is not allowed')) {
        return {
          message: errorMessage(InvalidityType.TYPE),
          type: InvalidityType.TYPE,
        };
      } else {
        return {
          error: t('common:documents.uploadError'),
          file,
          id: fileTmpId,
          name: fileName,
          status: FileStatusEnum.error,
        };
      }
    }
    return;
  };

  return handleFileChange;
};
