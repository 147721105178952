import { WEB_URL } from '@brand/const';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as MoneyhoonAsteriskEmpty } from 'assets/icons/MoneyhoonAsteriskEmpty.svg';
import { BasicLayout } from 'common/layout/onboarding/BasicLayout';
import { isProductionLikeEnvironment } from 'environment';
import { useTranslation } from 'react-i18next';

import { styles } from './SettingsPageRemovedUser.styles';

export const SettingsPageRemovedUser: React.FC = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (isProductionLikeEnvironment) {
      window.location.assign(WEB_URL);
    } else {
      window.location.assign('/');
    }
  };

  return (
    <BasicLayout contentCenter>
      <Box sx={styles.content}>
        <Box sx={styles.image}>
          <MoneyhoonAsteriskEmpty height={64} width={64}></MoneyhoonAsteriskEmpty>
          <Typography sx={styles.sadFace} variant="headlineXL">
            {':('}
          </Typography>
        </Box>
        <Typography mt={3} variant="headlineXL">
          {t('settings.pageRemovedUser.title')}
        </Typography>
        <Typography component="p" mt={1} variant="bodyXL">
          {t('settings.pageRemovedUser.paragraph.item1')}
        </Typography>
        <Typography component="p" my={3} variant="bodyXL">
          {t('settings.pageRemovedUser.paragraph.item2')}
        </Typography>
        <Button onClick={handleClick} size="large">
          {t('settings.pageRemovedUser.button')}
        </Button>
      </Box>
    </BasicLayout>
  );
};
