import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { EnvironmentEnum } from 'const';
import { environment } from 'environment';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

if (environment.currentEnvironment.toUpperCase() in EnvironmentEnum && environment.sentry.dns !== '') {
  // setup by following https://docs.sentry.io/platforms/javascript/guides/react/
  Sentry.init({
    attachStacktrace: true,
    dsn: environment.sentry.dns,
    environment: environment.currentEnvironment,
    integrations: [
      // setup router tracing according to https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    // TODO: setup release pipeline (https://robofintech.atlassian.net/browse/ROBO2-1348)
    release: 'robo@0.1.0',
    // if it will be heavy for resources we should lower it (or set differently per environment)
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
    tracesSampleRate: 1.0,
  });
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
