import LogoSrc from '@brand/assets/images/logo-small.png';
import { styled } from '@mui/material';
import { BoxProps } from '@mui/system';

import { Image } from '../Image/Image';

const LogoImage = (props: BoxProps<'img'>) => <Image {...props} src={LogoSrc} />;

export const SmallLogo = styled(LogoImage)(() => {
  return {
    display: 'block',
    height: 40,
    width: 'auto',
  };
});
