import { Loading } from 'common/components/Loading/Loading';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useInitNordigenFlow } from 'components/accounts/AccountConnectUtils/AccountConnectUtils';
import { LoginDialog, LoginDialogProps } from 'components/auth/LoginDialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BasicLayout } from '../common/layout/onboarding/BasicLayout';

export const RenewNordigenConsentPage: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, isLoading: isLoadingAuth } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [initNordigenFlow, { loading }] = useInitNordigenFlow();

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-dialog',
  });
  const handleRenewNordigenConsent = async () => {
    const institutionId = searchParams.get('institutionId');
    if (institutionId) {
      const resp = await initNordigenFlow(institutionId);
      if (resp.errors) {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (!isLoadingAuth) {
      if (!isAuthenticated) {
        loginDialog.create({
          displayAsPage: true,
          title: t('renewNordigenConsentPage.login.title'),
        });
      } else {
        handleRenewNordigenConsent();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoadingAuth]);

  return <BasicLayout>{loading && <Loading hideCommonLoadingTitle messages={[]} />}</BasicLayout>;
};
