import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { Account } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  accounts?: Account[];
}

export const AccountClientStatsCard: React.FC<Props> = ({ accounts, isLoading }) => {
  const validAccounts = accounts?.filter((account) => !account.consentExpired);
  const validAccountClients = Array.from(new Set(validAccounts?.map((account) => account.client.id)));

  const lastWeekTimestamp = new Date();
  lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);

  const validAccountsBeforeLastWeek = accounts?.filter(
    (account) =>
      account.creationTimestamp < lastWeekTimestamp && account.accessUntil && account.accessUntil >= lastWeekTimestamp,
  );
  const validAccountClientsBeforeLastWeek = Array.from(
    new Set(validAccountsBeforeLastWeek?.map((account) => account.client.id)),
  );
  const deltaAccountClientsLastWeek =
    (validAccountClients?.filter((client) => !validAccountClientsBeforeLastWeek.includes(client)).length ?? 0) -
    (validAccountClientsBeforeLastWeek?.filter((client) => !validAccountClients.includes(client)).length ?? 0);

  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">
          {`${validAccountClients.length} klientov s pripojeným účtom`}
        </Typography>
        <Typography color="text.secondary">{`${
          deltaAccountClientsLastWeek >= 0 ? '+' : ''
        }${deltaAccountClientsLastWeek} za posledných 7 dní`}</Typography>
      </CardContent>
    </Card>
  );
};
