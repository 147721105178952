import { Box, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/CloseCircle.svg';
import { getNameForTransactionCounterparty } from 'components/transactions/utils';
import { format, parseISO } from 'date-fns';
import {
  FilterConditionInput,
  FilterInput,
  FilterOperator,
  TransactionTypeCode,
  useTransactionCounterpartiesQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TransactionTypeTranslations } from 'translations/enums';

import { useAccountOptions } from '../filters/AccountFilter';
import { useAmountOptions } from '../filters/AmountFilter';
import { useAnalyticsOptions } from '../filters/AnalyticsFilter';
import { useCounterpartyOptions } from '../filters/CounterpartyFilter';
import { useDateOptions } from '../filters/DateFilter';
import { usePeriodicityFilters } from '../filters/PeriodicityFilter';
import { useTypeOptions } from '../filters/TypeFilter';
import { styles } from './FilterPill.styles';

interface Props {
  filter: FilterInput;
  onClick: () => void;
}

export const FilterPill: React.FC<Props> = ({ filter, onClick }) => {
  const { t } = useTranslation();

  const { data } = useTransactionCounterpartiesQuery({
    variables: {
      pageInput: {
        pageIndex: 0,
        pageSize: 10000, //get all counterparties that exist
      },
    },
  });

  const counterpartyMap = _.keyBy(data?.page?.items, 'id');

  const dateOptions = useDateOptions();
  const amountOptions = useAmountOptions();
  const counterpartyOptions = useCounterpartyOptions();
  const { options: accountOptions } = useAccountOptions();
  const typeOptions = useTypeOptions();
  const periodicityFilters = usePeriodicityFilters();
  const analyticsOptions = useAnalyticsOptions();

  const filterConditions: (FilterConditionInput | null | undefined)[] = filter.arguments.map(
    (argument) =>
      argument.filterCondition ??
      _.find(argument.filter?.arguments, (argument) => !!argument.filterCondition)?.filterCondition,
  );

  const getLabel = () => {
    const filterCondition = filterConditions[0];
    if (!filterCondition) {
      return '';
    }

    switch (filterCondition.fieldPath) {
      case 'bookingDate': {
        // Check if it matches one of quickfilters options
        const dateOption = dateOptions.find((option) => _.isEqual(option.value, filter));
        if (dateOption) {
          return dateOption.label;
        }

        const fromCondition = filterConditions.find(
          (condition) => condition?.operator === FilterOperator.GreaterThanOrEqual,
        );
        const toCondition = filterConditions.find(
          (condition) => condition?.operator === FilterOperator.LessThanOrEqual,
        );

        if (fromCondition && toCondition) {
          return (
            format(parseISO(fromCondition.values?.[0] ?? ''), 'dd.MM.yyyy') +
            ' - ' +
            format(parseISO(toCondition.values?.[0] ?? ''), 'dd.MM.yyyy')
          );
        }

        if (fromCondition && !toCondition) {
          return `${t('common:graterThan')} ${format(parseISO(fromCondition.values?.[0] ?? ''), 'dd.MM.yyyy')}`;
        }
        if (toCondition && !fromCondition) {
          return `${t('common:lessThan')} ${format(parseISO(toCondition.values?.[0] ?? ''), 'dd.MM.yyyy')}`;
        }
        return '';
      }

      case 'baseAmount.amount': {
        // Check if it matches one of quickfilters options
        const amountOption = amountOptions.find((option) => _.isEqual(option.value, filter));
        if (amountOption) {
          return amountOption.label;
        }

        const amountFilters = filter.arguments.map((argument) => argument.filter);

        //Pick only conditions with positive value
        const amountConditions = amountFilters.map(
          (amountFilter) =>
            amountFilter?.arguments?.find((argument) => (argument?.filterCondition?.values?.[0] ?? '-1') > '0')
              ?.filterCondition,
        );

        const fromCondition = amountConditions.find(
          (condition) => condition?.operator === FilterOperator.GreaterThanOrEqual,
        );
        const toCondition = amountConditions.find(
          (condition) => condition?.operator === FilterOperator.LessThanOrEqual,
        );

        if (fromCondition && toCondition) {
          return `${fromCondition.values?.[0]} - ${toCondition.values?.[0]} €`;
        }

        if (fromCondition && !toCondition) {
          return `${t('common:graterThan')} ${fromCondition.values?.[0]} €`;
        }
        if (toCondition && !fromCondition) {
          return `${t('common:lessThan')} ${toCondition.values?.[0]} €`;
        }
        return '';
      }

      case 'account.id': {
        const accountOption = accountOptions.find((option) => _.isEqual(option.value, filter));
        if (accountOption) {
          return accountOption.label;
        } else return filterCondition.operator + filterCondition.values?.join(', ');
      }

      case 'category.categoryGroup.name': {
        return filterCondition.values?.join(', ');
      }

      case 'tags.name': {
        return filterCondition.values?.join(', ');
      }

      case 'counterparty': {
        // filter for transactions without counterparty (without counterparty.id)
        const counterpartyOption = counterpartyOptions.find((option) => _.isEqual(option.value, filter));
        if (counterpartyOption) {
          return counterpartyOption.label;
        }
        return '';
      }

      case 'counterparty.id': {
        return filterConditions
          ?.map((condition) => {
            const counterparty = counterpartyMap[condition?.values?.[0] ?? ''];
            return getNameForTransactionCounterparty(counterparty, undefined, t);
          })
          .join(', ');
      }

      case 'transactionTypeCode': {
        // Check if it matches one of quickfilters options
        const typeOption = typeOptions.find((option) => _.isEqual(option.value, filter));
        if (typeOption) {
          return typeOption.label;
        } else {
          return filterCondition.values
            ?.map((transactionTypeCode) => TransactionTypeTranslations(t)[transactionTypeCode as TransactionTypeCode])
            .join(', ');
        }
      }

      case 'streamGroup.period.periodCount': {
        const periodicityOption = periodicityFilters.find((option) => _.isEqual(option.value, filter));
        if (periodicityOption) {
          return periodicityOption.label;
        }
        return '';
      }

      case 'excludeFromAnalytics': {
        const analyticsOption = analyticsOptions.find((option) => _.isEqual(option.value, filter));
        if (analyticsOption) {
          return analyticsOption.label;
        }
        return '';
      }

      default:
        return filterCondition.operator + filterCondition.values?.join(', ');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography> {getLabel()}</Typography>
      <Box onClick={onClick} sx={styles.button}>
        <CloseIcon />
      </Box>
    </Box>
  );
};
