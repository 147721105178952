import { addMonths, endOfMonth, formatISO, isThisMonth, startOfMonth } from 'date-fns';
import { AggregateBalanceFilter, FilterOperator } from 'generated/graphql';

export const getPeriodFilter = (referenceDate: Date, accountId?: string): AggregateBalanceFilter => {
  const referenceDatesConditions = [
    {
      operator: FilterOperator.GreaterThanOrEqual,
      value: formatISO(startOfMonth(referenceDate), { representation: 'date' }),
    },
    {
      operator: FilterOperator.LessThanOrEqual,
      value: formatISO(endOfMonth(referenceDate), { representation: 'date' }),
    },
  ];

  return {
    accountIds: !!accountId
      ? [
          {
            operator: FilterOperator.Equal,
            value: accountId,
          },
        ]
      : [],
    referenceDates: referenceDatesConditions,
  };
};

export const getDayFilter = (referenceDate: Date, accountId?: string): AggregateBalanceFilter => ({
  accountIds: !!accountId
    ? [
        {
          operator: FilterOperator.Equal,
          value: accountId,
        },
      ]
    : [],
  referenceDates: [
    {
      operator: FilterOperator.Equal,
      value: formatISO(referenceDate, { representation: 'date' }),
    },
  ],
});

export const getAdjacentEndDate = (asOfDate: Date, direction: -1 | 1): Date => {
  const newDate = addMonths(asOfDate, direction);

  if (isThisMonth(newDate)) {
    return new Date();
  } else {
    return endOfMonth(newDate);
  }
};

export const getAmountLabel = (range: number): number => {
  const power = Math.round(range).toString().length - 1;
  const base = range / Math.pow(10, power);

  if (base < 2.5) return 0.25 * Math.pow(10, power);
  if (base < 5) return 0.5 * Math.pow(10, power);
  return Math.pow(10, power);
};
