import { Box, Button, Stack, Typography } from '@mui/material';
import AccountConnectionSuccessImage from 'assets/images/account-connection-success.svg';
import { Image } from 'common/components/Image/Image';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

interface AccountConnectionSuccessProps {
  ids: string[];
}

const AccountConnectionSuccessContent: React.FC<AccountConnectionSuccessDialogProps> = ({ ids, onResolve }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack alignItems="center" spacing={1}>
        <Typography color="textSecondary" variant="bodyM">
          3/3
        </Typography>
        <Image src={AccountConnectionSuccessImage} />
        <Typography variant="headlineXL">
          {t('homepage.accounts.accountConnectionSuccessDialog.title', { count: ids.length })}
        </Typography>
      </Stack>

      <Stack alignItems="center" mt={2}>
        <Typography textAlign="center" variant="bodyL">
          {t('homepage.accounts.accountConnectionSuccessDialog.subtitle')}
        </Typography>
      </Stack>

      <Box mt={8}>
        <Button color="primary" fullWidth onClick={() => onResolve()} size="large">
          <Typography color="white" variant="bodyL">
            {t('homepage.accounts.accountConnectionSuccessDialog.done')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export interface AccountConnectionSuccessDialogProps
  extends AccountConnectionSuccessProps,
    DialogComponentProps<void> {}

export const AccountConnectionSuccessDialog: React.FC<AccountConnectionSuccessDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <AccountConnectionSuccessContent {...props} />
    </ResponsiveSideDialog>
  );
};
