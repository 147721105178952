import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'loading' | 'container' | 'menuItem' | 'menuItemMedium' | 'selectField' | 'checkmark',
  SxProps<Theme>
> = {
  checkmark: {
    color: 'transparent',
  },
  container: {
    width: '100%',
  },
  loading: {
    '& .MuiCircularProgress-root': {
      color: (theme) => theme.palette.gray?.[500],
    },
    margin: '0 auto',
    width: 'fit-content',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemMedium: {
    fontSize: '13px',
    padding: (theme) => theme.spacing(1, 1.5),
  },
  selectField: {
    fontSize: '13px',
    padding: (theme) => theme.spacing(1, 1.5),
    textAlign: 'left',
  },
};
