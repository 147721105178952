import { SxProps, Theme } from '@mui/material';

export const styles: Record<'submit' | 'title', SxProps<Theme>> = {
  submit: {
    position: { sm: 'absolute' },
    right: (theme) => ({
      sm: theme.spacing(1),
      xxs: theme.spacing(2),
    }),
  },
  title: {
    marginX: 'auto',
    padding: (theme) => theme.spacing(1),
  },
};
