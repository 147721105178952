import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormRadioSelect } from 'common/form/components/FormRadioSelect';
import { transformNaNtoNull } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { TransactionGroupPeriodTypeCode } from 'generated/graphql';
import { t } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface Period {
  periodCount?: number;
  periodTypeCode?: TransactionGroupPeriodTypeCode;
}

interface FormValues {
  period: Period;
}

export interface SetPeriodInputDialogProps extends DialogComponentProps<Period> {
  period: Period | undefined;
}

export const SetPeriodInputDialogContent: React.FC<SetPeriodInputDialogProps> = ({ onResolve, period }) => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    period: yup
      .object()
      .shape({
        periodCount: yup
          .number()
          .transform(transformNaNtoNull)
          .nullable(true)
          .integer(t('form.validator.integer'))
          .moreThan(0, t('form.validator.moreThan', { value: 0 }))
          .required(t('form.validator.fieldIsRequired')),
        periodTypeCode: yup.mixed<Period>().required(t('form.validator.fieldIsRequired')),
      })
      .required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      period: period,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve(data.period);
    return;
  });

  return (
    <Box>
      <FormProvider {...formMethods}>
        <Box component="form" mt={2} onSubmit={handleSubmit}>
          <Box>
            <FormRadioSelect
              name="period"
              options={[
                {
                  label: t('upcomingPaymentDetail.periodType.weekly'),
                  value: { periodCount: 1, periodTypeCode: TransactionGroupPeriodTypeCode.Week },
                },
                {
                  label: t('upcomingPaymentDetail.periodType.monthly'),
                  value: { periodCount: 1, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
                },
                {
                  label: t('upcomingPaymentDetail.periodType.quarterly'),
                  value: { periodCount: 3, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
                },
                {
                  label: t('upcomingPaymentDetail.periodType.semiannually'),
                  value: { periodCount: 6, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
                },
                {
                  label: t('upcomingPaymentDetail.periodType.annually'),
                  value: { periodCount: 12, periodTypeCode: TransactionGroupPeriodTypeCode.Month },
                },
              ]}
            />
          </Box>
          <Box mt={8} width="100%">
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('upcomingPaymentDetail.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export const SetPeriodInputDialog: React.FC<SetPeriodInputDialogProps> = (props) => (
  <PromptDialogOnSide
    onClose={() => props.onResolve()}
    open={props.isOpen}
    subtitle={t('upcomingPaymentDetail.periodDescription')}
    title={t('upcomingPaymentDetail.period')}
  >
    <SetPeriodInputDialogContent {...props} />
  </PromptDialogOnSide>
);
