import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormDatePicker } from 'common/form/components/FormDatePicker/FormDatePicker';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface FormValues {
  date?: Date | null;
}

export interface EditEventDateDialogProps extends DialogComponentProps<Date | undefined | null> {
  date?: Date | null;
  title: string;
  subtitle: string;
}

export const EditEventDateDialog: React.FC<EditEventDateDialogProps> = ({
  date,
  isOpen,
  onReject,
  onResolve,
  subtitle,
  title,
}) => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    date: yup.date().typeError(t('form.validator.invalidDate')).required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      date,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onResolve(data.date);
  });

  return (
    <PromptDialogOnSide onClose={() => onReject()} open={isOpen} subtitle={subtitle} title={title}>
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormDatePicker name="date" />
          </Box>

          <Stack mt={3} spacing={1}>
            <Button color="primary" sx={styles.button} type="submit">
              <Typography color="white" variant="bodyL">
                {t('products:financialCalendar.editDateDialog.save')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onReject()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
