import { AddDocumentButton } from 'admin/documents/AddDocumentButton';
import { FileLinks } from 'admin/documents/FileLinks';
import { FinancialProductType, ProductBase } from 'generated/graphql';
import {
  ArrayField,
  BooleanField,
  BulkDeleteButton,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';

interface ProductDatagridProps {
  productType: FinancialProductType;
  showClient?: boolean;
}

export const ProductDatagrid: React.FC<ProductDatagridProps> = ({ productType, showClient }) => {
  return (
    <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />} rowClick="show">
      <TextField label="ID" source="id" />
      {showClient && (
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
      )}
      <FunctionField
        label="Inštitúcia"
        render={(record: ProductBase) => record.institution?.name ?? record.institutionName}
      />
      <TextField label="Názov produktu" source="name" />
      <TextField label="Status" source="status" />
      <DateField label="Zmena stavu" locales="sk" source="statusChangeDate" />
      <BooleanField label="Starostlivosť" source="enableFinancialAdvisorCare" />
      <ArrayField label="Dokumenty" source="documents">
        <SingleFieldList linkType={false}>
          <ArrayField source="files">
            <FileLinks />
          </ArrayField>
        </SingleFieldList>
      </ArrayField>
      <AddDocumentButton productType={productType} />
      <EditButton />
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
  );
};
