import CashflowGuardian from 'assets/images/carousel/accounts/cashflow-guardian.png';
import ConnectAccount from 'assets/images/carousel/accounts/connect-account.png';
import SpendingReport from 'assets/images/carousel/accounts/spending-report.png';
import Contact from 'assets/images/carousel/contact.png';
import { useContactDialog } from 'common/hooks/useContactDialog';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useAddAccount } from 'components/accounts/AddAccountDialog/useAddAccount';
import {
  CashflowGuardianDialog,
  CashflowGuardianDialogProps,
} from 'components/cashflowGuardian/CashflowGuardianDialog/CashflowGuardianDialog';
import {
  CashflowGuardianProductTourDialog,
  CashflowGuardianProductTourDialogProps,
} from 'components/cashflowGuardian/CashflowGuardianProductTourDialog/CashflowGuardianProductTourDialog';
import { HomepageCarousel } from 'components/HomePage/HomepageCarousel/HomepageCarousel';
import { SpendingReportDialog, SpendingReportDialogProps } from 'components/spendingReport/SpendingReportDialog';
import { useBudgetQuery, useConnectedAccountsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const HomepageAccountsCarousel: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data: dataConnectedAccounts } = useConnectedAccountsQuery({
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
  });
  const hasConnectedAccounts = !!dataConnectedAccounts?.accounts?.items.length;

  const addAccount = useAddAccount();

  const handleConnectAccount = () => {
    // workaround: as this banner is also shown to unregistered client, we don't want to use
    // addAccount directly for them but redirect to login instead
    isAuthenticated ? addAccount() : navigate('/login?select-bank=open');
  };

  const { data } = useBudgetQuery({
    skip: !isAuthenticated,
  });

  const cashflowGuardianDialog = useDialog<CashflowGuardianDialogProps, void>(CashflowGuardianDialog, {
    instanceId: 'cashflow-guardian',
    navigable: true,
  });

  const setBudgetIntroDialog = useDialog<CashflowGuardianProductTourDialogProps, void>(
    CashflowGuardianProductTourDialog,
    {
      instanceId: 'cashflow-guardian-product-tour',
      navigable: true,
    },
  );

  const handleCashflowGuardian = () => {
    if (!hasConnectedAccounts) {
      handleConnectAccount();
      return;
    }
    if (!data?.budget) {
      setBudgetIntroDialog.create({});
      return;
    }
    cashflowGuardianDialog.create({});
  };

  const spendingReportDialog = useDialog<SpendingReportDialogProps, void>(SpendingReportDialog, {
    instanceId: 'spending-report',
    navigable: true,
  });

  const handleSpendingReport = () => {
    if (!hasConnectedAccounts) {
      handleConnectAccount();
      return;
    }
    spendingReportDialog.create({});
  };

  const openContactDialog = useContactDialog({});

  const handleContact = () => {
    openContactDialog();
  };

  const views = [
    {
      buttonLabel: t('homepage.accounts.carousel.connectAccounts'),
      description: t('homepage.accounts.carousel.1.description'),
      image: ConnectAccount,
      name: t('homepage.accounts.carousel.1.name'),
      onClick: handleConnectAccount,
      title: t('homepage.accounts.carousel.1.title'),
    },
    {
      buttonLabel: hasConnectedAccounts
        ? t('homepage.accounts.carousel.2.buttonLabel')
        : t('homepage.accounts.carousel.connectAccounts'),
      description: t('homepage.accounts.carousel.2.description'),
      image: CashflowGuardian,
      name: t('homepage.accounts.carousel.2.name'),
      onClick: handleCashflowGuardian,
      title: t('homepage.accounts.carousel.2.title'),
    },
    {
      buttonLabel: hasConnectedAccounts
        ? t('homepage.accounts.carousel.3.buttonLabel')
        : t('homepage.accounts.carousel.connectAccounts'),
      description: t('homepage.accounts.carousel.3.description'),
      image: SpendingReport,
      name: t('homepage.accounts.carousel.3.name'),
      onClick: handleSpendingReport,
      title: t('homepage.accounts.carousel.3.title'),
    },
    {
      buttonLabel: t('homepage.accounts.carousel.4.buttonLabel'),
      image: Contact,
      name: t('homepage.accounts.carousel.4.name'),
      onClick: handleContact,
      title: t('homepage.accounts.carousel.4.title'),
    },
  ];
  return <HomepageCarousel imageBg="linear-gradient(45deg, #96CEFD 0%, #0040DD 100%)" list={views} />;
};
