import { SxProps, Theme } from '@mui/material';

export const styles: Record<'option' | 'selected' | 'content', SxProps<Theme>> = {
  content: {
    flexDirection: { sm: 'row', xxs: 'column' },
    gap: { sm: 0, xxs: 1 },
  },
  option: {
    '& + &': {
      ':before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        display: { sm: 'flex', xxs: 'none' },
        height: '20px',
        left: 0,
        position: 'absolute',
        width: '1px',
      },
    },
    '&:hover': {
      backgroundColor: (theme) => theme.palette.gray[300],
      cursor: 'pointer',
    },
    backgroundColor: 'transparent',
    borderRadius: '6px',
    letterSpacing: '-0.43px',
    padding: (theme) => theme.spacing(1, 2),
    position: 'relative',
  },
  selected: {
    backgroundColor: (theme) => theme.palette.gray[200],
  },
};
