import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { ProductBase } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  products?: ProductBase[];
}

export const ProductStatsCard: React.FC<Props> = ({ isLoading, products }) => {
  const productsLastWeek = products?.filter((product) => {
    const creationTimestamp = product.creationTimestamp;
    const lastWeekTimestamp = new Date();
    lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);
    return creationTimestamp && creationTimestamp >= lastWeekTimestamp;
  });
  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">{`${products?.length} produktov`}</Typography>
        <Typography color="text.secondary">{`+${productsLastWeek?.length} za posledných 7 dní`}</Typography>
      </CardContent>
    </Card>
  );
};
