import { LifeInsuranceCalculatorCalculationList } from 'admin/calculators/LifeInsuranceCalculatorCalculation/LifeInsuranceCalculatorCalculationList';
import { LifeInsuranceCalculatorCalculationShow } from 'admin/calculators/LifeInsuranceCalculatorCalculation/LifeInsuranceCalculatorCalculationShow';
import { MortgageCalculatorCalculationList } from 'admin/calculators/MortgageCalculatorCalculation/MortgageCalculatorCalculationList';
import { MortgageCalculatorCalculationShow } from 'admin/calculators/MortgageCalculatorCalculation/MortgageCalculatorCalculationShow';
import { ClientList } from 'admin/clients/ClientList';
import { ClientShow } from 'admin/clients/ClientShow';
import { Dashboard } from 'admin/dashboard/Dashboard';
import { dataProvider } from 'admin/dataProvider';
import { FinancialAdvisorTaskCreate } from 'admin/financialAdvisorTask/FinancialAdvisorTaskCreate';
import { FinancialAdvisorTaskList } from 'admin/financialAdvisorTask/FinancialAdvisorTaskList';
import { FinancialAdvisorTaskShow } from 'admin/financialAdvisorTask/FinancialAdvisorTaskShow';
import { CustomLayout } from 'admin/layout/CustomLayout';
import { LifeInsuranceAuditProcessList } from 'admin/processes/LifeInsuranceAuditProcess/LifeInsuranceAuditProcessList';
import { LifeInsurancePurchaseProcessList } from 'admin/processes/LifeInsurancePurchaseProcess/LifeInsurancePurchaseProcessList';
import { MortgagePurchaseProcessList } from 'admin/processes/MortgagePurchaseProcess/MortgagePurchaseProcessList';
import { MortgageRefinanceProcessList } from 'admin/processes/MortgageRefinanceProcess/MortgageRefinanceProcessList';
import { BuildingSavingsProductCreate } from 'admin/products/BuildingSavingsProduct/BuildingSavingsProductCreate';
import { BuildingSavingsProductEdit } from 'admin/products/BuildingSavingsProduct/BuildingSavingsProductEdit';
import { BuildingSavingsProductList } from 'admin/products/BuildingSavingsProduct/BuildingSavingsProductList';
import { BuildingSavingsProductShow } from 'admin/products/BuildingSavingsProduct/BuildingSavingsProductShow';
import { CollisionInsuranceProductCreate } from 'admin/products/CollisionInsuranceProduct/CollisionInsuranceProductCreate';
import { CollisionInsuranceProductEdit } from 'admin/products/CollisionInsuranceProduct/CollisionInsuranceProductEdit';
import { CollisionInsuranceProductList } from 'admin/products/CollisionInsuranceProduct/CollisionInsuranceProductList';
import { CollisionInsuranceProductShow } from 'admin/products/CollisionInsuranceProduct/CollisionInsuranceProductShow';
import { CreditCardProductCreate } from 'admin/products/CreditCardProduct/CreditCardProductCreate';
import { CreditCardProductEdit } from 'admin/products/CreditCardProduct/CreditCardProductEdit';
import { CreditCardProductList } from 'admin/products/CreditCardProduct/CreditCardProductList';
import { CreditCardProductShow } from 'admin/products/CreditCardProduct/CreditCardProductShow';
import { GenericInsuranceProductCreate } from 'admin/products/GenericInsuranceProduct/GenericInsuranceProductCreate';
import { GenericInsuranceProductEdit } from 'admin/products/GenericInsuranceProduct/GenericInsuranceProductEdit';
import { GenericInsuranceProductList } from 'admin/products/GenericInsuranceProduct/GenericInsuranceProductList';
import { GenericInsuranceProductShow } from 'admin/products/GenericInsuranceProduct/GenericInsuranceProductShow';
import { GenericProductCreate } from 'admin/products/GenericProduct/GenericProductCreate';
import { GenericProductEdit } from 'admin/products/GenericProduct/GenericProductEdit';
import { GenericProductList } from 'admin/products/GenericProduct/GenericProductList';
import { GenericProductShow } from 'admin/products/GenericProduct/GenericProductShow';
import { InvestmentProductCreate } from 'admin/products/InvestmentProduct/InvestmentProductCreate';
import { InvestmentProductEdit } from 'admin/products/InvestmentProduct/InvestmentProductEdit';
import { InvestmentProductList } from 'admin/products/InvestmentProduct/InvestmentProductList';
import { InvestmentProductShow } from 'admin/products/InvestmentProduct/InvestmentProductShow';
import { LifeInsuranceProductCreate } from 'admin/products/LifeInsuranceProduct/LifeInsuranceProductCreate';
import { LifeInsuranceProductEdit } from 'admin/products/LifeInsuranceProduct/LifeInsuranceProductEdit';
import { LifeInsuranceProductList } from 'admin/products/LifeInsuranceProduct/LifeInsuranceProductList';
import { LifeInsuranceProductShow } from 'admin/products/LifeInsuranceProduct/LifeInsuranceProductShow';
import { LoanProductCreate } from 'admin/products/LoanProduct/LoanProductCreate';
import { LoanProductEdit } from 'admin/products/LoanProduct/LoanProductEdit';
import { LoanProductList } from 'admin/products/LoanProduct/LoanProductList';
import { LoanProductShow } from 'admin/products/LoanProduct/LoanProductShow';
import { MortgageProductCreate } from 'admin/products/MortgageProduct/MortgageProductCreate';
import { MortgageProductEdit } from 'admin/products/MortgageProduct/MortgageProductEdit';
import { MortgageProductList } from 'admin/products/MortgageProduct/MortgageProductList';
import { MortgageProductShow } from 'admin/products/MortgageProduct/MortgageProductShow';
import { MotorLiabilityInsuranceProductCreate } from 'admin/products/MotorLiabilityInsuranceProduct/MotorLiabilityInsuranceProductCreate';
import { MotorLiabilityInsuranceProductEdit } from 'admin/products/MotorLiabilityInsuranceProduct/MotorLiabilityInsuranceProductEdit';
import { MotorLiabilityInsuranceProductList } from 'admin/products/MotorLiabilityInsuranceProduct/MotorLiabilityInsuranceProductList';
import { MotorLiabilityInsuranceProductShow } from 'admin/products/MotorLiabilityInsuranceProduct/MotorLiabilityInsuranceProductShow';
import { PropertyInsuranceProductCreate } from 'admin/products/PropertyInsuranceProduct/PropertyInsuranceProductCreate';
import { PropertyInsuranceProductEdit } from 'admin/products/PropertyInsuranceProduct/PropertyInsuranceProductEdit';
import { PropertyInsuranceProductList } from 'admin/products/PropertyInsuranceProduct/PropertyInsuranceProductList';
import { PropertyInsuranceProductShow } from 'admin/products/PropertyInsuranceProduct/PropertyInsuranceProductShow';
import { RetirementPensionSavingsProductCreate } from 'admin/products/RetirementPensionSavingsProduct/RetirementPensionSavingsProductCreate';
import { RetirementPensionSavingsProductEdit } from 'admin/products/RetirementPensionSavingsProduct/RetirementPensionSavingsProductEdit';
import { RetirementPensionSavingsProductList } from 'admin/products/RetirementPensionSavingsProduct/RetirementPensionSavingsProductList';
import { RetirementPensionSavingsProductShow } from 'admin/products/RetirementPensionSavingsProduct/RetirementPensionSavingsProductShow';
import { SavingsProductCreate } from 'admin/products/SavingsProduct/SavingsProductCreate';
import { SavingsProductEdit } from 'admin/products/SavingsProduct/SavingsProductEdit';
import { SavingsProductList } from 'admin/products/SavingsProduct/SavingsProductList';
import { SavingsProductShow } from 'admin/products/SavingsProduct/SavingsProductShow';
import { SupplementaryPensionSavingsProductCreate } from 'admin/products/SupplementaryPensionSavingsProduct/SupplementaryPensionSavingsProductCreate';
import { SupplementaryPensionSavingsProductEdit } from 'admin/products/SupplementaryPensionSavingsProduct/SupplementaryPensionSavingsProductEdit';
import { SupplementaryPensionSavingsProductList } from 'admin/products/SupplementaryPensionSavingsProduct/SupplementaryPensionSavingsProductList';
import { SupplementaryPensionSavingsProductShow } from 'admin/products/SupplementaryPensionSavingsProduct/SupplementaryPensionSavingsProductShow';
import { Loading } from 'common/components/Loading/Loading';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { Role, useCurrentUserQuery } from 'generated/graphql';
import { useContext } from 'react';
import { Admin, Resource } from 'react-admin';

import { NotFoundPage } from './NotFoundPage';

export const AdminPage: React.FC = () => {
  const { isAuthenticated, isLoading: loadingAuth } = useContext(AuthContext);
  const { data, loading: loadingUser } = useCurrentUserQuery({ skip: !isAuthenticated });

  if (loadingAuth || loadingUser || !dataProvider) return <Loading fullScreen messages={[]} />;

  // TODO: replace this workarounds with proper auth context (which will includes roles)
  if (!isAuthenticated || !data?.user?.roles?.includes(Role.RoleFinancialAdvisor)) return <NotFoundPage />;

  return (
    <Admin basename="/admin" dashboard={Dashboard} dataProvider={dataProvider} layout={CustomLayout}>
      <Resource list={ClientList} name="Client" options={{ label: 'Klienti' }} show={ClientShow} />
      <Resource
        create={FinancialAdvisorTaskCreate}
        list={FinancialAdvisorTaskList}
        name="FinancialAdvisorTask"
        options={{ label: 'Úlohy' }}
        show={FinancialAdvisorTaskShow}
      />
      <Resource
        create={BuildingSavingsProductCreate}
        edit={BuildingSavingsProductEdit}
        list={BuildingSavingsProductList}
        name="BuildingSavingsProduct"
        options={{ label: 'Stavebné sporenie' }}
        show={BuildingSavingsProductShow}
      />
      <Resource
        create={CollisionInsuranceProductCreate}
        edit={CollisionInsuranceProductEdit}
        list={CollisionInsuranceProductList}
        name="CollisionInsuranceProduct"
        options={{ label: 'Havarijné poistenie' }}
        show={CollisionInsuranceProductShow}
      />
      <Resource
        create={CreditCardProductCreate}
        edit={CreditCardProductEdit}
        list={CreditCardProductList}
        name="CreditCardProduct"
        options={{ label: 'Kreditná karta' }}
        show={CreditCardProductShow}
      />
      <Resource
        create={GenericInsuranceProductCreate}
        edit={GenericInsuranceProductEdit}
        list={GenericInsuranceProductList}
        name="GenericInsuranceProduct"
        options={{ label: 'Poistenie' }}
        show={GenericInsuranceProductShow}
      />
      <Resource
        create={GenericProductCreate}
        edit={GenericProductEdit}
        list={GenericProductList}
        name="GenericProduct"
        options={{ label: 'Iný finančný produkt' }}
        show={GenericProductShow}
      />
      <Resource
        create={InvestmentProductCreate}
        edit={InvestmentProductEdit}
        list={InvestmentProductList}
        name="InvestmentProduct"
        options={{ label: 'Investičný produkt' }}
        show={InvestmentProductShow}
      />
      <Resource
        create={LifeInsuranceProductCreate}
        edit={LifeInsuranceProductEdit}
        list={LifeInsuranceProductList}
        name="LifeInsuranceProduct"
        options={{ label: 'Životné poistenie' }}
        show={LifeInsuranceProductShow}
      />
      <Resource
        create={LoanProductCreate}
        edit={LoanProductEdit}
        list={LoanProductList}
        name="LoanProduct"
        options={{ label: 'Úver' }}
        show={LoanProductShow}
      />
      <Resource
        create={MortgageProductCreate}
        edit={MortgageProductEdit}
        list={MortgageProductList}
        name="MortgageProduct"
        options={{ label: 'Hypotéka' }}
        show={MortgageProductShow}
      />
      <Resource
        create={MotorLiabilityInsuranceProductCreate}
        edit={MotorLiabilityInsuranceProductEdit}
        list={MotorLiabilityInsuranceProductList}
        name="MotorLiabilityInsuranceProduct"
        options={{ label: 'PZP' }}
        show={MotorLiabilityInsuranceProductShow}
      />
      <Resource
        create={PropertyInsuranceProductCreate}
        edit={PropertyInsuranceProductEdit}
        list={PropertyInsuranceProductList}
        name="PropertyInsuranceProduct"
        options={{ label: 'Poistenie majetku' }}
        show={PropertyInsuranceProductShow}
      />
      <Resource
        create={RetirementPensionSavingsProductCreate}
        edit={RetirementPensionSavingsProductEdit}
        list={RetirementPensionSavingsProductList}
        name="RetirementPensionSavingsProduct"
        options={{ label: 'Starobné dôchodkové sporenie' }}
        show={RetirementPensionSavingsProductShow}
      />
      <Resource
        create={SavingsProductCreate}
        edit={SavingsProductEdit}
        list={SavingsProductList}
        name="SavingsProduct"
        options={{ label: 'Sporenie' }}
        show={SavingsProductShow}
      />
      <Resource
        create={SupplementaryPensionSavingsProductCreate}
        edit={SupplementaryPensionSavingsProductEdit}
        list={SupplementaryPensionSavingsProductList}
        name="SupplementaryPensionSavingsProduct"
        options={{ label: 'Doplnkové dôchodkové sporenie' }}
        show={SupplementaryPensionSavingsProductShow}
      />
      <Resource
        list={MortgagePurchaseProcessList}
        name="MortgagePurchaseProcess"
        options={{ label: 'Nová hypotéka' }}
      />
      <Resource
        list={MortgageRefinanceProcessList}
        name="MortgageRefinanceProcess"
        options={{ label: 'Refinancovanie hypotéky' }}
      />
      <Resource
        list={LifeInsurancePurchaseProcessList}
        name="LifeInsurancePurchaseProcess"
        options={{ label: 'Nové životné poistenie' }}
      />
      <Resource
        list={LifeInsuranceAuditProcessList}
        name="LifeInsuranceAuditProcess"
        options={{ label: 'Audit životného poistenia' }}
      />
      <Resource
        list={MortgageCalculatorCalculationList}
        name="MortgageCalculatorCalculation"
        options={{ label: 'Kalkulačka maximálnej hypotéky' }}
        show={MortgageCalculatorCalculationShow}
      />
      <Resource
        list={LifeInsuranceCalculatorCalculationList}
        name="LifeInsuranceCalculatorCalculation"
        options={{ label: 'Kalkulačka potreby životného poistenia' }}
        show={LifeInsuranceCalculatorCalculationShow}
      />
    </Admin>
  );
};
