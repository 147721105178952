import { makeVar, ReactiveVar } from '@apollo/client';
import {
  MortgageCollateralType,
  MortgageIncomeType,
  MortgageRefinanceInquiryFragment,
  MortgageRefinanceInquiryInput,
} from 'generated/graphql';

import { ApplicantType, MortgageRefinanceState } from './refinanceTypes';

const initialValue: MortgageRefinanceState = {};
export const mortgageRefinanceState: ReactiveVar<MortgageRefinanceState> =
  makeVar<MortgageRefinanceState>(initialValue);

export const mapRefinanceStateToInput = (state: MortgageRefinanceState): MortgageRefinanceInquiryInput => {
  const applicants = (state.applicants?.data?.filter((applicant) => !!applicant) ?? []) as ApplicantType[];

  return {
    applicants: applicants.map((applicant) => ({
      dateOfBirth: applicant.dateOfBirth ?? new Date(),
      incomeCommencementDate:
        applicant.incomeType === MortgageIncomeType.Ltd
          ? applicant.incomeCommencementDateLtd
          : applicant.incomeCommencementDate,
      incomeType: applicant.incomeType ?? MortgageIncomeType.Salary,
      name: applicant.name ?? '',
    })),
    currentSetup: state.setup
      ? {
          balance: state.setup.balance
            ? {
                amount: state.setup.balance,
                currency: 'EUR',
              }
            : null,
          collaterals: state.property?.collaterals?.map((collateral) => ({
            location: collateral.location,
            type: collateral.type ?? MortgageCollateralType.Flat,
            value: {
              amount: collateral.value ?? 0,
              currency: 'EUR',
            },
          })),
          fixationEndDate: state.setup.fixationEndDate,
          fixationLength: state.setup.fixationLength,
          installment: state.setup.installment
            ? {
                amount: state.setup.installment,
                currency: 'EUR',
              }
            : null,
          institutionId: state.setup.provider?.id ?? '',
          interestRate: state.setup.interestRate,
          isNewFixationProposed: state.setup.receivedNotice,
          newProposedFixation: state.setup.receivedNotice
            ? {
                newInstallment: state.setup.newInstallment
                  ? {
                      amount: state.setup.newInstallment ?? 0,
                      currency: 'EUR',
                    }
                  : undefined,
                newInterestRate: state.setup.newInterestRate ?? undefined,
              }
            : null,
        }
      : null,
    inquiryCurrentStepUrl: state?.meta?.currentStep || '',
    inquiryProgress: state?.meta?.progress || 0,
  };
};

export const mapRefinanceInquiryToState = (
  inquiry?: MortgageRefinanceInquiryFragment | null,
  updateTimestamp?: Date | null,
): MortgageRefinanceState => {
  return {
    applicants: {
      data:
        inquiry?.applicants?.map((applicant) => ({
          dateOfBirth: applicant.dateOfBirth,
          incomeCommencementDate: applicant.incomeCommencementDate,
          incomeCommencementDateLtd:
            applicant.incomeType === MortgageIncomeType.Ltd ? applicant.incomeCommencementDate : undefined,
          incomeType: applicant.incomeType,
          name: applicant.name,
        })) ?? [],
    },
    meta: {
      currentStep: inquiry?.inquiryCurrentStepUrl || undefined,
      progress: inquiry?.inquiryProgress || 0,
      updateTimestamp,
    },
    property: {
      collaterals: inquiry?.currentSetup?.collaterals?.map((collateral) => ({
        location: collateral.location ?? undefined,
        type: collateral.type,
        value: collateral.value?.amount,
      })),
    },
    setup: inquiry?.currentSetup
      ? {
          balance: inquiry.currentSetup.balance?.amount,
          fixationEndDate: inquiry.currentSetup.fixationEndDate,
          fixationLength: inquiry.currentSetup.fixationLength,
          installment: inquiry.currentSetup.installment?.amount,
          interestRate: inquiry.currentSetup.interestRate,
          newInstallment: inquiry.currentSetup.newProposedFixation?.newInstallment?.amount,
          newInterestRate: inquiry.currentSetup.newProposedFixation?.newInterestRate,
          provider: inquiry.currentSetup.institution,
          receivedNotice: inquiry.currentSetup.isNewFixationProposed,
        }
      : undefined,
  };
};

export const trimRefinanceState = (state: MortgageRefinanceState): MortgageRefinanceState => {
  const inquiryInput = mapRefinanceStateToInput(state);

  const inquiry: MortgageRefinanceInquiryFragment = {
    id: '',
    ...inquiryInput,
    applicants: inquiryInput.applicants?.map((applicant) => ({
      id: '',
      ...applicant,
    })),
    currentSetup: inquiryInput.currentSetup
      ? { ...inquiryInput.currentSetup, institution: state.setup?.provider }
      : undefined,
  };

  return mapRefinanceInquiryToState(inquiry, state.meta?.updateTimestamp);
};
