import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, DialogActions, DialogContent, Link, Typography } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogSubtitle } from 'common/components/StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormInput } from 'common/form/components/FormInput';
import { emailValidator } from 'common/form/validators';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useSuccessDialog } from 'common/hooks/useSuccessDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { SAVE_LOCAL_STATE_STORAGE_KEY } from 'components/flows/useSaveLocalFlowStates';
import { ProductFlows } from 'const';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { RegisterDialog, RegisterDialogProps } from './RegisterDialog';
import { styles } from './styles';

interface FormValues {
  email: string;
}

export interface ForgotPasswordDialogProps extends DialogComponentProps<void> {
  email?: string;
  saveLocalState?: ProductFlows[];
}

export const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  email,
  isOpen,
  onResolve,
  saveLocalState,
}) => {
  const { t } = useTranslation();
  const { forgotPassword } = useContext(AuthContext);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const openSuccessDialog = useSuccessDialog();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    email: emailValidator(),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: email ?? '',
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (values: FormValues) => {
    setIsLoading(true);
    setFormError('');

    const result = await forgotPassword(values.email);
    setIsLoading(false);

    if (!result) {
      // the flow is broken by email verification so we need to save the state
      if (!!saveLocalState) localStorage.setItem(SAVE_LOCAL_STATE_STORAGE_KEY, saveLocalState.join(','));

      openSuccessDialog({
        message: t('forgotPassword.success.message'),
        title: t('forgotPassword.success.title'),
      });
      onResolve();
      return;
    }
    if (result.formError) {
      setFormError(result.formError.message);
    }
  });

  const registerDialog = useDialog<RegisterDialogProps, void>(RegisterDialog, {
    instanceId: 'register-dialog',
  });

  const handleRegister = () => {
    registerDialog.create({});
    onResolve();
  };

  return (
    <StyledDialog fullWidth maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle>{t('forgotPassword.title')}</StyledDialogTitle>
      <StyledDialogSubtitle>
        <Trans i18nKey="forgotPassword.subtitle">
          <hr />
          <br />
          <br />
          <Link onClick={() => onResolve()} />
        </Trans>
      </StyledDialogSubtitle>

      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormInput
              autoComplete="email"
              autoFocus
              label={t('forgotPassword.email.label')}
              name="email"
              placeholder={t('forgotPassword.email.placeholder')}
              sx={styles.input}
              type="email"
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} size="large" sx={styles.button} type="submit">
              {t('forgotPassword.submit')}
            </LoadingButton>
          </DialogActions>
          {formError && <StyledDialogFormError content={formError} title={t('forgotPassword.error.title')} />}
        </Box>
        <Typography color="textSecondary" marginTop={4} textAlign="center" variant="body1">
          <Trans i18nKey="forgotPassword.toRegister">
            <Link onClick={handleRegister} />
          </Trans>
        </Typography>
      </FormProvider>
    </StyledDialog>
  );
};
