import { Box, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { ReactComponent as CalendarEventIcon } from 'assets/icons/CalendarEvent.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { ButtonRow } from 'components/products/ButtonRow/ButtonRow';
import { format } from 'date-fns';
import { useCalendarEventQuery, useUpdateCalendarEventMutation } from 'generated/graphql';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency } from 'rrule';

import { DeleteEventDialog, DeleteEventDialogProps } from '../DeleteEventDialog/DeleteEventDialog';
import { DetailRow } from '../DetailRow/DetailRow';
import { EditEventDateDialog, EditEventDateDialogProps } from '../EditEventDateDialog/EditEventDateDialog';
import {
  EditEventFrequencyDialog,
  EditEventFrequencyDialogProps,
  EventFrequency,
} from '../EditEventFrequencyDialog/EditEventFrequencyDialog';
import { EditEventNameDialog, EditEventNameDialogProps } from '../EditEventNameDialog/EditEventNameDialog';
import { createRecurrenceRule, formatFrequency, parseRecurrenceRule } from '../utils';
import { styles } from './EventDetailDialog.styles';

interface EventDetailProps {
  id: string;
}

const EventDetailDialogContent: React.FC<EventDetailDialogProps> = ({ id, onResolve }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { isAuthenticated } = useContext(AuthContext);
  const [recurrence, setRecurrence] = useState(false);

  const { data, loading: eventLoading } = useCalendarEventQuery({
    skip: !isAuthenticated,
    variables: {
      id,
    },
  });

  const name = data?.detail.name ?? '';
  const startDate = data?.detail.startDate ?? new Date();
  const recurrenceRule = data?.detail?.recurrenceRule;
  const { freq, interval, until } = parseRecurrenceRule(recurrenceRule);

  const [updateCalendarEvent, { loading: eventUpdateLoading }] = useUpdateCalendarEventMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const loading = eventLoading || eventUpdateLoading;

  const eventNameDialog = useDialog<EditEventNameDialogProps, string>(EditEventNameDialog, {
    instanceId: `set-event-name`,
  });

  const handleNameClick = async () => {
    const newName = await eventNameDialog.create({ name });
    await updateCalendarEvent({
      variables: {
        id,
        updatedEvent: {
          name: newName,
          recurrenceRule,
          startDate,
        },
      },
    });
  };

  const eventDateDialog = useDialog<EditEventDateDialogProps, Date>(EditEventDateDialog, {
    instanceId: `set-event-date`,
  });

  const handleDateClick = async () => {
    const newStartDate = await eventDateDialog.create({
      date: startDate,
      subtitle: t('products:financialCalendar.editDateDialog.text'),
      title: t('products:financialCalendar.editDateDialog.title'),
    });
    await updateCalendarEvent({
      variables: {
        id,
        updatedEvent: {
          name,
          recurrenceRule: recurrenceRule,
          startDate: newStartDate,
        },
      },
    });
  };

  const eventFrequencyDialog = useDialog<EditEventFrequencyDialogProps, EventFrequency>(EditEventFrequencyDialog, {
    instanceId: `set-event-frequency`,
  });

  const handleFrequencyClick = async () => {
    if (recurrence) {
      const newFrequency = await eventFrequencyDialog.create({
        frequency: { freq, interval },
      });
      const newRecurrenceRule = createRecurrenceRule(newFrequency.freq, newFrequency.interval, until);
      await updateCalendarEvent({
        variables: {
          id,
          updatedEvent: {
            name,
            recurrenceRule: newRecurrenceRule,
            startDate,
          },
        },
      });
    } else {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    }
  };

  const eventUntilDialog = useDialog<EditEventDateDialogProps, Date>(EditEventDateDialog, {
    instanceId: `set-event-until`,
  });

  const handleUntilClick = async () => {
    if (freq != null && interval != null) {
      const newUntil = await eventUntilDialog.create({
        date: until,
        subtitle: t('products:financialCalendar.editUntilDialog.text'),
        title: t('products:financialCalendar.editUntilDialog.title'),
      });
      const newRecurrenceRule = createRecurrenceRule(freq, interval, newUntil);
      await updateCalendarEvent({
        variables: {
          id,
          updatedEvent: {
            name,
            recurrenceRule: newRecurrenceRule,
            startDate,
          },
        },
      });
    } else {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    }
  };

  const deleteEventDialog = useDialog<DeleteEventDialogProps, boolean>(DeleteEventDialog, {
    instanceId: 'delete-event',
  });

  const handleDelete = async () => {
    const deleted = await deleteEventDialog.create({ id });
    if (deleted) {
      await onResolve();
    }
  };

  useEffect(() => {
    setRecurrence(!!recurrenceRule);
  }, [recurrenceRule]);

  const handleRecurrenceChange = async () => {
    const updatedRecurrence = !recurrence;
    setRecurrence(updatedRecurrence);

    if (!updatedRecurrence) {
      // remove existing recurrenceRule from event
      await updateCalendarEvent({
        variables: {
          id,
          updatedEvent: { name, startDate },
        },
      });
    }
  };

  return (
    <Stack>
      <Stack alignItems="center" spacing={1}>
        <CalendarEventIcon />
        {loading ? (
          <Skeleton height={41} variant="rectangular" />
        ) : (
          <Typography variant="headlineXL">{name}</Typography>
        )}
      </Stack>

      {loading ? (
        <Box mt={3}>
          <Skeleton height={112} variant="rectangular" />
        </Box>
      ) : (
        <Stack sx={styles.container}>
          <DetailRow<string>
            label={t('products:financialCalendar.eventDetail.name')}
            onClick={handleNameClick}
            value={name}
          />
          <DetailRow<Date>
            formatter={(value: Date) => format(value, 'd. M. yyyy')}
            label={t('products:financialCalendar.eventDetail.date')}
            onClick={handleDateClick}
            value={startDate}
          />
        </Stack>
      )}

      {loading ? (
        <Box mt={3}>
          <Skeleton height={166} variant="rectangular" />
        </Box>
      ) : (
        <Stack sx={styles.container}>
          <Box onClick={handleRecurrenceChange} sx={styles.switchRow}>
            <Typography color="textPrimary" variant="bodyL">
              {t('products:financialCalendar.eventDetail.recurrence')}
            </Typography>
            <Switch aria-label={t('products:financialCalendar.eventDetail.recurrence')} checked={recurrence} />
          </Box>

          <DetailRow<[Frequency, number]>
            disabled={!recurrence}
            formatter={(value: [Frequency, number]) => formatFrequency(value[0], value[1], t) ?? ''}
            label={t('products:financialCalendar.eventDetail.frequency.label')}
            onClick={handleFrequencyClick}
            value={freq != null && interval != null ? [freq, interval] : undefined}
          />

          <DetailRow<Date>
            disabled={!(recurrence && !!freq && !!interval)}
            formatter={(value: Date) => format(value, 'd. M. yyyy')}
            label={t('products:financialCalendar.eventDetail.until')}
            onClick={handleUntilClick}
            value={until}
          />
        </Stack>
      )}

      <Box mt={8}>
        {loading ? (
          <Skeleton height={54} variant="rectangular" />
        ) : (
          <ButtonRow
            color="red.dark"
            label={t('products:financialCalendar.deleteEvent.button')}
            onClick={handleDelete}
          />
        )}
      </Box>
    </Stack>
  );
};

export interface EventDetailDialogProps extends EventDetailProps, DialogComponentProps<void> {}

export const EventDetailDialog: React.FC<EventDetailDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <EventDetailDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
