import { DialogContext } from 'common/providers/DialogProvider/DialogContext';
import { InstanceComponent, InstanceProps } from 'common/providers/DialogProvider/types';
import { getContainer, hexGen } from 'common/providers/DialogProvider/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum DialogRejectReason {
  Back = 'back',
  Cancel = 'cancel',
}

export type DialogComponentProps<Resolve, Reject = Resolve> = InstanceProps<Resolve, Reject>;

export type Create<T, Resolve, Reject> = (
  props: Omit<T, keyof InstanceProps<Resolve, Reject>> & Partial<InstanceProps<Resolve, Reject>>,
) => Promise<Resolve>;

export const useDialog = <T extends DialogComponentProps<Resolve, Reject>, Resolve, Reject = Resolve>(
  entity: InstanceComponent<T, Resolve, Reject>,
  params: Partial<Omit<T, keyof InstanceProps<Resolve, Reject>>> & Partial<InstanceProps<Resolve, Reject>>,
): {
  create: Create<T, Resolve, Reject>;
  reject: () => void | Reject;
  resolve: () => void | Resolve;
} => {
  const [instanceId, setInstanceId] = useState<string>(params.instanceId ?? hexGen());

  const { hasInstance, rejectInstance, resolveInstance } = useContext(DialogContext);
  const create: Create<T, Resolve, Reject> = useCallback(
    (props) => {
      const container = getContainer();
      if (!container) {
        throw new Error('Missing dialog container');
      }
      const extendedParams = { ...params, instanceId };
      setInstanceId(props.instanceId ?? instanceId);
      return container.create<T, Resolve, Reject>(entity, extendedParams, { ...extendedParams, ...props });
    },
    [entity, params, instanceId],
  );

  const resolve = useCallback(() => resolveInstance<Resolve>(instanceId), [instanceId, resolveInstance]);
  const reject = useCallback(() => rejectInstance<Reject>(instanceId), [instanceId, rejectInstance]);

  const [searchParams] = useSearchParams();

  // open and close navigable dialogs based on search params
  const isInUrl = searchParams.has(instanceId);
  const dialogsInUrl = Array.from(searchParams.entries()).filter((entry) => entry[1] === 'open');
  const index = dialogsInUrl.findIndex((entry) => entry[0] === instanceId);
  const previousDialogId = index && index > 0 ? dialogsInUrl[index - 1][0] : undefined;

  useEffect(() => {
    if (!isInUrl && hasInstance(instanceId) && (params.navigable || params.tmpNavigable)) {
      resolve();
    }
    if (
      isInUrl &&
      !hasInstance(instanceId) &&
      params.navigable &&
      (!previousDialogId || hasInstance(previousDialogId))
    ) {
      const props = params as Omit<T, keyof InstanceProps<Resolve, Reject>> & Partial<InstanceProps<Resolve, Reject>>;
      create(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId, isInUrl, previousDialogId]);

  //Close all normal dialogs when navigable dialog opens/closes
  useEffect(() => {
    if (!params.navigable && !params.tmpNavigable && hasInstance(instanceId)) {
      resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogsInUrl.length]);

  //Update dialog when key changes
  useEffect(() => {
    if (hasInstance(instanceId) && !!params.key) {
      const props = params as Omit<T, keyof InstanceProps<Resolve, Reject>> & Partial<InstanceProps<Resolve, Reject>>;
      create(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.key]);

  return {
    create,
    reject,
    resolve,
  };
};
