import { Liability, Mapper } from 'components/flows/common/lifeInsurance/state/lifeInsuranceTypes';
import { LifeInsuranceLiabilityType } from 'const';
import { LifeInsuranceApplicantFragment, LifeInsuranceLiabilityInput } from 'generated/graphql';

export const mapLiabilities: Mapper<
  Liability[],
  LifeInsuranceLiabilityInput[],
  LifeInsuranceApplicantFragment['liabilities']
> = {
  parse: (liabilities) => {
    if (!liabilities) {
      return undefined;
    }

    if (liabilities.length === 0) {
      return [{ type: 'none' }];
    }

    return liabilities.map((liability) => {
      switch (liability.__typename) {
        case 'ApplicantAlimonyLiability':
          return {
            monthlyInstallment: liability.monthlyInstallment.amount,
            type: LifeInsuranceLiabilityType.Alimony,
          };
        case 'ApplicantConsumerLoanLiability':
          return {
            balance: liability.balance.amount,
            monthlyInstallment: liability.monthlyInstallment.amount,
            type: LifeInsuranceLiabilityType.ConsumerLoan,
          };
        case 'ApplicantLeaseLiability':
          return {
            balance: liability.balance.amount,
            monthlyInstallment: liability.monthlyInstallment.amount,
            type: LifeInsuranceLiabilityType.Lease,
          };
        case 'ApplicantMortgageLiability':
          return {
            balance: liability.balance.amount,
            monthlyInstallment: liability.monthlyInstallment.amount,
            type: LifeInsuranceLiabilityType.Mortgage,
          };
        case 'ApplicantOtherLoanLiability':
          return {
            balance: liability.balance.amount,
            monthlyInstallment: liability.monthlyInstallment.amount,
            type: LifeInsuranceLiabilityType.OtherLoan,
          };
        case 'ApplicantReconstructionLoanLiability':
          return {
            balance: liability.balance.amount,
            monthlyInstallment: liability.monthlyInstallment.amount,
            type: LifeInsuranceLiabilityType.ReconstructionLoan,
          };
        default:
          return {};
      }
    });
  },
  serialize: (liabilities) => {
    if (!liabilities || liabilities.length === 0) {
      return undefined;
    }

    const serializedLiabilities = liabilities.map<LifeInsuranceLiabilityInput | undefined>((liability) => {
      const { balance: balanceValue, monthlyInstallment: monthlyInstallmentValue } = liability;

      const createMoney = (amount?: number | null, currency = 'EUR' as const) => ({ amount: amount ?? 0, currency });
      const balance = createMoney(balanceValue);
      const monthlyInstallment = createMoney(monthlyInstallmentValue);

      switch (liability.type) {
        case LifeInsuranceLiabilityType.Alimony:
          return { alimonyLiability: { monthlyInstallment } };
        case LifeInsuranceLiabilityType.ConsumerLoan:
          return { consumerLoanLiability: { balance, monthlyInstallment } };
        case LifeInsuranceLiabilityType.Mortgage:
          return { mortgageLiability: { balance, monthlyInstallment } };
        case LifeInsuranceLiabilityType.Lease:
          return { leaseLiability: { balance, monthlyInstallment } };
        case LifeInsuranceLiabilityType.OtherLoan:
          return { otherLoanLiability: { balance, monthlyInstallment } };
        case LifeInsuranceLiabilityType.ReconstructionLoan:
          return { reconstructionLoanLiability: { balance, monthlyInstallment } };
        default:
          return undefined;
      }
    });

    const inputLiabilities = serializedLiabilities.filter<LifeInsuranceLiabilityInput>(
      (liability: LifeInsuranceLiabilityInput | undefined): liability is LifeInsuranceLiabilityInput => !!liability,
    );

    return inputLiabilities;
  },
};
