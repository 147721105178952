import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useMortgagePurchaseState } from 'components/flows/mortgagePurchase/state/useMortgagePurchaseState';
import { useMortgagePurchaseProcessQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MortgagePurchasePreviewDialogProps,
  MortgagePurchaseProductPreviewDialog,
} from './MoneyhoonProcessPreviewDialog/MortgagePurchasePreviewDialog';
import { ProductCard } from './ProductCard/ProductCard';

interface Props {
  id?: string;
}

export const MortgagePurchaseHomepageCard: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { state } = useMortgagePurchaseState();

  const { data, loading } = useMortgagePurchaseProcessQuery({
    skip: !isAuthenticated || !id,
    variables: {
      id: id ?? '',
    },
  });

  const progress = isAuthenticated ? data?.process.inquiry?.inquiryProgress : state.meta?.progress;

  const updateTimestamp = isAuthenticated ? data?.process.updateTimestamp : state.meta?.updateTimestamp;

  const accepted = !!data?.process.acceptedProposal?.id;

  const handlePurchaseClick = async () => {
    processProductPreviewDialog.create({ id });
  };

  const processProductPreviewDialog = useDialog<MortgagePurchasePreviewDialogProps, void>(
    MortgagePurchaseProductPreviewDialog,
    {
      id,
      instanceId: 'mortgage-purchase-product-preview-dialog',
      navigable: true,
    },
  );

  return (
    <ProductCard
      accepted={accepted}
      color="red"
      loading={loading}
      onClick={handlePurchaseClick}
      progress={progress}
      title={t('products:cards.purchaseMortgage.title')}
      updateDate={updateTimestamp}
    />
  );
};
