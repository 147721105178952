import { Button, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg';
import { ReactNode } from 'react';

import { styles } from './PeriodSwitcher.styles';

interface Props {
  title?: string | ReactNode;
  onChange: (direction: -1 | 1) => void;
  disableRight?: boolean;
}

export const PeriodSwitcher: React.FC<Props> = ({ disableRight, onChange, title }) => {
  const handleChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, direction: -1 | 1) => {
    e.currentTarget.blur();
    onChange(direction);
  };
  return (
    <Stack sx={styles.chevrons}>
      {typeof title === 'string' ? (
        <Typography component="h1" variant="headlineXL">
          {title}
        </Typography>
      ) : (
        title
      )}
      <Stack direction="row">
        <Button color="tertiaryButton" onClick={(e) => handleChange(e, -1)}>
          <ChevronLeftIcon />
        </Button>
        <Button color="tertiaryButton" disabled={disableRight} onClick={(e) => handleChange(e, 1)}>
          <ChevronRightIcon />
        </Button>
      </Stack>
    </Stack>
  );
};
