import { Box, Stack, Typography } from '@mui/material';
import { NoData } from 'common/components/NoData/NoData';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { PeriodTypeEnum } from 'components/spendingReport/state/spendingReportTypes';
import { formatPeriodTitle, getPeriod } from 'components/spendingReport/utils';
import { UpcomingPaymentListItem } from 'components/transactions/TransactionListItem/UpcomingPaymentListItem';
import { endOfMonth, startOfDay } from 'date-fns';
import { FilterOperator, useUpcomingPaymentsQuery } from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { UpcomingExpensesChart } from './UpcomingExpensesChart';

export type UpcomingExpensesDialogProps = DialogComponentProps<void>;

export const UpcomingExpensesDialog: React.FC<UpcomingExpensesDialogProps> = (props) => {
  const { t } = useTranslation();
  const { data } = useUpcomingPaymentsQuery({
    variables: {
      filterInput: {
        asOfDate: startOfDay(new Date()),
        endDate: endOfMonth(new Date()),
        excludeFromAnalytics: { operator: FilterOperator.NotEqual, value: 'true' },
        expectedBaseAmount: { operator: FilterOperator.LessThan, value: '0' },
      },
    },
  });

  const currentDate = new Date();
  const period = getPeriod(PeriodTypeEnum.Month, currentDate);
  const periodTitle = formatPeriodTitle(period, endOfMonth(currentDate));

  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <Stack pt={5} spacing={3}>
        <Typography variant="headlineXL">{t('upcomingExpensesDialog.title')}</Typography>
        {data?.upcomingPaymentsList?.items.length !== 0 ? (
          <>
            <UpcomingExpensesChart />
            <Stack pt={5} spacing={3}>
              <Typography component="h2" mb={3} variant="headlineM">
                {t('upcomingExpensesDialog.transactions.title')}
              </Typography>

              <Box bgcolor="gray.50" borderRadius="10px">
                {_.sortBy(data?.upcomingPaymentsList?.items, 'expectedDate').map((payment) => (
                  <UpcomingPaymentListItem
                    key={payment.streamGroup.id + payment.expectedDate.toISOString()}
                    payment={payment}
                  />
                ))}
              </Box>
            </Stack>
          </>
        ) : (
          <NoData subtitle="" title={t('upcomingPaymentDetail.noTransactions.title', { period: periodTitle })} />
        )}
      </Stack>
    </ResponsiveSideDialog>
  );
};
