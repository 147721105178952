import { withDefaultCurrency } from 'common/utils/formatUtils';
import { GenericProductFragment, GenericProductInput, ProductStatus } from 'generated/graphql';

export type MapGenericProductToInput = (product?: GenericProductFragment) => GenericProductInput;

export const mapGenericProductToInput: MapGenericProductToInput = (
  product = {
    creationTimestamp: new Date(),
    documents: [],
    enableFinancialAdvisorCare: true,
    enableNotifications: true,
    id: '',
    status: ProductStatus.Active,
    statusChangeDate: new Date(),
  },
) => {
  return {
    contractEndDate: product.contractEndDate,
    contractNumber: product.contractNumber,
    contractSignDate: product.contractSignDate,
    enableFinancialAdvisorCare: product.enableFinancialAdvisorCare,
    enableNotifications: product.enableNotifications,
    institutionId: product.institution?.id,
    institutionName: product.institutionName,
    name: product.name,
    paymentInfo: {
      constantSymbol: product.paymentInfo?.constantSymbol,
      ibanForPayment: product.paymentInfo?.ibanForPayment,
      specificSymbol: product.paymentInfo?.specificSymbol,
      variableSymbol: product.paymentInfo?.variableSymbol,
    },
    paymentPeriod: {
      expectedAmount: withDefaultCurrency(product.paymentPeriod?.expectedAmount),
      periodCount: product.paymentPeriod?.periodCount,
      periodTypeCode: product.paymentPeriod?.periodTypeCode,
      referenceDate: product.paymentPeriod?.expectedDate,
    },
    status: product.status,
    statusChangeDate: product.statusChangeDate,
  };
};
