import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'text' | 'imageContainer', SxProps<Theme>> = {
  container: (theme) => ({
    backgroundColor: theme.palette.gray[50],
    borderRadius: '10px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
  imageContainer: (theme) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  }),
  text: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 0, 6, 8),
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      padding: theme.spacing(6, 4, 0, 4),
      textAlign: 'center',
    },
  }),
};
