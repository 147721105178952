import { Button, DialogActions } from '@mui/material';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import { StyledDialog } from '../StyledDialog/StyledDialog';
import { StyledDialogSubtitle } from '../StyledDialog/StyledDialogSubtitle';
import { StyledDialogTitle } from '../StyledDialog/StyledDialogTitle';

export interface SuccessDialogProps extends DialogComponentProps<void> {
  title?: string;
  message: string;
}

export const SuccessDialog: React.FC<SuccessDialogProps> = ({ isOpen, message, onResolve, title }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog maxWidth="xs" onClose={() => onResolve()} open={isOpen}>
      <StyledDialogTitle size="small">{title ?? t('successDialog.title')}</StyledDialogTitle>
      <StyledDialogSubtitle size="small">{message}</StyledDialogSubtitle>
      <DialogActions>
        <Button fullWidth onClick={() => onResolve()} size="medium">
          {t('successDialog.close')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
