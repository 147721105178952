import { SxProps, Theme } from '@mui/material';

export const styles: Record<'button' | 'buttonLink' | 'googleButton' | 'input', SxProps<Theme>> = {
  button: {
    width: '100%',
  },
  buttonLink: {
    ':hover': {
      textDecoration: 'underline',
    },
    alignSelf: 'center',
    fontSize: '16px',
    fontWeight: '400',
    marginTop: (theme) => theme.spacing(3),
    textDecoration: 'underline',
    width: 'fit-content',
  },
  googleButton: {
    fontSize: '11px',
    marginBottom: (theme) => theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },
  input: {
    '& + &': {
      marginTop: (theme) => theme.spacing(2),
    },
    width: '100%',
  },
};
