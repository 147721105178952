import { parseDatestrings } from 'common/utils/parseDatestrings';
import {
  useCreateLifeInsurancePurchaseProcessMutation,
  useSendLifeInsurancePurchaseNotificationToSpecialistMutation,
  useUpdateLifeInsurancePurchaseProcessMutation,
} from 'generated/graphql';
import _ from 'lodash';

import { mapLifeInsurancePurchaseStateToInput } from './lifeInsurancePurchaseState';
import { LifeInsurancePurchaseState } from './lifeInsurancePurchaseTypes';
import { LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY, useLifeInsurancePurchaseState } from './useLifeInsurancePurchaseState';

export const useSaveLocalLifeInsurancePurchaseState = (): (() => Promise<string | undefined>) => {
  const [startProcess] = useCreateLifeInsurancePurchaseProcessMutation({ refetchQueries: ['AllProcesses'] });
  const [updateProcess] = useUpdateLifeInsurancePurchaseProcessMutation({
    refetchQueries: ['AllLifeInsurancePurchaseProcesses'],
  });

  const [sendNotification] = useSendLifeInsurancePurchaseNotificationToSpecialistMutation();
  const { clear } = useLifeInsurancePurchaseState();

  const save = async () => {
    const rawState = localStorage.getItem(LIFE_INSURANCE_STATE_LOCAL_STORAGE_KEY);
    const parsedState = rawState ? JSON.parse(rawState) : undefined;

    if (!_.isEmpty(parsedState)) {
      const stateWithDates = parseDatestrings(parsedState) as LifeInsurancePurchaseState;
      try {
        const processResp = await startProcess();
        const id = processResp.data?.createLifeInsurancePurchaseProcess?.id;
        if (id) {
          await updateProcess({
            variables: {
              id,
              inquiry: mapLifeInsurancePurchaseStateToInput(stateWithDates),
            },
          }).then(() => {
            sendNotification({
              variables: {
                processId: id,
              },
            });
            clear();
          });
        }
        return id;
      } catch (e) {
        //pass
      }
    }
  };

  return save;
};
