import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'row', SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    margin: (theme) => theme.spacing(3, 0),
    overflow: 'hidden',
    width: '100%',
  },
  row: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    display: 'flex',
    'div + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: (theme) => theme.spacing(2),
        top: 0,
      },
    },
    flexDirection: 'row',
    padding: (theme) => theme.spacing(2),
    position: 'relative',
  },
};
