import { MutationTuple } from '@apollo/client';
import { useNotification } from 'common/hooks/useNotification';
import {
  CreateBuildingSavingsProductDocumentMutation,
  CreateCollisionInsuranceProductDocumentMutation,
  CreateCreditCardProductDocumentMutation,
  CreateGenericInsuranceProductDocumentMutation,
  CreateGenericProductDocumentMutation,
  CreateInvestmentProductDocumentMutation,
  CreateLifeInsuranceProductDocumentMutation,
  CreateLoanProductDocumentMutation,
  CreateMortgageProductDocumentMutation,
  CreateMotorLiabilityInsuranceProductDocumentMutation,
  CreatePropertyInsuranceProductDocumentMutation,
  CreateRetirementPensionSavingsProductDocumentMutation,
  CreateSavingsProductDocumentMutation,
  CreateSupplementaryPensionSavingsProductDocumentMutation,
  DocumentInput,
  DocumentType,
  Exact,
  FinancialProductType,
  useCreateBuildingSavingsProductDocumentMutation,
  useCreateCollisionInsuranceProductDocumentMutation,
  useCreateCreditCardProductDocumentMutation,
  useCreateGenericInsuranceProductDocumentMutation,
  useCreateGenericProductDocumentMutation,
  useCreateInvestmentProductDocumentMutation,
  useCreateLifeInsuranceProductDocumentMutation,
  useCreateLoanProductDocumentMutation,
  useCreateMortgageProductDocumentMutation,
  useCreateMotorLiabilityInsuranceProductDocumentMutation,
  useCreatePropertyInsuranceProductDocumentMutation,
  useCreateRetirementPensionSavingsProductDocumentMutation,
  useCreateSavingsProductDocumentMutation,
  useCreateSupplementaryPensionSavingsProductDocumentMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

type MutationInput = Exact<{
  document: DocumentInput;
  productId: string;
}>;

type CreateProductDocumentMutation =
  | MutationTuple<CreateBuildingSavingsProductDocumentMutation, MutationInput>
  | MutationTuple<CreateCollisionInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<CreateCreditCardProductDocumentMutation, MutationInput>
  | MutationTuple<CreateGenericInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<CreateGenericProductDocumentMutation, MutationInput>
  | MutationTuple<CreateInvestmentProductDocumentMutation, MutationInput>
  | MutationTuple<CreateLifeInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<CreateLoanProductDocumentMutation, MutationInput>
  | MutationTuple<CreateMortgageProductDocumentMutation, MutationInput>
  | MutationTuple<CreateMotorLiabilityInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<CreatePropertyInsuranceProductDocumentMutation, MutationInput>
  | MutationTuple<CreateRetirementPensionSavingsProductDocumentMutation, MutationInput>
  | MutationTuple<CreateSavingsProductDocumentMutation, MutationInput>
  | MutationTuple<CreateSupplementaryPensionSavingsProductDocumentMutation, MutationInput>;

export const DocumentTypeByProductType = {
  [FinancialProductType.BuildingSavings]: DocumentType.BuildingSavingsContract,
  [FinancialProductType.CollisionInsurance]: DocumentType.CollisionInsuranceContract,
  [FinancialProductType.CreditCard]: DocumentType.CreditCardContract,
  [FinancialProductType.Generic]: DocumentType.GenericContract,
  [FinancialProductType.GenericInsurance]: DocumentType.GenericInsuranceContract,
  [FinancialProductType.Investment]: DocumentType.InvestmentContract,
  [FinancialProductType.LifeInsurance]: DocumentType.LifeInsuranceContract,
  [FinancialProductType.Loan]: DocumentType.LoanContract,
  [FinancialProductType.Mortgage]: DocumentType.MortgageContract,
  [FinancialProductType.MotorLiabilityInsurance]: DocumentType.MotorLiabilityInsuranceContract,
  [FinancialProductType.PropertyInsurance]: DocumentType.PropertyInsuranceContract,
  [FinancialProductType.RetirementPensionSavings]: DocumentType.RetirementPensionSavingsContract,
  [FinancialProductType.Savings]: DocumentType.SavingsContract,
  [FinancialProductType.SupplementaryPensionSavings]: DocumentType.SupplementaryPensionSavingsContract,
} as const;

export const useCreateProductDocument = (type: FinancialProductType): CreateProductDocumentMutation => {
  const { t } = useTranslation();
  const notify = useNotification();

  const mutation = {
    [FinancialProductType.BuildingSavings]: useCreateBuildingSavingsProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['BuildingSavingsProducts'],
    }),
    [FinancialProductType.CollisionInsurance]: useCreateCollisionInsuranceProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CollisionInsuranceProducts'],
    }),
    [FinancialProductType.CreditCard]: useCreateCreditCardProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CreditCardProducts'],
    }),
    [FinancialProductType.Generic]: useCreateGenericProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericProducts'],
    }),
    [FinancialProductType.GenericInsurance]: useCreateGenericInsuranceProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericInsuranceProducts'],
    }),
    [FinancialProductType.Investment]: useCreateInvestmentProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['InvestmentProducts'],
    }),
    [FinancialProductType.LifeInsurance]: useCreateLifeInsuranceProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LifeInsuranceProducts'],
    }),
    [FinancialProductType.Loan]: useCreateLoanProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LoanProducts'],
    }),
    [FinancialProductType.Mortgage]: useCreateMortgageProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MortgageProducts'],
    }),
    [FinancialProductType.MotorLiabilityInsurance]: useCreateMotorLiabilityInsuranceProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MotorLiabilityInsuranceProducts'],
    }),
    [FinancialProductType.PropertyInsurance]: useCreatePropertyInsuranceProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['PropertyInsuranceProducts'],
    }),
    [FinancialProductType.RetirementPensionSavings]: useCreateRetirementPensionSavingsProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['RetirementPensionSavingsProducts'],
    }),
    [FinancialProductType.Savings]: useCreateSavingsProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SavingsProducts'],
    }),
    [FinancialProductType.SupplementaryPensionSavings]: useCreateSupplementaryPensionSavingsProductDocumentMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SupplementaryPensionSavingsProducts'],
    }),
  }[type];

  return mutation;
};
