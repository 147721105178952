import { Download } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import { useAdminGetPermanentFileLinkQuery } from 'generated/graphql';
import { useListContext } from 'react-admin';

interface Props {
  fileId: string;
}

const FileLink: React.FC<Props> = ({ fileId }) => {
  const { data, loading } = useAdminGetPermanentFileLinkQuery({
    variables: {
      fileId,
    },
  });

  return (
    <Box>
      {loading ? (
        <Skeleton />
      ) : (
        <a href={data?.adminGetPermanentFileLink} target="_blank">
          <Download />
        </a>
      )}
    </Box>
  );
};

export const FileLinks: React.FC = () => {
  const { data } = useListContext();
  return (
    <>
      {data.map((file) => (
        <FileLink key={file.id} fileId={file.id} />
      ))}
    </>
  );
};
