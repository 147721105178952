import { SxProps, Theme } from '@mui/material';

export const styles: Record<'remove' | 'removeDescription' | 'close' | 'data', SxProps<Theme>> = {
  close: {
    position: 'absolute',
    right: (theme) => theme.spacing(1),
  },
  data: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
  },
  remove: {
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
    },
    backgroundColor: (theme) => theme.palette.gray['50'],
    borderRadius: '8px',
    cursor: 'pointer',
    padding: (theme) => theme.spacing(2),
  },
  removeDescription: {
    marginLeft: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(1),
  },
};
