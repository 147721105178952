import { Button } from '@mui/material';
import { Header } from 'common/components/Header/Header';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentPageButtons } from './CurrentPageButtons';
import { styles } from './FlowsHeader.styles';

interface HeaderProps {
  children?: ReactNode;
  onSummaryClick?: () => void;
  onSaveClick?: () => void;
}

export const FlowsHeader: React.FC<HeaderProps> = ({ children, onSaveClick, onSummaryClick }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Header navigationBar={<CurrentPageButtons>{children}</CurrentPageButtons>} sx={styles.header}>
      {!isAuthenticated && onSaveClick && (
        <Button color="secondaryButton" onClick={onSaveClick}>
          {t('flows.header.save')}
        </Button>
      )}
      {!!onSummaryClick && (
        <Button color="primary" onClick={onSummaryClick}>
          {t('flows.header.summary')}
        </Button>
      )}
    </Header>
  );
};
