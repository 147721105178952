import { MortgageLiabilityType } from 'const';
import { MortgageLiabilityInput, MortgagePurchaseApplicantFragment } from 'generated/graphql';

import { Liability, Mapper } from './purchaseTypes';

const MortgageLiabilityTypeNamesToMortgageLiabilityEnum: Record<string, MortgageLiabilityType> = {
  ApplicantAlimonyLiability: MortgageLiabilityType.Alimony,
  ApplicantConsumerLoanLiability: MortgageLiabilityType.ConsumerLoan,
  ApplicantCreditCardLiability: MortgageLiabilityType.CreditCard,
  ApplicantLeaseLiability: MortgageLiabilityType.Lease,
  ApplicantMortgageLiability: MortgageLiabilityType.Mortgage,
  ApplicantOtherLoanLiability: MortgageLiabilityType.OtherLoan,
  ApplicantOverdraftLiability: MortgageLiabilityType.Overdraft,
  ApplicantReconstructionLoanLiability: MortgageLiabilityType.ReconstructionLoan,
};

export const mapLiabilities: Mapper<
  Liability[],
  MortgageLiabilityInput[],
  MortgagePurchaseApplicantFragment['liabilities']
> = {
  parse: (liabilities) => {
    const parserLiabilities = liabilities
      ?.filter((liability) => !!liability.__typename)
      .map((liability) => {
        const { __typename, ...values } = liability;

        const getValues = () =>
          Object.entries(values || {})
            .map(([key, moneyObj]) => ({ [key]: moneyObj.amount }))
            .reduce((acc, value) => ({ ...acc, ...value }), {});

        return {
          type: MortgageLiabilityTypeNamesToMortgageLiabilityEnum[__typename as MortgageLiabilityType],
          ...getValues(),
        } as Liability;
      });

    if (!parserLiabilities) {
      return undefined;
    }
    if (parserLiabilities.length === 0) {
      return [{ type: 'none' }];
    }

    return parserLiabilities;
  },
  serialize: (liabilities) => {
    if (!liabilities || liabilities.length === 0) {
      return undefined;
    }

    const serializedLiabilities = liabilities.map<MortgageLiabilityInput | undefined>((liability) => {
      const { balance: balanceValue, limit: limitValue, monthlyInstallment: monthlyInstallmentValue, type } = liability;

      const createMoney = (amount?: number | null, currency = 'EUR' as const) => ({ amount: amount ?? 0, currency });
      const limit = createMoney(limitValue);
      const balance = createMoney(balanceValue);
      const monthlyInstallment = createMoney(monthlyInstallmentValue);

      switch (type) {
        case MortgageLiabilityType.Alimony:
          return { alimonyLiability: { monthlyInstallment } };
        case MortgageLiabilityType.ConsumerLoan:
          return { consumerLoanLiability: { balance, monthlyInstallment } };
        case MortgageLiabilityType.CreditCard:
          return { creditCardLiability: { limit } };
        case MortgageLiabilityType.Lease:
          return { leaseLiability: { balance, monthlyInstallment } };
        case MortgageLiabilityType.Mortgage:
          return { mortgageLiability: { balance, monthlyInstallment } };
        case MortgageLiabilityType.OtherLoan:
          return { otherLoanLiability: { balance, monthlyInstallment } };
        case MortgageLiabilityType.Overdraft:
          return { overdraftLiability: { limit } };
        case MortgageLiabilityType.ReconstructionLoan:
          return { reconstructionLoanLiability: { balance, monthlyInstallment } };
        default:
          return undefined;
      }
    });

    const inputLiabilities = serializedLiabilities.filter<MortgageLiabilityInput>(
      (liability: MortgageLiabilityInput | undefined): liability is MortgageLiabilityInput => !!liability,
    );

    return inputLiabilities;
  },
};
