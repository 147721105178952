import { Box, Stack, Typography } from '@mui/material';
import { PeriodSwitcher } from 'common/components/PeriodSwitcher/PeriodSwitcher';
import { PeriodTypeEnum } from 'components/spendingReport/state/spendingReportTypes';
import { addPeriods, formatPeriodTitle, getPeriod } from 'components/spendingReport/utils';
import { endOfMonth, isSameMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CashflowGuardianAggregateList } from '../CashflowGuardianAggregateList/CashflowGuardianAggregateList';
import { CashflowGuardianLineChart } from '../CashflowGuardianLineChart/CashflowGuardianLineChart';
import { CashflowGuardianSituation } from '../CashflowGuardianSituation/CashflowGuardianSituation';
import { CashflowState } from '../useCashflowState';
import { styles } from './CashflowGuardianDialog.styles';

export interface CashflowGuardianDialogContentProps {
  cashflowState: CashflowState;
  pointerDate: Date;
  onArrowClick: (direction: -1 | 1) => void;
}

export const CashflowGuardianDialogContent: React.FC<CashflowGuardianDialogContentProps> = ({
  cashflowState,
  onArrowClick,
  pointerDate,
}) => {
  const { t } = useTranslation();

  const isCurrentMonth = isSameMonth(pointerDate, new Date());
  const asOfDate = isCurrentMonth ? pointerDate : endOfMonth(pointerDate);

  const period = getPeriod(PeriodTypeEnum.Month, pointerDate);
  const periodTitle = formatPeriodTitle(period, endOfMonth(pointerDate));
  return cashflowState.budget ? (
    <Stack pt={5} spacing={3}>
      <PeriodSwitcher
        disableRight={addPeriods(pointerDate, PeriodTypeEnum.Month, 1) > new Date()}
        onChange={onArrowClick}
        title={periodTitle}
      />

      {isCurrentMonth && (
        <Box sx={styles.box}>
          <CashflowGuardianSituation asOfDate={asOfDate} />
        </Box>
      )}
      <Box sx={styles.box}>
        <CashflowGuardianLineChart asOfDate={asOfDate} />
      </Box>
      <Box>
        <CashflowGuardianAggregateList asOfDate={asOfDate} cashflowState={cashflowState} />
      </Box>
      {isCurrentMonth && (
        <Typography color="textSecondary" textAlign="center" variant="bodyM">
          {t('cashflowGuardian.aggregates.info')}
        </Typography>
      )}
    </Stack>
  ) : null;
};
