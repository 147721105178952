import { SxProps, Theme } from '@mui/material';

export const styles = (
  introImage?: string,
): Record<
  | 'page'
  | 'main'
  | 'content'
  | 'image'
  | 'floatingAsterisk'
  | 'floatingAsteriskEmpty'
  | 'imageMoneyhoon'
  | 'lightBackground'
  | 'darkBackground',
  SxProps<Theme>
> => ({
  content: {
    alignItems: 'center',
    display: 'grid',
    flexGrow: 1,
    gridTemplateColumns: { md: '3fr 2fr', xs: '1fr' },
    height: '100%',
    overflowX: 'hidden',
    padding: (theme) => ({ md: 0, xxs: theme.spacing(0, 2) }),
    position: 'relative',
  },
  darkBackground: {
    backgroundColor: (theme) => theme.palette.gray[200],
  },
  floatingAsterisk: {
    display: { md: 'block', xs: 'none' },
    position: 'absolute',
    right: '-64px',
    top: '40%',
  },
  floatingAsteriskEmpty: {
    left: '-64px',
    position: 'absolute',
    top: '10%',
  },
  image: {
    alignSelf: 'stretch',
    background: `url(${introImage}) center center/cover no-repeat `,
    display: { md: 'block', xs: 'none' },
    paddingLeft: '64px',
    position: 'relative',
  },
  imageMoneyhoon: {
    background: `url(${introImage}) center center no-repeat `,
    display: { md: 'flex', xs: 'none' },
    height: '100%',
    position: 'relative',
  },
  lightBackground: {
    backgroundColor: (theme) => theme.palette.white,
  },
  main: {
    alignSelf: 'center',
    margin: (theme) => `${theme.spacing(6)} auto`,
    maxWidth: '624px',
  },
  page: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    minWidth: '100%',
    position: 'relative',
    scrollbarWidth: 'thin',
  },
});
