import { SxProps, Theme } from '@mui/material';

export const styles: Record<'chevrons', SxProps<Theme>> = {
  chevrons: {
    alignItems: 'baseline',
    flexDirection: { sm: 'row', xxs: 'column' },
    gap: 2,
    justifyContent: { sm: 'space-between', xxs: 'center' },
  },
};
