import { withDefaultCurrency } from 'common/utils/formatUtils';
import {
  MortgageProductFragment,
  MortgageProductInput,
  ProductStatus,
  TransactionGroupPeriodTypeCode,
} from 'generated/graphql';

export type MapMortgageProductToInput = (product?: MortgageProductFragment) => MortgageProductInput;

export const mapMortgageProductToInput: MapMortgageProductToInput = (
  product = {
    collaterals: [],
    creationTimestamp: new Date(),
    documents: [],
    enableFinancialAdvisorCare: true,
    enableNotifications: true,
    id: '',
    participants: [],
    status: ProductStatus.Active,
    statusChangeDate: new Date(),
  },
) => ({
  amount: withDefaultCurrency(product.amount),
  balance: withDefaultCurrency(product.balance),
  collaterals: product.collaterals?.map((collateral) => ({ address: collateral.address })) ?? [],
  contractNumber: product.contractNumber,
  contractSignDate: product.contractSignDate,
  enableFinancialAdvisorCare: product.enableFinancialAdvisorCare,
  enableNotifications: product.enableNotifications,
  fixationEndDate: product.fixationEndDate,
  fixationLength: product.fixationLength,
  institutionId: product.institution?.id,
  institutionName: product.institutionName,
  interestRate: product.interestRate,
  name: product.name,
  participants: product.participants?.map((participant) => ({ name: participant.name })) ?? [],
  paymentPeriod: {
    expectedAmount: withDefaultCurrency(product.paymentPeriod?.expectedAmount),
    periodCount: product.paymentPeriod?.expectedDate ? 1 : undefined,
    periodTypeCode: product.paymentPeriod?.expectedDate ? TransactionGroupPeriodTypeCode.Month : undefined,
    referenceDate: product.paymentPeriod?.expectedDate,
  },
  purchasedProperty: product.purchasedProperty
    ? {
        address: product.purchasedProperty?.address,
      }
    : undefined,
  status: product.status,
  statusChangeDate: product.statusChangeDate,
});
