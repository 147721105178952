import { Box } from '@mui/material';
import { Header } from 'common/components/Header/Header';
import { SettingsPageContent } from 'components/SettingsPage/SettingsPageContent';

export const SettingsPage: React.FC = () => {
  return (
    <Box>
      <Header />
      <SettingsPageContent />
    </Box>
  );
};
