import { SxProps, Theme } from '@mui/material';

export const styles: Record<'info' | 'label' | 'muiLabel', SxProps<Theme>> = {
  info: {
    cursor: 'pointer',
    display: 'flex',
    height: 16,
    marginLeft: (theme) => theme.spacing(1),
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: (theme) => theme.spacing(2),
  },
  muiLabel: {
    marginBottom: 0,
    textOverflow: 'hidden',
  },
};
