import { Box, FormControlLabel } from '@mui/material';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InputLabel } from 'common/components/InputLabel/InputLabel';
import React from 'react';
import { useController } from 'react-hook-form';

import { FormCheckBox } from '../FormCheckBox';
import { FormElementProps } from '../FormElement';
import { styles } from './FormCheckboxGroup.styles';

export interface Checkbox {
  label: string | React.ReactNode;
  name: string;
}

export interface FormCheckboxGroupProps extends FormElementProps {
  options: Checkbox[];
  size?: 'large' | 'medium';
  helperText?: string;
  columns?: number;
}

export const FormCheckBoxGroup = ({
  columns = 1,
  helperText,
  label,
  name,
  options,
  size,
}: FormCheckboxGroupProps): React.ReactElement => {
  const { fieldState } = useController({ name });

  return (
    <>
      {!!label && <InputLabel helperText={helperText} label={label} size={size} />}

      <Box sx={styles(columns).root}>
        {options.map((option) => (
          <Box key={JSON.stringify(option.name)}>
            <FormControlLabel
              control={<FormCheckBox name={`${name}.${option.name}`} />}
              label={option.label}
              sx={styles().checkbox}
              tabIndex={0}
            />
          </Box>
        ))}
      </Box>

      {!!fieldState?.error?.message && (
        <FormHelperText error size={size}>
          {fieldState.error.message}
        </FormHelperText>
      )}
    </>
  );
};
