import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmDialogOnSideProps extends DialogComponentProps<boolean> {
  title: string;
  content?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => Promise<void>;
}

export const ConfirmDialogOnSide: React.FC<ConfirmDialogOnSideProps> = ({
  cancelButtonText,
  confirmButtonText,
  content,
  isOpen,
  onConfirm,
  onResolve,
  title,
}) => {
  const { t } = useTranslation();
  const [working, setWorking] = useState(false);

  const handleConfirm = async () => {
    if (onConfirm) {
      setWorking(true);
      await onConfirm();
      setWorking(false);
    }
    onResolve(true);
  };

  return (
    <PromptDialogOnSide onClose={() => onResolve(false)} open={isOpen} subtitle={content} title={title}>
      <Stack spacing={1}>
        <LoadingButton color="tertiaryButton" loading={working} onClick={handleConfirm} sx={styles.button}>
          <Typography color="red.dark" variant="bodyL">
            {confirmButtonText ?? t('common:confirm')}
          </Typography>
        </LoadingButton>
        <Button color="tertiaryButton" onClick={() => onResolve(false)} sx={styles.button}>
          <Typography color="blue.dark" variant="bodyStrongL">
            {cancelButtonText ?? t('common:cancel')}
          </Typography>
        </Button>
      </Stack>
    </PromptDialogOnSide>
  );
};
