import { configureAmplify } from 'common/auth/aws-amplify-setup';
import { Loading } from 'common/components/Loading/Loading';
import { useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { LoginDialog, LoginDialogProps } from 'components/auth/LoginDialog';
import { VerifyEmailFailDialog, VerifyEmailFailDialogProps } from 'components/auth/VerifyEmailFailDialog';
import { ProductFlows } from 'const';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BasicLayout } from '../common/layout/onboarding/BasicLayout';

export const VerifyEmailPage: React.FC = () => {
  const { t } = useTranslation();
  const { confirmSignUp } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const verifyEmailFailDialog = useDialog<VerifyEmailFailDialogProps, void>(VerifyEmailFailDialog, {
    instanceId: 'verify-email-fail-dialog',
  });

  const loginDialog = useDialog<LoginDialogProps, void>(LoginDialog, {
    instanceId: 'login-page-dialog',
  });

  const onVerifyEmailFail = () => {
    verifyEmailFailDialog.create({ displayAsPage: true });
  };

  const handleVerifyEmail = async () => {
    const userName = searchParams.get('userName');
    const code = searchParams.get('confirmationCode');
    if (userName && code) {
      configureAmplify();
      setLoading(true);
      const resp = await confirmSignUp(userName, code);
      setLoading(false);
      if (!resp) {
        searchParams.delete('confirmationCode');
        searchParams.delete('userName');
        searchParams.delete('clientId');
        setSearchParams(searchParams, { replace: true });
        await loginDialog.create({
          displayAsPage: true,
          saveLocalState: Object.values(ProductFlows),
          showGoogleLoginInfo: false,
          showRegistrationInfo: false,
          title: t('registrationVerification.loginSuccessTitle'),
        });
      } else {
        onVerifyEmailFail();
      }
    } else {
      setTimeout(() => navigate('/login', { replace: true }), 0);
    }
  };

  useEffect(() => {
    handleVerifyEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BasicLayout showLogin={false}>{loading && <Loading hideCommonLoadingTitle messages={[]} />}</BasicLayout>;
};
