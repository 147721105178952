import { SxProps, Theme } from '@mui/material';

export const styles = (columns?: number): Record<'checkbox' | 'checkboxSelected' | 'root', SxProps<Theme>> => ({
  checkbox: {
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
      border: (theme) => `1px solid ${theme.palette.gray['500']}`,
    },
    '.MuiFormControlLabel-label': {
      flexGrow: 1,
    },
    border: (theme) => `1px solid ${theme.palette.gray['400']}`,
    borderRadius: '4px',
    margin: 0,
    padding: 2,
    width: '100%',
  },
  checkboxSelected: {
    borderColor: (theme) => theme.palette.gray[900],
  },
  root: {
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: { sm: `repeat(${columns}, 1fr)`, xs: '1fr' },
  },
});
