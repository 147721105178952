import {
  ProductTourBaseProps,
  ProductTourDialog,
  TourStep,
} from 'common/components/ProductTourDialog/ProductTourDialog';
import { useTranslation } from 'react-i18next';

import { SetBudgetInput } from './SetBudgetInput';
import { SetBudgetIntro } from './SetBudgetIntro';

export type CashflowGuardianProductTourDialogProps = Omit<ProductTourBaseProps, 'steps' | 'finishButtonText'>;

export const CashflowGuardianProductTourDialog: React.FC<CashflowGuardianProductTourDialogProps> = (props) => {
  const { t } = useTranslation();

  const steps: TourStep[] = [
    { content: <SetBudgetIntro />, nextButtonText: t('cashflowGuardian.setBudget.next') },
    { content: <SetBudgetInput onResolve={props.onResolve} /> },
  ];

  return (
    <ProductTourDialog
      {...props}
      finishButtonText={t('cashflowGuardian.setBudget.finish')}
      hideFinishButton
      steps={steps}
    />
  );
};
