import MortgageAvatar from 'assets/images/mortgage-avatar.png';
import { useNotification } from 'common/hooks/useNotification';
import { formatCurrency, formatPercent, formatTransactionAmount } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import {
  FinancialProductType,
  MortgageCollateral,
  MortgageParticipant,
  MortgageProductFragment,
  useMortgageProductQuery,
  useUpdateMortgageProductMutation,
} from 'generated/graphql';
import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductDetail } from '../ProductDetail/ProductDetail';
import { DetailRowType, List, Section } from '../types';
import { ProductDetailDialogProps } from '../useProductDetail';
import { mapMortgageProductToInput } from './utils';

export const MortgageProductDetail: React.FC<ProductDetailDialogProps> = ({ id, ...props }) => {
  const { i18n, t } = useTranslation();
  const notify = useNotification();

  const { data } = useMortgageProductQuery({
    variables: {
      id,
    },
  });

  const content: (
    | Section<MortgageProductFragment, Path<MortgageProductFragment>>
    | List<MortgageProductFragment, 'collaterals'>
    | List<MortgageProductFragment, 'participants'>
  )[] = [
    {
      rows: [
        {
          inputType: 'text',
          label: t('products:mortgage.detail.institutionName'),
          path: 'institutionName',
          skip: !!data?.detail.institution?.id,
        } as DetailRowType<MortgageProductFragment, 'institutionName'>,
        {
          inputType: 'text',
          label: t('products:mortgage.detail.productName'),
          path: 'name',
        } as DetailRowType<MortgageProductFragment, 'name'>,
        {
          inputType: 'text',
          label: t('products:mortgage.detail.contractNumber'),
          path: 'contractNumber',
        } as DetailRowType<MortgageProductFragment, 'contractNumber'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          formatter: (value: number) =>
            formatTransactionAmount(value, i18n.language, 2, data?.detail.paymentPeriod?.expectedAmount?.currency),
          inputEndAdornment: data?.detail.paymentPeriod?.expectedAmount?.currency,
          inputType: 'amount',
          label: t('products:mortgage.detail.instalment'),
          // we are storing paymentPeriod.expectedAmount.amount as negative number
          // but we want user to type a positive equivalent
          parse: (value) => -value,
          path: 'paymentPeriod.expectedAmount.amount',
          transform: (value) => -value,
        } as DetailRowType<MortgageProductFragment, 'paymentPeriod.expectedAmount.amount'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          inputType: 'date',
          label: t('products:mortgage.detail.nextPayment'),
          path: 'paymentPeriod.expectedDate',
        } as DetailRowType<MortgageProductFragment, 'paymentPeriod.expectedDate'>,
      ],
      title: '',
    },
    {
      rows: [
        {
          formatter: (value: number) => formatCurrency(value ?? 0, i18n.language),
          inputEndAdornment: t('common:inputEndAdornment.eur'),
          inputType: 'amount',
          label: t('products:mortgage.detail.amount'),
          path: 'amount.amount',
        } as DetailRowType<MortgageProductFragment, 'amount.amount'>,
        {
          formatter: (value: number) => formatPercent(value, i18n.language),
          inputEndAdornment: t('common:inputEndAdornment.percent'),
          inputType: 'number',
          label: t('products:mortgage.detail.interestRate'),
          parse: (value: number) => value / 100,
          path: 'interestRate',
          transform: (value: number) => value * 100,
        } as DetailRowType<MortgageProductFragment, 'interestRate'>,
        {
          inputType: 'integer',
          label: t('products:mortgage.detail.fixationLength'),
          path: 'fixationLength',
        } as DetailRowType<MortgageProductFragment, 'fixationLength'>,
        {
          formatter: (value: Date) => format(value, 'd. M. yyyy'),
          helperText: t('products:mortgage.detail.fixationEndDate.helperText'),
          inputType: 'date',
          label: t('products:mortgage.detail.fixationEndDate.label'),
          path: 'fixationEndDate',
          tooltip: t('products:mortgage.detail.fixationEndDate.tooltip'),
        } as DetailRowType<MortgageProductFragment, 'fixationEndDate'>,
      ],
      title: t('products:mortgage.detail.paymentInfo.title', {
        date: format(data?.detail.statusChangeDate ?? new Date(), 'd. M. yyyy'),
      }),
    },
    {
      addLabel: t('products:mortgage.detail.collaterals.addLabel'),
      fields: [
        {
          inputType: 'text',
          label: t('products:mortgage.detail.collateral.location.label'),
          path: 'address',
        },
      ],
      formatter: (value: MortgageCollateral) => value.address ?? '-',
      label: t('products:mortgage.detail.collaterals.label'),
      path: 'collaterals',
      removeLabel: t('products:mortgage.detail.collaterals.removeLabel'),
      title: t('products:mortgage.detail.collaterals.title'),
    },
    {
      addLabel: t('products:mortgage.detail.participants.addLabel'),
      fields: [
        {
          inputType: 'text',
          label: t('products:mortgage.detail.participants.name.label'),
          path: 'name',
        },
      ],
      formatter: (value?: MortgageParticipant) => value?.name ?? '-',
      label: t('products:mortgage.detail.participants.label'),
      path: 'participants',
      removeLabel: t('products:mortgage.detail.participants.removeLabel'),
      title: t('products:mortgage.detail.participants.title'),
    },
  ];

  const [updateProduct] = useUpdateMortgageProductMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    refetchQueries: ['CalendarEventInstances', 'Notifications', 'NotificationsCount'],
  });

  const handleUpdate = async (value: MortgageProductFragment) => {
    await updateProduct({ variables: { id, updatedProduct: mapMortgageProductToInput(value) } });
  };

  return (
    <ProductDetail<MortgageProductFragment>
      {...props}
      contactExpert={{
        avatarSrc: MortgageAvatar,
        email: t('products:contacts.mortgage.email'),
        jobTitle: t('products:contacts.mortgage.jobTitle'),
        name: t('products:contacts.mortgage.name'),
        phone: t('products:contacts.mortgage.phone'),
      }}
      content={content}
      data={data?.detail}
      institution={data?.detail.institution ?? undefined}
      onUpdate={handleUpdate}
      title={t('products:mortgage.detail.title')}
      type={FinancialProductType.Mortgage}
    />
  );
};
