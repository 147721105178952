import { Grid } from '@mui/material';
import { FinancialProductType } from 'generated/graphql';
import { useGetList } from 'react-admin';

import { AccountClientStatsCard } from './AccountClientStatsCard';
import { AccountsChart } from './AccountsChart';
import { AccountStatsCard } from './AccountStatsCard';
import { ClientsContactsChart } from './ClientsContactsChart';
import { ClientStatsCard } from './ClientStatsCard';
import { ContactStatsCard } from './ContactsStatsCard';
import { ProductActiveClientStatsCard } from './ProductActiveClientStatsCard';
import { ProductActiveStatsCard } from './ProductActiveStatsCard';
import { ProductClientStatsCard } from './ProductClientStatsCard';
import { ProductsStatusChart } from './ProductsStatusChart';
import { ProductStatsCard } from './ProductStatsCard';
import { ProductsTypeChart } from './ProductsTypeChart';

export const Dashboard: React.FC = () => {
  const { data: clients, isLoading: isLoadingClients } = useGetList('Client', {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'creationTimestamp', order: 'DESC' },
  });

  const { data: accounts, isLoading: isLoadingAccounts } = useGetList('Account', {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'creationTimestamp', order: 'DESC' },
  });

  const { data: collisionInsuranceProducts, isLoading: isLoadingCollisionInsuranceProducts } = useGetList(
    'CollisionInsuranceProduct',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'creationTimestamp', order: 'DESC' },
    },
  );

  const { data: genericProducts, isLoading: isLoadingGenericProducts } = useGetList('GenericProduct', {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'creationTimestamp', order: 'DESC' },
  });

  const { data: investmentProducts, isLoading: isLoadingInvestmentProducts } = useGetList('InvestmentProduct', {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'creationTimestamp', order: 'DESC' },
  });

  const { data: lifeInsuranceProducts, isLoading: isLoadingLifeInsuranceProducts } = useGetList(
    'LifeInsuranceProduct',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'creationTimestamp', order: 'DESC' },
    },
  );

  const { data: mortgageProducts, isLoading: isLoadingMortgageProducts } = useGetList('MortgageProduct', {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'creationTimestamp', order: 'DESC' },
  });

  const { data: motorLiabilityInsuranceProducts, isLoading: isLoadingMotorLiabilityInsuranceProducts } = useGetList(
    'MotorLiabilityInsuranceProduct',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'creationTimestamp', order: 'DESC' },
    },
  );

  const { data: propertyInsuranceProducts, isLoading: isLoadingPropertyInsuranceProducts } = useGetList(
    'PropertyInsuranceProduct',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'creationTimestamp', order: 'DESC' },
    },
  );

  const isLoadingProducts =
    isLoadingCollisionInsuranceProducts ||
    isLoadingGenericProducts ||
    isLoadingInvestmentProducts ||
    isLoadingLifeInsuranceProducts ||
    isLoadingMortgageProducts ||
    isLoadingMotorLiabilityInsuranceProducts ||
    isLoadingPropertyInsuranceProducts;

  const products = [
    ...(collisionInsuranceProducts?.map((item) => ({ ...item, type: FinancialProductType.CollisionInsurance })) ?? []),
    ...(genericProducts?.map((item) => ({ ...item, type: FinancialProductType.Generic })) ?? []),
    ...(investmentProducts?.map((item) => ({ ...item, type: FinancialProductType.Investment })) ?? []),
    ...(lifeInsuranceProducts?.map((item) => ({ ...item, type: FinancialProductType.LifeInsurance })) ?? []),
    ...(mortgageProducts?.map((item) => ({ ...item, type: FinancialProductType.Mortgage })) ?? []),
    ...(motorLiabilityInsuranceProducts?.map((item) => ({
      ...item,
      type: FinancialProductType.MotorLiabilityInsurance,
    })) ?? []),
    ...(propertyInsuranceProducts?.map((item) => ({ ...item, type: FinancialProductType.PropertyInsurance })) ?? []),
  ];

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item md={3} sm={12}>
        <ClientStatsCard clients={clients} isLoading={isLoadingClients} />
      </Grid>

      <Grid item md={3} sm={12}>
        <ContactStatsCard clients={clients} isLoading={isLoadingClients} />
      </Grid>

      <Grid item md={3} sm={12}>
        <AccountStatsCard accounts={accounts} isLoading={isLoadingAccounts} />
      </Grid>

      <Grid item md={3} sm={12}>
        <AccountClientStatsCard accounts={accounts} isLoading={isLoadingAccounts} />
      </Grid>

      <Grid item md={6} sm={12}>
        <ClientsContactsChart clients={clients} isLoading={isLoadingClients} />
      </Grid>

      <Grid item md={6} sm={12}>
        <AccountsChart accounts={accounts} isLoading={isLoadingAccounts} />
      </Grid>

      <Grid item md={2} sm={12}>
        <ProductStatsCard isLoading={isLoadingProducts} products={products} />
      </Grid>

      <Grid item md={3} sm={12}>
        <ProductClientStatsCard isLoading={isLoadingProducts} products={products} />
      </Grid>

      <Grid item md={3} sm={12}>
        <ProductActiveStatsCard isLoading={isLoadingProducts} products={products} />
      </Grid>

      <Grid item md={4} sm={12}>
        <ProductActiveClientStatsCard isLoading={isLoadingProducts} products={products} />
      </Grid>

      <Grid item md={6} sm={12}>
        <ProductsStatusChart isLoading={isLoadingProducts} products={products} />
      </Grid>

      <Grid item md={6} sm={12}>
        <ProductsTypeChart isLoading={isLoadingProducts} products={products} />
      </Grid>
    </Grid>
  );
};
