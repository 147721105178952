import { SxProps, Theme } from '@mui/material';

export const styles: Record<'clickableRow' | 'disabledRow' | 'icon' | 'skeleton', SxProps<Theme>> = {
  clickableRow: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    display: 'flex',
    'div + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: (theme) => theme.spacing(2),
        top: 0,
      },
    },
    flexDirection: 'row',
    padding: (theme) => theme.spacing(2),
    position: 'relative',
  },

  disabledRow: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    display: 'flex',
    'div + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: (theme) => theme.spacing(2),
        top: 0,
      },
    },
    flexDirection: 'row',
    padding: (theme) => theme.spacing(2),
    position: 'relative',
  },

  icon: {
    color: (theme) => theme.palette.gray[500],
  },

  skeleton: {
    borderRadius: '10px',
    'div + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: (theme) => theme.spacing(2),
        top: 0,
      },
    },
    height: 54,
  },
};
