import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';

import { styles } from './InfoBox.styles';

interface Props {
  title?: string;
  children: React.ReactNode;
}
export const InfoBox: React.FC<Props> = ({ children, title }) => {
  return (
    <Box sx={styles.infoBox}>
      <Box>
        <InfoIcon height={16} width={16} />
      </Box>
      <Stack justifyContent="center">
        {title && (
          <Typography color="blue" component="div" variant="bodyStrongS">
            {title}
          </Typography>
        )}
        <Typography color="blue" component="div" variant="bodyS">
          {children}
        </Typography>
      </Stack>
    </Box>
  );
};
