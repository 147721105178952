import { LoadingIndicatorContext } from 'common/providers/LoadingIndicatorProvider/LoadingIndicatorProvider';
import { useContext } from 'react';

interface UseLoadingIndicatorReturn {
  end: (message?: string) => void;
  start: (message?: string) => void;
}

export const useLoadingIndicator = (baseMessage: string): UseLoadingIndicatorReturn => {
  const { end, start } = useContext(LoadingIndicatorContext);

  return {
    end: (message) => end(message ?? baseMessage),
    start: (message) => start(message ?? baseMessage),
  };
};
