import { SxProps, Theme } from '@mui/material';

export const styles: Record<'visibilityIcon', SxProps<Theme>> = {
  visibilityIcon: {
    height: 'unset',
    margin: '-10px',
    marginRight: 0,
    maxHeight: 'unset',
  },
};
