import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'label' | 'labelTextSmall' | 'score', SxProps<Theme>> = {
  container: {
    position: 'relative',
  },
  label: {
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  labelTextSmall: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: '7px',
    fontWeight: 400,
  },
  score: {
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
};
