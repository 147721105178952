export const MORTGAGE_CALCULATOR_BASE_URL = '/mortgage/calculator';
export const MORTGAGE_CALCULATOR_URL_ALIASES = ['/hypoteka/hypokalkulacka'];

export const MORTGAGE_PURCHASE_BASE_URL = '/mortgage/purchase';
export const MORTGAGE_PURCHASE_URL_ALIASES = ['/hypoteka'];

export const MORTGAGE_REFINANCE_BASE_URL = '/mortgage/refinance';
export const MORTGAGE_REFINANCE_URL_ALIASES = ['/hypoteka/refinancovanie'];

export const LIFE_INSURANCE_CALCULATOR_BASE_URL = '/life-insurance/calculator';
export const LIFE_INSURANCE_CALCULATOR_URL_ALIASES = ['/zivotne-poistenie/kalkulacka'];

export const LIFE_INSURANCE_PURCHASE_BASE_URL = '/life-insurance/purchase';
export const LIFE_INSURANCE_PURCHASE_URL_ALIASES = ['/zivotne-poistenie'];

export const LIFE_INSURANCE_AUDIT_BASE_URL = '/life-insurance/audit';
export const LIFE_INSURANCE_AUDIT_URL_ALIASES = ['/zivotne-poistenie/audit'];

export const MOTOR_LIABILITY_INSURANCE_BASE_URL = '/motor-liability-insurance';
export const MOTOR_LIABILITY_INSURANCE_URL_ALIASES = ['/pzp'];

export const COLLISION_INSURANCE_BASE_URL = '/collision-insurance';
export const COLLISION_INSURANCE_URL_ALIASES = ['/havarijne-poistenie'];

export const PROPERTY_INSURANCE_BASE_URL = '/property-insurance';
export const PROPERTY_INSURANCE_URL_ALIASES = ['/poistenie-majetku'];

export const INVESTMENT_BASE_URL = '/investment';
export const INVESTMENT_URL_ALIASES = ['/investovanie'];

export const MORTGAGE_PROPERTY_SIZES = { FLAT: 150, HOUSE: 300 };

export const PSD2_URL = 'https://eur-lex.europa.eu/legal-content/SK/TXT/?uri=celex%3A32015L2366';

export const INVESTMENT_BOOK_APPOINTMENT_URL = '/rest/book-appointment/investment';
export const LIFE_INSURANCE_BOOK_APPOINTMENT_URL = '/rest/book-appointment/life-insurance';
export const MORTGAGE_BOOK_APPOINTMENT_URL = '/rest/book-appointment/mortgage';

export const INVESTMENT_OPEN_ACCOUNT_URL = 'https://wsign.wem.sk/#/registration';
export const INVESTMENT_OPEN_ACCOUNT_MANUAL_URL = '/files/investicny_ucet_moneyhoon.pdf';

export const LOGIN_URL = '/login';
export const LOGIN_URL_ALIASES = ['/prihlasenie'];

export const VERIFY_EMAIL_URL = '/verify-email';

export const REMOVED_USER_URL = '/removed-user';

export const RESET_PASSWORD_URL = '/reset-password';

export const REGISTER_URL = '/registration';
export const REGISTER_URL_ALIASES = ['/registracia'];

export const PRODUCTS_MANAGEMENT_BASE_URL = '/products-management';
export const PRODUCTS_MANAGEMENT_URL_ALIASES = ['/sprava-produktov'];

export const PRODUCTS_MANAGEMENT_ADD_PRODUCT_URL = '/products-management?select-product-type-dialog=open';

export const ACCOUNTS_MANAGEMENT_BASE_URL = '/accounts-management';
export const ACCOUNTS_MANAGEMENT_URL_ALIASES = ['/sprava-uctov'];

export const HOMEPAGE_LOGIN_REDIRECTS = [
  LOGIN_URL,
  REGISTER_URL,
  VERIFY_EMAIL_URL,
  REMOVED_USER_URL,
  RESET_PASSWORD_URL,
];

export const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];

export enum ProductFlows {
  MortgagePurchase = 'MortgagePurchase',
  MortgageRefinance = 'MortgageRefinance',
  LifeInsurancePurchase = 'LifeInsurancePurchase',
  LifeInsuranceAudit = 'LifeInsuranceAudit',
}

export enum EnvironmentEnum {
  PROD = 'prod',
  STAGING = 'staging',
  DEV = 'dev',
  LOCAL = 'local',
}

export enum BrandEnum {
  MONEYHOON = 'moneyhoon',
  SALVE = 'salve',
}

export enum GTMEventValueEnum {
  Login = 'Login',
  Registration = 'Registration',
  MortgagePurchaseInquiryCompleted = 'Mortgage purchase inquiry completed',
  MortgageRefinanceInquiryCompleted = 'Mortgage refinance inquiry completed',
  LifeInsurancePurchaseInquiryCompleted = 'Life insurance purchase inquiry completed',
  LifeInsuranceAuditInquiryCompleted = 'Life insurance audit inquiry completed',
  LifeInsuranceScoreCalculationCompleted = 'Life insurance score calculation completed',
  InvestmentInquiryCompleted = 'Investment inquiry completed',
  AccountConnectionCompleted = 'Account connection completed',
  MortgageMaximumCalculationCompleted = 'Mortgage maximum calculation completed',
}

export enum MortgageLiabilityType {
  Alimony = 'ALIMONY',
  ConsumerLoan = 'CONSUMER_LOAN',
  CreditCard = 'CREDIT_CARD',
  Lease = 'LEASE',
  Mortgage = 'MORTGAGE',
  OtherLoan = 'OTHER_LOAN',
  Overdraft = 'OVERDRAFT',
  ReconstructionLoan = 'RECONSTRUCTION_LOAN',
}

export enum LifeInsuranceLiabilityType {
  Alimony = 'ALIMONY',
  ConsumerLoan = 'CONSUMER_LOAN',
  Lease = 'LEASE',
  Mortgage = 'MORTGAGE',
  OtherLoan = 'OTHER_LOAN',
  ReconstructionLoan = 'RECONSTRUCTION_LOAN',
}

export enum LifeInsuranceIncomeType {
  Salary = 'SALARY',
  Entrepreneurship = 'ENTREPRENEURSHIP',
  WorkAgreement = 'WORK_AGREEMENT',
  Ltd = 'LTD',
  MaternityLeave = 'MATERNITY_LEAVE',
  Alimony = 'ALIMONY',
  ParentalAllowance = 'PARENTAL_ALLOWANCE',
  UnemploymentBenefit = 'UNEMPLOYMENT_BENEFIT',
  Other = 'OTHER',
}

export enum LifeInsuranceSports {
  football = 'FOOTBALL',
  hockey = 'HOCKEY',
  volleyball = 'VOLLEYBALL',
  fitness = 'FITNESS',
  cycling = 'CYCLING',
  skiing = 'SKIING',
  swimming = 'SWIMMING',
  running = 'RUNNING',
  figureSkating = 'FIGURE_SKATING',
  more = 'MORE',
}

export const CATEGORY_COLORS: Record<string, string> = {
  AUTO_AND_TRANSPORT: '#3892F3',
  EATING_OUT: '#F68511',
  ENTERTAINMENT: '#ED74ED',
  GROCERIES: '#87B103',
  HEALTH_AND_BEAUTY: '#EA3829',
  HOUSING_AND_UTILITIES: '#AE72F9',
  INCOME: '#007A4D',
  LOANS: '#5258E4',
  MISCELLANEOUS: '#909090',
  SAVINGS_AND_INVESTMENTS: '#0FB5AE',
  SHOPPING: '#F8D904',
  TRANSFERS: '#464646',
  TRAVEL: '#147AF3',
};
