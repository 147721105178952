import { Stack, Typography } from '@mui/material';
import { FormDatePicker } from 'common/form/components/FormDatePicker/FormDatePicker';
import { format, subDays } from 'date-fns';
import { FilterOperator } from 'generated/graphql';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Filter, FilterOption } from './Filter';
import { styles } from './styles';

export const useDateOptions = (): FilterOption[] => {
  const { t } = useTranslation();
  return [
    {
      label: t('filterTransactions.filter.date.yesterday.label'),
      value: {
        fieldPath: 'bookingDate',
        operator: FilterOperator.Equal,
        values: [format(subDays(Date.now(), 1), 'yyyy-MM-dd')],
      },
    },
    {
      label: t('filterTransactions.filter.date.lastWeek.label'),
      value: {
        fieldPath: 'bookingDate',
        operator: FilterOperator.GreaterThanOrEqual,
        values: [format(subDays(Date.now(), 7), 'yyyy-MM-dd')],
      },
    },
    {
      label: t('filterTransactions.filter.date.lastMonth.label'),
      value: {
        fieldPath: 'bookingDate',
        operator: FilterOperator.GreaterThanOrEqual,
        values: [format(subDays(Date.now(), 30), 'yyyy-MM-dd')],
      },
    },
  ].map((option) => ({
    key: option.label,
    label: option.label,
    value: {
      arguments: [{ filterCondition: option.value }],
    },
  }));
};

const NAME = 'date';

export const DateFilter: React.FC = () => {
  const { t } = useTranslation();
  const { field: manualField } = useController({ name: `${NAME}.manual` });

  const dateOptions = useDateOptions();

  const isManual = manualField.value != null;

  return (
    <Filter name={NAME} options={dateOptions} title={t('filterTransactions.filter.date.title')}>
      {isManual && (
        <Stack direction="row" pr={4} spacing={3}>
          <Stack alignItems="start" direction="row" spacing={1}>
            <Typography sx={styles.label} variant="bodyL">
              {t('filterTransactions.filter.date.from.label')}
            </Typography>
            <FormDatePicker name={`${NAME}.manual.from`} />
          </Stack>
          <Stack alignItems="start" direction="row" spacing={1}>
            <Typography sx={styles.label} variant="bodyL">
              {t('filterTransactions.filter.date.to.label')}
            </Typography>
            <FormDatePicker name={`${NAME}.manual.to`} />
          </Stack>
        </Stack>
      )}
    </Filter>
  );
};
