import { FetchResult } from '@apollo/client';
import { useNotification } from 'common/hooks/useNotification';
import { InitNordigenFlowMutation, useInitNordigenFlowMutation } from 'generated/graphql';
import { TFunction, useTranslation } from 'react-i18next';

export const ADD_ACCOUNT_REDIRECT_SEARCH_PARAM_KEY = 'addAccountRedirect';
export const ADD_ACCOUNT_REDIRECT_SEARCH_PARAM_VALUE = 'true';

export const getRedirectUrl = (): string => {
  const url = new URL(
    `${window.location.origin}?gtmEvent=AccountConnectionCompleted&nordigenFlowStatus=${NordigenFlowStatus.Finished}`,
  );
  // we redirected user back to homepage without any side dialog opened
  // TODO: what in case of consent prolongation?
  return url.href;
};

interface InitNordigenFlowResult {
  loading: boolean;
}

export const useInitNordigenFlow = (): [
  (
    institutionId: string,
  ) => Promise<FetchResult<InitNordigenFlowMutation, Record<string, unknown>, Record<string, unknown>>>,
  InitNordigenFlowResult,
] => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [initNordigenFlow, { loading }] = useInitNordigenFlowMutation({
    onError: () => {
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
  });

  const redirectUrl = getRedirectUrl();

  return [
    async (institutionId) => {
      const resp = await initNordigenFlow({ variables: { institutionId, redirectUrl } });
      if (resp?.data?.link) {
        window.location.assign(resp.data.link);
      }
      return resp;
    },
    {
      loading,
    },
  ];
};

export enum NordigenFlowStatus {
  Finished = 'Finished',
}

export enum NordigenFlowError {
  InstitutionTechnicalError = 'InstitutionTechnicalError',
  NordigenTechnicalError = 'NordigenTechnicalError',
  UserCancelledSession = 'UserCancelledSession',
  UserSessionRejected = 'UserSessionRejected',
  UnknownError = 'UnknownError',
}

export const convertJobStatusErrorMessage = (
  errorMessage: string | undefined | null,
  t: TFunction<'translation', undefined>,
): string => {
  if (errorMessage?.trim() == 'Client error : 429') {
    return t('homepage.accounts.errors.tooManyRequests');
  }

  if (errorMessage?.trim() == 'Server error : 500') {
    return t('homepage.accounts.errors.thirdPartyError');
  }

  if (errorMessage?.includes('Invalid nordigen connection')) {
    return t('homepage.accounts.errors.invalidConnectionError');
  }

  return t('common:somethingWentWrong');
};
