import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  'asteriskImage' | 'header' | 'button' | 'content' | 'asteriskContainer' | 'title',
  SxProps<Theme>
> = {
  asteriskContainer: {
    alignItems: 'center',
    // color: (theme) => theme.palette.gray[300],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(3),
    position: 'relative',
  },
  asteriskImage: {
    color: (theme) => theme.palette.gray[300],
  },
  button: {
    marginTop: 3,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    overflowX: 'hidden',
    padding: 16,
    textAlign: 'center',
  },
  header: { paddingTop: 4, position: 'fixed', top: 0, zIndex: 10 },
  title: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: 64,
    fontWeight: 700,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
