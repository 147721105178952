import { SxProps, Theme } from '@mui/material';

export const styles: Record<'page' | 'main', SxProps<Theme>> = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
  },
  page: {
    backgroundColor: (theme) => theme.palette.gray[100],
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    minWidth: '100%',
    position: 'relative',
    scrollbarWidth: 'thin',
  },
};
