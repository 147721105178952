import { useNotification } from 'common/hooks/useNotification';
import {
  FinancialProductType,
  useCreateBuildingSavingsProductMutation,
  useCreateCollisionInsuranceProductMutation,
  useCreateCreditCardProductMutation,
  useCreateGenericInsuranceProductMutation,
  useCreateGenericProductMutation,
  useCreateInvestmentProductMutation,
  useCreateLifeInsuranceProductMutation,
  useCreateLoanProductMutation,
  useCreateMortgageProductMutation,
  useCreateMotorLiabilityInsuranceProductMutation,
  useCreatePropertyInsuranceProductMutation,
  useCreateRetirementPensionSavingsProductMutation,
  useCreateSavingsProductMutation,
  useCreateSupplementaryPensionSavingsProductMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

type CreateProductMutation =
  | ReturnType<typeof useCreateBuildingSavingsProductMutation>
  | ReturnType<typeof useCreateCollisionInsuranceProductMutation>
  | ReturnType<typeof useCreateCreditCardProductMutation>
  | ReturnType<typeof useCreateGenericInsuranceProductMutation>
  | ReturnType<typeof useCreateGenericProductMutation>
  | ReturnType<typeof useCreateInvestmentProductMutation>
  | ReturnType<typeof useCreateLifeInsuranceProductMutation>
  | ReturnType<typeof useCreateLoanProductMutation>
  | ReturnType<typeof useCreateMortgageProductMutation>
  | ReturnType<typeof useCreateMotorLiabilityInsuranceProductMutation>
  | ReturnType<typeof useCreatePropertyInsuranceProductMutation>
  | ReturnType<typeof useCreateRetirementPensionSavingsProductMutation>
  | ReturnType<typeof useCreateSavingsProductMutation>
  | ReturnType<typeof useCreateSupplementaryPensionSavingsProductMutation>;

export const useCreateProduct = (type: FinancialProductType): CreateProductMutation => {
  const { t } = useTranslation();
  const notify = useNotification();

  const mutation = {
    [FinancialProductType.BuildingSavings]: useCreateBuildingSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['BuildingSavingsProducts'],
    }),
    [FinancialProductType.CollisionInsurance]: useCreateCollisionInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CollisionInsuranceProducts'],
    }),
    [FinancialProductType.CreditCard]: useCreateCreditCardProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['CreditCardProducts'],
    }),
    [FinancialProductType.Generic]: useCreateGenericProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericProducts'],
    }),
    [FinancialProductType.GenericInsurance]: useCreateGenericInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['GenericInsuranceProducts'],
    }),
    [FinancialProductType.Investment]: useCreateInvestmentProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['InvestmentProducts'],
    }),
    [FinancialProductType.LifeInsurance]: useCreateLifeInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LifeInsuranceProducts'],
    }),
    [FinancialProductType.Loan]: useCreateLoanProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['LoanProducts'],
    }),
    [FinancialProductType.Mortgage]: useCreateMortgageProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MortgageProducts'],
    }),
    [FinancialProductType.MotorLiabilityInsurance]: useCreateMotorLiabilityInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['MotorLiabilityInsuranceProducts'],
    }),
    [FinancialProductType.PropertyInsurance]: useCreatePropertyInsuranceProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['PropertyInsuranceProducts'],
    }),
    [FinancialProductType.RetirementPensionSavings]: useCreateRetirementPensionSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['RetirementPensionSavingsProducts'],
    }),
    [FinancialProductType.Savings]: useCreateSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SavingsProducts'],
    }),
    [FinancialProductType.SupplementaryPensionSavings]: useCreateSupplementaryPensionSavingsProductMutation({
      onError: () => {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      },
      refetchQueries: ['SupplementaryPensionSavingsProducts'],
    }),
  }[type];

  return mutation;
};
