import { dateParser } from 'admin/formatUtils';
import { ClientInput } from 'admin/inputs/ClientInput';
import { ExpectedAmountInput } from 'admin/inputs/ExpectedAmountInput';
import { InstitutionInput } from 'admin/inputs/InstitutionInput';
import {
  ProductStatus,
  PropertyInsuranceInsuranceType,
  PropertyInsuranceInsuredPropertyType,
  TransactionGroupPeriodTypeCode,
} from 'generated/graphql';
import {
  ArrayInput,
  Create,
  DateInput,
  FormTab,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

// TODO: generalize components

export const PropertyInsuranceProductCreate: React.FC = () => (
  <Create>
    <TabbedForm>
      <FormTab label="Základné info">
        <ClientInput label="Klient" source="clientId" />
        <InstitutionInput source="institutionId" />
        <TextInput label="Vlastná inštitúcia" source="institutionName" sx={{ width: '40em' }} />
        <TextInput label="Názov produktu" source="name" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Zmluva">
        <TextInput label="Číslo zmluvy" source="contractNumber" sx={{ width: '40em' }} />
        <DateInput label="Začiatok poistenia" parse={dateParser} source="contractSignDate" />
        <DateInput label="Platnosť poistenia do" parse={dateParser} source="contractEndDate" />
      </FormTab>

      <FormTab label="Platby">
        <ExpectedAmountInput label="Suma poistného" source="paymentPeriod.expectedAmount.amount" />
        <TextInput source="paymentPeriod.expectedAmount.currency" style={{ display: 'none' }} value="EUR" />
        <NumberInput label="Opakovať každých" source="paymentPeriod.periodCount" />
        <SelectInput
          choices={[
            { id: TransactionGroupPeriodTypeCode.Week, name: 'týždňov' },
            { id: TransactionGroupPeriodTypeCode.Month, name: 'mesiacov' },
          ]}
          label=""
          source="paymentPeriod.periodTypeCode"
        />
        <DateInput label="Nasledujúca platba" parse={dateParser} source="paymentPeriod.expectedDate" />
      </FormTab>

      <FormTab label="Údaje pre platbu">
        <TextInput label="Variabilný symbol" source="paymentInfo.variableSymbol" sx={{ width: '40em' }} />
        <TextInput label="Špecifický symbol" source="paymentInfo.specificSymbol" sx={{ width: '40em' }} />
        <TextInput label="Konštantný symbol" source="paymentInfo.constantSymbol" sx={{ width: '40em' }} />
        <TextInput label="IBAN" source="paymentInfo.ibanForPayment" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Poistená nehnuteľnosť">
        <SelectInput
          choices={[
            { id: PropertyInsuranceInsuredPropertyType.House, name: 'Dom' },
            { id: PropertyInsuranceInsuredPropertyType.Apartment, name: 'Byt' },
            { id: PropertyInsuranceInsuredPropertyType.RecreationalFacility, name: 'Rekreačný objekt' },
          ]}
          label="Typ"
          source="insuredProperty.type"
        />
        <TextInput label="Adresa" source="insuredProperty.address" sx={{ width: '40em' }} />
      </FormTab>

      <FormTab label="Základné poistenia">
        <ArrayInput label="Základné poistenia" source="basicInsurances">
          <SimpleFormIterator inline>
            <SelectInput
              choices={[
                { id: PropertyInsuranceInsuranceType.Property, name: 'Poistenie nehnuteľnosti' },
                { id: PropertyInsuranceInsuranceType.Household, name: 'Poistenie domácnosti' },
              ]}
              helperText={false}
              label="Typ"
              source="type"
            />
            <NumberInput helperText={false} label="Poistná suma" source="insuredSum.amount" />
            <NumberInput helperText={false} label="Poistné" source="annualCost.amount" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Pripoistenia">
        <ArrayInput label="Pripoistenia" source="otherInsurances">
          <SimpleFormIterator inline>
            <TextInput helperText={false} label="Názov pripoistenia" source="name" sx={{ width: '40em' }} />
            <NumberInput helperText={false} label="Poistná suma" source="insuredSum.amount" />
            <NumberInput helperText={false} label="Poistné" source="annualCost.amount" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Poistník">
        <TextInput label="Meno" source="insuringPerson.name" />
        <TextInput label="Meno" source="insuringPerson.surname" />
      </FormTab>

      <FormTab label="Status">
        <SelectInput
          choices={[{ id: ProductStatus.Processing, name: 'Processing' }]}
          label="Status"
          required
          source="status"
        />
      </FormTab>
    </TabbedForm>
  </Create>
);
