import { SxProps, Theme } from '@mui/material';

export const styles: Record<'row' | 'clickableItem' | 'container', SxProps<Theme>> = {
  clickableItem: {
    '&:hover': {
      background: (theme) => theme.palette.gray['100'],
    },
    alignItems: 'flex-end',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2, 2),
  },
  container: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
  },
  row: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(2, 2),
  },
};
