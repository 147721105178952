import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { LightTooltip } from 'common/components/LightTooltip/LightTooltip';
import { useDialog } from 'common/hooks/useDialog';
import { formatIban } from 'common/utils/formatUtils';
import { useTranslation } from 'react-i18next';

import { AccountDetailDialog, AccountDetailDialogProps } from '../AccountDetailDialog/AccountDetailDialog';
import { styles } from './AccountListItem.styles';

export interface AccountListItemProps {
  id: string;
  currency: string;
  name: string;
  iban: string;
  colorIndex: number;
  consentExpired: boolean;
}

export const AccountListItem: React.FC<AccountListItemProps> = (account) => {
  const { t } = useTranslation();

  const accountDetailsDialog = useDialog<AccountDetailDialogProps, void>(AccountDetailDialog, {
    id: account.id,
    instanceId: `account-detail-${account.id}`,
    navigable: true,
  });

  const handleAccountListItemClick = async () => {
    await accountDetailsDialog.create({ id: account.id });
  };

  return (
    <Box onClick={handleAccountListItemClick} sx={styles.container}>
      <Stack direction="row">
        <Box alignItems="center" display="flex">
          <AccountCard colorIndex={account.colorIndex} height={29} width={48} />
        </Box>

        <Box ml={2} width="100%">
          <Stack direction="column">
            <Stack direction="row" justifyContent="space-between">
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography variant="headlineXS">{account.name}</Typography>
                {account.consentExpired && (
                  <LightTooltip placement="top" title={t('homepage.accounts.consentExpiredTooltip')}>
                    <WarningIcon />
                  </LightTooltip>
                )}
              </Stack>

              <ChevronRightIcon />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography sx={styles.label} variant="bodyM">
                {formatIban(account.iban)}
              </Typography>
              <Typography sx={styles.label} variant="bodyM">
                {account.currency}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
