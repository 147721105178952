import { withDefaultCurrency } from 'common/utils/formatUtils';
import { LifeInsuranceProductFragment, LifeInsuranceProductInput, ProductStatus } from 'generated/graphql';
import { TFunction } from 'react-i18next';

import { ProductBase } from '../types';

export const getLifeInsuranceDefaultSupplementalInsurances = (t: TFunction<'translation', undefined>): string[] => [
  t('products:lifeInsurance.detail.applicants.insurance.defaultSupplementalInsurances.death'),
  t('products:lifeInsurance.detail.applicants.insurance.defaultSupplementalInsurances.permanentInjuryConsequences'),
  t('products:lifeInsurance.detail.applicants.insurance.defaultSupplementalInsurances.criticalIllnesses'),
  t('products:lifeInsurance.detail.applicants.insurance.defaultSupplementalInsurances.disability'),
];

export interface LifeInsuranceProductDetailType extends ProductBase {
  contractSignDate?: Date | null;
  contractEndDate?: Date | null;
  applicants: Array<{
    id: string;
    name: string | null;
    surname?: string | null;
    insurance?: {
      supplementalInsurances: {
        name: string;
        annualCost: {
          amount: number;
          currency: string;
        };
        insuredSum: {
          amount: number;
          currency: string;
        };
      }[];
    } | null;
  }>;
  insurer: Array<{
    id: string;
    name: string;
    surname?: string | null;
  }>;
}

export type MapLifeInsuranceProductToInput = (product?: LifeInsuranceProductDetailType) => LifeInsuranceProductInput;

export const mapLifeInsuranceProductToInput: MapLifeInsuranceProductToInput = (
  product = {
    applicants: [],
    documents: [],
    enableFinancialAdvisorCare: true,
    enableNotifications: true,
    id: '',
    institution: {
      abbreviation: '',
      id: '',
      logoUrl: '',
      name: '',
      possibleAispNordigenConnection: false,
      smallLogoUrl: '',
      wideLogoUrl: '',
    },
    insurer: [],
    status: ProductStatus.Active,
    statusChangeDate: new Date(),
  },
) => {
  return {
    applicants: (
      product.applicants?.map((applicant) => ({
        insurance: {
          deathInsuranceAnnualCost: {
            amount: 0,
            currency: 'EUR',
          },
          deathInsuranceInsuredSum: {
            amount: 0,
            currency: 'EUR',
          },
          supplementalInsurances:
            applicant.insurance?.supplementalInsurances.map((supplementalInsurance) => ({
              annualCost: {
                amount: supplementalInsurance.annualCost?.amount ?? 0,
                currency: supplementalInsurance.annualCost?.currency ?? 'EUR',
              },
              insuredSum: {
                amount: supplementalInsurance.insuredSum?.amount ?? 0,
                currency: supplementalInsurance.insuredSum?.currency ?? 'EUR',
              },
              name: supplementalInsurance.name ?? '',
            })) ?? [],
        },
        isInsured: true,
        isInsuring: false,
        name: applicant.name ?? '',
        surname: applicant.surname ?? '',
      })) ?? []
    ).concat(
      product.insurer?.[0]
        ? [
            {
              insurance: {
                deathInsuranceAnnualCost: {
                  amount: 0,
                  currency: 'EUR',
                },
                deathInsuranceInsuredSum: {
                  amount: 0,
                  currency: 'EUR',
                },
                supplementalInsurances: [],
              },
              isInsured: false,
              isInsuring: true,
              name: product.insurer[0].name ?? '',
              surname: product.insurer[0].surname ?? '',
            },
          ]
        : [],
    ),
    contractEndDate: product.contractEndDate,
    contractNumber: product.contractNumber,
    contractSignDate: product.contractSignDate,
    enableFinancialAdvisorCare: product.enableFinancialAdvisorCare,
    enableNotifications: product.enableNotifications,
    institutionId: product.institution?.id,
    institutionName: product.institutionName,
    name: product.name,
    paymentInfo: {
      constantSymbol: product.paymentInfo?.constantSymbol ?? '',
      ibanForPayment: product.paymentInfo?.ibanForPayment ?? '',
      specificSymbol: product.paymentInfo?.specificSymbol ?? '',
      variableSymbol: product.paymentInfo?.variableSymbol ?? '',
    },
    paymentPeriod: {
      expectedAmount: withDefaultCurrency(product.paymentPeriod?.expectedAmount),
      periodCount: product.paymentPeriod?.periodCount,
      periodTypeCode: product.paymentPeriod?.periodTypeCode,
      referenceDate: product.paymentPeriod?.expectedDate,
    },
    status: product.status,
    statusChangeDate: product.statusChangeDate,
  };
};

export const mapLifeInsuranceProductToDetail = (
  product?: LifeInsuranceProductFragment,
): LifeInsuranceProductDetailType => {
  const insurer = product?.applicants.find((applicant) => applicant.isInsuring);
  return {
    applicants:
      product?.applicants
        ?.filter((applicant) => !!applicant.isInsured)
        .map((applicant) => ({
          id: applicant.id,
          insurance: {
            supplementalInsurances:
              applicant.insurance?.supplementalInsurances.map((supplementalInsurance) => ({
                annualCost: {
                  amount: supplementalInsurance.annualCost.amount,
                  currency: supplementalInsurance.annualCost.currency,
                },
                insuredSum: {
                  amount: supplementalInsurance.insuredSum.amount,
                  currency: supplementalInsurance.insuredSum.currency,
                },
                name: supplementalInsurance.name,
              })) ?? [],
          },
          name: applicant.name,
          surname: applicant.surname,
        })) ?? [],
    contractEndDate: product?.contractEndDate,
    contractNumber: product?.contractNumber,
    contractSignDate: product?.contractSignDate,
    documents: product?.documents ?? [],
    enableFinancialAdvisorCare: product?.enableFinancialAdvisorCare ?? true,
    enableNotifications: product?.enableNotifications ?? true,
    id: product?.id ?? '',
    institution: product?.institution,
    institutionName: product?.institutionName ?? '',
    insurer: insurer ? [insurer] : [],
    name: product?.name,
    paymentInfo: product?.paymentInfo,
    paymentPeriod: product?.paymentPeriod,
    status: product?.status ?? ProductStatus.Active,
    statusChangeDate: product?.statusChangeDate ?? new Date(),
  };
};
