import { ChevronRight } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { Path } from 'react-hook-form';

import { EditListItemDialog, EditListItemProps } from '../EditListItemDialog/EditListItemDialog';
import { DetailRowType, ExpandedListType } from '../types';
import { styles } from './DetailList.styles';

interface Props<T> {
  data: T;
  path: string;
  label: string;
  removeLabel: string;
  formatter: (value: T) => string;
  fields: Array<{ [K in Path<T>]: DetailRowType<T, K> | ExpandedListType<T, K> }[Path<T>]>;
  onUpdate: <K extends Path<T>>(value: PathValue<T, K>, path: string) => Promise<void>;
  onRemove: () => void;
  updatedAt?: Date;
}

export const DetailListItem = <T,>({
  data,
  fields,
  formatter,
  label,
  onRemove,
  onUpdate,
  path,
  removeLabel,
  updatedAt,
}: Props<T>): React.ReactElement => {
  const editListItemDialog = useDialog<EditListItemProps<T>, void, void>(EditListItemDialog, {
    data,
    fields,
    instanceId: `edit-list-item-${path}`,
    key: updatedAt?.toISOString(),
    onRemove,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate: (value, fieldPath) => onUpdate(value as any, `${path}${fieldPath}`),
    removeLabel,
    title: label,
    tmpNavigable: true,
    updatedAt,
  });

  const handleUpdate = async () => {
    await editListItemDialog.create({
      data,
      fields,
      onRemove,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onUpdate: (value, fieldPath) => onUpdate(value as any, `${path}${fieldPath}`),
      removeLabel,
      title: label,
      tmpNavigable: true,
      updatedAt,
    });
  };

  return (
    <Box onClick={handleUpdate} sx={styles.clickableRow}>
      <Stack direction="row" flexGrow={1} justifyContent="space-between">
        <Typography variant="bodyL">{formatter?.(data) ?? '-'}</Typography>
      </Stack>
      <ChevronRight sx={styles.icon} />
    </Box>
  );
};
