import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PromptDialogOnSide } from 'common/components/PromptDialogOnSide/PromptDialogOnSide';
import { styles } from 'common/components/PromptDialogOnSide/PromptDialogOnSide.styles';
import { FormInput } from 'common/form/components/FormInput';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface FormValues {
  institutionName: string;
}

export interface AddCustomProviderDialogProps extends DialogComponentProps<void> {
  institutionName?: string;
  onSubmit: (institutionName: string) => Promise<void>;
}

export const AddCustomProviderDialog: React.FC<AddCustomProviderDialogProps> = ({
  institutionName,
  isOpen,
  onResolve,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    institutionName: yup.string().nullable(false).required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      institutionName,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    onSubmit(data.institutionName);
    onResolve();
  });

  return (
    <PromptDialogOnSide
      onClose={() => onResolve()}
      open={isOpen}
      subtitle={t('products:selectProvider.addCustomProvider.subtitle')}
      title={t('products:selectProvider.addCustomProvider.title')}
    >
      <FormProvider {...formMethods}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box>
            <FormInput autoComplete="institutionName" autoFocus fullWidth name="institutionName" type="text" />
          </Box>

          <Stack mt={3} spacing={1}>
            <Button
              aria-label={t('products:selectProvider.addCustomProvider.addLabel')}
              color="primary"
              sx={styles.button}
              type="submit"
            >
              <Typography color="white" variant="bodyL">
                {t('products:selectProvider.addCustomProvider.addLabel')}
              </Typography>
            </Button>
            <Button color="tertiaryButton" onClick={() => onResolve()} sx={styles.button}>
              <Typography color="blue.dark" variant="bodyL">
                {t('common:cancel')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </PromptDialogOnSide>
  );
};
