import { SxProps, Theme } from '@mui/material';

export const styles: Record<'blueIcon', SxProps<Theme>> = {
  blueIcon: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.blue.dark,
    borderRadius: '8px',
    color: (theme) => theme.palette.gray[50],
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    minWidth: '48px',
    svg: {
      height: 20,
      width: 20,
    },
    width: '48px',
  },
};
