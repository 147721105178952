import { Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { useDialog } from 'common/hooks/useDialog';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { CategoryIcon } from 'components/transactions/CategoryIcon/CategoryIcon';
import {
  Account,
  TransactionAggregationValueFragment,
  TransactionCategoryFragment,
  TransactionTag,
} from 'generated/graphql';
import { i18n } from 'i18n';
import { useTranslation } from 'react-i18next';

import { SpendingReportDialog, SpendingReportDialogProps } from '../SpendingReportDialog';
import { PeriodTypeEnum } from '../state/spendingReportTypes';
import { useSpendingReportState } from '../state/useSpendingReportState';
import { TagIcon } from '../TagIcon/TagIcon';
import { formatPeriodTitle, getPeriod } from '../utils';
import { styles } from './AggregateListItem.styles';

export interface AggregateListItemProps {
  total?: number;
  aggregate: TransactionAggregationValueFragment;
  pointerDate: Date;
  accountId?: string;
  categoryId?: string;
  tagId?: string;
  lockedPeriodType?: PeriodTypeEnum;
}

export const AggregateListItem: React.FC<AggregateListItemProps> = (props) => {
  const { accountId, aggregate, categoryId, pointerDate, tagId, total } = props;
  const { state } = useSpendingReportState();
  const periodType = props.lockedPeriodType ?? state.params.periodType;

  const categoryKey = aggregate.key.find((key) => key.__typename === 'TransactionCategory') as
    | TransactionCategoryFragment
    | undefined;
  const tagKey = aggregate.key.find((key) => key.__typename === 'TransactionTag') as TransactionTag | undefined;
  const accountKey = aggregate.key.find((key) => key.__typename === 'Account') as Account | undefined;

  const label = categoryKey?.categoryGroup.name ?? tagKey?.name ?? accountKey?.name;
  const { t } = useTranslation();

  const period = getPeriod(periodType, pointerDate);

  const detailDialog = useDialog<SpendingReportDialogProps, void>(SpendingReportDialog, {
    accountId: accountKey?.id ?? accountId,
    categoryId: categoryKey?.id ?? categoryId,
    chartTitle: formatPeriodTitle(period, pointerDate),
    instanceId: `spending-report-detail-${accountKey?.id ?? accountId ?? ''}-${categoryKey?.id ?? categoryId ?? ''}-${
      tagKey?.id ?? tagId ?? ''
    }`,
    lockedPeriodType: props.lockedPeriodType,
    navigable: true,
    pointerDate,
    tagId: tagKey?.id ?? tagId,
  });

  const handleClick = () => {
    detailDialog.create({});
  };

  return (
    <Stack direction="row" justifyContent="space-between" onClick={handleClick} sx={styles.container}>
      <Stack direction="row" spacing={2}>
        {categoryKey && <CategoryIcon category={categoryKey} />}
        {tagKey && <TagIcon />}
        {accountKey && <AccountCard colorIndex={accountKey.colorIndex} height={48} width={48} />}
        <Stack>
          <Typography variant="bodyStrongL">{label}</Typography>
          <Typography color="textSecondary" variant="bodyM">
            {t('spendingReport.common.transactionsCount', { count: aggregate.count })}
          </Typography>
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <Stack textAlign="right">
          <Typography color={aggregate.sum.amount > 0 ? 'success.main' : undefined} variant="bodyL">
            {formatTransactionAmount(aggregate.sum.amount, i18n.language, 2, aggregate.sum.currency)}
          </Typography>
          {aggregate.sum.amount !== 0 && (
            <Typography color="textSecondary" variant="bodyM">
              {Math.round((100 * aggregate.sum.amount) / (total ?? aggregate.sum.amount))}%
            </Typography>
          )}
        </Stack>
        <ChevronRightIcon />
      </Stack>
    </Stack>
  );
};
