import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactNode } from 'react';

import { styles } from './ProductCard.styles';

export interface ProductCardProps {
  title: string;
  subtitle: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  productsDetected?: number;
  loading?: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  icon,
  loading,
  onClick,
  productsDetected,
  subtitle,
  title,
}) => {
  if (loading) {
    return <Skeleton height={94} variant="rectangular" />;
  }

  return (
    <Stack onClick={onClick} sx={styles.card}>
      <Stack direction="row" gap={2}>
        {icon ?? <Box sx={styles.icon} />}
        <Stack>
          <Typography variant="bodyStrongL">{title}</Typography>
          <Typography color="text.secondary" variant="bodyM">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={0.5}>
        {productsDetected && (
          <Box sx={styles.productsDetected}>
            <Typography color="primary.contrastText" variant="bodyStrongXS">
              {productsDetected}
            </Typography>
          </Box>
        )}
        <ChevronRightIcon />
      </Stack>
    </Stack>
  );
};
