import { Box, Button, Typography } from '@mui/material';
import { useDialog } from 'common/hooks/useDialog';
import { AllTransactions, AllTransactionsProps } from 'components/transactions/AllTransactions/AllTransactions';
import { TransactionList } from 'components/transactions/TransactionList/TransactionList';
import { useTranslation } from 'react-i18next';

import { styles } from './RecentTransactions.styles';

export const RecentTransactions: React.FC = () => {
  const { t } = useTranslation();

  const allTransactionsDialog = useDialog<AllTransactionsProps, void>(AllTransactions, {
    instanceId: 'all-transactions',
    navigable: true,
  });

  const handleViewAll: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.currentTarget?.blur();
    allTransactionsDialog.create({});
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="headlineL">{t('homepage.transactions.title')}</Typography>
        <Button color="tertiaryButton" onClick={handleViewAll}>
          <Typography color="blue.dark">{t('homepage.transactions.seeAll')}</Typography>
        </Button>
      </Box>
      <TransactionList count={3} />
    </Box>
  );
};
