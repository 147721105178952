import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CalendarEventIcon } from 'assets/icons/CalendarEvent.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { CalendarEventInstanceFragment, CalendarEventType, FinancialProductType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { FinancialCalendarEventTypeTranslations } from 'translations/enums';

import { useProductDetail } from '../useProductDetail';
import { EventDetailDialog, EventDetailDialogProps } from './EventDetailDialog/EventDetailDialog';
import { styles } from './EventListItem.styles';

export interface EventItemProps {
  event: CalendarEventInstanceFragment;
}

export const EventListItem: React.FC<EventItemProps> = ({ event }) => {
  const { i18n, t } = useTranslation();
  const notify = useNotification();

  const eventDetailDialog = useDialog<EventDetailDialogProps, void>(EventDetailDialog, {
    id: event.originalEventId,
    instanceId: `edit-event-${event.originalEventId}`,
    navigable: true,
  });

  const productDetail = useProductDetail(event.productType ?? FinancialProductType.Generic, {
    id: event.productId ?? '',
    instanceId: `${event.productType}-detail-${event.productId}`,
    navigable: true,
  });

  const handleEventListItemClick = async () => {
    if (event.type == CalendarEventType.UserDefined) {
      // for event defined by user show dialog with event details
      await eventDetailDialog.create({ id: event.originalEventId });
    } else {
      // for automatically created events show dialog with product details (we should have productId and productType for such)
      if (!!event.productId && !!event.productType) {
        await productDetail.create({ id: event.productId });
      } else {
        notify({ message: t('common:somethingWentWrong'), type: 'error' });
      }
    }
  };

  return (
    <Box onClick={handleEventListItemClick} sx={styles.container}>
      <Stack direction="row">
        {!!event.institution?.name || !!event.institutionName ? (
          <InstitutionIcon
            alt={event.institution?.name ?? event.institutionName ?? '?'}
            src={event.institution?.logoUrl ?? undefined}
            title={event.institution?.name ?? event.institutionName ?? '?'}
            width={48}
          />
        ) : (
          <CalendarEventIcon height={48} width={48} />
        )}

        <Box ml={2} width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="bodyStrongL">
                {event.productType
                  ? FinancialCalendarEventTypeTranslations(t)[event.productType][event.type]
                  : event.name}
              </Typography>
              <Typography>{event.institution?.name ?? event.institutionName}</Typography>
            </Stack>

            <Stack direction="row">
              {event.amount && (
                <Typography pr={0.5} variant="bodyL">
                  {formatTransactionAmount(event.amount?.amount, i18n.language, 2, event.amount?.currency)}
                </Typography>
              )}
              <ChevronRightIcon />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
