import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { Account } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  accounts?: Account[];
}

export const AccountStatsCard: React.FC<Props> = ({ accounts, isLoading }) => {
  const validAccounts = accounts?.filter((account) => !account.consentExpired);

  const lastWeekTimestamp = new Date();
  lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);

  const validAccountsLastWeek = accounts?.filter(
    (account) => account.creationTimestamp >= lastWeekTimestamp && !account.consentExpired,
  );

  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">
          {`${validAccounts?.length} pripojených účtov`}
        </Typography>
        <Typography color="text.secondary">{`+${validAccountsLastWeek?.length} za posledných 7 dní`}</Typography>
      </CardContent>
    </Card>
  );
};
