import { Box, Stack, Typography } from '@mui/material';
import {
  ConfirmDialogOnSide,
  ConfirmDialogOnSideProps,
} from 'common/components/ConfirmDialogOnSide/ConfirmDialogOnSide';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import _ from 'lodash';
import { ReactElement } from 'react';
import { Path } from 'react-hook-form';

import { ButtonRow } from '../ButtonRow/ButtonRow';
import { DetailRow } from '../DetailRow/DetailRow';
import { ExpandedList } from '../ExpandedList/ExpandedList';
import { DetailRowType, ExpandedListType, isExpandedList } from '../types';
import { styles } from './EditListItemDialog.styles';

export interface EditListItemProps<T> extends DialogComponentProps<void> {
  title: string;
  fields: Array<{ [K in Path<T>]: DetailRowType<T, K> | ExpandedListType<T, K> }[Path<T>]>;
  data: T;
  onUpdate: (value: PathValue<T, Path<T>>, path: string) => Promise<void>;
  onRemove: () => void;
  removeLabel: string;
  updatedAt?: Date;
}

const EditListItemContent = <T,>({
  data,
  fields,
  onRemove,
  onResolve,
  onUpdate,
  removeLabel,
  updatedAt,
}: EditListItemProps<T>): ReactElement => {
  const deleteProductItemDialog = useDialog<ConfirmDialogOnSideProps, boolean>(ConfirmDialogOnSide, {
    instanceId: 'delete-product-item',
  });

  const handleRemove = async () => {
    const deleted = await deleteProductItemDialog.create({
      confirmButtonText: removeLabel,
      onConfirm: async () => {
        await onRemove();
      },
      title: removeLabel,
    });
    if (deleted) {
      await onResolve();
    }
  };

  return (
    <Stack>
      <Stack sx={styles.container}>
        {fields.map((field) => {
          return (
            !isExpandedList(field) && (
              <DetailRow<PathValue<T, Path<T>>>
                key={field.path}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatter={field.formatter as any}
                helperText={field.helperText}
                inputEndAdornment={field.inputEndAdornment}
                inputType={field.inputType}
                label={field.label}
                onUpdate={onUpdate}
                options={field.options}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                parse={field.parse as any}
                path={field.path}
                tooltip={field.tooltip}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                transform={field.transform as any}
                updatedAt={updatedAt}
                value={field.path ? _.get(data, field.path) : data}
              />
            )
          );
        })}
      </Stack>

      {fields.map((field) => {
        return (
          isExpandedList(field) && (
            <ExpandedList
              key={field.path}
              addLabel={field.addLabel}
              data={_.get(data, field.path) ?? []}
              fields={field.fields}
              labelPath={field.labelPath}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              maxLength={field.maxLength}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onUpdate={onUpdate as any}
              path={field.path}
              removeLabel={field.removeLabel}
              updatedAt={updatedAt}
            />
          )
        );
      })}

      <Box mt={3}>
        <ButtonRow color="red.dark" label={removeLabel} onClick={handleRemove} />
      </Box>
    </Stack>
  );
};

export const EditListItemDialog = <T,>(props: EditListItemProps<T>): ReactElement => (
  <ResponsiveSideDialog
    dialogId={props.instanceId}
    header={<Typography variant="headlineM">{props.title}</Typography>}
    isOpen={props.isOpen}
    onClose={props.onResolve}
  >
    <EditListItemContent {...props} />
  </ResponsiveSideDialog>
);
