import ProfileImage from '@brand/assets/images/profile.svg';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { Image } from 'common/components/Image/Image';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { ConnectedAccountsDialog } from 'components/accounts/ConnectedAccountsDialog/ConnectedAccountsDialog';
import { useConnectedAccountsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './Dashboard.styles';

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);
  const { data, loading, refetch } = useConnectedAccountsQuery({
    skip: !isAuthenticated,
  });

  const connectedAccountsDialog = useDialog<DialogComponentProps<void>, void>(ConnectedAccountsDialog, {
    instanceId: 'connected-accounts',
    navigable: true,
  });

  const handleProfileClick = async () => {
    await connectedAccountsDialog.create({});
    await refetch();
  };

  if (loading || isAuthLoading) {
    return (
      <Box sx={styles.box}>
        <Stack spacing={1}>
          <Skeleton height={36} variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Stack>
      </Box>
    );
  }

  return (
    <Box onClick={handleProfileClick} sx={styles.box}>
      <Stack spacing={3}>
        <Image src={ProfileImage} sx={styles.profileImage} />
        <Typography variant="headlineXL">{t('homepage.dashboard.profile.welcome')}</Typography>

        <Stack direction="row" spacing={1}>
          <Stack alignItems="center" direction="row" spacing={-1}>
            {data?.accounts?.items.map((account) => (
              <AccountCard key={account.id} colorIndex={account.colorIndex} height={12} width={20} />
            ))}
          </Stack>
          <Typography variant="bodyM">
            {t('homepage.dashboard.profile.connectedAccounts', { count: data?.accounts?.items.length })}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
