import { Box } from '@mui/material';
import { BalanceAttribute, useAggregateBalancesQuery } from 'generated/graphql';

import { AccountListItemWrapper } from '../AccountListItemWrapper/AccountListItemWrapper';
import { getDayFilter } from '../utils';
import { styles } from './AccountList.styles';

interface Props {
  asOfDate: Date;
}

export const AccountList: React.FC<Props> = ({ asOfDate }) => {
  const filterInput = getDayFilter(asOfDate);

  const { data } = useAggregateBalancesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filterInput,
      groupBy: [BalanceAttribute.Account, BalanceAttribute.Day],
    },
  });

  return (
    <Box mt={5} sx={styles.container}>
      {data?.aggregateBalances.resultData.map((aggregate) => (
        <AccountListItemWrapper key={JSON.stringify(aggregate.key[0])} aggregate={aggregate} asOfDate={asOfDate} />
      ))}
    </Box>
  );
};
