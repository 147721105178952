interface Props {
  count?: number;
}

export const NotificationsIcon: React.FC<Props> = ({ count }) => {
  return (
    <svg fill="none" height="23" viewBox="0 0 25 23" width="25" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M3 18.8V14.4897C3 11.8996 5.23858 9.79999 8 9.79999C10.7614 9.79999 13 11.8996 13 14.4897V18.8"
          stroke="#6D6D6D"
          strokeWidth="2"
        />
        <path d="M0 19L16 19" stroke="#6D6D6D" strokeWidth="2" />
        <path d="M6 22H10" stroke="#6D6D6D" strokeWidth="2" />
        <path d="M8 7V10" stroke="#6D6D6D" strokeWidth="2" />
      </g>
      {!!count && (
        <>
          <circle cx="17" cy="8" fill="#0040DD" r="7" />
          <text
            fill="white"
            fontFamily="Inter, sans-serif"
            fontSize="9"
            fontWeight="600"
            textAnchor="middle"
            x="17"
            y="11"
          >
            {count}
          </text>
        </>
      )}
    </svg>
  );
};
