import { Box, Stack, Typography } from '@mui/material';
import CashflowGuardianImage from 'assets/images/cashflow-guardian-prodtour.svg';
import { Image } from 'common/components/Image/Image';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

export type SetBudgetIntroDialogProps = DialogComponentProps<void>;

export const SetBudgetIntro: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack alignItems="center" mb={4} spacing={1}>
        <Typography variant="headlineXL">{t('cashflowGuardian.setBudget.intro.title')}</Typography>
        <Typography color="text.secondary" variant="bodyL">
          {t('cashflowGuardian.setBudget.intro.description')}
        </Typography>
      </Stack>

      <Image height="auto" maxWidth="100%" src={CashflowGuardianImage} />
    </Box>
  );
};
