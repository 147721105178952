import { SxProps, Theme } from '@mui/material';
import { RESPONSIVE_DIALOG_HEADER_HEIGHT } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog.styles';

export const styles: Record<'search' | 'header' | 'icon', SxProps<Theme>> = {
  header: {
    backgroundColor: (theme) => theme.palette.gray[100],
    position: 'sticky',
    top: RESPONSIVE_DIALOG_HEADER_HEIGHT,
    zIndex: 1,
  },
  icon: (theme) => ({
    backgroundColor: theme.palette.gray[300],
    borderRadius: '6px',
    height: '96px',
    textAlign: 'center',
    width: '96px',
  }),
  search: (theme) => ({
    '& .MuiInputBase-root': {
      '& > *': {
        '&::placeholder': { fontStyle: 'normal' },
        padding: 0,
      },
      '&:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.gray[300],
      },
      backgroundColor: theme.palette.gray[200],
      borderRadius: '6px',
      input: {
        fontSize: 17,
        height: 32,
      },
      padding: theme.spacing(0, 1),
    },
    '& .MuiTextField-root': {
      flexGrow: 1,
    },
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(4),
  }),
};
