import { endOfMonth, startOfDay } from 'date-fns';
import {
  FilterOperator,
  Money,
  TransactionAggregationValueFragment,
  TransactionAttribute,
  UpcomingPaymentAggregationValueFragment,
  UpcomingPaymentAttribute,
  useAggregateTransactionsQuery,
  useAggregateUpcomingPaymentsQuery,
  useBudgetQuery,
} from 'generated/graphql';

import { createMoney, getTransactionsFilterConditions } from './utils';

export interface CashflowState {
  budget?: Partial<Money>;
  leftToSpend: Money;
  sumOfRealizedExpenses: Money;
  sumOfUpcomingExpenses: Money;
  realizedExpensesAggregates: TransactionAggregationValueFragment[];
  upcomingPaymentsAggregates: UpcomingPaymentAggregationValueFragment[];
  loading?: boolean;
}

export const useCashflowState = (asOfDate: Date): CashflowState => {
  const { data: budgetData, loading: budgetLoading } = useBudgetQuery({
    variables: {
      asOfDate: startOfDay(asOfDate),
    },
  });

  const upcomingPaymentsFilter = {
    asOfDate: startOfDay(asOfDate),
    endDate: endOfMonth(asOfDate),
    excludeFromAnalytics: { operator: FilterOperator.NotEqual, value: 'true' },
    expectedBaseAmount: { operator: FilterOperator.LessThan, value: '0' },
  };

  const { data: upcomingPaymentsAggregatesData, loading: upcomingPaymentsAggregatesLoading } =
    useAggregateUpcomingPaymentsQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        filterInput: upcomingPaymentsFilter,
        groupBy: [UpcomingPaymentAttribute.Day],
      },
    });

  const { data: totalUpcomingPaymentsData, loading: totalUpcomingPaymentsLoading } = useAggregateUpcomingPaymentsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: upcomingPaymentsFilter,
    },
  });

  const transactionsFilterConditions = getTransactionsFilterConditions(asOfDate);

  const transactionsFilterInput = {
    arguments: transactionsFilterConditions.map((condition) => ({ filterCondition: condition })),
  };

  const { data: transactionAggregatesData, loading: transactionAggregatesLoading } = useAggregateTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: transactionsFilterInput,
      groupBy: [TransactionAttribute.Day],
    },
  });

  const { data: totalTransactionsData, loading: totalTransactionsLoading } = useAggregateTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: transactionsFilterInput,
    },
  });

  const budget: Partial<Money> = {
    amount: budgetData?.budget ?? undefined,
    currency: 'EUR',
  };
  const sumOfRealizedExpenses =
    totalTransactionsData?.aggregateTransactions.resultData.find(Boolean)?.sum ?? createMoney(0);
  const sumOfUpcomingExpenses =
    totalUpcomingPaymentsData?.aggregateUpcomingPayments.resultData.find(Boolean)?.sum ?? createMoney(0);

  return {
    budget,
    leftToSpend: createMoney((budget.amount ?? 0) + sumOfUpcomingExpenses.amount + sumOfRealizedExpenses.amount),
    loading:
      budgetLoading ||
      upcomingPaymentsAggregatesLoading ||
      totalTransactionsLoading ||
      totalUpcomingPaymentsLoading ||
      transactionAggregatesLoading,
    realizedExpensesAggregates: transactionAggregatesData?.aggregateTransactions.resultData ?? [],
    sumOfRealizedExpenses,
    sumOfUpcomingExpenses,
    upcomingPaymentsAggregates: upcomingPaymentsAggregatesData?.aggregateUpcomingPayments.resultData ?? [],
  };
};
