import { format, parseISO } from 'date-fns';
import { FilterConditionInput, FilterInput, FilterOperator, LogicalOperator } from 'generated/graphql';
import _ from 'lodash';

export interface FilterState {
  account?: FilterInput;
  amount?: FilterInput;
  date?: FilterInput;
  category?: FilterInput;
  tags?: FilterInput;
  counterparty?: FilterInput;
  type?: FilterInput;
  periodicity?: FilterInput;
  analytics?: FilterInput;
}

export interface FilterFormValues {
  account?: {
    quickfilter?: FilterInput;
    manual?: never;
  };
  amount?: {
    quickfilter?: FilterInput;
    manual?: {
      from?: number | null;
      to?: number | null;
    } | null;
  };
  date?: {
    quickfilter?: FilterInput;
    manual?: {
      from?: Date | null;
      to?: Date | null;
    } | null;
  };
  category?: {
    quickfilter?: undefined | null;
    manual?: FilterConditionInput;
  };
  tags?: {
    quickfilter?: undefined | null;
    manual?: FilterConditionInput;
  };
  counterparty?: {
    quickfilter?: FilterInput;
    manual?: string[];
  };
  type?: {
    quickfilter?: FilterInput;
    manual?: FilterConditionInput;
  };
  periodicity?: {
    quickfilter?: FilterInput;
    manual?: FilterConditionInput;
  };
  analytics?: {
    quickfilter?: FilterInput;
    manual?: never;
  };
}

export const mapFilterStateToFormValues = (state?: FilterState): FilterFormValues => {
  return _.mapValues(state, (filter, key) => {
    if (!filter) {
      return undefined;
    }
    switch (key) {
      case 'date': {
        if (filter.operator === LogicalOperator.And) {
          const fromCondition = filter.arguments.find(
            (filterWrapper) => filterWrapper.filterCondition?.operator === FilterOperator.GreaterThanOrEqual,
          );
          const toCondition = filter.arguments.find(
            (filterWrapper) => filterWrapper.filterCondition?.operator === FilterOperator.LessThanOrEqual,
          );
          return {
            manual: {
              from: fromCondition?.filterCondition?.values?.[0]
                ? parseISO(fromCondition?.filterCondition?.values[0])
                : undefined,
              to: toCondition?.filterCondition?.values?.[0]
                ? parseISO(toCondition?.filterCondition?.values[0])
                : undefined,
            },
            quickfilter: undefined,
          } as FilterFormValues['date'];
        } else return { quickfilter: filter } as FilterFormValues['date'];
      }

      case 'amount': {
        if (filter.operator === LogicalOperator.And) {
          const amountConditions = filter.arguments.map((argument) =>
            argument.filter?.arguments.find((orArgument) => (orArgument.filterCondition?.values?.[0] ?? '-1') > '0'),
          );

          const fromCondition = amountConditions.find(
            (filterWrapper) => filterWrapper?.filterCondition?.operator === FilterOperator.GreaterThanOrEqual,
          );
          const toCondition = amountConditions.find(
            (filterWrapper) => filterWrapper?.filterCondition?.operator === FilterOperator.LessThanOrEqual,
          );

          return {
            manual: {
              from: fromCondition?.filterCondition?.values?.[0] ?? undefined,
              to: toCondition?.filterCondition?.values?.[0] ?? undefined,
            },
            quickfilter: undefined,
          } as FilterFormValues['amount'];
        } else return { quickfilter: filter } as FilterFormValues['amount'];
      }

      case 'category': {
        return { manual: filter.arguments[0].filterCondition, quickfilter: undefined } as FilterFormValues['category'];
      }

      case 'tags': {
        return { manual: filter.arguments[0].filterCondition, quickfilter: undefined } as FilterFormValues['tags'];
      }

      case 'counterparty': {
        if (filter.arguments[0].filterCondition?.fieldPath === 'counterparty.id') {
          return {
            manual: _.flatten(filter.arguments.map((inputWrapper) => inputWrapper.filterCondition?.values)),
            quickfilter: undefined,
          } as FilterFormValues['counterparty'];
        } else return { quickfilter: filter } as FilterFormValues['counterparty'];
      }

      case 'type': {
        if (filter.arguments[0].filterCondition?.operator === FilterOperator.In) {
          return {
            manual: filter.arguments[0].filterCondition,
            quickfilter: undefined,
          } as FilterFormValues['type'];
        } else return { quickfilter: filter } as FilterFormValues['type'];
      }

      default: {
        return {
          quickfilter: filter,
        };
      }
    }
  }) as FilterFormValues;
};

export const mapFilterFormValuesToState = (formData: FilterFormValues): FilterState => {
  return _.mapValues(formData, (value, key) => {
    switch (key) {
      case 'date': {
        const filterValue = value as FilterFormValues['date'];
        if (filterValue?.manual?.from || filterValue?.manual?.to) {
          return {
            arguments: [
              filterValue.manual.from && {
                filterCondition: {
                  fieldPath: 'bookingDate',
                  operator: FilterOperator.GreaterThanOrEqual,
                  values: [format(filterValue.manual.from, 'yyyy-MM-dd')],
                },
              },
              filterValue.manual.to && {
                filterCondition: {
                  fieldPath: 'bookingDate',
                  operator: FilterOperator.LessThanOrEqual,
                  values: [format(filterValue.manual.to, 'yyyy-MM-dd')],
                },
              },
            ].filter((filterCondition) => !!filterCondition),
            operator: LogicalOperator.And,
          };
        } else return filterValue?.quickfilter;
      }

      case 'amount': {
        const filterValue = value as FilterFormValues['amount'];
        if (filterValue?.manual?.from || filterValue?.manual?.to) {
          return {
            arguments: [
              filterValue.manual.from && {
                filter: {
                  arguments: [
                    {
                      filterCondition: {
                        fieldPath: 'baseAmount.amount',
                        operator: FilterOperator.GreaterThanOrEqual,
                        values: [filterValue.manual.from],
                      },
                    },
                    {
                      filterCondition: {
                        fieldPath: 'baseAmount.amount',
                        operator: FilterOperator.LessThanOrEqual,
                        values: [-1 * filterValue.manual.from],
                      },
                    },
                  ],
                  operator: LogicalOperator.Or,
                },
              },
              filterValue.manual.to && {
                filter: {
                  arguments: [
                    {
                      filterCondition: {
                        fieldPath: 'baseAmount.amount',
                        operator: FilterOperator.LessThanOrEqual,
                        values: [filterValue.manual.to],
                      },
                    },
                    {
                      filterCondition: {
                        fieldPath: 'baseAmount.amount',
                        operator: FilterOperator.GreaterThanOrEqual,
                        values: [-1 * filterValue.manual.to],
                      },
                    },
                  ],
                  operator: LogicalOperator.And,
                },
              },
            ].filter((filterCondition) => !!filterCondition),
            operator: LogicalOperator.And,
          };
        } else return filterValue?.quickfilter;
      }

      case 'category': {
        return value?.manual ? { arguments: [{ filterCondition: value?.manual }] } : undefined;
      }

      case 'tags': {
        return value?.manual ? { arguments: [{ filterCondition: value?.manual }] } : undefined;
      }

      case 'counterparty': {
        const filterValue = value as FilterFormValues['counterparty'];
        return filterValue?.manual
          ? {
              arguments: filterValue.manual.map((counterparty) => {
                return {
                  filterCondition: {
                    fieldPath: 'counterparty.id',
                    operator: FilterOperator.Equal,
                    values: [counterparty],
                  },
                };
              }),
              operator: LogicalOperator.Or,
            }
          : filterValue?.quickfilter;
      }

      case 'type': {
        return value?.manual ? { arguments: [{ filterCondition: value?.manual }] } : value?.quickfilter;
      }

      default:
        return value?.quickfilter;
    }
  }) as FilterState;
};
