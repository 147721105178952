import { SxProps, Theme } from '@mui/material';

export const styles: Record<
  | 'amount'
  | 'line'
  | 'container'
  | 'box'
  | 'redCircle'
  | 'homepageButton'
  | 'greenBox'
  | 'boxes'
  | 'benefits'
  | 'resultBoxes'
  | 'resultContainer'
  | 'resultDomain'
  | 'resultLine',
  SxProps<Theme>
> = {
  amount: {
    fontSize: '64px',
    fontWeight: 600,
  },
  benefits: {
    flexDirection: { sm: 'row', xxs: 'column' },
    gap: 2,
  },
  box: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    padding: (theme) => ({
      sm: theme.spacing(6, 6, 5, 6),
      xxs: theme.spacing(5, 5, 4, 5),
    }),
    position: 'relative',
    textAlign: 'left',
  },
  boxes: {
    flexDirection: { sm: 'row', xxs: 'column' },
    gap: 4,
    marginTop: (theme) => theme.spacing(5),
  },
  container: {
    alignItems: 'center',
    padding: (theme) => theme.spacing(6, 2, 8),
    textAlign: 'center',
  },
  greenBox: {
    backgroundColor: (theme) => theme.palette.green[100],
    borderRadius: '4px',
    color: (theme) => theme.palette.green[800],
    padding: (theme) => theme.spacing(0.5, 1),
    position: 'absolute',
    right: (theme) => theme.spacing(2),
    top: (theme) => theme.spacing(2),
  },
  homepageButton: {
    backgroundColor: 'inherit',
    marginTop: (theme) => theme.spacing(8),
    width: 'fit-content',
  },
  line: {
    backgroundColor: (theme) => theme.palette.gray[300],
    height: '1px',
    margin: (theme) => ({
      sm: theme.spacing(7, 0),
      xxs: theme.spacing(5, 0),
    }),
    width: '100%',
  },
  redCircle: {
    alignItems: 'center',
    background: 'linear-gradient(133.34deg, #FF9B88 0.27%, #EA3829 100%)',
    borderRadius: '100%',
    color: (theme) => theme.palette.gray[50],
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(4),
    width: 32,
  },
  resultBoxes: {
    alignItems: 'center',
    flexDirection: { sm: 'row', xxs: 'column' },
    gap: 6,
  },
  resultContainer: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    marginTop: (theme) => theme.spacing(8),
    padding: (theme) => theme.spacing(4, 6),
    position: 'relative',
    textAlign: 'left',
  },
  resultDomain: {
    backgroundColor: (theme) => theme.palette.gray[100],
    padding: (theme) => theme.spacing(0.5, 1),
  },
  resultLine: {
    backgroundColor: (theme) => theme.palette.gray[300],
    height: '1px',
    margin: (theme) => theme.spacing(4, 0),
    width: '100%',
  },
};
