import { Box, Stack, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import { PeriodTypeEnum } from 'components/spendingReport/state/spendingReportTypes';
import { formatPeriodTitle, getPeriod } from 'components/spendingReport/utils';
import { endOfMonth } from 'date-fns';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { useCashflowState } from '../useCashflowState';
import { useChartOptions } from './UpcomingExpensesChart.chart';

export const UpcomingExpensesChart: React.FC = () => {
  const { i18n } = useTranslation();
  const currentDate = new Date();

  const cashflowState = useCashflowState(currentDate);

  const period = getPeriod(PeriodTypeEnum.Month, currentDate);
  const periodTitle = formatPeriodTitle(period, endOfMonth(currentDate));

  const options = useChartOptions(currentDate);
  return (
    <Stack bgcolor="gray.50" borderRadius="10px" pt={3} px={4}>
      <Typography color="textSecondary" variant="bodyM">
        {periodTitle}
      </Typography>
      <Typography variant="headlineXS">
        {formatTransactionAmount(
          cashflowState.sumOfUpcomingExpenses.amount ?? 0,
          i18n.language,
          2,
          cashflowState.sumOfUpcomingExpenses.currency,
        )}
      </Typography>
      <Box height={300}>
        <ReactEcharts option={options} style={{ height: '100%', width: '100%' }} />
      </Box>
    </Stack>
  );
};
