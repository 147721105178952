import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { ProductBase } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  products?: ProductBase[];
}

export const ProductClientStatsCard: React.FC<Props> = ({ isLoading, products }) => {
  const productClients = Array.from(new Set(products?.map((product) => product.client?.id)));

  const lastWeekTimestamp = new Date();
  lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);

  const productsBeforeLastWeek = products?.filter((product) => {
    const creationTimestamp = product.creationTimestamp;
    return creationTimestamp && creationTimestamp < lastWeekTimestamp;
  });
  const productClientsBeforeLastWeek = Array.from(
    new Set(productsBeforeLastWeek?.map((product) => product.client?.id)),
  );

  const deltaProductClientsLastWeek =
    productClients?.filter((client) => !productClientsBeforeLastWeek.includes(client)).length ?? 0;

  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">
          {`${productClients.length} klientov s produktom`}
        </Typography>
        <Typography color="text.secondary">{`+${deltaProductClientsLastWeek} za posledných 7 dní`}</Typography>
      </CardContent>
    </Card>
  );
};
