import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { ProductBase, ProductStatus } from 'generated/graphql';

interface Props {
  isLoading: boolean;
  products?: ProductBase[];
}

export const ProductActiveClientStatsCard: React.FC<Props> = ({ isLoading, products }) => {
  const activeProducts = products?.filter((product) => product.status == ProductStatus.Active);

  const activeProductClients = Array.from(new Set(activeProducts?.map((product) => product.client?.id)));

  const lastWeekTimestamp = new Date();
  lastWeekTimestamp.setDate(lastWeekTimestamp.getDate() - 7);

  const activeProductsBeforeLastWeek = activeProducts?.filter((product) => {
    const creationTimestamp = product.creationTimestamp;
    return creationTimestamp && creationTimestamp < lastWeekTimestamp;
  });
  const activeProductClientsBeforeLastWeek = Array.from(
    new Set(activeProductsBeforeLastWeek?.map((product) => product.client?.id)),
  );

  const deltaActiveProductClientsLastWeek =
    activeProductClients?.filter((client) => !activeProductClientsBeforeLastWeek.includes(client)).length ?? 0;

  return isLoading ? (
    <Skeleton height={96} variant="rectangular" />
  ) : (
    <Card>
      <CardContent>
        <Typography component="div" variant="h5">
          {`${activeProductClients.length} klientov s aktívnym produktom`}
        </Typography>
        <Typography color="text.secondary">{`+${deltaActiveProductClientsLastWeek} za posledných 7 dní`}</Typography>
      </CardContent>
    </Card>
  );
};
