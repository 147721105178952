import { SxProps, Theme } from '@mui/material';

export const styles: Record<'actions' | 'dialog', SxProps<Theme>> = {
  actions: {
    '>:not(:first-of-type)': {
      margin: 0,
    },
    '@media (pointer: fine)': {
      display: 'none',
    },
    alignItems: 'unset',
    flexDirection: 'column',
    padding: (theme) => theme.spacing(0, 3, 3, 3),
  },
  dialog: {
    '& .MuiPickersToolbar-root': {
      '& .MuiPickersToolbar-penIconButton': {
        display: 'none',
      },
      '& .MuiTypography-overline': {
        textTransform: 'none',
      },
    },
  },
};
