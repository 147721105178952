import { Box, Button, Stack, Typography } from '@mui/material';
import { INVESTMENT_OPEN_ACCOUNT_MANUAL_URL, INVESTMENT_OPEN_ACCOUNT_URL } from 'const';
import { Trans, useTranslation } from 'react-i18next';

import { styles } from './Content.styles';

export const Content: React.FC = () => {
  const { t } = useTranslation();

  const handleDownloadManual = () => {
    window.open(INVESTMENT_OPEN_ACCOUNT_MANUAL_URL, '_blank');
  };

  const handleOpenAccount = () => {
    window.open(INVESTMENT_OPEN_ACCOUNT_URL, '_blank');
  };

  const texts = [t('flows/investment:openAccount.texts.item1'), t('flows/investment:openAccount.texts.item2')];

  return (
    <Stack pb={3}>
      <Box sx={styles.title}>
        <Typography component="h2" mb={6} variant="headlineXL">
          <Trans i18nKey="flows/investment:openAccount.title" />
        </Typography>
      </Box>
      <Stack gap={2} mb={6}>
        {texts.map((text, index) => (
          <Box key={index}>
            <Typography color="textSecondary" variant="bodyXXL">
              {text}
            </Typography>
          </Box>
        ))}
      </Stack>
      <Stack direction="row" gap={1.5}>
        <Button onClick={handleDownloadManual} size="large">
          {t('flows/investment:openAccount.buttons.downloadManual')}
        </Button>

        <Button onClick={handleOpenAccount} size="large">
          {t('flows/investment:openAccount.buttons.openAccount')}
        </Button>
      </Stack>
    </Stack>
  );
};
