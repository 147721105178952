import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container', SxProps<Theme>> = {
  container: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    background: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
};
