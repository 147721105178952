import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container', SxProps<Theme>> = {
  container: {
    '& + &': {
      borderTop: (theme) => `1px solid ${theme.palette.gray[200]}`,
    },
    '&:hover': (theme) => ({
      backgroundColor: theme.palette.gray[100],
      color: theme.palette.gray[900],
      cursor: 'pointer',
    }),
    padding: (theme) => theme.spacing(2),
  },
};
