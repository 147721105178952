import { AutocompleteInput, useGetList } from 'react-admin';

interface Props {
  source: string;
  label?: string;
}

export const FinancialAdvisorTaskTitleInput: React.FC<Props> = ({ label, source }) => {
  // TODO: get all distinct title nicer way
  const { data } = useGetList('FinancialAdvisorTask', {
    pagination: { page: 1, perPage: 10000 },
  });

  return (
    <AutocompleteInput
      choices={
        data &&
        Array.from(new Set(data?.map((item) => item.title)))
          .sort()
          .map((item) => ({
            id: item,
            name: item,
          }))
      }
      label={label}
      source={source}
      sx={{ width: '15em' }}
    />
  );
};

export const FinancialAdvisorTaskSubtitleInput: React.FC<Props> = ({ label, source }) => {
  // TODO: get all distinct title nicer way
  const { data } = useGetList('FinancialAdvisorTask', {
    pagination: { page: 1, perPage: 10000 },
  });

  return (
    <AutocompleteInput
      choices={
        data &&
        Array.from(new Set(data?.map((item) => item.subtitle)))
          .sort()
          .map((item) => ({
            id: item,
            name: item,
          }))
      }
      label={label}
      source={source}
      sx={{ width: '15em' }}
    />
  );
};

export const FinancialAdvisorTaskTextInput: React.FC<Props> = ({ label, source }) => {
  // TODO: get all distinct title nicer way
  const { data } = useGetList('FinancialAdvisorTask', {
    pagination: { page: 1, perPage: 10000 },
  });

  return (
    <AutocompleteInput
      choices={
        data &&
        Array.from(new Set(data?.map((item) => item.text)))
          .sort()
          .map((item) => ({
            id: item,
            name: item,
          }))
      }
      label={label}
      source={source}
      sx={{ width: '20em' }}
    />
  );
};
