import { Box, Button, Stack, Typography } from '@mui/material';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { FinancialProductType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { ContentDispositionType, useProductFiles } from '../../../common/hooks/useProductFiles';
import { DocumentRow } from './DocumentRow';
import { styles } from './styles';

interface EditDocumentDialogContentProps {
  documentId: string;
  productId: string;
  type: FinancialProductType;
}

export interface EditDocumentDialogProps extends DialogComponentProps<void>, EditDocumentDialogContentProps {}

const EditDocumentDialogContent: React.FC<EditDocumentDialogProps> = ({ documentId, productId, type }) => {
  const { t } = useTranslation();

  const {
    files,
    handleDeleteClick,
    handleDownloadClick,
    handleFileChange,
    handleInputClick,
    handleRetryClick,
    uploadError,
  } = useProductFiles(documentId, productId, type);

  return (
    <Stack>
      <Stack sx={styles.files}>
        {files?.map((file) => (
          <Box key={file.id}>
            <DocumentRow
              errorMessage={file.error}
              fileName={file.name}
              onDelete={() => handleDeleteClick(file.id, file.status)}
              onDownload={() => handleDownloadClick(file, ContentDispositionType.ATTACHMENT, window.open())}
              onOpen={() => handleDownloadClick(file, ContentDispositionType.INLINE, window.open())}
              onRetry={() => handleRetryClick(file.id)}
              status={file.status}
            />
          </Box>
        ))}
      </Stack>
      <Stack mt={8}>
        {!!uploadError && (
          <Typography color="error" mb={3} textAlign="center">
            {uploadError.message}
          </Typography>
        )}
        <Button component="label" sx={styles.buttonRow}>
          {t('products:uploadDocument.uploaded.uploadMore')}
          <input hidden onChange={handleFileChange} onClick={handleInputClick} type="file" />
        </Button>
        <Typography color="text.secondary" mt={3} textAlign="center" variant="bodyS">
          {t('products:uploadDocument.note')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const EditDocumentDialog: React.FC<EditDocumentDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <ResponsiveSideDialog
      dialogId={props.instanceId}
      header={
        <>
          <Typography sx={styles.header} variant="bodyStrongL">
            {t('products:editDocuments.header.title')}
          </Typography>
          <Button color="tertiaryButton" onClick={() => props.onResolve()} sx={styles.headerSubmit}>
            <Typography color="blue.dark" variant="bodyStrongL">
              {t('products:editDocuments.header.submit')}
            </Typography>
          </Button>
        </>
      }
      isOpen={props.isOpen}
      onClose={props.onReject}
    >
      <EditDocumentDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
