import { Box, Typography } from '@mui/material';
import { Header } from 'common/components/Header/Header';
import _ from 'lodash';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { styles } from './Loading.styles';

interface Props {
  messages: string[];
  fullScreen?: boolean;
  hideCommonLoadingTitle?: boolean;
}

export const Loading = forwardRef<HTMLDivElement, Props>(({ fullScreen, hideCommonLoadingTitle, messages }, ref) => {
  const { t } = useTranslation();

  const backgroundStyle = { ...styles.background, ...(fullScreen ? styles.fullScreen : {}) };

  return (
    <Box sx={backgroundStyle}>
      {fullScreen && <Header />}
      <Box ref={ref} sx={styles.content}>
        <Box sx={styles.loading}>
          <LoadingSpinner />
        </Box>
        {!hideCommonLoadingTitle && (
          <Typography mb={0.5} variant="headlineS">
            {t('common:loading.title')}
          </Typography>
        )}
        <Typography variant="bodyM">{_.last(messages)}</Typography>
      </Box>
    </Box>
  );
});
