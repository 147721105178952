import { SxProps, Theme } from '@mui/material';

export const styles = (
  iconSize: number = 64,
  circleSize: number = 96,
): Record<'progress' | 'progressLabel', SxProps<Theme>> => ({
  progress: (theme) => ({
    '.svg-indicator-center': {
      cx: iconSize / 2,
      cy: iconSize / 2,
      r: circleSize,
    },
    '.svg-indicator-indication': {
      fill: 'transparent',
      stroke: '#1D1D1B',
      strokeDasharray: '4px 2px',
      strokeWidth: 16,
    },
    '.svg-indicator-track': {
      fill: 'transparent',
      stroke: theme.palette.primary[50],
      strokeDasharray: '4px 2px',
      strokeWidth: 16,
    },
    borderRadius: '10px',
    height: iconSize,
    position: 'relative',
    width: iconSize,
  }),
  progressLabel: {
    bottom: '50%',
    position: 'absolute',
    right: '50%',
    transform: 'translate(50%,50%)',
  },
});
