import { Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { useDialog } from 'common/hooks/useDialog';
import { InstitutionFragment } from 'generated/graphql';

import { AddAccountDialog, AddAccountDialogProps } from '../AddAccountDialog/AddAccountDialog';
import { styles } from './SelectBankDialog.styles';

interface Props {
  institution: InstitutionFragment;
}
export const SelectBankListItem: React.FC<Props> = ({ institution }) => {
  const addAccountDialog = useDialog<AddAccountDialogProps, void>(AddAccountDialog, {
    instanceId: `add-account-${institution.id}`,
    institutionId: institution.id,
    navigable: true,
  });

  const handleInstitutionSelect = () => {
    addAccountDialog.create({ institutionId: institution.id });
  };

  return (
    <Stack
      key={institution.id}
      direction="row"
      justifyContent="space-between"
      onClick={() => handleInstitutionSelect()}
      sx={styles.row}
    >
      <Stack direction="row" spacing={1}>
        <InstitutionIcon
          alt={institution.name ?? undefined}
          src={institution.smallLogoUrl ?? undefined}
          title={institution.name ?? undefined}
          width={20}
        />
        <Typography variant="bodyM">{institution.name}</Typography>
      </Stack>
      <ChevronRightIcon />
    </Stack>
  );
};
