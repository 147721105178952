import { Box, Button, InputAdornment, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRightBig.svg';
import { ReactComponent as LoupeIcon } from 'assets/icons/Loupe.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { useNotification } from 'common/hooks/useNotification';
import {
  FilterOperator,
  FinancialProductType,
  InstitutionFragment,
  SortDirection,
  useInstitutionsQuery,
  useUpdateProductTypeMutation,
} from 'generated/graphql';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AddCustomProductDialog, AddCustomProductDialogProps } from '../AddCustomProductDialog/AddCustomProductDialog';
import {
  ProductWaitingForReviewDialog,
  ProductWaitingForReviewDialogProps,
} from '../ProductWaitingForReviewDialog/ProductWaitingForReviewDialog';
import { ProductBaseExtended, productTypename } from '../types';
import { useProductQuery } from '../useProductQuery';
import { getMapProductToInput, useUpdateProduct } from '../useUpdateProduct';
import { AddCustomProviderDialog, AddCustomProviderDialogProps } from './AddCustomProviderDialog';
import { styles } from './SelectProviderDialog.styles';

interface SelectProviderDialogContentProps {
  newProductType: FinancialProductType;
  // we use this dialog either for creating new product (undefined product)
  // or for updating product type of existing product
  product?: ProductBaseExtended;
}

export interface SelectProviderDialogProps extends DialogComponentProps<void>, SelectProviderDialogContentProps {}

const SelectProviderDialogContent: React.FC<SelectProviderDialogProps> = ({ newProductType, onResolve, product }) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const notify = useNotification();

  const [search, setSearch] = useState<string>('');

  const { data, loading } = useInstitutionsQuery({
    variables: {
      filterInput: {
        arguments: [
          {
            filterCondition: {
              fieldPath: 'financialProductTypes',
              operator: FilterOperator.Contains,
              values: [newProductType],
            },
          },
        ],
      },
      sortInput: {
        by: [{ direction: SortDirection.Asc, fieldPath: 'name' }],
      },
    },
  });

  const handleSearchChange = _.debounce(setSearch, 250);

  const addCustomProductDialog = useDialog<AddCustomProductDialogProps, void>(AddCustomProductDialog, {
    instanceId: 'add-custom-product-dialog',
    tmpNavigable: true,
  });

  const addCustomProviderInput = useDialog<AddCustomProviderDialogProps, void>(AddCustomProviderDialog, {
    instanceId: 'add-custom-provider-input',
  });

  const [updateProductType] = useUpdateProductTypeMutation();

  const [productParams, setProductParams] = useState<{
    type?: FinancialProductType;
    id?: string;
    institution?: Partial<InstitutionFragment>;
  }>({});
  const productQuery = useProductQuery(productParams.type ?? FinancialProductType.Generic, {
    id: productParams.id ?? '',
  });
  const [updateProduct] = useUpdateProduct(productParams.type ?? FinancialProductType.Generic);
  const mapProductToInput = getMapProductToInput(productParams.type ?? FinancialProductType.Generic);

  const productWaitingForReviewDialog = useDialog<ProductWaitingForReviewDialogProps, void>(
    ProductWaitingForReviewDialog,
    {
      navigable: true,
    },
  );

  useEffect(() => {
    if (productQuery.data?.detail) {
      const newProduct = { ...productQuery.data?.detail, type: newProductType } as ProductBaseExtended;
      if (product && newProduct.id != product.id) {
        updateProduct({
          onCompleted: () => {
            // close all previous dialogs
            setSearchParams('', { replace: true });
            productWaitingForReviewDialog.create({
              instanceId: `${newProductType}-waiting-for-review-${newProduct.id}`,
              navigable: true,
              product: newProduct,
            });
            onResolve();
          },
          onError: () => notify({ message: t('common:somethingWentWrong'), type: 'error' }),
          variables: {
            id: newProduct.id,
            updatedProduct: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(mapProductToInput?.(productQuery.data?.detail as any) ?? {}),
              institutionId: productParams.institution?.id,
              institutionName: productParams.institution?.name,
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productQuery]);

  const handleInstitutionSelect = async (institution: Partial<InstitutionFragment>) => {
    if (product) {
      // update product type, new product ID is returned
      await updateProductType({
        onCompleted: (resp) => {
          if (resp) {
            setProductParams({
              id: resp.updateProductType,
              institution,
              type: newProductType,
            });
          }
        },
        onError: () => notify({ message: t('common:somethingWentWrong'), type: 'error' }),
        update: (cache, resp) => {
          if (resp.data?.updateProductType) {
            cache.evict({
              id: cache.identify({
                __typename: productTypename(product.type),
                id: product.id,
              }),
            });
          }
        },
        variables: {
          id: product.id,
          newProductType: newProductType,
          productType: product.type,
        },
      });
    } else {
      // handle new product creation
      await addCustomProductDialog.create({
        institutionId: institution.id,
        institutionName: institution.name,
        productType: newProductType,
      });
      onResolve();
    }
  };

  const handleInstitutionEntry = async () => {
    await addCustomProviderInput.create({
      institutionName: search,
      onSubmit: async (institutionName: string) => {
        await handleInstitutionSelect({
          name: institutionName,
        });
      },
    });
  };

  const ProviderList: React.FC<{ items: InstitutionFragment[] }> = ({ items }) => {
    return (
      <Stack sx={styles.container}>
        {items.map((institution) => (
          <Stack
            key={institution.id}
            direction="row"
            justifyContent="space-between"
            onClick={() => handleInstitutionSelect(institution)}
            sx={styles.row}
          >
            <Stack direction="row" spacing={1}>
              <InstitutionIcon
                alt={institution.name ?? undefined}
                src={institution.smallLogoUrl ?? undefined}
                title={institution.name ?? undefined}
                width={20}
              />
              <Typography variant="bodyM">{institution.name}</Typography>
            </Stack>
            <ChevronRightIcon />
          </Stack>
        ))}
      </Stack>
    );
  };

  const NoResults: React.FC = () => {
    return (
      <Stack sx={styles.noResults}>
        <Box>
          <LoupeIcon />
        </Box>
        <Typography color="text.primary">{t('products:selectProvider.noResults.title')}</Typography>
        <Typography color="text.secondary">{t('products:selectProvider.noResults.subtitle')}</Typography>
        <Button
          aria-label={t('products:selectProvider.noResults.addLabel')}
          color="tertiaryButton"
          onClick={() => handleInstitutionEntry()}
          sx={styles.button}
        >
          <Typography color="blue.dark" variant="bodyL">
            {t('products:selectProvider.noResults.addLabel')}
          </Typography>
        </Button>
      </Stack>
    );
  };

  const Loading: React.FC = () => {
    return (
      <Stack spacing={1} sx={styles.container}>
        {[...Array(17)].map((i, x) => (
          <Skeleton key={x} height={48} variant="rectangular" />
        ))}
      </Stack>
    );
  };

  const Content: React.FC<{ search: string }> = ({ search }) => {
    const filteredItems =
      data?.getInstitutionsPage.items?.filter(
        (institution) => !search || institution.name.toLowerCase().includes(search.toLowerCase()),
      ) ?? [];
    return filteredItems.length > 0 ? <ProviderList items={filteredItems} /> : <NoResults />;
  };

  return (
    <Stack>
      <Stack gap={3}>
        <Typography textAlign="center" variant="headlineXL">
          {t('products:selectProvider.title')}
        </Typography>
      </Stack>
      <Box sx={styles.input}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleSearchChange(e.currentTarget.value)}
          placeholder={t('products:selectProvider.search.placeholder')}
          variant="filled"
        />
      </Box>
      {loading ? <Loading /> : <Content search={search} />}
    </Stack>
  );
};

export const SelectProviderDialog: React.FC<SelectProviderDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <SelectProviderDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
