import { SxProps, Theme } from '@mui/material';

export const styles: Record<'arrow' | 'number' | 'stepper' | 'connectorWrapper' | 'connector', SxProps<Theme>> = {
  arrow: (theme) => ({
    '& svg': {
      color: theme.palette.gray['300'],
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  connector: {
    background: (theme) => theme.palette.gray['300'],
    height: '100%',
    minHeight: '48px',
    width: '1px',
  },
  connectorWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  number: {
    color: (theme) => theme.palette.gray['300'],
    fontSize: '32px',
    fontWeight: '400',
  },
  stepper: {
    alignItems: 'center',
    display: 'grid',
    gap: (theme) => theme.spacing(2, 3),
    gridTemplateColumns: '36px 1fr',
    gridTemplateRows: '1fr',
  },
};
