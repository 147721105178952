import { Box, IconButton } from '@mui/material';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { FilterOperator, useNotificationsCountQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './Notifications.styles';
import { NotificationsDialog } from './NotificationsDialog';
import { NotificationsIcon } from './NotificationsIcon';

export const Notifications: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  const { data } = useNotificationsCountQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated,
    variables: {
      filterInput: {
        arguments: [
          {
            filterCondition: { fieldPath: 'readDate', operator: FilterOperator.IsNull },
          },
          {
            filterCondition: { fieldPath: 'isActive', operator: FilterOperator.Equal, values: ['true'] },
          },
        ],
      },
    },
  });

  const notificationsDialog = useDialog<DialogComponentProps<void>, void>(NotificationsDialog, {
    instanceId: 'notifications',
    navigable: true,
  });

  const handleNotificationsClick = async () => {
    await notificationsDialog.create({});
  };

  return (
    <Box>
      {isAuthenticated && (
        <IconButton
          aria-label={t('homepage.notifications.button')}
          color="tertiaryButton"
          onClick={handleNotificationsClick}
          sx={styles.button}
        >
          <NotificationsIcon count={data?.notificationsCount?.totalItems} />
        </IconButton>
      )}
    </Box>
  );
};
