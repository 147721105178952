import { SxProps, Theme } from '@mui/material';

// TODO: use colors from theme once ROBO2-159 is merged
export const styles: Record<'button', SxProps<Theme>> = {
  button: {
    background: (theme) => theme.palette.gray['400'],
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
};
