import { HomepageContent } from '@brand/components/HomePage/HomepageContent/HomepageContent';
import { HOMEPAGE_TABS } from 'components/HomePage/HomepageTabs/HomepageTabs';

interface Props {
  tab?: HOMEPAGE_TABS;
}

export const HomePage: React.FC<Props> = ({ tab }) => {
  return <HomepageContent tab={tab} />;
};
