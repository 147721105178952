import { ApolloClient, ApolloLink, HttpLink, Observable, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { withScalars } from 'apollo-link-scalars';
import { Auth } from 'aws-amplify';
import { getUnregisteredUserId } from 'common/auth/unregistered-user';
import { getCognitoAccessToken } from 'common/auth/user-auth';
import { environment } from 'environment';
import introspectionSchema from 'generated/introspection.json';
import { buildClientSchema } from 'graphql';

import { cache } from './cache';
import { typesMap } from './typesMap';

//@ts-expect-error
const schema = buildClientSchema(introspectionSchema);

const handleError = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    if (
      graphQLErrors.some(
        (error) => error.message.includes('User not logged in') || error.message.includes('UserNotSynchronized'),
      )
    ) {
      Auth.signOut();
      if (window.location.pathname != '/') {
        window.location.assign(`${window.location.origin}/login`);
      }
    }
    console.warn(`[GraphQL error] Error during graphQL operation '${operation.operationName}'`, {
      graphQLErrors,
      operation,
    });
  }
  if (networkError) {
    console.warn(`[Network error] Error during graphQL operation '${operation.operationName}'`, {
      networkError,
      operation,
    });
    if ('statusCode' in networkError && networkError.statusCode === 401) {
      Auth.signOut();
      if (window.location.pathname != '/') {
        window.location.assign(`${window.location.origin}/login`);
      }
    }
  }
});

const httpLink = new HttpLink({
  uri: environment.backendRouting.gqlEndpoint,
});

const request = async (operation: Operation) => {
  const token = await getCognitoAccessToken();
  const unregisteredUserId = getUnregisteredUserId();
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  } else if (unregisteredUserId) {
    operation.setContext({
      headers: {
        'X-UNREGISTERED-USER-ID': unregisteredUserId,
      },
    });
  }
};

const authLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let handle: any;
      Promise.resolve(operation)
        .then((op) => request(op))
        .then(() => {
          handle = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: observer.error.bind(observer),
            next: observer.next.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export const apolloClient = new ApolloClient({
  cache: cache,

  link: ApolloLink.from([handleError, authLink, withScalars({ schema, typesMap }), httpLink]),
});
