import { SxProps, Theme } from '@mui/material';
import { theme } from 'themes/theme';

export const styles: Record<'image' | 'button', SxProps<Theme>> = {
  button: {
    marginTop: (theme) => theme.spacing(8),
  },
  image: {
    alignItems: 'center',
    background: 'linear-gradient(#01A963, #004428)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    display: 'flex',
    height: 96,
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(1),
    width: 96,
  },
};
