import { Box } from '@mui/material';
import { TransactionAttribute, useAggregateTransactionsQuery } from 'generated/graphql';
import _ from 'lodash';
import { useEffect } from 'react';

import { AccountListItemWrapper } from '../AccountListItemWrapper/AccountListItemWrapper';
import { PeriodTypeEnum } from '../state/spendingReportTypes';
import { useSpendingReportState } from '../state/useSpendingReportState';
import { getFilter } from '../utils';
import { styles } from './AccountList.styles';

export interface AccountListProps {
  lockedPeriodType?: PeriodTypeEnum;
  total?: number;
  type: TransactionAttribute;
  categoryId?: string;
  tagId?: string;
  pointerDate: Date;
}

export const AccountList: React.FC<AccountListProps> = (props) => {
  const { categoryId, pointerDate, tagId, type } = props;
  const { state } = useSpendingReportState();
  const periodType = props.lockedPeriodType ?? state.params.periodType;

  const filterConditions = getFilter(pointerDate, periodType, categoryId, tagId);

  const filterInput = {
    arguments: filterConditions.map((condition) => ({ filterCondition: condition })),
  };

  const { called, data, refetch } = useAggregateTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput,
      groupBy: [type],
    },
  });

  useEffect(() => {
    if (called) {
      refetch({ filterInput });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, pointerDate, periodType, categoryId, tagId]);

  const sortedAggregates = _.sortBy(data?.aggregateTransactions.resultData, 'sum.amount');

  return sortedAggregates.length ? (
    <Box mt={8} sx={styles.root}>
      {sortedAggregates.map((aggregate) => (
        <AccountListItemWrapper
          key={JSON.stringify(aggregate.key[0])}
          aggregate={aggregate}
          categoryId={categoryId}
          lockedPeriodType={props.lockedPeriodType}
          pointerDate={pointerDate}
          tagId={tagId}
        />
      ))}
    </Box>
  ) : null;
};
