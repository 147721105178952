import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { FormInput } from 'common/form/components/FormInput';
import { transformNaNtoNull } from 'common/form/validators';
import { startOfMonth } from 'date-fns';
import { useSetBudgetMutation } from 'generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface FormValues {
  budget: number;
}

interface SetBudgetInputProps {
  budget?: number;
  onResolve: () => void;
}

export const SetBudgetInput: React.FC<SetBudgetInputProps> = ({ budget, onResolve }) => {
  const { t } = useTranslation();
  const [setBudget, { loading }] = useSetBudgetMutation();

  const schema: yup.SchemaOf<FormValues> = yup.object({
    budget: yup
      .number()
      .transform(transformNaNtoNull)
      .nullable(true)
      .integer(t('form.validator.integer'))
      .moreThan(0, t('form.validator.moreThan', { value: 0 }))
      .required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      budget: budget,
    } as FormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    const sinceDate = startOfMonth(new Date());
    await setBudget({
      refetchQueries: ['Budget'],
      variables: { amountInBaseCurrency: data.budget, sinceDate: sinceDate },
    });
    onResolve();
    return;
  });

  return (
    <Box width="100%">
      <Stack alignItems="center" spacing={1}>
        <Typography variant="headlineXL">{t('cashflowGuardian.setBudget.input.title')}</Typography>
        <Typography color="text.secondary" variant="bodyL">
          {t('cashflowGuardian.setBudget.input.description')}
        </Typography>
      </Stack>
      <FormProvider {...formMethods}>
        <Box component="form" mt={3} noValidate onSubmit={handleSubmit}>
          <FormInput fullWidth name="budget" type="number" />

          <Box mt={3}>
            <LoadingButton color="primary" fullWidth loading={loading} size="large" type="submit">
              <Typography color="white" variant="bodyL">
                {t('cashflowGuardian.setBudget.finish')}
              </Typography>
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};
