import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { useSidebarState, useTranslate } from 'react-admin';

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

export const SubMenu: React.FC<Props> = (props) => {
  const { children, dense, handleToggle, icon, isOpen, name } = props;
  const translate = useTranslate();

  const [sidebarIsOpen] = useSidebarState();

  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      <ListItemIcon sx={{ minWidth: 5 }}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
      <Typography color="textSecondary" variant="inherit">
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <div>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip placement="right" title={translate(name)}>
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          dense={dense}
          disablePadding
          sx={{
            '& .MuiMenuItem-root': {
              paddingLeft: (theme) => (sidebarIsOpen ? theme.spacing(4) : theme.spacing(2)),
              transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
          }}
        >
          {children}
        </List>
      </Collapse>
    </div>
  );
};
