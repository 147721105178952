import { ResetPasswordDialog } from 'components/auth/ResetPasswordDialog';
import _ from 'lodash';

import { BasicLayout } from '../common/layout/onboarding/BasicLayout';

export const ResetPasswordPage: React.FC = () => {
  return (
    <BasicLayout showLogin={false}>
      <ResetPasswordDialog
        displayAsPage
        instanceId="reset-password-page"
        isOpen={true}
        onReject={_.noop}
        onResolve={_.noop}
      />
    </BasicLayout>
  );
};
