import { Box, CircularProgress, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { ReactComponent as Checkmark } from 'assets/icons/Checkmark.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/ChevronUp.svg';
import { FormHelperText } from 'common/components/FormHelperText/FormHelperText';
import { InputLabel } from 'common/components/InputLabel/InputLabel';
import { useLayoutEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';

import { FormElementProps } from './FormElement';
import { styles } from './FormSelect.styles';

export interface FormSelectOption {
  value: {
    id: string | number;
    [key: string]: unknown;
  };
  label: React.ReactNode;
}

export interface FormSelectProps extends FormElementProps {
  options: FormSelectOption[];
  loading?: boolean;
  size?: 'large' | 'medium';
}

export const FormSelect = (props: FormSelectProps): React.ReactElement => {
  const { name } = props;
  const { field, fieldState } = useController({ name });
  const selectRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    setWidth(selectRef.current?.clientWidth ?? undefined);
  }, []);

  const handleChange = (value: string) => {
    const fullValue = props.options.find((option) => option.value.id === value);
    field.onChange(fullValue?.value);
    props.onChange?.();
  };

  const error = fieldState?.error?.message;

  const menuItemStyle = { ...styles.menuItem, ...(props.size !== 'medium' ? {} : styles.menuItemMedium) };

  return (
    <FormControl error={!!error} sx={styles.container}>
      {!!props.label && <InputLabel helperText={props.helperText} label={props.label} size={props.size} />}
      <Select
        IconComponent={ArrowIcon}
        MenuProps={{
          PaperProps: {
            style: { width: width },
          },
          anchorEl: selectRef.current,
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        }}
        autoFocus={props.autoFocus}
        label={props.label}
        onBlur={props.onBlur}
        onChange={(event) => handleChange(event.target.value as string)}
        ref={selectRef}
        renderValue={(selected) => props.options.find((o) => o.value.id === selected)?.label}
        sx={props.size !== 'medium' ? undefined : styles.selectField}
        value={field.value?.id ?? 'placeholder'}
      >
        {props.placeholder && !field.value?.id && (
          <MenuItem key={'placeholder'} value={'placeholder'}>
            <Typography color="gray.700" variant="bodyL">
              {props.placeholder}
            </Typography>
          </MenuItem>
        )}
        {props.loading ? (
          <Box sx={styles.loading}>
            <CircularProgress />
          </Box>
        ) : (
          props.options?.map((option) => (
            <MenuItem key={option.value.id} sx={menuItemStyle} value={option.value.id}>
              <Box>{option.label}</Box>
              <Box sx={field.value?.id === option.value.id ? undefined : styles.checkmark}>
                <Checkmark />
              </Box>
            </MenuItem>
          ))
        )}
      </Select>
      {error && <FormHelperText size={props.size}>{error}</FormHelperText>}
    </FormControl>
  );
};
