import { Route } from 'react-router-dom';
import { SentryRoutes } from 'sentry';

import { PropertyInsuranceIntroPage } from './PropertyInsuranceIntroPage';
import { PropertyInsurancePurchasePage } from './PropertyInsurancePurchasePage';

type Props = Record<string, never>;

export const PropertyInsuranceRouter: React.FC<Props> = () => (
  <SentryRoutes>
    <Route element={<PropertyInsuranceIntroPage />} path="/" />
    <Route element={<PropertyInsurancePurchasePage />} path="/purchase" />
  </SentryRoutes>
);
