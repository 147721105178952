import { SxProps, Theme } from '@mui/material';

export const styles: Record<'headline' | 'subtitle', SxProps<Theme>> = {
  headline: {
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    background: '-webkit-linear-gradient(45deg, #F9B158, #E55692, #ED4637)',
    backgroundClip: 'text',
    fontSize: '64px',
    fontWeight: 600,
    lineHeight: '100%',
    paddingBottom: (theme) => theme.spacing(2),
  },
  subtitle: {
    maxWidth: '624px',
  },
};
