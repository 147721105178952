import { useReactiveVar } from '@apollo/client';
import { Loading } from 'common/components/Loading/Loading';
import { useNotification } from 'common/hooks/useNotification';
import { CalculatorLayout } from 'components/calculators/CalculatorLayout/CalculatorLayout';
import {
  lifeInsuranceCalculatorState,
  mapValuesToInquiry,
} from 'components/calculators/lifeInsurance/LifeInsuranceCalculator.utils';
import { LifeInsuranceCalculatorResult } from 'components/calculators/lifeInsurance/LifeInsuranceCalculatorResult/LifeInsuranceCalculatorResult';
import { LIFE_INSURANCE_CALCULATOR_BASE_URL } from 'const';
import {
  LifeInsuranceCalculatorResultQuery,
  useAverageLifeInsuranceScoreQuery,
  useLifeInsuranceCalculatorResultQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const LifeInsuranceCalculatorResultPage: React.FC = () => {
  const { t } = useTranslation();
  const state = useReactiveVar(lifeInsuranceCalculatorState);
  const notify = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (_.isEmpty(state)) {
      navigate(LIFE_INSURANCE_CALCULATOR_BASE_URL, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState<LifeInsuranceCalculatorResultQuery>();

  useLifeInsuranceCalculatorResultQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (resp) => {
      setData(resp);
    },
    onError: () => {
      navigate(LIFE_INSURANCE_CALCULATOR_BASE_URL, { replace: true });
      notify({ message: t('common:somethingWentWrong'), type: 'error' });
    },
    skip: !!data,
    variables: {
      inquiry: mapValuesToInquiry(state),
    },
  });

  const { data: dataAverageScore } = useAverageLifeInsuranceScoreQuery();

  if (!data || !dataAverageScore) {
    return <Loading messages={[]} />;
  } else {
    return (
      <CalculatorLayout fullWidth>
        <LifeInsuranceCalculatorResult
          averageScore={dataAverageScore.averageScore}
          maximalScore={data.result.maximalScore}
          necessity={data.result.necessity}
          score={data.result.score}
          significantDomains={data.result.significantDomains}
          similarUserRatio={data.result.similarUserRatio}
        />
      </CalculatorLayout>
    );
  }
};
