import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container', SxProps<Theme>> = {
  container: {
    display: 'grid',
    gap: (theme) => theme.spacing(2),
    gridTemplateColumns: {
      sm: 'repeat(auto-fit, minmax(20%, 1fr))',
      xs: '1fr',
    },
  },
};
