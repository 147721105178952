import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'icon' | 'text', SxProps<Theme>> = {
  container: {
    '& svg': {
      color: (theme) => theme.palette.blue[600],
    },
    backgroundColor: (theme) => theme.palette.blue[75],
    borderRadius: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.blue[600],
    marginTop: (theme) => theme.spacing(4),
    padding: (theme) => theme.spacing(1, 2),
  },
  icon: {
    alignSelf: 'flex-start',
    width: '24px',
  },
  text: {
    lineHeight: '22px',
  },
};
