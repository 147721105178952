import {
  COLLISION_INSURANCE_BASE_URL,
  COLLISION_INSURANCE_URL_ALIASES,
  INVESTMENT_BASE_URL,
  INVESTMENT_URL_ALIASES,
  LIFE_INSURANCE_AUDIT_BASE_URL,
  LIFE_INSURANCE_AUDIT_URL_ALIASES,
  LIFE_INSURANCE_CALCULATOR_BASE_URL,
  LIFE_INSURANCE_CALCULATOR_URL_ALIASES,
  LIFE_INSURANCE_PURCHASE_BASE_URL,
  LIFE_INSURANCE_PURCHASE_URL_ALIASES,
  MORTGAGE_CALCULATOR_BASE_URL,
  MORTGAGE_CALCULATOR_URL_ALIASES,
  MORTGAGE_PURCHASE_BASE_URL,
  MORTGAGE_PURCHASE_URL_ALIASES,
  MORTGAGE_REFINANCE_BASE_URL,
  MORTGAGE_REFINANCE_URL_ALIASES,
  MOTOR_LIABILITY_INSURANCE_BASE_URL,
  MOTOR_LIABILITY_INSURANCE_URL_ALIASES,
  PROPERTY_INSURANCE_BASE_URL,
  PROPERTY_INSURANCE_URL_ALIASES,
} from 'const';
import { CollisionInsuranceRouter } from 'pages/collisionInsurance/CollisionInsuranceRouter';
import { InvestmentRouter } from 'pages/investment/InvestmentRouter';
import LifeInsuranceCalculatorRouter from 'pages/lifeInsuranceCalculator/LifeInsuranceCalculatorRouter';
import { MortgageCalculatorRouter } from 'pages/mortgageCalculator/MortgageCalculatorRouter';
import { MotorLiabilityInsuranceRouter } from 'pages/motorLiabilityInsurance/MotorLiabilityInsuranceRouter';
import { PropertyInsuranceRouter } from 'pages/propertyInsurance/PropertyInsuranceRouter';
import { lazy } from 'react';
import { Navigate, RouteObject, To } from 'react-router-dom';

const MortgageRefinanceRouter = lazy(() => import('pages/mortgageRefinance/MortgageRefinanceRouter'));
const MortgagePurchaseRouter = lazy(() => import('pages/mortgagePurchase/MortgagePurchaseRouter'));
const LifeInsurancePurchaseRouter = lazy(() => import('pages/lifeInsurancePurchase/LifeInsurancePurchaseRouter'));
const LifeInsuranceAuditRouter = lazy(() => import('pages/lifeInsuranceAudit/LifeInsuranceAuditRouter'));

interface Props {
  appendSearchParams: (path: string) => To;
}

export const routes = ({ appendSearchParams }: Props): RouteObject[] => [
  { element: <MortgageCalculatorRouter />, path: `${MORTGAGE_CALCULATOR_BASE_URL}/*` },
  ...MORTGAGE_CALCULATOR_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(MORTGAGE_CALCULATOR_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <MortgagePurchaseRouter />, path: `${MORTGAGE_PURCHASE_BASE_URL}/*` },
  ...MORTGAGE_PURCHASE_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(MORTGAGE_PURCHASE_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <MortgageRefinanceRouter />, path: `${MORTGAGE_REFINANCE_BASE_URL}/*` },
  ...MORTGAGE_REFINANCE_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(MORTGAGE_REFINANCE_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <LifeInsuranceCalculatorRouter />, path: `${LIFE_INSURANCE_CALCULATOR_BASE_URL}/*` },
  ...LIFE_INSURANCE_CALCULATOR_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(LIFE_INSURANCE_CALCULATOR_BASE_URL)} />,
    key: path,
    path: path,
  })),

  { element: <LifeInsurancePurchaseRouter />, path: `${LIFE_INSURANCE_PURCHASE_BASE_URL}/*` },
  ...LIFE_INSURANCE_PURCHASE_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(LIFE_INSURANCE_PURCHASE_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <LifeInsuranceAuditRouter />, path: `${LIFE_INSURANCE_AUDIT_BASE_URL}/*` },
  ...LIFE_INSURANCE_AUDIT_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(LIFE_INSURANCE_AUDIT_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <MotorLiabilityInsuranceRouter />, path: `${MOTOR_LIABILITY_INSURANCE_BASE_URL}/*` },
  ...MOTOR_LIABILITY_INSURANCE_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(MOTOR_LIABILITY_INSURANCE_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <CollisionInsuranceRouter />, path: `${COLLISION_INSURANCE_BASE_URL}/*` },
  ...COLLISION_INSURANCE_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(COLLISION_INSURANCE_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <PropertyInsuranceRouter />, path: `${PROPERTY_INSURANCE_BASE_URL}/*` },
  ...PROPERTY_INSURANCE_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(PROPERTY_INSURANCE_BASE_URL)} />,
    key: path,
    path: path,
  })),
  { element: <InvestmentRouter />, path: `${INVESTMENT_BASE_URL}/*` },
  ...INVESTMENT_URL_ALIASES.map((path) => ({
    element: <Navigate replace to={appendSearchParams(INVESTMENT_BASE_URL)} />,
    key: path,
    path: path,
  })),
];
