import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/AnalyticsExcluded.svg';
import { ReactComponent as RecurringIcon } from 'assets/icons/Recurring.svg';
import { AccountCard } from 'common/components/AccountCard/AccountCard';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps, useDialog } from 'common/hooks/useDialog';
import { formatCurrency, formatIban } from 'common/utils/formatUtils';
import { format } from 'date-fns';
import { TransactionTag, TransactionTypeCode, useTransactionDetailQuery } from 'generated/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TransactionTypeTranslations } from 'translations/enums';

import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import { TagList } from '../tags/TagList/TagList';
import { getTitleForTransactionCounterparty } from '../utils';
import { EditCounterpartyDialog, EditCounterpartyDialogProps } from './EditCounterpartyDialog/EditCounterpartyDialog';
import { EditNoteDialog, EditNoteDialogProps } from './EditNoteDialog/EditNoteDialog';
import {
  ExcludeAllFromAnalyticsDialog,
  ExcludeAllFromAnalyticsDialogProps,
} from './ExcludeAllFromAnalyticsDialog/ExcludeAllFromAnalyticsDialog';
import {
  PickCategoryDialog,
  PickCategoryDialogOutput,
  PickCategoryDialogProps,
} from './PickCategoryDialog/PickCategoryDialog';
import { TagsSelectDialog, TagsSelectDialogProps } from './TagsSelectDialog/TagsSelectDialog';
import { styles } from './TransactionDetail.styles';
import { TransactionDetailBox } from './TransactionDetailBox';
import { TransactionDetailSwitch } from './TransactionDetailSwitch';
import {
  UpcomingPaymentCreate,
  UpcomingPaymentCreateProps,
  UpcomingPaymentCreateReturn,
} from './UpcomingPaymentDetail/UpcomingPaymentCreate/UpcomingPaymentCreate';
import { UpcomingPaymentDetail, UpcomingPaymentDetailProps } from './UpcomingPaymentDetail/UpcomingPaymentDetail';
import { ExtendedTransactionGroup } from './UpcomingPaymentDetail/utils';
import { useTransactionDetailChange } from './useTransactionDetailChange';

export interface TransactionDetailProps extends DialogComponentProps<void> {
  id: string;
}

const TransactionDetailContent: React.FC<TransactionDetailProps> = ({ id }) => {
  const { i18n, t } = useTranslation();

  const { data, loading } = useTransactionDetailQuery({
    variables: {
      id,
    },
  });

  const { update: updateTransactionDetail } = useTransactionDetailChange(data?.detail);

  const counterpartyDialog = useDialog<EditCounterpartyDialogProps, string>(EditCounterpartyDialog, {
    instanceId: `edit-counterparty-${data?.detail?.counterparty?.id}`,
  });

  const handleCounterpartyClick = async () => {
    if (!!data?.detail?.counterparty) {
      await counterpartyDialog.create({
        id: data?.detail?.counterparty.id,
        name: data?.detail?.counterparty.displayName ?? data?.detail?.counterparty.defaultName,
        transactionAmount: data?.detail?.amount.amount,
      });
    }
  };

  const selectCategoryDialog = useDialog<PickCategoryDialogProps, PickCategoryDialogOutput>(PickCategoryDialog, {
    category: data?.detail?.category,
    instanceId: 'select-category',
    navigable: true,
  });

  const handleCategoryClick = async () => {
    if (!!data?.detail) {
      const { category: newCategory, changeGroup } = await selectCategoryDialog.create({});
      updateTransactionDetail({ category: newCategory }, changeGroup);
    }
  };

  const selectTagsDialog = useDialog<TagsSelectDialogProps, TransactionTag[] | undefined>(TagsSelectDialog, {
    instanceId: 'select-tags',
    navigable: true,
    tags: data?.detail?.tags,
  });

  const handleTagsClick = async () => {
    if (!!data?.detail) {
      const newTags = await selectTagsDialog.create({});
      updateTransactionDetail({ tags: newTags }, false);
    }
  };

  const noteDialog = useDialog<EditNoteDialogProps, string>(EditNoteDialog, {
    instanceId: `edit-note-${data?.detail?.id}`,
  });

  const handleNoteClick = async () => {
    if (!!data?.detail) {
      const newNote = await noteDialog.create({
        note: data?.detail?.note,
      });
      updateTransactionDetail({ note: newNote }, false);
    }
  };

  const excludeAllFromAnalyticsDialog = useDialog<ExcludeAllFromAnalyticsDialogProps, boolean>(
    ExcludeAllFromAnalyticsDialog,
    {
      instanceId: `exclude-from-analytics-${data?.detail?.id}`,
    },
  );

  const handleExcludeFromAnalytics = async () => {
    if (!!data?.detail) {
      const excludeAll = await excludeAllFromAnalyticsDialog.create({
        isExcluded: data.detail.excludeFromAnalytics,
      });
      if (excludeAll !== undefined) {
        updateTransactionDetail({ excludeFromAnalytics: !data.detail.excludeFromAnalytics }, excludeAll);
      }
    }
  };

  const transactionGroup: ExtendedTransactionGroup | undefined = data?.detail?.streamGroup
    ? { ...data?.detail?.streamGroup, category: data.detail.category ?? undefined }
    : undefined;

  const upcomingPaymentCreateDialog = useDialog<UpcomingPaymentCreateProps, UpcomingPaymentCreateReturn>(
    UpcomingPaymentCreate,
    {
      group: transactionGroup,
      instanceId: 'upcoming-payment-create',
      tmpNavigable: true,
    },
  );

  const upcomingPaymentDetailDialog = useDialog<UpcomingPaymentDetailProps, void>(UpcomingPaymentDetail, {
    group: transactionGroup,
    instanceId: 'upcoming-payment-detail',
    tmpNavigable: true,
  });

  const handleAddToRecurringPayments = async () => {
    if (!!transactionGroup) {
      const editReturn = await upcomingPaymentCreateDialog.create({
        group: _.set(_.cloneDeep(transactionGroup), 'period', {
          expectedAmount: data?.detail?.amount,
        }),
      });
      if (editReturn) {
        upcomingPaymentDetailDialog.create({ group: editReturn.group });
      }
    }
  };

  const handleUpcomingPaymentDetail = async () => {
    if (!!transactionGroup) {
      await upcomingPaymentDetailDialog.create({ group: transactionGroup });
    }
  };

  return (
    <Stack>
      <Stack spacing={3}>
        <Stack alignItems="center" spacing={0.5}>
          {loading && <Skeleton height={65} variant="rectangular" width={130} />}
          {data?.detail?.amount && (
            <Typography variant="headlineXL">
              {formatCurrency(data?.detail?.amount.amount, i18n.language, 2, data?.detail?.amount.currency, 'never')}
            </Typography>
          )}
          <Typography color="textSecondary" variant="bodyM">
            {data?.detail?.bookingDate ? format(data?.detail?.bookingDate, 'dd.MM.yyyy, HH:mm') : ''}
          </Typography>
        </Stack>

        {loading ? (
          <Skeleton height={74} variant="rectangular" />
        ) : (
          data?.detail?.counterparty && (
            <TransactionDetailBox
              onClick={handleCounterpartyClick}
              title={getTitleForTransactionCounterparty(data?.detail?.counterparty, data?.detail?.amount.amount, t)}
            >
              {data?.detail?.counterparty?.accountIban && (
                <Typography color="textSecondary" variant="bodyM">
                  {formatIban(data.detail.counterparty.accountIban)}
                </Typography>
              )}
            </TransactionDetailBox>
          )
        )}

        {loading ? (
          <Skeleton height={54} variant="rectangular" />
        ) : (
          <Stack sx={styles.data}>
            <Box sx={styles.row}>
              <Typography variant="bodyL">{t('transactionDetail.account')}</Typography>
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography color="textSecondary" variant="bodyL">
                  {data?.detail?.account.name}
                </Typography>
                <AccountCard colorIndex={data?.detail?.account.colorIndex} height={12} width={20} />
              </Stack>
            </Box>
            <Box sx={styles.row}>
              <Typography variant="bodyL">{t('transactionDetail.transactionType')}</Typography>
              <Typography color="textSecondary" variant="bodyL">
                {TransactionTypeTranslations(t)[data?.detail?.transactionTypeCode ?? TransactionTypeCode.Other]}
              </Typography>
            </Box>
            {data?.detail?.variableSymbol && (
              <Box sx={styles.row}>
                <Typography variant="bodyL">{t('transactionDetail.variableSymbol')}</Typography>
                <Typography color="textSecondary" variant="bodyL">
                  {data?.detail?.variableSymbol}
                </Typography>
              </Box>
            )}
            {data?.detail?.constantSymbol && (
              <Box sx={styles.row}>
                <Typography variant="bodyL">{t('transactionDetail.constantSymbol')}</Typography>
                <Typography color="textSecondary" variant="bodyL">
                  {data?.detail?.constantSymbol}
                </Typography>
              </Box>
            )}
            {data?.detail?.specificSymbol && (
              <Box sx={styles.row}>
                <Typography variant="bodyL">{t('transactionDetail.specificSymbol')}</Typography>
                <Typography color="textSecondary" variant="bodyL">
                  {data?.detail?.specificSymbol}
                </Typography>
              </Box>
            )}
            {data?.detail?.messageForRecipient && (
              <Stack sx={styles.messageForRecipient}>
                <Typography variant="bodyL">{t('transactionDetail.messageForRecipient')}</Typography>
                <Typography color="textSecondary" variant="bodyL">
                  {data?.detail?.messageForRecipient}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}

        <TransactionDetailBox
          image={data?.detail?.category ? <CategoryIcon category={data?.detail?.category} /> : undefined}
          loading={loading}
          onClick={handleCategoryClick}
          title={data?.detail?.category.categoryGroup.name ?? t('transactionDetail.category.title')}
        />

        <TransactionDetailBox loading={loading} onClick={handleTagsClick} title={t('transactionDetail.tags.title')}>
          <Typography color="textSecondary" variant="bodyM">
            <TagList tags={data?.detail?.tags ?? []} />
          </Typography>
        </TransactionDetailBox>

        <TransactionDetailBox loading={loading} onClick={handleNoteClick} title={t('transactionDetail.note.title')}>
          <Typography color="textSecondary" variant="bodyM">
            {data?.detail?.note}
          </Typography>
        </TransactionDetailBox>

        <TransactionDetailSwitch
          image={<AnalyticsIcon height={16} width={16} />}
          label={t('transactionDetail.excludeFromAnalytics.label')}
          loading={loading}
          onClick={handleExcludeFromAnalytics}
          value={data?.detail?.excludeFromAnalytics}
        />
      </Stack>
      {data?.detail?.streamGroup && (
        <Stack marginTop={8} spacing={3}>
          <Typography variant="headlineM">{t('transactionDetail.next')}</Typography>
          {data?.detail?.streamGroup?.period ? (
            <TransactionDetailBox
              image={<RecurringIcon height={20} width={20} />}
              loading={loading}
              onClick={handleUpcomingPaymentDetail}
              title={t('transactionDetail.recurringPayment')}
            />
          ) : (
            <Stack>
              <Box onClick={handleAddToRecurringPayments} sx={styles.add}>
                <Typography color="blue.dark">{t('transactionDetail.addToRecurringPayments')}</Typography>
              </Box>
              <Typography color="textSecondary" sx={styles.addDescription} textAlign="left" variant="bodyS">
                {t('transactionDetail.addDescription')}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export const TransactionDetail: React.FC<TransactionDetailProps> = (props) => (
  <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
    <TransactionDetailContent {...props} />
  </ResponsiveSideDialog>
);
