import { SxProps, Theme } from '@mui/material';

export const styles: Record<'content' | 'image' | 'sadFace', SxProps<Theme>> = {
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: '720px',
    textAlign: 'center',
  },

  image: {
    '& svg': {
      color: (theme) => theme.palette.gray[300],
    },
    position: 'relative',
  },
  sadFace: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '24px',
    height: '64px',
    justifyContent: 'center',
    left: '-2px',
    position: 'absolute',
    top: '-2px',
    whiteSpace: 'nowrap',
    width: '64px',
  },
};
