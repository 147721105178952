import { DateField, NumberField, ReferenceField, Show, TabbedShowLayout, TextField } from 'react-admin';

export const MortgageCalculatorCalculationShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Základné info">
        <TextField label="ID" source="id" />
        <ReferenceField label="Klient" link="show" reference="Client" source="client.id">
          <TextField source="user.email" />
        </ReferenceField>
        <TextField label="Telefónne číslo" source="client.user.phoneNumber" />
        <DateField label="Vytvorené" locales="sk" source="creationTimestamp" />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Vstup">
        <NumberField label="Počet žiadateľov" source="inquiry.numberOfApplicants" />
        <NumberField label="Vek najstaršieho žiadateľa" source="inquiry.ageOldestApplicant" />
        <NumberField label="Počet vyživovaných detí" source="inquiry.numberOfDependents" />

        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>Príjmy zo zamestnania</span>
        <TextField
          label="Súčet čistých mesačných príjmov všetkých žiadateľov"
          source="inquiry.salaryIncome.monthlyIncome.amount"
        />

        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>Príjmy zo živnosti</span>
        <TextField
          label="Základ dane v poslednom zdaňovacom období"
          source="inquiry.entrepreneurshipIncome.taxBase.amount"
        />
        <TextField
          label="Zaplatená daň v poslednom zdaňovacom období"
          source="inquiry.entrepreneurshipIncome.taxPayed.amount"
        />
        <TextField
          label="Tržby v poslednom zdaňovacom období"
          source="inquiry.entrepreneurshipIncome.yearlyRevenues.amount"
        />

        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>Príjmy z s. r. o.</span>
        <TextField label="Podiel v spoločnosti" source="inquiry.ltdIncome.equityShares" />
        <TextField label="Tržby v poslednom zdaňovacom období" source="inquiry.ltdIncome.yearlyRevenues.amount" />
        <TextField
          label="Zisk po zdanení v poslednom zdaňovacom období"
          source="inquiry.ltdIncome.yearlyProfit.amount"
        />
        <TextField
          label="Odmeny konateľa za ostatných 12 mesiacov v čistom"
          source="inquiry.ltdIncome.ceoCompensation.amount"
        />

        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>Záväzky</span>
        <TextField
          label="Výška mesačných splátok všetkých úverových záväzkov žiadateľov"
          source="inquiry.liabilities.monthlyInstallment.amount"
        />
        <TextField
          label="Aktuálny zostatok úverových záväzkov všetkých žiadateľov"
          source="inquiry.liabilities.balance.amount"
        />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Výsledok">
        <TextField label="Maximálna hypotéka" source="result.maxMortgageAmount.amount" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
