import { SxProps, Theme } from '@mui/material';
import { RESPONSIVE_DIALOG_HEADER_HEIGHT } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog.styles';

const filterButtonBase: SxProps<Theme> = {
  color: (theme) => theme.palette.blue.dark,
  marginLeft: (theme) => theme.spacing(1),
  svg: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '100%',
  },
};

export const styles: Record<'search' | 'filterButton' | 'activeFilterButton' | 'filters' | 'header', SxProps<Theme>> = {
  activeFilterButton: {
    ...filterButtonBase,
    backgroundColor: (theme) => theme.palette.blue.dark,
    color: (theme) => theme.palette.blue.dark,
    svg: {
      backgroundColor: (theme) => theme.palette.gray[50],
      borderRadius: '100%',
      boxShadow: (theme) => `inset 0px 0px 0px 1px ${theme.palette.blue.dark}`,
    },
  },
  filterButton: {
    ...filterButtonBase,
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(3),
  },
  header: {
    backgroundColor: (theme) => theme.palette.gray[100],
    position: 'sticky',
    top: RESPONSIVE_DIALOG_HEADER_HEIGHT,
    zIndex: 1,
  },
  search: (theme) => ({
    '& .MuiInputBase-root': {
      '& > *': {
        '&::placeholder': { fontStyle: 'normal' },
        padding: 0,
      },
      '&:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.gray[300],
      },
      backgroundColor: theme.palette.gray[200],
      borderRadius: '6px',
      input: {
        fontSize: 17,
        height: 36,
      },
      padding: theme.spacing(0, 1),
    },
    '& .MuiTextField-root': {
      flexGrow: 1,
    },
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  }),
};
