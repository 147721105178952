import { Stack, Typography } from '@mui/material';
import { Center } from 'common/components/Center/Center';
import { environment } from 'environment';
import { useTranslation } from 'react-i18next';

export const Content: React.FC<{ url?: string }> = ({ url = environment.finportal.propertyInsuranceUrl }) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Center>
        <Typography component="h1" mb={2} variant="headlineXL">
          {t(`propertyInsurance.purchasePage.title`)}
        </Typography>
      </Center>
      <iframe height="800>" src={url} style={{ border: 0 }} width="100%" />
    </Stack>
  );
};
