import { MortgageIncomeInput, MortgageIncomeType, MortgagePurchaseApplicantFragment } from 'generated/graphql';

import { Income, Mapper, MortgageOtherIncomeSubtype } from './purchaseTypes';

export const mapIncomes: Mapper<Income[], MortgageIncomeInput[], MortgagePurchaseApplicantFragment['incomes']> = {
  parse: (incomes) => {
    if (!incomes) {
      return undefined;
    }
    return incomes.map((income) => {
      switch (income.__typename) {
        case 'ApplicantSalaryIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            type: MortgageIncomeType.Salary,
          };
        case 'ApplicantEntrepreneurshipIncome':
          return {
            expenseType: income.expenseType,
            revenue: income.yearlyRevenues?.amount,
            taxBase: income.taxBase?.amount,
            taxPayed: income.taxPayed?.amount,
            type: MortgageIncomeType.Entrepreneurship,
          };
        case 'ApplicantLtdIncome':
          return {
            ceoCompensation: income.ceoCompensation?.amount,
            equityShares: income.equityShares,
            type: MortgageIncomeType.Ltd,
            yearlyProfit: income.yearlyProfit?.amount,
            yearlyRevenues: income.yearlyRevenues?.amount,
          };
        case 'ApplicantTravelCompensationIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.TravelCompensation },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantRealEstateRentalIncome':
          return {
            incomeCommencementDate: income.contractSignatureDate,
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.RealEstateRental },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantMaternityLeaveIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.MaternityLeave },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantParentalAllowanceIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.ParentalAllowance },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantAlimonyIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.Alimony },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantWorkAgreementIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.WorkAgreement },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantDoctoralScholarshipIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.DoctoralScholarship },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantDisabilityPensionIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.DisabilityPension },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantOldAgePensionIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.OldAgePension },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantOrphanPensionIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.OrphanPension },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantRetirementPensionIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.RetirementPension },
            type: MortgageIncomeType.Other,
          };
        case 'ApplicantWidowPensionIncome':
          return {
            monthlyIncome: income.monthlyIncome.amount,
            otherIncomeSubtype: { id: MortgageOtherIncomeSubtype.WidowPension },
            type: MortgageIncomeType.Other,
          };
        default:
          return {};
      }
    });
  },
  serialize: (incomes) => {
    if (!incomes) {
      return undefined;
    }

    return incomes.map((income) => {
      switch (income.type) {
        case MortgageIncomeType.Salary:
          return {
            salaryIncome: {
              monthlyIncome: {
                amount: income.monthlyIncome ?? 0,
                currency: 'EUR',
              },
            },
          };
        case MortgageIncomeType.Entrepreneurship:
          return {
            entrepreneurshipIncome: {
              expenseType: income.expenseType,
              monthlyIncome: null,
              taxBase: {
                amount: income.taxBase ?? 0,
                currency: 'EUR',
              },
              taxPayed: {
                amount: income.taxPayed ?? 0,
                currency: 'EUR',
              },
              yearlyRevenues: {
                amount: income.revenue ?? 0,
                currency: 'EUR',
              },
            },
          };
        case MortgageIncomeType.Ltd:
          return {
            ltdIncome: {
              ceoCompensation: {
                amount: income.ceoCompensation ?? 0,
                currency: 'EUR',
              },
              equityShares: income.equityShares ?? 0,
              monthlyIncome: null,
              yearlyProfit: {
                amount: income.yearlyProfit ?? 0,
                currency: 'EUR',
              },
              yearlyRevenues: {
                amount: income.yearlyRevenues ?? 0,
                currency: 'EUR',
              },
            },
          };

        case MortgageIncomeType.Other:
          switch (income.otherIncomeSubtype?.id) {
            case MortgageOtherIncomeSubtype.TravelCompensation:
              return {
                travelCompensationIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.RealEstateRental:
              return {
                realEstateRentalIncome: {
                  contractSignatureDate: income.incomeCommencementDate ?? new Date(),
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.MaternityLeave:
              return {
                maternityLeaveIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.ParentalAllowance:
              return {
                parentalAllowanceIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.Alimony:
              return {
                alimonyIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.WorkAgreement:
              return {
                workAgreementIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.DoctoralScholarship:
              return {
                doctoralScholarshipIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.DisabilityPension:
              return {
                disabilityPensionIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.OldAgePension:
              return {
                oldAgePensionIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.OrphanPension:
              return {
                orphanPensionIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.RetirementPension:
              return {
                retirementPensionIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            case MortgageOtherIncomeSubtype.WidowPension:
              return {
                widowPensionIncome: {
                  monthlyIncome: {
                    amount: income.monthlyIncome ?? 0,
                    currency: 'EUR',
                  },
                },
              };

            default:
              return {};
          }

        default:
          return {};
      }
    });
  },
};
