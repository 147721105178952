import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { formatTransactionAmount } from 'common/utils/formatUtils';
import ReactEcharts from 'echarts-for-react';
import { TransactionAttribute, useAggregateTransactionsQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { PeriodTypeEnum } from '../state/spendingReportTypes';
import { useSpendingReportState } from '../state/useSpendingReportState';
import { getFilter, getPeriod } from '../utils';
import { createChartOption } from './SpendingReportWidget.chart';

const useSpendingReportWidgetTitle = (periodType: PeriodTypeEnum): string => {
  const { t } = useTranslation();
  return {
    [PeriodTypeEnum.Day]: t('spendingReport.widget.title.daily'),
    [PeriodTypeEnum.Week]: t('spendingReport.widget.title.weekly'),
    [PeriodTypeEnum.Month]: t('spendingReport.widget.title.monthly'),
    [PeriodTypeEnum.Year]: t('spendingReport.widget.title.yearly'),
  }[periodType];
};

export const SpendingReportWidget: React.FC = () => {
  const { i18n } = useTranslation();
  const { state } = useSpendingReportState();
  const period = getPeriod(state.params.periodType);

  const filterConditions = getFilter(new Date(), state.params.periodType);
  const filterInput = {
    arguments: filterConditions.map((condition) => ({ filterCondition: condition })),
  };
  const { data } = useAggregateTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput,
      groupBy: [TransactionAttribute.Category, period.unit as string as TransactionAttribute],
    },
  });

  const { data: totalData } = useAggregateTransactionsQuery({
    variables: {
      filterInput,
    },
  });

  const total = totalData?.aggregateTransactions.resultData[0]?.sum.amount ?? 0;

  const title = useSpendingReportWidgetTitle(state.params.periodType);

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant="bodyM">{title}</Typography>
        <Typography color={total > 0 ? 'success.main' : undefined} variant="headlineL">
          {data ? (
            formatTransactionAmount(total, i18n.language, 2, data?.aggregateTransactions.baseCurrency)
          ) : (
            <Skeleton />
          )}
        </Typography>
      </Stack>
      {data ? (
        <Box>
          <ReactEcharts
            notMerge={true}
            option={createChartOption(data.aggregateTransactions.resultData, period)}
            style={{ height: '100%', pointerEvents: 'none', width: '100%' }}
          />
        </Box>
      ) : (
        <Skeleton height={100} variant="rectangular" />
      )}
    </Stack>
  );
};
