import { Box, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { styles } from './TransactionDetailSwitch.styles';

interface Props {
  image?: ReactNode;
  label: string;
  value?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export const TransactionDetailSwitch: React.FC<Props> = (props) => {
  if (props.loading) {
    return <Skeleton height={52} variant="rectangular" />;
  }

  return (
    <Box onClick={props.onClick} sx={styles.container}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {props.image}
        <Typography variant="bodyL">{props.label}</Typography>
      </Stack>
      <Switch checked={props.value} />
    </Box>
  );
};
