import { Stack, Typography } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { useTranslation } from 'react-i18next';

export const NoTransactions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" height={196} justifyContent="center" spacing={0.5}>
      <SearchIcon />
      <Typography variant="bodyM">{t('transactionList.noTransactions.title')}</Typography>
      <Typography color="textSecondary" variant="bodyM">
        {t('transactionList.noTransactions.subtitle')}
      </Typography>
    </Stack>
  );
};
