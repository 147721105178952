import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { StyledDialog } from 'common/components/StyledDialog/StyledDialog';
import { StyledDialogFormError } from 'common/components/StyledDialog/StyledDialogFormError';
import { StyledDialogTitle } from 'common/components/StyledDialog/StyledDialogTitle';
import { FormInput } from 'common/form/components/FormInput';
import { phoneValidator } from 'common/form/validators';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { normalizePhoneNumber } from 'common/utils/formatUtils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { styles } from './ChangeDialog.styles';

export interface FormValues {
  phone?: string;
}

export type ChangePhoneDialogProps = DialogComponentProps<void>;

export const ChangePhoneDialog: React.FC<ChangePhoneDialogProps> = ({ isOpen, onReject, onResolve }) => {
  const { t } = useTranslation();
  const { updateAttributes } = useContext(AuthContext);

  const schema: yup.SchemaOf<FormValues> = yup.object({
    phone: phoneValidator().required(t('form.validator.fieldIsRequired')),
  });

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (data.phone) {
      const normalizedPhone = normalizePhoneNumber(data.phone);
      setError('');
      setLoading(true);
      const resp = await updateAttributes({
        phone_number: normalizedPhone,
      });

      if (resp) {
        setError(resp.formError?.message ?? t('common:somethingWentWrong'));
      } else {
        onResolve();
      }
      setLoading(false);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <StyledDialog maxWidth="sm" onClose={() => onReject()} open={isOpen}>
        <StyledDialogTitle textAlign="left">{t('settings.changeDialog.phone.title')}</StyledDialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormInput
              fullWidth
              label={t('settings.changeDialog.phone.label')}
              name="phone"
              placeholder={t('settings.changeDialog.phone.placeholder')}
              sx={styles.input}
              type="tel"
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondaryButton" onClick={() => onReject()}>
              {t('settings.changeDialog.cancel')}
            </Button>
            <LoadingButton loading={loading} type="submit">
              {t('settings.changeDialog.save')}
            </LoadingButton>
          </DialogActions>
          {!!error && !loading && <StyledDialogFormError content={error} title={t('settings.changeDialog.error')} />}
        </Box>
      </StyledDialog>
    </FormProvider>
  );
};
