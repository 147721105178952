import { SxProps, Theme } from '@mui/material';

const tabStyle: SxProps<Theme> = {
  alignItems: 'center',
  borderRadius: '1000px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  gap: (theme) => theme.spacing(1),
  padding: (theme) => theme.spacing(1.5, 2),
};

export const styles: Record<'container' | 'tab' | 'activeTab' | 'tabLabel', SxProps<Theme>> = {
  activeTab: {
    ...tabStyle,
    backgroundColor: (theme) => theme.palette.gray[50],
  },
  container: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[200],
    borderRadius: '1000px',
    display: 'flex',
    flexDirection: 'row',
    padding: '2px',
  },
  tab: {
    ...tabStyle,
    backgroundColor: (theme) => theme.palette.gray[200],
  },
  tabLabel: {
    display: {
      sm: 'unset',
      xs: 'none',
    },
  },
};
