import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { InstitutionIcon } from 'common/components/InstitutionIcon/InstitutionIcon';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { AuthContext } from 'common/providers/AuthContextProvider/AuthContextProvider';
import { useInitNordigenFlow } from 'components/accounts/AccountConnectUtils/AccountConnectUtils';
import { DataProtectionGuarantees } from 'components/accounts/DataProtectionGuarantees/DataProtectionGuarantees';
import { FilterOperator, useInstitutionsQuery } from 'generated/graphql';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './AddAccountDialog.styles';

interface AddAccountDialogContentProps extends DialogComponentProps<void> {
  institutionId: string;
}

const AddAccountDialogContent: React.FC<AddAccountDialogContentProps> = ({ institutionId }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { data } = useInstitutionsQuery({
    skip: !isAuthenticated,
    variables: {
      filterInput: {
        arguments: [
          {
            filterCondition: {
              fieldPath: 'possibleAispNordigenConnection',
              operator: FilterOperator.Equal,
              values: ['true'],
            },
          },
        ],
      },
    },
  });

  const institution = data?.getInstitutionsPage.items.find((institution) => institution.id === institutionId);

  const [handleInitNordigenFlow, { loading: loadingNordigenFlow }] = useInitNordigenFlow();

  const handleContinueClick = async () => {
    await handleInitNordigenFlow(institutionId);
  };

  return (
    <Box>
      <Stack alignItems="center" spacing={1} textAlign="center">
        <InstitutionIcon
          alt={institution?.name ?? undefined}
          src={institution?.logoUrl ?? undefined}
          title={institution?.name ?? undefined}
          width={96}
        />

        <Typography variant="headlineXL">{institution?.name}</Typography>
        <Typography textAlign="center" variant="bodyL">
          {t('homepage.accounts.addAccount.subtitle')}
        </Typography>
      </Stack>
      <Box sx={styles.textBox}>
        <DataProtectionGuarantees />
      </Box>
      <Box mt={8}>
        <LoadingButton
          color="primary"
          fullWidth
          loading={loadingNordigenFlow}
          onClick={handleContinueClick}
          size="large"
        >
          <Typography color="white" variant="bodyL">
            {t('homepage.accounts.addAccount.continue')}
          </Typography>
        </LoadingButton>
      </Box>
    </Box>
  );
};

export type AddAccountDialogProps = AddAccountDialogContentProps;

export const AddAccountDialog: React.FC<AddAccountDialogProps> = (props) => {
  return (
    <ResponsiveSideDialog dialogId={props.instanceId} isOpen={props.isOpen} onClose={props.onResolve}>
      <AddAccountDialogContent {...props} />
    </ResponsiveSideDialog>
  );
};
