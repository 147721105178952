import { HOMEPAGE_LOGIN_REDIRECTS, LOGIN_URL } from 'const';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

const NAVIGATE_AFTER_LOGIN_URL = 'navigateAfterLoginUrl';
const DEFAULT_REDIRECT_URL = '/';
const EXPIRATION_TIME = 1000 * 60 * 60; // saved URL expiration time in ms

interface NavigateAfterLogin {
  url: string;
  timestamp: number;
}

export const useNavigateAfterLogin = (): {
  saveNavigateAfterLoginUrl: (urlString: string) => void;
  handleNavigateAfterLogin: () => void;
  navigateAfterLogin: (to: To, options?: NavigateOptions) => void;
} => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateAfterLogin = (to: To, options?: NavigateOptions) => {
    if (typeof to === 'string') {
      navigate(`${LOGIN_URL}${to}`, options);
    } else {
      navigate({ ...to, pathname: `${LOGIN_URL}${to.pathname}` }, options);
    }
  };

  const saveNavigateAfterLoginUrl = (urlString: string) => {
    // TODO: save timestamp to prevent redirecting to old URLs
    localStorage.setItem(NAVIGATE_AFTER_LOGIN_URL, JSON.stringify({ timestamp: Date.now(), url: urlString }));
  };

  const handleNavigateAfterLogin = () => {
    const data = localStorage.getItem(NAVIGATE_AFTER_LOGIN_URL);
    if (!!data) {
      localStorage.removeItem(NAVIGATE_AFTER_LOGIN_URL);
      const navigateAfterLogin: NavigateAfterLogin = JSON.parse(data);
      if (navigateAfterLogin.timestamp >= Date.now() - EXPIRATION_TIME) {
        navigate(navigateAfterLogin.url, { replace: true });
        return;
      }
    }

    if (HOMEPAGE_LOGIN_REDIRECTS.find((redirect) => location.pathname.startsWith(redirect))) {
      // no redirect navigate to homepage
      navigate(
        {
          hash: location.hash,
          pathname: DEFAULT_REDIRECT_URL,
          search: location.search,
        },
        { replace: true },
      );
    }
  };
  return {
    handleNavigateAfterLogin,
    navigateAfterLogin,
    saveNavigateAfterLoginUrl,
  };
};
