import { SxProps, Theme } from '@mui/material';

export const styles: Record<'container' | 'skeleton', SxProps<Theme>> = {
  container: {
    '&:hover': {
      background: (theme) => theme.palette.gray[200],
      cursor: 'pointer',
    },
    '.MuiBox-root + &': {
      '&::before': {
        backgroundColor: (theme) => theme.palette.gray[200],
        content: '""',
        height: '1px',
        left: (theme) => theme.spacing(2),
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    padding: (theme) => theme.spacing(2),
    position: 'relative',
  },
  skeleton: {
    borderRadius: '10px',
    height: 54,
  },
};
