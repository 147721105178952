import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CheckmarkSuccessIcon } from 'assets/icons/Checkmark.svg';
import { ResponsiveSideDialog } from 'common/components/ResponsiveSideDialog/ResponsiveSideDialog';
import { DialogComponentProps } from 'common/hooks/useDialog';
import { useTranslation } from 'react-i18next';

import { styles } from './DocumentUploadSuccessDialog.styles';

export interface DocumentUploadSuccessDialogProps extends DialogComponentProps<void> {
  submitted?: boolean;
}

export const DocumentUploadSuccessDialog: React.FC<DocumentUploadSuccessDialogProps> = ({
  instanceId,
  isOpen,
  onResolve,
  submitted,
}) => {
  const { t } = useTranslation();

  const bannerItems = [
    t('products:documentUploadSuccess.banner.items.0'),
    t('products:documentUploadSuccess.banner.items.1'),
    t('products:documentUploadSuccess.banner.items.2'),
  ];

  return (
    <ResponsiveSideDialog dialogId={instanceId} isOpen={isOpen} onClose={onResolve}>
      <Stack alignItems="center">
        <Box sx={styles.image}>
          <CheckmarkSuccessIcon height={40} width={40} />
        </Box>

        {submitted ? (
          <Stack>
            <Typography textAlign="center" variant="headlineXL">
              {t('products:documentUploadSuccess.submitted.title')}
            </Typography>
            <Typography mt={2} textAlign="center" variant="bodyStrongXL">
              {t('products:documentUploadSuccess.submitted.subtitle')}
            </Typography>
            <Typography color="text.secondary" textAlign="center" variant="bodyM">
              {t('products:documentUploadSuccess.submitted.text')}
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={1}>
            <Typography textAlign="center" variant="headlineXL">
              {t('products:documentUploadSuccess.processing.title')}
            </Typography>
            <Typography color="text.secondary" textAlign="center" variant="bodyM">
              {t('products:documentUploadSuccess.processing.text')}
            </Typography>
          </Stack>
        )}

        <Stack spacing={1} sx={styles.banner}>
          <Typography variant="bodyStrongM">{t('products:documentUploadSuccess.banner.title')}</Typography>
          {bannerItems.map((item, index) => (
            <Typography key={index} variant="bodyM">
              {index + 1}. {item}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </ResponsiveSideDialog>
  );
};
