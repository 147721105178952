import _ from 'lodash';
import { createContext } from 'react';

import { Rejector, Resolver } from './types';

interface DialogContext {
  resolveInstance: Resolver;
  rejectInstance: Rejector;
  hasInstance: (id: string) => boolean;
}

export const DialogContext = createContext<DialogContext>({
  hasInstance: () => false,
  rejectInstance: _.noop,
  resolveInstance: _.noop,
});
