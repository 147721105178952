import { SxProps, Theme } from '@mui/material';

export const styles: Record<'data' | 'button', SxProps<Theme>> = {
  button: {
    padding: (theme) => theme.spacing(2),
    width: '100%',
  },
  data: {
    backgroundColor: (theme) => theme.palette.gray[50],
    borderRadius: '10px',
  },
};
