import { SxProps, Theme } from '@mui/material';

export const styles: Record<'tag' | 'tagList', SxProps<Theme>> = {
  tag: {
    backgroundColor: (theme) => theme.palette.gray[100],
    borderRadius: '2px',
    padding: (theme) => theme.spacing(0, 0.5),
    whiteSpace: 'nowrap',
  },

  tagList: {
    marginRight: (theme) => theme.spacing(1),
    width: '100%',
  },
};
